import { Button, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { formatNumberOnTwoDigits, getTimeDifference } from '../../Common/DateHandler';
import { RequestOriginApp, ShipmentDto } from '../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../AutoGeneratedAPI/Extension';
import { RequestOriginAppSR } from '../Extensions/Localizations/Types/Shipment/SR/RequestOriginAppSR';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { removeShipmentFromSelectableArray } from '../Redux/Reducers/Shipments/ShipmentReducer';
import { RootState } from '../Redux/store';

type ShipmentSideDetailsProps = {
    closeShipmentSideDetails(): void;
    allowShipmentUpdate: boolean;
    openShipmentForm(): void;
    isDeletionAllowed: boolean;
};

export default function ShipmentSideDetails({
    closeShipmentSideDetails,
    allowShipmentUpdate,
    openShipmentForm,
    isDeletionAllowed,
}: ShipmentSideDetailsProps): ReactElement {
    const shipment = useAppSelector((state: RootState) => state.shipFormData.shipment);

    const dispatch = useAppDispatch();

    const deleteShipment = (ship: ShipmentDto) => {
        GranitClient.deleteShipment(ship.id ?? 0, ship).then(
            () => {
                dispatch(removeShipmentFromSelectableArray(ship.id ?? 0));
                closeShipmentSideDetails();
            },
            () => {
                alert('Doslo je do greske pri bisanju isporuke');
            },
        );
    };

    const shipmentSideDetailsElement = (): ReactElement => {
        const loadingTimeDiff = getTimeDifference(
            shipment?.startingStop.endingTime,
            shipment.startingStop.startingTime,
        );
        const loadingTimeString =
            loadingTimeDiff.hours > 0
                ? formatNumberOnTwoDigits(loadingTimeDiff.hours) +
                  ':' +
                  formatNumberOnTwoDigits(loadingTimeDiff.minutes)
                : loadingTimeDiff.minutes + 'min.';

        const unloadingTimeDiff = getTimeDifference(shipment?.endingStop.endingTime, shipment.endingStop.startingTime);
        const unloadingTimeString =
            unloadingTimeDiff.hours > 0
                ? formatNumberOnTwoDigits(unloadingTimeDiff.hours) +
                  ':' +
                  formatNumberOnTwoDigits(unloadingTimeDiff.minutes)
                : unloadingTimeDiff.minutes + 'min.';

        return (
            <>
                <div>
                    <Typography gutterBottom variant="h5" component="p">
                        {shipment.title && shipment.title !== '' ? shipment.title : 'Nema naslova'}
                    </Typography>
                </div>
                <hr style={{ width: '100%' }} />
                <div>
                    <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                        <span style={{ fontWeight: 'normal' }}>Cena transporta:</span>{' '}
                        <span style={{ float: 'right' }}>{shipment.transportPrice} din.</span>
                    </Typography>
                </div>
                <hr style={{ width: '100%' }} />
                <div style={{ marginTop: 25 }}>
                    <Typography gutterBottom variant="h5" component="p">
                        Informacije o tovaru
                    </Typography>
                </div>
                <hr style={{ width: '100%' }} />
                <div>
                    <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                        <span style={{ fontWeight: 'normal' }}>Vrednost robe:</span>{' '}
                        <span style={{ float: 'right' }}>{shipment.loadValue ?? 0} din.</span>
                    </Typography>
                    <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                        <span style={{ fontWeight: 'normal' }}>Težina robe:</span>{' '}
                        <span style={{ float: 'right' }}>{shipment.loadWeight ?? 0} kg</span>
                    </Typography>
                    <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                        <span style={{ fontWeight: 'normal' }}>Zapremina robe:</span>{' '}
                        <span style={{ float: 'right' }}>{shipment.loadVolume ?? 0} m&sup3;</span>
                    </Typography>
                    <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                        <span style={{ fontWeight: 'normal' }}>Paletna mesta:</span>{' '}
                        <span style={{ float: 'right' }}>{shipment.loadPalletSpaces ?? 0} pal.</span>
                    </Typography>
                </div>
                <div style={{ marginTop: 25 }}>
                    <Typography gutterBottom variant="h5" component="p">
                        Informacije o klijentu
                    </Typography>
                </div>
                <hr style={{ width: '100%' }} />
                <div>
                    <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                        <span style={{ fontWeight: 'normal' }}>Ime klijenta:</span>{' '}
                        <span style={{ float: 'right' }}>{shipment.data?.clientName}</span>
                    </Typography>
                    <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                        <span style={{ fontWeight: 'normal' }}>Kontakt:</span>{' '}
                        <span style={{ float: 'right' }}>{shipment.data?.clientContact}</span>
                    </Typography>
                    <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                        <span style={{ fontWeight: 'normal' }}>Šifra računa:</span>{' '}
                        <span style={{ float: 'right' }}>{shipment.data?.invoiceCode}</span>
                    </Typography>
                    <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                        <span style={{ fontWeight: 'normal' }}>Dokument:</span>{' '}
                        <span style={{ float: 'right' }}>{shipment.data?.invoiceName}</span>
                    </Typography>
                </div>
                <div style={{ marginTop: 25 }}>
                    <Typography gutterBottom variant="h5" component="p">
                        Utovar/istovar
                    </Typography>
                </div>
                <hr style={{ width: '100%' }} />
                <div>
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="p"
                        style={{ fontSize: 18, whiteSpace: 'pre-wrap' }}
                    >
                        <span style={{ fontWeight: 'normal' }}>Utovar ({loadingTimeString}): </span>
                        <span style={{ display: 'block', textAlign: 'end' }}>
                            {shipment.startingStop?.locationInfo?.address}{' '}
                            <span style={{ fontSize: 16, opacity: 0.8 }}>
                                ({RequestOriginAppSR[shipment.requestOriginApp ?? RequestOriginApp.Transport]})
                            </span>
                        </span>
                    </Typography>
                    <div style={{ clear: 'right' }}></div>
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="p"
                        style={{ fontSize: 18, whiteSpace: 'pre-wrap' }}
                    >
                        <span style={{ fontWeight: 'normal' }}>Istovar ({unloadingTimeString}):</span>{' '}
                        <span style={{ display: 'block', textAlign: 'end' }}>
                            {shipment.endingStop?.locationInfo?.address}
                        </span>
                    </Typography>
                </div>
            </>
        );
    };

    return (
        <main style={{ width: '100%', height: 'calc(100vh - 70px)' }}>
            <div
                style={{
                    position: 'absolute',
                    left: 15,
                    top: 0,
                    fontSize: 30,
                    fontFamily: 'Courier New',
                    cursor: 'pointer',
                }}
                onClick={closeShipmentSideDetails}
            >
                {'>'}
            </div>
            <div
                style={{
                    height: 'calc(100vh - 200px)',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    padding: 20,
                    paddingTop: 0,
                    marginTop: 50,
                }}
            >
                {shipmentSideDetailsElement()}
            </div>
            {allowShipmentUpdate && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: 80,
                        right: 20,
                        width: isDeletionAllowed ? 400 : 200,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                    }}
                >
                    {isDeletionAllowed && (
                        <Button variant="outlined" color="error" onClick={() => deleteShipment(shipment)}>
                            Izbriši isporuku
                        </Button>
                    )}
                    <Button variant="outlined" color="primary" onClick={openShipmentForm}>
                        Izmeni isporuku
                    </Button>
                </div>
            )}
        </main>
    );
}
