export enum TWSortingParameter {
    StartingTime = 'StartingTime',
    LoadingCapacity = 'LoadingCapacity',
    Distance = 'Distance',
}

export enum SortingDirection {
    Ascending = 'Ascending',
    Descending = 'Descending',
}

export enum TWSortingParameterSR {
    StartingTime = 'Početno vreme',
    LoadingCapacity = 'Popunjenost',
    Distance = 'Pređeni put',
}

export enum SortingDirectionSR {
    Ascending = 'Rastuce',
    Descending = 'Opadajuce',
}
