import React, { ReactElement } from 'react';
import { Chart, registerables } from 'chart.js';
import { ExpenseCategory } from '../General/AutoGeneratedAPI/clientApi';
import AdditionalExpensesCalculator from './AdditionalExpensesCalculator';
import { useAppSelector } from '../General/hooks/hooks';
import { RootState } from '../General/Redux/store';

Chart.register(...registerables);

export default function AdditionalExpensesCalcContainer(): ReactElement {
    const expenses = useAppSelector((state: RootState) => state.expenses.expenses);

    return (
        <div
            style={{
                width: 600,
                height: 500,
                border: '1px solid lightgray',
                borderRadius: 5,
                padding: 20,
                paddingBottom: 5,
            }}
        >
            <AdditionalExpensesCalculator
                expenses={
                    expenses?.filter((e) => e.expenseType.expenseCategory === ExpenseCategory.CalculatorExpense) ?? []
                }
            />
        </div>
    );
}
