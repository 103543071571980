import { Grid, Typography } from '@mui/material';
import React, { ChangeEvent, ReactElement } from 'react';
import { ClientDto } from '../../../AutoGeneratedAPI/clientApi';
import CommonCustomerInfo from './CommonCustomerInfo';

type CustomerInfoProps = {
    customer: ClientDto;
    title: string;
    handleInputElementChange(event: ChangeEvent<HTMLInputElement>): void;
};

export default function CustomerInfo({ customer, title, handleInputElementChange }: CustomerInfoProps): ReactElement {
    return (
        <>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="h5" align="center">
                        Unesite podatke {title}
                    </Typography>
                </Grid>
            </Grid>
            <CommonCustomerInfo customer={customer} handleInputElementChange={handleInputElementChange} />
        </>
    );
}
