import { TransportWarrantDto } from '../../../AutoGeneratedAPI/clientApi';
import { createSlice } from '@reduxjs/toolkit';
import { ServerResponseStatus, TimelineViewType } from '../../../Extensions/Extensions';

interface IInitialStateType {
    transportWarrants: TransportWarrantDto[] | null;
    transportWarrantAdditionStatus: ServerResponseStatus;
    transportWarrantUpdateStatus: ServerResponseStatus;
    shipmentsSelectability: boolean;
    timelineViewType: TimelineViewType;
    currentDate: Date;
}

const initialState: IInitialStateType = {
    transportWarrants: null,
    transportWarrantAdditionStatus: ServerResponseStatus.Pending,
    transportWarrantUpdateStatus: ServerResponseStatus.Pending,
    shipmentsSelectability: false,
    timelineViewType: TimelineViewType.Day,
    currentDate: new Date(),
};

const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        addTransportWarrants(state, action) {
            const oldArr = [...(state.transportWarrants ?? [])];
            const newArr = (action.payload ? (action.payload as TransportWarrantDto[]) : []).filter((tw) => {
                return !oldArr?.find((oaTw) => oaTw.id === tw.id);
            });

            const mergedArray = [...oldArr, ...newArr];

            state.transportWarrants = mergedArray;
        },
        addTransportWarrantToRedux(state, action) {
            state.transportWarrants = [...(state.transportWarrants ?? []), action.payload];
        },
        deleteTransportWarrantFromRedux(state, action) {
            const twId = action.payload;
            if (twId > 0) {
                state.transportWarrants =
                    state.transportWarrants?.filter((tw) => tw.id != twId) ?? state.transportWarrants;
            }
        },
        updateTransportWarrant(state, action) {
            const tw = action.payload;
            const tws = state.transportWarrants ?? [];
            const twIndex = tws.findIndex((ctw) => {
                return ctw.id === tw.id;
            });
            if (twIndex !== -1) {
                const oldTwTwItems = tws[twIndex]?.transportWarrantItems;
                if (oldTwTwItems) {
                    tw.transportWarrantItems = oldTwTwItems;
                }
            }
            state.transportWarrants = [...tws.slice(0, twIndex), tw, ...tws.slice(twIndex + 1)];
        },
        setAdditionStatus(state, action) {
            state.transportWarrantAdditionStatus = action.payload;
        },
        setUpdateStatus(state, action) {
            state.transportWarrantUpdateStatus = action.payload;
        },
        addTransportWarrantItem(state, action) {
            const twItem = action.payload;
            const tws = state.transportWarrants ?? [];
            const twIndex = tws.findIndex((tw) => {
                return tw.id === twItem.transportWarrantId;
            });
            const currTw = tws.find((tw) => tw.id === twItem.transportWarrantId) ?? new TransportWarrantDto();
            const currTwItems = currTw?.transportWarrantItems;
            if (currTwItems) {
                const newTwItems = currTwItems.concat(twItem);

                if (newTwItems && twIndex !== -1 && currTw) {
                    currTw.transportWarrantItems = newTwItems;
                    state.transportWarrants = [...tws.slice(0, twIndex), currTw, ...tws.slice(twIndex + 1)];
                }
            } else {
                const newTwItems = [twItem];

                if (newTwItems && twIndex !== -1 && currTw) {
                    currTw.transportWarrantItems = newTwItems;
                    state.transportWarrants = [...tws.slice(0, twIndex), currTw, ...tws.slice(twIndex + 1)];
                }
            }
        },
        deleteTransportWarrantItem(state, action) {
            const twItem = action.payload;
            const tws = state.transportWarrants ?? [];
            const twIndex = tws.findIndex((tw) => {
                return tw.id === twItem.transportWarrantId;
            });
            const currTw = tws.find((tw) => tw.id === twItem.transportWarrantId) ?? new TransportWarrantDto();
            const currTwItemIndex =
                currTw?.transportWarrantItems?.findIndex((twi) => {
                    return twi.shipmentId === twItem.shipmentId;
                }) ?? -1;
            const currTwItems = currTw?.transportWarrantItems;
            if (currTwItems && currTwItemIndex >= 0) {
                const newTwItems = [
                    ...currTwItems.slice(0, currTwItemIndex),
                    ...currTwItems.slice(currTwItemIndex + 1),
                ];

                if (newTwItems && twIndex !== -1) {
                    currTw.transportWarrantItems = newTwItems;
                    state.transportWarrants = [...tws.slice(0, twIndex), currTw, ...tws.slice(twIndex + 1)];
                }
            }
        },
        replaceCalendarTransportWarrantItem(state, action) {
            const shipment = action.payload;

            const tws = state.transportWarrants ?? [];
            const twIndex = tws.findIndex((tw) => {
                return tw.transportWarrantItems?.find((twi) => twi.shipmentId === shipment.id) !== undefined;
            });

            if (twIndex >= 0) {
                const currTw = tws[twIndex];

                const currTwItems = currTw?.transportWarrantItems;

                const currTwItemIndex =
                    currTwItems?.findIndex((twi) => {
                        return twi.shipmentId === shipment.id;
                    }) ?? -1;

                if (currTwItems && currTwItemIndex >= 0) {
                    const newTwItem = currTwItems[currTwItemIndex];

                    if (newTwItem) {
                        newTwItem.shipment = shipment;
                        const newTwItems = [
                            ...currTwItems.slice(0, currTwItemIndex),
                            newTwItem,
                            ...currTwItems.slice(currTwItemIndex + 1),
                        ];

                        if (newTwItems) {
                            currTw.transportWarrantItems = newTwItems;
                            state.transportWarrants = [...tws.slice(0, twIndex), currTw, ...tws.slice(twIndex + 1)];
                        }
                    }
                }
            }
        },
        setShipmentsSelectability(state, action) {
            state.shipmentsSelectability = action.payload;
        },
        toggleShipmentsSelectability(state) {
            state.shipmentsSelectability = !state.shipmentsSelectability;
        },
        setCurrentDate(state, action) {
            state.currentDate = action.payload ?? new Date();
        },
        setTimelineViewType(state, action) {
            state.timelineViewType = action.payload ?? TimelineViewType.Day;
        },
    },
});

export const {
    addTransportWarrants,
    addTransportWarrantToRedux,
    deleteTransportWarrantFromRedux,
    updateTransportWarrant,
    setAdditionStatus,
    setUpdateStatus,
    addTransportWarrantItem,
    deleteTransportWarrantItem,
    replaceCalendarTransportWarrantItem,
    setShipmentsSelectability,
    toggleShipmentsSelectability,
    setCurrentDate,
    setTimelineViewType,
} = calendarSlice.actions;

export default calendarSlice.reducer;
