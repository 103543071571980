import styled from 'styled-components';
import {
    DEFAULT_COLOR,
    DEFAULT_DARK_COLOR,
    DEFAULT_LIGHTLY_LIGHT_COLOR,
    DEFAULT_LIGHT_COLOR,
    DEFAULT_SEMI_LIGHT_COLOR,
} from '../../../../Common/Consts';

// nice border and background, but needs a lot of calculations in order for times and paddings to be precise, so I am leaving it commented
// border: 1px solid ${DEFAULT_COLOR};
// background-color: ${DEFAULT_SEMI_LIGHT_COLOR};
export const TWTimelineViewContainer = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    border-radius: 10px;
    padding: 10px 10px;
    padding-top: 20px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    border: 1px solid ${DEFAULT_COLOR};
    background-color: ${DEFAULT_SEMI_LIGHT_COLOR};
`;

export const TWTimelineViewLoadingUnloadingElement = styled.div<{ $width?: number }>`
    background: repeating-linear-gradient(
        -45deg,
        ${DEFAULT_DARK_COLOR},
        ${DEFAULT_DARK_COLOR} 3px,
        ${DEFAULT_SEMI_LIGHT_COLOR} 3px,
        ${DEFAULT_SEMI_LIGHT_COLOR} 6px
    );
    width: ${(props) => props.$width ?? 0}%;
    border: 1px solid ${DEFAULT_COLOR};
    z-index: 2;
    border-radius: 10px;
`;

export const TWTimelineViewTransportElement = styled.div<{ $width?: number; $left?: number }>`
    background-color: ${DEFAULT_LIGHT_COLOR};
    width: ${(props) => props.$width ?? 0}%;
    margin-left: ${(props) => props.$left ?? 0}%;
    border: 1px solid ${DEFAULT_COLOR};
    z-index: 2;
    border-radius: 10px;
`;

export const TWTimelineViewEstimatedTimeElement = styled.div<{ $width?: number; $left?: number }>`
    background-color: ${DEFAULT_LIGHT_COLOR};
    width: ${(props) => props.$width ?? 0}%;
    margin-left: ${(props) => props.$left ?? 0}%;
    border: 1px solid ${DEFAULT_COLOR};
    z-index: 2;
    border-radius: 10px;
    height: 40px;
    margin-top: 5px;
`;

export const TWTimelineViewActualTimeElement = styled.div<{ $left?: number; $width?: number }>`
    background-color: ${DEFAULT_LIGHTLY_LIGHT_COLOR};
    height: 55px;
    top: 18px;
    left: calc(${(props) => props.$left ?? 0}% + 10px);
    zindex: 1;
    position: absolute;
    width: calc(${(props) => props.$width ?? 0}% - 20px);
    border: 1px solid ${DEFAULT_COLOR};
    border-radius: 10px;
`;

export const TimestampsContainer = styled.div`
    position: absolute;
    width: calc(100% - 20px);
    height: 100%;
    top: 0px;
`;

export const TWTimelineViewTimestampElement = styled.div<{ $left?: number }>`
    position: absolute;
    left: ${(props) => props.$left ?? 0}%;
    top: 0px;
    z-index: 50;
    border-left: 1px dashed ${DEFAULT_DARK_COLOR};
    color: ${DEFAULT_LIGHTLY_LIGHT_COLOR};
    height: 80px;
    pointer-events: none;
    padding: 0px 10px;
`;
