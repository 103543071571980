import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import user_logo from '../../General/assets/img/user_logo.png';
import ImageComponent from '../../Common/CommonComponents/ImageComponent';
import { MgtTemplateProps } from '@microsoft/mgt-react';
import '../../General/Forms/TransportWarrantForm/TransportWarrantForm.css';

export default function GraphDriversCalendarCardContainer(props: MgtTemplateProps): ReactElement {
    const { person, personImage } = props.dataContext;

    return (
        <div className="graph-driver-card-container">
            <ImageComponent src={personImage ?? user_logo} placeholder={''} isCalendar={true} />

            <div className="graph-driver-card-text">
                <Typography gutterBottom variant={'body2'} component="p" className="calendar-vehicle-card-text">
                    {person.displayName}
                </Typography>
            </div>
        </div>
    );
}
