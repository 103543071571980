import { StopDto } from '../General/AutoGeneratedAPI/clientApi';
import { CompareTwoDateTimesEquality } from './DateHandler';

/**
 * Returns true if corresponding times and dates are the same for oldStop and newStop
 * @param oldStop
 * @param newStop
 * @returns boolean
 */
export const CompareDatesAndTimesForTwoStops = (oldStop: StopDto, newStop: StopDto): boolean => {
    return (
        CompareTwoDateTimesEquality(oldStop.startingTime, newStop.startingTime) &&
        CompareTwoDateTimesEquality(oldStop.endingTime, newStop.endingTime)
    );
};
