import { Typography, Grid, Stack, TextField, Tooltip } from '@mui/material';
import React, { ChangeEvent, ReactElement } from 'react';
import { formatNumberTypeInput } from '../../../Common/Common';
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/sr';
import { CompareTwoDates, CompareTwoDatesEquality, CompareTwoTimes } from '../../../Common/DateHandler';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { setTransportWarrant } from '../../Redux/Reducers/Warrants/TransportWarrantFormReducer';
import { RootState } from '../../Redux/store';
import { TransportWarrantDto } from '../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import { ExceptionsWithUpdating, ExceptionsWithTransportWarrant } from '../../Extensions/Exceptions';
import { ServerResponseStatus } from '../../Extensions/Extensions';
import { setUpdateStatus, updateTransportWarrant } from '../../Redux/Reducers/Warrants/CalendarReducer';
import SubmitButton from '../../../Common/CommonComponents/SubmitButton';

type TransportWarrantSideDetailsElementFinishComponentProps = {
    closeFinishComponent(): void;
    enableFinishTw: boolean;
};

export default function TransportWarrantSideDetailsElementFinishComponent({
    closeFinishComponent,
    enableFinishTw,
}: TransportWarrantSideDetailsElementFinishComponentProps): ReactElement {
    const transportWarrant = useAppSelector((state: RootState) => state.twFormData.transportWarrant);

    const dispatch = useAppDispatch();

    const dateTimeValidity = (): boolean => {
        return transportWarrant.actStartingTime &&
            transportWarrant.actEndingTime &&
            (CompareTwoDates(new Date(transportWarrant.actStartingTime), new Date(transportWarrant.actEndingTime)) ||
                (CompareTwoDatesEquality(
                    new Date(transportWarrant.actStartingTime),
                    new Date(transportWarrant.actEndingTime),
                ) &&
                    CompareTwoTimes(
                        new Date(transportWarrant.actStartingTime),
                        new Date(transportWarrant.actEndingTime),
                    )))
            ? true
            : false;
    };

    const kmValidity = (): boolean => {
        return transportWarrant.startingKm &&
            transportWarrant.endingKm &&
            transportWarrant.startingKm < transportWarrant.endingKm
            ? true
            : false;
    };

    const handleActualStartingTimeChange = (date?: Date): void => {
        if (date && date.toString() !== 'Invalid Date') {
            dispatch(
                setTransportWarrant({
                    ...transportWarrant,
                    actStartingTime: new Date(date),
                }),
            );
        }
    };

    const handleActualEndingTimeChange = (date?: Date): void => {
        if (date && date.toString() !== 'Invalid Date') {
            dispatch(
                setTransportWarrant({
                    ...transportWarrant,
                    actEndingTime: new Date(date),
                }),
            );
        }
    };

    const handleStartingKmChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const startKm = event.target.value;
        if (startKm) {
            dispatch(
                setTransportWarrant({
                    ...transportWarrant,
                    startingKm: +startKm,
                }),
            );
        }
    };

    const handleEndingKmChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const endKm = event.target.value;
        if (endKm) {
            dispatch(
                setTransportWarrant({
                    ...transportWarrant,
                    endingKm: +endKm,
                }),
            );
        }
    };

    const submit = (): void => {
        const tw: TransportWarrantDto = new TransportWarrantDto();
        Object.assign(tw, transportWarrant);
        tw.isTwFinished = true;
        GranitClient.updateTransportWarrant(tw !== undefined ? (tw.id as number) : 0, tw as TransportWarrantDto).then(
            (result) => {
                dispatch(updateTransportWarrant(result));
                dispatch(setTransportWarrant(result));
                closeFinishComponent();
            },
            (error) => {
                let message =
                    ExceptionsWithUpdating.EXCEPTION_UPDATING_TRANSPORTWARRANTS +
                        '\n\n' +
                        ExceptionsWithTransportWarrant.find((e) => e.code === error.messageCode)?.value ??
                    error.messageCode;
                if (error.messageCode === ExceptionsWithTransportWarrant[2].code) {
                    if (error.messageValue && error.messageValue !== '') {
                        const kms = error.messageValue.split(',');
                        const startKm = +kms[0];
                        const endKm = +kms[1];

                        if (startKm > 0) {
                            if (endKm > 0) {
                                message += `. Kilometraža mora biti između ${startKm} - ${endKm}`;
                            } else {
                                message += `. Kilometraža mora biti veća od ${startKm}`;
                            }
                        } else if (endKm > 0) {
                            message += `. Kilometraža mora biti manja od ${endKm}`;
                        }
                    }
                }
                alert(message);
                dispatch(setUpdateStatus(ServerResponseStatus.Error));
            },
        );
    };

    const tooltipText = enableFinishTw
        ? !dateTimeValidity()
            ? 'Početno vreme mora biti ranije od završnog vremena'
            : !kmValidity()
            ? 'Početna kilometraža mora biti manja od završne kilometraže'
            : ''
        : 'Sačuvajte promene na isporukama pre nego sto završite transport';

    return (
        <div>
            <div style={{ marginTop: 25 }}>
                <Typography gutterBottom variant="body1" component="p">
                    Trajanje putnog naloga
                </Typography>
            </div>
            <hr style={{ width: '100%' }} />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                        <Stack spacing={3}>
                            <MobileTimePicker
                                label="Početno vreme"
                                disableMaskedInput
                                value={transportWarrant.actStartingTime}
                                onChange={(newValue) => {
                                    handleActualStartingTimeChange(newValue ?? new Date());
                                }}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                        <Stack spacing={3}>
                            <MobileTimePicker
                                label="Završno vreme"
                                disableMaskedInput
                                value={transportWarrant.actEndingTime}
                                onChange={(newValue) => {
                                    handleActualEndingTimeChange(newValue ?? new Date());
                                }}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="filled-basic"
                        label="Početna kilometraža"
                        name="description"
                        value={formatNumberTypeInput(transportWarrant.startingKm)}
                        InputProps={{
                            inputProps: { min: 0 },
                        }}
                        type="number"
                        variant="outlined"
                        size="small"
                        onChange={handleStartingKmChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="filled-basic"
                        label="Završna kilometraža"
                        name="description"
                        value={formatNumberTypeInput(transportWarrant.endingKm)}
                        InputProps={{
                            inputProps: { min: 0 },
                        }}
                        type="number"
                        variant="outlined"
                        size="small"
                        onChange={handleEndingKmChange}
                    />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    {(enableFinishTw && (!dateTimeValidity() || !kmValidity())) || !enableFinishTw ? (
                        <Tooltip title={tooltipText}>
                            <div>
                                <SubmitButton
                                    btnStyle={{ color: 'primary', variant: 'contained', fullWidth: true }}
                                    funct={submit}
                                    shouldDisable
                                    buttonText="Sačuvaj novo vreme"
                                />
                            </div>
                        </Tooltip>
                    ) : (
                        <SubmitButton
                            btnStyle={{ color: 'primary', variant: 'contained', fullWidth: true }}
                            funct={submit}
                            useTimer={true}
                            disableDuration={1}
                            buttonText="Sačuvaj novo vreme"
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
