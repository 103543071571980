import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './Calendar.css';
import { addGraphDriver, fetchInitialDrivers } from '../General/Redux/Reducers/Drivers/DriversReducer';
import { fetchInitialTrucks, fetchInitialTrailers } from '../General/Redux/Reducers/Vehicles/VehiclesReducer';
import { fetchInitialWorkingMachines } from '../General/Redux/Reducers/WorkingTools/WorkingMachinesReducer';
import { LoaderProgress } from '../Common/CommonComponents/Loader/LoaderProgress';
import { RootState } from '../General/Redux/store';
import { useAppDispatch, useAppSelector } from '../General/hooks/hooks';
import TimelineView from './TimelineView/TimelineView';
import { fetchInitialShipments } from '../General/Redux/Reducers/Shipments/ShipmentReducer';
import { useSearchParams } from 'react-router-dom';
import TableView from './TableView/TableView';
import { getGraphDriverFromDriver } from '../General/Extensions/Selectors/Drivers/DriverSelector';
import RightSidebar from '../Common/CommonComponents/RightSidebar';
import ShipmentForm from '../General/Forms/ShipmentForm/ShipmentForm';
import TransportWarrantForm from '../General/Forms/TransportWarrantForm/TransportWarrantForm';
import ShipmentSideDetails from '../General/SideDetails/ShipmentSideDetails';
import ShipmentsUnderbar from './ShipmentsUnderbar/ShipmentsUnderbar';
import { CompareTwoDatesEquality, GetStartOfTheWeek, getTimeDifference } from '../Common/DateHandler';
import { CompositionDto, RequestOriginApp, ShipmentDto } from '../General/AutoGeneratedAPI/clientApi';
import { CalendarViewType, TimelineViewType } from '../General/Extensions/Extensions';
import { TWSearchTokenModel } from '../General/Extensions/models/RecordSearchTokenModel';
import { fetchInitialTransportWarrants } from '../General/Redux/Actions/Warrants/TransportWarrantAction';
import { resetShipment, setShipment } from '../General/Redux/Reducers/Shipments/ShipmentFormReducer';
import { setCurrentDate, setTimelineViewType } from '../General/Redux/Reducers/Warrants/CalendarReducer';
import {
    emptyUnselectedShipmentsForTw,
    emptyDriverAzureObjIdsForTw,
    emptyInitialShipmentsForTw,
    emptyInitialTWItemsForTw,
    setComposition,
    resetTransportWarrant,
    setTwDate,
    addSelectedShipmentForTw,
    unselectSelectedShipmentForTw,
    addInitialShipmentForTw,
    unselectPreselectedShipmentForTw,
    removeUnselectedShipmentForTw,
    addInitialStopForTw,
    addStopForTwArrangement,
    emptyInitialStopsForTw,
    emptyInitialStopsFromArrangement,
} from '../General/Redux/Reducers/Warrants/TransportWarrantFormReducer';
import { checkTransportWarrantsExistence } from './Helpers/TransportWarrantHelpers';
import ShipmentLoading from '../General/SideDetails/ShipmentLoading/ShipmentLoading';
import { addDays, addMinutes, addMonths, setDate } from 'date-fns';
import CalendarTWSideDetailsRightSidebar, {
    CalendarTWSideDetailsRightSidebarRefType,
} from './CalendarTWSideDetailsRightSidebar';

export enum ShipmentInfoOrigin {
    TwInfo,
    ShipmentUnderbar,
}

enum TwFormOrigin {
    ShipmentLoading,
    Other,
}

export default function Calendar(): ReactElement {
    const trucks = useAppSelector((state: RootState) => state.vehicles.trucks);
    const trailers = useAppSelector((state: RootState) => state.vehicles.trailers);
    const workingMachines = useAppSelector((state: RootState) => state.workingMachines.workingMachines);
    const drivers = useAppSelector((state: RootState) => state.drivers.drivers);
    const activeShipments = useAppSelector((state: RootState) => state.shipments.shipments);
    // const roles = useAppSelector((state: RootState) => state.loggedEmployee.employee?.roles);

    const currentDate = useAppSelector((state: RootState) => state.calendarData.currentDate);
    const timelineViewType = useAppSelector((state: RootState) => state.calendarData.timelineViewType);

    const transportWarrants = useAppSelector((state: RootState) => state.calendarData.transportWarrants);

    const areShipmentsChanged = useAppSelector((state: RootState) => state.twFormData.areStopsChanged);

    // const isTransOp = roles?.find((r) => r === 'Transport_Operator') !== undefined ? true : false;
    const [currTwId, setCurrTwId] = useState<number>(0);
    const [openShipmentForm, setOpenShipmentForm] = useState<boolean>(false);
    const [showShipmentInfo, setShowShipmentInfo] = useState<boolean>(false);
    const [shipmentUpdateAllowed, setShipmentUpdateAllowed] = useState<boolean>(true);
    const [shipmentInfoOrigin, setShipmentInfoOrigin] = useState<ShipmentInfoOrigin>(ShipmentInfoOrigin.TwInfo);
    const [twFormOrigin, setTwFormOrigin] = useState<TwFormOrigin>(TwFormOrigin.Other);
    const [updateFormTW, setUpdateFormTW] = useState<number | undefined>(undefined);
    const [openTransportWarrantForm, setOpenTransportWarrantForm] = useState<boolean>(false);
    const [openShipmentLoading, setOpenShipmentLoading] = useState<boolean>(false);
    const [query, setQuery] = useState<TWSearchTokenModel>({
        clientId: 0,
        startingDate:
            timelineViewType === TimelineViewType.Day
                ? new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
                : GetStartOfTheWeek(currentDate),
        endingDate:
            timelineViewType === TimelineViewType.Day
                ? addDays(currentDate, 1)
                : addDays(GetStartOfTheWeek(currentDate), 7),
        lastEntityId: 0,
    });

    const Ref_CalendarTWSideDetailsRightSidebar = useRef<CalendarTWSideDetailsRightSidebarRefType>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useAppDispatch();

    const calendarViewType = searchParams.get('calendarViewType');

    useEffect(() => {
        if (!trucks) {
            dispatch(fetchInitialTrucks());
        }
        if (!trailers) {
            dispatch(fetchInitialTrailers());
        }
        if (!drivers) {
            dispatch(fetchInitialDrivers());
        }
        if (!workingMachines) {
            dispatch(fetchInitialWorkingMachines());
        }
        if (!activeShipments) {
            dispatch(fetchInitialShipments());
        }
    }, []);

    useEffect(() => {
        if (drivers) {
            drivers.map((drv) => {
                if (drv !== undefined && drv.azObjId && drv.azObjId !== '') {
                    getGraphDriverFromDriver(drv).then((res) => {
                        dispatch(addGraphDriver(res));
                    });
                }
            });
        }
    }, [drivers]);

    useEffect(() => {
        const {
            exists: existsStartingDate,
            startingDate: strDateStart,
            endingDate: strDateEnd,
        } = checkTransportWarrantsExistence(transportWarrants ?? [], query.startingDate ?? new Date());

        const {
            exists: existsEndingDate,
            startingDate: endDateStart,
            endingDate: endDateEnd,
        } = checkTransportWarrantsExistence(transportWarrants ?? [], query.endingDate ?? new Date());

        if (!existsStartingDate && !existsEndingDate) {
            dispatch(fetchInitialTransportWarrants(strDateStart ?? new Date(), endDateEnd ?? new Date(), true));
        } else if (!existsStartingDate) {
            dispatch(fetchInitialTransportWarrants(strDateStart ?? new Date(), strDateEnd ?? new Date(), true));
        } else if (!existsEndingDate) {
            dispatch(fetchInitialTransportWarrants(endDateStart ?? new Date(), endDateEnd ?? new Date(), true));
        }
    }, [query]);

    useEffect(() => {
        if (!openTransportWarrantForm) {
            dispatch(emptyUnselectedShipmentsForTw());
            setUpdateFormTW(undefined);
        }
    }, [openTransportWarrantForm]);

    useEffect(() => {
        if (!openShipmentLoading) {
            dispatch(unselectSelectedShipmentForTw());
        }
    }, []);

    const changeSearchTwId = (twId: number) => {
        const spCurrentDate = searchParams.get('currentDate');
        const spTimelineViewType = searchParams.get('timelineViewType');
        const spCalendarViewType = searchParams.get('calendarViewType');

        const search = {
            currentDate: spCurrentDate ?? '',
            timelineViewType: spTimelineViewType ?? '',
            calendarViewType: spCalendarViewType ?? '',
            twId: twId.toString(),
        };

        setSearchParams(search, { replace: true });
    };

    const setInitialQuery = (date: Date) => {
        if (calendarViewType === CalendarViewType.TimelineView) {
            const strOfTheWeek = GetStartOfTheWeek(date);
            const endOfTheWeek = addDays(strOfTheWeek, 7);

            const strDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            const endDate = addDays(strDate, 1);

            const spTimelineViewType = searchParams.get('timelineViewType');

            if (spTimelineViewType) {
                setQuery({
                    clientId: 0,
                    startingDate: spTimelineViewType === TimelineViewType.Day ? strDate : strOfTheWeek,
                    endingDate: spTimelineViewType === TimelineViewType.Day ? endDate : endOfTheWeek,
                    lastEntityId: 0,
                });
            }
        } else if (calendarViewType === CalendarViewType.TableView) {
            const startOfTheMonth = setDate(date, 1);
            const endOfTheMonth = addMonths(startOfTheMonth, 1);
            setQuery({
                clientId: 0,
                startingDate: startOfTheMonth,
                endingDate: endOfTheMonth,
                lastEntityId: 0,
            });
        }
    };

    useEffect(() => {
        if (searchParams.get('currentDate')) {
            const searchParamsCurrDate = searchParams.get('currentDate');
            const spTimelineViewType = searchParams.get('timelineViewType');
            const spTwId = searchParams.get('twId');

            if (searchParamsCurrDate && new Date(searchParamsCurrDate).toString() !== 'Invalid Date') {
                const spDate = new Date(searchParamsCurrDate);
                if (!CompareTwoDatesEquality(spDate, currentDate)) {
                    dispatch(setCurrentDate(spDate));
                    setInitialQuery(spDate);
                }
            }

            if (spTimelineViewType) {
                if (spTimelineViewType !== timelineViewType) {
                    dispatch(setTimelineViewType(spTimelineViewType));
                }
            }

            if (spTwId && !isNaN(+spTwId) && +spTwId > 0) {
                if (+spTwId !== updateFormTW) {
                    handleShowTwInfo();
                }
            }
        } else {
            const currDate = new Date();
            const tlvType = TimelineViewType.Day;
            const twi = 0;
            const cViewType = CalendarViewType.TimelineView;

            const search = {
                currentDate: currDate.toString(),
                timelineViewType: tlvType.toString(),
                twId: twi.toString(),
                calendarViewType: cViewType,
            };

            setSearchParams(search, { replace: true });
        }
    }, [searchParams]);

    const emptyTwFromRedux = useCallback((): void => {
        dispatch(emptyDriverAzureObjIdsForTw());
        dispatch(emptyInitialShipmentsForTw());
        dispatch(emptyInitialTWItemsForTw());
        dispatch(emptyInitialStopsForTw());
        dispatch(emptyInitialStopsFromArrangement());
        dispatch(setComposition(new CompositionDto({ ...new CompositionDto(), trailersIds: [] })));
        dispatch(resetTransportWarrant());
        dispatch(setTwDate(new Date()));
    }, []);

    const emptyShipmentFormRedux = (): void => {
        dispatch(resetShipment());
    };

    const handleCloseTwSideDetails = (): void => {
        if (Ref_CalendarTWSideDetailsRightSidebar?.current) {
            Ref_CalendarTWSideDetailsRightSidebar.current.handleToggleTwInfo(false);
        }
    };

    const handleOpenTwSideDetails = (): void => {
        if (Ref_CalendarTWSideDetailsRightSidebar?.current) {
            Ref_CalendarTWSideDetailsRightSidebar.current.handleToggleTwInfo(true);
        }
    };

    const openSaveUnsavedShipmentsChangesAlertForShipmentForm = (): void => {
        if (Ref_CalendarTWSideDetailsRightSidebar?.current) {
            if (areShipmentsChanged) {
                Ref_CalendarTWSideDetailsRightSidebar.current.openSaveUnsavedShipmentsChangesAlertForShipmentForm(
                    false,
                    true,
                );
            } else {
                handleOpenShipmentForm();
            }
        } else {
            handleOpenShipmentForm();
        }
    };

    const handleCloseShipmentForm = (): void => {
        setCurrTwId(0);
        emptyShipmentFormRedux();
        setOpenShipmentForm(false);
    };

    const handleCloseShipmentFormAndLoadShipment = (): void => {
        setCurrTwId(0);
        emptyShipmentFormRedux();
        setOpenShipmentForm(false);
        setOpenShipmentLoading(true);
    };

    const handleCloseShipmentFormAndOpenTwSideDetails = (): void => {
        if (currTwId > 0) {
            changeSearchTwId(currTwId);
            handleShowTwInfo();
        }
        setCurrTwId(0);
        emptyShipmentFormRedux();
        setOpenShipmentForm(false);
    };

    const handleSelectShipmentForUpdating = (shipment: ShipmentDto): void => {
        dispatch(setShipment(shipment));
        setShipmentUpdateAllowed(true);
        setShowShipmentInfo(true);
        setShipmentInfoOrigin(ShipmentInfoOrigin.ShipmentUnderbar);
        setOpenShipmentForm(false);
        setOpenTransportWarrantForm(false);
        // dispatch(unselectSelectedShipmentForTw());
    };

    const handleUnshowShipmentInfo = (): void => {
        setShowShipmentInfo(false);
        emptyShipmentFormRedux();
    };

    const handleShowShipmentInfoFromTWInfo = (shipment: ShipmentDto, isTwFinished: boolean): void => {
        dispatch(setShipment(shipment));
        setShipmentUpdateAllowed(!isTwFinished);
        setShowShipmentInfo(true);
        setShipmentInfoOrigin(ShipmentInfoOrigin.TwInfo);
    };

    const changeSearchDateAndTwId = (currDate: Date, twId: number) => {
        const spTimelineViewType = searchParams.get('timelineViewType');
        const spCalendarViewType = searchParams.get('calendarViewType');

        const search = {
            currentDate: currDate.toString(),
            twId: twId.toString(),
            timelineViewType: spTimelineViewType ?? '',
            calendarViewType: spCalendarViewType ?? '',
        };

        setSearchParams(search, { replace: true });
    };

    const handleCloseTransportWarrantForm = (twDate?: Date, twId?: number): void => {
        if (twDate && twId) {
            changeSearchDateAndTwId(twDate, twId);
        }
        emptyTwFromRedux();
        setUpdateFormTW(undefined);
        setOpenTransportWarrantForm(false);
    };

    const handleCloseTransportWarrantFormOnClick = (): void => {
        emptyTwFromRedux();
        setUpdateFormTW(undefined);
        setOpenTransportWarrantForm(false);
        dispatch(unselectSelectedShipmentForTw());
    };

    const handleOpenShipFormWithShipmentsCheck = (): void => {
        openSaveUnsavedShipmentsChangesAlertForShipmentForm();
    };

    const handleOpenShipmentForm = (): void => {
        const currTwId = searchParams.get('twId');
        if (currTwId && !isNaN(+currTwId) && +currTwId > 0) {
            setCurrTwId(+currTwId);
        }
        emptyTwFromRedux();
        setShowShipmentInfo(false);
        setOpenTransportWarrantForm(false);
        dispatch(emptyUnselectedShipmentsForTw());
        changeSearchTwId(0);
        // dispatch(unselectSelectedShipmentForTw());
        handleCloseTwSideDetails();
        setOpenShipmentForm(true);
    };

    const handleShowTwInfo = (): void => {
        handleOpenTwSideDetails();
        setShowShipmentInfo(false);
        setOpenTransportWarrantForm(false);
        setOpenShipmentForm(false);
    };

    const handleOpenTransportWarrantForm = useCallback((): void => {
        changeSearchTwId(0);
        setTwFormOrigin(TwFormOrigin.Other);
        handleCloseTwSideDetails();
        dispatch(emptyUnselectedShipmentsForTw());
        dispatch(unselectSelectedShipmentForTw());
        setOpenTransportWarrantForm(true);
    }, [searchParams]);

    const handleOpenTransportWarrantFormFromShipmentLoading = (): void => {
        setTwFormOrigin(TwFormOrigin.ShipmentLoading);
        setOpenTransportWarrantForm(true);
        setOpenShipmentLoading(false);
        dispatch(emptyUnselectedShipmentsForTw());
        changeSearchTwId(0);
    };

    const handleSelectTWForUpdating = (twId: number): void => {
        changeSearchTwId(twId);
        setOpenTransportWarrantForm(false);
        setOpenShipmentForm(false);
        setOpenShipmentLoading(false);
    };

    const handleSelectShipmentForLoading = (shipment: ShipmentDto, twStartingTime: Date) => {
        const spTwId = searchParams.get('twId');
        const isTWInfoOpened = !(!spTwId || isNaN(+spTwId) || +spTwId === 0);
        if (shipment) {
            const newShip = shipment;
            if (shipment.requestOriginApp !== RequestOriginApp.Transport) {
                const shipmentEndingTime = twStartingTime;

                const shipmentDurationTimeSpan = getTimeDifference(
                    shipment.startingStop?.endingTime,
                    shipment.startingStop?.startingTime,
                );
                const shipmentDurationInMinutes =
                    shipmentDurationTimeSpan.hours * 60 + shipmentDurationTimeSpan.minutes;

                const shipmentStartingTime = addMinutes(shipmentEndingTime, 0 - shipmentDurationInMinutes);

                newShip.startingStop.startingTime = shipmentStartingTime;
                newShip.startingStop.endingTime = shipmentEndingTime;
            }

            if (isTWInfoOpened) {
                // This is happening if we are loading shipment on opened transport warrant
                dispatch(addInitialShipmentForTw(newShip));
                dispatch(removeUnselectedShipmentForTw(newShip.id));
                dispatch(unselectPreselectedShipmentForTw());

                if (newShip.requestOriginApp === RequestOriginApp.Transport) {
                    dispatch(addInitialStopForTw(newShip.startingStop));
                    dispatch(addStopForTwArrangement(newShip.startingStopId));
                    dispatch(addInitialStopForTw(newShip.endingStop));
                    dispatch(addStopForTwArrangement(newShip.endingStopId));
                } else {
                    dispatch(addInitialStopForTw(newShip.endingStop));
                    dispatch(addStopForTwArrangement(newShip.endingStopId));
                }
            } else {
                // This is happening if we are looking for a tw to load the shipment on
                setOpenShipmentLoading(true);
                dispatch(addSelectedShipmentForTw(newShip));
                dispatch(unselectPreselectedShipmentForTw());

                // close shipment
                handleUnshowShipmentInfo();
                setOpenShipmentForm(false);

                // close tw
                setOpenTransportWarrantForm(false);
                emptyTwFromRedux();
            }
        }
    };

    const closeAndClearShipmentLoading = () => {
        setOpenShipmentLoading(false);
        dispatch(unselectSelectedShipmentForTw());
    };

    const NewElement = useMemo(() => {
        if (!trucks || !trailers || !drivers || !workingMachines) {
            return (
                <div style={{ height: 600, width: 600, margin: 'auto' }}>
                    <LoaderProgress />
                </div>
            );
        } else {
            if (searchParams.get('calendarViewType') === CalendarViewType.TimelineView) {
                return (
                    <TimelineView
                        handleOpenShipmentForm={handleOpenShipmentForm}
                        handleSelectTWForUpdating={handleSelectTWForUpdating}
                        handleOpenTWForm={handleOpenTransportWarrantForm}
                        query={query}
                    />
                );
            } else if (searchParams.get('calendarViewType') === CalendarViewType.TableView) {
                return <TableView handleSelectTWForUpdating={handleSelectTWForUpdating} />;
            } else {
                return <>Greška</>;
            }
        }
    }, [
        query,
        searchParams,
        trucks,
        trailers,
        drivers,
        workingMachines,
        transportWarrants,
        currentDate,
        timelineViewType,
    ]);

    return (
        <div
            style={{
                marginTop: 67,
                height: 'calc(100vh - 67px)',
                width: '100vw',
            }}
        >
            {NewElement}
            {/** Shipments underbar */}
            <ShipmentsUnderbar
                isFullWidth={!(openShipmentForm || openTransportWarrantForm)}
                selectShipmentForUpdating={handleSelectShipmentForUpdating}
                selectShipmentForLoading={handleSelectShipmentForLoading}
            />
            <CalendarTWSideDetailsRightSidebar
                ref={Ref_CalendarTWSideDetailsRightSidebar}
                changeSearchTwId={changeSearchTwId}
                emptyTwFromRedux={emptyTwFromRedux}
                handleOpenTransportWarrantForm={handleOpenTransportWarrantForm}
                handleShowShipmentInfoFromTWInfo={handleShowShipmentInfoFromTWInfo}
                handleOpenShipmentForm={handleOpenShipmentForm}
            />
            <RightSidebar open={openShipmentLoading} width={'100vw'} defaultheight={'calc(100% - 146px)'}>
                <ShipmentLoading
                    close={closeAndClearShipmentLoading}
                    selectTwForLoading={handleSelectTWForUpdating}
                    handleOpenTransportWarrantForm={handleOpenTransportWarrantFormFromShipmentLoading}
                />
            </RightSidebar>

            {/** Tw Form */}
            <RightSidebar open={openTransportWarrantForm}>
                <TransportWarrantForm
                    closeForm={handleCloseTransportWarrantForm}
                    closeFormOnClick={handleCloseTransportWarrantFormOnClick}
                    isOriginShipmentLoadingWindow={twFormOrigin === TwFormOrigin.ShipmentLoading}
                />
            </RightSidebar>
            <RightSidebar open={showShipmentInfo}>
                <ShipmentSideDetails
                    isDeletionAllowed={shipmentInfoOrigin === ShipmentInfoOrigin.ShipmentUnderbar}
                    closeShipmentSideDetails={handleUnshowShipmentInfo}
                    allowShipmentUpdate={shipmentUpdateAllowed}
                    openShipmentForm={handleOpenShipFormWithShipmentsCheck}
                />
            </RightSidebar>
            {/** Shipment Form */}
            <RightSidebar open={openShipmentForm}>
                <ShipmentForm
                    closeForm={handleCloseShipmentForm}
                    closeFormAndLoadShipment={handleCloseShipmentFormAndLoadShipment}
                    closeFormAndOpenTwSideDetails={handleCloseShipmentFormAndOpenTwSideDetails}
                />
            </RightSidebar>
        </div>
    );
}
