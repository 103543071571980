import { Grid, Typography } from '@mui/material';
import React, {
    ReactElement,
    Ref,
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
} from 'react';
import { RequestOriginApp, ShipmentDto, StopDto } from '../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import {
    addInitialShipmentsForTw,
    setComposition,
    setTransportWarrant,
    addDriverAzureObjIdForTw,
    setTwDate,
    emptyDriverAzureObjIdsForTw,
    unselectSelectedShipmentForTw,
    setUnloadingTime,
    addInitialStopsForTw,
    addStopsArrangementForTw,
    addInitialTWItemsForTw,
} from '../../Redux/Reducers/Warrants/TransportWarrantFormReducer';
import { RootState } from '../../Redux/store';
import { useSearchParams } from 'react-router-dom';
import ShipmentsDND from './ShipmentsDND/ShipmentsDND';
import DirectionsGoogleMaps from '../../../Common/CommonComponents/GoogleMaps/DirectionsGoogleMaps';
import TransportWarrantSideDetailsElement from './TransportWarrantsSideDetailsElement';
import { CompareTwoDateTimes, FormatDateText, getTimeDifference } from '../../../Common/DateHandler';
import TransportWarrantTimelineView from './TransportWarrantTimelineView/TransportWarrantTimelineView';
import {
    CalendarTWSideDetailsCloseBtn,
    CalendarTWSideDetailsContainer,
} from './CalendarTransportWarrantSideDetailsStyled';
import cloneDeep from 'lodash.clonedeep';
import { addSeconds } from 'date-fns';
import { LoaderProgress } from '../../../Common/CommonComponents/Loader/LoaderProgress';
import SaveUnsavedShipmentsChangesAlertModal, {
    SaveUnsavedShipmentsChangesAlertModalRefType,
} from './SaveUnsavedShipmentsChangesAlertModal';
import { rearrangeStopsByScheme } from './ShipmentsDND/ShipmentsDNDHelper';

export interface CalendarTWSideDetailsRefType {
    openSaveUnsavedShipmentsChangesAlertForShipmentForm: (openTwForm: boolean, openShipForm: boolean) => void;
}

type CalendarTransportWarrantSideDetailsProps = {
    closeTWSideDetails(): void;
    openTwForm(): void;
    openShipmentForm(): void;
    openShipmentSideDetails(shipment: ShipmentDto, isTwFinished: boolean): void;
};

const frComponent = forwardRef(function CalendarTransportWarrantSideDetails(
    {
        closeTWSideDetails,
        openTwForm,
        openShipmentSideDetails,
        openShipmentForm,
    }: CalendarTransportWarrantSideDetailsProps,
    ref: Ref<CalendarTWSideDetailsRefType>,
): ReactElement {
    const composition = useAppSelector((state: RootState) => state.twFormData.composition);
    const transportWarrant = useAppSelector((state: RootState) => state.twFormData.transportWarrant);
    const selectedShipment = useAppSelector((state: RootState) => state.twFormData.selectedShipmentForTW);
    const currShipments = useAppSelector((state: RootState) => state.twFormData.currentTwInitialShipments);
    const currStops = useAppSelector((state: RootState) => state.twFormData.currentTwInitialStops);
    const currStopsArrangement = useAppSelector((state: RootState) => state.twFormData.currentTwStopsArrangement);
    const predefinedUnloadingTime = useAppSelector((state: RootState) => state.twFormData.unloadingTime);
    const stopsDurations = useAppSelector((state: RootState) => state.twFormData.stopsDurations);

    const areShipmentsChanged = useAppSelector((state: RootState) => state.twFormData.areStopsChanged);

    const currentTwDate = useAppSelector((state: RootState) => state.twFormData.twDate);

    const googleMapsApiKey = useAppSelector((state: RootState) => state.loggedEmployee.googleApiKey);

    const [searchParams] = useSearchParams();

    const Ref_SaveUnsavedShipmentsChangesAlertModal = useRef<SaveUnsavedShipmentsChangesAlertModalRefType>(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                openSaveUnsavedShipmentsChangesAlertForShipmentForm(openTwForm: boolean, openShipForm: boolean) {
                    handleOpenSaveUnsavedShipmentsChangesAlertForShipmentForm(openTwForm, openShipForm);
                },
            };
        },
        [],
    );

    const dispatch = useAppDispatch();

    /**
     * Set basic times for transport warrant if this is the first shipment loaded on tw
     */
    useEffect(() => {
        if (
            predefinedUnloadingTime !== undefined &&
            (stopsDurations.length === 2 || stopsDurations.length === 3) &&
            currShipments.length === 1
        ) {
            const loadingDurationTimeSpan = getTimeDifference(
                currShipments[0].startingStop.endingTime,
                currShipments[0].startingStop.startingTime,
            );
            const loadingDurationInSeconds =
                (loadingDurationTimeSpan.hours * 60 + loadingDurationTimeSpan.minutes) * 60;

            const transportDurationBeforeUnload =
                Math.round(stopsDurations[0].value) +
                (stopsDurations.length === 2 ? 0 : Math.round(stopsDurations[1].value) + loadingDurationInSeconds);

            const estimatedStartingTime = addSeconds(predefinedUnloadingTime, 0 - transportDurationBeforeUnload);

            const unloadingDurationTimeSpan = getTimeDifference(
                currShipments[0].endingStop.endingTime,
                currShipments[0].endingStop.startingTime,
            );
            const unloadingDurationInSeconds =
                (unloadingDurationTimeSpan.hours * 60 + unloadingDurationTimeSpan.minutes) * 60;

            const transportDurationAfterUnload =
                (stopsDurations.length === 2
                    ? Math.round(stopsDurations[1].value)
                    : Math.round(stopsDurations[2].value)) + unloadingDurationInSeconds;

            const estimatedEndingTime = addSeconds(predefinedUnloadingTime, transportDurationAfterUnload);

            dispatch(
                setTransportWarrant({
                    ...transportWarrant,
                    estStartingTime: estimatedStartingTime,
                    actStartingTime: estimatedStartingTime,
                    estEndingTime: estimatedEndingTime,
                    actEndingTime: estimatedEndingTime,
                }),
            );

            dispatch(setUnloadingTime(undefined));
        }
    }, [stopsDurations]);

    useEffect(() => {
        dispatch(emptyDriverAzureObjIdsForTw());

        const currentTw = searchParams.get('twId');

        if (currentTw && !isNaN(+currentTw)) {
            const currentTransportWarrant = +currentTw;

            if (currentTransportWarrant > 0) {
                GranitClient.getTransportWarrant(currentTransportWarrant, true).then((transWarr) => {
                    dispatch(setTransportWarrant(transWarr));
                    dispatch(setTwDate(new Date(transWarr.actStartingTime)));
                });
            } else {
                dispatch(
                    setComposition({
                        ...composition,
                        trailersIds: [],
                        workingMachinesIds: [],
                    }),
                );
            }
        }
    }, [searchParams.get('twId')]);

    useEffect(() => {
        if (transportWarrant.id > 0) {
            if (transportWarrant.compositionId > 0) {
                GranitClient.getComposition(transportWarrant.compositionId).then((res) => {
                    dispatch(setComposition(res));
                });
            } else {
                const deepClonedComposition = cloneDeep(transportWarrant.composition);
                dispatch(setComposition(deepClonedComposition));
            }
        }
    }, [transportWarrant]);

    useEffect(() => {
        if (transportWarrant.id > 0) {
            if (
                transportWarrant.driverJobPositionIds !== undefined &&
                transportWarrant.driverJobPositionIds.length > 0
            ) {
                transportWarrant.driverJobPositionIds.map((djpId) => {
                    GranitClient.getDriverByJobPositionId(djpId).then((res) => {
                        if (res.azObjId) {
                            dispatch(addDriverAzureObjIdForTw(res.azObjId));
                        }
                    });
                });
            }
        }
    }, [transportWarrant]);

    useEffect(() => {
        if (transportWarrant.id > 0) {
            GranitClient.getTransportWarrantItems(transportWarrant.id).then((res) => {
                if (res.length > 0) {
                    const resDeepCopy = cloneDeep(res);

                    const ships = resDeepCopy.map((r) => r.shipment);

                    const stops: StopDto[] = [];

                    ships.map((ship) => {
                        if (ship.requestOriginApp === RequestOriginApp.Transport) {
                            stops.push(ship.startingStop);
                        }
                        stops.push(ship.endingStop);
                    });

                    const stopsIds = stops
                        .slice()
                        .sort((a, b) => {
                            const aStartingTime = a.startingTime;
                            const bStartingTime = b.startingTime;

                            if (!aStartingTime || !bStartingTime) return -1;

                            return CompareTwoDateTimes(aStartingTime, bStartingTime, true) ? -1 : 1;
                        })
                        .map((r) => r.id);

                    if (selectedShipment) {
                        if (selectedShipment.requestOriginApp === RequestOriginApp.Transport) {
                            stops.push(selectedShipment.startingStop);
                            stopsIds.push(selectedShipment.startingStopId);
                        }

                        stops.push(selectedShipment.endingStop);
                        stopsIds.push(selectedShipment.endingStopId);
                    }

                    const newCurrStops = rearrangeStopsByScheme(stops, stopsIds);

                    dispatch(addInitialStopsForTw(newCurrStops));

                    dispatch(addStopsArrangementForTw(stopsIds));
                } else {
                    if (selectedShipment) {
                        const stops: StopDto[] = [];
                        const stopsIds: number[] = [];

                        if (selectedShipment.requestOriginApp === RequestOriginApp.Transport) {
                            stops.push(selectedShipment.startingStop);
                            stopsIds.push(selectedShipment.startingStopId);
                        }

                        stops.push(selectedShipment.endingStop);
                        stopsIds.push(selectedShipment.endingStopId);

                        dispatch(addInitialStopsForTw(stops));
                        dispatch(addStopsArrangementForTw(stopsIds));
                    }
                }
            });

            GranitClient.getTransportWarrantItems(transportWarrant.id).then((res) => {
                if (res.length > 0) {
                    const resDeepCopy = cloneDeep(res);

                    const ships = resDeepCopy.map((r) => r.shipment);

                    if (selectedShipment) {
                        ships.push(selectedShipment);
                    }

                    dispatch(addInitialShipmentsForTw(ships));
                    dispatch(unselectSelectedShipmentForTw());
                } else {
                    if (selectedShipment) {
                        const ships = [selectedShipment];
                        dispatch(addInitialShipmentsForTw(ships));
                        dispatch(unselectSelectedShipmentForTw());
                    }
                }
            });

            GranitClient.getTransportWarrantItems(transportWarrant.id).then((res) => {
                if (res.length > 0) {
                    dispatch(addInitialTWItemsForTw(res ?? []));
                }
            });
        }
    }, [transportWarrant]);

    const handleOpenSaveUnsavedShipmentsChangesAlert = (openTwForm: boolean, openShipForm: boolean): void => {
        if (Ref_SaveUnsavedShipmentsChangesAlertModal?.current) {
            Ref_SaveUnsavedShipmentsChangesAlertModal.current.handleOpenSaveUnsavedShipmentsChangesAlert(
                openTwForm,
                openShipForm,
            );
        }
    };

    const handleOpenSaveUnsavedShipmentsChangesAlertForShipmentForm = (
        openTwForm: boolean,
        openShipForm: boolean,
    ): void => {
        handleOpenSaveUnsavedShipmentsChangesAlert(openTwForm, openShipForm);
    };

    const handleOpenShipmentSideDetails = useCallback(
        (ship?: ShipmentDto) => {
            if (ship) {
                openShipmentSideDetails(ship, transportWarrant.isTwFinished);
            }
        },
        [transportWarrant.isTwFinished],
    );

    const handleCloseTWSideDetails = () => {
        if (areShipmentsChanged && !transportWarrant.isTwFinished) {
            handleOpenSaveUnsavedShipmentsChangesAlert(false, false);
        } else {
            closeTWSideDetails();
        }
    };

    const handleOpenTWForm = useCallback(() => {
        if (areShipmentsChanged && !transportWarrant.isTwFinished) {
            handleOpenSaveUnsavedShipmentsChangesAlert(true, false);
        } else {
            openTwForm();
        }
    }, [areShipmentsChanged, transportWarrant.isTwFinished]);

    const twTimelineViewElement = useMemo(() => {
        if (
            transportWarrant.id > 0 &&
            (stopsDurations.length === 0 || stopsDurations.length === currStops.length + 1) &&
            currStopsArrangement.length === currStops.length
        ) {
            return <TransportWarrantTimelineView />;
        }

        return (
            <div style={{ height: 100, width: 100, margin: 'auto' }}>
                <LoaderProgress />
            </div>
        );
    }, [currStops, transportWarrant, currStopsArrangement, stopsDurations]);

    const directionsGoogleMapElement = useMemo(() => {
        if (currStopsArrangement.length === currStops.length && googleMapsApiKey && googleMapsApiKey !== '') {
            return (
                <DirectionsGoogleMaps
                    apiKey={googleMapsApiKey}
                    mapDimensions={{ height: 500, width: (900 / 12) * 9 }}
                />
            );
        }

        return (
            <div style={{ width: 500, height: 500, margin: 'auto' }}>
                <LoaderProgress />
            </div>
        );
    }, [currStops, currStopsArrangement, googleMapsApiKey]);

    const shipmentsDNDElement = () => {
        if (
            currStopsArrangement.length === currStops.length &&
            transportWarrant.id > 0 &&
            (stopsDurations.length === currStops.length + 1 || stopsDurations.length === 0)
        ) {
            return <ShipmentsDND openShipmentSideDetails={handleOpenShipmentSideDetails} />;
        }

        return (
            <div style={{ width: 200, height: 200, margin: 'auto' }}>
                <LoaderProgress />
            </div>
        );
    };

    const twSideDetailsElement = useMemo(() => {
        if (transportWarrant.id > 0 && composition.id > 0) {
            return (
                <TransportWarrantSideDetailsElement
                    closeTWSideDetails={closeTWSideDetails}
                    openTwForm={handleOpenTWForm}
                    enableFinishTW={!areShipmentsChanged}
                />
            );
        }

        return (
            <div style={{ width: 350, height: 350, margin: 'auto' }}>
                <LoaderProgress />
            </div>
        );
    }, [transportWarrant, composition, currStops, handleOpenTWForm, closeTWSideDetails, areShipmentsChanged]);

    const shipmentsMapSection = (): ReactElement => {
        return (
            <div style={{ flexGrow: 10 }}>
                <Grid container spacing={1} style={{ height: '100%' }}>
                    <Grid item xs style={{ height: '100%', width: 500 }}>
                        {twTimelineViewElement}
                        {directionsGoogleMapElement}
                    </Grid>
                    <Grid item xs="auto" style={{ height: '100%', minWidth: 250 }}>
                        {shipmentsDNDElement()}
                    </Grid>
                </Grid>
            </div>
        );
    };

    return (
        <main style={{ width: '100%', height: 'calc(100vh - 68px)' }}>
            <CalendarTWSideDetailsCloseBtn onClick={handleCloseTWSideDetails}>{'>'}</CalendarTWSideDetailsCloseBtn>
            <div style={{ position: 'absolute', top: 10, right: 15 }}>
                <Typography gutterBottom variant="body1" component="p" style={{ fontSize: 18 }}>
                    {currentTwDate.toLocaleDateString('sr-SR', { weekday: 'short' })} {FormatDateText(currentTwDate)}
                </Typography>
            </div>
            <CalendarTWSideDetailsContainer>
                {twSideDetailsElement}
                {shipmentsMapSection()}
            </CalendarTWSideDetailsContainer>
            <SaveUnsavedShipmentsChangesAlertModal
                ref={Ref_SaveUnsavedShipmentsChangesAlertModal}
                openShipmentForm={openShipmentForm}
                openTwForm={openTwForm}
                closeTWSideDetails={closeTWSideDetails}
            />
        </main>
    );
});

export default memo(frComponent);
