import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { concatInvoices, setInitialInvoices } from '../../../Redux/Reducers/Invoice/InvoiceReducer';
import { InvoiceSearchTokenModel } from '../../models/RecordSearchTokenModel';
import { fetchVehicleInvoices } from './API';

type InvoiceSearchType = {
    loadingInvoices: boolean;
    hasMoreInvoices: boolean;
    invoiceError: boolean;
};

export default function useInvoicesSearch(clientId: number, query: InvoiceSearchTokenModel): InvoiceSearchType {
    const [loadingInvoices, setLoadingInvoices] = useState(true);
    const [invoiceError, setInvoiceError] = useState(false);
    const [hasMoreInvoices, setHasMoreInvoices] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setLoadingInvoices(true);
        setInvoiceError(false);
        fetchVehicleInvoices(clientId, query).then(
            (response) => {
                dispatch(concatInvoices(response));
                setHasMoreInvoices(response.length > 0);
                setLoadingInvoices(false);
            },
            (error) => {
                setInvoiceError(true);
                console.log(error.message);
            },
        );
    }, [query]);

    useEffect(() => {
        dispatch(setInitialInvoices([]));
    }, [query.startingDate, query.searchText, query.numberOfEntitiesToReturn, query.endingDate]);

    return { loadingInvoices, hasMoreInvoices, invoiceError };
}
