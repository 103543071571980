import React, { ReactElement, useState, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ExpenseCategory, SumType } from '../General/AutoGeneratedAPI/clientApi';
import { GranitClient } from '../General/AutoGeneratedAPI/Extension';
import ChartSearch from './ChartSearch';
import { SelectChangeEvent } from '@mui/material';
import { ALL_EXP_TYPES } from '../Common/Consts';
import { ServerResponseStatus } from '../General/Extensions/Extensions';
import { LoaderProgress } from '../Common/CommonComponents/Loader/LoaderProgress';
import { Data, DataSet, options } from './ChartsHelper';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../General/hooks/hooks';
import { RootState } from '../General/Redux/store';
import AverageConsumption from './AverageConsumption';
import { CompareTwoDates, CompareTwoDatesWithoutDayEquality } from '../Common/DateHandler';
import { clone } from 'lodash';

Chart.register(...registerables);

type AllExpensesChartProps = {
    endDate: string;
    startDate: string;
};

export default function AllExpensesChart({ endDate, startDate }: AllExpensesChartProps): ReactElement {
    const expenses = useAppSelector((state: RootState) => state.expenses.expenses);

    const [data, setData] = useState<Data>({ labels: [], datasets: [] });
    const [fullData, setFullData] = useState<Data>({ labels: [], datasets: [] });
    const [averageExpenseForPeriod, setAverageExpenseForPeriod] = useState<number>(0);
    const [expenseType, setExpenseType] = useState<ExpenseCategory | null>(null);
    const [allExpensesLoadingStatus, setAllExpensesLoadingStatus] = useState<ServerResponseStatus>(
        ServerResponseStatus.Success,
    );
    const [lastVehId, setLastVehId] = useState<number>(0);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const vehId = searchParams.get('vehicleId');
        if (vehId && !isNaN(+vehId) && +vehId > 0) {
            const vehicleId = +vehId;
            if (
                fullData.datasets.length <= 0 ||
                !fullData.datasets[0] ||
                !fullData.datasets[0].dates ||
                fullData.datasets[0].dates.length !== 12 ||
                (CompareTwoDates(new Date(startDate), fullData.datasets[0].dates[0]) &&
                    CompareTwoDates(
                        new Date(new Date(endDate).getFullYear(), new Date(endDate).getMonth(), 1),
                        fullData.datasets[0].dates[11],
                    )) ||
                CompareTwoDates(
                    fullData.datasets[0].dates[11],
                    new Date(new Date(endDate).getFullYear(), new Date(endDate).getMonth(), 1),
                ) ||
                lastVehId !== +vehId
            ) {
                setAllExpensesLoadingStatus(ServerResponseStatus.Pending);
                setLastVehId(+vehId);
                GranitClient.getTruckPricePerKm(
                    vehicleId,
                    SumType.Monthly,
                    new Date(endDate),
                    expenseType ?? undefined,
                ).then((res) => {
                    let lbls: string[] = [];
                    let currData: number[] = [];
                    let currDates: Date[] = [];

                    res.forEach((sum) => {
                        const date = new Date(sum.date ?? new Date());

                        if (date) {
                            currDates = [...currDates, date];
                        }
                        if (date) {
                            const title =
                                date.toLocaleString('sr-SR', { month: 'short' }) + ' ' + date.getUTCFullYear();
                            lbls = [...lbls, title];
                        }
                        currData = [...currData, sum.value];
                    });

                    const dataset: DataSet[] = [
                        {
                            label: 'Svi troškovi',
                            data: currData,
                            fill: false,
                            backgroundColor: ['rgb(255, 99, 132)'],
                            borderColor: ['rgba(255, 99, 132, 0.5)'],
                            hoverBorderWidth: 8,
                            pointRadius: 4,
                            tension: 0.1,
                            dates: currDates,
                        },
                    ];
                    setFullData(() => ({ labels: lbls, datasets: dataset }));
                    setAllExpensesLoadingStatus(ServerResponseStatus.Success);
                });
            }
        } else {
            const lbls = ['Nema informacija', 'Nema informacija'];
            const dataset: DataSet[] = [
                {
                    label: 'Svi troškovi',
                    data: [0, 0],
                    fill: false,
                    backgroundColor: ['rgb(255, 99, 132)'],
                    borderColor: ['rgba(255, 99, 132, 0.5)'],
                    hoverBorderWidth: 8,
                    pointRadius: 4,
                    tension: 0.1,
                    dates: [new Date(), new Date()],
                },
            ];
            setFullData(() => ({ labels: lbls, datasets: dataset }));
        }
    }, [expenseType, searchParams, startDate, endDate, expenses]);

    useEffect(() => {
        if (allExpensesLoadingStatus === ServerResponseStatus.Success && fullData.datasets.length > 0) {
            let priceSum = 0;
            let nullMonths = 0;
            let currData: number[] = [];
            let currDates: Date[] = [];
            let labels: string[] = [];

            const dataSet = fullData.datasets[0];
            if (dataSet) {
                dataSet.data.map((currDataValue, index) => {
                    if (
                        CompareTwoDates(dataSet.dates[index], new Date(startDate)) ||
                        CompareTwoDates(new Date(endDate), dataSet.dates[index])
                    ) {
                    } else {
                        currData = [...currData, clone(currDataValue)];
                        currDates = [...currDates, clone(dataSet.dates[index])];
                        labels = [...labels, clone(fullData.labels[index])];

                        if (!CompareTwoDatesWithoutDayEquality(dataSet.dates[index], new Date())) {
                            priceSum += currDataValue;

                            if (currDataValue <= 1) {
                                nullMonths++;
                            }
                        } else {
                            nullMonths++;
                        }
                    }
                });

                if (nullMonths === dataSet.data.length) {
                    setAverageExpenseForPeriod(0);
                } else {
                    setAverageExpenseForPeriod(priceSum / (dataSet.data.length - nullMonths));
                }
            }

            const dataset: DataSet[] = [
                {
                    label: 'Svi troškovi',
                    data: currData,
                    fill: false,
                    backgroundColor: ['rgb(255, 99, 132)'],
                    borderColor: ['rgba(255, 99, 132, 0.5)'],
                    hoverBorderWidth: 8,
                    pointRadius: 4,
                    tension: 0.1,
                    dates: currDates,
                },
            ];

            setData(() => ({ labels: labels, datasets: dataset }));
        }
    }, [fullData, startDate, endDate]);

    const handleExpenseTypeChange = (event: SelectChangeEvent<ExpenseCategory | string | undefined>): void => {
        const target = event.target;
        const value = target.value;

        if (!value || value === ALL_EXP_TYPES) {
            setExpenseType(null);
        } else if (value as ExpenseCategory) {
            setExpenseType(value as ExpenseCategory);
        }
    };

    return (
        <div
            style={{
                width: 600,
                height: 400,
                border: '1px solid lightgray',
                borderRadius: 5,
                padding: 20,
                paddingTop: 0,
                position: 'relative',
            }}
        >
            <ChartSearch handleExpenseTypeChange={handleExpenseTypeChange} isAllExpensesChart={true} />
            {allExpensesLoadingStatus === ServerResponseStatus.Success ? (
                <Line data={data} options={options} />
            ) : allExpensesLoadingStatus === ServerResponseStatus.Pending ? (
                <div style={{ height: 250, width: 250, margin: 'auto' }}>
                    <LoaderProgress />
                </div>
            ) : (
                'Greška'
            )}
            <div style={{ position: 'absolute', bottom: 10, right: 25 }}>
                <AverageConsumption
                    averageExpense={averageExpenseForPeriod}
                    endDate={endDate}
                    startDate={startDate}
                    isFuel={false}
                />
            </div>
        </div>
    );
}
