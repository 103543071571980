import { Paper, Typography, Button, Grid, Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

const SaveUnsavedShipmentsChangesAlertContainer = styled.div`
    position: relative;
    width: 470px;
    margin-left: auto;
    margin-right: auto;
    top: 20vh;
    height: 270px;
`;

type SaveUnsavedShipmentsChangesAlertProps = {
    closeAlert(): void;
    closeTwSideDetails(): void;
};

export default function SaveUnsavedShipmentsChangesAlert({
    closeAlert,
    closeTwSideDetails,
}: SaveUnsavedShipmentsChangesAlertProps): ReactElement {
    return (
        <SaveUnsavedShipmentsChangesAlertContainer>
            <Paper
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: 15,
                    padding: 10,
                }}
            >
                <div style={{ paddingLeft: 10, paddingTop: 10 }}>
                    <Typography
                        component="h5"
                        variant="h5"
                        align="left"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            marginBottom: 20,
                            fontSize: 30,
                        }}
                    >
                        <ErrorOutlineRoundedIcon style={{ color: '#d32f2f', fontSize: 50, marginRight: 10 }} />{' '}
                        Izgubićete izmene
                    </Typography>
                    <Typography
                        component="p"
                        variant="body1"
                        align="left"
                        style={{ fontSize: 16, marginLeft: 40, padding: 20 }}
                    >
                        Imate nesačuvanih izmena u rasporedu isporuka. Da li želite da ih sačuvate?
                    </Typography>
                </div>
                <Grid container spacing={2} style={{ padding: 30, marginLeft: 20, width: 440 }}>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <Tooltip title="Izmene neće biti sačuvane!" placement="top">
                            <Button
                                fullWidth
                                size="small"
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    closeTwSideDetails();
                                }}
                            >
                                IZAĐI
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <Tooltip title="Nastavi sa izmenama." placement="top">
                            <Button
                                fullWidth
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    closeAlert();
                                }}
                            >
                                NASTAVI
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Paper>
        </SaveUnsavedShipmentsChangesAlertContainer>
    );
}
