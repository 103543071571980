import React, { ReactElement } from 'react';
import './App.css';
import ExchangeRateWidget from './Widgets/ExchangeRateWidget';
import WeatherForecastWidget from './Widgets/WeatherForecastWidget';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Grid, IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { routes } from '../Common/Routes';

export default function MainMenu(): ReactElement {
    return (
        <>
            <div style={{ position: 'absolute', width: 'calc(100vw - 700px)', left: 170 }}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Link to={routes.charts} className="link">
                            <IconButton color="inherit" edge="end" className="main-menu-icon-button">
                                <BarChartIcon style={{ width: '100%', height: '100%' }} />
                            </IconButton>
                            <Typography variant="h5" component="h5" className="main-menu-button-text">
                                POGLEDAJTE STATISTIKU
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Link to={routes.calendar} className="link">
                            <IconButton color="inherit" edge="end" className="main-menu-icon-button">
                                <CalendarMonthIcon
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            </IconButton>
                            <Typography variant="h5" component="h5" className="main-menu-button-text">
                                BUDITE U TOKU SA SVOJIM KALENDAROM
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </div>
            <div
                style={{
                    float: 'right',
                    width: '280px',
                }}
            >
                <WeatherForecastWidget />
            </div>
            <div style={{ float: 'right' }}>
                <ExchangeRateWidget />
            </div>
        </>
    );
}
