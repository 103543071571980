import React, { ReactElement } from 'react';
import './Details.css';
import { DriverDto } from '../../AutoGeneratedAPI/clientApi';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { CompleteUserInfoDto } from '../../AutoGeneratedAPI/MSGraph/msGraphApi';
import { useEffect } from 'react';
import { getGraphDriverFromDriver } from '../../Extensions/Selectors/Drivers/DriverSelector';

type DriverDetailsProps = {
    driver: DriverDto | null;
};

export default function DriverDetails({ driver }: DriverDetailsProps): ReactElement {
    const [graphDriver, setGraphDriver] = useState<CompleteUserInfoDto | undefined>(undefined);

    useEffect(() => {
        getGraphDriverFromDriver(driver).then((res) => {
            setGraphDriver(res);
        });
    }, []);

    return (
        <Grid container style={{ justifyContent: 'space-evenly' }} spacing={1}>
            <Grid key={0} item xs={12}>
                <p key={11} className="naming">
                    Ime:
                </p>
                <p key={12} className="info">
                    {' '}
                    {graphDriver ? graphDriver.displayName : ''}
                </p>
            </Grid>
            <Grid key={1} item xs={12}>
                <p key={21} className="naming">
                    Br. taho kartice:
                </p>
                <p key={22} className="info">
                    {' '}
                    {driver && driver.tcNumber !== 'empty' ? driver.tcNumber : ''}
                </p>
            </Grid>
            <Grid key={10} item xs={12}>
                <p key={23} className="naming">
                    Datum rođenja:
                </p>
                <p key={24} className="info">
                    {' '}
                    {graphDriver &&
                    graphDriver.onPremisesExtensionAttributes &&
                    graphDriver.onPremisesExtensionAttributes.extensionAttribute3
                        ? new Date(graphDriver.onPremisesExtensionAttributes.extensionAttribute3).toLocaleDateString(
                              'en-UK',
                          )
                        : ''}
                </p>
            </Grid>
            <Grid key={158} item xs={12}>
                <p key={315} className="naming">
                    Adresa:
                </p>
                <p key={316} className="info">
                    {' '}
                    {graphDriver ? graphDriver.streetAddress : ''}
                </p>
            </Grid>
            <Grid key={2} item xs={12}>
                <p key={31} className="naming">
                    Br. lične karte:
                </p>
                <p key={32} className="info">
                    {' '}
                    {graphDriver &&
                    graphDriver.onPremisesExtensionAttributes &&
                    graphDriver.onPremisesExtensionAttributes.extensionAttribute1
                        ? graphDriver.onPremisesExtensionAttributes.extensionAttribute1
                        : ''}
                </p>
            </Grid>
            <Grid key={3} item xs={12}>
                <p key={41} className="naming">
                    JMBG:
                </p>
                <p key={42} className="info">
                    {' '}
                    {graphDriver &&
                    graphDriver.onPremisesExtensionAttributes &&
                    graphDriver.onPremisesExtensionAttributes.extensionAttribute2
                        ? graphDriver.onPremisesExtensionAttributes.extensionAttribute2
                        : ''}
                </p>
            </Grid>
            <Grid key={4} item xs={12}>
                <p key={51} className="naming">
                    Br. telefona:
                </p>
                <p key={52} className="info">
                    {' '}
                    {graphDriver && graphDriver.businessPhones ? graphDriver.businessPhones[0] : ''}
                </p>
            </Grid>
        </Grid>
    );
}
