import { Box } from '@mui/material';
import React, { ReactElement } from 'react';
import { useAppSelector } from '../../General/hooks/hooks';
import { RootState } from '../../General/Redux/store';
import { CompareTwoDates, CompareTwoDatesEquality, CompareTwoTimes, getTimeDifference } from '../../Common/DateHandler';
import { JobPositionType } from '../../General/AutoGeneratedAPI/clientApi';
import { useSearchParams } from 'react-router-dom';
import { addMonths, setDate } from 'date-fns';
import { DataGrid, GridColumnMenu, GridColumnMenuProps, GridToolbarQuickFilter } from '@mui/x-data-grid';
import './TableViewTable.css';
import { columns, createRowData, RowDataType } from './TableViewTableHelper';

type TableViewTableProps = {
    setUpdateFormTW(twId: number): void;
    startingDate?: Date;
};

function CustomColumnMenu(props: GridColumnMenuProps) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
                columnMenuSortItem: null,
            }}
        />
    );
}

export default function TableViewTable({ setUpdateFormTW }: TableViewTableProps): ReactElement {
    const transportWarrants = useAppSelector((state: RootState) => state.calendarData.transportWarrants);
    const trucks = useAppSelector((state: RootState) => state.vehicles.trucks);
    const drivers = useAppSelector((state: RootState) => state.drivers.drivers);
    const graphDrivers = useAppSelector((state: RootState) => state.drivers.graphDrivers);

    const [searchParams] = useSearchParams();

    const newVisibleRows = () => {
        const currDate = searchParams.get('currentDate');
        const newCurrDate = new Date(currDate ?? new Date());
        if (currDate && newCurrDate.toString() !== 'Invalid Date') {
            const strDateStart = setDate(newCurrDate, 1);
            const strDateEnd = addMonths(strDateStart, 1);

            const rows: RowDataType[] = [];
            transportWarrants
                ?.filter(
                    (tw) =>
                        ((CompareTwoDates(strDateStart, tw.actStartingTime) ||
                            CompareTwoDatesEquality(strDateStart, tw.actStartingTime)) &&
                            CompareTwoDates(tw.actStartingTime, strDateEnd)) ||
                        ((CompareTwoDates(strDateStart, tw.actEndingTime) ||
                            CompareTwoDatesEquality(strDateStart, tw.actEndingTime)) &&
                            CompareTwoDates(tw.actEndingTime, strDateEnd)),
                )
                .map((tw) => {
                    const truck = trucks?.find((tr) => tr.id === tw.composition.mainVehicleId);
                    const truckTitle = truck?.manufacturer
                        ? truck.model
                            ? truck.manufacturer + ' ' + truck.model
                            : truck.manufacturer
                        : 'Nema kamiona';
                    const driver = drivers?.find((dr) => {
                        const djp = dr.jobPositions?.find((djp) => djp.jobPositionType === JobPositionType.Driver);
                        return tw.driverJobPositionIds?.includes(djp ? djp.id : 0);
                    });
                    const graphDriver = graphDrivers?.find((gd) => gd.id === driver?.azObjId);
                    const driverName = graphDriver?.displayName ?? 'Nema vozača';

                    if (tw.transportWarrantItems && tw.transportWarrantItems.length > 0) {
                        tw.transportWarrantItems.map((twi) => {
                            rows.push(
                                createRowData(
                                    tw.id,
                                    tw.isTwFinished,
                                    tw.actStartingTime,
                                    truckTitle,
                                    truck?.licensePlateNum ?? 'Nema tablica',
                                    driverName,
                                    twi.shipment.data.clientName,
                                    twi.shipment.data.invoiceName,
                                    twi.shipment.data.invoiceCode,
                                    twi.shipment.loadValue ?? 0,
                                    twi.shipment.endingStop?.locationInfo.address,
                                    twi.shipment.transportPrice ?? 0,
                                    twi.shipment.startingStop?.startingTime ?? new Date(),
                                    twi.shipment.startingStop?.endingTime ?? new Date(),
                                    getTimeDifference(
                                        twi.shipment.startingStop?.startingTime,
                                        twi.shipment.startingStop?.endingTime,
                                    ),
                                    tw.actStartingTime,
                                    tw.actEndingTime,
                                    getTimeDifference(tw.actStartingTime, tw.actEndingTime),
                                    tw.startingKm ?? 0,
                                    tw.endingKm ?? 0,
                                    (tw.endingKm ?? 0) - (tw.startingKm ?? 0),
                                ),
                            );
                        });
                    } else {
                        rows.push(
                            createRowData(
                                tw.id,
                                tw.isTwFinished,
                                tw.actStartingTime,
                                truckTitle,
                                truck?.licensePlateNum ?? 'Nema tablica',
                                driverName,
                                '/',
                                '/',
                                '/',
                                0,
                                '/',
                                0,
                                new Date(),
                                new Date(),
                                getTimeDifference(new Date(), new Date()),
                                tw.actStartingTime,
                                tw.actEndingTime,
                                getTimeDifference(tw.actStartingTime, tw.actEndingTime),
                                tw.startingKm ?? 0,
                                tw.endingKm ?? 0,
                                (tw.endingKm ?? 0) - (tw.startingKm ?? 0),
                            ),
                        );
                    }
                });

            return rows;
        }

        return [];
    };

    return (
        <Box
            sx={{
                height: 'calc(100vh - 60px - 67px - 80px)',
                '& .unfinished-tw': {
                    color: '#E4393E',
                },
                '& .regular-row': {
                    cursor: 'pointer',
                },
                '& .even': {
                    backgroundColor: '#f3f3f3',
                    '&:hover, &.Mui-hovered': {
                        backgroundColor: '#DEDEDE',
                    },
                },
                '& .odd': {
                    backgroundColor: '#EAEAEA',
                    '&:hover, &.Mui-hovered': {
                        backgroundColor: '#DEDEDE',
                    },
                },
            }}
        >
            <DataGrid
                rows={newVisibleRows()}
                columns={columns}
                onRowClick={(row) => setUpdateFormTW(row.row.realId)}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'realDate', sort: 'asc' }],
                    },
                }}
                rowHeight={30}
                getRowClassName={(params) => {
                    const className = `${
                        !params.row.isFinished &&
                        (CompareTwoDates(new Date(params.row.realTwEndingTime), new Date()) ||
                            (CompareTwoDatesEquality(new Date(params.row.realTwEndingTime), new Date()) &&
                                CompareTwoTimes(new Date(params.row.realTwEndingTime), new Date())))
                            ? 'regular-row unfinished-tw'
                            : 'regular-row'
                    }`;
                    return className.concat(params.indexRelativeToCurrentPage % 2 === 0 ? ' even' : ' odd');
                }}
                slots={{
                    toolbar: GridToolbarQuickFilter,
                    columnMenu: CustomColumnMenu,
                    noRowsOverlay: () => (
                        <div
                            style={{
                                margin: 'auto',
                                textAlign: 'center',
                                marginTop: 'calc(50vh - 170px)',
                            }}
                        >
                            Nema podataka
                        </div>
                    ),
                }}
                slotProps={{
                    toolbar: {
                        placeholder: 'Pretraži...',
                        style: {
                            width: 250,
                            height: 33,
                            position: 'absolute',
                            backgroundColor: 'white',
                            zIndex: 999,
                            top: -46,
                            right: 250,
                            borderRadius: 5,
                        },
                    },
                    filterPanel: {
                        columnsSort: 'asc',
                        filterFormProps: {
                            columnInputProps: {
                                variant: 'outlined',
                                size: 'small',
                                sx: { mt: 'auto' },
                                style: { marginLeft: 5 },
                            },
                            operatorInputProps: {
                                variant: 'outlined',
                                size: 'small',
                                sx: { mt: 'auto' },
                                style: { marginLeft: 5 },
                            },
                            valueInputProps: {
                                InputComponentProps: {
                                    variant: 'outlined',
                                    size: 'small',
                                    placeholder: 'Pretraži',
                                    label: 'Filter',
                                },
                                style: { marginLeft: 5 },
                            },
                            deleteIconProps: {
                                sx: {
                                    '& .MuiSvgIcon-root': { color: '#d32f2f' },
                                },
                            },
                        },
                    },
                }}
            />
        </Box>
    );
}
