import Catalogue from '../../General/Catalogue/Catalogue';
import '../App.css';
import Calendar from '../../Calendar/Calendar';
import MainMenu from './../MainMenu';
import Charts from '../../Charts/Charts';
import { routes } from '../../Common/Routes';
import Profile from '../../General/Profile/ProfilePage/Profile';
import TransportWarrantReport from '../../Reports/TransportWarrantReport';
import AdminLayout from '../Layouts/AdminLayout';

export const cataloguePages = [
    {
        path: routes.cars,
        component: Catalogue,
        layout: AdminLayout,
    },
    {
        path: routes.trucks,
        component: Catalogue,
        layout: AdminLayout,
    },
    {
        path: routes.trailers,
        component: Catalogue,
        layout: AdminLayout,
    },
    {
        path: routes.drivers,
        component: Catalogue,
        layout: AdminLayout,
    },
    {
        path: routes.customers,
        component: Catalogue,
        layout: AdminLayout,
    },
    {
        path: routes.suppliers,
        component: Catalogue,
        layout: AdminLayout,
    },
    {
        path: routes.services,
        component: Catalogue,
        layout: AdminLayout,
    },
    {
        path: routes.workingmachines,
        component: Catalogue,
        layout: AdminLayout,
    },
    {
        path: routes.workingtools,
        component: Catalogue,
        layout: AdminLayout,
    },
    {
        path: routes.officemachines,
        component: Catalogue,
        layout: AdminLayout,
    },
    {
        path: routes.handmachines,
        component: Catalogue,
        layout: AdminLayout,
    },
];

export const generalPages = [
    // Public pages
    {
        path: routes.twPrint,
        component: TransportWarrantReport,
        layout: AdminLayout,
    },
    // Authenticated pages
    {
        path: routes.mainMenu,
        component: MainMenu,
        layout: AdminLayout,
    },
    {
        path: routes.calendar,
        component: Calendar,
        layout: AdminLayout,
    },
    {
        path: routes.charts,
        component: Charts,
        layout: AdminLayout,
    },
];

export const profilePages = [
    // CARS
    {
        path: routes.carDetails,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.carDetailsRecords,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.carDetailsRecordsRecord,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.carDetailsExpenses,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.carDetailsExpensesExpense,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.carDetailsTransportWarrants,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.carDetailsImportantDocuments,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.carDetailsImportantDocumentsImpDoc,
        component: Profile,
        layout: AdminLayout,
    },
    // TRUCKS
    {
        path: routes.truckDetails,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.truckDetailsRecords,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.truckDetailsRecordsRecord,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.truckDetailsExpenses,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.truckDetailsExpensesExpense,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.truckDetailsTransportWarrants,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.truckDetailsImportantDocuments,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.truckDetailsImportantDocumentsImpDoc,
        component: Profile,
        layout: AdminLayout,
    },
    // TRAILERS
    {
        path: routes.trailerDetails,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.trailerDetailsRecords,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.trailerDetailsRecordsRecord,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.trailerDetailsExpenses,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.trailerDetailsExpensesExpense,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.trailerDetailsTransportWarrants,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.trailerDetailsImportantDocuments,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.trailerDetailsImportantDocumentsImpDoc,
        component: Profile,
        layout: AdminLayout,
    },
    // DRIVERS
    {
        path: routes.driversDetails,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.driversDetailsRecords,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.driversDetailsRecordsRecord,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.driversDetailsTransportWarrants,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.driversDetailsImportantDocuments,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.driversDetailsImportantDocumentsImpDoc,
        component: Profile,
        layout: AdminLayout,
    },
    // CUSTOMERS
    {
        path: routes.customerDetails,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.customerDetailsRecords,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.customerDetailsRecordsRecord,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.customerDetailsInvoices,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.customerDetailsProjects,
        component: Profile,
        layout: AdminLayout,
    },
    // SUPPLIERS
    {
        path: routes.supplierDetails,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.supplierDetailsRecords,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.supplierDetailsRecordsRecord,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.supplierDetailsInvoices,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.supplierDetailsProjects,
        component: Profile,
        layout: AdminLayout,
    },
    // SERVICES
    {
        path: routes.serviceDetails,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.serviceDetailsRecords,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.serviceDetailsRecordsRecord,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.serviceDetailsInvoices,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.serviceDetailsProjects,
        component: Profile,
        layout: AdminLayout,
    },
    // WORKINGMACHINES
    {
        path: routes.workingmachineDetails,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingmachineDetailsRecords,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingmachineDetailsRecordsRecord,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingmachineDetailsExpenses,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingmachineDetailsExpensesExpense,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingmachineDetailsTransportWarrants,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingmachineDetailsImportantDocuments,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingmachineDetailsImportantDocumentsImpDoc,
        component: Profile,
        layout: AdminLayout,
    },
    // WORKINGTOOLS
    {
        path: routes.workingtoolDetails,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingtoolDetailsRecords,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingtoolDetailsRecordsRecord,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingtoolDetailsExpenses,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingtoolDetailsExpensesExpense,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingtoolDetailsTransportWarrants,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingtoolDetailsImportantDocuments,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.workingtoolDetailsImportantDocumentsImpDoc,
        component: Profile,
        layout: AdminLayout,
    },
    // OFFICEMACHINES
    {
        path: routes.officemachineDetails,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.officemachineDetailsRecords,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.officemachineDetailsRecordsRecord,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.officemachineDetailsExpenses,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.officemachineDetailsExpensesExpense,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.officemachineDetailsTransportWarrants,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.officemachineDetailsImportantDocuments,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.officemachineDetailsImportantDocumentsImpDoc,
        component: Profile,
        layout: AdminLayout,
    },
    // HANDMACHINES
    {
        path: routes.handmachineDetails,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.handmachineDetailsRecords,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.handmachineDetailsRecordsRecord,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.handmachineDetailsExpenses,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.handmachineDetailsExpensesExpense,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.handmachineDetailsTransportWarrants,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.handmachineDetailsImportantDocuments,
        component: Profile,
        layout: AdminLayout,
    },
    {
        path: routes.handmachineDetailsImportantDocumentsImpDoc,
        component: Profile,
        layout: AdminLayout,
    },
];
