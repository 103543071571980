import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ShipmentDto } from '../../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithFetching } from '../../../Extensions/Exceptions';

interface IInitialStateType {
    shipments: ShipmentDto[] | null;
}

const initialState: IInitialStateType = {
    shipments: null,
};

export const fetchInitialShipments = createAsyncThunk(
    'shipments/fetchInitialShipments',
    async () => {
        const response = await GranitClient.getActiveShipments(true);
        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingShipments: ShipmentDto[] | null = thunkAPI.getState().shipments.shipments;

            return existingShipments === null;
        },
    },
);

const shipmentReducer = createSlice({
    name: 'shipment',
    initialState,
    reducers: {
        setInitialShipments(state, action) {
            state.shipments = action.payload ?? state.shipments;
        },
        concatShipments(state, action) {
            if (action.payload && action.payload.length > 0) {
                state.shipments = [...(state.shipments ?? []), ...action.payload];
            }
        },
        addShipmentToBeginning(state, action) {
            if (action.payload) {
                state.shipments = [action.payload, ...(state.shipments ?? [])];
            }
        },
        updateShipment(state, action) {
            const shipment = action.payload;
            const ships = state.shipments ?? [];
            const shipIndex = ships.findIndex((i) => {
                return i.id === shipment.id;
            });
            if (shipIndex != undefined && shipIndex >= 0) {
                state.shipments = [...ships.slice(0, shipIndex), shipment, ...ships.slice(shipIndex + 1)];
            }
        },
        removeShipmentFromSelectableArray(state, action) {
            const shipmentId = action.payload;
            const ships = state.shipments ?? [];
            const shipIndex = ships.findIndex((i) => {
                return i.id === shipmentId;
            });
            state.shipments = [...ships.slice(0, shipIndex), ...ships.slice(shipIndex + 1)];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInitialShipments.fulfilled, (state, action) => {
            state.shipments = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialShipments.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_SHIPMENTS);
        });
    },
});

export const {
    addShipmentToBeginning,
    updateShipment,
    setInitialShipments,
    concatShipments,
    removeShipmentFromSelectableArray,
} = shipmentReducer.actions;

export default shipmentReducer.reducer;
