import { WorkingMachineDto } from '../../../AutoGeneratedAPI/clientApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ExceptionsWithFetching } from '../../../Extensions/Exceptions';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';

interface IInitialStateType {
    workingMachines: WorkingMachineDto[] | null;
}

const initialState: IInitialStateType = {
    workingMachines: null,
};

export const fetchInitialWorkingMachines = createAsyncThunk(
    'workingMachines/fetchInitialWorkingMachines',
    async () => {
        const response = await GranitClient.getAllWorkingMachines();
        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingWMachines: WorkingMachineDto[] | null = thunkAPI.getState().workingMachines.workingMachines;

            return existingWMachines === null;
        },
    },
);

const workingMachinesSlice = createSlice({
    name: 'workingMachines',
    initialState,
    reducers: {
        addWorkingMachine(state, action) {
            state.workingMachines = [action.payload, ...(state.workingMachines ?? [])];
        },
        updateWorkingMachine: {
            reducer(state, action) {
                const { workingMachine, oldWorkingMachine } = action.payload;
                const workingMachines = state.workingMachines ?? [];
                const workingMachineIndex = workingMachines.findIndex((wm) => {
                    return wm.id === oldWorkingMachine.id;
                });
                state.workingMachines = [
                    ...workingMachines.slice(0, workingMachineIndex),
                    workingMachine,
                    ...workingMachines.slice(workingMachineIndex + 1),
                ];
            },
            prepare(workingMachine, oldWorkingMachine) {
                return {
                    payload: { workingMachine, oldWorkingMachine },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
        changeWorkingMachineProfileImage: {
            reducer(state, action) {
                const wmIndex = state.workingMachines?.findIndex((wm) => {
                    return wm.id === action.payload.workingToolId;
                });
                if (state.workingMachines && wmIndex !== undefined && wmIndex >= 0) {
                    const wm = state.workingMachines[wmIndex];
                    wm.documents = [action.payload.doc, ...(wm.documents?.slice(1) ?? [])];
                    state.workingMachines = [
                        ...state.workingMachines.slice(0, wmIndex),
                        wm,
                        ...state.workingMachines.slice(wmIndex + 1),
                    ];
                }
            },
            prepare(workingToolId, doc) {
                return {
                    payload: { workingToolId, doc },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInitialWorkingMachines.fulfilled, (state, action) => {
            state.workingMachines = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialWorkingMachines.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_WORKINGMACHINES);
        });
    },
});

export const {
    addWorkingMachine,
    updateWorkingMachine,
    changeWorkingMachineProfileImage,
} = workingMachinesSlice.actions;

export default workingMachinesSlice.reducer;
