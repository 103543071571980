import React, { ReactElement, ChangeEvent, useState, useEffect } from 'react';
import { GranitClient } from '../AutoGeneratedAPI/Extension';
import './Forms.css';
import {
    Button,
    Grid,
    Stack,
    TextField,
    Typography,
    Paper,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import {
    EngineType,
    IWorkingMachineDto,
    IWorkingToolDto,
    MachineType,
    WorkingMachineDto,
    WorkingToolDto,
} from '../AutoGeneratedAPI/clientApi';
import { useDispatch, useSelector } from 'react-redux';
import { addNewWorkingTool, updateOldWorkingTool } from '../Redux/Actions/WorkingTools/WorkingToolAction';
import { addNewWorkingMachine, updateOldWorkingMachine } from '../Redux/Actions/WorkingTools/WorkingMachineAction';
import { addNewHandMachine, updateOldHandMachine } from '../Redux/Actions/WorkingTools/HandMachineAction';
import { addNewOfficeMachine, updateOldOfficeMachine } from '../Redux/Actions/WorkingTools/OfficeMachineAction';
import { RootState } from '../Redux/store';
import WorkingToolFormHelper from './WorkingToolFormHelper';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import { SelectChangeEvent } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
    isAppTypeHandMachine,
    isAppTypeOfficeMachine,
    isAppTypeWorkingMachine,
    isAppTypeWorkingTool,
    isUpdateForm,
} from '../Extensions/Selectors/Selectors';
import { EngineTypeSR } from '../Extensions/Localizations/Types/Vehicles/SR/EngineTypeSR';

type WorkingToolFormProps = {
    closeForm(): void;
};

export default function WorkingToolForm({ closeForm }: WorkingToolFormProps): ReactElement {
    const [workingMachine, setWorkingMachine] = useState<IWorkingMachineDto | IWorkingToolDto>(new WorkingMachineDto());
    const [manufacturers, setManufacturers] = useState<string[]>([]);
    const [models, setModels] = useState<string[]>([]);

    const location = useLocation();
    const path = location.pathname;

    const entity = useSelector((state: RootState) => state.currentEntity.entity);

    const isUpdateFrm = isUpdateForm(path);
    const isWorkingTool = isAppTypeWorkingTool(path);
    const isWorkingMachine = isAppTypeWorkingMachine(path);
    const isOfficeMachine = isAppTypeOfficeMachine(path);
    const isHandMachine = isAppTypeHandMachine(path);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isUpdateFrm && entity) {
            const e = isWorkingTool ? (entity as WorkingToolDto) : (entity as WorkingMachineDto);
            setWorkingMachine(e);
        } else {
            setWorkingMachine({ ...workingMachine, productionDate: new Date() });
        }
    }, [entity, isUpdateFrm, path]);

    const handleMaufacturerSelect = (event: ChangeEvent<unknown>, value: string | null): void => {
        if (value) {
            setWorkingMachine({ ...workingMachine, manufacturer: value as string });
            GranitClient.getWorkingToolModels(value as string).then(
                (result) => {
                    setModels(result);
                },
                (error) => console.log(error.message),
            );
        }
    };

    const handleMaufacturerChange = (event: ChangeEvent<{ value: unknown }>): void => {
        if (event.target.value) {
            setWorkingMachine({ ...workingMachine, manufacturer: event.target.value as string });
            GranitClient.getWorkingToolManufacturers().then(
                (result) => {
                    setManufacturers(result);
                },
                (error) => console.log(error.message),
            );
            GranitClient.getWorkingToolModels(event.target.value as string).then(
                (result) => {
                    setModels(result);
                },
                (error) => console.log(error.message),
            );
        }
    };

    const handleModelSelect = (event: ChangeEvent<unknown>, value: string | null): void => {
        setWorkingMachine({ ...workingMachine, model: value as string });
    };

    const handleInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setWorkingMachine({ ...workingMachine, [name]: value });
    };

    const handleSelectElement = (event: SelectChangeEvent<EngineType>): void => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setWorkingMachine({ ...workingMachine, [name]: value });
    };

    const handleProductionDateChange = (date?: Date): void => {
        if (date) {
            setWorkingMachine({ ...workingMachine, productionDate: date as Date });
        }
    };

    const onSubmit = (): void => {
        const wMachine = workingMachine;
        if (isWorkingTool) {
            wMachine.machineType = MachineType.WorkingTool;
        } else if (isWorkingMachine) {
            wMachine.machineType = MachineType.WorkingMachine;
        } else if (isHandMachine) {
            wMachine.machineType = MachineType.HandMachine;
        } else if (isOfficeMachine) {
            wMachine.machineType = MachineType.OfficeMachine;
        }
        if (isUpdateFrm) {
            if (isWorkingTool) {
                const wm = new WorkingToolDto(wMachine as IWorkingToolDto);
                dispatch(updateOldWorkingTool(wm, entity as WorkingToolDto));
            } else if (isWorkingMachine) {
                const wm = new WorkingMachineDto(wMachine as IWorkingMachineDto);
                dispatch(updateOldWorkingMachine(wm, entity as WorkingMachineDto));
            } else if (isHandMachine) {
                const wm = new WorkingMachineDto(wMachine as IWorkingMachineDto);
                dispatch(updateOldHandMachine(wm, entity as WorkingMachineDto));
            } else if (isOfficeMachine) {
                const wm = new WorkingMachineDto(wMachine as IWorkingMachineDto);
                dispatch(updateOldOfficeMachine(wm, entity as WorkingMachineDto));
            }
        } else {
            if (isWorkingTool) {
                const wm = new WorkingToolDto(wMachine as IWorkingToolDto);
                dispatch(addNewWorkingTool(wm));
            } else if (isWorkingMachine) {
                const wm = new WorkingMachineDto(wMachine as IWorkingMachineDto);
                dispatch(addNewWorkingMachine(wm));
            } else if (isHandMachine) {
                const wm = new WorkingMachineDto(wMachine as IWorkingMachineDto);
                dispatch(addNewHandMachine(wm));
            } else if (isOfficeMachine) {
                const wm = new WorkingMachineDto(wMachine as IWorkingMachineDto);
                dispatch(addNewOfficeMachine(wm));
            }
        }
        closeForm();
    };

    const formTitle = WorkingToolFormHelper.getTitle(isUpdateFrm, path);

    const checkIfDataIsChanged = (): boolean => {
        const wm = workingMachine;
        const currEntity = entity;
        const wmProdDate = new Date(wm?.productionDate as Date);
        const entityProdDate = currEntity
            ? new Date((currEntity as WorkingToolDto).productionDate as Date)
            : new Date();
        if (isUpdateFrm && currEntity) {
            return (
                wm?.manufacturer === (currEntity as WorkingToolDto).manufacturer &&
                wm?.chassisNumber === (currEntity as WorkingToolDto).chassisNumber &&
                wm?.model === (currEntity as WorkingToolDto).model &&
                wm?.nickname === (currEntity as WorkingToolDto).nickname &&
                wmProdDate.getFullYear() === entityProdDate.getFullYear() &&
                wmProdDate.getMonth() === entityProdDate.getMonth() &&
                wmProdDate.getDay() === entityProdDate.getDay() &&
                ((!isWorkingTool &&
                    new WorkingMachineDto(wm as IWorkingMachineDto).engineType ===
                        (currEntity as WorkingMachineDto).engineType) ||
                    isWorkingTool)
            );
        }
        return false;
    };

    const checkIfRequiredIsEmpty = (): boolean => {
        return workingMachine?.manufacturer &&
            workingMachine?.model &&
            ((!isWorkingTool && new WorkingMachineDto(workingMachine as IWorkingMachineDto).engineType) ||
                isWorkingTool)
            ? false
            : true;
    };

    const isDataChanged = !checkIfDataIsChanged();
    const isRequiredEmpty = checkIfRequiredIsEmpty();

    const WorkingMachineFormElement = (): ReactElement => {
        return (
            <>
                <Grid container spacing={5}>
                    <Grid item xs={12} lg={6}>
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={manufacturers.map((option) => option)}
                            value={workingMachine?.manufacturer ?? ''}
                            onChange={handleMaufacturerSelect}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={workingMachine?.manufacturer ? false : true}
                                    required={true}
                                    fullWidth
                                    id="filled-basic"
                                    label="Marka"
                                    variant="outlined"
                                    onChange={handleMaufacturerChange}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={models.map((option) => option)}
                            value={workingMachine?.model ?? ''}
                            onChange={handleModelSelect}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={workingMachine?.model ? false : true}
                                    required={true}
                                    fullWidth
                                    name="model"
                                    id="filled-basic"
                                    label="Model"
                                    variant="outlined"
                                    onChange={handleInputElementChange}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                            <Stack spacing={3}>
                                <DesktopDatePicker
                                    label="Datum proizvodnje"
                                    disableMaskedInput
                                    value={workingMachine?.productionDate}
                                    inputFormat="DD/MM/YYYY"
                                    minDate={dayjs('1940-01-01')}
                                    onChange={(newValue) => {
                                        handleProductionDateChange(newValue?.toDate());
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            fullWidth
                            id="filled-basic"
                            label="Naziv"
                            name="nickname"
                            value={workingMachine?.nickname ?? ''}
                            variant="outlined"
                            onChange={handleInputElementChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            fullWidth
                            id="filled-basic"
                            label="Broj šasije"
                            name="chassisNumber"
                            value={workingMachine?.chassisNumber ?? ''}
                            variant="outlined"
                            onChange={handleInputElementChange}
                        />
                    </Grid>
                    {!isWorkingTool && (
                        <Grid item xs={12} lg={6}>
                            <FormControl
                                fullWidth
                                required={true}
                                error={(workingMachine as IWorkingMachineDto).engineType ? false : true}
                            >
                                <InputLabel id="engine-type">Tip goriva</InputLabel>
                                <Select
                                    labelId="engine-type"
                                    label="Tip goriva"
                                    id="demo-simple-select"
                                    value={(workingMachine as IWorkingMachineDto).engineType ?? ''}
                                    name="engineType"
                                    onChange={handleSelectElement}
                                >
                                    {Object.values(EngineType).map((et) => {
                                        return (
                                            <MenuItem key={et} value={et as EngineType}>
                                                {EngineTypeSR[et as EngineType]}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </>
        );
    };

    return (
        <>
            <main className="custom-form-main">
                <div className="custom-form-close" onClick={closeForm}>
                    x
                </div>
                <Paper className="custom-form-paper">
                    <Typography component="h4" variant="h4" align="center" style={{ marginBottom: 50 }}>
                        {formTitle}
                    </Typography>
                    <div style={{ height: 'calc(80vh - 200px)', overflowX: 'hidden', overflowY: 'auto', padding: 20 }}>
                        {WorkingMachineFormElement()}
                    </div>
                    <div style={{ position: 'absolute', bottom: 20, right: 50, width: 200 }}>
                        <Button
                            disabled={isDataChanged && !isRequiredEmpty ? false : true}
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onSubmit();
                            }}
                        >
                            SAČUVAJ
                        </Button>
                    </div>
                </Paper>
            </main>
        </>
    );
}
