import React, { MouseEvent, useState, ReactElement } from 'react';
import { Menu, MenuItem, Modal } from '@mui/material';
import VehicleForm from '../../Forms/VehicleForm';
import { EntitiesType } from '../../Extensions/Entities';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import EditIcon from '@mui/icons-material/Edit';
import WorkingToolForm from '../../Forms/WorkingToolForm';
import DriverForm from '../../Forms/DriverForm';
import {
    CarDto,
    EntityType,
    KmEventDto,
    TimeEventDto,
    TrailerDto,
    TruckDto,
    VehicleDto,
} from '../../AutoGeneratedAPI/clientApi';
import './DetailsMenu.css';
import CustomerForm from '../../Forms/CustomersForm/CustomerForm';
import {
    isAppTypeCar,
    isAppTypeClient,
    isAppTypeDriver,
    isAppTypeTrailer,
    isAppTypeTruck,
    isAppTypeVehicle,
} from '../../Extensions/Selectors/Selectors';
import { useLocation, useParams } from 'react-router-dom';
import InventoryIcon from '@mui/icons-material/Inventory';
import RefreshIcon from '@mui/icons-material/Refresh';
import { updateCar, updateTrailer, updateTruck } from '../../Redux/Reducers/Vehicles/VehiclesReducer';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { changeCurrentEntity } from '../../Redux/Reducers/General/CurrentEntityReducer';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import AlarmIcon from '@mui/icons-material/Alarm';
import EventForm from '../../Forms/EventForm/EventForm';
import EventsList from '../../Events/EventsList';
import { RootState } from '../../Redux/store';

type DetailsMenuProps = {
    anchorEl: null | HTMLElement;
    handleCloseAnchor(): void;
    entity: EntitiesType | null;
    handleFileInputChange(selectorFiles: FileList | undefined): void;
};

export function DetailsMenu({
    anchorEl,
    handleCloseAnchor,
    entity,
    handleFileInputChange,
}: DetailsMenuProps): ReactElement {
    const kmEvents = useAppSelector((state: RootState) => state.notifications.kmEvents);
    const timeEvents = useAppSelector((state: RootState) => state.notifications.timeEvents);

    const [openEventModal, setOpenEventModal] = useState<boolean>(false);
    const [eventsListAnchorEl, setEventsListAnchorEl] = useState<null | HTMLElement>(null);
    const [formOpen, setFormOpen] = useState(false);

    const [defaultTimeEvent, setDefaultTimeEvent] = useState<TimeEventDto | undefined>(undefined);
    const [defaultKmEvent, setDefaultKmEvent] = useState<KmEventDto | undefined>(undefined);

    const dispatch = useAppDispatch();

    const location = useLocation();
    const path = location.pathname;

    const { entityId } = useParams();

    const isClient = isAppTypeClient(path);
    const isVehicle = isAppTypeVehicle(path);
    const isCar = isAppTypeCar(path);
    const isTruck = isAppTypeTruck(path);
    const isTrailer = isAppTypeTrailer(path);
    const isDriver = isAppTypeDriver(path);

    const entType = isVehicle
        ? EntityType.Vehicle
        : isDriver
        ? EntityType.Employee
        : isClient
        ? EntityType.Client
        : EntityType.WorkingMachine;

    const handleSelectEvent = (eventId: number, isKmEvent: boolean) => {
        if (isKmEvent) {
            const defKmEvent = kmEvents?.find((e) => e.id === eventId);
            setDefaultKmEvent(defKmEvent);
        } else {
            const defTimeEvent = timeEvents?.find((e) => e.id === eventId);
            setDefaultTimeEvent(defTimeEvent);
        }
        setOpenEventModal(true);
    };

    const handleClickEventsListAnchor = (event: MouseEvent<HTMLElement>) => {
        setEventsListAnchorEl(event.currentTarget);
    };

    const handleCloseEventsListAnchor = () => {
        setEventsListAnchorEl(null);
    };

    const handleOpenEventModal = (): void => {
        handleCloseAnchor();
        setOpenEventModal(true);
    };

    const handleCloseEventModal = (): void => {
        setOpenEventModal(false);
        if (defaultKmEvent) {
            setDefaultKmEvent(undefined);
        }
        if (defaultTimeEvent) {
            setDefaultTimeEvent(undefined);
        }
    };

    const handleOpenForm = (): void => {
        handleCloseAnchor();
        setFormOpen(true);
    };

    const handleCloseForm = (): void => {
        setFormOpen(false);
    };

    const handleArchiveVehicle = (): void => {
        if (entityId && !isNaN(+entityId) && isVehicle && entity) {
            if (isCar) {
                const car = entity as CarDto;
                car.isDeleted = !car.isDeleted;
                GranitClient.updateCar(+entityId, car)
                    .then((res) => {
                        dispatch(updateCar(res, car));
                        dispatch(changeCurrentEntity(res));
                    })
                    .then(() => {
                        handleCloseAnchor();
                        alert('Uspesno ste ' + (car.isDeleted ? 'arhivirali' : 'aktivirali') + ' automobil');
                    });
            }
            if (isTruck) {
                const truck = entity as TruckDto;
                truck.isDeleted = !truck.isDeleted;
                GranitClient.updateTruck(+entityId, truck)
                    .then((res) => {
                        dispatch(updateTruck(res, truck));
                        dispatch(changeCurrentEntity(res));
                    })
                    .then(() => {
                        handleCloseAnchor();
                        alert('Uspesno ste ' + (truck.isDeleted ? 'arhivirali' : 'aktivirali') + ' kamion');
                    });
            }
            if (isTrailer) {
                const trailer = entity as TrailerDto;
                trailer.isDeleted = !trailer.isDeleted;
                GranitClient.updateTrailer(+entityId, trailer)
                    .then((res) => {
                        dispatch(updateTrailer(res, trailer));
                        dispatch(changeCurrentEntity(res));
                    })
                    .then(() => {
                        handleCloseAnchor();
                        alert('Uspesno ste ' + (trailer.isDeleted ? 'arhivirali' : 'aktivirali') + ' prikolicu');
                    });
            }
        }
    };

    const getTitle = (): string => {
        return isVehicle ? 'vozila' : isDriver ? 'vozača' : isClient ? 'klijenta' : 'radne mašine';
    };

    const getEntityType = (): string => {
        return isVehicle ? 'vehicle' : isDriver ? 'driver' : isClient ? 'customer' : 'workingMachine';
    };

    return (
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseAnchor}>
            {!(isVehicle && (entity as VehicleDto).isDeleted) && (
                <MenuItem key={1} onClick={handleOpenForm}>
                    <span className="icon">
                        <EditIcon />
                    </span>
                    Promenite detalje {getTitle()}
                </MenuItem>
            )}
            {!(isVehicle && (entity as VehicleDto).isDeleted) && (
                <MenuItem key={4}>
                    <label style={{ display: 'block' }}>
                        <span className="icon">
                            <EditIcon />
                        </span>
                        Promenite profilnu fotografiju
                        <input
                            type="file"
                            onChange={(e) => {
                                handleFileInputChange(e.target.files as FileList);
                            }}
                            hidden
                        />
                    </label>
                </MenuItem>
            )}
            {!isClient && !(isVehicle && (entity as VehicleDto).isDeleted) && (
                <MenuItem key={2} onClick={handleOpenEventModal}>
                    <span className="icon">
                        <AddAlertIcon />
                    </span>
                    Ubacite novi podsetnik
                </MenuItem>
            )}
            {!isClient && !(isVehicle && (entity as VehicleDto).isDeleted) && (
                <MenuItem key={3} onClick={handleClickEventsListAnchor}>
                    <span className="icon">
                        <AlarmIcon />
                    </span>
                    Pogledajte buduće podsetnike
                </MenuItem>
            )}
            {isVehicle && (
                <MenuItem key={5} onClick={handleArchiveVehicle}>
                    <span className="icon">
                        {(entity as VehicleDto).isDeleted ? <RefreshIcon /> : <InventoryIcon />}
                    </span>
                    {(entity as VehicleDto).isDeleted ? 'Aktivirajte vozilo' : 'Arhivirajte vozilo'}
                </MenuItem>
            )}

            <div>
                <Modal
                    className="modalStyle"
                    open={openEventModal}
                    aria-labelledby="add-vehicle-form"
                    aria-describedby="adding a vehicle to a database through this form"
                >
                    <>
                        <EventForm
                            closeForm={handleCloseEventModal}
                            entityType={
                                isVehicle
                                    ? EntityType.Vehicle
                                    : isDriver
                                    ? EntityType.Employee
                                    : isClient
                                    ? EntityType.Client
                                    : EntityType.WorkingMachine
                            }
                            entityId={entityId ? (!isNaN(+entityId) ? +entityId : 0) : 0}
                            defaultKmEvent={defaultKmEvent}
                            defaultTimeEvent={defaultTimeEvent}
                        />
                    </>
                </Modal>
            </div>
            <EventsList
                anchorEl={eventsListAnchorEl}
                closeWindow={handleCloseEventsListAnchor}
                entityId={entityId && !isNaN(+entityId) && +entityId > 0 ? +entityId : 0}
                entityType={entType}
                handleSelectEvent={handleSelectEvent}
            />
            <div>
                <Modal
                    className="modalStyle"
                    open={formOpen}
                    aria-labelledby="add-vehicle-form"
                    aria-describedby="adding a vehicle to a database through this form"
                >
                    <div>
                        {getEntityType() === 'vehicle' ? (
                            <VehicleForm closeForm={handleCloseForm} />
                        ) : getEntityType() === 'driver' ? (
                            <DriverForm closeForm={handleCloseForm} />
                        ) : getEntityType() === 'customer' ? (
                            <CustomerForm closeForm={handleCloseForm} />
                        ) : (
                            <WorkingToolForm closeForm={handleCloseForm} />
                        )}
                    </div>
                </Modal>
            </div>
        </Menu>
    );
}
