import React, { ReactElement, useEffect, useState } from 'react';
import {
    ShipmentLoadingCloseBtn,
    ShipmentLoadingContainer,
    ShipmentLoadingVerticalLine,
} from './ShipmentLoadingStyled';
import './ShipmentLoading.css';
import ShipmentLoadingFilterBar from './ShipmentLoadingFilterBar';
import { TransportWarrantDto } from '../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import ShipmentLoadingShipmentInfo from './ShipmentLoadingShipmentInfo';
import ShipmentLoadingTWList from './ShipmentLoadingTWList';
import { CompareTwoDateTimes, getLastMomentOfTheDate, getZeroMomentOfTheDate } from '../../../Common/DateHandler';
import { TWSortingParameter, SortingDirection } from './ShipmentLoadingCommon';

type ShipmentLoadingProps = {
    close(): void;
    selectTwForLoading(twId: number): void;
    handleOpenTransportWarrantForm(): void;
};

export default function ShipmentLoading({
    close,
    selectTwForLoading,
    handleOpenTransportWarrantForm,
}: ShipmentLoadingProps): ReactElement {
    const [currDate, setCurrDate] = useState<Date>(new Date());
    const [currTWs, setCurrTWs] = useState<TransportWarrantDto[]>([]);
    const [startingHours, setStartingHours] = useState<number>(0);
    const [endingHours, setEndingHours] = useState<number>(24);
    const [sortParameter, setSortParameter] = useState<TWSortingParameter>(TWSortingParameter.StartingTime);
    const [sortDirection, setSortDirection] = useState<SortingDirection>(SortingDirection.Ascending);

    const handleChangeSortParameter = (twSortParam: TWSortingParameter) => {
        setSortParameter(twSortParam);
    };

    const handleChangeSortDirection = (twSortDir: SortingDirection) => {
        setSortDirection(twSortDir);
    };

    const handleSetStartingHours = (startHours: number): void => {
        setStartingHours(startHours);
    };

    const handleSetEndingHours = (endHours: number): void => {
        setEndingHours(endHours);
    };

    useEffect(() => {
        const date = currDate ?? new Date();
        const stTime = startingHours
            ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), startingHours, 0, 0)
            : getZeroMomentOfTheDate(date);
        const eTime = endingHours
            ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), endingHours, 0, -1)
            : getLastMomentOfTheDate(date);

        if (CompareTwoDateTimes(stTime, eTime, true)) {
            GranitClient.getTransportWarrantsForDateRange(stTime, eTime, true).then((res) =>
                setCurrTWs(res.filter((tw) => !tw.isTwFinished)),
            );
        }
    }, [currDate, startingHours, endingHours]);

    const handleCurrentDateChange = (date?: Date) => {
        setCurrDate(date ?? new Date());
    };

    return (
        <main style={{ width: '100%', height: 'calc(100vh - 68px)' }}>
            <ShipmentLoadingCloseBtn onClick={close}>{'>'}</ShipmentLoadingCloseBtn>
            <ShipmentLoadingFilterBar
                handleSetEndingHours={handleSetEndingHours}
                handleSetStartingHours={handleSetStartingHours}
                handleCurrentDateChange={handleCurrentDateChange}
                currDate={currDate}
                endingHours={endingHours}
                startingHours={startingHours}
                handleOpenTransportWarrantForm={handleOpenTransportWarrantForm}
                changeTWSortParameter={handleChangeSortParameter}
                changeTWSortDirection={handleChangeSortDirection}
                sortParam={sortParameter}
                sortDir={sortDirection}
                twsExist={currTWs.length > 0}
            />
            <ShipmentLoadingContainer>
                <ShipmentLoadingShipmentInfo />
                <ShipmentLoadingVerticalLine />
                <ShipmentLoadingTWList
                    transportWarrants={currTWs}
                    selectTw={selectTwForLoading}
                    sortDirection={sortDirection}
                    sortParameter={sortParameter}
                />
            </ShipmentLoadingContainer>
        </main>
    );
}
