import { ExpenseEntityType } from '../../../Extensions/Entities';
import { createSlice } from '@reduxjs/toolkit';

interface IInitialStateExpensesType {
    expenses: ExpenseEntityType[] | null;
}

const initialState: IInitialStateExpensesType = {
    expenses: null,
};

const expensesSlice = createSlice({
    name: 'expenses',
    initialState,
    reducers: {
        setInitialExpenses(state, action) {
            state.expenses = action.payload ?? [];
        },
        concatExpenses(state, action) {
            if (action.payload && action.payload.length > 0) {
                state.expenses = (state.expenses ?? []).concat(action.payload);
            }
        },
        addExpense(state, action) {
            if (action.payload) {
                const expIndex = (state.expenses ?? []).findIndex((e) => {
                    return (e.validFrom ?? new Date()) <= action.payload.validFrom;
                });
                if (expIndex >= 0) {
                    const firstHalf = (state.expenses ?? []).slice(0, expIndex);
                    const secondHalf = (state.expenses ?? []).slice(expIndex);
                    state.expenses = [...firstHalf, action.payload, ...secondHalf];
                } else {
                    state.expenses = (state.expenses ?? []).concat(action.payload);
                }
            }
        },
        updateExpense(state, action) {
            const expenses = state.expenses ?? [];
            const newExp = action.payload;
            const oldExp = expenses.find((r) => r.id === newExp.id);
            newExp.documents = oldExp?.documents;

            if (newExp.validFrom === oldExp?.validFrom) {
                const expIndex = expenses.findIndex((r) => {
                    return r.id === newExp.id;
                });
                state.expenses = [...expenses.slice(0, expIndex), newExp, ...expenses.slice(expIndex + 1)];
            } else {
                const expIndex = (state.expenses ?? []).findIndex((e) => {
                    return (e.validFrom ?? new Date()) <= newExp.validFrom;
                });
                const oldExpIndex = expenses.findIndex((r) => {
                    return r.id === newExp.id;
                });
                const exps = [...expenses.slice(0, oldExpIndex), ...expenses.slice(oldExpIndex + 1)];
                if (expIndex >= 0) {
                    const firstHalf = exps.slice(0, expIndex);
                    const secondHalf = exps.slice(expIndex);
                    state.expenses = [...firstHalf, newExp, ...secondHalf];
                } else {
                    state.expenses = exps.concat(newExp);
                }
            }
        },
        deleteExpense(state, action) {
            const expenses = state.expenses ?? [];
            const expIndex = expenses.findIndex((r) => {
                return r.id === action.payload.id;
            });
            if (expIndex >= 0) {
                state.expenses = [...expenses.slice(0, expIndex), ...expenses.slice(expIndex + 1)];
            }
        },
        deleteExpenseDoc: {
            reducer(state, action) {
                const { expense, expDocument } = action.payload;
                const expenses = state.expenses ?? [];
                const currExp = expenses.find((exp) => exp.id === expense.id);
                const currExpIndex = expenses.findIndex((e) => {
                    return currExp ? e.id === currExp.id : expense.id === e.id;
                });
                const expDocIndex = currExp?.documents?.findIndex((d) => {
                    return d.id === expDocument.id;
                });
                if (currExp?.documents !== undefined && expDocIndex !== undefined) {
                    const docs = [
                        ...currExp?.documents?.slice(0, expDocIndex),
                        ...currExp?.documents?.slice(expDocIndex + 1),
                    ];
                    currExp.documents = docs;
                }
                if (currExp) {
                    state.expenses = [...expenses.slice(0, currExpIndex), currExp, ...expenses.slice(currExpIndex + 1)];
                }
            },
            prepare(expense, expDocument) {
                return {
                    payload: { expense, expDocument },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
        addExpenseDoc: {
            reducer(state, action) {
                const { expense, expDocument } = action.payload;
                const expenses = state.expenses ?? [];
                const currExp = expenses.find((exp) => exp.id === expense.id);
                const currExpIndex = expenses.findIndex((exp) => exp.id === expense.id);
                if (expDocument && currExp) {
                    currExp.documents = (currExp?.documents ?? []).concat(expDocument);
                }
                if (currExp) {
                    state.expenses = [...expenses.slice(0, currExpIndex), currExp, ...expenses.slice(currExpIndex + 1)];
                }
            },
            prepare(expense, expDocument) {
                return {
                    payload: { expense, expDocument },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
    },
});

export const {
    setInitialExpenses,
    concatExpenses,
    addExpense,
    updateExpense,
    deleteExpense,
    deleteExpenseDoc,
    addExpenseDoc,
} = expensesSlice.actions;

export default expensesSlice.reducer;
