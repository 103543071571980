import {
    IconButton,
    FormControl,
    InputLabel,
    Fab,
    Button,
    Select,
    MenuItem,
    Tooltip,
    SelectChangeEvent,
    Stack,
    TextField,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { CalendarViewType, TimelineViewType } from '../../General/Extensions/Extensions';
import { TimelineViewTypeSR } from '../../General/Extensions/Localizations/Types/Common/SR/TimelineViewTypeSR';
import AddIcon from '@mui/icons-material/Add';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './TimelineViewFilterBar.css';

import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/sr';
import { useAppSelector } from '../../General/hooks/hooks';
import { RootState } from '../../General/Redux/store';
import { handleDateChange } from '../Helpers/TimelineViewDateHelper';
import { CompareTwoDatesEquality } from '../../Common/DateHandler';
import TableChartSharpIcon from '@mui/icons-material/TableChartSharp';
import { useSearchParams } from 'react-router-dom';

const strtHrs = [0, 1, 2, 3, 4, 5, 6, 7, 8];
const endHrs = [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

type TimelineViewFilterBarProps = {
    handleOpenShipmentForm(): void;
    handleOpenTransportWarrantForm(): void;
    startingHours: number;
    endingHours: number;
    setStartHours(startingHours: number): void;
    setEndHours(endingHours: number): void;
};

export default function TimelineViewFilterBar({
    handleOpenShipmentForm,
    handleOpenTransportWarrantForm,
    startingHours,
    endingHours,
    setStartHours,
    setEndHours,
}: TimelineViewFilterBarProps): ReactElement {
    const timelineViewType = useAppSelector((state: RootState) => state.calendarData.timelineViewType);
    const currentDate = useAppSelector((state: RootState) => state.calendarData.currentDate);

    const [searchParams, setSearchParams] = useSearchParams();

    const changeSearchDate = (currDate: Date) => {
        const spTwId = searchParams.get('twId');
        const spTimelineViewType = searchParams.get('timelineViewType');
        const spCalendarViewType = searchParams.get('calendarViewType');

        const search = {
            currentDate: currDate.toString(),
            twId: spTwId ?? '0',
            timelineViewType: spTimelineViewType ?? '',
            calendarViewType: spCalendarViewType ?? '',
        };

        setSearchParams(search, { replace: true });
    };

    const goToTableView = () => {
        const spCurrentDate = searchParams.get('currentDate');
        const spTwId = searchParams.get('twId');
        const spTimelineViewType = searchParams.get('timelineViewType');

        const search = {
            currentDate: spCurrentDate ?? '',
            twId: spTwId ?? '0',
            timelineViewType: spTimelineViewType ?? '',
            calendarViewType: CalendarViewType.TableView,
        };

        setSearchParams(search, { replace: true });
    };

    const changeSearchDateAndTimelineView = (currDate: Date, tmlnViewType: TimelineViewType) => {
        const spTwId = searchParams.get('twId');
        const spCalendarViewType = searchParams.get('calendarViewType');

        const search = {
            currentDate: currDate.toString(),
            twId: spTwId ?? '0',
            timelineViewType: tmlnViewType.toString(),
            calendarViewType: spCalendarViewType ?? '',
        };

        setSearchParams(search, { replace: true });
    };

    const changeSearchTimelineViewType = (tmlnViewType: TimelineViewType) => {
        const spCurrentDate = searchParams.get('currentDate');
        const spTwId = searchParams.get('twId');
        const spCalendarViewType = searchParams.get('calendarViewType');

        const search = {
            currentDate: spCurrentDate ?? '',
            twId: spTwId ?? '0',
            timelineViewType: tmlnViewType.toString(),
            calendarViewType: spCalendarViewType ?? '',
        };

        setSearchParams(search, { replace: true });
    };

    const handleChangeStartingHours = (event: SelectChangeEvent<number>): void => {
        const target = event.target;
        const value = +target.value;

        setStartHours(value);
    };

    const handleChangeEndingHours = (event: SelectChangeEvent<number>): void => {
        const target = event.target;
        const value = +target.value;

        setEndHours(value);
    };

    const handleChangeViewType = (): void => {
        if (timelineViewType === TimelineViewType.Day) {
            const { newCurrDate } = handleDateChange(TimelineViewType.Week, currentDate, currentDate, undefined);

            if (newCurrDate) {
                changeSearchDateAndTimelineView(newCurrDate, TimelineViewType.Week);
            } else {
                changeSearchTimelineViewType(TimelineViewType.Week);
            }
        } else {
            const { newCurrDate } = handleDateChange(TimelineViewType.Day, currentDate, currentDate, undefined);

            if (newCurrDate) {
                changeSearchDateAndTimelineView(newCurrDate, TimelineViewType.Day);
            } else {
                changeSearchTimelineViewType(TimelineViewType.Day);
            }
        }
    };

    const handleCurrentDateChange = (date?: Date, minus?: boolean): void => {
        const { newCurrDate } = handleDateChange(timelineViewType, currentDate, date, minus);

        if (newCurrDate) {
            changeSearchDate(newCurrDate);
        }
    };

    return (
        <div style={{ width: '100vw' }}>
            <div style={{ width: 30, height: 30, float: 'left', marginTop: 10, marginLeft: 10 }}>
                <Tooltip title="Tabelarni prikaz">
                    <Fab color="warning" size="small" onClick={goToTableView}>
                        <TableChartSharpIcon />
                    </Fab>
                </Tooltip>
            </div>
            <div className="filter-bar-container">
                <div className="filter-bar-flex-container">
                    <IconButton onClick={() => handleCurrentDateChange(undefined, true)} style={{ width: 24 }}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                disableMaskedInput
                                className="filter-bar-date-picker"
                                value={new Date(currentDate) ?? ''}
                                inputFormat="dddd DD.MM.YYYY"
                                onChange={(newValue) => {
                                    handleCurrentDateChange(newValue ? new Date(newValue) : undefined, undefined);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                    <IconButton onClick={() => handleCurrentDateChange(undefined, false)} style={{ width: 24 }}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                    <Button
                        variant="contained"
                        onClick={() => handleCurrentDateChange(new Date(), undefined)}
                        style={{ width: 70, fontSize: 12 }}
                        disabled={CompareTwoDatesEquality(new Date(currentDate), new Date())}
                    >
                        DANAS
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleChangeViewType()}
                        style={{ width: 70, fontSize: 12 }}
                    >
                        {TimelineViewTypeSR[timelineViewType as TimelineViewType]}
                    </Button>
                    <FormControl sx={{ m: 1, width: 70, height: 30, margin: 0 }} size="small">
                        <InputLabel id="startingHoursLabel" sx={{ color: 'white' }}>
                            Poč. h
                        </InputLabel>
                        <Select
                            labelId="startingHoursLabel"
                            label="Poč. h"
                            id="startingHoursSelect"
                            value={startingHours}
                            onChange={handleChangeStartingHours}
                            variant="outlined"
                            sx={{ width: 70, color: 'white', height: 30 }}
                            IconComponent={() => null}
                        >
                            {strtHrs?.map((sh) => {
                                return (
                                    <MenuItem key={sh} value={sh}>
                                        {sh}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 70, height: 30, margin: 0 }} size="small">
                        <InputLabel id="endingHoursLabel" sx={{ color: 'white' }}>
                            Zav. h
                        </InputLabel>
                        <Select
                            labelId="endingHoursLabel"
                            label="Zav. h"
                            id="endingHoursSelect"
                            value={endingHours}
                            sx={{ color: 'white', width: 70, height: 30 }}
                            onChange={handleChangeEndingHours}
                            variant="outlined"
                            IconComponent={() => null}
                        >
                            {endHrs?.map((eh) => {
                                return (
                                    <MenuItem key={eh} value={eh}>
                                        {eh}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Tooltip title="Dodaj isporuku">
                        <Fab
                            color="primary"
                            aria-label="add"
                            onClick={() => handleOpenShipmentForm()}
                            size="small"
                            style={{ marginTop: -5 }}
                        >
                            <LocalShippingIcon />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Rezerviši transport">
                        <Fab
                            color="primary"
                            aria-label="add"
                            onClick={() => handleOpenTransportWarrantForm()}
                            size="small"
                            style={{ marginTop: -5 }}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}
