import React, { ReactElement, useEffect, useState } from 'react';
import './Details.css';
import { Grid } from '@mui/material';
import { EntitiesType, VehicleEntityType } from '../../Extensions/Entities';
import { CarDto, TruckDto, TrailerDto, TruckType, TrailerType, EngineType } from '../../AutoGeneratedAPI/clientApi';
import { EngineTypeSR } from '../../Extensions/Localizations/Types/Vehicles/SR/EngineTypeSR';
import { TruckTypeSR } from '../../Extensions/Localizations/Types/Vehicles/SR/TruckTypeSR';
import { TrailerTypeSR } from '../../Extensions/Localizations/Types/Vehicles/SR/TrailerTypeSR';
import { useLocation } from 'react-router-dom';
import { isAppTypeCar, isAppTypeTruck, isAppTypeTrailer } from '../../Extensions/Selectors/Selectors';
import { useAppSelector } from '../../hooks/hooks';
import { RootState } from '../../Redux/store';

type VehicleDetailsProps = {
    entity: EntitiesType | null;
};

export default function VehicleDetails({ entity }: VehicleDetailsProps): ReactElement {
    const trucks = useAppSelector((state: RootState) => state.vehicles.trucks);
    const trailers = useAppSelector((state: RootState) => state.vehicles.trailers);
    const cars = useAppSelector((state: RootState) => state.vehicles.cars);

    const [currEntity, setCurrEntity] = useState<VehicleEntityType | undefined>(undefined);

    const location = useLocation();
    const path = location.pathname;

    const isCar = isAppTypeCar(path);
    const isTruck = isAppTypeTruck(path);
    const isTrailer = isAppTypeTrailer(path);

    useEffect(() => {
        if (entity) {
            if (isTruck) {
                const truck = trucks?.find((tr) => tr.id === entity.id);
                if (truck) {
                    setCurrEntity(truck);
                }
            }
            if (isTrailer) {
                const trailer = trailers?.find((tr) => tr.id === entity.id);
                if (trailer) {
                    setCurrEntity(trailer);
                }
            }
            if (isCar) {
                const car = cars?.find((c) => c.id === entity.id);
                if (car) {
                    setCurrEntity(car);
                }
            }
        }
    }, [entity]);

    return (
        <Grid container style={{ justifyContent: 'space-evenly' }} spacing={1}>
            <Grid key={0} item xs={12}>
                <p key={11} className="naming">
                    Proizvođač:
                </p>
                <p key={12} className="info">
                    {' '}
                    {currEntity !== undefined ? (currEntity as CarDto).manufacturer : ''}
                </p>
            </Grid>
            <Grid key={1} item xs={12}>
                <p key={21} className="naming">
                    Model:
                </p>
                <p key={22} className="info">
                    {' '}
                    {currEntity !== undefined ? (currEntity as CarDto).model : ''}
                </p>
            </Grid>
            <Grid key={10} item xs={12}>
                <p key={23} className="naming">
                    Godiste:
                </p>
                <p key={24} className="info">
                    {' '}
                    {currEntity ? new Date((currEntity as CarDto).productionDate as Date).getFullYear() : 0}
                </p>
            </Grid>
            <Grid key={2} item xs={12}>
                <p key={31} className="naming">
                    Broj šasije:
                </p>
                <p key={32} className="info">
                    {' '}
                    {currEntity !== undefined ? (currEntity as CarDto).chassisNumber : ''}
                </p>
            </Grid>
            <Grid key={3} item xs={12}>
                <p key={41} className="naming">
                    Registracija:
                </p>
                <p key={42} className="info">
                    {' '}
                    {currEntity !== undefined ? (currEntity as CarDto).licensePlateNum : ''}
                </p>
            </Grid>

            {isCar && (
                <Grid item xs={12} key={4}>
                    <p key={61} className="naming">
                        Tip goriva:
                    </p>
                    <p key={62} className="info">
                        {' '}
                        {currEntity ? EngineTypeSR[(currEntity as CarDto).engineType ?? EngineType.Diesel] : ''}
                    </p>
                </Grid>
            )}
            {isTruck && (
                <>
                    <Grid item xs={12} key={5}>
                        <p key={615} className="naming">
                            Tip goriva:
                        </p>
                        <p key={621} className="info">
                            {' '}
                            {currEntity ? EngineTypeSR[(currEntity as TruckDto).engineType ?? EngineType.Diesel] : ''}
                        </p>
                    </Grid>
                    <Grid item xs={12} key={67}>
                        <p key={71} className="naming">
                            Tip kamiona:
                        </p>
                        <p key={72} className="info">
                            {' '}
                            {currEntity
                                ? TruckTypeSR[(currEntity as TruckDto).truckType ?? TruckType.RegularTruck]
                                : ''}
                        </p>
                    </Grid>
                    <Grid item xs={12} key={68}>
                        <p key={71} className="naming">
                            Nosivost (kg):
                        </p>
                        <p key={72} className="info">
                            {' '}
                            {currEntity ? (currEntity as TruckDto).loadWeight ?? 0 : 0}
                        </p>
                    </Grid>
                    <Grid item xs={12} key={69}>
                        <p key={71} className="naming">
                            Nosivost (m&sup3;):
                        </p>
                        <p key={72} className="info">
                            {' '}
                            {currEntity ? (currEntity as TruckDto).loadVolume ?? 0 : 0}
                        </p>
                    </Grid>
                    <Grid item xs={12} key={678}>
                        <p key={71} className="naming">
                            Nosivost (paleta):
                        </p>
                        <p key={72} className="info">
                            {' '}
                            {currEntity ? (currEntity as TruckDto).loadPalletSpaces ?? 0 : 0}
                        </p>
                    </Grid>
                </>
            )}
            {isTrailer && (
                <>
                    <Grid item xs={12} key={7}>
                        <p key={611} className="naming">
                            Tip prikolice:
                        </p>
                        <p key={622} className="info">
                            {' '}
                            {currEntity
                                ? TrailerTypeSR[(currEntity as TrailerDto).trailerType ?? TrailerType.RegularTrailer]
                                : ''}
                        </p>
                    </Grid>
                    <Grid item xs={12} key={612}>
                        <p key={71} className="naming">
                            Nosivost (kg):
                        </p>
                        <p key={72} className="info">
                            {' '}
                            {currEntity ? (currEntity as TrailerDto).loadWeight ?? 0 : 0}
                        </p>
                    </Grid>
                    <Grid item xs={12} key={623}>
                        <p key={71} className="naming">
                            Nosivost (m&sup3;):
                        </p>
                        <p key={72} className="info">
                            {' '}
                            {currEntity ? (currEntity as TrailerDto).loadVolume ?? 0 : 0}
                        </p>
                    </Grid>
                    <Grid item xs={12} key={63}>
                        <p key={71} className="naming">
                            Nosivost (paleta):
                        </p>
                        <p key={72} className="info">
                            {' '}
                            {currEntity ? (currEntity as TrailerDto).loadPalletSpaces ?? 0 : 0}
                        </p>
                    </Grid>
                </>
            )}
            <Grid key={256} item xs={12}>
                <p key={31} className="naming">
                    Cena:
                </p>
                <p key={32} className="info">
                    {' '}
                    {currEntity !== undefined ? (currEntity as CarDto).amortisation ?? '' : ''}
                </p>
            </Grid>
            <Grid key={356} item xs={12}>
                <p key={41} className="naming">
                    Period otplate:
                </p>
                <p key={42} className="info">
                    {' '}
                    {currEntity !== undefined ? (currEntity as CarDto).depreciationPeriod ?? '' : ''}
                    {' god.'}
                </p>
            </Grid>
        </Grid>
    );
}
