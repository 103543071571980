/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RecordType } from '../../../../Extensions/Extensions';
import { RootState } from '../../../../Redux/store';
import ProfileCard from '../../../ProfileCards/ProfileCard';
import { VehicleExpenseDto } from '../../../../AutoGeneratedAPI/clientApi';
import '../Feed.css';
import { useRef } from 'react';
import useRecordsSearch from '../../../../Extensions/Hooks/RecordsHooks/RecordsSearchingHook';
import { useCallback } from 'react';
import { RecordSearchTokenModel } from '../../../../Extensions/models/RecordSearchTokenModel';
import EmptyPage from '../../../../EmptyPage';
import { RecordEntitiesType } from '../../../../Extensions/Entities';
import { Grid } from '@mui/material';
import { LoaderProgress } from '../../../../../Common/CommonComponents/Loader/LoaderProgress';
import { useParams } from 'react-router-dom';

type RecordFeedElementProps = {
    query: RecordSearchTokenModel;
    setRecordId(recordId: number): void;
};

export default function RecordFeedElement({ query, setRecordId }: RecordFeedElementProps): ReactElement {
    const records = useSelector((state: RootState) =>
        [...(state.records.records ?? [])].sort((r1, r2) => {
            return (r1?.validFrom ?? r1.created ?? new Date()) > (r2?.validFrom ?? r2.created ?? new Date()) ? -1 : 1;
        }),
    );
    const entity = useSelector((state: RootState) => state.currentEntity.entity);

    const { hasMore, loading, error } = useRecordsSearch(entity?.id ?? 0, query);

    const { recId } = useParams();

    const observer = useRef<IntersectionObserver>();
    const bottomRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (recId && !isNaN(+recId)) {
            if (!records?.find((r) => r.id === +recId)) {
                bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [recId, records]);

    const lastRecordRef = useCallback(
        (node: any) => {
            if (loading) {
                return;
            }

            if (observer.current) {
                observer.current.disconnect();
            }
            if (!records) {
                return null;
            }
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    let recIndex = -1;
                    let expIndex = -1;
                    for (let i = records.length - 1; i >= 0; i--) {
                        if ((records[i] as VehicleExpenseDto).price === undefined && recIndex === -1) {
                            recIndex = i;
                        } else if ((records[i] as VehicleExpenseDto).price !== undefined && expIndex === -1) {
                            expIndex = i;
                        }
                        if (recIndex !== -1 && expIndex !== -1) {
                            break;
                        }
                    }
                    if (records.length > 0) {
                        setRecordId(recIndex !== -1 ? records[recIndex].id ?? 0 : 0);
                    }
                }
            });

            if (node) {
                observer.current.observe(node);
            }
        },
        [loading, hasMore],
    );

    const ViewElement = () => {
        return (
            <>
                <Grid container spacing={3}>
                    {records ? (
                        records.map((record: RecordEntitiesType, index: number) => {
                            if (records.length === index + 1) {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        lg={4}
                                        xl={3}
                                        ref={lastRecordRef}
                                        key={(record.title ?? '') + (record.id ?? '1')}
                                    >
                                        <div
                                            style={{ display: 'inline', width: 0, height: 0, margin: 0, padding: 0 }}
                                            ref={bottomRef}
                                        />
                                        <ProfileCard
                                            record={record}
                                            recordType={
                                                (record as VehicleExpenseDto).price
                                                    ? RecordType.Expense
                                                    : RecordType.Record
                                            }
                                        />
                                    </Grid>
                                );
                            } else {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        lg={4}
                                        xl={3}
                                        key={(record.title ?? '') + (record.id ?? '1')}
                                    >
                                        <ProfileCard
                                            record={record}
                                            recordType={
                                                (record as VehicleExpenseDto).price
                                                    ? RecordType.Expense
                                                    : RecordType.Record
                                            }
                                        />
                                    </Grid>
                                );
                            }
                        })
                    ) : (
                        <EmptyPage title="Nema evidencija!" />
                    )}
                </Grid>
                <div>{loading && 'Učitava se...'}</div>
                <div>{error && 'Greška'}</div>
            </>
        );
    };
    if (!loading && !hasMore && (!records || records.length <= 0)) {
        return <EmptyPage title="Nema evidencija!" />;
    }

    if (loading && (!records || records.length <= 0)) {
        return (
            <div style={{ height: '60vh', width: '60vh', margin: 'auto' }}>
                <LoaderProgress />
            </div>
        );
    }
    return <ViewElement />;
}
