const radians = (degrees: number) => {
    const pi = Math.PI;
    return degrees * (pi / 180);
};

export const findOutTheDistanceBetweenTwoPoints = (lat1: number, lat2: number, lng1: number, lng2: number): number => {
    const R = 6373.0;

    const lat1Rad = radians(lat1);
    const lng1Rad = radians(lng1);
    const lat2Rad = radians(lat2);
    const lng2Rad = radians(lng2);

    const dlon = lng2Rad - lng1Rad;
    const dlat = lat2Rad - lat1Rad;

    const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dlon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;

    return distance;
};

export const airToLandDistanceCoefficient = (airDistance: number): number => {
    if (airDistance < 3) {
        return 2;
    }

    if (airDistance < 7) {
        return 1.5;
    }

    if (airDistance < 12) {
        return 1.3;
    }

    if (airDistance < 18) {
        return 1.2;
    }

    if (airDistance < 25) {
        return 1.1;
    }

    return 1;
};
