import { Grid, Typography } from '@mui/material';
import React, { ReactElement, useMemo } from 'react';
import { Droppable } from '@hello-pangea/dnd';
import styled from 'styled-components';
import { ShipmentDto, StopDto, StopType } from '../../../AutoGeneratedAPI/clientApi';
import { DistanceDuration } from '../../../../Common/Common';
import { FormatTimeText } from '../../../../Common/DateHandler';
import DNDTask from './DNDTask';
import CircleIcon from '@mui/icons-material/Circle';
import './DND.css';
import { useAppSelector } from '../../../hooks/hooks';
import { RootState } from '../../../Redux/store';
import { addSeconds } from 'date-fns';

const DNDColumnContainer = styled.div`
    margin: 8px;
`;

const DNDColumnTaskList = styled.div`
    height: calc(100vh - 265px);
    overflow-y: auto;
    padding-right: 10px;
`;

type DNDColumnProps = {
    shipments?: ShipmentDto[];
    stops?: StopDto[];
    openShipmentSideDetails(ship?: ShipmentDto): void;
    durations: DistanceDuration[];
};

export default function DNDColumn({
    shipments,
    stops,
    openShipmentSideDetails,
    durations,
}: DNDColumnProps): ReactElement {
    const transportWarrant = useAppSelector((state: RootState) => state.twFormData.transportWarrant);

    const lastPointTime = useMemo(() => {
        if (stops) {
            const lastStopTime = stops[stops.length - 1]?.endingTime;
            if (lastStopTime) {
                const lastDuration = durations[durations.length - 1];
                if (lastDuration) {
                    const newLastTime = addSeconds(lastStopTime, lastDuration.value);

                    return newLastTime;
                }

                return lastStopTime;
            }
        }
        return transportWarrant.actEndingTime ?? new Date();
    }, [durations, stops]);

    if (!stops) {
        return <></>;
    }
    return (
        <DNDColumnContainer>
            <Droppable droppableId={'shipments'}>
                {(provided) => (
                    <DNDColumnTaskList ref={provided.innerRef} {...provided.droppableProps}>
                        <Grid
                            key={'startingPoint'}
                            container
                            spacing={1}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: 5,
                                height: 60,
                            }}
                        >
                            <Grid
                                item
                                xs={3}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: 50,
                                    borderRight: '1px dashed black',
                                    position: 'relative',
                                    padding: 0,
                                    paddingLeft: 2,
                                }}
                                className="half-a-border-on-top"
                            >
                                <Typography
                                    variant="body2"
                                    component="p"
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontWeight: 'bold',
                                        flexGrow: 1,
                                        width: 50,
                                    }}
                                >
                                    {FormatTimeText(transportWarrant.actStartingTime ?? new Date())}
                                </Typography>
                                <CircleIcon
                                    style={{
                                        position: 'absolute',
                                        right: -5.8,
                                        top: 15,
                                        fontSize: 10,
                                        color: '#3277d5',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <div>Kuća</div>
                            </Grid>
                        </Grid>
                        {stops.map((stop, index) => {
                            const shipment = shipments?.find(
                                (ship) => ship.startingStopId === stop.id || ship.endingStopId === stop.id,
                            );
                            if (shipment) {
                                return (
                                    <Grid
                                        key={stop?.id}
                                        container
                                        spacing={1}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: 5,
                                            height: 60,
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={3}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: 50,
                                                borderRight: '1px dashed black',
                                                position: 'relative',
                                                padding: 0,
                                                paddingLeft: 2,
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                component="p"
                                                style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    fontWeight: 'bold',
                                                    flexGrow: 1,
                                                    width: 50,
                                                }}
                                            >
                                                {FormatTimeText(stop?.startingTime ?? new Date())}
                                            </Typography>
                                            <CircleIcon
                                                style={{
                                                    position: 'absolute',
                                                    right: -5.8,
                                                    fontSize: 10,
                                                    color: '#3277d5',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <DNDTask
                                                shipment={shipment}
                                                isStartingStop={stop.stopType === StopType.Loading}
                                                index={index}
                                                openShipmentSideDetails={openShipmentSideDetails}
                                                isTwFinished={transportWarrant.isTwFinished}
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            }
                        })}
                        <Grid
                            key={'endingPoint'}
                            container
                            spacing={1}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: 5,
                                height: 60,
                            }}
                        >
                            <Grid
                                item
                                xs={3}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: 50,
                                    borderRight: '1px dashed black',
                                    position: 'relative',
                                    padding: 0,
                                    paddingLeft: 2,
                                }}
                                className="half-a-border-on-bottom"
                            >
                                <Typography
                                    variant="body2"
                                    component="p"
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontWeight: 'bold',
                                        flexGrow: 1,
                                        width: 50,
                                    }}
                                >
                                    {FormatTimeText(lastPointTime)}
                                </Typography>
                                <CircleIcon
                                    style={{ position: 'absolute', right: -5.8, fontSize: 10, color: '#3277d5' }}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <div>Kuća</div>
                            </Grid>
                        </Grid>
                        {provided.placeholder}
                    </DNDColumnTaskList>
                )}
            </Droppable>
        </DNDColumnContainer>
    );
}
