import React, { ReactElement } from 'react';
import { useAppSelector } from '../../../hooks/hooks';
import { RootState } from '../../../Redux/store';
import FinishedTwTimelineViewElement from './FinishedTwTimelineViewElement';
import UnfinishedTwTimelineViewElement from './UnfinishedTwTimelineViewElement';

export default function TransportWarrantTimelineView(): ReactElement {
    const transportWarrant = useAppSelector((state: RootState) => state.twFormData.transportWarrant);

    return transportWarrant.isTwFinished ? <FinishedTwTimelineViewElement /> : <UnfinishedTwTimelineViewElement />;
}
