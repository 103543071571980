import { Fab, Tooltip, Stack, TextField } from '@mui/material';
import React, { ReactElement, useMemo } from 'react';
import './TableViewFilterBar.css';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/sr';
import { useAppSelector } from '../../General/hooks/hooks';
import { RootState } from '../../General/Redux/store';
import { FormatDateWithoutDay, GetStartOfTheMonth } from '../../Common/DateHandler';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import { useSearchParams } from 'react-router-dom';
import { CalendarViewType } from '../../General/Extensions/Extensions';
import { CSVLink } from 'react-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getCSVDataFromTWs, headers } from './CSVExportHelper';

export default function TableViewFilterBar(): ReactElement {
    const currentDate = useAppSelector((state: RootState) => state.calendarData.currentDate);
    const transportWarrants = useAppSelector((state: RootState) => state.calendarData.transportWarrants);
    const trucks = useAppSelector((state: RootState) => state.vehicles.trucks);
    const drivers = useAppSelector((state: RootState) => state.drivers.drivers);
    const graphDrivers = useAppSelector((state: RootState) => state.drivers.graphDrivers);

    const [searchParams, setSearchParams] = useSearchParams();

    const changeSearchDate = (currDate: Date) => {
        const spTwId = searchParams.get('twId');
        const spTimelineViewType = searchParams.get('timelineViewType');
        const spCalendarViewType = searchParams.get('calendarViewType');

        const search = {
            currentDate: currDate.toString(),
            twId: spTwId ?? '0',
            timelineViewType: spTimelineViewType ?? '',
            calendarViewType: spCalendarViewType ?? '',
        };

        setSearchParams(search, { replace: true });
    };

    const handleCurrentDateChange = (date?: Date): void => {
        if (date) {
            const startDate = GetStartOfTheMonth(date);

            if (startDate) {
                changeSearchDate(startDate);
            }
        }
    };

    const goToTimelineView = () => {
        const spCurrentDate = searchParams.get('currentDate');
        const spTwId = searchParams.get('twId');
        const spTimelineViewType = searchParams.get('timelineViewType');

        const search = {
            currentDate: spCurrentDate ?? '',
            twId: spTwId ?? '0',
            timelineViewType: spTimelineViewType ?? '',
            calendarViewType: CalendarViewType.TimelineView,
        };

        setSearchParams(search, { replace: true });
    };

    const csvData = useMemo(() => {
        if (transportWarrants && trucks && drivers && graphDrivers && currentDate) {
            return getCSVDataFromTWs(transportWarrants, trucks, drivers, graphDrivers, currentDate);
        }

        return [];
    }, [transportWarrants, drivers, graphDrivers, trucks, currentDate]);

    return (
        <div style={{ width: '100vw' }}>
            <div style={{ width: 30, height: 30, float: 'left', marginTop: 10, marginLeft: 10 }}>
                <Tooltip title="Kalendarski prikaz">
                    <Fab color="warning" size="small" onClick={goToTimelineView}>
                        <ViewTimelineOutlinedIcon />
                    </Fab>
                </Tooltip>
            </div>
            <div style={{ width: 30, height: 30, float: 'left', marginTop: 10, marginLeft: 20 }}>
                <Tooltip title="Skini u .csv formatu">
                    <Fab color="success" size="small">
                        <CSVLink
                            style={{ textDecoration: 'none', color: 'white' }}
                            data={csvData}
                            headers={headers}
                            filename={'Evidencije transporta ' + FormatDateWithoutDay(currentDate).toUpperCase()}
                        >
                            <Stack direction="row" alignItems="center" gap={1}>
                                <FileDownloadIcon />
                            </Stack>
                        </CSVLink>
                    </Fab>
                </Tooltip>
            </div>
            <div className="filter-bar-container">
                <div className="filter-bar-flex-container">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                views={['year', 'month']}
                                disableMaskedInput
                                className="filter-bar-date-picker"
                                value={new Date(currentDate) ?? ''}
                                inputFormat="MMMM YYYY."
                                disableFuture
                                onChange={(e) => console.log(e ?? new Date())}
                                onAccept={(newValue) => {
                                    handleCurrentDateChange(newValue ? new Date(newValue) : undefined);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </div>
            </div>
        </div>
    );
}
