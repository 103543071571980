import { addDays } from 'date-fns';
import { TransportWarrantDto, TruckDto } from '../../General/AutoGeneratedAPI/clientApi';

export type InitialType = {
    id: string;
    title: string;
    start: string;
    end: string;
    color: string;
    shipments: string[];
    loadingTime: number;
};

export const getEvent = (tw: TransportWarrantDto, mainVehicle: TruckDto, loadingTime?: number): InitialType => {
    // TODO: set title to be client info from shipment.invoice.client with invoice info (shipment.invoice.code)

    const title = mainVehicle.manufacturer ?? '';
    title.concat(mainVehicle.model ? ' ' + mainVehicle.model : '');
    title.concat(
        (mainVehicle.manufacturer || mainVehicle.model) && mainVehicle.licensePlateNum
            ? ', ' + mainVehicle.licensePlateNum
            : !mainVehicle.manufacturer && !mainVehicle.model && mainVehicle.licensePlateNum
            ? mainVehicle.licensePlateNum
            : '',
    );

    return {
        id: String(tw.id),
        title: title,
        start: new Date(tw.actStartingTime as Date).toISOString() ?? new Date().toISOString(),
        end: new Date(tw.actEndingTime as Date).toISOString() ?? new Date().toISOString(),
        color: tw.isTwFinished ? '#378006' : new Date(tw.actEndingTime as Date) < new Date() ? '#961134' : '#1976d2',
        shipments:
            tw.transportWarrantItems?.map((twi) => {
                return twi.shipment?.data.clientName + ' (' + twi.shipment?.data.invoiceCode + ')';
            }) ?? [],
        loadingTime: loadingTime ?? 0,
    };
};

export type EventDurationElements = {
    startingTimeInMinutes: number;
    durationInMinutes: number;
    hasStartedBeforeToday: boolean;
    willEndAfterToday: boolean;
};

// get startingTime, durationInMinutes, hasStartedBeforeToday, willEndAfterToday for starting and ending date, given current date and timespan
export const getEventDuration = (
    startingDate: string,
    endingDate: string,
    currentDate: Date,
    startingHours: number,
    endingHours: number,
): EventDurationElements => {
    let startingTimeMili = 0;
    let startingTimeInMinutes = 0;
    let durationInMinutes = 0;
    let hasStartedBeforeToday = false;
    let willEndAfterToday = false;

    const eventStart = new Date(startingDate);
    const eventStartMili = eventStart.getTime();
    const eventEnd = new Date(endingDate);
    const eventEndMili = eventEnd.getTime();

    const startHoursMili = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        startingHours,
        0,
        0,
        0,
    ).getTime();

    const endHoursMili = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        endingHours,
        0,
        0,
        0,
    ).getTime();

    const midnight = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

    const tomorrowMidnight = addDays(midnight, 1);

    const midnightMili = midnight.getTime();
    const tomorrowMidnightMili = tomorrowMidnight.getTime();

    if (startingHours > 0 || endingHours < 24) {
        if (eventStartMili < startHoursMili) {
            startingTimeMili = startHoursMili;
            startingTimeInMinutes = 0;
            hasStartedBeforeToday = true;
        } else {
            if (eventStartMili > endHoursMili) {
                startingTimeMili = 0;
                hasStartedBeforeToday = false;
            } else {
                startingTimeMili = Math.abs(eventStartMili - startHoursMili);
                startingTimeInMinutes = Math.floor(startingTimeMili / 1000 / 60);
                hasStartedBeforeToday = false;
            }
        }

        if (eventEndMili > endHoursMili) {
            if (eventStartMili > endHoursMili) {
                durationInMinutes = 0;
                willEndAfterToday = false;
            } else {
                const durationMili = endHoursMili - (eventStartMili < startHoursMili ? startHoursMili : eventStartMili);
                durationInMinutes = Math.floor(durationMili / 1000 / 60);
                willEndAfterToday = true;
            }
        } else {
            if (eventEndMili < startHoursMili) {
                durationInMinutes = 0;
                willEndAfterToday = false;
            } else {
                const durationMili = eventEndMili - (eventStartMili < startHoursMili ? startHoursMili : eventStartMili);
                durationInMinutes = Math.floor(durationMili / 1000 / 60);
                willEndAfterToday = false;
            }
        }
    } else {
        if (eventStartMili < midnightMili) {
            startingTimeMili = 0;
            startingTimeInMinutes = 0;
            hasStartedBeforeToday = true;
        } else {
            startingTimeMili = Math.abs(eventStartMili - midnightMili);
            startingTimeInMinutes = Math.floor(startingTimeMili / 1000 / 60);
            hasStartedBeforeToday = false;
        }

        if (eventEndMili > tomorrowMidnightMili) {
            const durationMili = tomorrowMidnightMili - (eventStartMili < midnightMili ? midnightMili : eventStartMili);
            durationInMinutes = Math.floor(durationMili / 1000 / 60);
            willEndAfterToday = true;
        } else {
            const durationMili = eventEndMili - (eventStartMili < midnightMili ? midnightMili : eventStartMili);
            durationInMinutes = Math.floor(durationMili / 1000 / 60);
            willEndAfterToday = false;
        }
    }

    return { startingTimeInMinutes, durationInMinutes, hasStartedBeforeToday, willEndAfterToday };
};
