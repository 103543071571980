export enum Environment {
    Dev = 'Dev',
    Int = 'Int',
    Ppe = 'Ppe',
    Prod = 'Prod',
}

export let ClientEnv: Environment = Environment.Dev;

const urlLocation: string = window.location.origin;

if (urlLocation.startsWith('https://app.granitdoo.rs')) {
    ClientEnv = Environment.Prod;
} else if (urlLocation.startsWith('https://app-int.granitdoo.rs')) {
    ClientEnv = Environment.Int;
} else if (urlLocation.startsWith('https://app-beta.granitdoo.rs')) {
    ClientEnv = Environment.Ppe;
} else {
    ClientEnv = Environment.Dev;
}

if (ClientEnv === Environment.Ppe) {
    document.title += ' - Beta';
} else if (ClientEnv === Environment.Int) {
    document.title += ' - Int';
} else if (ClientEnv === Environment.Dev) {
    document.title += ' - DEV';
}

export let baseUrl: string;

if (ClientEnv === Environment.Prod || ClientEnv === Environment.Ppe) {
    baseUrl = 'https://granitdoo.azurewebsites.net';
} else if (ClientEnv === Environment.Int) {
    baseUrl = 'https://ninegrt.azurewebsites.net';
} else if (ClientEnv === Environment.Dev) {
    baseUrl = 'https://localhost:44365';
    // baseUrl = "https://ninegrt.azurewebsites.net";
} else {
    throw Error('Environment error');
}
