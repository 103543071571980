import {
    Grid,
    Typography,
    InputAdornment,
    TextField,
    Stack,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import React, { ChangeEvent, ReactElement } from 'react';
import { EntityType, IKmEventDto, ITimeEventDto } from '../../AutoGeneratedAPI/clientApi';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import { isTimeEventInTimeRingValid } from './ExpenseFormHelper';
import { formatNumberTypeInput } from '../../../Common/Common';

type ExpenseEventFormElementProps = {
    currentTimeEvent?: ITimeEventDto;
    currentKmEvent?: IKmEventDto;
    handleEventInputElementChange(event: ChangeEvent<HTMLInputElement>): void;
    handleTimeEventTimeSpanInputElementChange(event: ChangeEvent<HTMLInputElement>): void;
    handleKmEventInputElementChange(event: ChangeEvent<HTMLInputElement>): void;
    handleTimeEventMarginInDaysChange(event: ChangeEvent<HTMLInputElement>): void;
    handleTimeEventRingDateChange(date?: Date): void;
    isTimeEventActive: boolean;
    isKmEventActive: boolean;
    isKmEventInKmRingActive: boolean;
    isTimeEventInTimeRingActive: boolean;
    toggleTimeEventActive?(): void;
    toggleKmEventActive?(): void;
    toggleTimeEventMode?(): void;
    toggleKmEventMode?(): void;
};

export default function ExpenseEventFormElement({
    currentTimeEvent,
    currentKmEvent,
    handleEventInputElementChange,
    handleTimeEventTimeSpanInputElementChange,
    handleKmEventInputElementChange,
    handleTimeEventMarginInDaysChange,
    handleTimeEventRingDateChange,
    isTimeEventActive,
    isKmEventActive,
    isKmEventInKmRingActive,
    isTimeEventInTimeRingActive,
    toggleTimeEventActive,
    toggleKmEventActive,
    toggleTimeEventMode,
    toggleKmEventMode,
}: ExpenseEventFormElementProps): ReactElement {
    const isTEventInTimeRingValid = isTimeEventInTimeRingValid(currentTimeEvent);

    return (
        <div
            style={{
                border: '1px solid lightgray',
                borderRadius: 5,
                padding: '40px',
                margin: 30,
                marginRight: 0,
                width: '100%',
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography component="h6" variant="h6">
                        Podsetnik
                        <hr />
                    </Typography>
                </Grid>
                {!toggleTimeEventActive && !toggleKmEventActive ? (
                    <></>
                ) : (
                    <Grid item xs={12}>
                        <FormGroup>
                            {toggleTimeEventActive && (
                                <FormControlLabel
                                    control={<Checkbox checked={isTimeEventActive} onChange={toggleTimeEventActive} />}
                                    label="Vremenski podsetnik"
                                />
                            )}
                            {toggleKmEventActive && (
                                <FormControlLabel
                                    control={<Checkbox checked={isKmEventActive} onChange={toggleKmEventActive} />}
                                    label="Kilometarski podsetnik"
                                />
                            )}
                        </FormGroup>
                    </Grid>
                )}

                <Grid key={148} item xs={12} md={6}>
                    <TextField
                        fullWidth
                        id="filled-basic"
                        label="Naslov"
                        error={!currentTimeEvent?.title}
                        name="title"
                        variant="outlined"
                        value={currentTimeEvent?.title ?? ''}
                        onChange={handleEventInputElementChange}
                    />
                </Grid>
                <Grid item xs={0} md={6} />
                {isTimeEventActive && (
                    <>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                    <Typography component="h6" variant="h6">
                                        Vremenski
                                    </Typography>
                                </FormLabel>
                                {currentTimeEvent?.entityType !== EntityType.Expense && toggleTimeEventMode && (
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={isTimeEventInTimeRingActive}
                                        name="radio-buttons-group"
                                        onChange={toggleTimeEventMode}
                                    >
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label="Zvoni za (interval)"
                                        />
                                        <FormControlLabel value={false} control={<Radio />} label="Zvoni datuma" />
                                    </RadioGroup>
                                )}
                            </FormControl>
                            <hr />
                        </Grid>
                        {isTimeEventInTimeRingActive ? (
                            <>
                                <Grid key={148} item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="filled-basic"
                                        label="Zvoni za"
                                        error={!isTEventInTimeRingValid}
                                        name="months"
                                        type="number"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mes.</InputAdornment>,
                                            inputProps: { min: 0 },
                                        }}
                                        variant="outlined"
                                        value={formatNumberTypeInput(currentTimeEvent?.inTimeRing?.months)}
                                        onChange={handleTimeEventTimeSpanInputElementChange}
                                    />
                                </Grid>
                                <Grid key={148} item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="filled-basic"
                                        label="Zvoni za"
                                        error={!isTEventInTimeRingValid}
                                        name="days"
                                        type="number"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">dana</InputAdornment>,
                                            inputProps: { min: 0 },
                                        }}
                                        variant="outlined"
                                        value={formatNumberTypeInput(currentTimeEvent?.inTimeRing?.days)}
                                        onChange={handleTimeEventTimeSpanInputElementChange}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12} md={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Datum zvonjenja"
                                            disableMaskedInput
                                            value={currentTimeEvent?.ringDate ?? new Date()}
                                            inputFormat="DD/MM/YYYY"
                                            minDate={dayjs(new Date())}
                                            onChange={(newValue) => {
                                                handleTimeEventRingDateChange(newValue?.toDate());
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                        )}
                        <Grid key={148} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                id="filled-basic"
                                label="Margina"
                                name="marginInDays"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">dana</InputAdornment>,
                                    inputProps: { min: 0 },
                                }}
                                variant="outlined"
                                value={formatNumberTypeInput(currentTimeEvent?.marginInDays)}
                                onChange={handleTimeEventMarginInDaysChange}
                            />
                        </Grid>
                    </>
                )}
                {isKmEventActive && (
                    <>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                    <Typography component="h6" variant="h6">
                                        Kilometarski
                                    </Typography>
                                </FormLabel>
                                {currentTimeEvent?.entityType !== EntityType.Expense && toggleKmEventMode && (
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={isKmEventInKmRingActive}
                                        name="radio-buttons-group"
                                        onChange={toggleKmEventMode}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="Zvoni ZA (km)" />
                                        <FormControlLabel value={false} control={<Radio />} label="Zvoni NA (km)" />
                                    </RadioGroup>
                                )}
                            </FormControl>
                            <hr />
                        </Grid>
                        {isKmEventInKmRingActive ? (
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="filled-basic"
                                    label="Zvoni za"
                                    error={!currentKmEvent?.inKmToRing}
                                    name="inKmToRing"
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                        inputProps: { min: 0 },
                                    }}
                                    variant="outlined"
                                    value={formatNumberTypeInput(currentKmEvent?.inKmToRing)}
                                    onChange={handleKmEventInputElementChange}
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="filled-basic"
                                    label="Zvoni na"
                                    error={!currentKmEvent?.kmWhenToRing}
                                    name="kmWhenToRing"
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                        inputProps: { min: 0 },
                                    }}
                                    variant="outlined"
                                    value={formatNumberTypeInput(currentKmEvent?.kmWhenToRing)}
                                    onChange={handleKmEventInputElementChange}
                                />
                            </Grid>
                        )}
                        <Grid key={148} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                id="filled-basic"
                                label="Margina"
                                name="kmMargin"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                    inputProps: { min: 0 },
                                }}
                                variant="outlined"
                                value={formatNumberTypeInput(currentKmEvent?.kmMargin)}
                                onChange={handleKmEventInputElementChange}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="filled-basic"
                        label="Opis podsetnika"
                        variant="outlined"
                        multiline
                        rows={6}
                        value={currentTimeEvent?.description ?? ''}
                        name="description"
                        onChange={handleEventInputElementChange}
                    />
                </Grid>
            </Grid>
        </div>
    );
}
