import React, { ReactElement, useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useAppDispatch } from '../General/hooks/hooks';
import { getEmployeeDataExtended, getEmployeeData } from '../General/Redux/Actions/Login/EmployeeActions';
import { useSelector } from 'react-redux';
import { isEmployeeAuthenticatedOB } from '../General/Redux/Selectors/EmployeeSelectors';
import AppWrapper from './AppWrapper';

export default function AuthenticationWrapper(): ReactElement {
    const isAuthenticated = useIsAuthenticated();
    const isAuthenticatedOB = useSelector(isEmployeeAuthenticatedOB);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { instance, accounts } = useMsal();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isAuthenticatedOB) {
            return;
        }

        dispatch(getEmployeeDataExtended());
    }, [isAuthenticatedOB, dispatch]);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        dispatch(getEmployeeData());
    }, [isAuthenticated, instance]);

    return (
        <>
            <AuthenticatedTemplate>
                <AppWrapper />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>You are not signed in! Please sign in.</p>
            </UnauthenticatedTemplate>
        </>
    );
}
