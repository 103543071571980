/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ShipmentStatus, TransportWarrantDto, TransportWarrantItemDto } from '../../../AutoGeneratedAPI/clientApi';
import { ServerResponseStatus } from '../../../Extensions/Extensions';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import {
    ExceptionsWithFetching,
    ExceptionsWithTransportWarrant,
    ExceptionsWithUpdating,
} from '../../../Extensions/Exceptions';
import {
    addTransportWarrants,
    updateTransportWarrant,
    deleteTransportWarrantItem,
    addTransportWarrantItem,
    setUpdateStatus,
} from '../../Reducers/Warrants/CalendarReducer';
import { addShipmentToBeginning } from '../../Reducers/Shipments/ShipmentReducer';
import {
    addInitialTWItemForTw,
    removeInitialTWItemForTw,
    setTransportWarrant,
} from '../../Reducers/Warrants/TransportWarrantFormReducer';

export const fetchInitialTransportWarrants = (startingDate: Date, endingDate: Date, includeTwItems: boolean) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    GranitClient.getTransportWarrantsForDateRange(startingDate, endingDate, includeTwItems).then(
        (result) => {
            dispatch(addTransportWarrants(result));
        },
        (error) => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_TRANSPORTWARRANTS);
            console.log(error);
        },
    );
};

export const updateOldTransportWarrant = (
    transportWarrant: TransportWarrantDto | undefined,
    setPendingStatus: boolean,
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
    GranitClient.updateTransportWarrant(
        transportWarrant !== undefined ? (transportWarrant.id as number) : 0,
        transportWarrant as TransportWarrantDto,
    ).then(
        (result) => {
            dispatch(setUpdateStatus(setPendingStatus ? ServerResponseStatus.Pending : ServerResponseStatus.Success));
            dispatch(updateTransportWarrant(result));
            dispatch(setTransportWarrant(result));
        },
        (error) => {
            let message =
                ExceptionsWithUpdating.EXCEPTION_UPDATING_TRANSPORTWARRANTS +
                    '\n\n' +
                    ExceptionsWithTransportWarrant.find((e) => e.code === error.messageCode)?.value ??
                error.messageCode;
            if (error.messageCode === ExceptionsWithTransportWarrant[2].code) {
                if (error.messageValue && error.messageValue !== '') {
                    const kms = error.messageValue.split(',');
                    const startKm = +kms[0];
                    const endKm = +kms[1];

                    if (startKm > 0) {
                        if (endKm > 0) {
                            message += `. Kilometraža mora biti između ${startKm} - ${endKm}`;
                        } else {
                            message += `. Kilometraža mora biti veća od ${startKm}`;
                        }
                    } else if (endKm > 0) {
                        message += `. Kilometraža mora biti manja od ${endKm}`;
                    }
                }
            }
            alert(message);
            dispatch(setUpdateStatus(ServerResponseStatus.Error));
        },
    );
};

export const deleteOldTransportWarrantItem = (transportWarrantItem: TransportWarrantItemDto | undefined) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    GranitClient.deleteTransportWarrantItem(
        transportWarrantItem?.id ?? -1,
        transportWarrantItem as TransportWarrantItemDto,
    ).then(
        () => {
            dispatch(deleteTransportWarrantItem(transportWarrantItem));
            dispatch(removeInitialTWItemForTw(transportWarrantItem?.id));
            GranitClient.getShipment(transportWarrantItem?.shipmentId ?? 0, true).then((res) => {
                const newShip = res;
                newShip.shipmentStatus = ShipmentStatus.Pending;
                GranitClient.updateShipment(newShip.id ?? 0, newShip).then((resShip) => {
                    const finalShip = resShip;
                    finalShip.startingStop = newShip.startingStop;
                    finalShip.endingStop = newShip.endingStop;
                    dispatch(addShipmentToBeginning(finalShip));
                });
            });
        },
        (error) => {
            alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_TRANSPORTWARRANTS);
            console.log(error.message);
        },
    );
};

export const addNewTransportWarrantItem = (transportWarrantItem: TransportWarrantItemDto | undefined) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    GranitClient.addNewTransportWarrantItem(transportWarrantItem as TransportWarrantItemDto).then(
        (res) => {
            const ship = res.shipment;
            ship.shipmentStatus = ShipmentStatus.PreTransit;
            GranitClient.updateShipment(ship.id ?? 0, ship).then(
                (resShip) => {
                    const twi = res;
                    twi.shipment = resShip;
                    dispatch(addTransportWarrantItem(twi));
                    dispatch(addInitialTWItemForTw(twi));
                },
                () => {
                    dispatch(addTransportWarrantItem(res));
                    dispatch(addInitialTWItemForTw(res));
                },
            );
        },
        (error) => {
            alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_TRANSPORTWARRANTS);
            console.log(error.message);
        },
    );
};
