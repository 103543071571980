import React, { ReactElement } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

type FormTemplateProps = {
    title: string;
    submit(): void;
    close(): void;
    shouldDisable: boolean;
    formContent: ReactElement;
    steps: string[];
    activeStep: number;
    handleNext(): void;
    handleBack(): void;
    disableBack?: boolean;
};

export default function FormTemplate({
    title,
    submit,
    close,
    shouldDisable,
    formContent,
    steps,
    activeStep,
    handleNext,
    handleBack,
    disableBack,
}: FormTemplateProps): ReactElement {
    return (
        <>
            <main className="custom-stepper-form-main">
                <div className="custom-form-close" onClick={close}>
                    x
                </div>
                <Paper className="custom-stepper-form-paper">
                    <Typography component="h4" variant="h4" align="center">
                        {title}
                    </Typography>
                    <Stepper
                        activeStep={activeStep}
                        style={{
                            paddingBottom: 30,
                            paddingTop: 22,
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <>
                        {formContent}
                        <div className="custom-form-buttons">
                            {activeStep !== 0 && !disableBack && (
                                <Button onClick={handleBack} className="custom-form-button">
                                    Nazad
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    activeStep === steps.length - 1 ? submit() : handleNext();
                                }}
                                disabled={shouldDisable}
                                className="custom-form-button"
                            >
                                {activeStep === steps.length - 1 ? 'Sačuvaj' : 'Dalje'}
                            </Button>
                        </div>
                    </>
                </Paper>
            </main>
        </>
    );
}
