import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import '../TransportWarrantForm.css';
import { RootState } from '../../../Redux/store';
import VehicleCalendarElement from '../../../../Vehicles/VehiclesCalendar/VehicleCalendarElement';
import { useAppSelector } from '../../../hooks/hooks';

type VehiclesFormProps = {
    handleVehicleAdding(truckId: number): void;
    currentVehicle: number;
};

export default function VehiclesElement({ handleVehicleAdding, currentVehicle }: VehiclesFormProps): ReactElement {
    const trucks = useAppSelector((state: RootState) => state.vehicles.trucks ?? []);

    const onClick = (value: number): void => {
        handleVehicleAdding(value);
    };
    return (
        <div className="scrollable">
            <Grid container spacing={1} className="tw-grid-container" style={{ justifyContent: 'flex-start' }}>
                {trucks
                    .filter((tr) => !tr.isDeleted)
                    .map((truck) => (
                        <Grid key={truck.id} item xs={12} lg={6}>
                            <a onClick={() => onClick(truck.id as number)}>
                                <div
                                    style={{
                                        width: 'inherit',
                                        height: 50,
                                        border:
                                            currentVehicle === truck.id ? '1px solid orange' : '1px solid lightgray',
                                        borderRadius: 5,
                                        cursor: 'pointer',
                                        padding: 4,
                                    }}
                                >
                                    <VehicleCalendarElement vehicle={truck} />
                                </div>
                            </a>
                        </Grid>
                    ))}
            </Grid>
        </div>
    );
}
