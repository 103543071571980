import { Modal } from '@mui/material';
import React, { ReactElement, Ref, forwardRef, useImperativeHandle, useState } from 'react';
import SaveUnsavedShipmentsChangesAlert from './SaveUnsavedShipmentsChangesAlert';

export interface SaveUnsavedShipmentsChangesAlertModalRefType {
    handleOpenSaveUnsavedShipmentsChangesAlert: (toggleTwForm: boolean, toggleShipmentForm: boolean) => void;
}

type SaveUnsavedShipmentsChangesAlertModalProps = {
    openTwForm(): void;
    openShipmentForm(): void;
    closeTWSideDetails(): void;
};

const frComponent = forwardRef(function SaveUnsavedShipmentsChangesAlertModal(
    { openTwForm, openShipmentForm, closeTWSideDetails }: SaveUnsavedShipmentsChangesAlertModalProps,
    ref: Ref<SaveUnsavedShipmentsChangesAlertModalRefType>,
): ReactElement {
    const [openSaveUnsavedShipmentsChangesAlert, setOpenSaveUnsavedShipmentsChangesAlert] = useState<boolean>(false);
    const [shouldOpenTwForm, setShouldOpenTwForm] = useState<boolean>(false);
    const [shouldOpenShipmentForm, setShouldOpenShipmentForm] = useState<boolean>(false);

    useImperativeHandle(
        ref,
        () => {
            return {
                handleOpenSaveUnsavedShipmentsChangesAlert(toggleTwForm: boolean, toggleShipmentForm: boolean) {
                    setShouldOpenTwForm(toggleTwForm);
                    setShouldOpenShipmentForm(toggleShipmentForm);
                    setOpenSaveUnsavedShipmentsChangesAlert(true);
                },
            };
        },
        [],
    );

    const handleCloseSaveUnsavedShipmentsChangesAlert = () => {
        setOpenSaveUnsavedShipmentsChangesAlert(false);
    };

    const handleCloseTwSideDetailsFromShipmentsChangesAlert = () => {
        if (shouldOpenTwForm) {
            openTwForm();
        } else if (shouldOpenShipmentForm) {
            openShipmentForm();
        } else {
            closeTWSideDetails();
        }
        handleCloseSaveUnsavedShipmentsChangesAlert();
    };

    return (
        <Modal
            className="modalStyle"
            open={openSaveUnsavedShipmentsChangesAlert}
            aria-labelledby="add-vehicle-form"
            aria-describedby="adding a vehicle to a database through this form"
        >
            <div>
                <SaveUnsavedShipmentsChangesAlert
                    closeTwSideDetails={handleCloseTwSideDetailsFromShipmentsChangesAlert}
                    closeAlert={handleCloseSaveUnsavedShipmentsChangesAlert}
                />
            </div>
        </Modal>
    );
});

export default frComponent;
