import React, { useState, ReactElement, ChangeEvent } from 'react';
import './Forms.css';
import { Button, Grid, TextField, Typography, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateOldDriver } from '../Redux/Actions/Drivers/DriversAction';
import { RootState } from '../Redux/store';
import { IDriverDto, DriverDto } from '../AutoGeneratedAPI/clientApi';
import { useEffect } from 'react';
import 'dayjs/locale/sr';
import { useLocation } from 'react-router-dom';
import { isUpdateForm } from '../Extensions/Selectors/Selectors';

type DriverFormProps = {
    closeForm(): void;
};

export default function DriverForm({ closeForm }: DriverFormProps): ReactElement {
    const [currDriver, setCurrDriver] = useState<IDriverDto>(new DriverDto());

    const location = useLocation();
    const path = location.pathname;

    const isUpdateFrm = isUpdateForm(path);
    const driver = useSelector((state: RootState) => state.currentEntity.entity as DriverDto);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isUpdateFrm && driver) {
            setCurrDriver(driver);
        } else {
            setCurrDriver({ ...currDriver, birthDate: new Date() });
        }
    }, [driver, isUpdateFrm]);

    const handleInpuElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setCurrDriver({ ...currDriver, [name]: value });
    };

    const onSubmit = (): void => {
        if (isUpdateFrm) {
            dispatch(updateOldDriver(new DriverDto(currDriver), driver));
        }
        closeForm();
    };

    const checkIfDataIsChanged = (): boolean => {
        const currentDriver = currDriver;
        const currEntity = driver;
        const driverBirthDate = new Date(currentDriver?.birthDate as Date);
        const entityBirthDate = currEntity ? new Date((currEntity as DriverDto).birthDate as Date) : new Date();

        if (isUpdateFrm && currEntity && currentDriver) {
            return (
                currentDriver?.ucin === currEntity.ucin &&
                currentDriver?.personalNumber === currEntity.personalNumber &&
                currentDriver?.tcNumber === currEntity.tcNumber &&
                driverBirthDate.getFullYear() === entityBirthDate.getFullYear() &&
                driverBirthDate.getMonth() === entityBirthDate.getMonth() &&
                driverBirthDate.getDay() === entityBirthDate.getDay()
            );
        }
        return false;
    };

    const checkIfRequiredIsEmpty = (): boolean => {
        return currDriver?.tcNumber && currDriver?.personalNumber && currDriver?.ucin && currDriver?.birthDate
            ? false
            : true;
    };

    const formTitle = isUpdateFrm ? 'Promenite detalje vozača' : 'Ubacite novog vozača';

    const isDataChanged = !checkIfDataIsChanged();

    const isRequiredEmpty = checkIfRequiredIsEmpty();

    const DriverFormElement = (): ReactElement => {
        return (
            <>
                <Grid container spacing={5}>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            error={currDriver?.tcNumber && currDriver?.tcNumber !== 'empty' ? false : true}
                            required={true}
                            fullWidth
                            id="filled-basic"
                            name="tcNumber"
                            label="Br. taho kartice"
                            value={currDriver?.tcNumber && currDriver?.tcNumber !== 'empty' ? currDriver?.tcNumber : ''}
                            variant="outlined"
                            onChange={handleInpuElementChange}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <>
            <main className="custom-form-main">
                <div className="custom-form-close" onClick={closeForm}>
                    x
                </div>
                <Paper className="custom-form-paper">
                    <Typography component="h4" variant="h4" align="center" style={{ marginBottom: 50 }}>
                        {formTitle}
                    </Typography>

                    <div style={{ height: 'calc(80vh - 200px)', overflowX: 'hidden', overflowY: 'auto', padding: 20 }}>
                        {DriverFormElement()}
                    </div>
                </Paper>
                <div style={{ position: 'absolute', bottom: 20, right: 50, width: 200 }}>
                    <Button
                        disabled={isDataChanged && !isRequiredEmpty ? false : true}
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onSubmit();
                        }}
                    >
                        SAČUVAJ
                    </Button>
                </div>
            </main>
        </>
    );
}
