import * as React from 'react';
import { ReactElement } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import '../Profile.css';

type ImportantDocsMenuProps = {
    anchorEl: HTMLElement | null;
    handleCloseAnchor(): void;
    handleOpenModal(): void;
    openDeleteModal(): void;
};

export default function ImportantDocsMenu({
    anchorEl,
    handleOpenModal,
    handleCloseAnchor,
    openDeleteModal,
}: ImportantDocsMenuProps): ReactElement {
    const handleItemClick = () => {
        handleCloseAnchor();
        handleOpenModal();
    };

    const handleDeleteModalOpen = () => {
        openDeleteModal();
    };

    return (
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseAnchor}>
            <MenuItem key={1} onClick={() => handleItemClick()} style={{ whiteSpace: 'normal' }}>
                <span className="icon">
                    <VisibilityIcon />
                </span>
                <Typography variant="body1" color="textPrimary" component="p">
                    Otvorite dokument
                </Typography>
            </MenuItem>
            <MenuItem key={5} onClick={() => handleDeleteModalOpen()} style={{ whiteSpace: 'normal' }}>
                <span className="icon">
                    <DeleteIcon />
                </span>
                <Typography variant="body1" color="textPrimary" component="p">
                    Izbrišite dokument
                </Typography>
            </MenuItem>
        </Menu>
    );
}
