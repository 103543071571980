import React, { ReactElement } from 'react';
import './Feed.css';
import { RootState } from '../../../Redux/store';
import { RecordType } from '../../../Extensions/Extensions';
import RecordsFeed from './RecordsFeed';
import ExpensesFeed from './ExpensesFeed';
import InvoicesFeed from './InvoicesFeed';
import ImportantDocsFeed from './ImportantDocsFeed';
import TWFeed from './TWFeed';
import ProjectsFeed from './ProjectsFeed';
import { useAppSelector } from '../../../hooks/hooks';

export default function Feed(): ReactElement {
    const recordType = useAppSelector((state: RootState) => state.recordTypes.recordType);

    if (recordType !== null) {
        switch (recordType) {
            case RecordType.Record:
                return <RecordsFeed />;
            case RecordType.Expense:
                return <ExpensesFeed />;
            case RecordType.ImportantDocument:
                return <ImportantDocsFeed />;
            case RecordType.TravelExpense:
                return <TWFeed />;
            case RecordType.Projects:
                return <ProjectsFeed />;
            case RecordType.Invoices:
                return <InvoicesFeed />;
            default:
                return <></>;
        }
    }

    return <></>;
}
