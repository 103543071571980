import { msalInstance } from '../../auth/AuthProvider';
import { protectedResources } from '../../authConfig';
import { baseUrl } from '../../environment/environment';
import { Client, IClient, IVNextClient, VNextClient } from './clientApi';

let globalToken = '';

export function setToken(token: string): void {
    globalToken = token;
}

function httpFetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
    if (init && init.headers) {
        init.headers = {
            ...init?.headers,
            Authorization: globalToken,
        };
    }

    return fetch(url, init);
}

function GranitClientApi() {
    const clientApi = new Client(baseUrl, {
        fetch: httpFetch,
    });

    return clientApi;
}

async function httpFetchVNext(url: RequestInfo, init?: RequestInit): Promise<Response> {
    if (init && init.headers) {
        const allAccounts = msalInstance.getAllAccounts();

        // console.log(allAccounts);

        const granitApiRequest = {
            ...{ scopes: protectedResources.granitApi.scopes },
            account: allAccounts[0],
        };

        const tokenPromise = msalInstance.acquireTokenSilent(granitApiRequest);
        const token = await tokenPromise;

        init.headers = {
            ...init?.headers,
            Authorization: 'Bearer ' + token.accessToken,
            'X-AuthSchema': 'AzureAD',
        };
    }

    return fetch(url, init);
}

function GranitClientApiVNext() {
    const clientApi = new VNextClient(baseUrl, {
        fetch: httpFetchVNext,
    });

    return clientApi;
}

export const GranitClient: IClient = GranitClientApi();
export const GranitVNextClient: IVNextClient = GranitClientApiVNext();

export const getBaseServerLink = (append: string): string => {
    return baseUrl + append;
};
