import { Tooltip } from '@mui/material';
import React, { ReactElement, useMemo, useState } from 'react';
import { getPercentage } from '../../../../Common/Common';
import { formatNumberOnTwoDigits, getTimeDifference, TimeSpan } from '../../../../Common/DateHandler';
import { useAppSelector } from '../../../hooks/hooks';
import { RootState } from '../../../Redux/store';
import {
    getStartingLoadingTime,
    getTimeStamps,
    getTooltipTitleForLoading,
    getTooltipTitleForTransport,
    getTooltipTitleForUnloading,
} from './TransportWarrantTimelineViewHelper';
import {
    TWTimelineViewContainer,
    TWTimelineViewLoadingUnloadingElement,
    TWTimelineViewTimestampElement,
    TWTimelineViewTransportElement,
    TimestampsContainer,
} from './TransportWarrantTimelineViewStyled';
import { RequestOriginApp, ShipmentDto } from '../../../AutoGeneratedAPI/clientApi';

export default function UnfinishedTwTimelineViewElement(): ReactElement {
    const [timeStamps, setTimeStamps] = useState<TimeSpan[]>([]);

    const transportWarrant = useAppSelector((state: RootState) => state.twFormData.transportWarrant);
    const distances = useAppSelector((state: RootState) => state.twFormData.stopsDistances);
    const durations = useAppSelector((state: RootState) => state.twFormData.stopsDurations);
    const currShipments = useAppSelector((state: RootState) => state.twFormData.currentTwInitialShipments);
    const currStops = useAppSelector((state: RootState) => state.twFormData.currentTwInitialStops);

    const loadingTimeInSeconds = useMemo(() => {
        return currShipments.reduce((acc, sh) => {
            if (sh.requestOriginApp !== RequestOriginApp.Transport) {
                const diffTimeSpan = getTimeDifference(sh.startingStop.endingTime, sh.startingStop.startingTime);
                const loadingTimeInMinutes = diffTimeSpan.hours * 60 + diffTimeSpan.minutes;

                return acc + loadingTimeInMinutes * 60;
            }
            return acc;
        }, 0);
    }, [currShipments]);

    const totalUnloadingTimeInSeconds = useMemo(() => {
        return currShipments.reduce((acc, sh) => {
            const diffTimeSpan = getTimeDifference(sh.endingStop.endingTime, sh.endingStop.startingTime);
            let unloadingTimeInMinutes = diffTimeSpan.hours * 60 + diffTimeSpan.minutes;

            if (sh.requestOriginApp === RequestOriginApp.Transport) {
                const diffTimeSpanStart = getTimeDifference(sh.startingStop.endingTime, sh.startingStop.startingTime);
                unloadingTimeInMinutes += diffTimeSpanStart.hours * 60 + diffTimeSpanStart.minutes;
            }

            return acc + unloadingTimeInMinutes * 60;
        }, 0);
    }, [currShipments]);

    const twStartingLoadingTime = getStartingLoadingTime(transportWarrant, loadingTimeInSeconds);

    const totalDuration = useMemo(() => {
        if (durations) {
            const totalCalculatedDuration =
                durations.reduce((acc, dur) => {
                    return acc + dur?.value;
                }, 0) +
                loadingTimeInSeconds +
                totalUnloadingTimeInSeconds;

            const twCalculatedTime = totalCalculatedDuration - loadingTimeInSeconds;

            const tStamps = getTimeStamps(transportWarrant.actStartingTime, twCalculatedTime, loadingTimeInSeconds);

            setTimeStamps(tStamps);

            return totalCalculatedDuration;
        }

        return 0;
    }, [durations, transportWarrant]);

    return (
        <TWTimelineViewContainer>
            {currStops !== undefined && currStops.length > 0 && (
                <Tooltip title="Utovar">
                    <TWTimelineViewLoadingUnloadingElement
                        key="loading"
                        $width={getPercentage(totalDuration, loadingTimeInSeconds)}
                    />
                </Tooltip>
            )}
            <TimestampsContainer>
                {timeStamps.map((ts) => {
                    const diffInSeconds =
                        ((ts.hours - twStartingLoadingTime.getHours()) * 60 +
                            ts.minutes -
                            twStartingLoadingTime.getMinutes()) *
                        60;
                    return (
                        <TWTimelineViewTimestampElement
                            key={ts.hours + ', ' + ts.minutes + ',' + diffInSeconds}
                            $left={getPercentage(totalDuration, diffInSeconds)}
                        >
                            {formatNumberOnTwoDigits(ts.hours)}:{formatNumberOnTwoDigits(ts.minutes)}
                        </TWTimelineViewTimestampElement>
                    );
                })}
            </TimestampsContainer>
            {durations.map((dur, index) => {
                const stop = currStops[index];
                let title = '';
                const shipmentWithStartingStop = currShipments.find((cs) => cs.startingStopId === stop?.id);
                const shipmentWithEndingStop = currShipments.find((cs) => cs.endingStopId === stop?.id);
                let unloadingTimeInSeconds = 0;
                let shipment = new ShipmentDto();
                if (shipmentWithEndingStop) {
                    title = getTooltipTitleForUnloading(shipmentWithEndingStop);
                    const unloadingTimeSpan = getTimeDifference(
                        shipmentWithEndingStop?.endingStop?.endingTime ?? new Date(),
                        shipmentWithEndingStop?.endingStop?.startingTime ?? new Date(),
                    );
                    unloadingTimeInSeconds = unloadingTimeSpan.hours * 3600 + unloadingTimeSpan.minutes * 60;
                    shipment = shipmentWithEndingStop;
                } else if (shipmentWithStartingStop) {
                    title = getTooltipTitleForLoading(shipmentWithStartingStop);
                    const unloadingTimeSpan = getTimeDifference(
                        shipmentWithStartingStop?.startingStop?.endingTime ?? new Date(),
                        shipmentWithStartingStop?.startingStop?.startingTime ?? new Date(),
                    );
                    unloadingTimeInSeconds = unloadingTimeSpan.hours * 3600 + unloadingTimeSpan.minutes * 60;
                    shipment = shipmentWithStartingStop;
                }
                return (
                    <React.Fragment key={Math.random()}>
                        {dur?.value !== undefined && dur?.value > 0 && (
                            <Tooltip title={getTooltipTitleForTransport(index, distances, durations, stop, shipment)}>
                                <TWTimelineViewTransportElement
                                    key={dur?.index * 2 + Math.random()}
                                    $width={getPercentage(totalDuration, dur?.value ?? 0)}
                                />
                            </Tooltip>
                        )}
                        {index < durations.length - 1 && (
                            <Tooltip title={title}>
                                <TWTimelineViewLoadingUnloadingElement
                                    key={dur?.index * 3 + Math.random()}
                                    $width={getPercentage(totalDuration, unloadingTimeInSeconds)}
                                />
                            </Tooltip>
                        )}
                    </React.Fragment>
                );
            })}
        </TWTimelineViewContainer>
    );
}
