import {
    CompareTwoDatesWithoutDayEquality,
    FormatDateWithDayNameTextAtTheEnd,
    FormatTimeText,
    getTimeDifferenceForTwoDates,
} from '../../Common/DateHandler';
import { CompleteUserInfoDto } from '../../General/AutoGeneratedAPI/MSGraph/msGraphApi';
import { DriverDto, TransportWarrantDto, TruckDto } from '../../General/AutoGeneratedAPI/clientApi';
import { RequestOriginAppSR } from '../../General/Extensions/Localizations/Types/Shipment/SR/RequestOriginAppSR';

export type CSVDataType = {
    rb: number;
    date: string;
    truck: string;
    reg: string;
    driver: string;
    customer: string;
    invoiceName: string;
    invoiceCode: string;
    requestOriginApp: string;
    loadValue: string | number;
    address: string;
    transportPrice: string | number;
    loadingDuration: string;
    loadingStartingTime: string;
    loadingEndingTime: string;
    actStartingTime: string;
    actEndingTime: string;
    actTransportDuration: string;
    estStartingTime: string;
    estEndingTime: string;
    estTransportDuration: string;
    loadPaletSpaces: string | number;
    loadWeight: string | number;
    startingKm: string | number;
    endingKm: string | number;
    transportKmDistance: string | number;
};

export const noInfo = 'Nema informacija';

export const headers = [
    { label: 'RB', key: 'rb' },
    { label: 'Datum', key: 'date' },
    { label: 'Kamion', key: 'truck' },
    { label: 'Reg. br.', key: 'reg' },
    { label: 'Vozač', key: 'driver' },
    { label: 'Kupac', key: 'customer' },
    { label: 'Dokument', key: 'invoiceName' },
    { label: 'Br. dok.', key: 'invoiceCode' },
    { label: 'Podnosilac zahteva', key: 'requestOriginApp' },
    { label: 'Cena robe', key: 'loadValue' },
    { label: 'Adresa istovara', key: 'address' },
    { label: 'Cena transporta', key: 'transportPrice' },
    { label: 'Trajanje utovara', key: 'loadingDuration' },
    { label: 'Početak utovara', key: 'loadingStartingTime' },
    { label: 'Kraj utovara', key: 'loadingEndingTime' },
    { label: 'Pravo vreme polaska', key: 'actStartingTime' },
    { label: 'Pravo vreme dolaska', key: 'actEndingTime' },
    { label: 'Pravo vreme u transportu', key: 'actTransportDuration' },
    { label: 'Pretp. vreme polaska', key: 'estStartingTime' },
    { label: 'Pretp. vreme dolaska', key: 'estEndingTime' },
    { label: 'Pretp. vreme u transportu', key: 'estTransportDuration' },
    { label: 'Br. paleta', key: 'loadPaletSpaces' },
    { label: 'Težina isporuke', key: 'loadWeight' },
    { label: 'Početna km', key: 'startingKm' },
    { label: 'Završna km', key: 'endingKm' },
    { label: 'Pređeno km', key: 'transportKmDistance' },
];

export const getCSVDataFromTWs = (
    transportWarrants: TransportWarrantDto[],
    trucks: TruckDto[],
    drivers: DriverDto[],
    graphDrivers: CompleteUserInfoDto[],
    currentDate: Date,
): CSVDataType[] => {
    const completeCsvData =
        transportWarrants
            ?.filter((tws) => CompareTwoDatesWithoutDayEquality(tws.actStartingTime, currentDate))
            .map((tw, index) => {
                const truck = trucks?.find((tr) => tr.id == tw.composition.mainVehicleId);

                const truckManMod = truck?.manufacturer
                    ? truck.model
                        ? truck.manufacturer + ' ' + truck.model
                        : truck.manufacturer
                    : noInfo;

                const driver = drivers?.find(
                    (d) =>
                        d.jobPositions?.findIndex(
                            (djp) => tw.driverJobPositionIds?.findIndex((twDjpId) => twDjpId === djp.id) !== -1,
                        ) !== -1,
                );
                const graphDriver = graphDrivers?.find((gd) => gd.id === driver?.azObjId);

                return (
                    tw.transportWarrantItems?.map((twItem, twItemIndex) => {
                        const loadingDuration = getTimeDifferenceForTwoDates(
                            twItem ? twItem.shipment.startingStop.endingTime : new Date(),
                            twItem ? twItem.shipment.startingStop.startingTime : new Date(),
                        );
                        const realTransportDuration = getTimeDifferenceForTwoDates(
                            tw.actEndingTime,
                            tw.actStartingTime,
                        );
                        const assumedTransportDuration = getTimeDifferenceForTwoDates(
                            tw.estEndingTime,
                            tw.estStartingTime,
                        );

                        return {
                            rb: index + 1,
                            date: FormatDateWithDayNameTextAtTheEnd(tw.actStartingTime),
                            truck: truckManMod,
                            reg: truck?.licensePlateNum ?? noInfo,
                            driver: graphDriver?.displayName ?? noInfo,
                            customer: twItem
                                ? twItem.shipment?.data?.clientName
                                    ? twItem.shipment.data.clientName.replace(/"/g, "'")
                                    : noInfo
                                : noInfo,
                            invoiceName: twItem
                                ? twItem.shipment?.data?.invoiceName
                                    ? twItem.shipment?.data?.invoiceName.replace(/"/g, "'")
                                    : noInfo
                                : noInfo,
                            invoiceCode: twItem
                                ? twItem.shipment?.data?.invoiceCode
                                    ? twItem.shipment?.data?.invoiceCode.replace(/"/g, "'")
                                    : noInfo
                                : noInfo,
                            requestOriginApp: twItem ? RequestOriginAppSR[twItem.shipment.requestOriginApp] : noInfo,
                            loadValue: twItem ? twItem.shipment?.loadValue ?? noInfo : noInfo,
                            address: twItem
                                ? twItem.shipment?.endingStop?.locationInfo?.address
                                    ? twItem.shipment?.endingStop?.locationInfo?.address.replace(/"/g, "'")
                                    : noInfo
                                : noInfo,
                            transportPrice: twItem ? twItem.shipment.transportPrice ?? noInfo : noInfo,
                            loadingDuration: twItemIndex === 0 ? loadingDuration : '',
                            loadingStartingTime:
                                twItemIndex === 0
                                    ? twItem
                                        ? twItem.shipment.startingStop.startingTime
                                            ? FormatTimeText(tw.actEndingTime)
                                            : noInfo
                                        : noInfo
                                    : '',
                            loadingEndingTime:
                                twItemIndex === 0
                                    ? twItem
                                        ? twItem.shipment.startingStop.endingTime
                                            ? FormatTimeText(tw.actEndingTime)
                                            : noInfo
                                        : noInfo
                                    : '',
                            actStartingTime:
                                twItemIndex === 0
                                    ? tw.actStartingTime
                                        ? FormatTimeText(tw.actStartingTime)
                                        : noInfo
                                    : '',
                            actEndingTime:
                                twItemIndex === 0 ? (tw.actEndingTime ? FormatTimeText(tw.actEndingTime) : noInfo) : '',
                            actTransportDuration: twItemIndex === 0 ? realTransportDuration ?? noInfo : '',
                            estStartingTime:
                                twItemIndex === 0
                                    ? tw.estStartingTime
                                        ? FormatTimeText(tw.estStartingTime)
                                        : noInfo
                                    : '',
                            estEndingTime:
                                twItemIndex === 0 ? (tw.estEndingTime ? FormatTimeText(tw.estEndingTime) : noInfo) : '',
                            estTransportDuration: twItemIndex === 0 ? assumedTransportDuration ?? noInfo : '',
                            loadPaletSpaces: twItem ? twItem.shipment.loadPalletSpaces : noInfo,
                            loadWeight: twItem ? twItem.shipment.loadWeight : noInfo,
                            startingKm: twItemIndex === 0 ? tw.startingKm ?? noInfo : '',
                            endingKm: twItemIndex === 0 ? tw.endingKm ?? noInfo : '',
                            transportKmDistance:
                                twItemIndex === 0
                                    ? tw.endingKm
                                        ? tw.startingKm
                                            ? tw.endingKm - tw.startingKm
                                            : noInfo
                                        : noInfo
                                    : '',
                        };
                    }) ?? []
                );
            }) ?? [];

    const csvData = completeCsvData.flat(1);

    return csvData;
};
