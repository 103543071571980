import React, { ReactElement } from 'react';
import TableViewFilterBar from '../TableViewFilterBar/TableViewFilterBar';
import TableViewTable from './TableViewTable';

type TableViewProps = {
    handleSelectTWForUpdating(twId: number): void;
};

export default function TableView({ handleSelectTWForUpdating }: TableViewProps): ReactElement {
    // show those events
    return (
        <>
            {/** Filter bar */}
            <TableViewFilterBar />

            <TableViewTable setUpdateFormTW={handleSelectTWForUpdating} />
        </>
    );
}
