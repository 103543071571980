import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Grid } from '@mui/material';
import React, { ReactElement } from 'react';
import { ClientEntityType } from '../../../AutoGeneratedAPI/clientApi';

type TypeOfCustomerProps = {
    selectCustomerEntityType(event: React.ChangeEvent<HTMLInputElement>): void;
    entityType: ClientEntityType;
};

export default function TypeOfCustomer({ selectCustomerEntityType, entityType }: TypeOfCustomerProps): ReactElement {
    return (
        <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Tip klijenta</FormLabel>
                    <RadioGroup
                        aria-label="tip entiteta"
                        name="entityType"
                        value={entityType}
                        onChange={selectCustomerEntityType}
                    >
                        <FormControlLabel value={ClientEntityType.Physical} control={<Radio />} label="Fizicko lice" />
                        <FormControlLabel value={ClientEntityType.Legal} control={<Radio />} label="Pravno lice" />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
}
