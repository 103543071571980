import { useDispatch } from 'react-redux';
import { LoginData, addLoginData } from '../Redux/Reducers/Login/EmployeeReducer';

type useLoginDataType = {
    setLoginData(loginData: LoginData | null): void;
    getLoginData(): void;
};

export const useLoginData = (): useLoginDataType => {
    const dispatch = useDispatch();

    const getLoginData = (): void => {
        const tokenString = localStorage.getItem('token');
        const expirationDateString = localStorage.getItem('expirationDate');

        if (!tokenString || tokenString === '' || !expirationDateString || expirationDateString === '') {
            dispatch(addLoginData(null));
        } else {
            const loginData: LoginData = {
                token: tokenString,
                expirationDate: expirationDateString ? new Date(JSON.parse(expirationDateString)) : null,
            };

            dispatch(addLoginData(loginData));
        }
    };

    const saveLoginData = (loginData: LoginData | null): void => {
        if (loginData) {
            if (loginData.token) {
                localStorage.setItem('token', loginData.token);
            }

            if (loginData.expirationDate) {
                localStorage.setItem('expirationDate', JSON.stringify(loginData.expirationDate));
            }
            dispatch(addLoginData(loginData));
        } else {
            localStorage.setItem('token', '');
            localStorage.setItem('expirationDate', '');
            dispatch(addLoginData(null));
        }
    };

    return {
        setLoginData: saveLoginData,
        getLoginData,
    };
};
