import React, { useEffect, ReactElement, useState } from 'react';
import {
    CompositionDto,
    DriverDto,
    JobPositionType,
    TrailerDto,
    TransportWarrantDto,
} from '../../AutoGeneratedAPI/clientApi';
import DatesAndKmElement from './Elements/DatesAndKmElement';
import {
    fetchInitialTransportWarrants,
    updateOldTransportWarrant,
} from '../../Redux/Actions/Warrants/TransportWarrantAction';
import { addCompositionToRedux } from '../../Redux/Actions/Compositions/CompositionsAction';
import './TransportWarrantForm.css';
import { RootState } from '../../Redux/store';
import { ServerResponseStatus } from '../../Extensions/Extensions';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import { ExceptionsWithAdding, ExceptionsWithTransportWarrant } from '../../Extensions/Exceptions';
import {
    addTransportWarrantToRedux,
    setAdditionStatus,
    setUpdateStatus,
} from '../../Redux/Reducers/Warrants/CalendarReducer';
import { Button, Grid, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import MainVehicleSection from './Sections/MainVehicleSection';
import TrailersSection from './Sections/TrailersSection';
import DriversSection from './Sections/DriversSection';
import {
    addDriverAzureObjIdForTw,
    removeDriverAzureObjIdForTw,
    setComposition,
    setTransportWarrant,
    setTwDate,
    setUnloadingTime,
} from '../../Redux/Reducers/Warrants/TransportWarrantFormReducer';
import { checkTransportWarrantsExistence } from '../../../Calendar/Helpers/TransportWarrantHelpers';
import { checkIfAnyIsNumber } from '../../../Common/Common';
import { airToLandDistanceCoefficient, findOutTheDistanceBetweenTwoPoints } from '../../../Common/DistanceCalculator';
import { getTimeDifference } from '../../../Common/DateHandler';
import { addMinutes } from 'date-fns';

type TransportWarrantFormProps = {
    closeForm(twDate?: Date, twId?: number): void;
    closeFormOnClick(): void;
    isOriginShipmentLoadingWindow: boolean;
};

export default function TransportWarrantForm({
    closeForm,
    closeFormOnClick,
    isOriginShipmentLoadingWindow,
}: TransportWarrantFormProps): ReactElement {
    const [addedTw, setAddedTw] = useState<TransportWarrantDto | undefined>(undefined);

    const transportWarrant = useAppSelector((state: RootState) => state.twFormData.transportWarrant);
    const currentTwDate = useAppSelector((state: RootState) => state.twFormData.twDate);
    const currentUnloadingTime = useAppSelector((state: RootState) => state.twFormData.unloadingTime);
    const composition = useAppSelector((state: RootState) => state.twFormData.composition);
    const driversAzureObjIds = useAppSelector((state: RootState) => state.twFormData.driversAzureObjIds);

    const selectedShipmentForTW = useAppSelector((state: RootState) => state.twFormData.selectedShipmentForTW);

    const twAdditionStatus = useAppSelector((state: RootState) => state.calendarData.transportWarrantAdditionStatus);
    const twUpdateStatus = useAppSelector((state: RootState) => state.calendarData.transportWarrantUpdateStatus);

    const transportWarrants = useAppSelector((state: RootState) => state.calendarData.transportWarrants);

    const dispatch = useAppDispatch();

    const changeTwTimesBasedOnPredefinedUnloadingTime = (date: Date) => {
        const newTime = new Date(date);

        const newDate = new Date(
            currentTwDate.getFullYear(),
            currentTwDate.getMonth(),
            currentTwDate.getDate(),
            newTime.getHours(),
            newTime.getMinutes(),
        );
        // set unloading time in redux
        dispatch(setUnloadingTime(newDate));

        // find out estimated starting time and estimated ending time of the transport warrant
        const startingLat = checkIfAnyIsNumber(selectedShipmentForTW?.startingStop.locationInfo?.coordinates?.lat);
        const startingLng = checkIfAnyIsNumber(selectedShipmentForTW?.startingStop.locationInfo?.coordinates?.lng);
        const endingLat = checkIfAnyIsNumber(selectedShipmentForTW?.endingStop.locationInfo?.coordinates?.lat);
        const endingLng = checkIfAnyIsNumber(selectedShipmentForTW?.endingStop.locationInfo?.coordinates?.lng);

        const distance = findOutTheDistanceBetweenTwoPoints(startingLat, endingLat, startingLng, endingLng);

        // air distance in km * factor for land distance / average km/h * 60 minutes
        const estimatedOneWayTransportDurationInMinutes =
            ((distance * airToLandDistanceCoefficient(distance)) / 50) * 60;

        const unlTime = newDate ?? new Date();

        const estimatedStartingTime = addMinutes(unlTime, 0 - estimatedOneWayTransportDurationInMinutes);

        const unloadingDurationTimeSpan = getTimeDifference(
            selectedShipmentForTW?.endingStop.endingTime,
            selectedShipmentForTW?.endingStop.startingTime,
        );
        const unloadingDurationInMinutes = unloadingDurationTimeSpan.hours * 60 + unloadingDurationTimeSpan.minutes;

        const estimatedEndingTime = addMinutes(
            unlTime,
            estimatedOneWayTransportDurationInMinutes + unloadingDurationInMinutes,
        );

        dispatch(
            setTransportWarrant({
                ...transportWarrant,
                estStartingTime: estimatedStartingTime,
                actStartingTime: estimatedStartingTime,
                estEndingTime: estimatedEndingTime,
                actEndingTime: estimatedEndingTime,
            }),
        );
    };

    useEffect(() => {
        if (isOriginShipmentLoadingWindow) {
            changeTwTimesBasedOnPredefinedUnloadingTime(currentUnloadingTime ?? new Date());
        }
    }, [currentTwDate]);

    useEffect(() => {
        if (twAdditionStatus === ServerResponseStatus.Success || addedTw !== undefined) {
            dispatch(setAdditionStatus(ServerResponseStatus.Pending));
            closeForm(addedTw?.actStartingTime, addedTw?.id);
        }
        if (twUpdateStatus === ServerResponseStatus.Success) {
            dispatch(setUpdateStatus(ServerResponseStatus.Pending));
            closeForm(transportWarrant.actEndingTime, transportWarrant.id);
        }
    }, [twAdditionStatus, twUpdateStatus, addedTw]);

    const handleCurrentDateChange = (date: Date): void => {
        if (date.toString() !== 'Invalid Date') {
            const newStartDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                transportWarrant.estStartingTime.getHours(),
                transportWarrant.estStartingTime.getMinutes(),
            );
            const newEndDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                transportWarrant.estEndingTime.getHours(),
                transportWarrant.estEndingTime.getMinutes(),
            );
            dispatch(
                setTransportWarrant({
                    ...transportWarrant,
                    estStartingTime: newStartDate,
                    actStartingTime: newStartDate,
                    estEndingTime: newEndDate,
                    actEndingTime: newEndDate,
                }),
            );
            dispatch(setTwDate(date));
        }
    };

    const handleStartingTimeChange = (date: Date): void => {
        if (date.toString() !== 'Invalid Date') {
            const newDate = new Date(
                currentTwDate.getFullYear(),
                currentTwDate.getMonth(),
                currentTwDate.getDate(),
                new Date(date).getHours(),
                new Date(date).getMinutes(),
            );
            dispatch(
                setTransportWarrant({
                    ...transportWarrant,
                    estStartingTime: newDate,
                    actStartingTime: newDate,
                }),
            );
        }
    };

    const handleEndingTimeChange = (date: Date): void => {
        if (date.toString() !== 'Invalid Date') {
            const newDate = new Date(
                currentTwDate.getFullYear(),
                currentTwDate.getMonth(),
                currentTwDate.getDate(),
                new Date(date).getHours(),
                new Date(date).getMinutes(),
            );
            dispatch(
                setTransportWarrant({
                    ...transportWarrant,
                    estEndingTime: newDate,
                    actEndingTime: newDate,
                }),
            );
        }
    };

    const handleUnloadingTimeChange = (date: Date): void => {
        if (date.toString() !== 'Invalid Date') {
            changeTwTimesBasedOnPredefinedUnloadingTime(date);
        }
    };

    const handleDriverAdding = (driver: DriverDto): void => {
        const drivJobPositionId = driver.jobPositions?.find((jp) => jp.jobPositionType === JobPositionType.Driver)
            ?.id as number;
        if (driversAzureObjIds.includes(driver.azObjId ?? '')) {
            const dId = driversAzureObjIds.indexOf(driver.azObjId ?? '');
            dispatch(removeDriverAzureObjIdForTw(driversAzureObjIds[dId]));
        } else {
            dispatch(addDriverAzureObjIdForTw(driver.azObjId ?? ''));
        }
        if (transportWarrant.driverJobPositionIds?.includes(drivJobPositionId)) {
            const drvrs = transportWarrant.driverJobPositionIds ?? [];
            const dId = drvrs.indexOf(drivJobPositionId);

            dispatch(
                setTransportWarrant({
                    ...transportWarrant,
                    driverJobPositionIds: [...drvrs.slice(0, dId), ...drvrs.slice(dId + 1)],
                }),
            );
        } else {
            dispatch(
                setTransportWarrant({
                    ...transportWarrant,
                    driverJobPositionIds: (transportWarrant.driverJobPositionIds ?? []).concat(drivJobPositionId),
                }),
            );
        }
    };

    const handleAddVehicleInComposition = (truck: number): void => {
        if (composition.mainVehicleId !== undefined && composition.mainVehicleId === truck) {
            dispatch(
                setComposition({
                    ...composition,
                    mainVehicleId: 0,
                }),
            );
        } else {
            dispatch(
                setComposition({
                    ...composition,
                    mainVehicleId: truck,
                }),
            );
        }
    };

    const handleTrailersAdding = (trailer: TrailerDto): void => {
        if (composition.trailersIds?.includes(trailer.id)) {
            const newTrailers = composition.trailersIds.filter((tId) => tId !== trailer.id);
            dispatch(setComposition({ ...composition, trailersIds: newTrailers }));
        } else {
            const newTrailers = [...(composition.trailersIds ?? []), trailer.id];
            dispatch(setComposition({ ...composition, trailersIds: newTrailers }));
        }
    };

    const addTransportWarrant = (tw: TransportWarrantDto) => {
        GranitClient.addNewTransportWarrant(tw).then(
            (result) => {
                setAddedTw(result);
                const {
                    exists: twExists,
                    startingDate: strDateStart,
                    endingDate: strDateEnd,
                } = checkTransportWarrantsExistence(transportWarrants ?? [], currentTwDate ?? new Date());

                if (twExists) {
                    dispatch(addTransportWarrantToRedux(result));
                } else {
                    dispatch(fetchInitialTransportWarrants(strDateStart, strDateEnd, true));
                }
            },
            (error) => {
                dispatch(setAdditionStatus(ServerResponseStatus.Error));
                alert(
                    ExceptionsWithAdding.EXCEPTION_ADDING_TRANSPORTWARRANTS +
                        '\n\n' +
                        ExceptionsWithTransportWarrant.find((e) => e.code === error.messageCode)?.value ??
                        error.messageCode,
                );
            },
        );
    };

    const addCompositionThenAddTw = (composition: CompositionDto | undefined) => {
        GranitClient.createOrGetComposition(composition as CompositionDto).then(
            (result) => {
                dispatch(
                    setTransportWarrant({
                        ...transportWarrant,
                        compositionId: result.id as number,
                    }),
                );

                const tw: TransportWarrantDto = new TransportWarrantDto(transportWarrant);
                tw.compositionId = result.id;
                tw.composition = new CompositionDto();
                addTransportWarrant(tw);
                dispatch(addCompositionToRedux(result));
            },
            (error) => {
                alert(ExceptionsWithAdding.EXCEPTION_ADDING_COMPOSITIONS);
                console.log(error.message);
            },
        );
    };

    const addCompositionThenUpdateTw = (composition: CompositionDto | undefined) => {
        GranitClient.createOrGetComposition(composition as CompositionDto).then(
            (result) => {
                dispatch(
                    setTransportWarrant({
                        ...transportWarrant,
                        compositionId: result.id as number,
                    }),
                );
                dispatch(addCompositionToRedux(result));
                const tw: TransportWarrantDto = new TransportWarrantDto();
                Object.assign(tw, transportWarrant);
                tw.compositionId = result.id;
                tw.composition = new CompositionDto();
                tw.isTwFinished = false;
                tw.transportWarrantItems = [];
                dispatch(updateOldTransportWarrant(tw, false));
            },
            (error) => {
                alert(ExceptionsWithAdding.EXCEPTION_ADDING_COMPOSITIONS);
                console.log(error.message);
            },
        );
    };

    const updateFullTransportWarrant = () => {
        const comp = new CompositionDto(composition);

        comp.id = 0;

        if (!composition.trailersIds) {
            setComposition({ ...composition, trailersIds: [] });
            comp.trailersIds = [];
        }
        if (!composition.workingMachinesIds) {
            setComposition({ ...composition, workingMachinesIds: [] });
            comp.workingMachinesIds = [];
        }

        addCompositionThenUpdateTw(comp);
    };

    const addFullTransportWarrant = () => {
        const comp = new CompositionDto(composition);
        if (!comp.trailersIds) {
            comp.trailersIds = [];
        }
        if (!comp.workingMachinesIds) {
            comp.workingMachinesIds = [];
        }
        comp.id = 0;

        addCompositionThenAddTw(comp);
    };

    const onSubmit = () => {
        if (transportWarrant.id !== undefined && transportWarrant.id >= 0) {
            updateFullTransportWarrant();
        } else {
            addFullTransportWarrant();
        }
    };

    const isRequiredEmpty =
        ((composition.mainVehicleId === undefined || composition.mainVehicleId <= 0) &&
            transportWarrant.compositionId === undefined) ||
        transportWarrant.driverJobPositionIds === undefined ||
        transportWarrant.driverJobPositionIds.length <= 0 ||
        transportWarrant.estStartingTime === undefined ||
        transportWarrant.estEndingTime === undefined ||
        transportWarrant.estStartingTime >= transportWarrant.estEndingTime;

    const twFormElement = (): ReactElement => {
        return (
            <>
                <Grid container spacing={1}>
                    <DatesAndKmElement
                        handleDateChange={handleCurrentDateChange}
                        handleEndingTimeChange={handleEndingTimeChange}
                        handleStartingTimeChange={handleStartingTimeChange}
                        handleUnloadingTimeChange={handleUnloadingTimeChange}
                        currentUnloadingTime={currentUnloadingTime}
                        currentDate={currentTwDate}
                        currentStartingTime={transportWarrant.estStartingTime as Date}
                        currentEndingTime={transportWarrant.estEndingTime as Date}
                        isOriginShipmentLoadingWindow={isOriginShipmentLoadingWindow}
                    />
                    <hr style={{ width: '100%' }} />
                    <Grid item xs={12}>
                        <Typography component="h6" variant="h6" align="left">
                            Vozila
                        </Typography>
                    </Grid>
                    <hr style={{ width: '100%' }} />
                    <MainVehicleSection
                        composition={composition}
                        handleAddVehicleToComposition={handleAddVehicleInComposition}
                    />
                    <TrailersSection composition={composition} handleTrailersAdding={handleTrailersAdding} />
                    <DriversSection
                        transportWarrant={transportWarrant}
                        driversAzureObjIds={driversAzureObjIds}
                        handleDriversAdding={handleDriverAdding}
                    />
                </Grid>
            </>
        );
    };

    return (
        <main style={{ width: '100%', height: 'calc(100vh - 70px)' }}>
            <div
                style={{
                    position: 'absolute',
                    left: 15,
                    top: 0,
                    fontSize: 30,
                    fontFamily: 'Courier New',
                    cursor: 'pointer',
                }}
                onClick={closeFormOnClick}
            >
                {'>'}
            </div>
            <Typography component="h5" variant="h5" align="center" style={{ marginBottom: 20, marginTop: 7 }}>
                Rezervacija transporta
            </Typography>
            <div style={{ height: 'calc(100vh - 200px)', overflowX: 'hidden', overflowY: 'auto', padding: 20 }}>
                {twFormElement()}
            </div>
            <div style={{ position: 'absolute', bottom: 80, right: 20, width: 200 }}>
                <Button
                    disabled={!isRequiredEmpty ? false : true}
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        onSubmit();
                    }}
                >
                    SAČUVAJ
                </Button>
            </div>
        </main>
    );
}
