import { Badge, Button, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import React, { ReactElement, useEffect, useState, MouseEvent } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { LoaderProgress } from '../../Common/CommonComponents/Loader/LoaderProgress';
import { FormatDateText } from '../../Common/DateHandler';
import {
    EntityType,
    ExpenseCategory,
    KmEventDto,
    RecordCategory,
    TimeEventDto,
    VehicleExpenseDto,
} from '../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../AutoGeneratedAPI/Extension';
import { ExpenseEntityType, RecordEntitiesType } from '../Extensions/Entities';
import { RecordType } from '../Extensions/Extensions';
import { ExpenseTypeSR } from '../Extensions/Localizations/Types/Expenses/SR/ExpenseTypeSR';
import { isAppTypeWorkingToolCat, isAppTypeVehicle, isViberUser } from '../Extensions/Selectors/Selectors';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import DeleteRecModal from '../Profile/DeleteRecModal';
import ViberRequestModal from '../Profile/ViberRequestModal';
import { deleteVehicleExpense, deleteWorkingToolExpense } from '../Redux/Actions/General/ExpensesAction';
import { deleteVehicleRecord, deleteWorkingToolRecord } from '../Redux/Actions/General/RecordsAction';
import { RootState } from '../Redux/store';
import viber_logo from '../assets/img/viber_logo.png';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import AlarmIcon from '@mui/icons-material/Alarm';
import EventForm from '../Forms/EventForm/EventForm';
import EventsList from '../Events/EventsList';

type FeedSideDetailsProps = {
    closeFeedSideDetails(): void;
    recordType: RecordType | null;
    openFeedForm(): void;
};

export default function FeedSideDetails({
    closeFeedSideDetails,
    recordType,
    openFeedForm,
}: FeedSideDetailsProps): ReactElement {
    const records = useAppSelector((state: RootState) => state.records.records);
    const expenses = useAppSelector((state: RootState) => state.expenses.expenses);
    const importantDocuments = useAppSelector((state: RootState) => state.importantDocs.importantDocs);

    const [record, setRecord] = useState<RecordEntitiesType | undefined>(undefined);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [openViberRequest, setOpenViberRequest] = useState<boolean>(false);
    const [openEventModal, setOpenEventModal] = useState<boolean>(false);

    const [defaultTimeEvent, setDefaultTimeEvent] = useState<TimeEventDto | undefined>(undefined);
    const [defaultKmEvent, setDefaultKmEvent] = useState<KmEventDto | undefined>(undefined);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const location = useLocation();
    const path = location.pathname;

    const isUserViberUser = useAppSelector(isViberUser);
    const isVehiclePage = isAppTypeVehicle(path);
    const isWorkingMachinePage = isAppTypeWorkingToolCat(path);

    const dispatch = useAppDispatch();

    const { recId, expId, impDocId } = useParams();

    const timeEvents = useAppSelector((state: RootState) =>
        state.notifications.timeEvents?.filter((te) => {
            if (recId && !isNaN(+recId) && +recId > 0) {
                return te.entityId === +recId && te.entityType === EntityType.Record;
            } else if (expId && !isNaN(+expId) && +expId > 0) {
                return te.entityId === +expId && te.entityType === EntityType.Expense;
            }
        }),
    );
    const kmEvents = useAppSelector((state: RootState) =>
        state.notifications.kmEvents?.filter((te) => {
            if (recId && !isNaN(+recId) && +recId > 0) {
                return te.entityId === +recId && te.entityType === EntityType.Record;
            } else if (expId && !isNaN(+expId) && +expId > 0) {
                return te.entityId === +expId && te.entityType === EntityType.Expense;
            }
        }),
    );

    useEffect(() => {
        if (records && recId && !isNaN(+recId)) {
            const rec = records?.find((rec) => rec.id === +recId);
            setRecord(rec);
        } else if (expenses && expId && !isNaN(+expId)) {
            const exp = expenses?.find((exp) => exp.id === +expId);
            setRecord(exp);
        } else if (importantDocuments && impDocId && !isNaN(+impDocId)) {
            const impDoc = importantDocuments?.find((impDoc) => impDoc.id === +impDocId);
            setRecord(impDoc);
        }
    }, [recId, expId, impDocId, records, expenses, importantDocuments]);

    const deleteRec = (): void => {
        if (record && record.id) {
            if (isVehiclePage) {
                dispatch(deleteVehicleRecord(record.id, record));
            }
            if (isWorkingMachinePage) {
                dispatch(deleteWorkingToolRecord(record.id, record));
            }
            closeFeedSideDetails();
        }
    };

    const deleteExp = (): void => {
        if (record && record.id) {
            if (isVehiclePage) {
                dispatch(deleteVehicleExpense(record.id, record as ExpenseEntityType));
            }
            if (isWorkingMachinePage) {
                dispatch(deleteWorkingToolExpense(record.id, record as ExpenseEntityType));
            }
            closeFeedSideDetails();
        }
    };

    const deleteRecExp = () => {
        if (recordType === RecordType.Record) {
            deleteRec();
        } else if (recordType === RecordType.Expense) {
            deleteExp();
        }
    };

    const handleSelectEvent = (eventId: number, isKmEvent: boolean) => {
        if (isKmEvent) {
            const defKmEvent = kmEvents?.find((e) => e.id === eventId);
            setDefaultKmEvent(defKmEvent);
        } else {
            const defTimeEvent = timeEvents?.find((e) => e.id === eventId);
            setDefaultTimeEvent(defTimeEvent);
        }
        setOpenEventModal(true);
    };

    const handleClickAnchor = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAnchor = () => {
        setAnchorEl(null);
    };

    const handleOpenDeleteModal = (): void => {
        setOpenDeleteModal(true);
    };

    const handleCloseDeleteModal = (): void => {
        setOpenDeleteModal(false);
    };

    const handleOpenEventModal = (): void => {
        setOpenEventModal(true);
    };

    const handleCloseEventModal = (): void => {
        setOpenEventModal(false);
        if (defaultKmEvent) {
            setDefaultKmEvent(undefined);
        }
        if (defaultTimeEvent) {
            setDefaultTimeEvent(undefined);
        }
    };

    const handleOpenViberModal = (): void => {
        setOpenViberRequest(true);
    };

    const handleCloseViberModal = (): void => {
        setOpenViberRequest(false);
    };

    const sendViberRequest = (): void => {
        if (record && record.id) {
            GranitClient.sendUploadMediaRequest(
                record.id as number,
                record.category === RecordCategory.ImportantDocs ? 'ImportantDocuments' : 'default',
            );
        }
    };

    const expDurationTextFormat = (): string => {
        const exp = record as VehicleExpenseDto;

        return !exp || !exp.expenseType
            ? ''
            : (!exp.expenseType.durationInKm || exp.expenseType.durationInKm <= 0) &&
              (!exp.expenseType.durationInMonths || exp.expenseType.durationInMonths <= 0)
            ? ''
            : !exp.expenseType.durationInKm || exp.expenseType.durationInKm <= 0
            ? exp.expenseType.durationInMonths + ' mes.'
            : !exp.expenseType.durationInMonths || exp.expenseType.durationInMonths <= 0
            ? exp.expenseType.durationInKm + ' km'
            : exp.expenseType.durationInKm + ' km ili ' + exp.expenseType.durationInMonths + ' mes.';
    };

    const fuelExpenseElement = (): ReactElement => {
        const exp = record as VehicleExpenseDto;

        return !exp || !exp.expenseType || !exp.expenseType.expenseCategory ? (
            <></>
        ) : exp.expenseType.expenseCategory !== ExpenseCategory.Fuel ? (
            <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                <span style={{ fontWeight: 'normal' }}>Tip troška:</span>{' '}
                <span style={{ float: 'right' }}>{ExpenseTypeSR[exp.expenseType.expenseCategory]}</span>
            </Typography>
        ) : (
            <>
                <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'normal' }}>Tip troška:</span>{' '}
                    <span style={{ float: 'right' }}>{ExpenseTypeSR[exp.expenseType.expenseCategory]}</span>
                </Typography>

                <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'normal' }}>Litraža:</span>{' '}
                    <span style={{ float: 'right' }}>{exp.liters} l</span>
                </Typography>

                <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'normal' }}>Cena po litru:</span>{' '}
                    <span style={{ float: 'right' }}>{exp.pricePerLiter} din.</span>
                </Typography>
            </>
        );
    };

    const feedSideDetailsElement = (): ReactElement => {
        const exp = record as VehicleExpenseDto;
        return !exp ? (
            <></>
        ) : (
            <>
                <div>
                    <Typography gutterBottom variant="h5" component="p">
                        <Typography
                            variant="h5"
                            component="p"
                            style={{
                                maxWidth: 350,
                                whiteSpace: 'normal',
                            }}
                        >
                            {exp.title && exp.title !== '' ? exp.title : 'Nema naslova'}
                        </Typography>
                        <Tooltip
                            title="Novi podsetnik"
                            placement="top"
                            style={{ float: 'right', height: 45, width: 45 }}
                        >
                            <IconButton color="primary" onClick={handleOpenEventModal}>
                                <AddAlertIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Pregledaj buduće podsetnike"
                            placement="top"
                            style={{ float: 'right', height: 45, width: 45 }}
                        >
                            <IconButton color="default" onClick={handleClickAnchor}>
                                {(timeEvents !== undefined && timeEvents.length > 0) ||
                                (kmEvents !== undefined && kmEvents.length > 0) ? (
                                    <Badge
                                        badgeContent={
                                            timeEvents && timeEvents.length > 0 && kmEvents && kmEvents.length > 0
                                                ? timeEvents.length + kmEvents.length
                                                : timeEvents && timeEvents.length > 0
                                                ? timeEvents.length
                                                : kmEvents && kmEvents.length > 0
                                                ? kmEvents.length
                                                : 0
                                        }
                                        color="error"
                                    >
                                        <AlarmIcon />
                                    </Badge>
                                ) : (
                                    <AlarmIcon />
                                )}
                            </IconButton>
                        </Tooltip>
                    </Typography>
                    <EventsList
                        anchorEl={anchorEl}
                        closeWindow={handleCloseAnchor}
                        handleSelectEvent={handleSelectEvent}
                        entityType={
                            recId && !isNaN(+recId) && +recId > 0
                                ? EntityType.Record
                                : expId && !isNaN(+expId) && +expId > 0
                                ? EntityType.Expense
                                : EntityType.Event
                        }
                        entityId={
                            recId && !isNaN(+recId) && +recId > 0
                                ? +recId
                                : expId && !isNaN(+expId) && +expId > 0
                                ? +expId
                                : 0
                        }
                    />
                    {exp.expenseType &&
                        exp.expenseType.expenseSubCategory &&
                        exp.expenseType.expenseSubCategory !== '' && (
                            <Typography gutterBottom variant="body2" component="p">
                                {exp.expenseType.expenseSubCategory}
                            </Typography>
                        )}
                </div>
                <hr style={{ width: '100%' }} />
                <div>
                    <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                        <span style={{ fontWeight: 'normal' }}>Datum važenja:</span>{' '}
                        <span style={{ float: 'right' }}>{exp.validFrom ? FormatDateText(exp.validFrom) : ''}</span>
                    </Typography>
                </div>
                <hr style={{ width: '100%' }} />
                {exp.price && exp.price > 0 && (
                    <>
                        <div style={{ marginTop: 25 }}>
                            <Typography gutterBottom variant="h5" component="p">
                                Informacije o trošku
                            </Typography>
                        </div>
                        <hr style={{ width: '100%' }} />
                        <div>
                            {fuelExpenseElement()}
                            <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                                <span style={{ fontWeight: 'normal' }}>Cena:</span>{' '}
                                <span style={{ float: 'right' }}>{exp.price} din.</span>
                            </Typography>
                            <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                                <span style={{ fontWeight: 'normal' }}>Predv. trajanje troška:</span>{' '}
                                <span style={{ float: 'right' }}>{expDurationTextFormat()}</span>
                            </Typography>
                            {exp.expenseKm && exp.expenseKm > 0 && (
                                <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                                    <span style={{ fontWeight: 'normal' }}>Km troška:</span>{' '}
                                    <span style={{ float: 'right' }}>{exp.expenseKm} km</span>
                                </Typography>
                            )}
                        </div>
                    </>
                )}
                <hr style={{ width: '100%' }} />
                <Typography
                    gutterBottom
                    variant="body1"
                    component="p"
                    style={{
                        whiteSpace: 'normal',
                    }}
                >
                    {exp.description && exp.description !== '' ? exp.description : 'Nema opisa'}
                </Typography>
            </>
        );
    };

    const recTypeText = (): string => {
        return recordType === RecordType.Expense
            ? 'trošak'
            : recordType === RecordType.Record
            ? 'evidenciju'
            : 'dokument';
    };

    return (
        <main style={{ width: '100%', height: 'calc(100vh - 70px)' }}>
            <div
                style={{
                    position: 'absolute',
                    left: 15,
                    top: 0,
                    fontSize: 30,
                    fontFamily: 'Courier New',
                    cursor: 'pointer',
                }}
                onClick={closeFeedSideDetails}
            >
                {'>'}
            </div>
            <div
                style={{
                    height: 'calc(100vh - 200px)',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    padding: 20,
                    paddingTop: 0,
                    marginTop: 50,
                }}
            >
                {record ? (
                    feedSideDetailsElement()
                ) : (
                    <div style={{ width: 400, margin: 'auto', marginTop: 100 }}>
                        <LoaderProgress />
                    </div>
                )}
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: 80,
                    right: 20,
                    width: isUserViberUser ? 450 : 400,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                }}
            >
                {isUserViberUser && (
                    <Button variant="outlined" color="secondary" onClick={handleOpenViberModal}>
                        <span className="icon">
                            <img src={viber_logo} style={{ width: 23, height: 23 }} />
                        </span>
                        Zahtev za slanje dok.
                    </Button>
                )}
                <Button variant="outlined" color="error" onClick={handleOpenDeleteModal}>
                    {isUserViberUser ? 'Izbriši' : 'Izbriši ' + recTypeText()}
                </Button>
                <Button variant="outlined" color="primary" onClick={openFeedForm}>
                    {isUserViberUser ? 'Izmeni' : 'Izmeni ' + recTypeText()}
                </Button>
            </div>
            <DeleteRecModal
                open={openDeleteModal}
                title={recordType === RecordType.Expense ? 'ovaj trošak' : 'ovu evidenciju'}
                closeModal={handleCloseDeleteModal}
                deleteDoc={deleteRecExp}
            />

            <ViberRequestModal
                open={openViberRequest}
                closeModal={handleCloseViberModal}
                sendViberRequest={sendViberRequest}
                title={record?.title ?? ''}
            />
            <div>
                <Modal
                    className="modalStyle"
                    open={openEventModal}
                    aria-labelledby="add-vehicle-form"
                    aria-describedby="adding a vehicle to a database through this form"
                >
                    <>
                        <EventForm
                            closeForm={handleCloseEventModal}
                            entityType={expId !== undefined && !isNaN(+expId) ? EntityType.Expense : EntityType.Record}
                            entityId={
                                expId !== undefined && !isNaN(+expId)
                                    ? +expId
                                    : recId !== undefined && !isNaN(+recId)
                                    ? +recId
                                    : 0
                            }
                            defaultKmEvent={defaultKmEvent}
                            defaultTimeEvent={defaultTimeEvent}
                        />
                    </>
                </Modal>
            </div>
        </main>
    );
}
