import { CompositionDto } from '../../../AutoGeneratedAPI/clientApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithFetching } from '../../../Extensions/Exceptions';

interface IInitialStateType {
    compositions: CompositionDto[] | null;
}

const initialState: IInitialStateType = {
    compositions: null,
};

export const fetchInitialCompositions = createAsyncThunk(
    'compositions/fetchInitialCompositions',
    async () => {
        const response = await GranitClient.getAllCompositions(undefined);
        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingCompositions: CompositionDto[] | null = thunkAPI.getState().compositions.compositions;

            return existingCompositions === null;
        },
    },
);

const compositionsSlice = createSlice({
    name: 'compositions',
    initialState,
    reducers: {
        addComposition(state, action) {
            if (!state.compositions?.find((c) => c.id === action.payload.id)) {
                state.compositions = (state.compositions ?? []).concat(action.payload);
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInitialCompositions.fulfilled, (state, action) => {
            state.compositions = action.payload ?? [];
        });
        builder.addCase(fetchInitialCompositions.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_COMPOSITIONS);
        });
    },
});

export const { addComposition } = compositionsSlice.actions;

export default compositionsSlice.reducer;
