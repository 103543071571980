import * as React from 'react';
import { ReactElement } from 'react';
import { Grid, Stack, TextField } from '@mui/material';
import { LocalizationProvider, MobileTimePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';

type DatesAndKmFormProps = {
    handleDateChange(date: Date): void;
    handleStartingTimeChange(date: Date): void;
    handleEndingTimeChange(date: Date): void;
    handleUnloadingTimeChange(date: Date): void;
    currentStartingTime: Date;
    currentEndingTime: Date;
    currentDate: Date;
    currentUnloadingTime?: Date;
    isOriginShipmentLoadingWindow: boolean;
};

export default function DatesAndKmElement({
    handleDateChange,
    handleStartingTimeChange,
    handleEndingTimeChange,
    handleUnloadingTimeChange,
    currentStartingTime,
    currentEndingTime,
    currentDate,
    currentUnloadingTime,
    isOriginShipmentLoadingWindow,
}: DatesAndKmFormProps): ReactElement {
    const onChangeDate = (date?: Date | null): void => {
        if (date) {
            handleDateChange(date as Date);
        }
    };

    const onChangeStartingTime = (date?: Date | null): void => {
        if (date) {
            handleStartingTimeChange(date as Date);
        }
    };

    const onChangeEndingTime = (date?: Date | null): void => {
        if (date) {
            handleEndingTimeChange(date as Date);
        }
    };

    const onChangeUnloadingTime = (date?: Date | null): void => {
        if (date) {
            handleUnloadingTimeChange(date as Date);
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                    <Stack spacing={3}>
                        <DesktopDatePicker
                            label="Datum"
                            disableMaskedInput
                            value={currentDate}
                            inputFormat="DD/MM/YYYY"
                            minDate={dayjs('1940-01-01')}
                            onChange={(newValue) => {
                                onChangeDate(newValue?.toDate());
                            }}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </Stack>
                </LocalizationProvider>
            </Grid>
            {isOriginShipmentLoadingWindow ? (
                <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                        <Stack spacing={3}>
                            <MobileTimePicker
                                label="Vreme istovara"
                                disableMaskedInput
                                value={currentUnloadingTime ?? new Date()}
                                onChange={(newValue) => {
                                    onChangeUnloadingTime(newValue);
                                }}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
            ) : (
                <>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                            <Stack spacing={3}>
                                <MobileTimePicker
                                    label="Početno vreme"
                                    disableMaskedInput
                                    value={currentStartingTime}
                                    onChange={(newValue) => {
                                        onChangeStartingTime(newValue);
                                    }}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                            <Stack spacing={3}>
                                <MobileTimePicker
                                    label="Završno vreme"
                                    disableMaskedInput
                                    value={currentEndingTime}
                                    onChange={(newValue) => {
                                        onChangeEndingTime(newValue);
                                    }}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
