import React, { ChangeEvent, ReactElement } from 'react';
import {
    IClientRecordDto,
    IEmployeeRecordDto,
    IVehicleRecordDto,
    IWorkingToolRecordDto,
    RecordDocumentDto,
} from '../../AutoGeneratedAPI/clientApi';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import { Grid, Fab, TextField, Button, Stack } from '@mui/material';
import { FileIcon } from 'react-file-icon';
import { getBaseServerLink } from '../../AutoGeneratedAPI/Extension';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';

type RecordFormElementProps = {
    currentRecord: IVehicleRecordDto | IWorkingToolRecordDto | IEmployeeRecordDto | IClientRecordDto;
    selectedFiles: Array<File>;
    handleInputElementChange(event: ChangeEvent<HTMLInputElement>): void;
    handleValidFromChange(date?: Date): void;
    handleFileInputChange(selectorFiles: FileList | undefined): void;
    handleFileDelete(fileToDelete: File): void;
    deleteFile(file: RecordDocumentDto): void;
    children: any;
};

export default function RecordFormElement({
    currentRecord,
    selectedFiles,
    handleInputElementChange,
    handleValidFromChange,
    handleFileInputChange,
    handleFileDelete,
    deleteFile,
    children,
}: RecordFormElementProps): ReactElement {
    return (
        <>
            <Grid key={2} container spacing={5}>
                <Grid key={12} item xs={12} lg={6}>
                    <TextField
                        fullWidth
                        id="filled-basic-title"
                        label="Naslov"
                        error={!currentRecord.title || currentRecord.title === ''}
                        variant="outlined"
                        value={currentRecord.title ?? ''}
                        name="title"
                        onChange={handleInputElementChange}
                    />
                </Grid>
                <Grid key={14} item xs={12} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Datum važenja"
                                disableMaskedInput
                                value={currentRecord.validFrom}
                                inputFormat="DD/MM/YYYY"
                                minDate={dayjs('1940-01-01')}
                                onChange={(newValue) => {
                                    handleValidFromChange(newValue?.toDate());
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                {children}
                <Grid key={120} item xs={12}>
                    <TextField
                        fullWidth
                        id="filled-basic"
                        label="Opis"
                        variant="outlined"
                        multiline
                        rows={6}
                        value={currentRecord.description ?? ''}
                        name="description"
                        onChange={handleInputElementChange}
                    />
                </Grid>
                <Grid key={16} item xs={2}>
                    <div style={{ marginBottom: 15 }}>
                        <Button fullWidth size="large" variant="outlined" component="label" style={{ height: 50 }}>
                            <AttachFileIcon />
                            <input
                                type="file"
                                multiple={selectedFiles.length <= 0 ? true : false}
                                onChange={(e) => handleFileInputChange(e.target.files as FileList)}
                                hidden
                            />
                        </Button>
                    </div>
                </Grid>

                <Grid key={72} item xs={10}>
                    {currentRecord.documents !== undefined ? (
                        currentRecord.documents.map((file) =>
                            file.requestPaths !== undefined ? (
                                file.requestPaths[0].endsWith('.jpg') || file.requestPaths[0].endsWith('.png') ? (
                                    <div
                                        key={file.requestPaths[0]}
                                        style={{
                                            display: 'inline',
                                            height: 100,
                                            width: 'auto',
                                            position: 'relative',
                                        }}
                                    >
                                        <img
                                            style={{ display: 'inline', height: 100, width: 'auto' }}
                                            src={getBaseServerLink(file.requestPaths[0])}
                                        />
                                        <Fab
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                bottom: 70,
                                                width: 40,
                                                height: 40,
                                            }}
                                            onClick={() => deleteFile(file as RecordDocumentDto)}
                                            color="secondary"
                                            aria-label="exit"
                                        >
                                            <CloseIcon />
                                        </Fab>
                                    </div>
                                ) : (
                                    <></>
                                )
                            ) : (
                                <></>
                            ),
                        )
                    ) : (
                        <div></div>
                    )}
                </Grid>
                {currentRecord.documents !== undefined ? (
                    currentRecord.documents.map((file) =>
                        file.requestPaths !== undefined ? (
                            !file.requestPaths[0].endsWith('.jpg') && !file.requestPaths[0].endsWith('.png') ? (
                                <Grid key={file.requestPaths[0] + Math.random()} item xs={2}>
                                    <span
                                        style={{
                                            display: 'inline',
                                            height: 100,
                                            width: 200,
                                            position: 'relative',
                                        }}
                                    >
                                        <span className="file-name-form">
                                            <p style={{ height: 'auto' }}>
                                                {file.requestPaths[0].slice(file.requestPaths[0].lastIndexOf('/') + 1)}
                                            </p>
                                        </span>
                                        <FileIcon
                                            extension={file.requestPaths[0].slice(
                                                file.requestPaths[0].lastIndexOf('.'),
                                            )}
                                            labelColor={
                                                file.requestPaths[0].endsWith('.pdf')
                                                    ? 'rgb(221, 68, 41)'
                                                    : file.requestPaths[0].endsWith('.docx')
                                                    ? 'rgb(0, 103, 199)'
                                                    : file.requestPaths[0].endsWith('.xlsx') ||
                                                      file.requestPaths[0].endsWith('.xlsm') ||
                                                      file.requestPaths[0].endsWith('.xls')
                                                    ? 'rgb(9, 133, 75)'
                                                    : 'default'
                                            }
                                        />
                                        <Fab
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                bottom: 20,
                                                width: 40,
                                                height: 40,
                                            }}
                                            onClick={() => deleteFile(file as RecordDocumentDto)}
                                            color="secondary"
                                            aria-label="exit"
                                        >
                                            <CloseIcon />
                                        </Fab>
                                    </span>
                                </Grid>
                            ) : (
                                <></>
                            )
                        ) : (
                            <></>
                        ),
                    )
                ) : (
                    <></>
                )}

                <Grid key={28} item xs={12}>
                    {selectedFiles !== undefined ? (
                        Array.from(selectedFiles).map((file) =>
                            file.name.endsWith('.jpg') || file.name.endsWith('.png') ? (
                                <div
                                    key={file.name}
                                    style={{ display: 'inline', height: 100, width: 'auto', position: 'relative' }}
                                >
                                    <img
                                        style={{ display: 'inline', height: 100, width: 'auto' }}
                                        src={URL.createObjectURL(file)}
                                    />
                                    <Fab
                                        style={{
                                            position: 'absolute',
                                            right: 0,
                                            bottom: 70,
                                            width: 40,
                                            height: 40,
                                        }}
                                        onClick={() => handleFileDelete(file)}
                                        color="secondary"
                                        aria-label="exit"
                                    >
                                        <CloseIcon />
                                    </Fab>
                                </div>
                            ) : (
                                <></>
                            ),
                        )
                    ) : (
                        <div></div>
                    )}
                </Grid>
                {selectedFiles !== undefined ? (
                    Array.from(selectedFiles).map((file) =>
                        !file.name.endsWith('.jpg') && !file.name.endsWith('.png') ? (
                            <Grid key={file.name + Math.random()} item xs={2}>
                                <span style={{ display: 'inline', height: 100, width: 200, position: 'relative' }}>
                                    <FileIcon
                                        extension={file.name.slice(file.name.lastIndexOf('.'))}
                                        labelColor={
                                            file.name.endsWith('.pdf')
                                                ? 'rgb(221, 68, 41)'
                                                : file.name.endsWith('.docx')
                                                ? 'rgb(0, 103, 199)'
                                                : file.name.endsWith('.xlsx') ||
                                                  file.name.endsWith('.xlsm') ||
                                                  file.name.endsWith('.xls')
                                                ? 'rgb(9, 133, 75)'
                                                : 'default'
                                        }
                                    />
                                    <Fab
                                        style={{
                                            position: 'absolute',
                                            right: 0,
                                            bottom: 20,
                                            width: 40,
                                            height: 40,
                                        }}
                                        onClick={() => handleFileDelete(file)}
                                        color="secondary"
                                        aria-label="exit"
                                    >
                                        <CloseIcon />
                                    </Fab>
                                </span>
                            </Grid>
                        ) : (
                            <></>
                        ),
                    )
                ) : (
                    <div></div>
                )}
            </Grid>
        </>
    );
}
