import { Grid, TextField } from '@mui/material';
import React, { ChangeEvent, ReactElement } from 'react';

type FormTextFieldProps = {
    errorCondition?: boolean;
    entityName: string;
    required?: boolean;
    label?: string;
    defaultValue?: string;
    onChange(event: ChangeEvent<HTMLInputElement>): void;
};

export default function FormTextField({
    errorCondition,
    entityName,
    required,
    label,
    defaultValue,
    onChange,
}: FormTextFieldProps): ReactElement {
    return (
        <Grid item xs={12} md={6}>
            <TextField
                error={errorCondition ?? false}
                name={entityName}
                required={required ?? false}
                fullWidth
                id="filled-basic"
                label={label ?? entityName}
                value={defaultValue}
                variant="standard"
                onChange={onChange}
            />
        </Grid>
    );
}
