import React, { ReactElement, useEffect } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import { RootState } from '../Redux/store';
import './Notifications.css';
import CircleIcon from '@mui/icons-material/Circle';
import { GranitClient } from '../AutoGeneratedAPI/Extension';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { updateOldNotification } from '../Redux/Reducers/Notifications/NotificationsReducer';
import { getTimeDifferenceForTwoDates } from '../../Common/DateHandler';

type NotificationsListProps = {
    anchorEl: null | HTMLElement;
    closeWindow(): void;
    selectNotification(index: number): void;
};

export default function NotificationsList({
    anchorEl,
    closeWindow,
    selectNotification,
}: NotificationsListProps): ReactElement {
    const notifications = useAppSelector((state: RootState) => state.notifications.notifications);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (anchorEl) {
            const unseenNotes = notifications?.filter((n) => !n.seen);
            if (unseenNotes && unseenNotes.length > 0) {
                unseenNotes.map((n) => {
                    const newNote = n;
                    newNote.seen = true;
                    // dispatch(updateOldNotification(newNote));
                    GranitClient.updateNotification(n.id, newNote).then((res) => {
                        dispatch(updateOldNotification(res));
                    });
                });
            }
        }
    }, [anchorEl]);

    const handleItemClick = (index: number) => {
        closeWindow();
        selectNotification(index);
    };

    return (
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeWindow}>
            {notifications !== null && notifications.length > 0 ? (
                notifications.map((note) => (
                    <MenuItem
                        key={note.id}
                        onClick={() => handleItemClick(note.id as number)}
                        style={{ whiteSpace: 'normal' }}
                    >
                        <div className="notification-menu-item-container">
                            <Typography
                                variant="h5"
                                color="textSecondary"
                                component="h5"
                                style={{ marginLeft: !note.closed ? 15 : 0 }}
                            >
                                {note.title ?? 'Nema naslova'}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                component="p"
                                display="block"
                                className="notification-description"
                            >
                                {note.description ?? 'Nema opisa'}
                            </Typography>
                            {!note.closed && (
                                <span style={{ position: 'absolute', left: 0, top: 5 }}>
                                    <CircleIcon style={{ color: '#1976d2', fontSize: 10 }} />
                                </span>
                            )}

                            {note.created && (
                                <span style={{ textAlign: 'end' }}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {getTimeDifferenceForTwoDates(new Date(), note.created)}
                                    </Typography>
                                </span>
                            )}
                            <hr />
                        </div>
                    </MenuItem>
                ))
            ) : (
                <div className="notification-menu-item-container">
                    <Typography variant="h6" color="textSecondary" component="h6" style={{ marginLeft: 10 }}>
                        Trenutno nema podsetnika
                    </Typography>
                </div>
            )}
        </Menu>
    );
}
