import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setInitialExpenses } from '../../../Redux/Reducers/General/ExpensesReducer';
import { setInitialImpDocs } from '../../../Redux/Reducers/General/ImportantDocsReducer';
import { concatRecords, setInitialRecords } from '../../../Redux/Reducers/General/RecordsReducer';
import { RecordSearchTokenModel } from '../../models/RecordSearchTokenModel';
import { isAppTypeClient, isAppTypeDriver, isAppTypeVehicle, isAppTypeWorkingToolCat } from '../../Selectors/Selectors';
import { fetchVehicleRecords, fetchWorkingToolRecords, fetchEmployeeRecords, fetchClientRecords } from './API';

type RecordSearchType = {
    loading: boolean;
    error: boolean;
    hasMore: boolean;
    // loadingExpenses: boolean;
    // hasMoreExpenses: boolean;
    // expenseError: boolean;
};

export default function useRecordsSearch(
    entityId: number,
    query: RecordSearchTokenModel,
    // expenseId?: number,
): RecordSearchType {
    const [loading, setLoading] = useState(true);
    // const [loadingExpenses, setLoadingExpenses] = useState(true);
    const [error, setError] = useState(false);
    // const [expenseError, setExpenseError] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    // const [hasMoreExpenses, setHasMoreExpenses] = useState(false);

    const location = useLocation();
    const path = location.pathname;

    const IsVehicle = isAppTypeVehicle(path);
    const IsWorkingMachine = isAppTypeWorkingToolCat(path);
    const IsDriver = isAppTypeDriver(path);
    const IsClient = isAppTypeClient(path);

    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        setError(false);
        IsVehicle
            ? fetchVehicleRecords(entityId, query).then(
                  (res) => {
                      if (query.lastEntityId && query.lastEntityId > 0) {
                          dispatch(concatRecords(res));
                      } else {
                          dispatch(setInitialRecords(res));
                      }
                      setHasMore(res.length > 0);
                      setLoading(false);
                  },
                  (error) => {
                      setError(true);
                      console.log(error.message);
                  },
              )
            : IsWorkingMachine
            ? fetchWorkingToolRecords(entityId, query).then(
                  (res) => {
                      if (query.lastEntityId && query.lastEntityId > 0) {
                          dispatch(concatRecords(res));
                      } else {
                          dispatch(setInitialRecords(res));
                      }
                      setHasMore(res.length > 0);
                      setLoading(false);
                  },
                  (error) => {
                      setError(true);
                      console.log(error.message);
                  },
              )
            : IsDriver
            ? fetchEmployeeRecords(entityId, query).then(
                  (res) => {
                      if (query.lastEntityId && query.lastEntityId > 0) {
                          dispatch(concatRecords(res));
                      } else {
                          dispatch(setInitialRecords(res));
                      }
                      setHasMore(res.length > 0);
                      setLoading(false);
                  },
                  (error) => {
                      setError(true);
                      console.log(error.message);
                  },
              )
            : IsClient
            ? fetchClientRecords(entityId, query).then(
                  (res) => {
                      if (query.lastEntityId && query.lastEntityId > 0) {
                          dispatch(concatRecords(res));
                      } else {
                          dispatch(setInitialRecords(res));
                      }
                      setHasMore(res.length > 0);
                      setLoading(false);
                  },
                  (error) => {
                      setError(true);
                      console.log(error.message);
                  },
              )
            : console.log('Ne postoje evidencije za ovaj tip entiteta');
    }, [query]);

    // useEffect(() => {
    //     setLoadingExpenses(true);
    //     setExpenseError(false);
    //     const newQuery: ExpenseSearchTokenModel = {
    //         endingDate: query.endingDate,
    //         lastEntityId: expenseId,
    //         numberOfEntitiesToReturn: query.numberOfEntitiesToReturn,
    //         expenseCategory: undefined,
    //         searchText: query.searchText,
    //         startingDate: query.startingDate,
    //     };
    //     IsVehicle
    //         ? fetchVehicleExpenses(entityId, newQuery).then(
    //               (response) => {
    //                   if (expenseId && expenseId > 0) {
    //                       dispatch(concatExpenses(response));
    //                   } else {
    //                       dispatch(setInitialExpenses(response));
    //                   }
    //                   setHasMoreExpenses(response.length > 0);
    //                   setLoadingExpenses(false);
    //               },
    //               (error) => {
    //                   setExpenseError(true);
    //                   console.log(error.message);
    //               },
    //           )
    //         : IsWorkingMachine
    //         ? fetchWorkingToolExpenses(entityId, newQuery).then(
    //               (res) => {
    //                   dispatch(concatExpenses(res));
    //                   setHasMoreExpenses(res.length > 0);
    //                   setLoadingExpenses(false);
    //               },
    //               (error) => {
    //                   setExpenseError(true);
    //                   console.log(error.message);
    //               },
    //           )
    //         : setLoadingExpenses(false);
    // }, [
    //     query.searchText,
    //     query.startingDate,
    //     query.recordCategory,
    //     query.numberOfEntitiesToReturn,
    //     query.endingDate,
    //     expenseId,
    // ]);

    useEffect(() => {
        dispatch(setInitialRecords([]));
        dispatch(setInitialExpenses([]));
        dispatch(setInitialImpDocs([]));
    }, [query.searchText, query.startingDate, query.recordCategory, query.numberOfEntitiesToReturn, query.endingDate]);

    // return { loading, error, hasMore, loadingExpenses, hasMoreExpenses, expenseError };
    return { loading, error, hasMore };
}
