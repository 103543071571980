/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RecordCategory } from '../../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import {
    ExpenseSearchTokenModel,
    ImportantDocsSearchTokenModel,
    InvoiceSearchTokenModel,
    ProjectsSearchToken,
    RecordSearchTokenModel,
    TWSearchTokenModel,
} from '../../models/RecordSearchTokenModel';

export const defaultNumberOfEntities = 20;

export function fetchVehicleExpenses(entityId: number, query?: ExpenseSearchTokenModel) {
    return GranitClient.getAllVehicleExpenses(
        entityId as number,
        query?.expenseCategory,
        query?.searchText,
        query?.startingDate,
        query?.endingDate,
        query?.lastEntityId ?? 0,
        query?.numberOfEntitiesToReturn ?? defaultNumberOfEntities,
    );
}

export function fetchWorkingToolExpenses(entityId: number, query?: ExpenseSearchTokenModel) {
    return GranitClient.getAllWorkingToolExpenses(
        entityId as number,
        query?.expenseCategory,
        query?.searchText,
        query?.startingDate,
        query?.endingDate,
        query?.lastEntityId ?? 0,
        query?.numberOfEntitiesToReturn ?? defaultNumberOfEntities,
    );
}

export function fetchVehicleImpDocs(entityId: number, query?: ImportantDocsSearchTokenModel) {
    return GranitClient.getAllVehicleRecords(
        entityId as number,
        RecordCategory.ImportantDocs,
        query?.searchText,
        query?.startingDate,
        query?.endingDate,
        query?.lastEntityId ?? 0,
        query?.numberOfEntitiesToReturn ?? defaultNumberOfEntities,
    );
}

export function fetchEmployeeImpDocs(entityId: number, query?: ImportantDocsSearchTokenModel) {
    return GranitClient.getAllEmployeeRecords(
        entityId as number,
        RecordCategory.ImportantDocs,
        query?.searchText,
        query?.startingDate,
        query?.endingDate,
        query?.lastEntityId ?? 0,
        query?.numberOfEntitiesToReturn ?? defaultNumberOfEntities,
    );
}

export function fetchVehicleInvoices(entityId: number, query?: InvoiceSearchTokenModel) {
    return GranitClient.getAllInvoicesForClient(
        entityId as number,
        query?.searchText,
        query?.startingDate,
        query?.endingDate,
        query?.lastEntityId ?? 0,
        query?.numberOfEntitiesToReturn ?? defaultNumberOfEntities,
    );
}

export function fetchProjects(entityId: number, query?: ProjectsSearchToken) {
    return GranitClient.getAllProjectsForClient(
        entityId as number,
        query?.searchText,
        query?.lastEntityId ?? 0,
        query?.numberOfEntitiesToReturn ?? defaultNumberOfEntities,
    );
}

export function fetchConstructionSites(projectId: number) {
    return GranitClient.getAllConstructionSitesForProject(projectId);
}

export function fetchVehicleRecords(entityId: number, query?: RecordSearchTokenModel) {
    return GranitClient.getAllVehicleRecords(
        entityId as number,
        // undefined,
        RecordCategory.Record,
        query?.searchText,
        query?.startingDate,
        query?.endingDate,
        query?.lastEntityId ?? 0,
        query?.numberOfEntitiesToReturn ?? defaultNumberOfEntities,
    );
}

export function fetchEmployeeRecords(entityId: number, query?: RecordSearchTokenModel) {
    return GranitClient.getAllEmployeeRecords(
        entityId as number,
        // undefined,
        RecordCategory.Record,
        query?.searchText,
        query?.startingDate,
        query?.endingDate,
        query?.lastEntityId ?? 0,
        query?.numberOfEntitiesToReturn ?? defaultNumberOfEntities,
    );
}

export function fetchWorkingToolRecords(entityId: number, query?: RecordSearchTokenModel) {
    return GranitClient.getAllWorkingToolRecords(
        entityId as number,
        // undefined,
        RecordCategory.Record,
        query?.searchText,
        query?.startingDate,
        query?.endingDate,
        query?.lastEntityId ?? 0,
        query?.numberOfEntitiesToReturn ?? defaultNumberOfEntities,
    );
}

export function fetchClientRecords(entityId: number, query?: RecordSearchTokenModel) {
    return GranitClient.getAllClientRecords(
        entityId as number,
        // undefined,
        RecordCategory.Record,
        query?.searchText,
        query?.startingDate,
        query?.endingDate,
        query?.lastEntityId ?? 0,
        query?.numberOfEntitiesToReturn ?? defaultNumberOfEntities,
    );
}

export function fetchVehicleTransportWarrants(entityId: number, onlyFinished: boolean, query?: TWSearchTokenModel) {
    return GranitClient.getVehicleTransportWarrants(
        entityId as number,
        onlyFinished,
        query?.clientId,
        query?.startingDate,
        query?.endingDate,
        query?.lastEntityId ?? 0,
        query?.numberOfEntitiesToReturn ?? defaultNumberOfEntities,
    );
}

export function fetchDriverTransportWarrants(entityId: number, onlyFinished: boolean, query?: TWSearchTokenModel) {
    return GranitClient.getDriverTransportWarrants(
        entityId as number,
        onlyFinished,
        query?.clientId,
        query?.startingDate,
        query?.endingDate,
        query?.lastEntityId ?? 0,
        query?.numberOfEntitiesToReturn ?? defaultNumberOfEntities,
    );
}
