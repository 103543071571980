/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkingMachineDto } from '../../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithAdding, ExceptionsWithUpdating } from '../../../Extensions/Exceptions';
import { addWorkingMachine, updateWorkingMachine } from '../../Reducers/WorkingTools/WorkingMachinesReducer';
import { changeCurrentEntity } from '../../Reducers/General/CurrentEntityReducer';

export const addNewWorkingMachine = (workingMachine: WorkingMachineDto | WorkingMachineDto | undefined) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    {
        GranitClient.addNewWorkingMachine(workingMachine as WorkingMachineDto).then(
            (result) => {
                dispatch(addWorkingMachine(result));
            },
            (error) => {
                alert(ExceptionsWithAdding.EXCEPTION_ADDING_WORKINGMACHINES);
                console.log(error.message);
            },
        );
    }
};

export const updateOldWorkingMachine = (
    workingMachine: WorkingMachineDto | WorkingMachineDto | undefined,
    oldWorkingMachine?: WorkingMachineDto | WorkingMachineDto,
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
    GranitClient.updateWorkingMachine(
        workingMachine !== undefined ? (workingMachine.id as number) : 0,
        workingMachine as WorkingMachineDto,
    ).then(
        (result) => {
            dispatch(changeCurrentEntity(result));
            dispatch(updateWorkingMachine(workingMachine as WorkingMachineDto, oldWorkingMachine));
        },
        (error) => {
            alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_WORKINGMACHINES);
            console.log(error.message);
        },
    );
};
