import React, { ReactElement, useState } from 'react';
import DetailsImage from './DetailsImage';
import DetailsText from './DetailsText';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import './Details.css';

const drawerWidth = 600;
// const drawerHeight = 'calc(100vh - 68px - 130px)';
const drawerHeight = 280;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    height: drawerHeight,
    marginTop: 66,
    border: '1px solid lightgray',
    borderTop: 'none',
    borderRadius: '0px 0px 0px 5px',
    transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
    }),
    overflowX: 'hidden',
    height: 0,
    marginTop: 66,
    [theme.breakpoints.up('sm')]: {
        height: 0,
    },
    width: drawerWidth,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    zIndex: 1,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    height: drawerHeight,
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

export default function DetailsSidebar(): ReactElement {
    const [open, setOpen] = useState(false);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    return (
        <>
            <div
                onClick={handleDrawerToggle}
                style={{
                    position: 'absolute',
                    right: 0,
                    zIndex: 5,
                    marginTop: open ? drawerHeight + 66 : 66,
                    transition: 'margin 0.3s',
                    border: '1px solid lightgray',
                    borderTop: 'none',
                    borderRadius: '0px 0px 15px 15px',
                }}
            >
                <div
                    style={{
                        backgroundColor: 'white',
                        borderRadius: '0px 0px 15px 15px',
                        width: 60,
                        height: 40,
                        padding: '10px 0px',
                        boxShadow: '-2px 2px 5px 1px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    <div
                        style={{
                            width: 40,
                            height: 40,
                            margin: 'auto',
                            cursor: 'pointer',
                        }}
                    >
                        <div className="arrow">
                            <span className={open ? 'active first-span' : 'first-span'} />
                            <span className={open ? 'active second-span' : 'second-span'} />
                        </div>
                    </div>
                </div>
            </div>
            <Drawer variant="permanent" anchor="right" open={open} onClose={handleDrawerToggle}>
                {open && (
                    <div
                        style={{
                            display: 'flex',
                            gap: 20,
                            overflow: 'hidden',
                            height: drawerHeight,
                            zIndex: 1,
                        }}
                    >
                        <div style={{ width: 250 }}>
                            <DetailsImage />
                        </div>
                        <div style={{ overflowY: 'auto', width: 330 }}>
                            <DetailsText />
                        </div>
                    </div>
                )}
            </Drawer>
        </>
    );
}
