import React, { useCallback, useEffect, useState } from 'react';
import { ReactElement } from 'react';
import { LoaderProgress } from './Loader/LoaderProgress';
import './ImageComponent.css';

interface ImageComponentProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    placeholderImg?: string;
    isCalendar?: boolean;
    isDetails?: boolean;
}

export default function ImageComponent({ placeholderImg, src, ...props }: ImageComponentProps): ReactElement {
    const [imgSrc, setSrc] = useState(placeholderImg || src);

    const onLoad = useCallback(() => {
        setSrc(src);
    }, [src]);

    useEffect(() => {
        const img = new Image();
        img.src = src as string;
        img.addEventListener('load', onLoad);

        return () => {
            img.removeEventListener('load', onLoad);
        };
    }, [src, onLoad]);

    if (imgSrc !== src) {
        return (
            <div className={props.isDetails ? '' : props.isCalendar ? 'calendar-card-img' : 'card-img'}>
                <div
                    className={props.isDetails ? '' : props.isCalendar ? 'calendar-card-img-loader' : 'card-img-loader'}
                >
                    <LoaderProgress />
                </div>
            </div>
        );
    }
    return (
        <img
            className={props.isDetails ? '' : props.isCalendar ? 'calendar-card-img' : 'card-img'}
            alt={imgSrc}
            src={imgSrc}
        />
    );
}
