import React, { ChangeEvent, ReactElement, useState } from 'react';
import { Grid, TextField, Stack } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { TruckDto } from '../General/AutoGeneratedAPI/clientApi';
import { useAppSelector } from '../General/hooks/hooks';
import { RootState } from '../General/Redux/store';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/sr';
import { isTruckSearched } from './ChartVehicleSelectHelper';

type ChartVehicleSelectProps = {
    startDate: string;
    endDate: string;
    setStartDate(date?: Date): void;
    setEndDate(date?: Date): void;
};

export default function ChartVehicleSelect({
    endDate,
    setEndDate,
    setStartDate,
    startDate,
}: ChartVehicleSelectProps): ReactElement {
    const trucks = useAppSelector((state: RootState) => state.vehicles.trucks)?.filter((tr) => !tr.isDeleted);
    const [vehicles, setVehicles] = useState<TruckDto[]>(trucks ?? []);
    const [vehicle, setVehicle] = useState<string>('');

    const [searchParams, setSearchParams] = useSearchParams();

    const handleVehicleIdSelect = (vehicleId: number) => {
        const search = {
            vehicleId: vehicleId.toString(),
        };

        setSearchParams(search, { replace: true });
    };

    useEffect(() => {
        if (trucks && trucks.length > 0 && vehicles.length === 0) {
            setVehicles(trucks ?? []);
        }
    }, [trucks]);

    useEffect(() => {
        const vehId = searchParams.get('vehicleId');

        if (vehId && !isNaN(+vehId) && +vehId > 0 && trucks) {
            const vehicleId = +vehId;
            const truck = trucks.find((tr) => tr.id === vehicleId);
            if (truck) {
                const veh =
                    (truck.manufacturer ?? '') + ' ' + (truck.model ?? '') + ' ' + (truck.licensePlateNum ?? '');
                setVehicle(veh);
            } else {
                setVehicle('');
            }
        } else {
            setVehicles(trucks ?? []);
            setVehicle('');
        }
    }, [searchParams, trucks]);

    const handleVehicleSelect = (event: ChangeEvent<unknown>, value: string | null): void => {
        if (value !== null) {
            const searchTokens = value.toLowerCase().split(' ');

            const searchedTruck = trucks?.find((t) => {
                return isTruckSearched(t, searchTokens);
            });

            if (searchedTruck && searchedTruck?.id >= 0) {
                handleVehicleIdSelect(searchedTruck.id);
            }
        } else {
            handleVehicleIdSelect(0);
        }
    };

    const handleVehicleChange = (event: ChangeEvent<{ value: unknown }>): void => {
        const searchToken: string = (event.target.value as string) ?? '';

        setVehicle(searchToken);

        const searchTokens = searchToken.toLowerCase().split(' ');

        const currVehicles: TruckDto[] = [];

        if (searchToken === '') {
            setVehicles(trucks ?? []);
        } else {
            trucks?.map((v) => {
                if (isTruckSearched(v, searchTokens)) {
                    currVehicles.push(v);
                }
            });

            setVehicles(currVehicles);
        }
    };

    return (
        <div
            style={{
                width: 'calc(100vw - 220px)',
                minWidth: 600,
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={vehicles.map(
                            (option) =>
                                (option.manufacturer ?? '') +
                                ' ' +
                                (option.model ?? '') +
                                ' ' +
                                (option.licensePlateNum ?? ''),
                        )}
                        value={vehicle}
                        onChange={handleVehicleSelect}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                id="filled-basic"
                                label="Vozilo"
                                variant="filled"
                                style={{ backgroundColor: '#f7f7f7', borderRadius: 5 }}
                                onChange={handleVehicleChange}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={3} style={{ position: 'relative' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Početni datum troška"
                                value={new Date(startDate) ?? ''}
                                inputFormat="DD.MM.YYYY"
                                disableFuture
                                onChange={(newValue) => {
                                    setStartDate(newValue ?? undefined);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={3} style={{ position: 'relative' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Završni datum troška"
                                value={new Date(endDate) ?? ''}
                                inputFormat="DD.MM.YYYY"
                                disableFuture
                                onChange={(newValue) => {
                                    setEndDate(newValue ?? undefined);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </div>
    );
}
