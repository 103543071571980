import { CarDto, TruckDto, TrailerDto } from '../../../AutoGeneratedAPI/clientApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithFetching } from '../../../Extensions/Exceptions';

interface IInitialStateType {
    cars: CarDto[] | null;
    trucks: TruckDto[] | null;
    trailers: TrailerDto[] | null;
}

const initialState: IInitialStateType = {
    cars: null,
    trucks: null,
    trailers: null,
};

export const fetchInitialCars = createAsyncThunk(
    'vehicles/fetchInitialCars',
    async () => {
        const response = await GranitClient.getAllCars();
        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingCars: CarDto[] | null = thunkAPI.getState().vehicles.cars;

            return existingCars === null;
        },
    },
);
export const fetchInitialTrucks = createAsyncThunk(
    'vehicles/fetchInitialTrucks',
    async () => {
        const response = await GranitClient.getAllTrucks();
        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingTrucks: TruckDto[] | null = thunkAPI.getState().vehicles.trucks;

            return existingTrucks === null;
        },
    },
);
export const fetchInitialTrailers = createAsyncThunk(
    'vehicles/fetchInitialTrailers',
    async () => {
        const response = await GranitClient.getAllTrailers();
        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingTrailers: TrailerDto[] | null = thunkAPI.getState().vehicles.trailers;

            return existingTrailers === null;
        },
    },
);

const vehiclesSlice = createSlice({
    name: 'vehicles',
    initialState,
    reducers: {
        addCar(state, action) {
            state.cars = [action.payload, ...(state.cars ?? [])];
        },
        addTruck(state, action) {
            state.trucks = [action.payload, ...(state.trucks ?? [])];
        },
        addTrailer(state, action) {
            state.trailers = [action.payload, ...(state.trailers ?? [])];
        },
        changeVehicleProfileImage: {
            reducer(state, action) {
                const carIndex = state.cars?.findIndex((c) => {
                    return c.id === action.payload.vehicleId;
                });
                const truckIndex = state.trucks?.findIndex((c) => {
                    return c.id === action.payload.vehicleId;
                });
                const trailerIndex = state.trailers?.findIndex((c) => {
                    return c.id === action.payload.vehicleId;
                });
                if (state.cars && carIndex !== undefined && carIndex >= 0) {
                    const car = state.cars[carIndex];
                    car.documents = [action.payload.doc, ...(car.documents?.slice(1) ?? [])];
                    state.cars = [...state.cars.slice(0, carIndex), car, ...state.cars.slice(carIndex + 1)];
                }
                if (state.trucks && truckIndex !== undefined && truckIndex >= 0) {
                    const truck = state.trucks[truckIndex];
                    truck.documents = [action.payload.doc, ...(truck.documents?.slice(1) ?? [])];
                    state.trucks = [...state.trucks.slice(0, truckIndex), truck, ...state.trucks.slice(truckIndex + 1)];
                }
                if (state.trailers && trailerIndex !== undefined && trailerIndex >= 0) {
                    const trailer = state.trailers[trailerIndex];
                    trailer.documents = [action.payload.doc, ...(trailer.documents?.slice(1) ?? [])];
                    state.trailers = [
                        ...state.trailers.slice(0, trailerIndex),
                        trailer,
                        ...state.trailers.slice(trailerIndex + 1),
                    ];
                }
            },
            prepare(vehicleId, doc) {
                return {
                    payload: { vehicleId, doc },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
        updateCar: {
            reducer(state, action) {
                const { car, oldCar } = action.payload;
                const cars = state.cars ?? [];
                const carIndex = cars.findIndex((c) => {
                    return c.id === oldCar.id;
                });
                state.cars = [...cars.slice(0, carIndex), car, ...cars.slice(carIndex + 1)];
            },
            prepare(car, oldCar) {
                return {
                    payload: { car, oldCar },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
        updateTruck: {
            reducer(state, action) {
                const { truck, oldTruck } = action.payload;
                const trucks = state.trucks ?? [];
                const truckIndex = trucks.findIndex((t) => {
                    return t.id === oldTruck.id;
                });
                state.trucks = [...trucks.slice(0, truckIndex), truck, ...trucks.slice(truckIndex + 1)];
            },
            prepare(truck, oldTruck) {
                return {
                    payload: { truck, oldTruck },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
        updateTrailer: {
            reducer(state, action) {
                const { trailer, oldTrailer } = action.payload;
                const trailers = state.trailers ?? [];
                const trailerIndex = trailers.findIndex((t) => {
                    return t.id === oldTrailer.id;
                });
                state.trailers = [...trailers.slice(0, trailerIndex), trailer, ...trailers.slice(trailerIndex + 1)];
            },
            prepare(trailer, oldTrailer) {
                return {
                    payload: { trailer, oldTrailer },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInitialCars.fulfilled, (state, action) => {
            state.cars = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialCars.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_CARS);
        });
        builder.addCase(fetchInitialTrucks.fulfilled, (state, action) => {
            state.trucks = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialTrucks.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_TRUCKS);
        });
        builder.addCase(fetchInitialTrailers.fulfilled, (state, action) => {
            state.trailers = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialTrailers.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_TRAILERS);
        });
    },
});

export const {
    addCar,
    updateCar,
    addTrailer,
    updateTrailer,
    addTruck,
    updateTruck,
    changeVehicleProfileImage,
} = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
