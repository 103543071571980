import { Card, CardContent, CardHeader, Fab, Modal, Typography } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import './ProfileCard.css';
import CloseIcon from '@mui/icons-material/Close';
import { ImageCarousel } from '../Carousel/Carousel';
import { ExpenseCategory, VehicleExpenseDto, RecordCategory, EntityType } from '../../AutoGeneratedAPI/clientApi';
import { RecordType } from '../../Extensions/Extensions';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import ProfileCardHelper from './ProfileCardHelper';
import { ImageContainer } from './ImageContainer';
import { ExpenseEntityType, RecordEntitiesType } from '../../Extensions/Entities';
import ViberRequestModal from '../ViberRequestModal';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import BuildIcon from '@mui/icons-material/Build';
import LoopIcon from '@mui/icons-material/Loop';
import { numberFormatter } from '../../../Common/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/hooks';
import { RootState } from '../../Redux/store';
import NotificationsIcon from '@mui/icons-material/Notifications';

type ProfileCardProps = {
    record: RecordEntitiesType;
    recordType: RecordType | undefined;
};

export default function ProfileCard({ record, recordType }: ProfileCardProps): ReactElement {
    const [openCarousel, setOpenCarousel] = useState<boolean>(false);
    const [imageIndex, setImageIndex] = useState<number>(0);
    const [openViberRequest, setOpenViberRequest] = useState<boolean>(false);
    const [hovered, setHovered] = useState<boolean>(false);

    const kmEvents = useAppSelector((state: RootState) => state.notifications.kmEvents);
    const timeEvents = useAppSelector((state: RootState) => state.notifications.timeEvents);

    const navigate = useNavigate();

    const location = useLocation();
    const path = location.pathname;

    const handleSelectRecord = (): void => {
        if (isNaN(+path.substring(path.lastIndexOf('/') + 1))) {
            navigate(path + '/' + record.id);
        } else {
            navigate(path.substring(0, path.lastIndexOf('/') + 1) + record.id);
        }
    };

    const handleCarouselOpen = (index: number): void => {
        setImageIndex(index);
        setOpenCarousel(true);
    };

    const handleCarouselClose = (): void => {
        setOpenCarousel(false);
    };

    const handleCloseViberModal = (): void => {
        setOpenViberRequest(false);
    };

    const sendViberRequest = (): void => {
        GranitClient.sendUploadMediaRequest(
            record.id as number,
            record.category === RecordCategory.ImportantDocs ? 'ImportantDocuments' : 'default',
        );
    };

    const subheader = record.validFrom
        ? new Date(record.validFrom as Date).toLocaleDateString('en-UK')
        : record.created
        ? new Date(record.created as Date).toLocaleDateString('en-UK')
        : 'Nema datum kreiranja';

    return (
        <Card className="profile-card">
            <CardHeader
                title={
                    <Typography
                        variant="body1"
                        component="p"
                        style={{ maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                        {record?.title}
                    </Typography>
                }
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className={hovered ? 'profile-card-content profile-card-content-hovered' : 'profile-card-content'}
                subheader={
                    <>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            component="p"
                            style={{ display: 'block', float: 'left' }}
                        >
                            {subheader}
                        </Typography>
                        {recordType === RecordType.Expense &&
                            (record as ExpenseEntityType).expenseKm !== null &&
                            (record as ExpenseEntityType).expenseKm !== undefined && (
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    component="p"
                                    style={{ display: 'block', float: 'right' }}
                                >
                                    {(record as ExpenseEntityType).expenseKm}km
                                </Typography>
                            )}
                    </>
                }
                avatar={
                    !kmEvents?.find(
                        (kme) =>
                            (kme.entityType === EntityType.Expense || kme.entityType === EntityType.Record) &&
                            kme.entityId === record.id,
                    ) &&
                    !timeEvents?.find(
                        (te) =>
                            (te.entityType === EntityType.Expense || te.entityType === EntityType.Record) &&
                            te.entityId === record.id,
                    ) ? (
                        <></>
                    ) : (
                        <NotificationsIcon style={{ color: 'primary' }} />
                    )
                }
                onClick={handleSelectRecord}
            />
            <>
                <ImageContainer
                    link={ProfileCardHelper.convertFromRecordDocumentToString(record.documents ?? [])}
                    handleCarouselOpen={handleCarouselOpen}
                />
                <Modal
                    className="profile-card-modal-style"
                    open={openCarousel}
                    aria-labelledby="carousel"
                    aria-describedby="image gallery carousel"
                >
                    <>
                        <ImageCarousel
                            imageLinks={ProfileCardHelper.convertFromRecordDocumentToString(record.documents ?? [])}
                            imageIndex={imageIndex}
                        />
                        <Fab onClick={handleCarouselClose} className="close-btn" color="secondary" aria-label="exit">
                            <CloseIcon />
                        </Fab>
                    </>
                </Modal>
            </>
            <CardContent
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className={hovered ? 'profile-card-content profile-card-content-hovered' : 'profile-card-content'}
                onClick={handleSelectRecord}
            >
                {recordType === RecordType.Expense &&
                    (record as ExpenseEntityType).expenseType?.expenseCategory === ExpenseCategory.Fuel && (
                        <LocalGasStationIcon style={{ margin: 'auto', fontSize: 25, position: 'relative', top: 4 }} />
                    )}
                {recordType === RecordType.Expense &&
                    (record as ExpenseEntityType).expenseType?.expenseCategory ===
                        ExpenseCategory.RepairAndMaintenance && (
                        <BuildIcon style={{ margin: 'auto', fontSize: 25, position: 'relative', top: 4 }} />
                    )}
                {recordType === RecordType.Expense &&
                    (record as ExpenseEntityType).expenseType?.expenseCategory === ExpenseCategory.RecurringExpense && (
                        <LoopIcon style={{ margin: 'auto', fontSize: 25, position: 'relative', top: 4 }} />
                    )}
                {'\t'}
                <Typography variant="h6" color="textSecondary" component="h6" style={{ display: 'inline-block' }}>
                    {recordType === RecordType.Expense
                        ? ((record as VehicleExpenseDto).price as number) > 0
                            ? numberFormatter.format((record as VehicleExpenseDto).price) + ' RSD'
                            : ''
                        : ''}
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{
                        maxWidth: 250,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        height: 15,
                    }}
                >
                    {record.description}
                </Typography>
            </CardContent>

            <ViberRequestModal
                open={openViberRequest}
                closeModal={handleCloseViberModal}
                sendViberRequest={sendViberRequest}
                title={record.title ?? ''}
            />
        </Card>
    );
}
