/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, MouseEvent, useCallback, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RecordType } from '../../../../Extensions/Extensions';
import { RootState } from '../../../../Redux/store';
import EmptyPage from '../../../../EmptyPage';
import { ImportantDocsSearchTokenModel } from '../../../../Extensions/models/RecordSearchTokenModel';
import useImpDocsSearch from '../../../../Extensions/Hooks/RecordsHooks/ImportantDocsSearchingHook';
import MuiTableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Fab, Modal, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { RecordEntityType } from '../../../../Extensions/Entities';
import ProfileCard from '../../../ProfileCards/ProfileCard';
import ImportantDocsMenu from '../ImportantDocsMenu';
import DeleteRecModal from '../../../DeleteRecModal';
import { deleteVehicleRecord } from '../../../../Redux/Actions/General/RecordsAction';
import '../../../TableView.css';
import { LoaderProgress } from '../../../../../Common/CommonComponents/Loader/LoaderProgress';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const TableCell = styled(
    MuiTableCell,
    {},
)(({}) => ({
    borderBottom: 'unset',
}));

function createData(id: number, title: string, dateValid: string) {
    return {
        id,
        title,
        dateValid,
    };
}

type ImportantDocsFeedElementProps = {
    query: ImportantDocsSearchTokenModel;
    setImpDocId(impDocId: number): void;
};

export default function ImportantDocsFeedElement({ query, setImpDocId }: ImportantDocsFeedElementProps): ReactElement {
    const impDocs = useSelector((state: RootState) => state.importantDocs.importantDocs);
    const entity = useSelector((state: RootState) => state.currentEntity.entity);

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLTableRowElement | null>(null);
    const [currId, setCurrId] = useState(-1);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const dispatch = useDispatch();

    const { loading, hasMore, error } = useImpDocsSearch(entity?.id ?? 0, query);

    const { impDocId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    const observer = useRef<IntersectionObserver>();
    const bottomRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (impDocId && !isNaN(+impDocId)) {
            if (!impDocs?.find((r) => r.id === +impDocId)) {
                bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
            }
            handleCloseModal();
        }
    }, [impDocId, impDocs]);

    const lastImpDocRef = useCallback(
        (node: any) => {
            if (loading) {
                return;
            }

            if (observer.current) {
                observer.current.disconnect();
            }
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setImpDocId(impDocs[impDocs.length - 1].id ?? 0);
                }
            });

            if (node) {
                observer.current.observe(node);
            }
        },
        [loading, hasMore],
    );

    const closeSideDetail = () => {
        if (impDocId && !isNaN(+impDocId)) {
            navigate(path.substring(0, path.lastIndexOf('/')));
        }
    };

    const onElementClick = (event: MouseEvent<HTMLTableRowElement>, id: number) => {
        handleOpenAnchor(event);
        setCurrId(id);
    };

    const handleOpenModal = () => {
        closeSideDetail();
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleOpenDeleteModal = (): void => {
        setOpenDeleteModal(true);
        handleCloseAnchor();
    };

    const handleCloseDeleteModal = (): void => {
        setOpenDeleteModal(false);
    };

    const handleOpenAnchor = (event: MouseEvent<HTMLTableRowElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAnchor = (): void => {
        setAnchorEl(null);
    };

    const deleteDocument = () => {
        if (currId >= 0) {
            const rec = impDocs.find((r) => r.id == currId);
            if (rec) {
                dispatch(deleteVehicleRecord(currId, rec));
                handleCloseAnchor();
            }
        }
    };

    const rows = impDocs.map((r) =>
        createData(
            r.id ?? -1,
            r.title ? (r.title !== '' ? r.title : 'Nema naslova') : 'Nema naslova',
            r.validFrom ? new Date(r.validFrom as Date).toLocaleDateString('en-UK') : 'Nema datum važenja',
        ),
    );

    const ImportantRecordModal = () => {
        const impDoc = impDocs.find((id) => id.id == currId);

        return (
            <Modal
                className="table-modalStyle"
                open={open}
                aria-labelledby="add-travel-warrant-form"
                aria-describedby="adding a travel warrant to a database through this form"
            >
                <div>
                    <div className="table-profile-card">
                        <ProfileCard record={impDoc as RecordEntityType} recordType={RecordType.Expense} />
                    </div>

                    <Fab
                        onClick={() => handleCloseModal()}
                        className="table-close-btn"
                        color="secondary"
                        aria-label="exit"
                    >
                        <CloseIcon />
                    </Fab>
                </div>
            </Modal>
        );
    };

    if (!loading && !hasMore && impDocs.length <= 0) {
        return <EmptyPage title="Nema dokumenata!" />;
    }

    if (loading && (!impDocs || impDocs.length <= 0)) {
        return (
            <div style={{ height: '60vh', width: '60vh', margin: 'auto' }}>
                <LoaderProgress />
            </div>
        );
    }
    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Naziv</TableCell>
                            <TableCell />
                            <TableCell align="right" width={150} style={{ textAlign: 'center' }}>
                                Datum važenja
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                            if (rows.length === index + 1) {
                                return (
                                    <TableRow
                                        key={index}
                                        ref={lastImpDocRef}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                            onElementClick(e, row.id);
                                        }}
                                    >
                                        <TableCell ref={bottomRef} />
                                        <TableCell component="th" scope="row">
                                            {row.title}
                                        </TableCell>
                                        <TableCell />
                                        <TableCell align="right" width={150} style={{ textAlign: 'center' }}>
                                            {row.dateValid}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                );
                            } else {
                                return (
                                    <TableRow
                                        key={index}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                            onElementClick(e, row.id);
                                        }}
                                    >
                                        <TableCell />
                                        <TableCell component="th" scope="row">
                                            {row.title}
                                        </TableCell>
                                        <TableCell />
                                        <TableCell align="right" width={150} style={{ textAlign: 'center' }}>
                                            {row.dateValid}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                );
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div>{loading && 'Učitava se...'}</div>
            <div>{error && 'Greška'}</div>
            <ImportantRecordModal />
            <ImportantDocsMenu
                anchorEl={anchorEl}
                handleOpenModal={handleOpenModal}
                handleCloseAnchor={handleCloseAnchor}
                openDeleteModal={handleOpenDeleteModal}
            />
            <DeleteRecModal
                open={openDeleteModal}
                deleteDoc={deleteDocument}
                title={'ovaj dokument'}
                closeModal={handleCloseDeleteModal}
            />
        </>
    );
}
