import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import customer_logo from '../../General/assets/img/drivers_logo.png';
import * as React from 'react';
import { ClientDto } from '../../General/AutoGeneratedAPI/clientApi';
import '../../General/Catalogue/Catalogue.css';
import { ReactElement } from 'react';
import { getBaseServerLink } from '../../General/AutoGeneratedAPI/Extension';
import { PROFILE_IMAGE } from '../../Common/Consts';
import { useState } from 'react';
import { useEffect } from 'react';
import ImageComponent from '../../Common/CommonComponents/ImageComponent';

interface CustomersCardContainerProps {
    client: ClientDto;
    isCalendar?: boolean;
}

export default function CustomersCardContainer({ client, isCalendar }: CustomersCardContainerProps): ReactElement {
    const [profileImageLink, setProfileImageLink] = useState<string>('');

    useEffect(() => {
        const docs = client.documents;
        if (client && docs && docs.length > 0) {
            const doc = docs.find((c) => {
                return c?.category === PROFILE_IMAGE;
            });
            if (doc?.requestPaths) {
                setProfileImageLink(doc.requestPaths[0]);
            }
        }
    });

    return (
        <Card className={isCalendar ? 'calendar-card' : 'card'}>
            <CardActionArea>
                <ImageComponent
                    src={profileImageLink !== '' ? getBaseServerLink(profileImageLink) : customer_logo}
                    placeholder={''}
                    isCalendar={isCalendar}
                />
                <CardContent>
                    <Typography
                        gutterBottom
                        variant={isCalendar ? 'body2' : 'subtitle1'}
                        component="p"
                        className="catalogue-card-text"
                    >
                        {client.name}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
