import React, { ReactElement, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Fab, Modal } from '@mui/material';
import './AdditionsBtn.css';
import VehicleForm from './Forms/VehicleForm';
import WorkingToolForm from './Forms/WorkingToolForm';
import { isAppTypeClient, isAppTypeVehicle, isAppTypeCatalogue } from './Extensions/Selectors/Selectors';
import CustomerForm from './Forms/CustomersForm/CustomerForm';
import { useLocation } from 'react-router-dom';

type AdditionBtnProps = {
    shouldDisplay: boolean;
};

export default function AdditionBtn({ shouldDisplay }: AdditionBtnProps): ReactElement {
    const [open, setOpen] = useState(false);

    const location = useLocation();
    const path = location.pathname;

    const isVehicles = isAppTypeVehicle(path);
    const isClient = isAppTypeClient(path);

    const isCatalogue = isAppTypeCatalogue(path);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            <Fab
                onClick={handleOpen}
                className="additions-btn"
                color="primary"
                aria-label="add"
                disabled={!shouldDisplay}
                style={isCatalogue ? { display: '' } : { display: 'none' }}
            >
                <AddIcon />
            </Fab>
            <div>
                <Modal
                    className="modalStyle"
                    open={open}
                    aria-labelledby="add-vehicle-form"
                    aria-describedby="adding a vehicle to a database through this form"
                >
                    <>
                        {isVehicles ? (
                            <VehicleForm closeForm={handleClose} />
                        ) : isClient && isCatalogue ? (
                            <CustomerForm closeForm={handleClose} />
                        ) : (
                            <WorkingToolForm closeForm={handleClose} />
                        )}
                    </>
                </Modal>
            </div>
        </>
    );
}
