/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { VehicleExpenseDto, WorkingToolExpenseDto } from '../../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExpenseEntityType } from '../../../Extensions/Entities';
import { ExceptionsWithAdding, ExceptionsWithDeleting, ExceptionsWithUpdating } from '../../../Extensions/Exceptions';
import { addExpense, updateExpense, addExpenseDoc, deleteExpense } from '../../Reducers/General/ExpensesReducer';
import { isAppTypeChart, isAppTypeVehicle, isAppTypeWorkingToolCat } from '../../../Extensions/Selectors/Selectors';

// HANDLING RECORD AND EXPENSE UPDATE AND ADD

const fileToSend: File[] = [];

const BAD_EXPENSE_DATE_OR_KM_RANGE = [
    'BAD_EXPENSE_DATE_OR_KM_RANGE',
    'Greška pri unosu datuma validnosti ili kilometraže sipanja goriva',
];

const BAD_EXPENSE_DATE_OR_HR_RANGE = [
    'BAD_EXPENSE_DATE_OR_HR_RANGE',
    'Greška pri unosu datuma validnosti ili radnih časova sipanja goriva',
];

const getErrorMessageRhDate = (errorMessage: string) => {
    return errorMessage === BAD_EXPENSE_DATE_OR_HR_RANGE[0]
        ? BAD_EXPENSE_DATE_OR_HR_RANGE[1]
        : ExceptionsWithAdding.EXCEPTION_ADDING_EXPENSES;
};

const getErrorMessageKmDate = (errorMessage: string) => {
    return errorMessage === BAD_EXPENSE_DATE_OR_KM_RANGE[0]
        ? BAD_EXPENSE_DATE_OR_KM_RANGE[1]
        : ExceptionsWithAdding.EXCEPTION_ADDING_EXPENSES;
};

export const addNewExpense = (
    expense: ExpenseEntityType,
    entityId: number,
    path: string,
    selectedFiles: Array<File>,
) => (dispatch: (arg0: { payload: any; type: string }) => number) => {
    if ((isAppTypeVehicle(path) || isAppTypeChart(path)) && entityId > 0) {
        return GranitClient.addNewVehicleExpense(entityId, expense as VehicleExpenseDto).then(
            (result) => {
                dispatch(addExpense(result));
                selectedFiles !== undefined
                    ? selectedFiles.map((sf) => {
                          (fileToSend[0] = sf),
                              GranitClient.saveRecordDocument(
                                  result.id as number,
                                  'default',
                                  fileToSend,
                                  '{ "test": "ovo je test"}',
                              ).then(
                                  (res) => {
                                      dispatch(addExpenseDoc(result, res));
                                  },
                                  (error) => {
                                      console.log(error.message);
                                      alert(ExceptionsWithAdding.EXCEPTION_ADDING_EXPENSES);
                                  },
                              );
                      })
                    : console.log('No files');

                return result.id;
            },
            (error) => {
                const errorMessage = error.messageCode;
                alert(getErrorMessageKmDate(errorMessage));

                return 0;
            },
        );
    } else if (isAppTypeWorkingToolCat(path) && entityId > 0) {
        return GranitClient.addNewWorkingToolExpense(entityId, expense as WorkingToolExpenseDto).then(
            (result) => {
                dispatch(addExpense(result));
                selectedFiles !== undefined
                    ? selectedFiles.map((sf) => {
                          (fileToSend[0] = sf),
                              GranitClient.saveRecordDocument(
                                  result.id as number,
                                  'default',
                                  fileToSend,
                                  '{ "test": "ovo je test"}',
                              ).then(
                                  (res) => {
                                      dispatch(addExpenseDoc(result, res));
                                  },
                                  (error) => {
                                      console.log(error.message);
                                      alert(ExceptionsWithAdding.EXCEPTION_ADDING_EXPENSES);
                                  },
                              );
                      })
                    : console.log('No files');

                return result.id;
            },
            (error) => {
                const errorMessage = error.messageCode;
                alert(getErrorMessageRhDate(errorMessage));

                return 0;
            },
        );
    }
};

export const updateOldExpense = (
    expense: ExpenseEntityType,
    path: string,
    selectedFiles: Array<File>,
    category?: string,
) => (dispatch: (arg0: { payload: any; type: string }) => number) => {
    if (isAppTypeVehicle(path) || isAppTypeChart(path)) {
        return GranitClient.updateVehicleExpense(
            expense !== undefined ? (expense?.id as number) : 0,
            expense as VehicleExpenseDto,
        ).then(
            async (result) => {
                dispatch(updateExpense(result));
                selectedFiles !== undefined && selectedFiles.length > 0
                    ? selectedFiles.map((sf) => {
                          (fileToSend[0] = sf),
                              GranitClient.saveRecordDocument(
                                  result.id as number,
                                  category !== undefined ? category : 'default',
                                  fileToSend,
                                  '{ "test": "ovo je test"}',
                              ).then(
                                  (res) => {
                                      dispatch(addExpenseDoc(expense, res));
                                  },
                                  (error) => {
                                      console.log(error.message);
                                      alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_EXPENSES);
                                  },
                              );
                      })
                    : console.log('No files');

                return result.id;
            },
            (error) => {
                const errorMessage = error.messageCode;
                alert(getErrorMessageKmDate(errorMessage));

                return 0;
            },
        );
    } else if (isAppTypeWorkingToolCat(path)) {
        return GranitClient.updateWorkingToolExpense(
            expense !== undefined ? (expense?.id as number) : 0,
            expense as WorkingToolExpenseDto,
        ).then(
            async (result) => {
                dispatch(updateExpense(result));
                selectedFiles !== undefined && selectedFiles.length > 0
                    ? selectedFiles.map((sf) => {
                          (fileToSend[0] = sf),
                              GranitClient.saveRecordDocument(
                                  result.id as number,
                                  category !== undefined ? category : 'default',
                                  fileToSend,
                                  '{ "test": "ovo je test"}',
                              ).then(
                                  (res) => {
                                      dispatch(addExpenseDoc(expense, res));
                                  },
                                  (error) => {
                                      console.log(error.message);
                                      alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_EXPENSES);
                                  },
                              );
                      })
                    : console.log('No files');

                return result.id;
            },
            (error) => {
                const errorMessage = error.messageCode;
                alert(getErrorMessageRhDate(errorMessage));
                return 0;
            },
        );
    }
};

export const deleteVehicleExpense = (recId: number, oldRecord: ExpenseEntityType) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    if (oldRecord !== undefined) {
        GranitClient.deleteVehicleExpense(recId as number, oldRecord as VehicleExpenseDto).then(
            () => {
                dispatch(deleteExpense(oldRecord));
            },
            (error) => {
                console.log(error.message);
                alert(ExceptionsWithDeleting.EXCEPTION_DELETING_EXPENSES);
            },
        );
    }
};

export const deleteWorkingToolExpense = (recId: number, oldRecord: ExpenseEntityType) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    if (oldRecord !== undefined) {
        GranitClient.deleteWorkingToolExpense(recId as number, oldRecord as WorkingToolExpenseDto).then(
            () => {
                dispatch(deleteExpense(oldRecord));
            },
            (error) => {
                console.log(error.message);
                alert(ExceptionsWithDeleting.EXCEPTION_DELETING_EXPENSES);
            },
        );
    }
};
