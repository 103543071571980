import { AlarmDto } from '../../../AutoGeneratedAPI/clientApi';
import { createSlice } from '@reduxjs/toolkit';

interface IInitialStateType {
    alarms: AlarmDto[] | null;
}

const initialState: IInitialStateType = {
    alarms: null,
};

const alarmsSlice = createSlice({
    name: 'alarms',
    initialState,
    reducers: {
        addAlarms(state, action) {
            state.alarms = action.payload ?? [];
        },
        addAlarm(state, action) {
            state.alarms = (state.alarms ?? []).concat(action.payload);
        },
        updateAlarm: {
            reducer(state, action) {
                const { alarm, oldAlarm } = action.payload;
                const alarms = state.alarms ?? [];
                const alarmIndex = alarms.findIndex((a) => {
                    return a.id === oldAlarm.id;
                });
                state.alarms = [...alarms.slice(0, alarmIndex), alarm, ...alarms.slice(alarmIndex + 1)];
            },
            prepare(alarm, oldAlarm) {
                return {
                    payload: { alarm, oldAlarm },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
        removeAlarm(state, action) {
            const currAlarms = state.alarms ?? [];
            const aIndex = currAlarms.findIndex((a) => {
                return a.id === action.payload.id;
            });
            state.alarms = [...currAlarms.slice(0, aIndex), ...currAlarms.slice(aIndex + 1)];
        },
    },
});

export const { addAlarms, addAlarm, updateAlarm, removeAlarm } = alarmsSlice.actions;

export default alarmsSlice.reducer;
