import { RecordEntitiesType } from '../../../Extensions/Entities';
import { createSlice } from '@reduxjs/toolkit';

interface IInitialStateType {
    records: RecordEntitiesType[] | null;
}

const initialState: IInitialStateType = {
    records: null,
};

const recordsSlice = createSlice({
    name: 'records',
    initialState,
    reducers: {
        setInitialRecords(state, action) {
            state.records = action.payload ?? [];
        },
        concatRecords(state, action) {
            if (action.payload && action.payload.length > 0) {
                state.records = (state.records ?? []).concat(action.payload);
                const sortByKey = () => (a: RecordEntitiesType, b: RecordEntitiesType) =>
                    (a.validFrom ?? a.created ?? new Date()) < (b.validFrom ?? b.created ?? new Date()) ? 1 : -1;
                const sorted = state.records.slice().sort(sortByKey());
                state.records = sorted;
            }
        },
        addRecord(state, action) {
            if (action.payload) {
                const recIndex = (state.records ?? []).findIndex((r) => {
                    return (r.validFrom ?? new Date()) <= action.payload.validFrom;
                });
                if (recIndex >= 0) {
                    const firstHalf = (state.records ?? []).slice(0, recIndex);
                    const secondHalf = (state.records ?? []).slice(recIndex);
                    state.records = [...firstHalf, action.payload, ...secondHalf];
                } else {
                    state.records = (state.records ?? []).concat(action.payload);
                }
            }
        },
        updateRecord(state, action) {
            const records = state.records ?? [];
            const newRec = action.payload;
            const oldRec = records.find((r) => r.id === newRec.id);
            newRec.documents = oldRec?.documents;

            if (newRec.validFrom === oldRec?.validFrom) {
                const recIndex = records.findIndex((r) => {
                    return r.id === newRec.id;
                });
                state.records = [...records.slice(0, recIndex), newRec, ...records.slice(recIndex + 1)];
            } else {
                const recIndex = (state.records ?? []).findIndex((e) => {
                    return (e.validFrom ?? new Date()) <= newRec.validFrom;
                });
                const oldRecIndex = records.findIndex((r) => {
                    return r.id === newRec.id;
                });
                const recs = [...records.slice(0, oldRecIndex), ...records.slice(oldRecIndex + 1)];
                if (recIndex >= 0) {
                    const firstHalf = recs.slice(0, recIndex);
                    const secondHalf = recs.slice(recIndex);
                    state.records = [...firstHalf, newRec, ...secondHalf];
                } else {
                    state.records = recs.concat(newRec);
                }
            }
        },
        deleteRecord(state, action) {
            const records = state.records ?? [];
            const recIndex = records.findIndex((r) => {
                return r.id === action.payload.id;
            });
            if (recIndex >= 0) {
                state.records = [...records.slice(0, recIndex), ...records.slice(recIndex + 1)];
            }
        },
        deleteRecordDoc: {
            reducer(state, action) {
                const { record, recDocument } = action.payload;
                const records = state.records ?? [];
                const currRec = records.find((rec) => rec.id === record.id);
                const currRecIndex = records.findIndex((r) => {
                    return currRec ? r.id === currRec.id : record.id === r.id;
                });
                const recDocIndex = currRec?.documents?.findIndex((d) => {
                    return d.id === recDocument.id;
                });
                if (currRec?.documents !== undefined && recDocIndex !== undefined) {
                    const docs = [
                        ...currRec?.documents?.slice(0, recDocIndex),
                        ...currRec?.documents?.slice(recDocIndex + 1),
                    ];
                    currRec.documents = docs;
                }
                if (currRec) {
                    state.records = [...records.slice(0, currRecIndex), currRec, ...records.slice(currRecIndex + 1)];
                }
            },
            prepare(record, recDocument) {
                return {
                    payload: { record, recDocument },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
        addRecordDoc: {
            reducer(state, action) {
                const { record, recDocument } = action.payload;
                const records = state.records ?? [];
                const currRec = records.find((rec) => rec.id === record.id);
                const currRecIndex = records.findIndex((rec) => rec.id === record.id);
                if (recDocument && currRec) {
                    currRec.documents = (currRec?.documents ?? []).concat(recDocument);
                }
                if (currRec) {
                    state.records = [...records.slice(0, currRecIndex), currRec, ...records.slice(currRecIndex + 1)];
                }
            },
            prepare(record, recDocument) {
                return {
                    payload: { record, recDocument },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
    },
});

export const {
    setInitialRecords,
    addRecord,
    updateRecord,
    deleteRecord,
    deleteRecordDoc,
    addRecordDoc,
    concatRecords,
} = recordsSlice.actions;

export default recordsSlice.reducer;
