import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import '../TransportWarrantForm.css';
import { RootState } from '../../../Redux/store';
import VehicleCalendarElement from '../../../../Vehicles/VehiclesCalendar/VehicleCalendarElement';
import { useAppSelector } from '../../../hooks/hooks';
import { TrailerDto } from '../../../AutoGeneratedAPI/clientApi';

type TrailersFormProps = {
    handleTrailerAdding(trailer: TrailerDto): void;
    currentTrailers: number[];
};

export default function TrailersElement({ handleTrailerAdding, currentTrailers }: TrailersFormProps): ReactElement {
    const trailers = useAppSelector((state: RootState) => state.vehicles.trailers ?? []);

    const onClick = (trailer: TrailerDto): void => {
        handleTrailerAdding(trailer);
    };
    return (
        <div className="scrollable">
            <Grid container spacing={1} className="tw-grid-container" style={{ justifyContent: 'flex-start' }}>
                {trailers
                    .filter((tr) => !tr.isDeleted)
                    .map((trailer) => (
                        <Grid key={trailer.id} item xs={12} lg={6}>
                            <a onClick={() => onClick(trailer)}>
                                <div
                                    style={{
                                        width: 'inherit',
                                        height: 50,
                                        border: currentTrailers?.includes(trailer.id as number)
                                            ? '1px solid orange'
                                            : '1px solid lightgray',
                                        borderRadius: 5,
                                        cursor: 'pointer',
                                        padding: 4,
                                    }}
                                >
                                    <VehicleCalendarElement vehicle={trailer} />
                                </div>
                            </a>
                        </Grid>
                    ))}
            </Grid>
        </div>
    );
}
