/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RecordType } from '../../../../Extensions/Extensions';
import { RootState } from '../../../../Redux/store';
import ProfileCard from '../../../ProfileCards/ProfileCard';
import { ExpenseCategory, VehicleExpenseDto } from '../../../../AutoGeneratedAPI/clientApi';
import '../Feed.css';
import { useRef } from 'react';
import useExpensesSearch from '../../../../Extensions/Hooks/RecordsHooks/ExpensesSearchingHook';
import { useCallback } from 'react';
import { RecordSearchTokenModel } from '../../../../Extensions/models/RecordSearchTokenModel';
import EmptyPage from '../../../../EmptyPage';
import { Grid } from '@mui/material';
import '../../../TableView.css';
import { numberFormatter } from '../../../../../Common/Common';
import { LoaderProgress } from '../../../../../Common/CommonComponents/Loader/LoaderProgress';
import { ExpenseTypeSR } from '../../../../Extensions/Localizations/Types/Expenses/SR/ExpenseTypeSR';
import { GetIsoDateString } from '../../../../../Common/DateHandler';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function createData(id: number, title: string, expenseCategory: string, price: number | string, dateValid: string) {
    return {
        id,
        title,
        expenseCategory,
        price,
        dateValid,
    };
}

type ExpensesFeedElementProps = {
    query: RecordSearchTokenModel;
    expenseCategory?: ExpenseCategory;
    tableView: boolean;
    setExpenseId(expenseId: number): void;
};

export default function ExpensesFeedElement({
    query,
    expenseCategory,
    tableView,
    setExpenseId,
}: ExpensesFeedElementProps): ReactElement {
    const expenses = useSelector((state: RootState) =>
        state.expenses.expenses?.filter((e) => e.expenseType.expenseCategory !== ExpenseCategory.CalculatorExpense),
    );
    const entity = useSelector((state: RootState) => state.currentEntity.entity);

    const { loadingExpenses, hasMoreExpenses, expenseError } = useExpensesSearch(
        entity?.id ?? 0,
        query,
        expenseCategory,
    );

    const { expId } = useParams();

    const navigate = useNavigate();

    const location = useLocation();
    const path = location.pathname;

    const observer = useRef<IntersectionObserver>();
    const bottomRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (expId && !isNaN(+expId)) {
            if (!expenses?.find((e) => e.id === +expId)) {
                bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [expId, expenses]);

    const lastExpenseRef = useCallback(
        (node: any) => {
            if (loadingExpenses) {
                return;
            }

            if (observer.current) {
                observer.current.disconnect();
            }
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMoreExpenses) {
                    setExpenseId(expenses ? expenses[expenses.length - 1].id ?? 0 : 0);
                }
            });

            if (node) {
                observer.current.observe(node);
            }
        },
        [loadingExpenses, hasMoreExpenses],
    );

    const selectExpense = (expenseId: number): void => {
        if (isNaN(+path.substring(path.lastIndexOf('/') + 1))) {
            navigate(path + '/' + expenseId);
        } else {
            navigate(path.substring(0, path.lastIndexOf('/') + 1) + expenseId);
        }
    };

    const ViewElement = () => {
        if (!expenses || expenses.length === 0) {
            return <EmptyPage title="Nema troškova!" />;
        }
        return (
            <>
                <Grid container spacing={3}>
                    {expenses.map((expense, index) => {
                        if (expenses.length === index + 1) {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    xl={3}
                                    key={expense.id + (expense.title ?? '')}
                                    ref={lastExpenseRef}
                                >
                                    <div
                                        style={{ display: 'inline', width: 0, height: 0, margin: 0, padding: 0 }}
                                        ref={bottomRef}
                                    />
                                    <ProfileCard
                                        key={expense.title}
                                        record={expense}
                                        recordType={
                                            (expense as VehicleExpenseDto).price
                                                ? RecordType.Expense
                                                : RecordType.Record
                                        }
                                    />
                                </Grid>
                            );
                        } else {
                            return (
                                <Grid item xs={12} md={6} lg={4} xl={3} key={expense.id + (expense.title ?? '')}>
                                    <ProfileCard
                                        record={expense}
                                        recordType={
                                            (expense as VehicleExpenseDto).price
                                                ? RecordType.Expense
                                                : RecordType.Record
                                        }
                                    />
                                </Grid>
                            );
                        }
                    })}
                </Grid>
                <div>{loadingExpenses && 'Učitava se...'}</div>
                <div>{expenseError && 'Greška'}</div>
            </>
        );
    };

    const rows = expenses
        ? expenses.map((r) =>
              createData(
                  r.id ?? -1,
                  r.title ? (r.title !== '' ? r.title : 'Nema naslova') : 'Nema naslova',
                  (r as VehicleExpenseDto).expenseType.expenseCategory
                      ? ExpenseTypeSR[(r as VehicleExpenseDto).expenseType.expenseCategory].toString()
                      : 'Nema tipa troška',
                  (r as VehicleExpenseDto).price ?? 'Nema cene',
                  r.validFrom ? new Date(r.validFrom as Date).toLocaleDateString('en-UK') : 'Nema datum važenja',
              ),
          )
        : null;

    const TableView = () => {
        if (!rows || rows.length === 0 || !expenses || expenses.length === 0) {
            return <EmptyPage title="Nema troškova!" />;
        }
        return (
            <>
                <Grid container spacing={0}>
                    <Grid
                        item
                        xs={12}
                        className="feed-table-header-footer"
                        style={{
                            position: 'sticky',
                            top: 0,
                            paddingTop: 30,
                            borderRadius: '5px 5px 0px 0px',
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={5}>
                                Naziv
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: 'end' }}>
                                Tip troška
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: 'end' }}>
                                Cena
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: 'end' }}>
                                Datum važenja
                            </Grid>
                        </Grid>
                    </Grid>
                    {rows.map((row, index) => {
                        if (rows.length === index + 1) {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    key={index + row.title}
                                    ref={lastExpenseRef}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        selectExpense(row.id);
                                    }}
                                    className="feed-table-element"
                                >
                                    <div
                                        style={{ display: 'inline', width: 0, height: 0, margin: 0, padding: 0 }}
                                        ref={bottomRef}
                                    />
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>
                                            {row.title}
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'end' }}>
                                            {row.expenseCategory}
                                        </Grid>
                                        <Grid item xs={2} style={{ textAlign: 'end' }}>
                                            {numberFormatter.format(+row.price)}
                                        </Grid>
                                        <Grid item xs={2} style={{ textAlign: 'end' }}>
                                            {row.dateValid}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        } else {
                            return (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        key={index + row.title}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            selectExpense(row.id);
                                        }}
                                        className="feed-table-element"
                                    >
                                        <Grid container spacing={1}>
                                            <Grid item xs={5}>
                                                {row.title}
                                            </Grid>
                                            <Grid item xs={3} style={{ textAlign: 'end' }}>
                                                {row.expenseCategory}
                                            </Grid>
                                            <Grid item xs={2} style={{ textAlign: 'end' }}>
                                                {numberFormatter.format(+row.price)}
                                            </Grid>
                                            <Grid item xs={2} style={{ textAlign: 'end' }}>
                                                {row.dateValid}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            );
                        }
                    })}
                </Grid>
                <hr></hr>
                <Grid
                    container
                    className="feed-table-header-footer"
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        borderRadius: '0px 0px 5px 5px',
                    }}
                >
                    <div style={{ margin: 'auto', float: 'left', marginLeft: 20 }}>
                        {GetIsoDateString((expenses as VehicleExpenseDto[])[0].validFrom)} -{' '}
                        {GetIsoDateString(
                            (expenses as VehicleExpenseDto[])[(expenses as VehicleExpenseDto[]).length - 1].validFrom,
                        )}
                    </div>
                    <div style={{ margin: 'auto', float: 'right', marginRight: 20 }}>
                        Suma:{' '}
                        {numberFormatter.format(
                            (expenses as VehicleExpenseDto[]).reduce((accumulator, object) => {
                                return accumulator + object.price;
                            }, 0),
                        )}
                    </div>
                </Grid>
            </>
        );
    };

    if (!loadingExpenses && !hasMoreExpenses && (!expenses || expenses.length <= 0)) {
        return <EmptyPage title="Nema troškova!" />;
    }
    if (loadingExpenses && (!expenses || expenses.length <= 0)) {
        return (
            <div style={{ height: '60vh', width: '60vh', margin: 'auto' }}>
                <LoaderProgress />
            </div>
        );
    }
    if (!tableView) {
        return <ViewElement />;
    }
    return <TableView />;
}
