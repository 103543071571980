import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    clearConstructionSites,
    concatConstrucionSites,
    concatProjects,
    setInitialProjects,
} from '../../../Redux/Reducers/ConstructionProject/ConstructionProjectReducer';
import { ProjectsSearchToken } from '../../models/RecordSearchTokenModel';
import { fetchConstructionSites, fetchProjects } from './API';

type ProjectsSearchType = {
    loading: boolean;
    error: boolean;
    hasMore: boolean;
};

export default function useProjectsSearch(entityId: number, query: ProjectsSearchToken): ProjectsSearchType {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        setError(false);
        fetchProjects(entityId, query).then(
            (res) => {
                if (query.lastEntityId && query.lastEntityId > 0) {
                    dispatch(concatProjects(res));
                } else {
                    dispatch(setInitialProjects(res));
                    dispatch(clearConstructionSites());
                }
                res.forEach((proj) =>
                    fetchConstructionSites(proj.id ?? 0).then((result) => {
                        if (query.lastEntityId && query.lastEntityId > 0) {
                            dispatch(concatConstrucionSites(result));
                        } else {
                            dispatch(concatConstrucionSites(result));
                        }
                    }),
                );
                setHasMore(res.length > 0);
                setLoading(false);
            },
            (error) => {
                setError(true);
                console.log(error.message);
            },
        );
    }, [query]);

    useEffect(() => {
        dispatch(clearConstructionSites());
        setInitialProjects([]);
    }, [query.numberOfEntitiesToReturn, query.searchText]);

    return { loading, error, hasMore };
}
