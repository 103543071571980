import { Button, Grid } from '@mui/material';
import React, { ChangeEvent, ReactElement } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import FormTextField from '../../Common/FormTextField';
import '../ConstructionProject.css';
import GoogleMaps from '../../../../Common/CommonComponents/GoogleMaps/GoogleMaps';
import { ConstructionSiteDto } from '../../../AutoGeneratedAPI/clientApi';
import { useAppSelector } from '../../../hooks/hooks';
import { RootState } from '../../../Redux/store';
import { LatLngLiteral } from '../../../../Common/Common';

type ConstructionSiteFormProps = {
    constrSite?: ConstructionSiteDto;
    constructionSites: ConstructionSiteDto[];
    handleNameChange(event: ChangeEvent<HTMLInputElement>): void;
    handleLocationChange(address: string, coordinates: LatLngLiteral): void;
    handleAddingSiteToArray(): void;
    handleRemovingSiteFromArray(index: number): void;
};

export default function ConstructionSiteForm({
    constrSite,
    constructionSites,
    handleNameChange,
    handleLocationChange,
    handleAddingSiteToArray,
    handleRemovingSiteFromArray,
}: ConstructionSiteFormProps): ReactElement {
    const googleMapsApiKey = useAppSelector((state: RootState) => state.loggedEmployee.googleApiKey);

    const sitesList = (): ReactElement => {
        if (!constructionSites || constructionSites.length <= 0) {
            return <></>;
        }
        return (
            <div className="constrsite-scrollable">
                {constructionSites.map((cs) => {
                    const index = constructionSites.findIndex((c) => {
                        return c.name === cs.name;
                    });
                    return (
                        <Grid key={index} item xs={12} style={{ marginBottom: 20 }}>
                            <span
                                style={{ marginRight: 20, display: 'inline', cursor: 'pointer' }}
                                aria-label="edit"
                                onClick={() => handleRemovingSiteFromArray(index)}
                            >
                                <DeleteIcon style={{ fontSize: 20, color: '#F11419' }} />
                            </span>
                            <p style={{ display: 'inline', fontSize: 20 }}>{cs.name}</p>
                        </Grid>
                    );
                })}
            </div>
        );
    };

    const isRequiredEmpty = (): boolean => {
        return constrSite?.locationInfo?.address && constrSite?.name ? false : true;
    };

    return (
        <Grid container spacing={5}>
            <FormTextField
                defaultValue={constrSite?.name ?? ''}
                required={true}
                label="Naziv gradilišta"
                entityName="name"
                errorCondition={constrSite?.name ? false : true}
                onChange={handleNameChange}
            />
            {googleMapsApiKey && googleMapsApiKey !== '' && (
                <Grid item xs={12}>
                    <GoogleMaps
                        handleAddingAddress={handleLocationChange}
                        label="Unesite adresu gradilišta"
                        apiKey={googleMapsApiKey}
                    />
                </Grid>
            )}
            <Grid item xs={11}>
                <Button
                    style={{ float: 'right' }}
                    onClick={handleAddingSiteToArray}
                    disabled={isRequiredEmpty()}
                    variant="contained"
                    color="primary"
                >
                    Dodaj gradilište
                </Button>
            </Grid>

            <Grid item xs={11}>
                <p style={{ marginLeft: 20, fontSize: 18 }}>Dodata gradilišta:</p>
            </Grid>
            {sitesList()}
        </Grid>
    );
}
