import { addMinutes } from 'date-fns';
import { StopDto, ShipmentDto } from '../../../AutoGeneratedAPI/clientApi';
import { DistanceDuration, LatLngLiteral, warehouseAddress } from '../../../../Common/Common';
import { getTimeDifference } from '../../../../Common/DateHandler';
import cloneDeep from 'lodash.clonedeep';

/**
 * Alters endStop ending time based on the difference between startStop and endStop points on the map
 * @param startStop origin stop
 * @param endStop destination stop
 * @returns stop with altered ending time
 */
const changeEndingTimeForStop = (startStop: StopDto, endStop: StopDto, duration: number): StopDto => {
    const deepClonedSStop = cloneDeep(startStop);
    const deepClonedEStop = cloneDeep(endStop);

    const timeDiff = Math.round(duration / 60);

    const shipStartingStopEndingTime = new Date(deepClonedSStop.endingTime);

    const eStopDurationTimeSpan = getTimeDifference(deepClonedEStop?.endingTime, deepClonedEStop?.startingTime);
    const eStopDurationInMinutes = eStopDurationTimeSpan.hours * 60 + eStopDurationTimeSpan.minutes;

    deepClonedEStop.startingTime = addMinutes(shipStartingStopEndingTime, timeDiff);

    deepClonedEStop.endingTime = addMinutes(shipStartingStopEndingTime, timeDiff + eStopDurationInMinutes);

    return deepClonedEStop;
};

/**
 * Changes time of shipments ending stop based on the starting stop and the difference between starting and ending stop,
 * or ending stop of previous shipment and ending stop of current shipment
 * @param shipments array of shipments (sorted descending by endingStop.startingTime) to rearrange
 * @returns array of shipments
 */
export const rearrangeShipmentsTimes = (
    shipments: ShipmentDto[],
    twStartingTime: Date,
    durations: DistanceDuration[],
): ShipmentDto[] => {
    const deepClonedShipments = cloneDeep(shipments);

    const newShips = deepClonedShipments.map((ship, index) => {
        const dur = durations.find((d) => d.index === index)?.value ?? 0;

        if (index === 0) {
            const newStartStop = ship.startingStop;

            const startStopTimeSpan = getTimeDifference(newStartStop.endingTime, newStartStop.startingTime);
            const startStopTimeDiffInMinutes = startStopTimeSpan.hours * 60 + startStopTimeSpan.minutes;

            const newStartStopStartingTime = addMinutes(twStartingTime, 0 - startStopTimeDiffInMinutes);

            newStartStop.startingTime = newStartStopStartingTime;
            newStartStop.endingTime = twStartingTime;

            ship.endingStop = changeEndingTimeForStop(newStartStop, ship.endingStop, dur);

            return ship;
        }

        ship.endingStop = changeEndingTimeForStop(deepClonedShipments[index - 1].endingStop, ship.endingStop, dur);

        return ship;
    });

    return newShips;
};

export const rearrangeStopsTimes = (
    stops: StopDto[],
    twStartingTime: Date,
    durations: DistanceDuration[],
): StopDto[] => {
    const deepClonedStops = cloneDeep(stops);
    const newStops: StopDto[] = [];

    deepClonedStops.map((stop, index) => {
        const dur = durations.find((d) => d.index === index)?.value ?? 0;

        if (index === 0) {
            const newStartStop = new StopDto({ ...new StopDto(), endingTime: twStartingTime });

            const newEndingStop = changeEndingTimeForStop(newStartStop, stop, dur);

            newStops.push(newEndingStop);
        } else {
            const nStop = changeEndingTimeForStop(newStops[index - 1], stop, dur);

            newStops.push(nStop);
        }
    });

    return newStops;
};

/**
 * Gets shipments locations and converts them into an array
 * @param shipments shipment array to extract locations from
 * @returns locations of shipment ending stops
 */
export const getLatLngArrayFromShipments = (shipments: ShipmentDto[]): LatLngLiteral[] => {
    const deepClonedShipments = cloneDeep(shipments);

    const latLngs = deepClonedShipments.slice().map((ship) => {
        const coords = ship?.endingStop?.locationInfo?.coordinates;
        if (coords) {
            const lat = +coords.lat;
            const lng = +coords.lng;
            if (!isNaN(lat) && !isNaN(lng)) {
                return { lat, lng };
            }
            return { lat: 0, lng: 0 };
        }
        return { lat: 0, lng: 0 };
    });
    const shipStartingStopCoords = deepClonedShipments[0]?.startingStop?.locationInfo?.coordinates;

    if (shipStartingStopCoords) {
        const lat = +shipStartingStopCoords.lat;
        const lng = +shipStartingStopCoords.lng;
        if (!isNaN(lat) && !isNaN(lng)) {
            latLngs.unshift({ lat, lng });
            latLngs.push({ lat, lng });
        } else {
            latLngs.unshift({ lat: 0, lng: 0 });
            latLngs.push({ lat: 0, lng: 0 });
        }
    }

    return latLngs;
};

export const getLatLngArrayFromStops = (stops: StopDto[]): LatLngLiteral[] => {
    const deepClonedStops = cloneDeep(stops);

    const latLngs = deepClonedStops.slice().map((stop) => {
        const coords = stop?.locationInfo?.coordinates;
        if (coords) {
            const lat = +coords.lat;
            const lng = +coords.lng;
            if (!isNaN(lat) && !isNaN(lng)) {
                return { lat, lng };
            }
            return { lat: 0, lng: 0 };
        }
        return { lat: 0, lng: 0 };
    });
    const startingStopCoords = warehouseAddress.coordinates;

    if (startingStopCoords) {
        const lat = +startingStopCoords.lat;
        const lng = +startingStopCoords.lng;
        if (!isNaN(lat) && !isNaN(lng)) {
            latLngs.unshift({ lat, lng });
            latLngs.push({ lat, lng });
        } else {
            latLngs.unshift({ lat: 0, lng: 0 });
            latLngs.push({ lat: 0, lng: 0 });
        }
    }

    return latLngs;
};

export const rearrangeStopsByScheme = (stops: StopDto[], scheme: number[]): StopDto[] => {
    if (stops.length !== scheme.length) {
        return [];
    }

    const deepClonedStops = cloneDeep(stops);

    const diffElement = deepClonedStops.find((sh, index) => {
        return sh.id !== scheme[index];
    });

    // if the arrangement is already set, don't do anything
    if (!diffElement) {
        return deepClonedStops;
    }

    const updatedArray = scheme.map(
        (schemeId) => deepClonedStops.find((ship) => ship.id === schemeId) ?? new StopDto(),
    );

    return updatedArray;
};
