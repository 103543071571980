import { Grid } from '@mui/material';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { useEffect } from 'react';
import { NameDataPair } from '../../Extensions/Extensions';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import FormDataReview from '../Common/FormDataReview';
import FormTemplate from '../Common/FormTemplate';
import FormTextField from '../Common/FormTextField';
import GoogleMaps from '../../../Common/CommonComponents/GoogleMaps/GoogleMaps';
import { ConstructionSiteDto, IConstructionSiteDto } from '../../AutoGeneratedAPI/clientApi';
import { useAppSelector } from '../../hooks/hooks';
import { RootState } from '../../Redux/store';
import { LatLngLiteral } from '../../../Common/Common';

const steps = ['Gradilište', 'Pregled unetih informacija'];

type UpdateConstructionSiteFormProps = {
    closeForm(): void;
    updateSite(constrSite: ConstructionSiteDto): void;
    constrSiteId: number;
};

export default function UpdateConstructionSiteForm({
    closeForm,
    updateSite,
    constrSiteId,
}: UpdateConstructionSiteFormProps): ReactElement {
    const [activeStep, setActiveStep] = useState(0);
    const [constrSite, setConstrSite] = useState<IConstructionSiteDto>(new ConstructionSiteDto());

    const googleMapsApiKey = useAppSelector((state: RootState) => state.loggedEmployee.googleApiKey);

    useEffect(() => {
        GranitClient.getConstructionSite(constrSiteId ?? 0, false).then((res) => setConstrSite(res));
    }, []);

    const handleConstructionSiteNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setConstrSite({ ...constrSite, name: event.target.value });
    };

    const handleConstructionSiteLocationChange = (address: string, coordinates: LatLngLiteral): void => {
        setConstrSite({ ...constrSite, locationInfo: { address: address, coordinates: coordinates } });
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const onSubmit = (): void => {
        const conSite = constrSite;
        updateSite(new ConstructionSiteDto(conSite));
        closeForm();
    };

    const getStepContent = () => {
        const displayText: NameDataPair[] = [
            { name: 'Naziv gradilišta:', data: constrSite.name },
            { name: 'Adresa:', data: constrSite.locationInfo?.address },
        ];
        switch (activeStep) {
            case 0:
                return (
                    <Grid container spacing={5}>
                        <FormTextField
                            defaultValue={constrSite?.name ?? ''}
                            required={true}
                            label="Naziv gradilišta"
                            entityName="name"
                            errorCondition={constrSite?.name ? false : true}
                            onChange={handleConstructionSiteNameChange}
                        />
                        {googleMapsApiKey && googleMapsApiKey !== '' && (
                            <Grid item xs={12}>
                                <GoogleMaps
                                    handleAddingAddress={handleConstructionSiteLocationChange}
                                    label="Unesite adresu gradilišta"
                                    currLocation={constrSite.locationInfo ?? {}}
                                    apiKey={googleMapsApiKey}
                                />
                            </Grid>
                        )}
                    </Grid>
                );
            case 1:
                return <FormDataReview displayText={displayText} />;
            default:
                throw new Error('Unknown step');
        }
    };

    const formElem = getStepContent();

    const shouldDisable = constrSite.name && constrSite.locationInfo?.address ? false : true;

    return (
        <>
            <FormTemplate
                activeStep={activeStep}
                close={closeForm}
                formContent={formElem}
                title={'Projekti / Gradilišta'}
                shouldDisable={shouldDisable}
                submit={onSubmit}
                steps={steps}
                handleNext={handleNext}
                handleBack={handleBack}
            />
        </>
    );
}
