import { Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import {
    ShipmentDto,
    TrailerDto,
    TransportWarrantDto,
    TransportWarrantItemDto,
    WorkingMachineDto,
} from '../General/AutoGeneratedAPI/clientApi';
import { GraphUserDto } from '../General/AutoGeneratedAPI/MSGraph/msGraphApi';
import './Report.css';

type TWReportProps = {
    tw: TransportWarrantDto;
    vehicle: string;
    trailers: TrailerDto[];
    workingMachines: WorkingMachineDto[];
    graphDrivers: GraphUserDto[];
    twItems: TransportWarrantItemDto[];
    shipments: ShipmentDto[];
};

export class TWReportContent extends React.PureComponent<TWReportProps> {
    constructor(props: TWReportProps) {
        super(props);
    }

    divEl!: HTMLDivElement;

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setRef = (ref: HTMLDivElement) => (this.divEl = ref);

    startTime = this.props.tw.estStartingTime;
    endTime = this.props.tw.estEndingTime;

    startDateText = new Date(this.startTime as Date).toLocaleDateString('en-UK');

    endDateText = new Date(this.endTime as Date).toLocaleDateString('en-UK');

    startTimeText =
        (new Date(this.startTime as Date).getHours() >= 10
            ? new Date(this.startTime as Date).getHours()
            : '0' + new Date(this.startTime as Date).getHours()) +
        ':' +
        (new Date(this.startTime as Date).getMinutes() >= 10
            ? new Date(this.startTime as Date).getMinutes()
            : '0' + new Date(this.startTime as Date).getMinutes());

    endTimeText =
        (new Date(this.endTime as Date).getHours() >= 10
            ? new Date(this.endTime as Date).getHours()
            : '0' + new Date(this.endTime as Date).getHours()) +
        ':' +
        (new Date(this.endTime as Date).getMinutes() >= 10
            ? new Date(this.endTime as Date).getMinutes()
            : '0' + new Date(this.endTime as Date).getMinutes());

    getTextPattern(name: string, info: string): ReactElement {
        return (
            <div>
                <Typography variant="body2" component="p" style={{ marginBottom: '2px' }}>
                    {name} <h5 className="tw-report-info">{info}</h5>
                </Typography>
            </div>
        );
    }

    getTextPatternWithLine(name: string): ReactElement {
        return (
            <div>
                <Typography variant="body2" component="p" style={{ marginBottom: '2px', display: 'inline-block' }}>
                    {name}
                </Typography>
                <span
                    style={{
                        width: '33%',
                        height: 0,
                        marginLeft: 5,
                        display: 'inline-block',
                        borderBottom: '1px solid black',
                    }}
                ></span>
            </div>
        );
    }

    getTextPatternWithLine2(name: string, name2: string): ReactElement {
        return (
            <div style={{ marginLeft: 15 }}>
                <span>-</span>
                <Typography variant="body2" component="p" style={{ marginBottom: '2px', display: 'inline-block' }}>
                    {name}
                </Typography>
                <span
                    style={{
                        width: '20%',
                        height: 15,
                        marginLeft: 5,
                        display: 'inline-block',
                        borderBottom: '1px solid black',
                    }}
                ></span>
                <Typography variant="body2" component="p" style={{ marginBottom: '2px', display: 'inline-block' }}>
                    {name2}
                </Typography>
                <span
                    style={{
                        width: '20%',
                        height: 15,
                        marginLeft: 5,
                        display: 'inline-block',
                        borderBottom: '1px solid black',
                    }}
                ></span>
            </div>
        );
    }

    getTextPattern2(name: string, info: string, info2: string): ReactElement {
        return (
            <>
                <Typography variant="h6" component="h5">
                    {name} <h5 className="tw-report-info">{info}</h5>-<h5 className="tw-report-info">{info2}</h5>
                </Typography>
            </>
        );
    }

    makeSignatureElement(): ReactElement {
        return (
            <>
                <span
                    style={{
                        width: '100%',
                        height: 0,
                        display: 'inline-block',
                        borderBottom: '1px solid black',
                    }}
                ></span>

                <Typography variant="caption" component="p" style={{ marginTop: '5px', textAlign: 'center' }}>
                    potpis kupca
                </Typography>
            </>
        );
    }

    makeSignatureStampElement(): ReactElement {
        return (
            <div style={{ width: '100%' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        width: '100%',
                    }}
                >
                    <div style={{ width: '33%' }}>{this.makeSignatureElement()}</div>
                </div>
            </div>
        );
    }

    pageTitle(): ReactElement {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        margin: 0,
                    }}
                >
                    <Typography
                        variant="h2"
                        style={{ display: 'inline', margin: '0px', color: 'black', fontWeight: 'bold' }}
                    >
                        GRANIT
                    </Typography>
                    <div style={{ display: 'inline' }}>
                        <Typography variant="h6" style={{ textAlign: 'end', margin: '0px', color: 'black' }}>
                            Društvo za proizvodnju, trgovinu i usluge
                            <br />
                            Granit d.o.o.
                            <br />
                            34000 Kragujevac; ul. Mlavska bb
                            <br />
                            Matični broj: 07647115; Šifra delatnosti: 2593;
                            <br />
                            PIB: 100562816
                        </Typography>
                    </div>
                </div>
                <hr />
            </>
        );
    }

    numberOfPages(): number {
        const pageNum = Math.floor((this.props.twItems.length - 1) / 2) + ((this.props.twItems.length - 1) % 2) + 1;

        return pageNum > 0 ? pageNum : 1;
    }

    titlePage = (): ReactElement => {
        return (
            <div className="tw-report-content first-page-content">
                {this.pageTitle()}
                {this.props.graphDrivers.length > 0 ? (
                    this.props.graphDrivers.map((dr) => this.getTextPattern('Vozač:', dr.displayName ?? ''))
                ) : (
                    <></>
                )}
                {this.getTextPattern('Vozilo:', this.props.vehicle)}
                {this.props.trailers.length > 0 ? (
                    this.props.trailers.map((tr) => this.getTextPattern('Prikolica:', tr.manufacturer + ' ' + tr.model))
                ) : (
                    <></>
                )}
                {this.props.workingMachines.length > 0 ? (
                    this.props.workingMachines.map((wm) =>
                        this.getTextPattern('Radna mašina:', wm.nickname ?? wm.manufacturer + ' ' + wm.model),
                    )
                ) : (
                    <></>
                )}

                <div style={{ height: 30 }}></div>
                {this.getTextPattern('Datum:', this.startDateText ?? '')}
                <hr />
                {this.props.twItems.length > 0 ? (
                    <div>
                        <Typography
                            variant="h6"
                            component="h6"
                            style={{ textAlign: 'center', marginTop: '4%', marginBottom: '20px' }}
                        >
                            NALOG ZA TRANSPORT ROBE
                        </Typography>
                        {this.twItemPage(this.props.twItems[0])}
                        {this.props.twItems.length - 2 <= 0 ? this.lastPage() : <></>}
                    </div>
                ) : (
                    <></>
                )}

                <footer
                    style={{
                        position: 'absolute',
                        bottom: 20,
                        left: 0,
                        width: '100%',
                        textAlign: 'center',
                        fontSize: '2vw',
                    }}
                >
                    1/{this.numberOfPages()}
                </footer>
            </div>
        );
    };

    twItemPage = (twItem: TransportWarrantItemDto): ReactElement => {
        const shipment = this.props.shipments.find((ship) => ship.id === twItem.shipmentId);
        const clientName = shipment?.data?.clientName;
        const address = shipment?.endingStop?.locationInfo?.address;
        const contactPerson = shipment?.data?.clientContact;
        return (
            <div key={twItem.id}>
                {this.getTextPattern('Kupac:', clientName ?? '')}
                {this.getTextPattern('Br. računa:', shipment?.data?.invoiceCode ?? '')}
                {this.getTextPattern('Adresa:', address ?? '')}
                {this.getTextPattern('Kontakt za prijem robe:', contactPerson ?? '')}
                {this.getTextPatternWithLine('Vreme isporuke:')}
                {this.getTextPatternWithLine2('predv. vreme utovara:', '/stvarno vreme utovara:')}
                {this.getTextPatternWithLine2('predv. vreme polaska:', '/stvarno vreme polaska:')}
                {this.getTextPatternWithLine2('predv. vreme dolaska na adresu:', '/stvarno vreme:')}
                {this.getTextPatternWithLine2('predv. vreme istovara:', '/stvarno vreme istovara:')}
                <div style={{ height: 15 }}></div>
                {this.getTextPatternWithLine('Broj paleta/t:')}
                <div style={{ height: 15 }}></div>
                {this.getTextPatternWithLine('Napomena:')}
                <div style={{ height: 15 }}></div>
                {this.getTextPatternWithLine2('Predviđena kilometraža:', '/stvarna kilometraža:')}
                <div
                    style={{
                        width: '100%',
                        marginTop: 50,
                    }}
                >
                    {this.makeSignatureStampElement()}
                </div>
            </div>
        );
    };

    twItemPages = (): ReactElement => {
        const len = this.props.twItems.length;

        return len > 0 ? (
            <>
                {this.props.twItems.map((twItem, index) => {
                    if (index % 2 == 1) {
                        return (
                            <div className="tw-report-page" key={twItem.id}>
                                <Typography
                                    variant="h6"
                                    component="h6"
                                    style={{ textAlign: 'center', marginTop: '4%', marginBottom: '20px' }}
                                >
                                    NALOG ZA TRANSPORT ROBE
                                </Typography>
                                {this.twItemPage(this.props.twItems[index])}
                                {this.props.twItems[index + 1] ? this.twItemPage(this.props.twItems[index + 1]) : <></>}
                                {index >= len - 2 ? this.lastPage() : <></>}

                                <footer
                                    style={{
                                        position: 'absolute',
                                        bottom: 20,
                                        left: 0,
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: '2vw',
                                    }}
                                >
                                    {Math.floor(index / 2) + 2}/{this.numberOfPages()}
                                </footer>
                            </div>
                        );
                    }
                })}
            </>
        ) : (
            <></>
        );
    };

    lastPage = (): ReactElement => {
        return (
            <>
                <div style={{ height: 15 }}></div>
                <hr />
                <Typography variant="body1" component="p" style={{ marginBottom: '0px', fontWeight: 'bold' }}>
                    <p style={{ marginBottom: '0px' }}>Završena isporuka</p>
                </Typography>
                <div style={{ height: 15 }}></div>
                {this.getTextPatternWithLine2('Predv. vreme vracanja na stovariste:', '/stvarno vreme:')}
            </>
        );
    };

    render(): ReactElement {
        return this.props.tw ? (
            <div ref={this.setRef} className="tw-report-content-container">
                {this.titlePage()}
                {this.twItemPages()}
            </div>
        ) : (
            <></>
        );
    }
}
