import { WorkingMachineDto } from '../../../AutoGeneratedAPI/clientApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithFetching } from '../../../Extensions/Exceptions';

interface IInitialStateType {
    handMachines: WorkingMachineDto[] | null;
}

const initialState: IInitialStateType = {
    handMachines: null,
};

export const fetchInitialHandMachines = createAsyncThunk(
    'handMachines/fetchInitialHandMachines',
    async () => {
        const response = await GranitClient.getAllHandMachines();
        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingHMachines: WorkingMachineDto[] | null = thunkAPI.getState().handMachines.handMachines;

            return existingHMachines === null;
        },
    },
);

const handMachinesSlice = createSlice({
    name: 'handMachines',
    initialState,
    reducers: {
        addHandMachine(state, action) {
            state.handMachines = [action.payload, ...(state.handMachines ?? [])];
        },
        updateHandMachine: {
            reducer(state, action) {
                const { handMachine, oldHandMachine } = action.payload;
                const handMachines = state.handMachines ?? [];
                const handMachineIndex = handMachines.findIndex((hm) => {
                    return hm.id === oldHandMachine.id;
                });
                state.handMachines = [
                    ...handMachines.slice(0, handMachineIndex),
                    handMachine,
                    ...handMachines.slice(handMachineIndex + 1),
                ];
            },
            prepare(handMachine, oldHandMachine) {
                return {
                    payload: { handMachine, oldHandMachine },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
        changeHandMachineProfileImage: {
            reducer(state, action) {
                const hmIndex = state.handMachines?.findIndex((hm) => {
                    return hm.id === action.payload.workingToolId;
                });
                if (state.handMachines && hmIndex !== undefined && hmIndex >= 0) {
                    const hm = state.handMachines[hmIndex];
                    hm.documents = [action.payload.doc, ...(hm.documents?.slice(1) ?? [])];
                    state.handMachines = [
                        ...state.handMachines.slice(0, hmIndex),
                        hm,
                        ...state.handMachines.slice(hmIndex + 1),
                    ];
                }
            },
            prepare(workingToolId, doc) {
                return {
                    payload: { workingToolId, doc },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInitialHandMachines.fulfilled, (state, action) => {
            state.handMachines = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialHandMachines.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_HANDMACHINES);
        });
    },
});

export const { addHandMachine, updateHandMachine, changeHandMachineProfileImage } = handMachinesSlice.actions;

export default handMachinesSlice.reducer;
