import { Grid, TextField, Stack } from '@mui/material';
import React, { ChangeEvent, ReactElement } from 'react';
import FormTextField from '../../Common/FormTextField';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import { InvoiceDto } from '../../../AutoGeneratedAPI/clientApi';

type InvoiceInfoFormProps = {
    invoice: InvoiceDto;
    handleCodeChange(event: ChangeEvent<HTMLInputElement>): void;
    handleDescriptionChange(event: ChangeEvent<HTMLInputElement>): void;
    handleCreationDateChange(date?: Date): void;
};

export default function InvoiceInfoForm({
    invoice,
    handleCodeChange,
    handleDescriptionChange,
    handleCreationDateChange,
}: InvoiceInfoFormProps): ReactElement {
    return (
        <Grid container spacing={5}>
            <FormTextField
                defaultValue={invoice.code ?? ''}
                required={true}
                label="Kod računa"
                entityName="code"
                errorCondition={invoice.code ? false : true}
                onChange={handleCodeChange}
            />
            <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                    <Stack spacing={3}>
                        <DesktopDatePicker
                            label="Datum izdavanja"
                            disableMaskedInput
                            value={invoice.creationDate}
                            inputFormat="DD/MM/YYYY"
                            minDate={dayjs('1940-01-01')}
                            onChange={(newValue) => {
                                handleCreationDateChange(newValue?.toDate());
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Stack>
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="filled-basic"
                    label="Opis"
                    variant="standard"
                    multiline
                    rows={6}
                    value={invoice.description ?? ''}
                    name="description"
                    onChange={handleDescriptionChange}
                />
            </Grid>
        </Grid>
    );
}
