import React, { ReactElement } from 'react';
import './Details.css';
import { Grid } from '@mui/material';
import { EntitiesType } from '../../Extensions/Entities';
import { WorkingToolDto, WorkingMachineDto, MachineType, EngineType } from '../../AutoGeneratedAPI/clientApi';
import { EngineTypeSR } from '../../Extensions/Localizations/Types/Vehicles/SR/EngineTypeSR';
import { MachineTypeSR } from '../../Extensions/Localizations/Types/Vehicles/WorkingMachines/SR/MachineTypeSR';
import { isAppTypeWorkingTool, isAppTypeWorkingToolCat } from '../../Extensions/Selectors/Selectors';
import { useLocation } from 'react-router-dom';

type WorkingToolsDetailsProps = {
    entity: EntitiesType | null;
};

export default function WorkingToolsDetails({ entity }: WorkingToolsDetailsProps): ReactElement {
    const location = useLocation();
    const path = location.pathname;

    const isWorkingMachine = isAppTypeWorkingToolCat(path) && !isAppTypeWorkingTool(path);

    return (
        <Grid container style={{ justifyContent: 'space-evenly' }} spacing={1}>
            <Grid key={0} item xs={12}>
                <p key={11} className="naming">
                    Proizvođač:
                </p>
                <p key={12} className="info">
                    {' '}
                    {entity !== null ? (entity as WorkingToolDto).manufacturer : ''}
                </p>
            </Grid>
            <Grid key={1} item xs={12}>
                <p key={21} className="naming">
                    Model:
                </p>
                <p key={22} className="info">
                    {' '}
                    {entity !== null ? (entity as WorkingToolDto).model : ''}
                </p>
            </Grid>
            <Grid key={3} item xs={12}>
                <p key={41} className="naming">
                    Naziv:
                </p>
                <p key={42} className="info">
                    {' '}
                    {entity !== null ? (entity as WorkingToolDto).nickname : ''}
                </p>
            </Grid>
            <Grid key={4} item xs={12}>
                <p key={23} className="naming">
                    Godiste:
                </p>
                <p key={24} className="info">
                    {' '}
                    {new Date((entity as WorkingToolDto).productionDate as Date).getFullYear()}
                </p>
            </Grid>
            <Grid key={5} item xs={12}>
                <p key={41} className="naming">
                    Tip:
                </p>
                <p key={42} className="info">
                    {' '}
                    {MachineTypeSR[(entity as WorkingToolDto).machineType ?? MachineType.WorkingMachine]}
                </p>
            </Grid>
            {entity !== null ? (
                (entity as WorkingToolDto).chassisNumber !== undefined &&
                (entity as WorkingToolDto).chassisNumber !== '' ? (
                    <Grid key={2} item xs={12}>
                        <p key={31} className="naming">
                            Broj šasije:
                        </p>
                        <p key={32} className="info">
                            {' '}
                            {entity !== null ? (entity as WorkingToolDto).chassisNumber : ''}
                        </p>
                    </Grid>
                ) : (
                    <></>
                )
            ) : (
                <></>
            )}
            {isWorkingMachine ? (
                <Grid item xs={12} key={150}>
                    <p key={61} className="naming">
                        Tip goriva:
                    </p>
                    <p key={62} className="info">
                        {' '}
                        {EngineTypeSR[(entity as WorkingMachineDto).engineType ?? EngineType.Electric]}
                    </p>
                </Grid>
            ) : (
                <div></div>
            )}
        </Grid>
    );
}
