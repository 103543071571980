import * as React from 'react';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import noData from '../assets/img/no_data.png';
import { isAppTypeDriver, isRoleSysAdmin } from '../Extensions/Selectors/Selectors';
import './NoData.css';

export default function NoDataScreen(): ReactElement {
    const isSysAdmin = useSelector(isRoleSysAdmin);

    const location = useLocation();
    const path = location.pathname;

    const isDrivers = isAppTypeDriver(path);

    return (
        <div className="no-data-container">
            <div className="no-data-img">
                <img src={noData} alt="" />
            </div>
            <div className="no-data-text">
                Baza je prazna.{' '}
                {isSysAdmin || !isDrivers ? 'Ubacite svoj prvi objekat.' : 'Kontaktirajte sistem administratora.'}
            </div>
        </div>
    );
}
