import React, { ChangeEvent, ReactElement, useState } from 'react';
import './Feed.css';
import ExpensesFeedElement from './FeedElements/ExpensesFeedElement';
import { RecordSearchTokenModel } from '../../../Extensions/models/RecordSearchTokenModel';
import { ExpenseCategory } from '../../../AutoGeneratedAPI/clientApi';
import { SelectChangeEvent } from '@mui/material';
import { ALL_EXP_TYPES } from '../../../../Common/Consts';
import SearchWindow from './SearchWindow';
import ToggleViewWindow from './ToggleViewWindow';
import { RecordType } from '../../../Extensions/Extensions';

export default function ExpensesFeed(): ReactElement {
    const [query, setQuery] = useState<RecordSearchTokenModel>({
        searchText: '',
        startingDate: undefined,
        endingDate: undefined,
        lastEntityId: 0,
    });
    const [expenseType, setExpenseType] = useState<ExpenseCategory | undefined>(undefined);
    const [toggleView, setToggleView] = useState(false);

    const handleToggleView = () => {
        setToggleView(!toggleView);
    };

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery({ ...query, searchText: e.target.value, lastEntityId: 0 });
    };

    const handleStartDateChange = (date?: Date) => {
        setQuery({ ...query, startingDate: date as Date, lastEntityId: 0 });
    };

    const handleEndDateChange = (date?: Date) => {
        setQuery({ ...query, endingDate: date as Date, lastEntityId: 0 });
    };

    const handleExpenseTypeChange = (event: SelectChangeEvent<ExpenseCategory | string | undefined>): void => {
        const target = event.target;
        const value = target.value;
        if (!value || value === ALL_EXP_TYPES) {
            setExpenseType(undefined);
        } else {
            if (value as ExpenseCategory) {
                setExpenseType(value as ExpenseCategory);
            }
        }
        setExpId(0);
    };

    const setExpId = (expId: number) => {
        setQuery({ ...query, lastEntityId: expId });
    };

    return (
        <div style={{ overflowY: 'hidden' }}>
            <SearchWindow
                handleEndDateChange={handleEndDateChange}
                handleExpenseTypeChange={handleExpenseTypeChange}
                handleSearch={handleSearch}
                handleStartDateChange={handleStartDateChange}
                query={query}
                recordType={RecordType.Expense}
                defaultExpenseType={expenseType}
            />
            <ToggleViewWindow toggleView={toggleView} handleToggleView={handleToggleView} />
            <div className={'outter'}>
                <div className={'inner'}>
                    <ExpensesFeedElement
                        tableView={toggleView}
                        query={query}
                        expenseCategory={expenseType}
                        setExpenseId={setExpId}
                    />
                </div>
            </div>
        </div>
    );
}
