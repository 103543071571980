import React, { ReactElement, useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { GoogleMap, DirectionsRenderer, MarkerF, InfoWindowF } from '@react-google-maps/api';
import './DirectionsMap.css';
import { DirectionsRendererOptions, DirectionsResult, LatLngLiteral, MapOptions } from '../../Common';
import { getBoundsZoomLevel } from './GoogleMapsHelper';
import { Dimensions } from '../../../General/Extensions/Extensions';
import { RequestOriginApp, StopDto } from '../../../General/AutoGeneratedAPI/clientApi';
import {
    getLatLngArrayFromStops,
    rearrangeStopsByScheme,
    rearrangeStopsTimes,
} from '../../../General/SideDetails/CalendarTWSideDetails/ShipmentsDND/ShipmentsDNDHelper';
import { useAppDispatch, useAppSelector } from '../../../General/hooks/hooks';
import {
    addInitialStopsForTw,
    addStopsDistances,
    addStopsDurations,
} from '../../../General/Redux/Reducers/Warrants/TransportWarrantFormReducer';
import { RootState } from '../../../General/Redux/store';

type DirectionsMapProps = {
    mapDimensions: Dimensions;
};

export default function DirectionsMap({ mapDimensions }: DirectionsMapProps): ReactElement {
    const currShipments = useAppSelector((state: RootState) => state.twFormData.currentTwInitialShipments);
    const currStops = useAppSelector((state: RootState) => state.twFormData.currentTwInitialStops);
    const currStopsArrangement = useAppSelector((state: RootState) => state.twFormData.currentTwStopsArrangement);
    const stopsDurations = useAppSelector((state: RootState) => state.twFormData.stopsDurations);
    const transportWarrant = useAppSelector((state: RootState) => state.twFormData.transportWarrant);

    const [zoom, setZoom] = useState<number>(10);
    const [center, setCenter] = useState<LatLngLiteral>({ lat: 44.01667, lng: 20.91667 });
    const [infoBoxIndex, setInfoBoxIndex] = useState<number>(-1);
    /**
     * Paths from every two points in locations[]
     */
    const [paths, setPaths] = useState<{ index: number; dirRes: DirectionsResult }[]>();

    const dispatch = useAppDispatch();

    const mapRef = useRef<GoogleMap>();

    const options = useMemo<MapOptions>(
        () => ({
            disableDefaultUI: true,
            clickableIcons: false,
        }),
        [],
    );

    const directionsRendererOptions = useMemo<DirectionsRendererOptions>(
        () => ({
            preserveViewport: true,
            suppressMarkers: true,
        }),
        [],
    );

    const onLoad = useCallback((map: any) => (mapRef.current = map), []);

    /** Get distances and durations based on paths */
    useEffect(() => {
        if (paths && paths.length === currStops.length + 1) {
            const newPaths = paths.sort((a, b) => {
                return a.index - b.index;
            });

            const dists = newPaths.map((p) => {
                return {
                    index: p.index,
                    value: p.dirRes.routes[0].legs[0].distance?.value ?? 0,
                };
            });

            const durs = newPaths.map((p) => {
                return {
                    index: p.index,
                    value: p.dirRes.routes[0].legs[0].duration?.value ?? 0,
                };
            });

            dispatch(addStopsDistances(dists));
            dispatch(addStopsDurations(durs));
        } else if (currStops.length <= 0 && (!paths || paths?.length <= 0)) {
            dispatch(addStopsDistances([]));
            dispatch(addStopsDurations([]));
        }
    }, [paths]);

    /**
     * Gets directions between two positions
     * @param position1 origin position
     * @param position2 destination position
     * @returns void
     */
    const fetchDirections = (position1: LatLngLiteral, position2: LatLngLiteral, index: number): void => {
        if (!position1 || !position2) return;

        const directionsService = new google.maps.DirectionsService();

        directionsService.route(
            {
                origin: position1,
                destination: position2,
                travelMode: google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === 'OK' && result) {
                    const res = { index: index, dirRes: result };
                    setPaths((prev) => [...(prev ?? []), res]);
                }
            },
        );
    };

    const locations = useMemo(() => {
        return getLatLngArrayFromStops(currStops);
    }, [currStops, currStopsArrangement]);

    // Set zoom and map size based on positions of shipments
    useEffect(() => {
        if (currStops && currStops.length > 0 && currStops.length === currStopsArrangement.length) {
            /** Get bounds for given locations, then extract zoom and center */
            const bounds = new google.maps.LatLngBounds();

            locations.forEach((n) => {
                bounds.extend(n);
            });

            const newZoom = getBoundsZoomLevel(bounds, {
                height: mapDimensions.height,
                width: mapDimensions.width,
            });
            const newCenterFuncs = bounds.getCenter();
            const newCenter: LatLngLiteral = { lat: newCenterFuncs.lat(), lng: newCenterFuncs.lng() };

            setZoom(newZoom);
            setCenter(newCenter);
        }
    }, [currStops, mapDimensions]);

    // Get paths and directions for shipments
    useEffect(() => {
        if (currStops && currStops.length > 0 && currStops.length === currStopsArrangement.length) {
            const newCurrStops = rearrangeStopsByScheme(currStops, currStopsArrangement);

            const arrangedLocations = getLatLngArrayFromStops(newCurrStops);
            setPaths([]);

            /** Get directions between every two consecutive locations */
            arrangedLocations.map((loc, index) => {
                if (index === 0) return;

                fetchDirections(arrangedLocations[index - 1], loc, index - 1);
            });
        } else if (!currStops || currStops.length <= 0 || currStopsArrangement.length <= 0) {
            setPaths([]);
        }
    }, [currStopsArrangement]);

    /**
     * Rearrange shipments based on currShipmentsArrangement only when the durations are changed.
     * Flow:    1) fetch currentShipments and store them into redux (CalendarTransportWarrantSideDetails)
     *          2) get arrangement based on times and put it in redux (CalendarTransportWarrantSideDetails)
     *          3) when arrangement changes, directionsMap gets paths, fetches distances and durations, and puts it all in redux
     *          4) on durations change, we rearrange shipments and their times, and put the result into redux
     */
    useEffect(() => {
        if (
            currStops.length > 0 &&
            currStops.length === currStopsArrangement.length &&
            stopsDurations.length === currStops.length + 1
        ) {
            const newCurrStops = rearrangeStopsByScheme(currStops, currStopsArrangement);

            const newInitialStops = rearrangeStopsTimes(newCurrStops, transportWarrant.actStartingTime, stopsDurations);

            dispatch(addInitialStopsForTw(newInitialStops));
        }
    }, [stopsDurations, transportWarrant.actStartingTime]);

    const directionsRenderers = useMemo((): ReactElement => {
        return paths && paths.length === currStops.length + 1 ? (
            <>
                {paths.map((path) => {
                    return (
                        <DirectionsRenderer
                            key={path.dirRes.routes[0].overview_path[0].lng() + Math.random()}
                            options={directionsRendererOptions}
                            directions={path.dirRes}
                        />
                    );
                })}
            </>
        ) : (
            <></>
        );
    }, [paths]);

    const Markers = useMemo((): ReactElement => {
        if (currStops && currStops.length > 0) {
            return locations ? (
                <>
                    {locations.map((location, index) => {
                        return (
                            <MarkerF
                                key={location.lat + ' ' + location.lng + ' ' + Math.random()}
                                position={location}
                                onClick={() => openInfoBox(index)}
                            ></MarkerF>
                        );
                    })}
                </>
            ) : (
                <></>
            );
        } else {
            return <></>;
        }
    }, [locations]);

    const openInfoBox = (index: number) => {
        setInfoBoxIndex(index);
    };
    const resetInfoBox = () => {
        setInfoBoxIndex(-1);
    };

    const InfoBoxes = (): ReactElement => {
        if (currShipments && currShipments.length > 0 && infoBoxIndex > -1) {
            const currLocation = locations[infoBoxIndex];
            const currentStops = currStops.filter(
                (stop) =>
                    stop?.locationInfo?.coordinates?.lat === currLocation?.lat &&
                    stop?.locationInfo?.coordinates?.lng === currLocation?.lng,
            );
            return (
                <>
                    {infoBoxIndex === 0 || (infoBoxIndex === locations.length - 1 && infoBoxIndex !== -1) ? (
                        <CustomInfoWindow
                            key={infoBoxIndex + ' ' + locations[infoBoxIndex].lat}
                            position={locations[infoBoxIndex]}
                            currentStops={undefined}
                            customTitle={'Kuća'}
                        ></CustomInfoWindow>
                    ) : (
                        locations.map((location, index) => {
                            if (infoBoxIndex === index) {
                                return (
                                    <CustomInfoWindow
                                        key={infoBoxIndex + ' ' + location?.lat}
                                        position={location}
                                        currentStops={currentStops}
                                    ></CustomInfoWindow>
                                );
                            }
                        })
                    )}
                </>
            );
        }
        return <></>;
    };

    type CustomInfoWindowProps = {
        position: LatLngLiteral;
        currentStops?: StopDto[];
        customTitle?: string;
    };

    const CustomInfoWindow = ({ position, currentStops, customTitle }: CustomInfoWindowProps) => {
        return (
            <InfoWindowF
                key={infoBoxIndex + ' ' + position.lat}
                position={position}
                onCloseClick={() => resetInfoBox()}
            >
                {customTitle ? (
                    <div style={{ fontWeight: 'bold' }}>{customTitle}</div>
                ) : currentStops ? (
                    <>
                        {currentStops.map((stop, index) => {
                            const shipment = currShipments.find(
                                (cs) => cs.startingStopId === stop.id || cs.endingStopId === stop.id,
                            );
                            if (shipment) {
                                return (
                                    <>
                                        <div style={{ fontWeight: 'bold' }}>
                                            {currentStops.length > 1 && <>{index + 1}</>} {shipment.title} (
                                            {shipment.data?.clientName})
                                        </div>
                                        {shipment.requestOriginApp === RequestOriginApp.Transport &&
                                            (stop.id === shipment.startingStopId ? (
                                                <div>(Utovar)</div>
                                            ) : stop.id === shipment.endingStopId ? (
                                                <div>(Istovar)</div>
                                            ) : (
                                                <></>
                                            ))}
                                        <div>{shipment.data?.invoiceCode}</div>
                                        {index === currentStops.length - 1 && (
                                            <>
                                                <hr />
                                                <div>{stop?.locationInfo?.address}</div>
                                            </>
                                        )}
                                    </>
                                );
                            }
                            return <></>;
                        })}
                    </>
                ) : (
                    <>Nema podataka</>
                )}
            </InfoWindowF>
        );
    };

    return (
        <div className="directions-map-container">
            <GoogleMap
                zoom={zoom}
                center={center}
                mapContainerClassName="directions-map-map-container"
                options={options}
                onLoad={onLoad}
            >
                {directionsRenderers}
                {Markers}
                <InfoBoxes />
            </GoogleMap>
        </div>
    );
}
