import { RecordType } from '../../Extensions/Extensions';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import {
    isAppTypeClient,
    isAppTypeVehicle,
    isAppTypeWorkingToolCat,
    isAppTypeDriver,
    isRecords,
    isExpenses,
    isInvoices,
    isImpDocuments,
    isTransportWarrants,
    isProjects,
    isAppTypeWorkingTool,
} from '../../Extensions/Selectors/Selectors';
import { changeCurrentEntity } from '../../Redux/Reducers/General/CurrentEntityReducer';
import { changeRecordType } from '../../Redux/Reducers/General/RecordTypeReducer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCurrEntity = (id: number, path: string) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    const isClient = isAppTypeClient(path);

    const isVehicle = isAppTypeVehicle(path);
    const isWorkingToolCat = isAppTypeWorkingToolCat(path);
    const isDriver = isAppTypeDriver(path);
    const isWorkingTool = isAppTypeWorkingTool(path);

    if (isClient) {
        GranitClient.getClientById(id).then((res) => dispatch(changeCurrentEntity(res)));
    }

    if (isVehicle) {
        GranitClient.getVehicle(id).then((res) => {
            dispatch(changeCurrentEntity(res));
        });
    }

    if (isDriver) {
        GranitClient.getDriver(id).then((res) => dispatch(changeCurrentEntity(res)));
    }

    if (isWorkingToolCat) {
        if (isWorkingTool) {
            GranitClient.getWorkingTool(id).then((res) => dispatch(changeCurrentEntity(res)));
        } else {
            GranitClient.getWorkingMachine(id).then((res) => dispatch(changeCurrentEntity(res)));
        }
    }
};

export const getTabIndexForPath = (path: string): number => {
    switch (true) {
        case isRecords(path):
            return 0;
        case isExpenses(path):
        case isInvoices(path):
            return 1;
        case isProjects(path):
        case isTransportWarrants(path):
            return 2;
        case isImpDocuments(path):
            return 3;
        default:
            return -1;
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const changeRecordTypeBasedOnPath = (path: string) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    switch (true) {
        case isRecords(path):
            dispatch(changeRecordType(RecordType.Record));
            break;
        case isExpenses(path):
            dispatch(changeRecordType(RecordType.Expense));
            break;
        case isInvoices(path):
            dispatch(changeRecordType(RecordType.Invoices));
            break;
        case isProjects(path):
            dispatch(changeRecordType(RecordType.Projects));
            break;
        case isTransportWarrants(path):
            dispatch(changeRecordType(RecordType.TravelExpense));
            break;
        case isImpDocuments(path):
            dispatch(changeRecordType(RecordType.ImportantDocument));
            break;
        default:
            break;
    }
};
