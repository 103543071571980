import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BuildIcon from '@mui/icons-material/Build';
import customer_icon from '../assets/img/customer_icon.svg';
import driver_icon from '../assets/img/driver_icon.svg';
import handmachine_icon from '../assets/img/handmachine_icon.svg';
import officemachine_icon from '../assets/img/officemachine_icon.svg';
import service_icon from '../assets/img/service_icon.svg';
import supplier_icon from '../assets/img/supplier_icon.svg';
import tools_icon from '../assets/img/tools_icon.svg';
import trailer_icon from '../assets/img/trailer_icon.svg';
import truck_icon from '../assets/img/truck_icon.svg';
import workingmachine_icon from '../assets/img/workingmachine_icon.svg';
import { Divider, Tooltip } from '@mui/material';
import { ReactElement } from 'react';
import {
    isAppTypeClient,
    isAppTypeDriver,
    isAppTypeVehicle,
    isAppTypeWorkingToolCat,
    isAppTypeMainMenu,
} from '../Extensions/Selectors/Selectors';
import './NavigationBtnList.css';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { routes } from '../../Common/Routes';
import {} from '../AutoGeneratedAPI/Extension';

export default function NavigationBtnList(): ReactElement {
    const [openVehicles, setOpenVehicles] = useState(false);
    const [openClients, setOpenClients] = useState(false);
    const [openWorkingMachines, setOpenWorkingMachines] = useState(false);
    const [openWorkers, setOpenWorkers] = useState(false);
    const [activeItem, setActiveItem] = useState<number>(-1);

    const location = useLocation();
    const path = location.pathname;

    const isVehicle = isAppTypeVehicle(path);
    const isClient = isAppTypeClient(path);
    const isWorkingTool = isAppTypeWorkingToolCat(path);
    const isDriver = isAppTypeDriver(path);
    const isMainMenu = isAppTypeMainMenu(path);

    const handleToggleVehicles = () => {
        setOpenVehicles(!openVehicles);
    };

    const handleToggleClients = () => {
        setOpenClients(!openClients);
    };

    const handleToggleWorkingMachines = () => {
        setOpenWorkingMachines(!openWorkingMachines);
    };

    const handleToggleWorkers = () => {
        setOpenWorkers(!openWorkers);
    };

    const handleSelectActiveItem = (objIndex: number) => {
        setActiveItem(objIndex);
    };

    const liClasses = (objIndex: number): string => {
        let classNames = `nav-btn-list-nested`;
        if (!isMainMenu && activeItem === objIndex) {
            classNames += ` nav-btn-list-active`;
        } else {
            classNames += ` nav-btn-list-nonActive`;
        }
        return classNames;
    };

    return (
        <List component="nav" aria-labelledby="nested-list-subheader" className="nav-btn-list-root">
            <Tooltip title="Vozila" placement="right">
                <ListItem
                    button
                    onClick={handleToggleVehicles}
                    className={isVehicle && !isMainMenu ? 'nav-btn-list-activeMain' : 'nav-btn-list-nonActive'}
                >
                    <ListItemIcon>
                        <DirectionsCarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Vozila" />
                    {openVehicles ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            </Tooltip>
            <Collapse in={openVehicles} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Tooltip title="Automobili" placement="right">
                        <Link
                            to={routes.cars}
                            className="link"
                            onClick={() => {
                                handleSelectActiveItem(0);
                            }}
                        >
                            <ListItem button className={liClasses(0)}>
                                <ListItemIcon>
                                    <DirectionsCarIcon />
                                </ListItemIcon>
                                <ListItemText primary="Automobili" />
                            </ListItem>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Kamioni" placement="right">
                        <Link to={routes.trucks} className="link">
                            <ListItem
                                button
                                className={liClasses(1)}
                                onClick={() => {
                                    handleSelectActiveItem(1);
                                }}
                            >
                                <ListItemIcon>
                                    <img className="nav-icon" src={truck_icon} />
                                </ListItemIcon>
                                <ListItemText primary="Kamioni" />
                            </ListItem>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Prikolice" placement="right">
                        <Link to={routes.trailers} className="link">
                            <ListItem
                                button
                                className={liClasses(2)}
                                onClick={() => {
                                    handleSelectActiveItem(2);
                                }}
                            >
                                <ListItemIcon>
                                    <img className="nav-icon" src={trailer_icon} />
                                </ListItemIcon>
                                <ListItemText primary="Prikolice" />
                            </ListItem>
                        </Link>
                    </Tooltip>
                </List>
            </Collapse>
            <Divider />
            <Tooltip title="Klijenti" placement="right">
                <ListItem
                    button
                    onClick={handleToggleClients}
                    className={isClient && !isMainMenu ? 'nav-btn-list-activeMain' : 'nav-btn-list-nonActive'}
                >
                    <ListItemIcon>
                        <PeopleAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Klijenti" />
                    {openClients ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            </Tooltip>
            <Collapse in={openClients} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Tooltip title="Kupci" placement="right">
                        <Link to={routes.customers} className="link">
                            <ListItem
                                button
                                className={liClasses(3)}
                                onClick={() => {
                                    handleSelectActiveItem(3);
                                }}
                            >
                                <ListItemIcon>
                                    <img className="nav-icon" src={customer_icon} />
                                </ListItemIcon>
                                <ListItemText primary="Kupci" />
                            </ListItem>
                        </Link>
                    </Tooltip>

                    <Tooltip title="Servisi" placement="right">
                        <Link to={routes.services} className="link">
                            <ListItem
                                button
                                className={liClasses(4)}
                                onClick={() => {
                                    handleSelectActiveItem(4);
                                }}
                            >
                                <ListItemIcon>
                                    <img className="nav-icon" src={service_icon} />
                                </ListItemIcon>
                                <ListItemText primary="Serviseri" />
                            </ListItem>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Dobavljači" placement="right">
                        <Link to={routes.suppliers} className="link">
                            <ListItem
                                button
                                className={liClasses(5)}
                                onClick={() => {
                                    handleSelectActiveItem(5);
                                }}
                            >
                                <ListItemIcon>
                                    <img className="nav-icon" src={supplier_icon} />
                                </ListItemIcon>
                                <ListItemText primary="Dobavljači" />
                            </ListItem>
                        </Link>
                    </Tooltip>
                </List>
            </Collapse>
            <Divider />
            <Tooltip title="Radne mašine" placement="right">
                <ListItem
                    button
                    onClick={handleToggleWorkingMachines}
                    className={isWorkingTool && !isMainMenu ? 'nav-btn-list-activeMain' : 'nav-btn-list-nonActive'}
                >
                    <ListItemIcon>
                        <BuildIcon />
                    </ListItemIcon>
                    <ListItemText primary="Radne mašine" />
                    {openWorkingMachines ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            </Tooltip>
            <Collapse in={openWorkingMachines} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Tooltip title="Alati" placement="right">
                        <Link to={routes.workingtools} className="link">
                            <ListItem
                                button
                                className={liClasses(6)}
                                onClick={() => {
                                    handleSelectActiveItem(6);
                                }}
                            >
                                <ListItemIcon>
                                    <img className="nav-icon" src={tools_icon} />
                                </ListItemIcon>
                                <ListItemText primary="Alati" />
                            </ListItem>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Radne mašine" placement="right">
                        <Link to={routes.workingmachines} className="link">
                            <ListItem
                                button
                                className={liClasses(7)}
                                onClick={() => {
                                    handleSelectActiveItem(7);
                                }}
                            >
                                <ListItemIcon>
                                    <img className="nav-icon" src={workingmachine_icon} />
                                </ListItemIcon>
                                <ListItemText primary="Radne mašine" />
                            </ListItem>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Kanc. mašine" placement="right">
                        <Link to={routes.officemachines} className="link">
                            <ListItem
                                button
                                className={liClasses(8)}
                                onClick={() => {
                                    handleSelectActiveItem(8);
                                }}
                            >
                                <ListItemIcon>
                                    <img className="nav-icon" src={officemachine_icon} />
                                </ListItemIcon>
                                <ListItemText primary="Kanc. mašine" />
                            </ListItem>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Ručne mašine" placement="right">
                        <Link to={routes.handmachines} className="link">
                            <ListItem
                                button
                                className={liClasses(9)}
                                onClick={() => {
                                    handleSelectActiveItem(9);
                                }}
                            >
                                <ListItemIcon>
                                    <img className="nav-icon" src={handmachine_icon} />
                                </ListItemIcon>
                                <ListItemText primary="Ručne mašine" />
                            </ListItem>
                        </Link>
                    </Tooltip>
                </List>
            </Collapse>
            <Divider />
            <Tooltip title="Radnici" placement="right">
                <ListItem
                    button
                    onClick={handleToggleWorkers}
                    className={isDriver && !isMainMenu ? 'nav-btn-list-activeMain' : 'nav-btn-list-nonActive'}
                >
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Radnici" />
                    {openWorkers ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            </Tooltip>
            <Collapse in={openWorkers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Tooltip title="Vozači" placement="right">
                        <Link to={routes.drivers} className="link">
                            <ListItem
                                button
                                className={liClasses(10)}
                                onClick={() => {
                                    handleSelectActiveItem(10);
                                }}
                            >
                                <ListItemIcon>
                                    <img className="nav-icon" src={driver_icon} />
                                </ListItemIcon>
                                <ListItemText primary="Vozači" />
                            </ListItem>
                        </Link>
                    </Tooltip>
                </List>
            </Collapse>
        </List>
    );
}
