import { ExpenseCategory, RecordCategory } from '../../AutoGeneratedAPI/clientApi';

export class BaseSearchToken {
    startingDate?: Date;
    endingDate?: Date;
    lastEntityId?: number;
    numberOfEntitiesToReturn?: number;
}

export class SearchToken implements BaseSearchToken {
    startingDate?: Date;
    endingDate?: Date;
    lastEntityId?: number;
    numberOfEntitiesToReturn?: number;
    searchText?: string;
}

export class RecordSearchTokenModel implements SearchToken {
    startingDate?: Date;
    endingDate?: Date;
    lastEntityId?: number;
    numberOfEntitiesToReturn?: number;
    recordCategory?: RecordCategory;
    searchText?: string;
}

export class ExpenseSearchTokenModel implements SearchToken {
    startingDate?: Date;
    endingDate?: Date;
    lastEntityId?: number;
    numberOfEntitiesToReturn?: number;
    searchText?: string;
    expenseCategory?: ExpenseCategory;
}

export class InvoiceSearchTokenModel implements SearchToken {
    startingDate?: Date;
    endingDate?: Date;
    lastEntityId?: number;
    numberOfEntitiesToReturn?: number;
    searchText?: string;
}

export class ImportantDocsSearchTokenModel implements SearchToken {
    startingDate?: Date;
    endingDate?: Date;
    lastEntityId?: number;
    numberOfEntitiesToReturn?: number;
    recordCategory?: RecordCategory;
    searchText?: string;
}

export class TWSearchTokenModel implements BaseSearchToken {
    startingDate?: Date;
    endingDate?: Date;
    lastEntityId?: number;
    numberOfEntitiesToReturn?: number;
    clientId?: number;
}

export class ProjectsSearchToken {
    searchText?: string;
    lastEntityId?: number;
    numberOfEntitiesToReturn?: number;
}
