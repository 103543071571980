import styled from 'styled-components';

export const CalendarTWSideDetailsCloseBtn = styled.div`
    position: absolute;
    left: 15px;
    top: 0;
    font-size: 30px;
    font-family: Courier New;
    cursor: pointer;
`;

export const CalendarTWSideDetailsContainer = styled.div`
    height: calc(100vh - 190px);
    overflow: hidden;
    padding: 20px;
    padding-top: 0;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-evenly;
    align-items: stretch;
    align-content: stretch;
`;

export const CalendarTWSideDetailsBtnsContainer = styled.div<{ $width: number }>`
    position: fixed;
    bottom: 95px;
    left: 0px;
    width: ${(props) => props.$width ?? 0}px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;
