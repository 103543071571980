/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkingMachineDto } from '../../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithAdding, ExceptionsWithUpdating } from '../../../Extensions/Exceptions';
import { addOfficeMachine, updateOfficeMachine } from '../../Reducers/WorkingTools/OfficeMachinesReducer';
import { changeCurrentEntity } from '../../Reducers/General/CurrentEntityReducer';

export const addNewOfficeMachine = (officeMachine: WorkingMachineDto | WorkingMachineDto | undefined) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    GranitClient.addNewWorkingMachine(officeMachine as WorkingMachineDto).then(
        (result) => {
            dispatch(addOfficeMachine(result));
        },
        (error) => {
            alert(ExceptionsWithAdding.EXCEPTION_ADDING_OFFICEMACHINES);
            console.log(error.message);
        },
    );
};

export const updateOldOfficeMachine = (
    officeMachine: WorkingMachineDto | WorkingMachineDto | undefined,
    oldOfficeMachine?: WorkingMachineDto | WorkingMachineDto,
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
    GranitClient.updateWorkingMachine(
        officeMachine !== undefined ? (officeMachine.id as number) : 0,
        officeMachine as WorkingMachineDto,
    ).then(
        (result) => {
            dispatch(changeCurrentEntity(result));
            dispatch(updateOfficeMachine(officeMachine as WorkingMachineDto, oldOfficeMachine));
        },
        (error) => {
            alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_OFFICEMACHINES);
            console.log(error.message);
        },
    );
};
