import React, { ReactElement, useMemo } from 'react';
import { ShipmentDto } from '../../General/AutoGeneratedAPI/clientApi';
import { useAppDispatch, useAppSelector } from '../../General/hooks/hooks';
import {
    addPreselectedShipmentForTw,
    unselectPreselectedShipmentForTw,
} from '../../General/Redux/Reducers/Warrants/TransportWarrantFormReducer';
import { RootState } from '../../General/Redux/store';
import ShipmentCalendarElement from '../../Shipments/ShipmentsCalendar/ShipmentCalendarElement';
import './ShipmentsUnderbar.css';

type ShipmentsUnderbarProps = {
    isFullWidth: boolean;
    selectShipmentForUpdating(shipment: ShipmentDto): void;
    selectShipmentForLoading(shipment: ShipmentDto, twStartingTime: Date): void;
};

export default function ShipmentsUnderbar({
    isFullWidth,
    selectShipmentForUpdating,
    selectShipmentForLoading,
}: ShipmentsUnderbarProps): ReactElement {
    const transportWarrant = useAppSelector((state: RootState) => state.twFormData.transportWarrant);
    const shipments = useAppSelector((state: RootState) => state.shipments.shipments ?? []);
    const unselectedShipments = useAppSelector((state: RootState) => state.twFormData.unselectedShipmentsFromTW);
    const currentTwShipments = useAppSelector((state: RootState) => state.twFormData.currentTwInitialShipments);
    const selectedShipmentForTw = useAppSelector((state: RootState) => state.twFormData.selectedShipmentForTW);

    const dispatch = useAppDispatch();

    const selectShipmentForTw = (shipment?: ShipmentDto): void => {
        if (shipment) {
            selectShipmentForLoading(shipment, transportWarrant.actStartingTime);
        }
    };

    const preselectShipmentForTw = (shipment?: ShipmentDto): void => {
        if (shipment) {
            dispatch(addPreselectedShipmentForTw(shipment));
        }
    };

    const unselectPreselectedShipForTw = (): void => {
        dispatch(unselectPreselectedShipmentForTw());
    };

    const filteredShipments = useMemo(() => {
        return shipments
            .filter(
                (sh) =>
                    sh.id !== selectedShipmentForTw?.id && currentTwShipments.findIndex((ss) => ss.id === sh.id) === -1,
            )
            .concat(unselectedShipments);
    }, [shipments, currentTwShipments, unselectedShipments, selectedShipmentForTw]);

    return (
        <div
            className={
                isFullWidth
                    ? 'shipments-underbar-container shipments-underbar-container-full-width'
                    : 'shipments-underbar-container shipments-underbar-container-70'
            }
        >
            <div className="shipments-underbar-flex-container">
                {filteredShipments.map((shipment) => {
                    return (
                        <ShipmentCalendarElement
                            key={shipment.id}
                            shipment={shipment}
                            preselectShipmentForTw={preselectShipmentForTw}
                            unselectPreselectedShipForTw={unselectPreselectedShipForTw}
                            selectForUpdating={selectShipmentForUpdating}
                            selectShipmentForLoading={selectShipmentForTw}
                            isTwActive={!transportWarrant.isTwFinished}
                        />
                    );
                })}
            </div>
        </div>
    );
}
