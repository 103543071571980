import {
    Button,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import RightSidebar from '../Common/CommonComponents/RightSidebar';
import { ExpenseCategory } from '../General/AutoGeneratedAPI/clientApi';
import { ExpenseEntityType } from '../General/Extensions/Entities';
import { ExpenseTypeSR } from '../General/Extensions/Localizations/Types/Expenses/SR/ExpenseTypeSR';
import ExpenseForm from '../General/Profile/Form/ExpenseForm';
import ChartExpenseSideDetails from '../General/SideDetails/ChartExpenseSideDetails';
import { columns } from './ChartsHelper';

type AdditionalExpensesCalculatorProps = {
    expenses: ExpenseEntityType[];
};

export default function AdditionalExpensesCalculator({ expenses }: AdditionalExpensesCalculatorProps): ReactElement {
    const [openExpenseForm, setOpenExpenseForm] = useState<boolean>(false);
    const [openChartExpenseSideDetails, setOpenChartExpenseSideDetails] = useState<boolean>(false);
    const [expId, setExpId] = useState<number>(0);

    const [searchParams] = useSearchParams();

    const vehId = searchParams.get('vehicleId');

    useEffect(() => {
        if (expId && expId > 0) {
            setOpenChartExpenseSideDetails(true);
        } else {
            setOpenChartExpenseSideDetails(false);
        }
    }, [expId]);

    const handleOpenExpenseForm = () => {
        setOpenExpenseForm(true);
    };

    const handleCloseExpenseForm = () => {
        setOpenExpenseForm(false);
    };

    const handleOpenChartExpenseSideDetails = (expId: number) => {
        setExpId(expId);
    };

    const handleCloseChartExpenseSideDetails = () => {
        setExpId(0);
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <Typography variant="h6" component="h6">
                {ExpenseTypeSR[ExpenseCategory.CalculatorExpense].toString()}
            </Typography>
            <TableContainer component={Paper} sx={{ height: '80%', borderRadius: 0 }}>
                <Table sx={{ minWidth: 550 }} stickyHeader size="small" aria-label="Transport warrant table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} sx={{ width: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(!expenses || expenses.length <= 0) && (
                            <div
                                style={{
                                    fontSize: 45,
                                    textAlign: 'center',
                                    position: 'absolute',
                                    width: 400,
                                    top: 120,
                                    left: 80,
                                    color: 'rgba(0, 0, 0, 0.5)',
                                    letterSpacing: -2,
                                }}
                            >
                                Nema dodatnih troškova
                            </div>
                        )}
                        {expenses.map((expense) => {
                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={expense.timestamp}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        handleOpenChartExpenseSideDetails(expense.id);
                                    }}
                                >
                                    {columns.map((column) => {
                                        let value = '';
                                        switch (column.id) {
                                            case 'title':
                                                value = expense.title ?? '';
                                                break;
                                            case 'duration (mon)':
                                                value = expense.expenseType.durationInMonths
                                                    ? column.format
                                                        ? column.format(expense.expenseType.durationInMonths)
                                                        : expense.expenseType.durationInMonths.toString()
                                                    : '';
                                                break;
                                            case 'duration (km)':
                                                value = expense.expenseType.durationInKm
                                                    ? column.format
                                                        ? column.format(expense.expenseType.durationInKm)
                                                        : expense.expenseType.durationInKm.toString()
                                                    : '';
                                                break;
                                            case 'price':
                                                value = expense.price
                                                    ? column.format
                                                        ? column.format(expense.price)
                                                        : expense.price.toString()
                                                    : '';
                                                break;
                                            default:
                                                value = '';
                                                break;
                                        }
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                sx={{ width: column.minWidth }}
                                            >
                                                {column.format && typeof value === 'number'
                                                    ? column.format(value)
                                                    : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                <Button
                    variant="outlined"
                    onClick={handleOpenExpenseForm}
                    disabled={!vehId || isNaN(+vehId) || +vehId === 0}
                >
                    Ubacite novi trošak
                </Button>
            </div>

            <Modal
                className="modalStyle"
                open={openExpenseForm}
                aria-labelledby="add-vehicle-form"
                aria-describedby="adding a vehicle to a database through this form"
            >
                <div>
                    <ExpenseForm
                        closeForm={handleCloseExpenseForm}
                        calculatorVehicleId={!vehId || isNaN(+vehId) ? 0 : +vehId}
                        calculatorExpId={expId}
                    />
                </div>
            </Modal>
            <RightSidebar open={openChartExpenseSideDetails}>
                <ChartExpenseSideDetails
                    closeChartExpenseSideDetails={handleCloseChartExpenseSideDetails}
                    openChartExpenseForm={handleOpenExpenseForm}
                    expId={expId}
                />
            </RightSidebar>
        </div>
    );
}
