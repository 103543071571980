import React, { ReactElement, useState, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { GranitClient } from '../General/AutoGeneratedAPI/Extension';
import { Typography } from '@mui/material';
import { ServerResponseStatus } from '../General/Extensions/Extensions';
import { LoaderProgress } from '../Common/CommonComponents/Loader/LoaderProgress';
import { Data, DataSet, options } from './ChartsHelper';
import { useSearchParams } from 'react-router-dom';
import AverageConsumption from './AverageConsumption';

Chart.register(...registerables);

type FuelExpensesChartProps = {
    fuelExpenseEndDate: string;
    fuelExpenseStartDate: string;
};

export default function FuelExpensesChart({
    fuelExpenseEndDate,
    fuelExpenseStartDate,
}: FuelExpensesChartProps): ReactElement {
    const [fuelData, setFuelData] = useState<Data>({ labels: [], datasets: [] });
    const [numOfExpensesPerStep, setNumOfExpensesPerStep] = useState<number>(0);
    const [averageFuelConsuptionForPeriod, setAverageFuelConsuptionForPeriod] = useState<number>(0);
    const [averageFuelExpenseForPeriod, setAverageFuelExpenseForPeriod] = useState<number>(0);

    const [fuelExpensesLoadingStatus, setFuelExpensesLoadingStatus] = useState<ServerResponseStatus>(
        ServerResponseStatus.Success,
    );

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const vehId = searchParams.get('vehicleId');
        if (vehId && !isNaN(+vehId) && +vehId > 0) {
            const vehicleId = +vehId;
            const eDate = new Date(fuelExpenseEndDate);
            const sDate = new Date(fuelExpenseStartDate);
            setFuelExpensesLoadingStatus(ServerResponseStatus.Pending);
            GranitClient.getTruckFuelPricePerKm(vehicleId, sDate, eDate).then((res) => {
                let lbls: string[] = [];
                let currData: number[] = [];
                let currPricePerKm: number[] = [];
                let currDates: Date[] = [];

                let priceSum = 0;
                let averageFuelConsumptionSum = 0;
                res.forEach((sum) => {
                    if (sum.step) {
                        setNumOfExpensesPerStep(sum.step);
                    }
                    const date = new Date(sum.date ?? new Date());

                    if (date) {
                        currDates = [...currDates, date];
                    }

                    priceSum += sum.value;
                    averageFuelConsumptionSum += sum.averageFuelConsumptionPerOneHundredKm ?? 0;

                    const title = sum?.currentVehicleKm + 'km';
                    lbls = [...lbls, title];
                    if (sum.averageFuelConsumptionPerOneHundredKm !== undefined) {
                        currData = [...currData, sum.averageFuelConsumptionPerOneHundredKm];
                    }
                    if (sum.value) {
                        currPricePerKm = [...currPricePerKm, sum.value];
                    }
                });

                setAverageFuelConsuptionForPeriod(averageFuelConsumptionSum / res.length);
                setAverageFuelExpenseForPeriod(priceSum / res.length);

                const dataset: DataSet[] = [
                    {
                        label: 'Troškovi goriva',
                        afterLabel: currPricePerKm,
                        data: currData,
                        fill: false,
                        backgroundColor: ['rgb(255, 99, 132)'],
                        borderColor: ['rgba(255, 99, 132, 0.5)'],
                        hoverBorderWidth: 8,
                        pointRadius: 4,
                        tension: 0.1,
                        dates: currDates,
                    },
                ];
                setFuelData(() => ({ labels: lbls, datasets: dataset }));
                setFuelExpensesLoadingStatus(ServerResponseStatus.Success);
            });
        } else {
            const lbls = ['Nema informacija', 'Nema informacija'];
            const dataset: DataSet[] = [
                {
                    label: 'Troškovi goriva',
                    afterLabel: [0, 0],
                    data: [0, 0],
                    fill: false,
                    backgroundColor: ['rgb(255, 99, 132)'],
                    borderColor: ['rgba(255, 99, 132, 0.5)'],
                    hoverBorderWidth: 8,
                    pointRadius: 4,
                    tension: 0.1,
                    dates: [new Date(), new Date()],
                },
            ];
            setFuelData(() => ({ labels: lbls, datasets: dataset }));
        }
    }, [searchParams, fuelExpenseStartDate, fuelExpenseEndDate]);

    return (
        <div
            style={{
                width: 600,
                height: 400,
                border: '1px solid lightgray',
                borderRadius: 5,
                padding: 20,
                position: 'relative',
            }}
        >
            <div style={{ position: 'absolute', top: 25, left: 25 }}>
                <Typography component="h6" variant="h6" style={{ letterSpacing: -1 }}>
                    Br. sipanja po koraku: {numOfExpensesPerStep}
                </Typography>
            </div>
            <div style={{ position: 'absolute', bottom: 55, left: 25 }}>
                <Typography
                    component="h6"
                    variant="h6"
                    style={{ letterSpacing: -1, fontWeight: 'normal', fontSize: 18 }}
                ></Typography>
            </div>
            <div style={{ position: 'absolute', bottom: 10, right: 25 }}>
                <AverageConsumption
                    averageExpense={averageFuelExpenseForPeriod}
                    endDate={fuelExpenseEndDate}
                    startDate={fuelExpenseStartDate}
                    averageFuelConsumption={averageFuelConsuptionForPeriod}
                    isFuel={true}
                />
            </div>
            {fuelExpensesLoadingStatus === ServerResponseStatus.Success ? (
                <Line data={fuelData} options={options} style={{ position: 'absolute', zIndex: 9, bottom: 50 }} />
            ) : fuelExpensesLoadingStatus === ServerResponseStatus.Pending ? (
                <div style={{ height: 250, width: 250, margin: 'auto' }}>
                    <LoaderProgress />
                </div>
            ) : (
                'Greška'
            )}
        </div>
    );
}
