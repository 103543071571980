import React, { ReactElement, useEffect } from 'react';
import { ChangeEvent } from 'react';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import '../../Forms/Forms.css';
import { Button, Grid, Typography, Paper, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { addNewExpense, updateOldExpense } from '../../Redux/Actions/General/ExpensesAction';
import { deleteExpenseDoc } from '../../Redux/Reducers/General/ExpensesReducer';
import { RootState } from '../../Redux/store';
import {
    ExpenseCategory,
    RecordDocumentDto,
    VehicleExpenseDto,
    WorkingToolExpenseDto,
    IVehicleExpenseDto,
    IWorkingToolExpenseDto,
    ExpenseTypeDto,
    IExpenseTypeDto,
    ITimeEventDto,
    IKmEventDto,
    TimeEventDto,
    KmEventDto,
    NotificationMedia,
    UserScope,
    EntityType,
    EventTimeSpan,
} from '../../AutoGeneratedAPI/clientApi';
import { ExpenseEntityType } from '../../Extensions/Entities';
import { useState } from 'react';
import { isAppTypeChart, isAppTypeVehicle } from '../../Extensions/Selectors/Selectors';
import 'dayjs/locale/sr';
import { SelectChangeEvent } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../hooks/hooks';
import { addKmEventToRedux, addTimeEventToRedux } from '../../Redux/Reducers/Notifications/NotificationsReducer';
import ExpenseEventFormElement from './ExpenseEventFormElement';
import {
    checkIfDataIsChanged,
    checkIfRequiredIsNotEmpty,
    isExpenseCategoryFuel,
    isKmEventExpense,
    isKmEventValid,
    isTimeEventExpense,
    isTimeEventValid,
} from './ExpenseFormHelper';
import ExpenseFormElement from './ExpenseFormElement';

type ExpenseFormProps = {
    closeForm(): void;
    calculatorVehicleId?: number;
    calculatorExpId?: number;
};

export default function ExpenseForm({
    closeForm,
    calculatorVehicleId,
    calculatorExpId,
}: ExpenseFormProps): ReactElement {
    const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
    const [currentExpense, setCurrentExpense] = useState<IWorkingToolExpenseDto | IVehicleExpenseDto>(
        new VehicleExpenseDto(),
    );
    const [oldExpense, setOldExpense] = useState<IWorkingToolExpenseDto | IVehicleExpenseDto | undefined>(undefined);
    const [isUpdateForm, setIsUpdateForm] = useState<boolean>(false);
    const [currentTimeEvent, setCurrentTimeEvent] = useState<ITimeEventDto>(
        new TimeEventDto({
            ...new TimeEventDto(),
            notificationMedia: NotificationMedia.Transport,
            userScope: UserScope.Personal,
            entityType: EntityType.Expense,
            isDone: false,
            startingDate: new Date(),
        }),
    );
    const [currentKmEvent, setCurrentKmEvent] = useState<IKmEventDto>(
        new KmEventDto({
            ...new KmEventDto(),
            notificationMedia: NotificationMedia.Transport,
            userScope: UserScope.Personal,
            entityType: EntityType.Expense,
            isDone: false,
            startingDate: new Date(),
        }),
    );
    const [openEventForm, setOpenEventForm] = useState<boolean>(false);

    const userGroup = useAppSelector((state: RootState) => state.loggedEmployee.userGroup);

    const entity = useAppSelector((state: RootState) => state.currentEntity.entity);
    const expenses = useAppSelector((state: RootState) => state.expenses.expenses);

    const location = useLocation();
    const path = location.pathname;

    const isVehicle = isAppTypeVehicle(path);
    const isCalculator = isAppTypeChart(path);

    const dispatch = useAppDispatch();

    const { expId } = useParams();

    useEffect(() => {
        if (expenses && expId && !isNaN(+expId)) {
            const exp = expenses?.find((exp) => exp.id === +expId);
            setCurrentExpense(exp ?? new VehicleExpenseDto());
            setOldExpense(exp);
            setIsUpdateForm(true);
            if (exp && exp.expenseType && exp.expenseTypeId) {
                if (exp.expenseType.durationInKm && exp.expenseType.durationInKm > 0) {
                    setCurrentKmEvent({
                        ...currentKmEvent,
                        inKmToRing: exp.expenseType.durationInKm,
                        startingDate: exp.validFrom,
                    });
                }
                if (exp.expenseType.durationInMonths && exp.expenseType.durationInMonths > 0) {
                    setCurrentTimeEvent({
                        ...currentTimeEvent,
                        inTimeRing: {
                            ...currentTimeEvent.inTimeRing,
                            months: exp.expenseType.durationInMonths,
                            days: 0,
                        } as EventTimeSpan,
                        startingDate: exp.validFrom,
                    });
                }
            }
        }
        if (isCalculator) {
            if (calculatorExpId && calculatorExpId > 0) {
                GranitClient.getVehicleExpenseByExpId(calculatorExpId).then((res) => {
                    setCurrentExpense(res);
                    setOldExpense(res);
                });
                setIsUpdateForm(true);
            } else {
                const newExp = new VehicleExpenseDto({
                    ...new VehicleExpenseDto(),
                    expenseType: {
                        ...new ExpenseTypeDto(),
                        expenseCategory: ExpenseCategory.CalculatorExpense,
                    } as ExpenseTypeDto,
                    validFrom: new Date(),
                });
                setCurrentExpense(newExp);
                setOldExpense(newExp);
            }
        }
    }, [expId, expenses]);

    const handleToogleEventForm = () => {
        setOpenEventForm(!openEventForm);
    };

    const handleFileInputChange = (selectorFiles: FileList | undefined): void => {
        const filesArray = selectorFiles !== undefined ? Array.from(selectorFiles).map((file) => file) : undefined;
        if (filesArray !== undefined) {
            if (selectedFiles.length <= 0) {
                setSelectedFiles(filesArray);
            } else {
                for (let i = 0; i < filesArray?.length; i++) {
                    setSelectedFiles((selectedFiles) => [...selectedFiles, filesArray[i]]);
                }
            }
        }
    };

    const handleFileDelete = (fileToDelete: File): void => {
        let newFiles: Array<File> = [];
        if (selectedFiles.length > 0) {
            newFiles = selectedFiles.filter((file) => file !== fileToDelete).map((f) => f);
            setSelectedFiles(newFiles);
        }
    };

    const handleInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const type = target.type;

        if (type === 'number') {
            const val = +value;
            setCurrentExpense({ ...currentExpense, [name]: val });
        } else {
            setCurrentExpense({ ...currentExpense, [name]: value });
        }
    };

    const handleExpTypeInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === 'expenseSubCategory') {
            let expType: IExpenseTypeDto = new ExpenseTypeDto();
            expType = {
                ...currentExpense.expenseType,
                [name]: value,
            };
            const eT = new ExpenseTypeDto(expType);

            setCurrentExpense({
                ...currentExpense,
                expenseType: eT,
            });
        } else {
            let expType: IExpenseTypeDto = new ExpenseTypeDto();
            const val = +value;
            expType = {
                ...currentExpense.expenseType,
                [name]: val,
            };
            const eT = new ExpenseTypeDto(expType);

            if (name === 'durationInMonths') {
                setCurrentTimeEvent({
                    ...currentTimeEvent,
                    inTimeRing: { ...currentTimeEvent.inTimeRing, months: val, days: 0 } as EventTimeSpan,
                });
            } else if (name === 'durationInKm') {
                setCurrentKmEvent({ ...currentKmEvent, inKmToRing: val });
            }

            setCurrentExpense({
                ...currentExpense,
                expenseType: eT,
            });
        }
    };

    const handleValidFromChange = (date?: Date): void => {
        if (date) {
            setCurrentExpense({ ...currentExpense, validFrom: date as Date });
            setCurrentTimeEvent({ ...currentTimeEvent, startingDate: date as Date });
            setCurrentKmEvent({ ...currentKmEvent, startingDate: date as Date });
        }
    };

    const handleExpenseTypeChange = (event: SelectChangeEvent<ExpenseCategory>): void => {
        const target = event.target;
        const value = target.value;

        if (value as ExpenseCategory) {
            Object.values(ExpenseCategory).map((et) => {
                if (ExpenseCategory[et] === value) {
                    const expType: IExpenseTypeDto = new ExpenseTypeDto();
                    expType.expenseCategory = et;
                    const eT = new ExpenseTypeDto(expType);
                    setCurrentExpense({ ...currentExpense, expenseType: eT });
                }
            });
        }
    };

    const handleEventInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (value !== undefined) {
            setCurrentTimeEvent({ ...currentTimeEvent, [name]: value });
            setCurrentKmEvent({ ...currentKmEvent, [name]: value });
        }
    };

    const handleTimeEventTimeSpanInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = +target.value;
        const name = target.name;

        if (!isNaN(value) && value >= 0) {
            setCurrentTimeEvent({
                ...currentTimeEvent,
                inTimeRing: { ...currentTimeEvent.inTimeRing, [name]: value } as EventTimeSpan,
            });
        }
    };

    const handleTimeEventRingDateChange = (date?: Date): void => {
        if (date) {
            setCurrentTimeEvent({ ...currentTimeEvent, ringDate: date as Date });
        }
    };

    const handleTimeEventMarginInDaysChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = +target.value;

        if (!isNaN(value) && value >= 0) {
            setCurrentTimeEvent({ ...currentTimeEvent, marginInDays: value });
        }
    };

    const handleKmEventInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = +target.value;
        const name = target.name;

        if (!isNaN(value) && value >= 0) {
            setCurrentKmEvent({ ...currentKmEvent, [name]: value });
        }
    };

    const addKmEvent = (expId: number) => {
        if (userGroup && userGroup.id) {
            const kmEvent = currentKmEvent;
            kmEvent.entityId = expId;
            kmEvent.userGroupId = userGroup.id;

            GranitClient.addKmEvent(kmEvent as KmEventDto).then((res) => {
                dispatch(addKmEventToRedux(res));
            });
        }
    };

    const addTimeEvent = (expId: number) => {
        if (userGroup && userGroup.id) {
            const timeEvent = currentTimeEvent;
            timeEvent.entityId = expId;
            timeEvent.userGroupId = userGroup.id;

            GranitClient.addTimeEvent(timeEvent as TimeEventDto).then((res) => {
                dispatch(addTimeEventToRedux(res));
            });
        }
    };

    const deleteFile = async (file: RecordDocumentDto) => {
        GranitClient.deleteRecordDocument(oldExpense?.id as number, file.id as number);
        await dispatch(deleteExpenseDoc(oldExpense as ExpenseEntityType, file));
        setCurrentExpense({ ...currentExpense, documents: oldExpense?.documents });
    };

    const isTimeEventExp = isTimeEventExpense(currentExpense);
    const isKmEventExp = isKmEventExpense(currentExpense);
    const isTimeEventOk = isTimeEventValid(currentTimeEvent, true);
    const isKmEventOk = isKmEventValid(currentKmEvent, true);

    const onSubmit = async () => {
        const expense = currentExpense;

        if (expense.expenseType.expenseCategory != ExpenseCategory.Fuel) {
            expense.liters = undefined;
            expense.pricePerLiter = undefined;
        }

        if (!expense.validFrom) {
            expense.validFrom = new Date();
        }

        if (expense !== undefined) {
            if (isUpdateForm) {
                const expenseId = await dispatch(
                    updateOldExpense(
                        isVehicle || isCalculator
                            ? new VehicleExpenseDto(expense as IVehicleExpenseDto)
                            : new WorkingToolExpenseDto(expense as IWorkingToolExpenseDto),
                        path,
                        selectedFiles as Array<File>,
                    ),
                );

                if (isTimeEventExp && isTimeEventOk && expenseId && openEventForm) {
                    addTimeEvent(expenseId);
                }

                if (isKmEventExp && isKmEventOk && expenseId && openEventForm) {
                    addKmEvent(expenseId);
                }

                if (expenseId !== 0) {
                    closeForm();
                }
            } else {
                if (expense.expenseType && expense.expenseType.expenseCategory === ExpenseCategory.Fuel) {
                    expense.expenseType.expenseSubCategory = 'Gorivo';
                } else if (expense.expenseType && !expense.expenseType.expenseSubCategory) {
                    expense.expenseType.expenseSubCategory = '';
                }
                const expenseId = await dispatch(
                    addNewExpense(
                        isVehicle || isCalculator
                            ? new VehicleExpenseDto(expense as IVehicleExpenseDto)
                            : new WorkingToolExpenseDto(expense as IWorkingToolExpenseDto),
                        isCalculator ? calculatorVehicleId ?? 0 : entity?.id ?? 0,
                        path,
                        selectedFiles as Array<File>,
                    ),
                );

                if (isTimeEventExp && isTimeEventOk && expenseId && openEventForm) {
                    addTimeEvent(expenseId);
                }

                if (isKmEventExp && isKmEventOk && expenseId && openEventForm) {
                    addKmEvent(expenseId);
                }

                if (expenseId !== 0) {
                    closeForm();
                }
            }
        }
    };

    const isEventDataValid =
        (isTimeEventExp && isTimeEventOk && (!isKmEventExp || isKmEventOk)) ||
        (isKmEventExp && !isTimeEventExp && isKmEventOk);

    const isDataChanged =
        ((!openEventForm && checkIfDataIsChanged(currentExpense, oldExpense, selectedFiles)) ||
            (openEventForm && isEventDataValid)) &&
        checkIfRequiredIsNotEmpty(currentExpense);

    const isExpCategoryFuel = isExpenseCategoryFuel(currentExpense);

    const disableEventForm = !(currentExpense && !isExpCategoryFuel && (isKmEventExp || isTimeEventExp));

    return (
        <>
            <main className="custom-form-main">
                <div className="custom-form-close" onClick={closeForm}>
                    x
                </div>
                <Paper className="custom-form-paper">
                    <Typography component="h4" variant="h4" align="center" style={{ marginBottom: 50 }}>
                        {!isUpdateForm ? 'Ubacite novi trošak' : 'Promenite trošak'}
                    </Typography>

                    <div style={{ height: 'calc(80vh - 200px)', overflowX: 'hidden', overflowY: 'auto', padding: 20 }}>
                        <ExpenseFormElement
                            deleteFile={deleteFile}
                            handleExpTypeInputElementChange={handleExpTypeInputElementChange}
                            handleExpenseTypeChange={handleExpenseTypeChange}
                            handleFileDelete={handleFileDelete}
                            handleFileInputChange={handleFileInputChange}
                            handleInputElementChange={handleInputElementChange}
                            handleValidFromChange={handleValidFromChange}
                            selectedFiles={selectedFiles}
                            currentExpense={currentExpense}
                        >
                            {!isUpdateForm && (
                                <>
                                    <Grid item xs={12}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={openEventForm}
                                                        onChange={handleToogleEventForm}
                                                        disabled={disableEventForm}
                                                    />
                                                }
                                                label="Ubacite podsetnik"
                                            />
                                        </FormGroup>
                                    </Grid>
                                    {openEventForm && (
                                        <ExpenseEventFormElement
                                            handleEventInputElementChange={handleEventInputElementChange}
                                            handleKmEventInputElementChange={handleKmEventInputElementChange}
                                            handleTimeEventMarginInDaysChange={handleTimeEventMarginInDaysChange}
                                            handleTimeEventTimeSpanInputElementChange={
                                                handleTimeEventTimeSpanInputElementChange
                                            }
                                            handleTimeEventRingDateChange={handleTimeEventRingDateChange}
                                            isKmEventActive={isKmEventExp}
                                            isTimeEventActive={isTimeEventExp}
                                            currentKmEvent={currentKmEvent}
                                            currentTimeEvent={currentTimeEvent}
                                            isKmEventInKmRingActive={true}
                                            isTimeEventInTimeRingActive={true}
                                        />
                                    )}
                                </>
                            )}
                        </ExpenseFormElement>
                    </div>
                    <div style={{ position: 'absolute', bottom: 20, right: 50, width: 200 }}>
                        <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                            disabled={!isDataChanged}
                            onClick={() => {
                                onSubmit();
                            }}
                        >
                            SAČUVAJ
                        </Button>
                    </div>
                </Paper>
            </main>
        </>
    );
}
