/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkingToolDto, WorkingMachineDto } from '../../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithAdding, ExceptionsWithUpdating } from '../../../Extensions/Exceptions';
import { addWorkingTool, updateWorkingTool } from '../../Reducers/WorkingTools/WorkingToolsReducer';
import { changeCurrentEntity } from '../../Reducers/General/CurrentEntityReducer';

export const addNewWorkingTool = (workingTool: WorkingToolDto | WorkingMachineDto | undefined) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    {
        GranitClient.addNewWorkingTool(workingTool as WorkingToolDto).then(
            (result) => {
                dispatch(addWorkingTool(result));
            },
            (error) => {
                alert(ExceptionsWithAdding.EXCEPTION_ADDING_WORKINGTOOLS);
                console.log(error.message);
            },
        );
    }
};

export const updateOldWorkingTool = (
    workingTool: WorkingToolDto | WorkingMachineDto | undefined,
    oldWorkingTool?: WorkingToolDto | WorkingMachineDto | null,
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
    GranitClient.updateWorkingTool(
        workingTool !== undefined ? (workingTool.id as number) : 0,
        workingTool as WorkingToolDto,
    ).then(
        (result) => {
            dispatch(changeCurrentEntity(result));
            dispatch(updateWorkingTool(workingTool as WorkingToolDto, oldWorkingTool));
        },
        (error) => {
            alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_WORKINGTOOLS);
            console.log(error.message);
        },
    );
};
