import { DocumentDto } from '../../AutoGeneratedAPI/clientApi';

export default {
    convertFromRecordDocumentToString: (recordDocuments: DocumentDto[]): string[] => {
        const newArray: string[] = [];
        if (recordDocuments !== undefined && recordDocuments.length > 0) {
            recordDocuments.map((rd) => {
                if (rd.requestPaths !== undefined && rd.requestPaths.length > 0) {
                    rd.requestPaths.map((rp) => newArray.push(rp));
                }
            });
        }
        return newArray;
    },
};
