import React, { ReactElement } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { ExpenseCategory } from '../General/AutoGeneratedAPI/clientApi';
import { ExpenseTypeSR } from '../General/Extensions/Localizations/Types/Expenses/SR/ExpenseTypeSR';
import { SelectChangeEvent } from '@mui/material';
import { ALL_EXP_TYPES } from '../Common/Consts';

type ChartSearchProps = {
    handleExpenseTypeChange?(event: SelectChangeEvent<ExpenseCategory | string | undefined>): void;
    isAllExpensesChart: boolean;
};

export default function ChartSearch({ handleExpenseTypeChange, isAllExpensesChart }: ChartSearchProps): ReactElement {
    return (
        <div
            style={{
                width: '100%',
                margin: 'auto',
                marginTop: 15,
                marginBottom: 0,
            }}
        >
            <Grid container spacing={2}>
                {isAllExpensesChart && handleExpenseTypeChange !== undefined && (
                    <Grid item xs={6}>
                        <FormControl fullWidth required={true}>
                            <InputLabel id="expense-type">Tip troška</InputLabel>
                            <Select
                                labelId="expense-type"
                                label="Tip troška"
                                id="demo-simple-select"
                                defaultValue={ALL_EXP_TYPES}
                                name="expenseType"
                                onChange={handleExpenseTypeChange}
                            >
                                {Object.values(ExpenseCategory).map((et) => {
                                    return (
                                        et != ExpenseCategory.Fuel &&
                                        et != ExpenseCategory.Other &&
                                        et != ExpenseCategory.CalculatorExpense && (
                                            <MenuItem key={et} value={et as ExpenseCategory}>
                                                {ExpenseTypeSR[et as ExpenseCategory]}
                                            </MenuItem>
                                        )
                                    );
                                })}
                                <MenuItem key={ExpenseCategory.Other} value={ExpenseCategory.Other}>
                                    {ExpenseTypeSR[ExpenseCategory.Other]}
                                </MenuItem>
                                <MenuItem key={ALL_EXP_TYPES} value={ALL_EXP_TYPES}>
                                    Svi
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}
