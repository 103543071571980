/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConstructionSiteDto, ProjectDto } from '../../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithDeleting, ExceptionsWithUpdating } from '../../../Extensions/Exceptions';
import {
    deleteProject,
    updateConstructionSite,
    updateProject,
} from '../../Reducers/ConstructionProject/ConstructionProjectReducer';

export const updateOldProject = (project: ProjectDto | undefined) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    GranitClient.updateProject(project !== undefined ? (project.id as number) : 0, project as ProjectDto).then(
        (result) => {
            dispatch(updateProject(result as ProjectDto));
        },
        (error) => {
            alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_PROJECTS);
            console.log(error.message);
        },
    );
};

export const updateOldConstructionSite = (constructionSite: ConstructionSiteDto | undefined) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    GranitClient.updateConstructionSite(
        constructionSite !== undefined ? (constructionSite.id as number) : 0,
        constructionSite as ConstructionSiteDto,
    ).then(
        (result) => {
            dispatch(updateConstructionSite(result as ConstructionSiteDto));
        },
        (error) => {
            alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_CONSTRUCTIONSITES);
            console.log(error.message);
        },
    );
};

export const deleteOldProject = (projectId: number) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
    GranitClient.deleteProject(projectId).then(
        () => {
            dispatch(deleteProject(projectId));
        },
        (error) => {
            alert(ExceptionsWithDeleting.EXCEPTION_DELETING_PROJECT);
            console.log(error.message);
        },
    );
};
