import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import '../TransportWarrantForm.css';
import { RootState } from '../../../Redux/store';
import { useAppSelector } from '../../../hooks/hooks';
import { DriverDto, JobPositionType } from '../../../AutoGeneratedAPI/clientApi';
import GraphDriversCalendarCardContainer from '../../../../Drivers/DriverCalendar/GraphCalendarCard';
import { Person } from '@microsoft/mgt-react';

type DriversFormProps = {
    handleDriverAdding(driver: DriverDto): void;
    currentDrivers: number[];
};

export default function DriversElement({ handleDriverAdding, currentDrivers }: DriversFormProps): ReactElement {
    const drivers = useAppSelector((state: RootState) => state.drivers.drivers ?? []);

    const onClick = (driver: DriverDto): void => {
        handleDriverAdding(driver);
    };
    return (
        <div className="scrollable">
            <Grid container spacing={1} className="tw-grid-container" style={{ justifyContent: 'flex-start' }}>
                {drivers.map((driver) => (
                    <Grid key={driver.id} item xs={12} lg={6}>
                        <a onClick={() => onClick(driver)}>
                            <div
                                style={{
                                    width: 'inherit',
                                    height: 50,
                                    border: currentDrivers?.includes(
                                        driver.jobPositions?.find((jp) => jp.jobPositionType === JobPositionType.Driver)
                                            ?.id as number,
                                    )
                                        ? '1px solid orange'
                                        : '1px solid lightgray',
                                    borderRadius: 5,
                                    cursor: 'pointer',
                                    padding: 4,
                                }}
                            >
                                <Person userId={driver.azObjId}>
                                    <GraphDriversCalendarCardContainer template="default" />
                                </Person>
                            </div>
                        </a>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
