import { Card, Grid, CardContent, Chip } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';
import '../Profile/ProfileCards/ProfileCard.css';
import { getBaseServerLink, GranitClient } from '../AutoGeneratedAPI/Extension';
import { RootState } from '../Redux/store';
import { ShipmentDto, TransportWarrantDto, TruckDto } from '../AutoGeneratedAPI/clientApi';
import truck_logo from '../assets/img/truck_logo.jpg';
import './TransportWarrant.css';
import { CompareTwoDateTimes, FormatDateSpanText, FormatTimeText } from '../../Common/DateHandler';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAppSelector } from '../hooks/hooks';
import { getGraphDriverFromDriver } from '../Extensions/Selectors/Drivers/DriverSelector';
import { GraphUserDto } from '../AutoGeneratedAPI/MSGraph/msGraphApi';
import { getCustomerInvoiceText } from '../../Common/Common';

type TransportWarrantProps = {
    transportWarrant: TransportWarrantDto;
};

export default function TransportWarrant({ transportWarrant }: TransportWarrantProps): ReactElement {
    const [shipments, setShipments] = useState<ShipmentDto[]>([]);
    const drivers = useAppSelector((state: RootState) => state.drivers.drivers);
    const truck = useAppSelector((state: RootState) => state.currentEntity.entity);
    const [graphDrivers, setGraphDrivers] = useState<GraphUserDto[]>([]);

    useEffect(() => {
        if (transportWarrant && transportWarrant.transportWarrantItems) {
            transportWarrant.transportWarrantItems?.map((twi) => {
                if (twi.shipmentId) {
                    GranitClient.getShipment(twi.shipmentId, false).then((ship) => {
                        setShipments((prevState) => [...prevState, ship]);
                    });
                }
            });
        }
    }, [transportWarrant]);

    useEffect(() => {
        if (drivers) {
            transportWarrant.driverJobPositionIds &&
                transportWarrant.driverJobPositionIds.map((dId) => {
                    const drv = drivers?.filter((driver) => {
                        return driver.id === dId;
                    })[0];
                    if (drv !== undefined && drv.azObjId && drv.azObjId !== '') {
                        getGraphDriverFromDriver(drv).then((res) => {
                            setGraphDrivers([...graphDrivers, res]);
                        });
                    }
                });
        }
    }, [drivers]);

    const estCurrDate = new Date(transportWarrant.estStartingTime as Date);
    const estEnDate = new Date(transportWarrant.estEndingTime as Date);
    const estStartingTimeView = FormatTimeText(estCurrDate);
    const estEndingTimeView = FormatTimeText(estEnDate);
    const estDateView = FormatDateSpanText(estCurrDate, estEnDate);

    const currDate = new Date(transportWarrant.actStartingTime as Date);
    const enDate = new Date(transportWarrant.actEndingTime as Date);
    const startingTimeView = FormatTimeText(currDate);
    const endingTimeView = FormatTimeText(enDate);
    const dateView = FormatDateSpanText(currDate, enDate);

    const tr = truck as TruckDto;

    const img =
        tr.documents && tr.documents.length > 0
            ? tr.documents[0].requestPaths && tr.documents[0].requestPaths.length > 0
                ? getBaseServerLink(tr.documents[0].requestPaths[0])
                : truck_logo
            : truck_logo;

    const custInvTexts = getCustomerInvoiceText(shipments);

    return (
        <Card className="tw-container">
            <CardContent>
                <Grid container>
                    <Grid key={transportWarrant.id + ' customer-invoice-text'} item xs={12}>
                        {custInvTexts?.map((cit) => {
                            return (
                                <p style={{ fontSize: '1rem' }} key={cit}>
                                    {cit}
                                </p>
                            );
                        })}
                    </Grid>
                    <Grid
                        key={transportWarrant.id + ' ' + 'predictedTime'}
                        item
                        xs={12}
                        style={{ marginTop: 10, marginBottom: 10 }}
                    >
                        <Chip label="Predv." color="info" size="small" style={{ position: 'relative', top: -3 }} />

                        <p
                            style={{
                                display: 'inline-block',
                                marginLeft: 10,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: '1rem',
                            }}
                        >
                            {estDateView} {estStartingTimeView} - {estEndingTimeView}
                        </p>
                    </Grid>
                    <Grid
                        key={transportWarrant.id + ' ' + 'isTwFinished'}
                        item
                        xs={12}
                        style={{ marginTop: 5, marginBottom: 20 }}
                    >
                        {transportWarrant.isTwFinished ? (
                            <Chip
                                label="Završen"
                                color={CompareTwoDateTimes(enDate, estEnDate, true) ? 'success' : 'warning'}
                                size="small"
                                style={{ position: 'relative', top: -3 }}
                            />
                        ) : transportWarrant.estEndingTime < new Date() ? (
                            <Chip
                                label="Istekao"
                                color="error"
                                size="small"
                                style={{ position: 'relative', top: -3 }}
                            />
                        ) : (
                            <Chip label="Sledi" color="info" size="small" style={{ position: 'relative', top: -3 }} />
                        )}
                        <p
                            style={{
                                display: 'inline-block',
                                marginLeft: 10,
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: '1rem',
                            }}
                        >
                            {dateView} {startingTimeView} - {endingTimeView}
                        </p>
                    </Grid>
                </Grid>
                <hr></hr>
                <Grid container spacing={1}>
                    <Grid key={transportWarrant.id + ' ' + 'mainVehicle'} item xs={8}>
                        <p style={{ marginTop: 0, marginBottom: 0, fontSize: '1rem' }}>
                            {(truck as TruckDto).manufacturer +
                                ' ' +
                                (truck as TruckDto).model +
                                ((truck as TruckDto).licensePlateNum ? ', ' + (truck as TruckDto).licensePlateNum : '')}
                        </p>
                        {graphDrivers.map((drv) => {
                            if (drv !== undefined) {
                                return (
                                    <p
                                        key={drv.id + (drv.givenName ?? '')}
                                        style={{ marginTop: 10, marginBottom: 10, fontSize: '1rem' }}
                                    >
                                        {drv.givenName}
                                    </p>
                                );
                            }
                        })}
                    </Grid>
                    <Grid key={transportWarrant.id + 'km:' + transportWarrant.startingKm} item xs={4}>
                        <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={img} />
                    </Grid>
                    <Grid item xs={12}>
                        <p style={{ marginTop: 5, marginBottom: 0, fontSize: '1.1rem' }}>
                            {transportWarrant.startingKm}km - {transportWarrant.endingKm}km
                        </p>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
