import React, { ReactElement, useState } from 'react';
import { Box, LinearProgress, linearProgressClasses, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ShipmentDto } from '../../General/AutoGeneratedAPI/clientApi';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 10,
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 10,
    },
}));

type TWOccupancyDetailsProps = {
    shipments: ShipmentDto[];
    loadWeightCapacity: number;
    loadVolumeCapacity: number;
    loadPalletSpacesCapacity: number;
};

export default function TWOccupancyDetails({
    shipments,
    loadWeightCapacity,
    loadVolumeCapacity,
    loadPalletSpacesCapacity,
}: TWOccupancyDetailsProps): ReactElement {
    const [hoveredBarIndex, setHoveredBarIndex] = useState<number>(-1);

    const handleSetHoveredBarIndex = (index: number) => {
        setHoveredBarIndex(index);
    };

    const shipmentLoadWeight = shipments.reduce((acc, shipment) => {
        return acc + shipment.loadWeight;
    }, 0);
    const shipmentLoadVolume = shipments.reduce((acc, shipment) => {
        return acc + shipment.loadVolume;
    }, 0);
    const shipmentLoadPalletSpaces = shipments.reduce((acc, shipment) => {
        return acc + shipment.loadPalletSpaces;
    }, 0);

    const shipLoadWeightOccupancy = Math.floor((100 * shipmentLoadWeight) / loadWeightCapacity);
    const shipLoadVolumeOccupancy = Math.floor((100 * shipmentLoadVolume) / loadVolumeCapacity);
    const shipLoadPalletSpacesOccupancy = Math.floor((100 * shipmentLoadPalletSpaces) / loadPalletSpacesCapacity);

    const colorGenerator = (percentage: number): 'success' | 'primary' | 'warning' | 'error' => {
        if (percentage <= 60) {
            return 'primary';
        }
        if (percentage <= 85) {
            return 'warning';
        }
        if (percentage <= 100) {
            return 'error';
        }
        return 'error';
    };

    return (
        <div>
            {loadWeightCapacity ? (
                <div
                    style={{ position: 'relative', marginTop: 10 }}
                    onMouseOver={() => handleSetHoveredBarIndex(0)}
                    onMouseOut={() => handleSetHoveredBarIndex(-1)}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <BorderLinearProgress
                            variant="buffer"
                            color={colorGenerator(shipLoadWeightOccupancy)}
                            value={shipLoadWeightOccupancy <= 100 ? shipLoadWeightOccupancy : 100}
                            valueBuffer={100}
                            style={{ height: hoveredBarIndex === 0 ? 20 : 12, transition: 'height .5s ease-in-out' }}
                        />
                    </Box>
                    <Typography
                        component="p"
                        variant="body2"
                        style={{
                            position: 'absolute',
                            color: 'white',
                            top: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontSize: hoveredBarIndex === 0 ? 15 : 10,
                            transition: 'font-size .5s ease-in-out',
                        }}
                    >
                        {isNaN(shipLoadWeightOccupancy) ? 0 : shipLoadWeightOccupancy}% ({shipmentLoadWeight}/
                        {loadWeightCapacity} kg)
                    </Typography>
                </div>
            ) : (
                <></>
            )}
            {loadVolumeCapacity ? (
                <div
                    style={{ position: 'relative', marginTop: 10 }}
                    onMouseOver={() => handleSetHoveredBarIndex(1)}
                    onMouseOut={() => handleSetHoveredBarIndex(-1)}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <BorderLinearProgress
                            variant="buffer"
                            color={colorGenerator(shipLoadVolumeOccupancy)}
                            value={shipLoadVolumeOccupancy <= 100 ? shipLoadVolumeOccupancy : 100}
                            valueBuffer={100}
                            style={{ height: hoveredBarIndex === 1 ? 20 : 12, transition: 'height .5s ease-in-out' }}
                        />
                        <Typography
                            component="p"
                            variant="body2"
                            style={{
                                position: 'absolute',
                                color: 'white',
                                top: 0,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                fontSize: hoveredBarIndex === 1 ? 15 : 10,
                                transition: 'font-size .5s ease-in-out',
                            }}
                        >
                            {isNaN(shipLoadVolumeOccupancy) ? 0 : shipLoadVolumeOccupancy}% ({shipmentLoadVolume}/
                            {loadVolumeCapacity} m&sup3;)
                        </Typography>
                    </Box>
                </div>
            ) : (
                <></>
            )}
            {loadPalletSpacesCapacity ? (
                <div
                    style={{ position: 'relative', marginTop: 10 }}
                    onMouseOver={() => handleSetHoveredBarIndex(2)}
                    onMouseOut={() => handleSetHoveredBarIndex(-1)}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <BorderLinearProgress
                            variant="buffer"
                            color={colorGenerator(shipLoadPalletSpacesOccupancy)}
                            value={shipLoadPalletSpacesOccupancy <= 100 ? shipLoadPalletSpacesOccupancy : 100}
                            valueBuffer={100}
                            style={{ height: hoveredBarIndex === 2 ? 20 : 12, transition: 'height .5s ease-in-out' }}
                        />
                        <Typography
                            component="p"
                            variant="body2"
                            style={{
                                position: 'absolute',
                                color: 'white',
                                top: 0,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                fontSize: hoveredBarIndex === 2 ? 15 : 10,
                                transition: 'font-size .5s ease-in-out',
                            }}
                        >
                            {isNaN(shipLoadPalletSpacesOccupancy) ? 0 : shipLoadPalletSpacesOccupancy}% (
                            {shipmentLoadPalletSpaces}/{loadPalletSpacesCapacity} pal.)
                        </Typography>
                    </Box>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
