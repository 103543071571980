import { CompareTwoDateTimes } from '../../../Common/DateHandler';
import { StopDto, TrailerDto, TransportWarrantDto, TruckDto } from '../../AutoGeneratedAPI/clientApi';
import { calculateRoutesDistancesDiff } from '../CalendarTWSideDetails/CalendarTWSideDetailsHelper';
import { SortingDirection } from './ShipmentLoadingCommon';

/**
 * Sorts array of transport warrants by loading capacity of the vehicles on the transport warrant
 * minus load parameters of shipments on the transport warrant in sortDirection order
 * @param transportWarrants TransportWarrantDto[]
 * @param sortDirection SortDirection (asc/desc)
 * @returns sorted transportWarrants
 */
export const sortTwsBasedOnLoadingCapacityLeft = (
    transportWarrants: TransportWarrantDto[],
    sortDirection: SortingDirection,
    trucks: TruckDto[],
    trailers: TrailerDto[],
): TransportWarrantDto[] => {
    return transportWarrants.sort((a, b) => {
        const truckA = trucks?.find((t) => t.id === a.composition.mainVehicleId);
        const truckB = trucks?.find((t) => t.id === b.composition.mainVehicleId);
        const shipmentsA = a.transportWarrantItems?.map((twi) => twi.shipment);
        const shipmentsB = b.transportWarrantItems?.map((twi) => twi.shipment);
        const currTrailersA = trailers?.filter((tr) => a?.composition?.trailersIds?.includes(tr.id));
        const currTrailersB = trailers?.filter((tr) => b?.composition?.trailersIds?.includes(tr.id));

        const shipmentsALoad = shipmentsA?.reduce((acc, sh) => {
            return acc + sh.loadWeight;
        }, 0);

        const shipmentsBLoad = shipmentsB?.reduce((acc, sh) => {
            return acc + sh.loadWeight;
        }, 0);

        const twLoadingCapacityA =
            (truckA?.loadWeight ?? 0) +
            (currTrailersA?.reduce((acc, tr) => {
                return acc + tr.loadWeight;
            }, 0) ?? 0);

        const twLoadingCapacityB =
            (truckB?.loadWeight ?? 0) +
            (currTrailersB?.reduce((acc, tr) => {
                return acc + tr.loadWeight;
            }, 0) ?? 0);

        return twLoadingCapacityA - (shipmentsALoad ?? 0) - (twLoadingCapacityB - (shipmentsBLoad ?? 0)) < 0
            ? sortDirection === SortingDirection.Ascending
                ? -1
                : 1
            : sortDirection === SortingDirection.Ascending
            ? 1
            : -1;
    });
};

/**
 * Sorts array of transport warrants by calculating the difference between distances of existing stops
 * and newStop. Calculation is done with lat/lng data
 * @param transportWarrants TransportWarrantDto[]
 * @param sortDirection SortDirection (asc/desc)
 * @param newStop StopDto
 * @returns sorted transportWarrants
 */
export const sortTwsBasedOnDistance = (
    transportWarrants: TransportWarrantDto[],
    sortDirection: SortingDirection,
    newStop?: StopDto,
): TransportWarrantDto[] => {
    if (!newStop) {
        return transportWarrants;
    }

    return transportWarrants.sort((a, b) => {
        const aStops = a.transportWarrantItems?.map((twi) => twi.shipment.endingStop);
        const bStops = b.transportWarrantItems?.map((twi) => twi.shipment.endingStop);

        // Calculate differences for every route
        const aRoutesDistancesDiff = calculateRoutesDistancesDiff(aStops, newStop);
        const bRoutesDistancesDiff = calculateRoutesDistancesDiff(bStops, newStop);

        // Smallest distance difference
        const aSmallest = aRoutesDistancesDiff.sort((a, b) => {
            return a.value - b.value < 0 ? -1 : 1;
        })[0];
        const bSmallest = bRoutesDistancesDiff.sort((a, b) => {
            return a.value - b.value < 0 ? -1 : 1;
        })[0];

        // Return smallest difference and indexes of shipments inbetween which to insert newShipment
        return sortDirection === SortingDirection.Ascending
            ? aSmallest.value - bSmallest.value < 0
                ? -1
                : 1
            : aSmallest.value - bSmallest.value < 0
            ? 1
            : -1;
    });
};

/**
 * Sorts array of transport warrants by their starting time in sortDirection order
 * @param transportWarrants TransportWarrantDto[]
 * @param sortDirection SortDirection (asc/desc)
 * @returns sorted transportWarrants
 */
export const sortTwsBasedOnStartingTime = (
    transportWarrants: TransportWarrantDto[],
    sortDirection: SortingDirection,
): TransportWarrantDto[] => {
    return transportWarrants.sort((a, b) => {
        return sortDirection === SortingDirection.Ascending
            ? CompareTwoDateTimes(a.actStartingTime, b.actStartingTime, false)
                ? -1
                : 1
            : CompareTwoDateTimes(a.actStartingTime, b.actStartingTime, false)
            ? 1
            : -1;
    });
};
