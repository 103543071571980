/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    AuthenticatedDto,
    EmployeeInfoDto,
    EmployeeDto,
    UserGroupDto,
    UserGroupType,
} from '../../../AutoGeneratedAPI/clientApi';
import { GranitClient, GranitVNextClient } from '../../../AutoGeneratedAPI/Extension';
import { myConsoleLog } from '../../../../Common/Console';
import { RootState } from '../../store';
import { LoggedEmployeeState } from '../../Reducers/Login/EmployeeReducer';
import { GraphUserDto } from '../../../AutoGeneratedAPI/MSGraph/msGraphApi';
import { GraphClient } from '../../../AutoGeneratedAPI/MSGraph/MSGraphExtensions';

export const logoutEmployee = createAsyncThunk<void, void>('employee/logout', async () => {
    console.log();
});

export const getUserGroup = createAsyncThunk(
    'employee/getUserGroup',
    async (arg, thunkAPI: { getState: any }) => {
        const emp = (thunkAPI.getState() as RootState).loggedEmployee.employee?.employee;

        return GranitClient.getSingleEmployeeUserGroup(emp?.id ?? 0);
    },
    {
        condition: (arg: void, thunkAPI: { getState: any }) => {
            const userGroup = (thunkAPI.getState() as RootState).loggedEmployee.userGroup;
            const empId = (thunkAPI.getState() as RootState).loggedEmployee.employee?.employee.id;

            return userGroup === null && empId !== undefined && empId > 0;
        },
    },
);

export const createUserGroup = createAsyncThunk(
    'employee/createUserGroup',
    async (arg, thunkAPI: { getState: any }) => {
        const emp = (thunkAPI.getState() as RootState).loggedEmployee.employee?.employee;

        const newUserGroup = new UserGroupDto({
            ...new UserGroupDto(),
            membersIds: [emp?.id ?? 0],
            name: emp?.azObjId,
            userGroupType: UserGroupType.Predefined,
        });

        return GranitClient.addUserGroup(newUserGroup);
    },
    {
        condition: (arg: void, thunkAPI: { getState: any }) => {
            const userGroup = (thunkAPI.getState() as RootState).loggedEmployee.userGroup;
            const empId = (thunkAPI.getState() as RootState).loggedEmployee.employee?.employee.id;

            return userGroup === undefined && empId !== undefined && empId > 0;
        },
    },
);

export const loginEmployee = createAsyncThunk<AuthenticatedDto, void>(
    'employee/login',
    async (thunkAPI) => {
        myConsoleLog('LogginIn employee!!!');
        return GranitVNextClient.oldAuthenticate();
    },
    {
        condition: (arg: void, thunkAPI: { getState: any }) => {
            const employeeState: LoggedEmployeeState = (thunkAPI.getState() as RootState).loggedEmployee;
            const employeeInfo: EmployeeInfoDto | null = employeeState.employee;
            const expirationDate: Date | null = employeeState.expirationDate;
            const token: Date | null = employeeState.token ? new Date(employeeState.token) : null;

            /*if(employeeState.logginStatus === LoginStatus.InProgress) {
          return false;
        }*/

            if (employeeInfo === null || token === null || expirationDate === null || expirationDate <= new Date()) {
                return true;
            }

            return false;

            //return employeeInfo.employee.employeeAccount.username !== employeeAccount.username;
        },
    },
);

export const getEmployeeData = createAsyncThunk<GraphUserDto, void>(
    'employee/getEmployeeData',
    async () => {
        return GraphClient.getMe();
    },
    {
        condition: (arg: void, thunkAPI: { getState: any }) => {
            const employeeState: LoggedEmployeeState = (thunkAPI.getState() as RootState).loggedEmployee;
            const employeeInfo: GraphUserDto | null = employeeState.basicEmployee;

            return employeeInfo === null;
        },
    },
);

export const getEmployeeDataExtended = createAsyncThunk<EmployeeInfoDto, void>(
    'employee/getEmployeeDataExtended',
    async () => {
        return GranitClient.getMyInfo();
    },
    {
        condition: (arg: void, thunkAPI: { getState: any }) => {
            const employeeState: LoggedEmployeeState = (thunkAPI.getState() as RootState).loggedEmployee;
            const employeeInfo: EmployeeInfoDto | null = employeeState.employee;

            return employeeInfo === null;
        },
    },
);

export const importEmployees = createAsyncThunk<EmployeeDto[], void>('employees/importEmployees', async () => {
    return GranitClient.getAllEmployees(true);
});
