import {
    Grid,
    InputAdornment,
    FormControl,
    InputLabel,
    Fab,
    TextField,
    Select,
    MenuItem,
    Button,
    SelectChangeEvent,
    Stack,
} from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import React, { ChangeEvent, ReactElement } from 'react';
import { FileIcon } from 'react-file-icon';
import { useLocation } from 'react-router-dom';
import {
    ExpenseCategory,
    IVehicleExpenseDto,
    IWorkingToolExpenseDto,
    RecordDocumentDto,
} from '../../AutoGeneratedAPI/clientApi';
import { getBaseServerLink } from '../../AutoGeneratedAPI/Extension';
import { ExpenseTypeSR } from '../../Extensions/Localizations/Types/Expenses/SR/ExpenseTypeSR';
import { isAppTypeChart, isAppTypeVehicle } from '../../Extensions/Selectors/Selectors';
import { durationError, isExpenseCategoryFuel } from './ExpenseFormHelper';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { formatNumberTypeInput } from '../../../Common/Common';

type ExpenseFormElementProps = {
    currentExpense?: IVehicleExpenseDto | IWorkingToolExpenseDto;
    selectedFiles: Array<File>;
    handleInputElementChange(event: ChangeEvent<HTMLInputElement>): void;
    handleExpenseTypeChange(event: SelectChangeEvent<ExpenseCategory>): void;
    handleExpTypeInputElementChange(event: ChangeEvent<HTMLInputElement>): void;
    handleValidFromChange(date?: Date): void;
    handleFileInputChange(selectorFiles: FileList | undefined): void;
    handleFileDelete(fileToDelete: File): void;
    deleteFile(file: RecordDocumentDto): void;
    children: any;
};

export default function ExpenseFormElement({
    currentExpense,
    selectedFiles,
    handleInputElementChange,
    handleExpenseTypeChange,
    handleExpTypeInputElementChange,
    handleValidFromChange,
    handleFileInputChange,
    handleFileDelete,
    deleteFile,
    children,
}: ExpenseFormElementProps): ReactElement {
    const isExpCategoryFuel = isExpenseCategoryFuel(currentExpense);

    const location = useLocation();
    const path = location.pathname;

    const isVehicle = isAppTypeVehicle(path);
    const isCalculator = isAppTypeChart(path);

    return (
        <>
            <Grid key={12} container spacing={4}>
                <Grid key={148} item xs={12} md={6}>
                    <TextField
                        fullWidth
                        id="filled-basic"
                        label="Naslov"
                        error={!currentExpense?.title}
                        name="title"
                        variant="outlined"
                        value={currentExpense?.title ?? ''}
                        onChange={handleInputElementChange}
                    />
                </Grid>
                <Grid key={3} item xs={6}>
                    <TextField
                        fullWidth
                        id="filled-basic"
                        error={(currentExpense?.price as number) > 0 ? false : true}
                        required={true}
                        label="Cena"
                        name="price"
                        variant="outlined"
                        value={formatNumberTypeInput(currentExpense?.price)}
                        onChange={handleInputElementChange}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">din.</InputAdornment>,
                            inputProps: { min: 0 },
                        }}
                    />
                </Grid>
                {!isCalculator && (
                    <Grid key={1516} item xs={12} md={6}>
                        <FormControl fullWidth required={true} error={!currentExpense?.expenseType}>
                            <InputLabel id="expense-type">Tip troška</InputLabel>
                            <Select
                                labelId="expense-type"
                                label="Tip troška"
                                id="demo-simple-select"
                                error={
                                    currentExpense && currentExpense.expenseType
                                        ? !currentExpense?.expenseType?.expenseCategory
                                        : true
                                }
                                name="expenseCategory"
                                value={
                                    currentExpense && currentExpense.expenseType
                                        ? currentExpense.expenseType.expenseCategory
                                        : ''
                                }
                                onChange={handleExpenseTypeChange}
                            >
                                {Object.values(ExpenseCategory).map((et) => {
                                    return (
                                        et != ExpenseCategory.Other &&
                                        et != ExpenseCategory.CalculatorExpense && (
                                            <MenuItem key={et} value={et as ExpenseCategory}>
                                                {ExpenseTypeSR[et as ExpenseCategory]}
                                            </MenuItem>
                                        )
                                    );
                                })}
                                <MenuItem key={ExpenseCategory.Other} value={ExpenseCategory.Other}>
                                    {ExpenseTypeSR[ExpenseCategory.Other]}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                {currentExpense?.expenseType && currentExpense?.expenseType.expenseCategory === ExpenseCategory.Fuel ? (
                    <Grid key={'empty'} item xs={0} md={6}></Grid>
                ) : (
                    !isCalculator && (
                        <Grid key={'podtip troška'} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                id="filled-basic"
                                label={'Podtip troška'}
                                variant="outlined"
                                name="expenseSubCategory"
                                value={
                                    currentExpense && currentExpense.expenseType
                                        ? currentExpense.expenseType.expenseSubCategory
                                        : ''
                                }
                                onChange={handleExpTypeInputElementChange}
                            />
                        </Grid>
                    )
                )}
                {!isCalculator && (
                    <Grid key={1794} item xs={12} md={6}>
                        <TextField
                            fullWidth
                            id="filled-basic"
                            error={
                                !currentExpense?.expenseType ||
                                currentExpense?.expenseType.expenseCategory !== ExpenseCategory.Fuel ||
                                (currentExpense?.expenseKm as number) > 0
                                    ? false
                                    : true
                            }
                            required={isExpCategoryFuel}
                            label={isVehicle ? (isExpCategoryFuel ? 'Km sipanja' : 'Km troška') : 'Rh sipanja'}
                            variant="outlined"
                            name="expenseKm"
                            value={formatNumberTypeInput(currentExpense?.expenseKm)}
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                inputProps: { min: 0 },
                            }}
                            onChange={handleInputElementChange}
                        />
                    </Grid>
                )}
                {!isCalculator && <Grid key={'empty2'} item xs={12} md={6}></Grid>}
                {!currentExpense || !currentExpense.expenseType ? (
                    <></>
                ) : isExpCategoryFuel ? (
                    <>
                        <Grid key={19879} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                id="filled-basic"
                                error={(currentExpense?.liters as number) > 0 ? false : true}
                                required={true}
                                label="Litraža"
                                name="liters"
                                variant="outlined"
                                value={formatNumberTypeInput(currentExpense?.liters)}
                                onChange={handleInputElementChange}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">L</InputAdornment>,
                                    inputProps: { min: 0 },
                                }}
                            />
                        </Grid>
                        <Grid key={1784} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                id="filled-basic"
                                error={(currentExpense?.pricePerLiter as number) > 0 ? false : true}
                                required={true}
                                label="Cena po litru"
                                variant="outlined"
                                name="pricePerLiter"
                                value={formatNumberTypeInput(currentExpense?.pricePerLiter)}
                                onChange={handleInputElementChange}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">din.</InputAdornment>,
                                    inputProps: { min: 0 },
                                }}
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid key={178} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                id="filled-basic"
                                error={durationError(currentExpense)}
                                required={true}
                                label="Pretp. trajanje u Km"
                                variant="outlined"
                                name="durationInKm"
                                value={
                                    currentExpense && currentExpense.expenseType
                                        ? formatNumberTypeInput(currentExpense.expenseType.durationInKm)
                                        : ''
                                }
                                onChange={handleExpTypeInputElementChange}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                    inputProps: { min: 0 },
                                }}
                            />
                        </Grid>
                        <Grid key={179} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                id="filled-basic"
                                error={durationError(currentExpense)}
                                required={true}
                                label={'Pretp. trajanje u mesecima'}
                                variant="outlined"
                                name="durationInMonths"
                                value={
                                    currentExpense && currentExpense.expenseType
                                        ? formatNumberTypeInput(currentExpense.expenseType.durationInMonths)
                                        : ''
                                }
                                onChange={handleExpTypeInputElementChange}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mes.</InputAdornment>,
                                    inputProps: { min: 0 },
                                }}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Datum važenja"
                                disableMaskedInput
                                value={currentExpense?.validFrom ?? new Date()}
                                inputFormat="DD/MM/YYYY"
                                minDate={dayjs('1940-01-01')}
                                onChange={(newValue) => {
                                    handleValidFromChange(newValue?.toDate());
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                {!isCalculator && children}
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="filled-basic"
                        label="Opis"
                        variant="outlined"
                        multiline
                        rows={6}
                        value={currentExpense?.description ?? ''}
                        name="description"
                        onChange={handleInputElementChange}
                    />
                </Grid>
                {!isCalculator && (
                    <Grid key={16} item xs={2}>
                        <div style={{ marginBottom: 15 }}>
                            <Button fullWidth size="large" variant="outlined" component="label" style={{ height: 50 }}>
                                <AttachFileIcon />
                                <input
                                    type="file"
                                    multiple={selectedFiles.length <= 0 ? true : false}
                                    onChange={(e) => handleFileInputChange(e.target.files as FileList)}
                                    hidden
                                />
                            </Button>
                        </div>
                    </Grid>
                )}
                {!isCalculator && (
                    <Grid key={72} item xs={10}>
                        {currentExpense?.documents !== undefined ? (
                            currentExpense?.documents.map((file) =>
                                file.requestPaths !== undefined ? (
                                    file.requestPaths[0].endsWith('.jpg') || file.requestPaths[0].endsWith('.png') ? (
                                        <div
                                            key={file.requestPaths[0]}
                                            style={{
                                                display: 'inline',
                                                height: 100,
                                                width: 'auto',
                                                position: 'relative',
                                            }}
                                        >
                                            <img
                                                style={{ display: 'inline', height: 100, width: 'auto' }}
                                                src={getBaseServerLink(file.requestPaths[0])}
                                            />
                                            <Fab
                                                style={{
                                                    position: 'absolute',
                                                    right: 0,
                                                    bottom: 70,
                                                    width: 40,
                                                    height: 40,
                                                }}
                                                onClick={() => deleteFile(file as RecordDocumentDto)}
                                                color="secondary"
                                                aria-label="exit"
                                            >
                                                <CloseIcon />
                                            </Fab>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                ) : (
                                    <></>
                                ),
                            )
                        ) : (
                            <div></div>
                        )}
                    </Grid>
                )}
                {!isCalculator ? (
                    currentExpense?.documents !== undefined ? (
                        currentExpense?.documents.map((file) =>
                            file.requestPaths !== undefined ? (
                                !file.requestPaths[0].endsWith('.jpg') && !file.requestPaths[0].endsWith('.png') ? (
                                    <Grid key={file.id} item xs={2}>
                                        <span
                                            style={{
                                                display: 'inline',
                                                height: 100,
                                                width: 200,
                                                position: 'relative',
                                            }}
                                        >
                                            <span className="file-name-form">
                                                <p style={{ height: 'auto' }}>
                                                    {file.requestPaths[0].slice(
                                                        file.requestPaths[0].lastIndexOf('/') + 1,
                                                    )}
                                                </p>
                                            </span>
                                            <FileIcon
                                                extension={file.requestPaths[0].slice(
                                                    file.requestPaths[0].lastIndexOf('.'),
                                                )}
                                                labelColor={
                                                    file.requestPaths[0].endsWith('.pdf')
                                                        ? 'rgb(221, 68, 41)'
                                                        : file.requestPaths[0].endsWith('.docx')
                                                        ? 'rgb(0, 103, 199)'
                                                        : file.requestPaths[0].endsWith('.xlsx') ||
                                                          file.requestPaths[0].endsWith('.xlsm') ||
                                                          file.requestPaths[0].endsWith('.xls')
                                                        ? 'rgb(9, 133, 75)'
                                                        : 'default'
                                                }
                                            />
                                            <Fab
                                                style={{
                                                    position: 'absolute',
                                                    right: 0,
                                                    bottom: 20,
                                                    width: 40,
                                                    height: 40,
                                                }}
                                                onClick={() => deleteFile(file as RecordDocumentDto)}
                                                color="secondary"
                                                aria-label="exit"
                                            >
                                                <CloseIcon />
                                            </Fab>
                                        </span>
                                    </Grid>
                                ) : (
                                    <></>
                                )
                            ) : (
                                <></>
                            ),
                        )
                    ) : (
                        <></>
                    )
                ) : (
                    <></>
                )}

                {!isCalculator && (
                    <Grid key={28} item xs={12}>
                        {selectedFiles !== undefined ? (
                            Array.from(selectedFiles).map((file) =>
                                file.name.endsWith('.jpg') || file.name.endsWith('.png') ? (
                                    <div
                                        key={file.name}
                                        style={{ display: 'inline', height: 100, width: 'auto', position: 'relative' }}
                                    >
                                        <img
                                            style={{ display: 'inline', height: 100, width: 'auto' }}
                                            src={URL.createObjectURL(file)}
                                        />
                                        <Fab
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                bottom: 70,
                                                width: 40,
                                                height: 40,
                                            }}
                                            onClick={() => handleFileDelete(file)}
                                            color="secondary"
                                            aria-label="exit"
                                        >
                                            <CloseIcon />
                                        </Fab>
                                    </div>
                                ) : (
                                    <></>
                                ),
                            )
                        ) : (
                            <div></div>
                        )}
                    </Grid>
                )}
                {!isCalculator ? (
                    selectedFiles !== undefined ? (
                        Array.from(selectedFiles).map((file) =>
                            !file.name.endsWith('.jpg') && !file.name.endsWith('.png') ? (
                                <Grid key={file.name} item xs={2}>
                                    <span style={{ display: 'inline', height: 100, width: 200, position: 'relative' }}>
                                        <FileIcon
                                            extension={file.name.slice(file.name.lastIndexOf('.'))}
                                            labelColor={
                                                file.name.endsWith('.pdf')
                                                    ? 'rgb(221, 68, 41)'
                                                    : file.name.endsWith('.docx')
                                                    ? 'rgb(0, 103, 199)'
                                                    : file.name.endsWith('.xlsx') ||
                                                      file.name.endsWith('.xlsm') ||
                                                      file.name.endsWith('.xls')
                                                    ? 'rgb(9, 133, 75)'
                                                    : 'default'
                                            }
                                        />
                                        <Fab
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                bottom: 20,
                                                width: 40,
                                                height: 40,
                                            }}
                                            onClick={() => handleFileDelete(file)}
                                            color="secondary"
                                            aria-label="exit"
                                        >
                                            <CloseIcon />
                                        </Fab>
                                    </span>
                                </Grid>
                            ) : (
                                <></>
                            ),
                        )
                    ) : (
                        <div></div>
                    )
                ) : (
                    <></>
                )}
            </Grid>
        </>
    );
}
