import { DriverDto } from '../../../AutoGeneratedAPI/clientApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithFetching } from '../../../Extensions/Exceptions';
import { CompleteUserInfoDto } from '../../../AutoGeneratedAPI/MSGraph/msGraphApi';

interface IInitialStateType {
    drivers: DriverDto[] | null;
    graphDrivers: CompleteUserInfoDto[] | null;
}

const initialState: IInitialStateType = {
    drivers: null,
    graphDrivers: null,
};

export const fetchInitialDrivers = createAsyncThunk(
    'drivers/fetchInitialDrivers',
    async () => {
        const response = await GranitClient.getAllDrivers();
        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingDrivers: DriverDto[] | null = thunkAPI.getState().drivers.drivers;

            return existingDrivers === null;
        },
    },
);

const driversSlice = createSlice({
    name: 'drivers',
    initialState,
    reducers: {
        addGraphDriver(state, action) {
            state.graphDrivers = [...(state.graphDrivers ?? []), action.payload];
        },
        updateDriver: {
            reducer(state, action) {
                const { driver, oldDriver } = action.payload;
                const drivers = state.drivers ?? [];
                const driverIndex = drivers.findIndex((d) => {
                    return d.id === oldDriver.id;
                });
                state.drivers = [...drivers.slice(0, driverIndex), driver, ...drivers.slice(driverIndex + 1)];
            },
            prepare(driver, oldDriver) {
                return {
                    payload: { driver, oldDriver },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInitialDrivers.fulfilled, (state, action) => {
            state.drivers = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialDrivers.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_DRIVERS);
        });
    },
});

export const { addGraphDriver, updateDriver } = driversSlice.actions;

export default driversSlice.reducer;
