import { Libraries } from '@react-google-maps/api';
import { ShipmentDto } from '../General/AutoGeneratedAPI/clientApi';

export const getPhotoUrl = (imgUrl: Blob | MediaSource | undefined): string => {
    if (imgUrl) {
        const urlObject = URL.createObjectURL(imgUrl);
        return urlObject;
    }

    return '';
};

export const numberFormatter = new Intl.NumberFormat('en-US');

export const getCustomerInvoiceText = (shipments: ShipmentDto[]): string[] => {
    if (shipments.length <= 0) {
        return [''];
    }

    let dataText: string[] = [];

    let clearShipments: ShipmentDto[] = [];

    shipments.map((sh) => {
        if (clearShipments.findIndex((cs) => cs.id === sh.id) < 0) {
            clearShipments = clearShipments.concat(sh);
        }
    });

    clearShipments.map((cs) => {
        let fullText = '';

        fullText = fullText.concat((cs.data?.clientName ?? '') + ' ' + cs.data?.invoiceCode);

        dataText = dataText.concat(fullText);
    });

    return dataText;
};

export const warehouseAddress = {
    address: 'Mlavska bb, Kragujevac, Srbija',
    coordinates: { lat: 44.034827, lng: 20.90273 },
};

export const armatureAddress = {
    address: 'Mlavska bb, Kragujevac, Srbija',
    coordinates: { lat: 44.035903, lng: 20.901507 },
};

export const formatNumberTypeInput = (inputValue?: number): string => {
    return inputValue && inputValue !== 0 ? inputValue.toString().replace(/^0+/, '') : '0';
};

/**
 * Gets the percentage based on totalValue and partialValue
 * @param totalValue 100% value
 * @param partialValue part of the value
 */
export const getPercentage = (totalValue: number, partialValue: number): number => {
    if (totalValue === 0) {
        return 100;
    }
    if (partialValue === 0) {
        return 0;
    }
    const percentage = (partialValue / totalValue) * 100;

    return percentage;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const checkIfAnyIsNumber = (numberWannabe: any): number => {
    return numberWannabe !== undefined && !isNaN(+numberWannabe) ? numberWannabe : 0;
};

export const GOOGLE_MAPS_API_LIBRARIES: Libraries = ['places'];

export type LatLngLiteral = google.maps.LatLngLiteral;
export type LatLngBounds = google.maps.LatLngBounds;
export type DirectionsResult = google.maps.DirectionsResult;
export type MapOptions = google.maps.MapOptions;
export type DirectionsRendererOptions = google.maps.DirectionsRendererOptions;

export type DistanceDuration = { index: number; value: number };
