import { Button, Typography } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { LoaderProgress } from '../../Common/CommonComponents/Loader/LoaderProgress';
import { FormatDateText } from '../../Common/DateHandler';
import { VehicleExpenseDto } from '../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../AutoGeneratedAPI/Extension';
import { ExpenseEntityType } from '../Extensions/Entities';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import DeleteRecModal from '../Profile/DeleteRecModal';
import { deleteVehicleExpense } from '../Redux/Actions/General/ExpensesAction';
import { RootState } from '../Redux/store';

type ChartExpenseSideDetailsProps = {
    closeChartExpenseSideDetails(): void;
    openChartExpenseForm(): void;
    expId: number;
};

export default function ChartExpenseSideDetails({
    closeChartExpenseSideDetails,
    openChartExpenseForm,
    expId,
}: ChartExpenseSideDetailsProps): ReactElement {
    const expenses = useAppSelector((state: RootState) => state.expenses.expenses);

    const [expense, setExpense] = useState<VehicleExpenseDto | undefined>(undefined);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (expenses && expId && !isNaN(+expId)) {
            const exp = expenses?.find((exp) => exp.id === +expId);
            if (exp) {
                setExpense(exp ? (exp as VehicleExpenseDto) : undefined);
            } else {
                GranitClient.getVehicleExpenseByExpId(+expId).then((res) => setExpense(res));
            }
        } else if (expId && !isNaN(+expId)) {
            GranitClient.getVehicleExpenseByExpId(+expId).then((res) => setExpense(res));
        }
    }, [expId, expenses]);

    const deleteExp = (): void => {
        if (expense && expense.id) {
            dispatch(deleteVehicleExpense(expense.id, expense as ExpenseEntityType));
            closeChartExpenseSideDetails();
        }
    };

    const handleOpenDeleteModal = (): void => {
        setOpenDeleteModal(true);
    };

    const handleCloseDeleteModal = (): void => {
        setOpenDeleteModal(false);
    };

    const expDurationTextFormat = (): string => {
        const exp = expense as VehicleExpenseDto;

        return !exp || !exp.expenseType
            ? ''
            : (!exp.expenseType.durationInKm || exp.expenseType.durationInKm <= 0) &&
              (!exp.expenseType.durationInMonths || exp.expenseType.durationInMonths <= 0)
            ? ''
            : !exp.expenseType.durationInKm || exp.expenseType.durationInKm <= 0
            ? exp.expenseType.durationInMonths + ' mes.'
            : !exp.expenseType.durationInMonths || exp.expenseType.durationInMonths <= 0
            ? exp.expenseType.durationInKm + ' km'
            : exp.expenseType.durationInKm + ' km ili ' + exp.expenseType.durationInMonths + ' mes.';
    };

    const feedSideDetailsElement = (): ReactElement => {
        const exp = expense as VehicleExpenseDto;
        return !exp ? (
            <></>
        ) : (
            <>
                <div>
                    <Typography gutterBottom variant="h5" component="p">
                        {exp.title && exp.title !== '' ? exp.title : 'Nema naslova'}
                    </Typography>
                </div>
                <hr style={{ width: '100%' }} />
                <div>
                    <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                        <span style={{ fontWeight: 'normal' }}>Datum važenja:</span>{' '}
                        <span style={{ float: 'right' }}>{exp.validFrom ? FormatDateText(exp.validFrom) : ''}</span>
                    </Typography>
                </div>
                <hr style={{ width: '100%' }} />
                {exp.price && exp.price > 0 && (
                    <>
                        <div style={{ marginTop: 25 }}>
                            <Typography gutterBottom variant="h5" component="p">
                                Informacije o trošku
                            </Typography>
                        </div>
                        <hr style={{ width: '100%' }} />
                        <div>
                            <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                                <span style={{ fontWeight: 'normal' }}>Cena:</span>{' '}
                                <span style={{ float: 'right' }}>{exp.price} din.</span>
                            </Typography>
                            <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                                <span style={{ fontWeight: 'normal' }}>Predv. trajanje troška:</span>{' '}
                                <span style={{ float: 'right' }}>{expDurationTextFormat()}</span>
                            </Typography>
                            {exp.expenseKm && exp.expenseKm > 0 && (
                                <Typography gutterBottom variant="h6" component="p" style={{ fontSize: 18 }}>
                                    <span style={{ fontWeight: 'normal' }}>Km troška:</span>{' '}
                                    <span style={{ float: 'right' }}>{exp.expenseKm} km</span>
                                </Typography>
                            )}
                        </div>
                    </>
                )}
                <hr style={{ width: '100%' }} />
                <Typography gutterBottom variant="body1" component="p" style={{ whiteSpace: 'normal' }}>
                    {exp.description && exp.description !== '' ? exp.description : 'Nema opisa'}
                </Typography>
            </>
        );
    };

    return (
        <main style={{ width: '100%', height: 'calc(100vh - 70px)' }}>
            <div
                style={{
                    position: 'absolute',
                    left: 15,
                    top: 0,
                    fontSize: 30,
                    fontFamily: 'Courier New',
                    cursor: 'pointer',
                }}
                onClick={closeChartExpenseSideDetails}
            >
                {'>'}
            </div>
            <div
                style={{
                    height: 'calc(100vh - 200px)',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    padding: 20,
                    paddingTop: 0,
                    marginTop: 50,
                }}
            >
                {expense ? (
                    feedSideDetailsElement()
                ) : (
                    <div style={{ width: 400, margin: 'auto', marginTop: 100 }}>
                        <LoaderProgress />
                    </div>
                )}
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: 80,
                    right: 20,
                    width: 400,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                }}
            >
                <Button variant="outlined" color="error" onClick={handleOpenDeleteModal}>
                    Izbriši trošak
                </Button>
                <Button variant="outlined" color="primary" onClick={openChartExpenseForm}>
                    Izmeni trošak
                </Button>
            </div>
            <DeleteRecModal
                open={openDeleteModal}
                title={'ovaj trošak'}
                closeModal={handleCloseDeleteModal}
                deleteDoc={deleteExp}
            />
        </main>
    );
}
