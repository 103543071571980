import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ReactElement } from 'react';
import AdditionBtn from '../../General/AdditionsBtn';
import { isAppTypeDriver } from '../../General/Extensions/Selectors/Selectors';

export default function AdminLayout(): ReactElement {
    const location = useLocation();
    const path = location.pathname;

    const isDriver = isAppTypeDriver(path);

    return (
        <>
            {!isDriver && <AdditionBtn shouldDisplay={true} />}
            <Outlet />
        </>
    );
}
