import { Alert, Grid, Tooltip } from '@mui/material';
import React, { ReactElement, useEffect, memo, useState, useMemo } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import {
    RequestOriginApp,
    ShipmentDto,
    TransportWarrantDto,
    TransportWarrantItemDto,
} from '../../../AutoGeneratedAPI/clientApi';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
    addInitialShipmentsForTw,
    addInitialTWItemsForTw,
    addStopsArrangementForTw,
    emptyUnselectedShipmentsForTw,
    replaceInitialStopForTw,
    setAreStopsChanged,
    unselectSelectedShipmentForTw,
} from '../../../Redux/Reducers/Warrants/TransportWarrantFormReducer';
import { RootState } from '../../../Redux/store';
import { CompareDatesAndTimesForTwoStops } from '../../../../Common/ShipmentsHandler';
import DNDColumn from './DNDColumn';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import SubmitButton from '../../../../Common/CommonComponents/SubmitButton';
import { ServerResponseStatus } from '../../../Extensions/Extensions';
import cloneDeep from 'lodash.clonedeep';
import {
    addNewTransportWarrantItem,
    deleteOldTransportWarrantItem,
    updateOldTransportWarrant,
} from '../../../Redux/Actions/Warrants/TransportWarrantAction';
import { removeShipmentFromSelectableArray } from '../../../Redux/Reducers/Shipments/ShipmentReducer';
import { calculateRoutesDistancesDiff } from '../CalendarTWSideDetailsHelper';
import { armatureAddress, warehouseAddress } from '../../../../Common/Common';
import { getTimeDifference } from '../../../../Common/DateHandler';
import { addMinutes } from 'date-fns';

type ShipmentsDNDProps = {
    openShipmentSideDetails(shipment: ShipmentDto): void;
};

function ShipmentsDND({ openShipmentSideDetails }: ShipmentsDNDProps): ReactElement {
    const currShipments = useAppSelector((state: RootState) => state.twFormData.currentTwInitialShipments);
    const currStops = useAppSelector((state: RootState) => state.twFormData.currentTwInitialStops);
    const currStopsArrangement = useAppSelector((state: RootState) => state.twFormData.currentTwStopsArrangement);
    const stopsDurations = useAppSelector((state: RootState) => state.twFormData.stopsDurations);
    const transportWarrant = useAppSelector((state: RootState) => state.twFormData.transportWarrant);
    const currTwItems = useAppSelector((state: RootState) => state.twFormData.currentTwInitialTwItems);

    const areStopsChanged = useAppSelector((state: RootState) => state.twFormData.areStopsChanged);

    const [stopUpdatingStatuses, setStopUpdatingStatuses] = useState<{ id: number; status: ServerResponseStatus }[]>(
        [],
    );

    const dispatch = useAppDispatch();

    const handleSetAreStopsChanged = (toggle: boolean) => {
        dispatch(setAreStopsChanged(toggle));
    };

    useEffect(() => {
        if (stopUpdatingStatuses.length === currStops.length && stopUpdatingStatuses.length > 0) {
            if (!stopUpdatingStatuses.find((sus) => sus.status !== ServerResponseStatus.Success)) {
                setStopUpdatingStatuses([]);

                GranitClient.getTransportWarrantItems(transportWarrant.id).then((res) => {
                    if (res.length > 0) {
                        const resDeepCopy = cloneDeep(res);

                        const ships = resDeepCopy.map((r) => r.shipment);

                        dispatch(addInitialShipmentsForTw(ships));
                        dispatch(unselectSelectedShipmentForTw());
                    }
                });

                GranitClient.getTransportWarrantItems(transportWarrant.id).then((res) => {
                    if (res.length > 0) {
                        dispatch(addInitialTWItemsForTw(res ?? []));
                    }
                });
            }
        }
    }, [stopUpdatingStatuses]);

    /**
     * Check if current shipments list is somehow different from initial shipments list, be it in size or in order
     */
    useEffect(() => {
        if (currStops.length === currStopsArrangement.length) {
            const areStopsArranged = currStops.find((cs, index) => cs.id !== currStopsArrangement[index]) === undefined;
            if (areStopsArranged) {
                if (
                    currShipments.length !== currTwItems.length ||
                    (currStops.length === 0 && currShipments.length > 0)
                ) {
                    if (!areStopsChanged) {
                        handleSetAreStopsChanged(true);
                    }
                } else {
                    const currShip = currShipments.find(
                        (cs) => currTwItems.find((ctwi) => ctwi.shipmentId === cs.id) === undefined,
                    );
                    const currTwi = currTwItems.find(
                        (ctwi) => currShipments.find((cs) => cs.id === ctwi.shipmentId) === undefined,
                    );
                    if (!!currShip || !!currTwi) {
                        if (!areStopsChanged) {
                            handleSetAreStopsChanged(true);
                        }
                    } else {
                        const changedStopsCount = currStops.filter((stop) => {
                            if (stop) {
                                const currStartStop = currShipments?.find((cs) => cs.startingStopId === stop.id)
                                    ?.startingStop;
                                const currEndStop = currShipments?.find((cs) => cs.endingStopId === stop.id)
                                    ?.endingStop;

                                if (currStartStop) {
                                    if (!CompareDatesAndTimesForTwoStops(stop, currStartStop)) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                } else if (currEndStop) {
                                    if (!CompareDatesAndTimesForTwoStops(stop, currEndStop)) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                } else {
                                    return true;
                                }
                            } else {
                                return false;
                            }
                        }).length;

                        if (changedStopsCount > 0) {
                            if (!areStopsChanged) {
                                handleSetAreStopsChanged(true);
                            }
                        } else {
                            if (areStopsChanged) {
                                handleSetAreStopsChanged(false);
                            }
                        }
                    }
                }
            }
        }
    }, [currStops, currShipments, currStopsArrangement, currTwItems]);

    /**
     * Set alert for optimal shipments arrangement
     */
    const AlertInfoElement = useMemo(() => {
        if (currStops && currStops.length > 1) {
            const newStops = currStops.filter((cs) => {
                return (
                    currTwItems.find(
                        (currTwi) =>
                            currTwi.shipment.startingStopId === cs.id || currTwi.shipment.endingStopId === cs.id,
                    ) === undefined
                );
            });

            if (newStops.length <= 0) {
                return <></>;
            }

            const oldStops = currStops.filter((cs) => cs.id !== newStops[newStops.length - 1].id);

            const homeStop = currShipments.find(
                (cs) =>
                    cs.startingStop.locationInfo.address === warehouseAddress.address ||
                    cs.startingStop.locationInfo.address === armatureAddress.address,
            )?.startingStop;

            const distancesDifference = calculateRoutesDistancesDiff(oldStops, newStops[newStops.length - 1], homeStop);

            let minDiff = distancesDifference[0];

            distancesDifference.map((dd) => {
                if (dd.value < minDiff.value) {
                    minDiff = dd;
                }
            });

            const alertInfo = { newStopId: newStops[newStops.length - 1].id, prevStopId: minDiff.key };

            const alertStop = currStops.find((cs) => cs.id === alertInfo.newStopId);
            const prevStop = currStops.find((cs) => cs.id === alertInfo.prevStopId);

            const alertShip = currShipments.find(
                (cs) => cs.startingStopId === alertStop?.id || cs.endingStopId === alertStop?.id,
            );

            const prevShip = currShipments.find(
                (cs) => cs.startingStopId === prevStop?.id || cs.endingStopId === prevStop?.id,
            );

            const prevShipTitle = alertInfo.prevStopId === 0 ? 'polaska' : prevShip?.title;

            return (
                alertInfo.newStopId > 0 &&
                alertStop !== undefined && (
                    <Alert severity="info" style={{ position: 'absolute', bottom: 45, right: 5 }}>
                        Najbolja pozicija za isporuku <span style={{ fontWeight: 'bold' }}>{alertShip?.title}</span> je
                        nakon <span style={{ fontWeight: 'bold' }}>{prevShipTitle}</span>
                    </Alert>
                )
            );
        }

        return <></>;
    }, [currStops.length, currShipments, currTwItems]);

    const updateStopsForShipments = () => {
        currStops.map((stop) => {
            GranitClient.updateStop(stop.id, stop).then((res) => {
                const currSus = { id: stop.id, status: ServerResponseStatus.Success };

                dispatch(replaceInitialStopForTw(res));

                setStopUpdatingStatuses((prev) => [...prev, currSus]);
            });
        });
    };

    const clearShipments = (): void => {
        dispatch(emptyUnselectedShipmentsForTw());
        dispatch(unselectSelectedShipmentForTw());
    };

    const onSaveTwItems = () => {
        if (currShipments.length > 0) {
            // Update estimate and actual times for transport warrant

            // General part
            const newTw = new TransportWarrantDto();

            Object.assign(newTw, transportWarrant);

            // Get transport duration based on map
            const mapTime = Math.round(
                stopsDurations.reduce((acc, s) => {
                    return acc + s.value;
                }, 0) / 60,
            );

            // Get unloading duration based on predefined values
            const unloadingTime = currShipments.reduce((acc, sh) => {
                const diff = getTimeDifference(sh.endingStop.startingTime, sh.endingStop.endingTime);
                return acc + diff.hours * 60 + diff.minutes;
            }, 0);

            const loadingOnTheRoadTime = currShipments.reduce((acc, sh) => {
                if (sh.requestOriginApp === RequestOriginApp.Transport) {
                    const diff = getTimeDifference(sh.startingStop.startingTime, sh.startingStop.endingTime);
                    return acc + diff.hours * 60 + diff.minutes;
                }
                return acc;
            }, 0);

            // Add transport duration and unloading duration to startingTime
            const newEndingTime = addMinutes(newTw.estStartingTime, unloadingTime + mapTime + loadingOnTheRoadTime);

            // Set tw estEndingTime and actEndingTime to calculated finish time
            newTw.estEndingTime = newEndingTime;
            newTw.actEndingTime = newEndingTime;

            // Update tw
            dispatch(updateOldTransportWarrant(newTw, true));
        }

        const twId = transportWarrant.id;

        // Delete tw items that are removed from list
        currTwItems.map((ctwi) => {
            const shouldDelete = currShipments.find((cs) => ctwi.shipmentId === cs.id) === undefined;

            if (shouldDelete) {
                dispatch(deleteOldTransportWarrantItem(ctwi));
            }
        });

        // Filter shipments that are added in list
        currShipments.map((cs) => {
            const shouldAdd = currTwItems.find((twi) => twi.shipmentId === cs.id) === undefined;

            if (shouldAdd) {
                const twItem = new TransportWarrantItemDto();
                twItem.shipmentId = cs.id ?? 0;
                twItem.transportWarrantId = twId;
                dispatch(addNewTransportWarrantItem(twItem));
                dispatch(removeShipmentFromSelectableArray(twItem.shipmentId));
            }
        });

        // Update all different shipments
        updateStopsForShipments();

        clearShipments();
    };

    const handleOnDragEnd = (result: any) => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (!isNaN(+draggableId)) {
            const draggable = currStops.find((cs) => cs.id === +draggableId);
            if (draggable) {
                let shouldRearrange = true;
                const currStopsArrange = [...currStopsArrangement];
                currStopsArrange.splice(source.index, 1);
                currStopsArrange.splice(destination.index, 0, draggable.id);

                const currTransportShipment = currShipments.find(
                    (cs) =>
                        cs.requestOriginApp === RequestOriginApp.Transport &&
                        (cs.startingStopId === draggable.id || cs.endingStopId === draggable.id),
                );

                if (!!currTransportShipment) {
                    const startingStopIdIndex = currStopsArrange.findIndex(
                        (csa) => csa === currTransportShipment.startingStopId,
                    );
                    const endingStopIdIndex = currStopsArrange.findIndex(
                        (csa) => csa === currTransportShipment.endingStopId,
                    );
                    if (
                        startingStopIdIndex !== -1 &&
                        endingStopIdIndex !== -1 &&
                        startingStopIdIndex > endingStopIdIndex
                    ) {
                        shouldRearrange = false;
                    }
                }

                if (shouldRearrange) {
                    dispatch(addStopsArrangementForTw(currStopsArrange));
                } else {
                    alert('Nije moguce da se istovar desi pre utovara');
                }
            }
        }
    };

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100vh - 210px)',
                    justifyContent: 'space-between',
                    maxWidth: 350,
                    position: 'relative',
                }}
            >
                <DNDColumn
                    key={'shipmentsColumn'}
                    shipments={currShipments ?? []}
                    stops={currStops ?? []}
                    openShipmentSideDetails={openShipmentSideDetails}
                    durations={stopsDurations}
                />
                <Grid container spacing={1}>
                    {!transportWarrant.isTwFinished && (
                        <Grid item xs={12}>
                            <Tooltip title="Sačuvaj novi raspored isporuka" placement="top">
                                <>
                                    <SubmitButton
                                        btnStyle={{
                                            color: 'primary',
                                            variant: 'contained',
                                            style: {
                                                margin: 'auto',
                                                width: '100%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                display: 'inline-block',
                                                height: 37,
                                            },
                                        }}
                                        funct={onSaveTwItems}
                                        buttonText="Sačuvaj"
                                        useTimer={true}
                                        disableDuration={1.5}
                                        shouldDisable={!areStopsChanged}
                                    />
                                </>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
                {AlertInfoElement}
            </div>
        </DragDropContext>
    );
}

export default memo(ShipmentsDND);
