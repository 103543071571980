import { IEmployeeInfoDto } from '../../AutoGeneratedAPI/clientApi';
import { GraphUserDto } from '../../AutoGeneratedAPI/MSGraph/msGraphApi';
import { RootState } from '../store';
import { LoginStatus } from '../Reducers/Login/EmployeeReducer';

export function selectEmployeeInfo(state: RootState): GraphUserDto | null {
    return state.loggedEmployee.basicEmployee;
}

export function loggedInEmployee(state: RootState): IEmployeeInfoDto | null {
    return state.loggedEmployee.employee;
}

export function logginUserStatus(state: RootState): LoginStatus | null {
    return state.loggedEmployee.logginStatus;
}

export function isEmployeeAuthenticatedOB(state: RootState): boolean {
    return state.loggedEmployee.token &&
        state.loggedEmployee.token !== '' &&
        state.loggedEmployee.expirationDate &&
        new Date(state.loggedEmployee.expirationDate) > new Date()
        ? true
        : false;
}
