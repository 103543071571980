import * as React from 'react';
import { ReactElement, useState } from 'react';
import './Catalogue.css';
import { RecordType, ServerResponseStatus } from '../Extensions/Extensions';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import CatalogueElement from './CatalogueElement';
import { changeRecordType } from '../Redux/Reducers/General/RecordTypeReducer';
import { useEffect } from 'react';
import { fetchInitialEntities } from '../../Common/FetchingEntities';
import { useLocation } from 'react-router-dom';

export default function Catalogue(): ReactElement {
    const [serverStatus, setServerStatus] = useState<ServerResponseStatus>(ServerResponseStatus.Pending);

    const dispatch = useDispatch();

    const location = useLocation();
    const path = location.pathname;

    useEffect(() => {
        fetchInitialEntities(path, dispatch);
        handleSuccessfulFetch();
    }, [path]);

    const handleSuccessfulFetch = () => {
        dispatch(changeRecordType(RecordType.Record));
        setServerStatus(ServerResponseStatus.Success);
    };

    if (serverStatus === ServerResponseStatus.Pending) {
        return (
            <div style={{ height: '60vh', width: '60vh', margin: 'auto' }}>
                <CircularProgress disableShrink className="loader" />
            </div>
        );
    } else {
        return <CatalogueElement />;
    }
}
