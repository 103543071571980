export const protectedResources = {
    graphMe: {
        endpoint: 'https://graph.microsoft.com/v1.0/me',
        scopes: ['User.Read'],
    },
    graphMessages: {
        endpoint: 'https://graph.microsoft.com/v1.0/me/messages',
        scopes: ['Mail.Read'],
    },
    granitApi: {
        endpoint: 'https://edu-mathsolver-prod.trafficmanager.net/api/User/Ping',
        scopes: ['api://granitdoo/Employees.Read.All'],
    },
    armTenants: {
        endpoint: 'https://management.azure.com/tenants?api-version=2020-01-01',
        scopes: ['https://management.azure.com/user_impersonation'],
    },
};
