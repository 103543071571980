import { Grid, Typography } from '@mui/material';
import React, { ReactElement, useMemo, useState } from 'react';
import { TransportWarrantDto } from '../../AutoGeneratedAPI/clientApi';
import {
    ShipmentLoadingTWListContainer,
    ShipmentLoadingTWListElementContainer,
    ShipmentLoadingTWListShipmentElement,
    ShipmentLoadingTWListShipmentsContainer,
} from './ShipmentLoadingStyled';
import './ShipmentLoading.css';
import { useAppSelector } from '../../hooks/hooks';
import { RootState } from '../../Redux/store';
import { FormatTimeText } from '../../../Common/DateHandler';
import TWOccupancyDetails from '../../../Common/CommonComponents/TWOccupancyDetails';
import {
    sortTwsBasedOnLoadingCapacityLeft,
    sortTwsBasedOnDistance,
    sortTwsBasedOnStartingTime,
} from './ShipmentLoadingTWListSortingAlgs';
import { TWSortingParameter, SortingDirection } from './ShipmentLoadingCommon';

type ShipmentLoadingTWListProps = {
    transportWarrants: TransportWarrantDto[];
    selectTw(twId: number): void;
    sortParameter: TWSortingParameter;
    sortDirection: SortingDirection;
};

export default function ShipmentLoadingTWList({
    transportWarrants,
    selectTw,
    sortParameter,
    sortDirection,
}: ShipmentLoadingTWListProps): ReactElement {
    const [hovered, setHovered] = useState<number>(-1);

    const trucks = useAppSelector((state: RootState) => state.vehicles.trucks);
    const trailers = useAppSelector((state: RootState) => state.vehicles.trailers);
    const selectedShipment = useAppSelector((state: RootState) => state.twFormData.selectedShipmentForTW);

    const filteredTws = useMemo(() => {
        return transportWarrants.filter((tw) => !tw.isTwFinished);
    }, [transportWarrants]);

    const sortedTws = useMemo(() => {
        switch (sortParameter) {
            case TWSortingParameter.LoadingCapacity:
                return sortTwsBasedOnLoadingCapacityLeft(filteredTws, sortDirection, trucks ?? [], trailers ?? []);
            case TWSortingParameter.Distance:
                return sortTwsBasedOnDistance(filteredTws, sortDirection, selectedShipment?.endingStop);
            default:
                return sortTwsBasedOnStartingTime(filteredTws, sortDirection);
        }
    }, [sortParameter, sortDirection, transportWarrants]);

    return (
        <ShipmentLoadingTWListContainer>
            {sortedTws && sortedTws.length > 0 ? (
                <Grid container spacing={1}>
                    {sortedTws.map((tw, index) => {
                        const truck = trucks?.find((t) => t.id === tw.composition.mainVehicleId);
                        const shipments = tw.transportWarrantItems?.map((twi) => twi.shipment);
                        const currTrailers = trailers?.filter((tr) => tw?.composition?.trailersIds?.includes(tr.id));

                        return (
                            <Grid key={tw.id} item xs={12}>
                                <ShipmentLoadingTWListElementContainer
                                    className={'shipment-loading-element'}
                                    onClick={() => selectTw(tw.id ?? 0)}
                                    onMouseOver={() => setHovered(index)}
                                    onMouseOut={() => setHovered(-1)}
                                >
                                    {/** Basic info: truck, trailers, drivers, startingTime, endingTime, occupancy */}
                                    <div style={{ display: 'inline-block', marginRight: 5 }}>
                                        {truck?.manufacturer} {truck?.model}, {truck?.licensePlateNum}
                                    </div>
                                    <div
                                        style={{
                                            whiteSpace: 'pre-wrap',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {currTrailers?.map((tr, trIndex) => {
                                            return (
                                                <Typography
                                                    key={tr.id}
                                                    gutterBottom
                                                    variant="body1"
                                                    component="p"
                                                    color="gray"
                                                    style={{
                                                        display: 'inline-block',
                                                        fontSize: 14,
                                                        height: 16,
                                                    }}
                                                >
                                                    {trIndex === 0 && '('}
                                                    {tr.manufacturer} {tr.model}, {tr.licensePlateNum}
                                                    {trIndex === (currTrailers ? currTrailers.length - 1 : 0)
                                                        ? ')'
                                                        : '; '}
                                                </Typography>
                                            );
                                        })}
                                    </div>
                                    <div style={{ display: 'inline-block', marginLeft: 15, float: 'right' }}>
                                        {FormatTimeText(tw.actStartingTime)} - {FormatTimeText(tw.actEndingTime)}
                                    </div>
                                    <div>
                                        <TWOccupancyDetails
                                            shipments={
                                                shipments
                                                    ? selectedShipment
                                                        ? index === hovered
                                                            ? shipments.concat(selectedShipment)
                                                            : shipments
                                                        : shipments
                                                    : []
                                            }
                                            loadPalletSpacesCapacity={
                                                (truck?.loadPalletSpaces ?? 0) +
                                                (currTrailers?.reduce((acc, tr) => {
                                                    return acc + tr.loadPalletSpaces;
                                                }, 0) ?? 0)
                                            }
                                            loadWeightCapacity={
                                                (truck?.loadWeight ?? 0) +
                                                (currTrailers?.reduce((acc, tr) => {
                                                    return acc + tr.loadWeight;
                                                }, 0) ?? 0)
                                            }
                                            loadVolumeCapacity={
                                                (truck?.loadVolume ?? 0) +
                                                (currTrailers?.reduce((acc, tr) => {
                                                    return acc + tr.loadVolume;
                                                }, 0) ?? 0)
                                            }
                                        />
                                    </div>
                                    {/** Additional info: shipments on tw */}
                                    <ShipmentLoadingTWListShipmentsContainer>
                                        {shipments && shipments.length > 0 ? (
                                            shipments.map((ship) => (
                                                <ShipmentLoadingTWListShipmentElement key={Math.random()}>
                                                    <div
                                                        style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                    >
                                                        {ship.title}{' '}
                                                        {ship.data.clientName && ship.data.clientName !== '' && (
                                                            <span style={{ color: 'gray' }}>
                                                                ({ship.data.clientName})
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div>{ship.data.invoiceCode}</div>
                                                </ShipmentLoadingTWListShipmentElement>
                                            ))
                                        ) : (
                                            <div>Nema isporuka</div>
                                        )}
                                    </ShipmentLoadingTWListShipmentsContainer>
                                </ShipmentLoadingTWListElementContainer>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <div>Nema putnih naloga</div>
            )}
        </ShipmentLoadingTWListContainer>
    );
}
