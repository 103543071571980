import React, { ReactElement } from 'react';
import './Details.css';
import { Grid } from '@mui/material';
import { EntitiesType } from '../../Extensions/Entities';
import { ClientDto, ClientEntityType } from '../../AutoGeneratedAPI/clientApi';
import { ClientTypeSR } from '../../Extensions/Localizations/Types/Client/SR/ClientTypeSR';

type CustomerDetailsProps = {
    entity: EntitiesType | null;
};

export default function CustomerDetails({ entity }: CustomerDetailsProps): ReactElement {
    const isFirm = (entity as ClientDto).clientEntityType === ClientEntityType.Legal;

    const clientTypeText = ClientTypeSR[(entity as ClientDto).clientType ?? 0];

    return (
        <Grid container style={{ justifyContent: 'space-evenly' }} spacing={1}>
            <Grid key={0} item xs={12}>
                <p key={11} className="naming">
                    {isFirm ? 'Naziv:' : 'Ime:'}
                </p>
                <p key={12} className="info">
                    {' '}
                    {entity !== null ? (entity as ClientDto).name : ''}
                </p>
            </Grid>
            <Grid key={1} item xs={12}>
                <p key={21} className="naming">
                    Tip klijenta:
                </p>
                <p key={22} className="info">
                    {' '}
                    {entity !== null ? clientTypeText : ''}
                </p>
            </Grid>
            <Grid key={3} item xs={12}>
                <p key={41} className="naming">
                    Email:
                </p>
                <p key={42} className="info">
                    {' '}
                    {entity !== null ? (entity as ClientDto).email : ''}
                </p>
            </Grid>
            <Grid key={4} item xs={12}>
                <p key={23} className="naming">
                    Telefon:
                </p>
                <p key={24} className="info">
                    {' '}
                    {entity !== null ? (entity as ClientDto).phone : ''}
                </p>
            </Grid>
            <Grid key={4} item xs={12}>
                <p key={23} className="naming">
                    Adresa:
                </p>
                <p key={24} className="info">
                    {' '}
                    {entity !== null ? (entity as ClientDto).address : ''}
                </p>
            </Grid>
            <Grid key={4} item xs={12}>
                <p key={23} className="naming">
                    Grad:
                </p>
                <p key={24} className="info">
                    {' '}
                    {entity !== null ? (entity as ClientDto).city : ''}
                </p>
            </Grid>
            <Grid key={4} item xs={12}>
                <p key={23} className="naming">
                    {isFirm ? 'MB:' : 'JMBG:'}
                </p>
                <p key={24} className="info">
                    {' '}
                    {entity !== null ? (entity as ClientDto).ucin : ''}
                </p>
            </Grid>

            {isFirm ? (
                <>
                    <Grid key={4} item xs={12}>
                        <p key={23} className="naming">
                            PIB:
                        </p>
                        <p key={24} className="info">
                            {' '}
                            {entity !== null ? (entity as ClientDto).taxId : ''}
                        </p>
                    </Grid>
                    <Grid key={4} item xs={12}>
                        <p key={23} className="naming">
                            Kontakt osoba:
                        </p>
                        <p key={24} className="info">
                            {' '}
                            {entity !== null ? (entity as ClientDto).contactInfo : ''}
                        </p>
                    </Grid>
                </>
            ) : (
                <></>
            )}
        </Grid>
    );
}
