import React, { ReactElement } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type ConfirmDialogProps = {
    visible: boolean;
    message?: string;
    title?: string;
    onConfirmSubmit(): void;
    onCancelSubmit(): void;
};

export default function ConfirmDialog({
    visible,
    message,
    title,
    onCancelSubmit,
    onConfirmSubmit,
}: ConfirmDialogProps): ReactElement {
    return (
        <div>
            <Dialog
                open={visible}
                onClose={onCancelSubmit}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title ?? ''}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{message ?? ''}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onCancelSubmit}
                        color="primary"
                        style={{
                            marginTop: 24,
                            marginLeft: 8,
                        }}
                    >
                        Ne
                    </Button>
                    <Button
                        onClick={onConfirmSubmit}
                        color="primary"
                        variant="contained"
                        style={{
                            marginTop: 24,
                            marginLeft: 8,
                        }}
                        autoFocus
                    >
                        Da
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
