import React, { useEffect } from 'react';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../General/hooks/hooks';
import { loginEmployee } from '../General/Redux/Actions/Login/EmployeeActions';
import { LoginStatus } from '../General/Redux/Reducers/Login/EmployeeReducer';
import { logginUserStatus } from '../General/Redux/Selectors/EmployeeSelectors';

export function Authenticate(): ReactElement {
    const logginUserStatusValue = useSelector(logginUserStatus);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (logginUserStatusValue === LoginStatus.Succeeded || logginUserStatusValue === LoginStatus.InProgress) {
            return;
        }

        if (logginUserStatusValue === null) {
            console.log('Login status ' + logginUserStatusValue);
            dispatch(loginEmployee());
        }
    }, [dispatch, logginUserStatusValue]);

    function getStatusComponent(status: LoginStatus | null) {
        if (logginUserStatusValue === null) {
            return <div>Loggin in...</div>;
        }

        if (status === LoginStatus.InProgress) {
            return <div>Loggin in......</div>;
        }

        if (status === LoginStatus.Succeeded) {
            return <div>Succeeded</div>;
        }

        if (status === LoginStatus.Failed) {
            return <div>Error!!!</div>;
        }

        return <div>...</div>;
    }

    return getStatusComponent(logginUserStatusValue);
}
