import React, { ReactElement, useState, ReactNode, useEffect } from 'react';
import { Collapse, Grid, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

type CollapsableFormSectionProps = {
    children: ReactNode;
    initialExpanded: boolean;
    titleComponents: ReactNode;
    handleInitialExpandChange(newExpand: boolean): void;
};

export default function CollapsableFormSection({
    children,
    initialExpanded,
    titleComponents,
    handleInitialExpandChange,
}: CollapsableFormSectionProps): ReactElement {
    const [sectionExpanded, setSectionExpanded] = useState<boolean>(initialExpanded);

    useEffect(() => {
        setSectionExpanded(initialExpanded);
    }, [initialExpanded]);

    const handleToggleExpanded = () => {
        setSectionExpanded(!sectionExpanded);
        handleInitialExpandChange(!sectionExpanded);
    };

    return (
        <>
            <Grid item xs={12}>
                <Typography
                    component="p"
                    variant="body1"
                    align="left"
                    onClick={handleToggleExpanded}
                    style={{ display: 'inline', cursor: 'pointer' }}
                >
                    {sectionExpanded ? <ExpandLess /> : <ExpandMore />}
                </Typography>
                {titleComponents}
            </Grid>
            <hr style={{ width: '100%' }} />
            <Collapse in={sectionExpanded} timeout="auto" unmountOnExit style={{ width: '100%' }}>
                {children}
            </Collapse>
        </>
    );
}
