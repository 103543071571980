import { addMonths, setDate } from 'date-fns';
import { CompareTwoDates, CompareTwoDatesEquality } from '../../Common/DateHandler';
import { TransportWarrantDto } from '../../General/AutoGeneratedAPI/clientApi';

export type TransortWarrantExistenceCheckType = {
    exists: boolean;
    startingDate: Date;
    endingDate: Date;
};

export const checkTransportWarrantsExistence = (
    transportWarrants: TransportWarrantDto[],
    currentDate: Date,
): TransortWarrantExistenceCheckType => {
    const startingDate = setDate(currentDate ?? new Date(), 1);

    const endingDate = addMonths(startingDate, 1);

    const filteredTws = transportWarrants?.filter(
        (tw) =>
            ((CompareTwoDates(startingDate, tw.actStartingTime) ||
                CompareTwoDatesEquality(startingDate, tw.actStartingTime)) &&
                CompareTwoDates(tw.actStartingTime, endingDate)) ||
            ((CompareTwoDates(startingDate, tw.actEndingTime) ||
                CompareTwoDatesEquality(startingDate, tw.actEndingTime)) &&
                CompareTwoDates(tw.actEndingTime, endingDate)),
    );

    const exists = !filteredTws ? false : filteredTws?.length > 0;

    return { exists, startingDate, endingDate };
};
