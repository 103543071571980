import { TextFieldProps, Box, TextField } from '@mui/material';
import { GridFilterInputValueProps, useGridRootProps } from '@mui/x-data-grid';
import React, { ReactElement } from 'react';
import SyncIcon from '@mui/icons-material/Sync';
import { formatNumberTypeInput } from '../../../Common/Common';

export function InputNumberInterval(props: GridFilterInputValueProps): ReactElement {
    const rootProps = useGridRootProps();
    const { item, applyValue, focusElementRef = null } = props;

    const filterTimeout = React.useRef<any>();
    const [filterValueState, setFilterValueState] = React.useState<[string, string]>(item.value ?? ['0', '100000000']);
    const [applying, setIsApplying] = React.useState(false);

    React.useEffect(() => {
        return () => {
            clearTimeout(filterTimeout.current);
        };
    }, []);

    React.useEffect(() => {
        const itemValue = item.value ?? ['0', '1000000000'];
        setFilterValueState(itemValue);
    }, [item.value]);

    const updateFilterValue = (lowerBound: string, upperBound: string) => {
        clearTimeout(filterTimeout.current);
        setFilterValueState([lowerBound, upperBound]);

        setIsApplying(true);
        filterTimeout.current = setTimeout(() => {
            setIsApplying(false);
            applyValue({ ...item, value: [lowerBound, upperBound] });
        }, rootProps.filterDebounceMs);
    };

    const handleUpperFilterChange: TextFieldProps['onChange'] = (event) => {
        const newUpperBound = event.target.value;
        updateFilterValue(filterValueState[0], newUpperBound);
    };
    const handleLowerFilterChange: TextFieldProps['onChange'] = (event) => {
        const newLowerBound = event.target.value;
        updateFilterValue(newLowerBound, filterValueState[1]);
    };

    return (
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'end',
                height: 48,
                pl: '20px',
            }}
        >
            <TextField
                name="lower-bound-input"
                placeholder="Od"
                label="Od"
                variant="standard"
                value={formatNumberTypeInput(Number(filterValueState[0]))}
                onChange={handleLowerFilterChange}
                type="number"
                inputRef={focusElementRef}
                sx={{ mr: 2 }}
            />
            <TextField
                name="upper-bound-input"
                placeholder="Do"
                label="Do"
                variant="standard"
                value={formatNumberTypeInput(Number(filterValueState[1]))}
                onChange={handleUpperFilterChange}
                type="number"
                InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
            />
        </Box>
    );
}
