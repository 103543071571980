/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export interface IMSGraphClient {
    /**
     * @return Success
     */
    getMe(): Promise<GraphUserDto>;
    getUserById(userId: string): Promise<GraphUserDto>;
    getUserCompleteInfo(userId: string): Promise<CompleteUserInfoDto>;
    getAllUsersInfo(): Promise<CompleteUserInfoDto[]>;
    getUserPhoto(userId: string): Promise<Blob | MediaSource | undefined>;
}

export class MSGraphClient implements IMSGraphClient {
    private http: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> };
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) {
        this.http = http ? http : (window as any);
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    }

    getMe(): Promise<GraphUserDto> {
        let url_ = this.baseUrl + '/me';
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetMe(_response);
        });
    }

    protected processgetMe(response: Response): Promise<GraphUserDto> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: any = null;
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = GraphUserDto.fromJS(resultData200);
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getUserById(userId: string): Promise<GraphUserDto> {
        const select =
            '?$select=displayName,id,givenName,surname,mail,jobTitle,mobilePhone,department,userPrincipalName';
        let url_ = this.baseUrl + '/users/' + userId + select;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetUserById(_response);
        });
    }

    protected processgetUserById(response: Response): Promise<GraphUserDto> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: any = null;
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = GraphUserDto.fromJS(resultData200);
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getUserCompleteInfo(userId: string): Promise<CompleteUserInfoDto> {
        const select =
            '?$select=id,displayName,givenName,surname,mailNickname,userPrincipalName,businessPhones,city,companyName,country,department,employeeHireDate,jobTitle,mail,mobilePhone,officeLocation,streetAddress,onPremisesExtensionAttributes';
        let url_ = this.baseUrl + '/users/' + userId + select;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetUserCompleteInfo(_response);
        });
    }

    protected processgetUserCompleteInfo(response: Response): Promise<CompleteUserInfoDto> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: any = null;
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompleteUserInfoDto.fromJS(resultData200);
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getAllUsersInfo(): Promise<CompleteUserInfoDto[]> {
        const select =
            '?$select=id,displayName,givenName,surname,mailNickname,userPrincipalName,businessPhones,city,companyName,country,department,employeeHireDate,jobTitle,mail,mobilePhone,officeLocation,streetAddress';

        let url_ = this.baseUrl + '/users' + select;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetAllUsersInfo(_response);
        });
    }

    protected processgetAllUsersInfo(response: Response): Promise<CompleteUserInfoDto[]> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: CompleteUserInfoDto[] = [];
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                if (Array.isArray(resultData200.value)) {
                    for (const emp of resultData200.value) result200.push(CompleteUserInfoDto.fromJS(emp));
                } else {
                    result200 = <any>null;
                }
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getUserPhoto(userId: string): Promise<Blob | MediaSource | undefined> {
        const select = '/photo/$value';
        let url_ = this.baseUrl + '/users/' + userId + select;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetUserPhoto(_response);
        });
    }

    protected processgetUserPhoto(response: Response): Promise<Blob | MediaSource | undefined> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.blob();
        } else if (status === 404) {
            return response.text().then(() => {
                return undefined;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }
}

export interface IGraphUserDto {
    id: string | undefined;
    displayName: string | undefined;
    givenName: string | undefined;
    jobTitle: string | undefined;
    mail: string | undefined;
    mobilePhone: string | undefined;
    surname: string | undefined;
    department: string | undefined;
    userPrincipalName: string | undefined;
}

export class GraphUserDto implements IGraphUserDto {
    id!: string | undefined;
    displayName!: string | undefined;
    givenName!: string | undefined;
    jobTitle!: string | undefined;
    mail!: string | undefined;
    mobilePhone!: string | undefined;
    surname!: string | undefined;
    department!: string | undefined;
    userPrincipalName!: string | undefined;

    constructor(data?: IGraphUserDto) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any): void {
        if (_data) {
            this.id = _data['id'];
            this.displayName = _data['displayName'];
            this.givenName = _data['givenName'];
            this.jobTitle = _data['jobTitle'];
            this.mail = _data['mail'];
            this.mobilePhone = _data['mobilePhone'];
            this.surname = _data['surname'];
            this.department = _data['department'];
            this.userPrincipalName = _data['userPrincipalName'];
        }
    }

    static fromJS(data: any): GraphUserDto {
        data = typeof data === 'object' ? data : {};
        const result = new GraphUserDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data['id'] = this.id;
        data['displayName'] = this.displayName;
        data['givenName'] = this.givenName;
        data['jobTitle'] = this.jobTitle;
        data['mail'] = this.mail;
        data['mobilePhone'] = this.mobilePhone;
        data['surname'] = this.surname;
        data['department'] = this.department;
        data['userPrincipalName'] = this.userPrincipalName;

        return data;
    }
}

export class ProblemDetails implements IProblemDetails {
    type!: string | undefined;
    title!: string | undefined;
    status!: number | undefined;
    detail!: string | undefined;
    instance!: string | undefined;

    constructor(data?: IProblemDetails) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any): void {
        if (_data) {
            this.type = _data['type'];
            this.title = _data['title'];
            this.status = _data['status'];
            this.detail = _data['detail'];
            this.instance = _data['instance'];
        }
    }

    static fromJS(data: any): ProblemDetails {
        data = typeof data === 'object' ? data : {};
        const result = new ProblemDetails();
        result.init(data);
        return result;
    }

    toJSON(data?: any): any {
        data = typeof data === 'object' ? data : {};
        data['type'] = this.type;
        data['title'] = this.title;
        data['status'] = this.status;
        data['detail'] = this.detail;
        data['instance'] = this.instance;
        return data;
    }
}

export interface IProblemDetails {
    type: string | undefined;
    title: string | undefined;
    status: number | undefined;
    detail: string | undefined;
    instance: string | undefined;
}

export class ApiException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isApiException = true;

    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true;
    }
}

function throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any,
): any {
    if (result !== null && result !== undefined) throw result;
    else throw new ApiException(message, status, response, headers, null);
}

export interface ICompleteUserInfoDto {
    id: string | undefined;
    displayName: string | undefined;
    givenName: string | undefined;
    surname: string | undefined;
    mailNickname: string | undefined;
    userPrincipalName: string | undefined;
    passwordProfile: {
        forceChangePasswordNextSignIn: boolean;
        password: string;
    };
    businessPhones: string[] | undefined;
    city: string | undefined;
    companyName: string | undefined;
    country: string | undefined;
    department: string | undefined;
    employeeHireDate: string | undefined;
    jobTitle: string | undefined;
    mail: string | undefined;
    mobilePhone: string | undefined;
    officeLocation: string | undefined;
    streetAddress: string | undefined;
    birthday: string | undefined;
    onPremisesExtensionAttributes: ExtensionAttributes | undefined;
}

export class CompleteUserInfoDto implements ICompleteUserInfoDto {
    id!: string | undefined;
    displayName!: string | undefined;
    givenName!: string | undefined;
    surname!: string | undefined;
    mailNickname!: string | undefined;
    userPrincipalName!: string | undefined;
    passwordProfile!: {
        forceChangePasswordNextSignIn: boolean;
        password: string;
    };
    businessPhones!: string[] | undefined;
    city!: string | undefined;
    companyName!: string | undefined;
    country!: string | undefined;
    department!: string | undefined;
    employeeHireDate!: string | undefined;
    jobTitle!: string | undefined;
    mail!: string | undefined;
    mobilePhone!: string | undefined;
    officeLocation!: string | undefined;
    streetAddress!: string | undefined;
    birthday!: string | undefined;
    onPremisesExtensionAttributes!: ExtensionAttributes | undefined;

    constructor(data?: ICompleteUserInfoDto) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any): void {
        if (_data) {
            this.id = _data['id'];
            this.displayName = _data['displayName'];
            this.givenName = _data['givenName'];
            this.surname = _data['surname'];
            this.mailNickname = _data['mailNickname'];
            this.userPrincipalName = _data['userPrincipalName'];
            this.passwordProfile = _data['passwordProfile'];
            this.businessPhones = _data['businessPhones'];
            this.city = _data['city'];
            this.companyName = _data['companyName'];
            this.country = _data['country'];
            this.department = _data['department'];
            this.employeeHireDate = _data['employeeHireDate'];
            this.jobTitle = _data['jobTitle'];
            this.mail = _data['mail'];
            this.mobilePhone = _data['mobilePhone'];
            this.officeLocation = _data['officeLocation'];
            this.streetAddress = _data['streetAddress'];
            this.birthday = _data['birthday'];
            this.onPremisesExtensionAttributes = _data['onPremisesExtensionAttributes'];
        }
    }

    static fromJS(data: any): CompleteUserInfoDto {
        data = typeof data === 'object' ? data : {};
        const result = new CompleteUserInfoDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data['id'] = this.id;
        data['displayName'] = this.displayName;
        data['givenName'] = this.givenName;
        data['surname'] = this.surname;
        data['mailNickname'] = this.mailNickname;
        data['userPrincipalName'] = this.userPrincipalName;
        data['passwordProfile'] = this.passwordProfile;
        data['businessPhones'] = this.businessPhones;
        data['city'] = this.city;
        data['companyName'] = this.companyName;
        data['country'] = this.country;
        data['department'] = this.department;
        data['employeeHireDate'] = this.employeeHireDate;
        data['jobTitle'] = this.jobTitle;
        data['mail'] = this.mail;
        data['mobilePhone'] = this.mobilePhone;
        data['officeLocation'] = this.officeLocation;
        data['streetAddress'] = this.streetAddress;
        data['birthday'] = this.birthday;
        data['onPremisesExtensionAttributes'] = this.onPremisesExtensionAttributes;

        return data;
    }
}

type ExtensionAttributes = {
    extensionAttribute1: string | undefined;
    extensionAttribute2: string | undefined;
    extensionAttribute3: string | undefined;
};
