export const PROFILE_IMAGE = 'profileImage';

export const ALL_EXP_TYPES = 'Svi';

export const DEFAULT_NIGHT_COLOR = '#0a182b';
export const DEFAULT_DARK_COLOR = '#2d6bc0';
export const DEFAULT_COLOR = '#3277d5';
export const DEFAULT_LIGHTLY_LIGHT_COLOR = '#5b92dd';
export const DEFAULT_SEMI_LIGHT_COLOR = '#d6e4f7';
export const DEFAULT_LIGHT_COLOR = '#ebf1fb';
