/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    VehicleRecordDto,
    EmployeeRecordDto,
    WorkingToolRecordDto,
    RecordCategory,
    ClientRecordDto,
} from '../../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { EntitiesType, RecordEntityType } from '../../../Extensions/Entities';
import { ExceptionsWithAdding, ExceptionsWithUpdating, ExceptionsWithDeleting } from '../../../Extensions/Exceptions';
import { addRecord, updateRecord, addRecordDoc, deleteRecord } from '../../Reducers/General/RecordsReducer';
import { addImpDoc, addImpDocDoc, deleteImpDoc, updateImpDoc } from '../../Reducers/General/ImportantDocsReducer';
import {
    isAppTypeClient,
    isAppTypeDriver,
    isAppTypeVehicle,
    isAppTypeWorkingToolCat,
} from '../../../Extensions/Selectors/Selectors';

// HANDLING RECORD AND EXPENSE UPDATE AND ADD

const fileToSend: File[] = [];

export const addNewRecord = (
    record: RecordEntityType,
    entity: EntitiesType,
    path: string,
    selectedFiles: Array<File>,
) => (dispatch: (arg0: { payload: any; type: string }) => number) => {
    if (isAppTypeVehicle(path) && entity !== undefined) {
        return GranitClient.addNewVehicleRecord(entity.id as number, record as VehicleRecordDto).then(
            (result) => {
                if (record.category === RecordCategory.ImportantDocs) {
                    dispatch(addImpDoc(result));
                }
                dispatch(addRecord(result));
                selectedFiles !== undefined
                    ? selectedFiles.map((sf) => {
                          (fileToSend[0] = sf),
                              GranitClient.saveRecordDocument(
                                  result.id as number,
                                  'default',
                                  fileToSend,
                                  '{ "test": "ovo je test"}',
                              ).then(
                                  (res) => {
                                      if (record.category === RecordCategory.ImportantDocs) {
                                          dispatch(addImpDocDoc(result, res));
                                      }
                                      dispatch(addRecordDoc(result, res));
                                  },
                                  (error) => {
                                      console.log(error.message);
                                      alert(ExceptionsWithAdding.EXCEPTION_ADDING_RECORDS);
                                  },
                              );
                      })
                    : console.log('No files');

                return result.id;
            },
            (error) => {
                console.log(error.message);
                alert(ExceptionsWithAdding.EXCEPTION_ADDING_RECORDS);

                return 0;
            },
        );
    } else if (isAppTypeDriver(path) && entity !== undefined) {
        return GranitClient.addNewEmployeeRecord(entity.id as number, record as EmployeeRecordDto).then(
            (result) => {
                if (record.category === RecordCategory.ImportantDocs) {
                    dispatch(addImpDoc(result));
                }
                dispatch(addRecord(result));
                selectedFiles !== undefined
                    ? selectedFiles.map((sf) => {
                          (fileToSend[0] = sf),
                              GranitClient.saveRecordDocument(
                                  result.id as number,
                                  'default',
                                  fileToSend,
                                  '{ "test": "ovo je test"}',
                              ).then(
                                  (res) => {
                                      if (record.category === RecordCategory.ImportantDocs) {
                                          dispatch(addImpDocDoc(result, res));
                                      }
                                      dispatch(addRecordDoc(result, res));
                                  },
                                  (error) => {
                                      console.log(error.message);
                                      alert(ExceptionsWithAdding.EXCEPTION_ADDING_RECORDS);
                                  },
                              );
                      })
                    : console.log('No files');

                return result.id;
            },
            (error) => {
                console.log(error.message);
                alert(ExceptionsWithAdding.EXCEPTION_ADDING_RECORDS);

                return 0;
            },
        );
    } else if (isAppTypeWorkingToolCat(path) && entity !== undefined) {
        return GranitClient.addNewWorkingToolRecord(entity.id as number, record as WorkingToolRecordDto).then(
            (result) => {
                dispatch(addRecord(result));
                selectedFiles !== undefined
                    ? selectedFiles.map((sf) => {
                          (fileToSend[0] = sf),
                              GranitClient.saveRecordDocument(
                                  result.id as number,
                                  'default',
                                  fileToSend,
                                  '{ "test": "ovo je test"}',
                              ).then(
                                  (res) => {
                                      dispatch(addRecordDoc(result, res));
                                  },
                                  (error) => {
                                      console.log(error.message);
                                      alert(ExceptionsWithAdding.EXCEPTION_ADDING_RECORDS);
                                  },
                              );
                      })
                    : console.log('No files');

                return result.id;
            },
            (error) => {
                console.log(error.message);
                alert(ExceptionsWithAdding.EXCEPTION_ADDING_RECORDS);

                return 0;
            },
        );
    } else if (isAppTypeClient(path) && entity !== undefined) {
        return GranitClient.addNewClientRecord(entity.id as number, record as ClientRecordDto).then(
            (result) => {
                dispatch(addRecord(result));
                selectedFiles !== undefined
                    ? selectedFiles.map((sf) => {
                          (fileToSend[0] = sf),
                              GranitClient.saveRecordDocument(
                                  result.id as number,
                                  'default',
                                  fileToSend,
                                  '{ "test": "ovo je test"}',
                              ).then(
                                  (res) => {
                                      dispatch(addRecordDoc(result, res));
                                  },
                                  (error) => {
                                      console.log(error.message);
                                      alert(ExceptionsWithAdding.EXCEPTION_ADDING_RECORDS);
                                  },
                              );
                      })
                    : console.log('No files');

                return result.id;
            },
            (error) => {
                console.log(error.message);
                alert(ExceptionsWithAdding.EXCEPTION_ADDING_RECORDS);

                return 0;
            },
        );
    }
};

export const updateOldRecord = (
    record: RecordEntityType,
    path: string,
    selectedFiles: Array<File>,
    category?: string,
) => (dispatch: (arg0: { payload: any; type: string }) => number) => {
    if (isAppTypeVehicle(path)) {
        return GranitClient.updateVehicleRecord(
            record !== undefined ? (record?.id as number) : 0,
            record as VehicleRecordDto,
        ).then(
            async (result) => {
                if (record.category === RecordCategory.ImportantDocs) {
                    dispatch(updateImpDoc(result));
                }
                dispatch(updateRecord(result));
                selectedFiles !== undefined && selectedFiles.length > 0
                    ? selectedFiles.map((sf) => {
                          (fileToSend[0] = sf),
                              GranitClient.saveRecordDocument(
                                  result.id as number,
                                  category !== undefined ? category : 'default',
                                  fileToSend,
                                  '{ "test": "ovo je test"}',
                              ).then(
                                  (res) => {
                                      if (record.category === RecordCategory.ImportantDocs) {
                                          dispatch(addImpDocDoc(record, res));
                                      }
                                      dispatch(addRecordDoc(record, res));
                                  },
                                  (error) => {
                                      console.log(error.message);
                                      alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_RECORDS);
                                  },
                              );
                      })
                    : console.log('No files');

                return result.id;
            },
            (error) => {
                console.log(error.message);
                alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_RECORDS);

                return 0;
            },
        );
    } else if (isAppTypeDriver(path)) {
        return GranitClient.updateEmployeeRecord(
            record !== undefined ? (record?.id as number) : 0,
            record as EmployeeRecordDto,
        ).then(
            async (result) => {
                if (record.category === RecordCategory.ImportantDocs) {
                    dispatch(updateImpDoc(result));
                }
                dispatch(updateRecord(result));
                selectedFiles !== undefined && selectedFiles.length > 0
                    ? selectedFiles.map((sf) => {
                          (fileToSend[0] = sf),
                              GranitClient.saveRecordDocument(
                                  result.id as number,
                                  category !== undefined ? category : 'default',
                                  fileToSend,
                                  '{ "test": "ovo je test"}',
                              ).then(
                                  (res) => {
                                      if (record.category === RecordCategory.ImportantDocs) {
                                          dispatch(addImpDocDoc(record, res));
                                      }
                                      dispatch(addRecordDoc(record, res));
                                  },
                                  (error) => {
                                      console.log(error.message);
                                      alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_RECORDS);
                                  },
                              );
                      })
                    : console.log('No files');

                return result.id;
            },
            (error) => {
                console.log(error.message);
                alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_RECORDS);

                return 0;
            },
        );
    } else if (isAppTypeWorkingToolCat(path)) {
        return GranitClient.updateWorkingToolRecord(
            record !== undefined ? (record?.id as number) : 0,
            record as WorkingToolRecordDto,
        ).then(
            async (result) => {
                dispatch(updateRecord(result));
                selectedFiles !== undefined && selectedFiles.length > 0
                    ? selectedFiles.map((sf) => {
                          (fileToSend[0] = sf),
                              GranitClient.saveRecordDocument(
                                  result.id as number,
                                  category !== undefined ? category : 'default',
                                  fileToSend,
                                  '{ "test": "ovo je test"}',
                              ).then(
                                  (res) => {
                                      dispatch(addRecordDoc(record, res));
                                  },
                                  (error) => {
                                      console.log(error.message);
                                      alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_RECORDS);
                                  },
                              );
                      })
                    : console.log('No files');

                return result.id;
            },
            (error) => {
                console.log(error.message);
                alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_RECORDS);

                return 0;
            },
        );
    } else if (isAppTypeClient(path)) {
        return GranitClient.updateClientRecord(
            record !== undefined ? (record?.id as number) : 0,
            record as ClientRecordDto,
        ).then(
            async (result) => {
                dispatch(updateRecord(result));
                selectedFiles !== undefined && selectedFiles.length > 0
                    ? selectedFiles.map((sf) => {
                          (fileToSend[0] = sf),
                              GranitClient.saveRecordDocument(
                                  result.id as number,
                                  category !== undefined ? category : 'default',
                                  fileToSend,
                                  '{ "test": "ovo je test"}',
                              ).then(
                                  (res) => {
                                      dispatch(addRecordDoc(record, res));
                                  },
                                  (error) => {
                                      console.log(error.message);
                                      alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_RECORDS);
                                  },
                              );
                      })
                    : console.log('No files');

                return result.id;
            },
            (error) => {
                console.log(error.message);
                alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_RECORDS);

                return 0;
            },
        );
    }
};

export const deleteVehicleRecord = (recId: number, oldRecord: RecordEntityType) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    if (oldRecord !== undefined) {
        GranitClient.deleteVehicleRecord(recId as number, oldRecord as VehicleRecordDto).then(
            () => {
                if (oldRecord.category === RecordCategory.ImportantDocs) {
                    dispatch(deleteImpDoc(oldRecord));
                } else {
                    dispatch(deleteRecord(oldRecord));
                }
                dispatch(deleteRecord(oldRecord));
            },
            (error) => {
                console.log(error.message);
                alert(ExceptionsWithDeleting.EXCEPTION_DELETING_RECORDS);
            },
        );
    }
};

export const deleteWorkingToolRecord = (recId: number, oldRecord: RecordEntityType) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    if (oldRecord !== undefined) {
        GranitClient.deleteWorkingToolRecord(recId as number, oldRecord as WorkingToolRecordDto).then(
            () => {
                if (oldRecord.category === RecordCategory.ImportantDocs) {
                    dispatch(deleteImpDoc(oldRecord));
                } else {
                    dispatch(deleteRecord(oldRecord));
                }
                dispatch(deleteRecord(oldRecord));
            },
            (error) => {
                console.log(error.message);
                alert(ExceptionsWithDeleting.EXCEPTION_DELETING_RECORDS);
            },
        );
    }
};
