/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { fetchInitialDrivers } from '../General/Redux/Reducers/Drivers/DriversReducer';
import {
    fetchInitialCars,
    fetchInitialTrailers,
    fetchInitialTrucks,
} from '../General/Redux/Reducers/Vehicles/VehiclesReducer';
import { fetchInitialWorkingTools } from '../General/Redux/Reducers/WorkingTools/WorkingToolsReducer';
import { fetchInitialWorkingMachines } from '../General/Redux/Reducers/WorkingTools/WorkingMachinesReducer';
import { fetchInitialHandMachines } from '../General/Redux/Reducers/WorkingTools/HandMachinesReducer';
import { fetchInitialOfficeMachines } from '../General/Redux/Reducers/WorkingTools/OfficeMachinesReducer';
import {
    fetchInitialCustomers,
    fetchInitialServices,
    fetchInitialSuppliers,
} from '../General/Redux/Reducers/Customers/CustomersReducer';
import {
    isAppTypeCar,
    isAppTypeCustomer,
    isAppTypeDriver,
    isAppTypeHandMachine,
    isAppTypeOfficeMachine,
    isAppTypeService,
    isAppTypeSupplier,
    isAppTypeTrailer,
    isAppTypeTruck,
    isAppTypeWorkingMachine,
    isAppTypeWorkingTool,
} from '../General/Extensions/Selectors/Selectors';

export const fetchInitialEntities = (path: string, dispatch: any): void => {
    switch (true) {
        case isAppTypeDriver(path):
            dispatch(fetchInitialDrivers());
            break;
        case isAppTypeCar(path):
            dispatch(fetchInitialCars());
            break;
        case isAppTypeTruck(path):
            dispatch(fetchInitialTrucks());
            break;
        case isAppTypeTrailer(path):
            dispatch(fetchInitialTrailers());
            break;
        case isAppTypeWorkingTool(path):
            dispatch(fetchInitialWorkingTools());
            break;
        case isAppTypeWorkingMachine(path):
            dispatch(fetchInitialWorkingMachines());
            break;
        case isAppTypeHandMachine(path):
            dispatch(fetchInitialHandMachines());
            break;
        case isAppTypeOfficeMachine(path):
            dispatch(fetchInitialOfficeMachines());
            break;
        case isAppTypeCustomer(path):
            dispatch(fetchInitialCustomers());
            break;
        case isAppTypeService(path):
            dispatch(fetchInitialServices());
            break;
        case isAppTypeSupplier(path):
            dispatch(fetchInitialSuppliers());
            break;
        default:
            return;
    }
};
