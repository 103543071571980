import { useState, useEffect, Dispatch, SetStateAction } from 'react';

function getStorageValue<S>(key: string, defaultValue: S): S {
    // getting stored value
    const saved: string | null = localStorage.getItem(key);
    const initial: any | undefined = saved ? JSON.parse(saved) : undefined;
    return initial !== undefined ? initial : defaultValue;
}

export function useLocalStorage<S>(key: string, defaultValue: S): [S, Dispatch<SetStateAction<S>>] {
    const [value, setValue] = useState<S>(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        // storing input name
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
}
