import React, { useEffect, ReactElement, ChangeEvent, FocusEvent, useState } from 'react';
import '../Forms.css';
import {
    Button,
    Grid,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    InputAdornment,
    Autocomplete,
} from '@mui/material';
import { RequestOriginApp, ShipmentDto, StopDto } from '../../AutoGeneratedAPI/clientApi';
import { addNewShipment, updateOldShipment } from '../../Redux/Actions/Shipment/ShipmentAction';
import { SelectChangeEvent } from '@mui/material';
import GoogleMaps from '../../../Common/CommonComponents/GoogleMaps/GoogleMaps';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { RootState } from '../../Redux/store';
import { setShipment, setStartingStop, setEndingStop } from '../../Redux/Reducers/Shipments/ShipmentFormReducer';
import { useLocalStorage } from '../../hooks/useLocalStorageHook';
import { RequestOriginAppSR } from '../../Extensions/Localizations/Types/Shipment/SR/RequestOriginAppSR';
import { armatureAddress, formatNumberTypeInput, LatLngLiteral, warehouseAddress } from '../../../Common/Common';
import { getTimeDifference } from '../../../Common/DateHandler';
import { CompareDatesAndTimesForTwoStops } from '../../../Common/ShipmentsHandler';
import { addMinutes } from 'date-fns';

type ShipmentFormProps = {
    closeForm(): void;
    closeFormAndLoadShipment(): void;
    closeFormAndOpenTwSideDetails(): void;
};

export default function ShipmentForm({
    closeForm,
    closeFormAndLoadShipment,
    closeFormAndOpenTwSideDetails,
}: ShipmentFormProps): ReactElement {
    const shipment = useAppSelector((state: RootState) => state.shipFormData.shipment);
    const googleMapsApiKey = useAppSelector((state: RootState) => state.loggedEmployee.googleApiKey);

    const [initialShipment, setInitialShipment] = useState<ShipmentDto>(new ShipmentDto());

    const [customers, setCustomers] = useLocalStorage<string[]>('shipmentCustomers', []);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const newShip = new ShipmentDto();
        const newStartingStop = new StopDto();
        const newEndingStop = new StopDto();

        Object.assign(newStartingStop, shipment.startingStop);
        Object.assign(newEndingStop, shipment.endingStop);

        Object.assign(newShip, shipment);

        newShip.startingStop = newStartingStop;
        newShip.endingStop = newEndingStop;

        setInitialShipment(newShip);
    }, []);

    const handleSetInitialCustomer = () => {
        const clientName = shipment.data?.clientName ?? '/';
        const custIndex = customers.findIndex((c) => c.startsWith(clientName + '/**/'));

        if (clientName && clientName.trim() !== '' && custIndex === -1) {
            const address =
                shipment.endingStop?.locationInfo?.address && shipment.endingStop?.locationInfo?.address.trim() !== ''
                    ? shipment.endingStop?.locationInfo?.address.trim()
                    : '/';
            const clientContact =
                shipment.data?.clientContact && shipment.data?.clientContact.trim() !== ''
                    ? shipment.data?.clientContact.trim()
                    : '/';
            const lat = shipment.endingStop?.locationInfo?.coordinates?.lat
                ? shipment.endingStop?.locationInfo?.coordinates?.lat.toString()
                : '0';
            const lng = shipment.endingStop?.locationInfo?.coordinates?.lng
                ? shipment.endingStop?.locationInfo?.coordinates?.lng.toString()
                : '0';
            const newCustValue =
                clientName.trim() + '/**/' + clientContact + '/**/' + address + '/**/' + lat + '/**/' + lng;
            const newCusts = [...customers, newCustValue];
            setCustomers(newCusts.sort());
        }
    };

    const handleChangeLSCustomerAddress = (address: string, lat: number, lng: number) => {
        const clientName = shipment.data?.clientName;
        const clientContact =
            shipment.data?.clientContact && shipment.data?.clientContact.trim() !== ''
                ? shipment.data?.clientContact.trim()
                : '/';
        const custIndex = customers.findIndex((c) => c.startsWith(clientName + '/**/'));

        if (clientName && clientName.trim() !== '' && custIndex > -1) {
            const newCustValue =
                clientName.trim() +
                '/**/' +
                clientContact +
                '/**/' +
                address +
                '/**/' +
                lat.toString() +
                '/**/' +
                lng.toString();

            const newCusts = [...customers.slice(0, custIndex), newCustValue, ...customers.slice(custIndex + 1)];
            setCustomers(newCusts);
        }
    };

    const handleChangeLSCustomerContact = (clientContact: string) => {
        const clientName = shipment.data?.clientName;

        if (clientName && clientName.trim() !== '') {
            const custIndex = customers.findIndex((c) => c.startsWith(clientName.trim() + '/**/'));
            if (custIndex > -1) {
                const cust = customers[custIndex];
                const custInfo = cust.split('/**/');
                custInfo[1] = clientContact.trim() !== '' ? clientContact.trim() : '/';
                const newCust = custInfo.join('/**/');
                const newCusts = [...customers.slice(0, custIndex), newCust, ...customers.slice(custIndex + 1)];
                setCustomers(newCusts);
            }
        }
    };

    const handleShipmentInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value, name, type } = event.target;

        if (name === 'loadingTimeDiff') {
            const minutes = +value;
            if (minutes && !isNaN(minutes) && minutes >= 0) {
                const newStartingStop = shipment.startingStop;

                const loadingEndingTime = newStartingStop.endingTime;
                const loadingStartingTime = addMinutes(loadingEndingTime, 0 - minutes);

                newStartingStop.startingTime = loadingStartingTime;

                const newShip = new ShipmentDto({ ...shipment, startingStop: newStartingStop });
                dispatch(setShipment(newShip));
            } else {
                const newStartingStop = shipment.startingStop;

                const loadingStartingTime = newStartingStop.endingTime;

                newStartingStop.startingTime = loadingStartingTime;

                const newShip = new ShipmentDto({ ...shipment, startingStop: newStartingStop });
                dispatch(setShipment(newShip));
            }
        } else if (name === 'unloadingTimeDiff') {
            const minutes = +value;
            if (minutes && !isNaN(minutes) && minutes >= 0) {
                const newEndingStop = shipment.endingStop;

                const unloadingStartingTime = newEndingStop.startingTime;
                const unloadingEndingTime = addMinutes(unloadingStartingTime, minutes);

                newEndingStop.endingTime = unloadingEndingTime;

                const newShip = new ShipmentDto({ ...shipment, endingStop: newEndingStop });
                dispatch(setShipment(newShip));
            } else {
                const newEndingStop = shipment.endingStop;

                const unloadingStartingTime = newEndingStop.endingTime;

                newEndingStop.startingTime = unloadingStartingTime;

                const newShip = new ShipmentDto({ ...shipment, endingStop: newEndingStop });
                dispatch(setShipment(newShip));
            }
        } else {
            if (type === 'number') {
                const newVal = +value;
                if (newVal && !isNaN(newVal) && newVal >= 0) {
                    const newShip = new ShipmentDto({ ...shipment, [name]: newVal });
                    dispatch(setShipment(newShip));
                } else {
                    const newShip = new ShipmentDto({ ...shipment, [name]: 0 });
                    dispatch(setShipment(newShip));
                }
            } else {
                const newShip = new ShipmentDto({ ...shipment, [name]: value });

                dispatch(setShipment(newShip));
            }
        }
    };

    const handleShipmentRequestOriginAppSelectElement = (event: SelectChangeEvent<RequestOriginApp>): void => {
        const { value, name } = event.target;

        if (value === RequestOriginApp[RequestOriginApp.Armature]) {
            const newShip = new ShipmentDto({
                ...shipment,
                [name]: value,
                startingStop: {
                    ...shipment.startingStop,
                    locationInfo: {
                        address: armatureAddress.address,
                        coordinates: armatureAddress.coordinates,
                    },
                    name: 'Granit aarmiračnica',
                } as StopDto,
            });

            dispatch(setShipment(newShip));
        } else if (value === RequestOriginApp[RequestOriginApp.Warehouse]) {
            const newShip = new ShipmentDto({
                ...shipment,
                [name]: value,
                startingStop: {
                    ...shipment.startingStop,
                    locationInfo: {
                        address: warehouseAddress.address,
                        coordinates: warehouseAddress.coordinates,
                    },
                    name: 'Granit stovariste',
                } as StopDto,
            });

            dispatch(setShipment(newShip));
        } else {
            const newShip = new ShipmentDto({ ...shipment, [name]: value });
            dispatch(setShipment(newShip));
        }
    };

    const handleStartingStopInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value, name } = event.target;

        dispatch(setStartingStop({ ...shipment.startingStop, [name]: value }));
    };

    const handleEndingStopInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value, name } = event.target;

        dispatch(setEndingStop({ ...shipment.endingStop, [name]: value }));
    };

    const handleShipmentDataInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = target.value.trim();
        const name = target.name;

        if (name !== 'clientName') {
            if (name === 'clientContact') {
                handleChangeLSCustomerContact(value);
            }
            const newShip = new ShipmentDto({
                ...shipment,
                data: { ...shipment.data, [name]: value },
            });

            dispatch(setShipment(newShip));
        }
    };

    const handleClientNameSet = (clientName: string | null) => {
        const custIndex = customers.findIndex((c) => c.startsWith(clientName ? clientName + '/**/' : '/#//'));

        const newTitle =
            shipment.title && shipment.title !== '' && !shipment.title.endsWith(' ')
                ? shipment.title
                : clientName
                ? clientName.concat(' ')
                : undefined;

        if (custIndex > -1) {
            const cust = customers[custIndex];
            const custInfo = cust.split('/**/');
            const clientContact = custInfo[1];
            const address = custInfo[2];
            const lat = !isNaN(+custInfo[3]) ? +custInfo[3] : 0;
            const lng = !isNaN(+custInfo[4]) ? +custInfo[4] : 0;
            const newShip = new ShipmentDto({
                ...shipment,
                title: newTitle,
                data: {
                    ...shipment.data,
                    clientName: clientName && clientName.trim() !== '' ? clientName.trim() : '/',
                    clientContact: clientContact,
                },
                endingStop: {
                    ...shipment.endingStop,
                    locationInfo: { address: address, coordinates: { lat, lng } },
                    name: address,
                } as StopDto,
            });
            dispatch(setShipment(newShip));
        } else {
            const newShip = new ShipmentDto({
                ...shipment,
                data: {
                    ...shipment.data,
                    title: newTitle,
                    clientName: clientName && clientName.trim() !== '' ? clientName.trim() : '',
                },
            });

            dispatch(setShipment(newShip));
        }
    };

    const handleClientNameChange = (event: FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;

        handleClientNameSet(value);
    };

    const handleClientNameSelect = (event: ChangeEvent<unknown>, value: string | null): void => {
        handleClientNameSet(value);
    };

    const handleStartingStopLocationChange = (address: string, coordinates: LatLngLiteral): void => {
        dispatch(
            setStartingStop({
                ...shipment.startingStop,
                locationInfo: { address: address.trim(), coordinates: coordinates },
                name: address.trim(),
            }),
        );
    };

    const handleEndingStopLocationChange = (address: string, coordinates: LatLngLiteral): void => {
        if (shipment.requestOriginApp !== RequestOriginApp.Transport) {
            handleChangeLSCustomerAddress(address, coordinates.lat, coordinates.lng);
        }
        dispatch(
            setEndingStop({
                ...shipment.endingStop,
                locationInfo: { address: address.trim(), coordinates: coordinates },
                name: address.trim(),
            }),
        );
    };

    const onSubmit = async () => {
        // Add customer to local storage
        handleSetInitialCustomer();
        if (initialShipment.id && initialShipment.id > 0) {
            let sStop = shipment.startingStop;
            let eStop = shipment.endingStop;

            if (checkIfStopHasChanged(initialShipment.startingStop, shipment.startingStop)) {
                sStop = await GranitClient.updateStop(shipment.startingStop?.id ?? 0, shipment.startingStop as StopDto);
            }
            if (checkIfStopHasChanged(initialShipment.endingStop, shipment.endingStop)) {
                eStop = await GranitClient.updateStop(shipment.endingStop?.id ?? 0, shipment.endingStop as StopDto);
            }

            const newShip = shipment;
            newShip.startingStopId = sStop.id;
            newShip.endingStopId = eStop.id;
            newShip.startingStop = sStop;
            newShip.endingStop = eStop;

            dispatch(updateOldShipment(newShip as ShipmentDto, sStop, eStop));
            closeFormAndOpenTwSideDetails();
        } else {
            // Get or create starting stop
            const strStop = await GranitClient.addNewStop(shipment.startingStop as StopDto);
            // Get or create ending stop
            const endStop = await GranitClient.addNewStop(shipment.endingStop as StopDto);

            // Add shipment
            const newShip = shipment;
            newShip.startingStopId = strStop.id;
            newShip.endingStopId = endStop.id;
            newShip.startingStop = strStop;
            newShip.endingStop = endStop;

            dispatch(addNewShipment(newShip as ShipmentDto, strStop, endStop));
            closeFormAndLoadShipment();
        }
    };

    const checkIfRequiredIsEmpty = (): boolean => {
        return !shipment.title ||
            shipment.title === '' ||
            !shipment.transportPrice ||
            shipment.transportPrice <= 0 ||
            !shipment.loadValue ||
            shipment.loadValue < 0 ||
            shipment.loadWeight < 0 ||
            shipment.loadVolume < 0 ||
            shipment.loadPalletSpaces < 0 ||
            !shipment.data?.invoiceCode ||
            shipment.data?.invoiceCode === '' ||
            !shipment.data?.clientName ||
            shipment.data?.clientName === '' ||
            !shipment.data?.clientContact ||
            shipment.data?.clientContact === '' ||
            !checkIfStopHasRequired(shipment.startingStop) ||
            !checkIfStopHasRequired(shipment.endingStop)
            ? true
            : false;
    };

    const checkIfDataIsChanged = (): boolean => {
        return initialShipment.title !== shipment.title ||
            initialShipment.transportPrice != shipment.transportPrice ||
            initialShipment.loadValue != shipment.loadValue ||
            initialShipment.loadWeight != shipment.loadWeight ||
            initialShipment.loadVolume != shipment.loadVolume ||
            initialShipment.loadPalletSpaces != shipment.loadPalletSpaces ||
            initialShipment.data?.invoiceCode !== shipment.data?.invoiceCode ||
            initialShipment.data?.clientName !== shipment.data?.clientName ||
            initialShipment.data?.clientContact !== shipment.data?.clientContact ||
            initialShipment.data?.invoiceName !== shipment.data?.invoiceName ||
            checkIfStopHasChanged(initialShipment.startingStop, shipment.startingStop) ||
            checkIfStopHasChanged(initialShipment.endingStop, shipment.endingStop)
            ? true
            : false;
    };

    const checkIfStopHasChanged = (firstStop?: StopDto, secondStop?: StopDto): boolean => {
        return !firstStop || !secondStop
            ? false
            : firstStop.locationInfo?.address !== secondStop.locationInfo?.address ||
              firstStop.locationInfo?.coordinates?.lat !== secondStop.locationInfo?.coordinates?.lat ||
              firstStop.locationInfo?.coordinates?.lng !== secondStop.locationInfo?.coordinates?.lng ||
              firstStop.name !== secondStop.name ||
              firstStop.stopType !== secondStop.stopType ||
              !CompareDatesAndTimesForTwoStops(firstStop, secondStop)
            ? true
            : false;
    };

    const checkIfStopHasRequired = (stop?: StopDto): boolean => {
        return !stop ||
            !stop.locationInfo ||
            !stop.locationInfo.address ||
            stop.locationInfo.address === '' ||
            !stop.locationInfo.coordinates ||
            !stop.locationInfo.coordinates.lat ||
            stop.locationInfo.coordinates.lat === '' ||
            !stop.locationInfo.coordinates.lng ||
            stop.locationInfo.coordinates.lng === '' ||
            !stop.name ||
            stop.name === '' ||
            !stop.stopType
            ? false
            : true;
    };

    const isRequiredFulfilled = !checkIfRequiredIsEmpty() && checkIfDataIsChanged();

    const ShipmentFormElement = (): ReactElement => {
        const loadingTimeDiff = getTimeDifference(shipment.startingStop.endingTime, shipment.startingStop.startingTime);
        const loadingTimeDiffInMinutes = loadingTimeDiff.hours * 60 + loadingTimeDiff.minutes;

        const unloadingTimeDiff = getTimeDifference(shipment.endingStop.endingTime, shipment.endingStop.startingTime);
        const unloadingTimeDiffInMinutes = unloadingTimeDiff.hours * 60 + unloadingTimeDiff.minutes;

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id={Math.random().toString()}
                        label="Naslov isporuke"
                        name="title"
                        value={shipment.title ?? ''}
                        variant="outlined"
                        size="small"
                        onChange={handleShipmentInputElementChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        fullWidth
                        required={true}
                        error={!shipment.requestOriginApp ? true : false}
                        size="small"
                    >
                        <InputLabel id="stopType">Pošiljalac zahteva</InputLabel>
                        <Select
                            labelId="stopType"
                            label="Pošiljalac zahteva"
                            id="demo-simple-select"
                            value={shipment.requestOriginApp ?? ''}
                            name="requestOriginApp"
                            onChange={handleShipmentRequestOriginAppSelectElement}
                        >
                            {Object.values(RequestOriginApp).map((roa) => {
                                return (
                                    <MenuItem key={roa} value={roa as RequestOriginApp}>
                                        {RequestOriginAppSR[roa as RequestOriginApp]}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <hr style={{ width: '100%' }} />
                {googleMapsApiKey &&
                    googleMapsApiKey !== '' &&
                    shipment.requestOriginApp === RequestOriginApp.Transport && (
                        <>
                            <Grid item xs={12}>
                                <Typography component="p" variant="body1" align="left">
                                    Lokacija utovara
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ width: '80%', margin: 'auto', position: 'relative' }}>
                                    <GoogleMaps
                                        handleAddingAddress={handleStartingStopLocationChange}
                                        currLocation={shipment.startingStop?.locationInfo}
                                        label="Adresa utovara"
                                        apiKey={googleMapsApiKey}
                                    />
                                </div>
                            </Grid>
                        </>
                    )}
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id={Math.random().toString()}
                        label="Napomena za utovar"
                        name="note"
                        value={shipment.startingStop?.note ?? ''}
                        variant="outlined"
                        size="small"
                        multiline
                        rows={3}
                        onChange={handleStartingStopInputElementChange}
                    />
                </Grid>
                <hr style={{ width: '100%' }} />
                <Grid item xs={12}>
                    <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={customers.map((option) => option.slice(0, option.indexOf('/**/')))}
                        value={shipment.data?.clientName ?? ''}
                        onChange={handleClientNameSelect}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                id={Math.random().toString()}
                                label="Ime klijenta"
                                name="clientName"
                                variant="outlined"
                                size="small"
                                error={!shipment.data?.clientName || shipment.data?.clientName === ''}
                                required={true}
                                style={{ backgroundColor: '#f7f7f7', borderRadius: 5 }}
                                // onChange={handleShipmentDataInputElementChange}
                                onBlur={handleClientNameChange}
                            />
                        )}
                    />
                </Grid>
                {shipment.data?.clientName !== undefined && shipment.data?.clientName !== '' && (
                    <>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id={Math.random().toString()}
                                label="Kontakt"
                                name="clientContact"
                                value={shipment.data?.clientContact ?? ''}
                                required={true}
                                error={!shipment.data?.clientContact || shipment.data?.clientContact === ''}
                                variant="outlined"
                                size="small"
                                onChange={handleShipmentDataInputElementChange}
                            />
                        </Grid>
                        {googleMapsApiKey && googleMapsApiKey !== '' && (
                            <>
                                <Grid item xs={12}>
                                    <Typography component="p" variant="body1" align="left">
                                        Lokacija istovara
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ width: '80%', margin: 'auto', position: 'relative' }}>
                                        <GoogleMaps
                                            handleAddingAddress={handleEndingStopLocationChange}
                                            currLocation={shipment.endingStop?.locationInfo}
                                            label="Adresa istovara"
                                            apiKey={googleMapsApiKey}
                                        />
                                    </div>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id={Math.random().toString()}
                                label="Napomena za istovar"
                                name="note"
                                value={shipment.endingStop?.note ?? ''}
                                variant="outlined"
                                size="small"
                                multiline
                                rows={3}
                                onChange={handleEndingStopInputElementChange}
                            />
                        </Grid>
                    </>
                )}

                <hr style={{ width: '100%' }} />
                <Grid item xs={12}>
                    <Typography component="h6" variant="h6" align="left">
                        Račun
                    </Typography>
                </Grid>
                <hr style={{ width: '100%' }} />
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id={Math.random().toString()}
                        label="Broj računa"
                        name="invoiceCode"
                        value={shipment.data?.invoiceCode ?? ''}
                        required
                        error={!shipment.data?.invoiceCode || shipment.data?.invoiceCode === ''}
                        variant="outlined"
                        size="small"
                        onChange={handleShipmentDataInputElementChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id={Math.random().toString()}
                        label="Naziv dokumenta"
                        name="invoiceName"
                        value={shipment.data?.invoiceName ?? ''}
                        variant="outlined"
                        size="small"
                        onChange={handleShipmentDataInputElementChange}
                    />
                </Grid>
                <hr style={{ width: '100%' }} />
                <Grid item xs={12}>
                    <Typography component="h6" variant="h6" align="left">
                        Opšte informacije
                    </Typography>
                </Grid>
                <hr style={{ width: '100%' }} />
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id={Math.random().toString()}
                        label="Trajanje utovara"
                        name="loadingTimeDiff"
                        value={formatNumberTypeInput(loadingTimeDiffInMinutes)}
                        variant="outlined"
                        size="small"
                        type="number"
                        required
                        error={loadingTimeDiffInMinutes <= 0}
                        InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: <InputAdornment position="end">min.</InputAdornment>,
                        }}
                        onChange={handleShipmentInputElementChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id={Math.random().toString()}
                        label="Pretpostavljeno trajanje istovara"
                        name="unloadingTimeDiff"
                        value={formatNumberTypeInput(unloadingTimeDiffInMinutes)}
                        variant="outlined"
                        required
                        error={unloadingTimeDiffInMinutes <= 0}
                        size="small"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: <InputAdornment position="end">min.</InputAdornment>,
                        }}
                        onChange={handleShipmentInputElementChange}
                    />
                </Grid>
                <Grid item xs={12} style={{ height: 10 }} />
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id={Math.random().toString()}
                        label="Cena transporta"
                        name="transportPrice"
                        value={formatNumberTypeInput(shipment.transportPrice)}
                        variant="outlined"
                        size="small"
                        type="number"
                        required
                        error={!shipment.transportPrice || shipment.transportPrice <= 0}
                        InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: <InputAdornment position="end">din.</InputAdornment>,
                        }}
                        onChange={handleShipmentInputElementChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id={Math.random().toString()}
                        label="Vrednost isporuke"
                        name="loadValue"
                        value={formatNumberTypeInput(shipment.loadValue)}
                        variant="outlined"
                        required
                        error={!shipment.loadValue || shipment.loadValue <= 0}
                        size="small"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: <InputAdornment position="end">din.</InputAdornment>,
                        }}
                        onChange={handleShipmentInputElementChange}
                    />
                </Grid>
                <Grid item xs={12} style={{ height: 10 }} />
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        id={Math.random().toString()}
                        label="Težina isporuke"
                        name="loadWeight"
                        value={formatNumberTypeInput(shipment.loadWeight)}
                        variant="outlined"
                        required
                        error={shipment.loadWeight < 0}
                        size="small"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                        }}
                        onChange={handleShipmentInputElementChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        id={Math.random().toString()}
                        label="Zapremina isporuke"
                        name="loadVolume"
                        value={formatNumberTypeInput(shipment.loadVolume)}
                        variant="outlined"
                        required
                        error={shipment.loadVolume < 0}
                        size="small"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: <InputAdornment position="end">m&sup3;</InputAdornment>,
                        }}
                        onChange={handleShipmentInputElementChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        id={Math.random().toString()}
                        label="Paletna mesta"
                        name="loadPalletSpaces"
                        value={formatNumberTypeInput(shipment.loadPalletSpaces)}
                        variant="outlined"
                        required
                        error={shipment.loadPalletSpaces < 0}
                        size="small"
                        type="number"
                        InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: <InputAdornment position="end">pal.</InputAdornment>,
                        }}
                        onChange={handleShipmentInputElementChange}
                    />
                </Grid>
                <Grid item xs={12} style={{ height: 10 }} />
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id={Math.random().toString()}
                        label="Napomena"
                        name="description"
                        value={shipment.note ?? ''}
                        variant="outlined"
                        size="small"
                        multiline
                        rows={6}
                        onChange={handleShipmentInputElementChange}
                    />
                </Grid>
            </Grid>
        );
    };

    return (
        <main style={{ width: '100%', height: 'calc(100vh - 70px)' }}>
            <div
                style={{
                    position: 'absolute',
                    left: 15,
                    top: 0,
                    fontSize: 30,
                    fontFamily: 'Courier New',
                    cursor: 'pointer',
                }}
                onClick={closeForm}
            >
                {'>'}
            </div>
            <Typography component="h5" variant="h5" align="center" style={{ marginBottom: 20, marginTop: 7 }}>
                Isporuka
            </Typography>
            <div style={{ height: 'calc(100vh - 200px)', overflowX: 'hidden', overflowY: 'auto', padding: 20 }}>
                {ShipmentFormElement()}
            </div>
            <div style={{ position: 'absolute', bottom: 80, right: 20, width: 200 }}>
                <Button
                    disabled={!isRequiredFulfilled}
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        onSubmit();
                    }}
                >
                    SAČUVAJ
                </Button>
            </div>
        </main>
    );
}
