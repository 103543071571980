import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';
import './Navbar.css';
import granit_logo from '../assets/img/granit_logo-1.png';
import { NavbarConstElements } from './NavbarConstElements';
import { Link } from 'react-router-dom';
import { routes } from '../../Common/Routes';

export default function Navbar(): ReactElement {
    return (
        <AppBar position="fixed" style={{ zIndex: 9 }}>
            <Toolbar className="flex-container">
                <Link to={routes.mainMenu} className="link">
                    <IconButton edge="start" color="inherit">
                        <img src={granit_logo} style={{ width: 50, height: 50 }} />
                        <Typography variant="h5" color="inherit" style={{ display: 'inline', margin: 'auto' }}>
                            Granit
                        </Typography>
                    </IconButton>
                </Link>
                <NavbarConstElements />
            </Toolbar>
        </AppBar>
    );
}
