import { Grid } from '@mui/material';
import React, { ChangeEvent, ReactElement } from 'react';
import FormTextField from '../../Common/FormTextField';

type ProjectFormProps = {
    defaultValue?: string;
    handleInputElementChange(event: ChangeEvent<HTMLInputElement>): void;
};

export default function ProjectForm({ defaultValue, handleInputElementChange }: ProjectFormProps): ReactElement {
    return (
        <Grid container spacing={5}>
            <FormTextField
                defaultValue={defaultValue ?? ''}
                required={true}
                label="Naziv projekta"
                entityName="name"
                errorCondition={defaultValue ? false : true}
                onChange={handleInputElementChange}
            />
        </Grid>
    );
}
