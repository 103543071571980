import { RecordEntityType } from '../../../Extensions/Entities';
import { createSlice } from '@reduxjs/toolkit';

interface IInitialStateType {
    importantDocs: RecordEntityType[];
}

const initialState: IInitialStateType = {
    importantDocs: [],
};

const impDocsSlice = createSlice({
    name: 'importantDocs',
    initialState,
    reducers: {
        setInitialImpDocs(state, action) {
            state.importantDocs = action.payload ?? state.importantDocs;
        },
        concatImpDocs(state, action) {
            if (action.payload && action.payload.length > 0) {
                state.importantDocs = [...state.importantDocs, ...action.payload];
            }
        },
        addImpDoc(state, action) {
            if (action.payload) {
                const impDocIndex = state.importantDocs.findIndex((e) => {
                    return (e.validFrom ?? new Date()) <= action.payload.validFrom;
                });
                if (impDocIndex >= 0) {
                    const firstHalf = state.importantDocs.slice(0, impDocIndex);
                    const secondHalf = state.importantDocs.slice(impDocIndex);
                    state.importantDocs = [...firstHalf, action.payload, ...secondHalf];
                } else {
                    state.importantDocs = [...state.importantDocs, action.payload];
                }
            }
        },
        updateImpDoc(state, action) {
            const impDocs = state.importantDocs;
            const newImpDoc = action.payload;
            const oldImpDoc = impDocs.find((r) => r.id === newImpDoc.id);
            newImpDoc.documents = oldImpDoc?.documents;
            if (newImpDoc.validFrom === oldImpDoc?.validFrom) {
                const impDocIndex = impDocs.findIndex((r) => {
                    return r.id === newImpDoc.id;
                });
                state.importantDocs = [...impDocs.slice(0, impDocIndex), newImpDoc, ...impDocs.slice(impDocIndex + 1)];
            } else {
                const impDocIndex = state.importantDocs.findIndex((e) => {
                    return (e.validFrom ?? new Date()) <= newImpDoc.validFrom;
                });
                const oldImpDocIndex = impDocs.findIndex((r) => {
                    return r.id === newImpDoc.id;
                });
                const newImpDocs = [...impDocs.slice(0, oldImpDocIndex), ...impDocs.slice(oldImpDocIndex + 1)];
                if (impDocIndex >= 0) {
                    const firstHalf = newImpDocs.slice(0, impDocIndex);
                    const secondHalf = newImpDocs.slice(impDocIndex);
                    state.importantDocs = [...firstHalf, newImpDoc, ...secondHalf];
                } else {
                    state.importantDocs = [...newImpDocs, newImpDoc];
                }
            }
        },
        deleteImpDoc(state, action) {
            const importantDocs = state.importantDocs ?? [];
            const impDocIndex = importantDocs.findIndex((r) => {
                return r.id === action.payload.id;
            });
            if (impDocIndex >= 0) {
                state.importantDocs = [...importantDocs.slice(0, impDocIndex), ...importantDocs.slice(impDocIndex + 1)];
            }
        },
        addImpDocDoc: {
            reducer(state, action) {
                const { impDoc, impDocDocument } = action.payload;
                const impDocs = state.importantDocs ?? [];
                const currImpDoc = state.importantDocs.find((rec) => rec.id === impDoc.id);
                const currImpDocIndex = state.importantDocs.findIndex((rec) => rec.id === impDoc.id);
                if (impDocDocument && currImpDoc) {
                    currImpDoc.documents = (currImpDoc?.documents ?? []).concat(impDocDocument);
                }
                if (currImpDoc) {
                    state.importantDocs = [
                        ...impDocs.slice(0, currImpDocIndex),
                        currImpDoc,
                        ...impDocs.slice(currImpDocIndex + 1),
                    ];
                }
            },
            prepare(impDoc, impDocDocument) {
                return {
                    payload: { impDoc, impDocDocument },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
    },
});

export const {
    setInitialImpDocs,
    addImpDoc,
    updateImpDoc,
    deleteImpDoc,
    concatImpDocs,
    addImpDocDoc,
} = impDocsSlice.actions;

export default impDocsSlice.reducer;
