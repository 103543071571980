import {
    isAppTypeOfficeMachine,
    isAppTypeWorkingMachine,
    isAppTypeWorkingTool,
} from '../Extensions/Selectors/Selectors';

export default {
    getTitle: (isDetails: boolean, path: string): string => {
        return isDetails
            ? isAppTypeWorkingTool(path)
                ? 'Promenite detalje alata'
                : isAppTypeWorkingMachine(path)
                ? 'Promenite detalje radne mašine'
                : isAppTypeOfficeMachine(path)
                ? 'Promenite detalje kancelarijske mašine'
                : 'Promenite detalje ručne mašine'
            : isAppTypeWorkingTool(path)
            ? 'Ubacite novi alat'
            : isAppTypeWorkingMachine(path)
            ? 'Ubacite novu radnu mašinu'
            : isAppTypeOfficeMachine(path)
            ? 'Ubacite novu kancelarijsku mašinu'
            : 'Ubacite novu ručnu mašinu';
    },
};
