import { ContactInfoType } from '../../AutoGeneratedAPI/clientApi';
import { RootState } from '../../Redux/store';
import { routes } from '../../../Common/Routes';

export const isRoleSysAdmin = IsRoleAllowedSelector('System.Admin');
export const isRoleCEO = IsRoleAllowedSelector('CEO');
export const isRoleTransOp = IsRoleAllowedSelector('Transport_Operator');

function IsRoleAllowedSelector(role: string) {
    return (state: RootState): boolean => {
        return state.loggedEmployee.employee?.roles?.find((r) => r === role) !== undefined ? true : false;
    };
}

export const isAppTypeVehicle = (path: string): boolean => {
    return isAppTypeCar(path) || isAppTypeTruck(path) || isAppTypeTrailer(path);
};

export const isAppTypeCar = (path: string): boolean => {
    return path.indexOf(routes.carsBare) != -1;
};

export const isAppTypeTruck = (path: string): boolean => {
    return path.indexOf(routes.trucksBare) != -1;
};

export const isAppTypeTrailer = (path: string): boolean => {
    return path.indexOf(routes.trailersBare) != -1;
};

export const isAppTypeWorkingToolCat = (path: string): boolean => {
    return (
        isAppTypeWorkingTool(path) ||
        isAppTypeWorkingMachine(path) ||
        isAppTypeHandMachine(path) ||
        isAppTypeOfficeMachine(path)
    );
};

export const isAppTypeWorkingTool = (path: string): boolean => {
    return path.indexOf(routes.workingtoolsBare) != -1;
};

export const isAppTypeWorkingMachine = (path: string): boolean => {
    return path.indexOf(routes.workingmachinesBare) != -1;
};

export const isAppTypeHandMachine = (path: string): boolean => {
    return path.indexOf(routes.handmachinesBare) != -1;
};

export const isAppTypeOfficeMachine = (path: string): boolean => {
    return path.indexOf(routes.officemachinesBare) != -1;
};

export const isAppTypeCatalogue = (path: string): boolean => {
    return path.indexOf(routes.catalogue) !== -1;
};

export const isAppTypeCalendar = (path: string): boolean => {
    return path.indexOf(routes.calendar) !== -1;
};

export const isAppTypeChart = (path: string): boolean => {
    return path.indexOf(routes.charts) !== -1;
};

export const isAppTypeTWReport = (path: string): boolean => {
    return path.indexOf(routes.twPrintBase) !== -1;
};

export const isAppTypeClient = (path: string): boolean => {
    return isAppTypeCustomer(path) || isAppTypeService(path) || isAppTypeSupplier(path);
};

export const isAppTypeCustomer = (path: string): boolean => {
    return path.indexOf(routes.customersBare) != -1;
};

export const isAppTypeService = (path: string): boolean => {
    return path.indexOf(routes.servicesBare) != -1;
};

export const isAppTypeSupplier = (path: string): boolean => {
    return path.indexOf(routes.suppliersBare) != -1;
};

export const isAppTypeEmployee = (path: string): boolean => {
    return path.indexOf(routes.driversBare) != -1;
};

export const isAppTypeDriver = (path: string): boolean => {
    return path.indexOf(routes.driversBare) != -1;
};

export const isAppTypeMainMenu = (path: string): boolean => {
    return path.endsWith(routes.firstPage);
};

export const isUpdateForm = (path: string): boolean => {
    return path.indexOf(routes.profile) != -1;
};

export const isProfilePage = (path: string): boolean => {
    return path.indexOf(routes.profile) != -1;
};

export const isRecords = (path: string): boolean => {
    return path.indexOf(routes.records) != -1;
};

export const isExpenses = (path: string): boolean => {
    return path.indexOf(routes.expenses) != -1;
};

export const isInvoices = (path: string): boolean => {
    return path.indexOf(routes.invoices) != -1;
};

export const isProjects = (path: string): boolean => {
    return path.indexOf(routes.projects) != -1;
};

export const isTransportWarrants = (path: string): boolean => {
    return path.indexOf(routes.transportwarrants) != -1;
};

export const isImpDocuments = (path: string): boolean => {
    return path.indexOf(routes.impdocuments) != -1;
};

export const isViberUser = IsViberUser();

function IsViberUser() {
    return (state: RootState) => {
        return state.loggedEmployee.employee?.employee?.contactInfos?.find((ci) => ci.type === ContactInfoType.Viber)
            ? true
            : false;
    };
}
