import { msalInstance } from '../../../auth/AuthProvider';
import { protectedResources } from '../../../authConfig';
import { IMSGraphClient, MSGraphClient } from './msGraphApi';

async function httpFetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
    if (init && init.headers) {
        const allAccounts = msalInstance.getAllAccounts();

        // console.log(allAccounts);

        const msGraphRequest = {
            ...{ scopes: protectedResources.graphMe.scopes },
            account: allAccounts[0],
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        /*const tokenPromise2 = msalInstance
            .acquireTokenSilent(msGraphRequest)
            .then((response) => {
                callMsGraph(response.accessToken).then((response) => setGraphData(response));
            })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .catch((e) => {
                msalInstance.acquireTokenPopup(msGraphRequest).then((response) => {
                    callMsGraph(response.accessToken).then((response) => setGraphData(response));
                });
            });*/

        const tokenPromise = msalInstance.acquireTokenSilent(msGraphRequest);
        const token = await tokenPromise;

        init.headers = {
            ...init?.headers,
            Authorization: 'Bearer ' + token.accessToken,
            'X-AuthSchema': 'AzureAD',
        };
    }

    return fetch(url, init);
}

function MSGraphClientApi(): MSGraphClient {
    const clientApi = new MSGraphClient(baseUrl, {
        fetch: httpFetch,
    });

    return clientApi;
}

const baseUrl = 'https://graph.microsoft.com/v1.0';

export const GraphClient: IMSGraphClient = MSGraphClientApi();
