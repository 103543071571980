import { addMinutes, addSeconds } from 'date-fns';
import { DistanceDuration } from '../../../../Common/Common';
import { TimeSpan } from '../../../../Common/DateHandler';
import { RequestOriginApp, ShipmentDto, StopDto, TransportWarrantDto } from '../../../AutoGeneratedAPI/clientApi';

/**
 * Based on the step size, rounds the starting time to the nearest higher value divisible by step size
 * @param stepSize one step duration in minutes
 * @param realStartingTime starting time
 * @returns rounded starting time
 */
export const getStartingTime = (stepSize: number, realStartingTime: Date): TimeSpan => {
    const realStartingTimeMinutes = realStartingTime.getMinutes();
    const newStartingTime: TimeSpan = { hours: 0, minutes: 0 };
    if (realStartingTimeMinutes % stepSize === 0) {
        newStartingTime.hours = realStartingTime.getHours();
        newStartingTime.minutes = realStartingTime.getMinutes();
    } else {
        const additionalMinutes = stepSize - (realStartingTimeMinutes % stepSize);

        const newStartingLoadingTime = addMinutes(realStartingTime, additionalMinutes);

        newStartingTime.hours = newStartingLoadingTime.getHours();
        newStartingTime.minutes = newStartingLoadingTime.getMinutes();
    }

    return newStartingTime;
};

/**
 * Gets a step size (in minutes) based on the duration of transport warrant, then uses this value to generate an array with time values for every step
 * @param twStartingTime transport warrant starting time
 * @param twDuration duration of the transport warrant
 * @param loadingDuration duration of loading
 * @returns times rounded on a step size
 */
export const getTimeStamps = (twStartingTime: Date, twDuration: number, loadingDuration: number): TimeSpan[] => {
    const totalDuration = twDuration + loadingDuration;
    const totalDurationInMinutes = Math.round(totalDuration / 60);

    const stepSizeInMinutes = totalDurationInMinutes < 120 ? 15 : totalDurationInMinutes < 240 ? 30 : 60;

    const twEndingTime = addSeconds(twStartingTime, twDuration);

    const twStartingLoadingTime = addSeconds(twStartingTime, 0 - loadingDuration);

    const startingTime: TimeSpan = getStartingTime(stepSizeInMinutes, twStartingLoadingTime);

    const timeStamps: TimeSpan[] = [];

    let tempTime = startingTime;

    while (
        tempTime.hours < twEndingTime.getHours() ||
        (tempTime.hours === twEndingTime.getHours() && tempTime.minutes < twEndingTime.getMinutes())
    ) {
        timeStamps.push(tempTime);

        const addedMinutes = tempTime.minutes + stepSizeInMinutes;
        tempTime = { hours: tempTime.hours + Math.floor(addedMinutes / 60), minutes: addedMinutes % 60 };
    }

    return timeStamps;
};

export const getTooltipTitleForTransport = (
    index: number,
    distances: DistanceDuration[],
    durations: DistanceDuration[],
    stop?: StopDto,
    shipment?: ShipmentDto,
): string => {
    return (
        'Put do ' +
        (shipment?.requestOriginApp === RequestOriginApp.Transport
            ? stop?.id === shipment.startingStopId
                ? ' utovarnog mesta za '
                : stop?.id === shipment.endingStopId
                ? ' istovarnog mesta za '
                : ''
            : '') +
        (index < durations.length - 1 ? shipment?.data?.clientName ?? 'nepoznato' : 'kuce') +
        ' (' +
        (distances[index] ? Math.round(distances[index].value / 100) / 10 + 'km' : '') +
        (durations[index] ? (distances[index] ? ', ' : '') + Math.round(durations[index].value / 60) + 'min)' : ')')
    );
};

export const getTooltipTitleForUnloading = (shipment?: ShipmentDto): string => {
    return (
        'Istovar kod ' +
        (shipment?.data?.clientName ?? 'nepoznato') +
        ' (' +
        (shipment?.data?.invoiceCode ?? 'nepoznato') +
        ')'
    );
};

export const getTooltipTitleForLoading = (shipment?: ShipmentDto): string => {
    return (
        'Utovar kod ' +
        (shipment?.data?.clientName ?? 'nepoznato') +
        ' (' +
        (shipment?.data?.invoiceCode ?? 'nepoznato') +
        ')'
    );
};

export const getStartingLoadingTime = (transportWarrant: TransportWarrantDto, loadingTimeInSeconds: number): Date => {
    const twStartingTime = transportWarrant.actStartingTime;

    return addSeconds(twStartingTime, 0 - loadingTimeInSeconds);
};

export const getPredefinedDuraitonInSeconds = (transportWarrant: TransportWarrantDto): number => {
    const twStartingTime = transportWarrant.actStartingTime;
    const twEndingTime = transportWarrant.actEndingTime;
    if (
        !twStartingTime ||
        !twEndingTime ||
        new Date(twStartingTime).toString() === 'Invalid Date' ||
        new Date(twEndingTime).toString() === 'Invalid Date'
    ) {
        return 0;
    }
    return (
        ((twEndingTime.getHours() - twStartingTime.getHours()) * 60 +
            twEndingTime.getMinutes() -
            twStartingTime.getMinutes()) *
        60
    );
};
