import { Grid, Typography } from '@mui/material';
import React, { ReactElement, useMemo } from 'react';
import { formatNumberOnTwoDigits, getTimeDifference } from '../../../Common/DateHandler';
import { useAppSelector } from '../../hooks/hooks';
import { RootState } from '../../Redux/store';
import { ShipmentLoadingShipmentInfoContainer } from './ShipmentLoadingStyled';

export default function ShipmentLoadingShipmentInfo(): ReactElement {
    const currShipment = useAppSelector((state: RootState) => state.twFormData.selectedShipmentForTW);

    const currentStartingStop = currShipment?.startingStop;
    const currentEndingStop = currShipment?.endingStop;

    const loadingTime = useMemo(() => {
        if (currentStartingStop) {
            return getTimeDifference(currentStartingStop.endingTime, currentStartingStop.startingTime);
        }
    }, [currentStartingStop]);

    const unloadingTime = useMemo(() => {
        if (currentEndingStop) {
            return getTimeDifference(currentEndingStop.endingTime, currentEndingStop.startingTime);
        }
    }, [currentEndingStop]);

    const infoElement = (title?: string, elementInfo?: string) => {
        return elementInfo ? (
            <Grid
                item
                xs={12}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    overflow: 'hidden',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                }}
            >
                {title && (
                    <Typography variant="body1" component="p" style={{ display: 'inline-block' }}>
                        {title}:
                    </Typography>
                )}
                <Typography
                    variant="body1"
                    component="p"
                    style={{
                        display: 'inline-block',
                        fontWeight: 'bold',
                        textAlign: 'end',
                    }}
                >
                    {elementInfo} {title === 'Zapremina' && <span>m&sup3;</span>}
                </Typography>
            </Grid>
        ) : (
            <></>
        );
    };

    return (
        <ShipmentLoadingShipmentInfoContainer>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                        {currShipment?.title}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 25 }}>
                    <Typography variant="body1" component="p" style={{ fontSize: 18 }}>
                        Opšte informacije
                    </Typography>
                </Grid>
                <hr style={{ width: '100%' }} />
                {infoElement(
                    'Trajanje utovara',
                    loadingTime?.hours && loadingTime.hours > 0
                        ? formatNumberOnTwoDigits(loadingTime.hours) +
                              ':' +
                              formatNumberOnTwoDigits(loadingTime.minutes ?? 0)
                        : (loadingTime?.minutes ?? 0) + 'min',
                )}
                {infoElement(
                    'Trajanje istovara',
                    unloadingTime?.hours && unloadingTime.hours > 0
                        ? formatNumberOnTwoDigits(unloadingTime.hours) +
                              ':' +
                              formatNumberOnTwoDigits(unloadingTime.minutes ?? 0)
                        : (unloadingTime?.minutes ?? 0) + 'min',
                )}
                {infoElement(
                    'Cena transporta',
                    currShipment?.transportPrice ? currShipment?.transportPrice.toString() + ' din.' : undefined,
                )}
                {infoElement('Dokument', currShipment?.data?.invoiceName)}
                {infoElement('Br. dokumenta', currShipment?.data?.invoiceCode)}
                <Grid item xs={12} style={{ marginTop: 25 }}>
                    <Typography variant="body1" component="p" style={{ fontSize: 18 }}>
                        Informacije o klijentu
                    </Typography>
                </Grid>
                <hr style={{ width: '100%' }} />
                {infoElement('Klijent', currShipment?.data?.clientName)}
                {infoElement('Kontakt', currShipment?.data?.clientContact)}
                {infoElement('Adresa', currentEndingStop?.locationInfo.address)}
                <Grid item xs={12} style={{ marginTop: 25 }}>
                    <Typography variant="body1" component="p" style={{ fontSize: 18 }}>
                        Informacije o tovaru
                    </Typography>
                </Grid>
                <hr style={{ width: '100%' }} />
                {infoElement(
                    'Vrednost',
                    currShipment?.loadValue ? currShipment?.loadValue.toString() + ' din.' : undefined,
                )}
                {infoElement(
                    'Težina',
                    currShipment?.loadWeight ? currShipment?.loadWeight.toString() + ' kg' : undefined,
                )}
                {infoElement('Zapremina', currShipment?.loadVolume ? currShipment?.loadVolume.toString() : undefined)}
                {infoElement(
                    'Paletna mesta',
                    currShipment?.loadPalletSpaces ? currShipment?.loadPalletSpaces.toString() : undefined,
                )}
            </Grid>
        </ShipmentLoadingShipmentInfoContainer>
    );
}
