import { Button } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { LoaderProgress } from './Loader/LoaderProgress';

export type BtnStyleProps = {
    color: 'primary' | 'secondary' | 'success' | 'inherit' | 'error' | 'warning' | 'info';
    variant: 'contained' | 'outlined' | 'text';
    fullWidth?: boolean;
    style?: React.CSSProperties;
};

type SubmitButtonProps = {
    funct(): void;
    useTimer?: boolean;
    disableDuration?: number;
    shouldDisable?: boolean;
    btnStyle: BtnStyleProps;
    buttonText: string;
};

export default function SubmitButton({
    funct,
    useTimer,
    disableDuration,
    shouldDisable,
    btnStyle,
    buttonText,
}: SubmitButtonProps): ReactElement {
    const [btnDisabled, setBtnDisabled] = useState<boolean>(false);

    const onClick = (): void => {
        funct();
        if (!!useTimer) {
            const disDur = (disableDuration ?? 1) * 1000;
            setBtnDisabled(true);

            setTimeout(() => {
                setBtnDisabled(false);
            }, disDur);
        } else {
            setBtnDisabled(!!shouldDisable);
        }
    };

    return (
        <Button
            onClick={onClick}
            color={btnStyle.color}
            variant={btnStyle.variant}
            style={btnStyle.style}
            disabled={shouldDisable || btnDisabled}
            fullWidth={!!btnStyle.fullWidth}
        >
            {btnDisabled ? (
                <div style={{ width: 40, margin: 'auto' }}>
                    <LoaderProgress />
                </div>
            ) : (
                buttonText
            )}
        </Button>
    );
}
