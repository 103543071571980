import React, { ReactElement, MouseEvent, useState } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import { ShipmentDto } from '../../General/AutoGeneratedAPI/clientApi';
import './ShipmentCalendarElement.css';

interface ShipmentCalendarElementProps {
    shipment: ShipmentDto;
    preselectShipmentForTw(shipment: ShipmentDto): void;
    unselectPreselectedShipForTw(): void;
    selectForUpdating(shipment: ShipmentDto): void;
    selectShipmentForLoading(shipment: ShipmentDto): void;
    isTwActive: boolean;
}

export default function ShipmentCalendarElement({
    shipment,
    preselectShipmentForTw,
    unselectPreselectedShipForTw,
    selectForUpdating,
    selectShipmentForLoading,
    isTwActive,
}: ShipmentCalendarElementProps): ReactElement {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClickAnchor = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAnchor = () => {
        setAnchorEl(null);
    };

    const shipmentsCalendarElementMenu = (): ReactElement => {
        return (
            <Menu
                id="simple-shipment-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                    handleCloseAnchor();
                }}
                style={{ width: 300, height: 300 }}
            >
                <MenuItem
                    key={'Change shipment ' + shipment?.id}
                    style={{ whiteSpace: 'normal' }}
                    onClick={() => {
                        selectForUpdating(shipment);
                        handleCloseAnchor();
                    }}
                >
                    <div className="calendar-shipment-menu-item-container">Detalji isporuke</div>
                </MenuItem>
                {isTwActive && (
                    <MenuItem
                        key={'Load shipment' + shipment?.id}
                        style={{ whiteSpace: 'normal' }}
                        onClick={() => selectShipmentForLoading(shipment)}
                    >
                        <div className="calendar-shipment-menu-item-container">Utovari isporuku</div>
                    </MenuItem>
                )}
            </Menu>
        );
    };

    return (
        <>
            <div
                className={'calendar-shipment-element'}
                onMouseOver={() => {
                    preselectShipmentForTw(shipment);
                }}
                onMouseOut={unselectPreselectedShipForTw}
                onClick={handleClickAnchor}
            >
                <Typography
                    gutterBottom
                    variant="body2"
                    component="p"
                    className="calendar-shipment-card-text calendar-shipment-text-white"
                >
                    {shipment.title}
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className="calendar-shipment-card-text calendar-shipment-text-gray"
                >
                    {shipment.data.clientName}, {shipment.data.invoiceCode}
                </Typography>
            </div>

            {shipmentsCalendarElementMenu()}
        </>
    );
}
