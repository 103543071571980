import { Box, TextField, TextFieldProps } from '@mui/material';
import { GridFilterInputValueProps, useGridRootProps } from '@mui/x-data-grid';
import React, { ReactElement } from 'react';
import SyncIcon from '@mui/icons-material/Sync';
import { useAppSelector } from '../../../General/hooks/hooks';
import { RootState } from '../../../General/Redux/store';
import { formatNumberTypeInput } from '../../../Common/Common';

export function InputDateInterval(props: GridFilterInputValueProps): ReactElement {
    const currDate = useAppSelector((state: RootState) => state.calendarData.currentDate);

    const rootProps = useGridRootProps();
    const { item, applyValue, focusElementRef = null } = props;

    const filterTimeout = React.useRef<any>();
    const [filterValueState, setFilterValueState] = React.useState<[Date, Date]>(
        item.value ?? [
            new Date(currDate.getFullYear(), currDate.getMonth(), 1),
            new Date(currDate.getFullYear(), currDate.getMonth() + 1, 1 - 1, 23, 59, 59),
        ],
    );
    const [applying, setIsApplying] = React.useState(false);

    React.useEffect(() => {
        return () => {
            clearTimeout(filterTimeout.current);
        };
    }, []);

    React.useEffect(() => {
        const itemValue = item.value ?? [
            new Date(currDate.getFullYear(), currDate.getMonth(), 1),
            new Date(currDate.getFullYear(), currDate.getMonth() + 1, 1 - 1, 23, 59, 59),
        ];
        setFilterValueState(itemValue);
    }, [item.value]);

    const updateFilterValue = (lowerBound: Date, upperBound: Date) => {
        clearTimeout(filterTimeout.current);
        setFilterValueState([lowerBound, upperBound]);

        setIsApplying(true);
        filterTimeout.current = setTimeout(() => {
            setIsApplying(false);
            applyValue({ ...item, value: [lowerBound, upperBound] });
        }, rootProps.filterDebounceMs);
    };

    const handleUpperFilterChange: TextFieldProps['onChange'] = (event) => {
        const newUpperBound = +event.target.value;
        if (!isNaN(newUpperBound)) {
            const newDate = new Date(
                filterValueState[0].getFullYear(),
                filterValueState[0].getMonth(),
                newUpperBound,
                23,
                59,
                59,
            );
            updateFilterValue(filterValueState[0], newDate);
        }
    };
    const handleLowerFilterChange: TextFieldProps['onChange'] = (event) => {
        const newLowerBound = +event.target.value;
        if (!isNaN(newLowerBound)) {
            const newDate = new Date(
                filterValueState[1].getFullYear(),
                filterValueState[1].getMonth(),
                newLowerBound,
                0,
                0,
                0,
            );
            updateFilterValue(newDate, filterValueState[1]);
        }
    };

    return (
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'end',
                height: 48,
                pl: '20px',
            }}
        >
            <TextField
                name="lower-bound-input"
                placeholder="Od"
                label="Od"
                variant="standard"
                value={formatNumberTypeInput(Number(filterValueState[0].getDate()))}
                onChange={handleLowerFilterChange}
                type="number"
                InputProps={{
                    inputProps: { min: 0, max: new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0).getDate() },
                }}
                inputRef={focusElementRef}
                sx={{ mr: 2 }}
            />
            <TextField
                name="upper-bound-input"
                placeholder="Do"
                label="Do"
                variant="standard"
                value={formatNumberTypeInput(Number(filterValueState[1].getDate()))}
                onChange={handleUpperFilterChange}
                type="number"
                InputProps={
                    applying
                        ? {
                              endAdornment: <SyncIcon />,
                              inputProps: {
                                  min: 0,
                                  max: new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0).getDate(),
                              },
                          }
                        : {
                              inputProps: {
                                  min: 0,
                                  max: new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0).getDate(),
                              },
                          }
                }
            />
        </Box>
    );
}
