/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GranitClient } from '../../../../AutoGeneratedAPI/Extension';
import { RootState } from '../../../../Redux/store';
import EmptyPage from '../../../../EmptyPage';
import { ProjectsSearchToken } from '../../../../Extensions/models/RecordSearchTokenModel';
import MuiTableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import {
    Box,
    Collapse,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { deleteOldProject } from '../../../../Redux/Actions/ConstructionProjects/ConstructionProjectAction';
import ConstructionProjectForm from '../../../../Forms/ConstructionProject/ConstructionProjectForm';
import UpdateConstructionSiteForm from '../../../../Forms/ConstructionProject/UpdateConstructionSiteForm';
import ConfirmDialog from '../../../../Forms/Common/ConfirmationDialog';
import useProjectsSearch from '../../../../Extensions/Hooks/RecordsHooks/ProjectsSearchingHook';
import { ConstructionSiteDto } from '../../../../AutoGeneratedAPI/clientApi';
import { LoaderProgress } from '../../../../../Common/CommonComponents/Loader/LoaderProgress';

const TableCell = styled(
    MuiTableCell,
    {},
)(({}) => ({
    borderBottom: 'unset',
}));

function createData(id: number, title: string) {
    return {
        id,
        title,
    };
}

type ProjectsFeedElementProps = {
    query: ProjectsSearchToken;
    setLastProjectId(lastProjectId: number): void;
};

export default function ProjectsFeedElement({ query, setLastProjectId }: ProjectsFeedElementProps): ReactElement {
    const projects = useSelector((state: RootState) => state.constructionProjects.projects);
    const constructionSites = useSelector((state: RootState) => state.constructionProjects.constructionSites);
    const entity = useSelector((state: RootState) => state.currentEntity.entity);

    const [constrSites, setSites] = useState<ConstructionSiteDto[]>([]);
    const [openProjForm, setOpenProjectForm] = useState(false);
    const [currProjIndex, setCurrProjIndex] = useState<number | undefined>(undefined);
    const [currConSiteIndex, setCurrConSiteIndex] = useState<number | undefined>(undefined);
    const [openConSiteForm, setOpenConstrSiteForm] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [rowIndex, setRowIndex] = useState(0);
    const [deleteProjectDialog, setDeleteProjectDialog] = useState(true);
    const [constructionSiteId, setConstructionSiteId] = useState<number | undefined>(undefined);

    const dispatch = useDispatch();

    const { loading, hasMore, error } = useProjectsSearch(entity?.id ?? 0, query);

    const observer = useRef<IntersectionObserver>();

    const lastProjectRef = useCallback(
        (node: any) => {
            if (loading) {
                return;
            }

            if (observer.current) {
                observer.current.disconnect();
            }
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setLastProjectId(projects[projects.length - 1].id ?? 0);
                }
            });

            if (node) {
                observer.current.observe(node);
            }
        },
        [loading, hasMore],
    );

    const deleteConstructionSite = (index: number) => {
        const siteIndex = constrSites.findIndex((cs) => {
            return cs.id === index;
        });
        GranitClient.deleteConstructionSite(index).then(() => {
            if (siteIndex >= 0) {
                setSites([...constrSites.slice(0, siteIndex), ...constrSites.slice(siteIndex + 1)]);
            }
        });
    };

    const updateConstructionSite = (constrSite: ConstructionSiteDto) => {
        const siteIndex = constrSites.findIndex((cs) => {
            return cs.id === constrSite.id;
        });

        GranitClient.updateConstructionSite(constrSite.id ?? -1, constrSite).then((res) => {
            if (siteIndex >= 0) {
                setSites([...constrSites.slice(0, siteIndex), res, ...constrSites.slice(siteIndex + 1)]);
            }
        });
    };

    const deleteProject = (index: number) => {
        dispatch(deleteOldProject(index));
    };

    const handleOpenProjectForm = (index: number): void => {
        setCurrProjIndex(index);
        setOpenProjectForm(true);
    };

    const handleCloseProjectForm = (): void => {
        setOpenProjectForm(false);
    };

    const handleOpenConstrSiteForm = (index: number): void => {
        setCurrConSiteIndex(index);
        setOpenConstrSiteForm(true);
    };

    const handleCloseConstrSiteForm = (): void => {
        setOpenConstrSiteForm(false);
    };

    const handleOpenConfirmProjectDialog = (currRowIndex: number): void => {
        setRowIndex(currRowIndex);
        setDeleteProjectDialog(true);
        setShowConfirmDialog(true);
    };

    const handleCofirmConfirmDialog = (): void => {
        setShowConfirmDialog(false);
        if (deleteProjectDialog) {
            deleteProject(rowIndex);
        } else {
            deleteConstructionSite(constructionSiteId ?? 0);
        }
    };

    const handleCancelConfirmDialog = (): void => {
        setShowConfirmDialog(false);
    };

    const handleOpenConfirmConstructionSiteDialog = (currConstructionSiteId: number): void => {
        setConstructionSiteId(currConstructionSiteId);
        setDeleteProjectDialog(false);
        setShowConfirmDialog(true);
    };

    const rows = projects?.map((p) => {
        return createData(p.id ?? -1, p.name ? (p.name !== '' ? p.name : 'Nema naziva') : 'Nema naziva');
    });

    const ViewElement = () => {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell>Br.</TableCell>
                                <TableCell>Naziv</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map(
                                (
                                    row: {
                                        id:
                                            | string
                                            | number
                                            | boolean
                                            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                            | React.ReactFragment
                                            | null
                                            | undefined;
                                        title:
                                            | string
                                            | number
                                            | boolean
                                            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                            | React.ReactFragment
                                            | React.ReactPortal
                                            | null
                                            | undefined;
                                    },
                                    index: number,
                                ) => {
                                    const [open, setOpen] = useState(false);
                                    return (
                                        <>
                                            <TableRow key={0} ref={rows.length === index + 1 ? lastProjectRef : null}>
                                                <TableCell>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => setOpen(!open)}
                                                    >
                                                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    onClick={() => handleOpenConfirmProjectDialog(row.id as number)}
                                                >
                                                    <p style={{ cursor: 'pointer' }}>
                                                        <DeleteIcon style={{ fontSize: 20, color: '#B33E41' }} />
                                                    </p>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    onClick={() => handleOpenProjectForm(row.id as number)}
                                                >
                                                    <p style={{ cursor: 'pointer' }}>
                                                        <EditIcon style={{ fontSize: 20, color: '#366E9B' }} />
                                                    </p>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.title}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                                        <Box margin={1}>
                                                            <Typography variant="h6" gutterBottom component="div">
                                                                Gradilišta
                                                            </Typography>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell />
                                                                        <TableCell />
                                                                        <TableCell>Naziv</TableCell>
                                                                        <TableCell>Lokacija</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {constructionSites
                                                                        ?.filter((cs) => cs.projectId === row.id)
                                                                        .map((constSite) => (
                                                                            <TableRow key={constSite.id}>
                                                                                <TableCell
                                                                                    component="th"
                                                                                    scope="row"
                                                                                    onClick={() =>
                                                                                        handleOpenConfirmConstructionSiteDialog(
                                                                                            (constSite.id as number) ??
                                                                                                0,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <p
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                    >
                                                                                        <DeleteIcon
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                color: '#B33E41',
                                                                                            }}
                                                                                        />
                                                                                    </p>
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    component="th"
                                                                                    scope="row"
                                                                                    onClick={() =>
                                                                                        handleOpenConstrSiteForm(
                                                                                            (constSite.id as number) ??
                                                                                                0,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <p
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                    >
                                                                                        <EditIcon
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                color: '#366E9B',
                                                                                            }}
                                                                                        />
                                                                                    </p>
                                                                                </TableCell>
                                                                                <TableCell component="th" scope="row">
                                                                                    {constSite.name}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {constSite.locationInfo?.address ??
                                                                                        ''}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                </TableBody>
                                                            </Table>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                },
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div>{loading && 'Učitava se...'}</div>
                <div>{error && 'Greška'}</div>
                <div>
                    <Modal
                        className="modalStyle"
                        open={openProjForm}
                        aria-labelledby="add-vehicle-form"
                        aria-describedby="adding a vehicle to a database through this form"
                    >
                        <>
                            <ConstructionProjectForm closeForm={handleCloseProjectForm} projectId={currProjIndex} />
                        </>
                    </Modal>
                </div>

                <div>
                    <Modal
                        className="modalStyle"
                        open={openConSiteForm}
                        aria-labelledby="add-vehicle-form"
                        aria-describedby="adding a vehicle to a database through this form"
                    >
                        <>
                            <UpdateConstructionSiteForm
                                closeForm={handleCloseConstrSiteForm}
                                updateSite={updateConstructionSite}
                                constrSiteId={currConSiteIndex ?? 0}
                            />
                        </>
                    </Modal>
                </div>
                <ConfirmDialog
                    visible={showConfirmDialog}
                    title="Potvrda"
                    message={
                        deleteProjectDialog
                            ? 'Da li ste sigurni da želite da obrišete projekat?'
                            : 'Da li ste sigurni da želite da obrišete gradilište?'
                    }
                    onConfirmSubmit={handleCofirmConfirmDialog}
                    onCancelSubmit={handleCancelConfirmDialog}
                />
            </>
        );
    };

    if (!loading && !hasMore && projects.length <= 0) {
        return <EmptyPage title="Nema projekata!" />;
    }
    if (loading && (!projects || projects.length <= 0)) {
        return (
            <div style={{ height: '60vh', width: '60vh', margin: 'auto' }}>
                <LoaderProgress />
            </div>
        );
    }
    return <ViewElement />;
}
