import * as React from 'react';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import car_logo from '../../General/assets/img/car_logo.png';
import truck_logo from '../../General/assets/img/truck_logo.jpg';
import trailer_logo from '../../General/assets/img/trailer_logo.jpg';
import { CarDto, TrailerDto, TruckDto } from '../../General/AutoGeneratedAPI/clientApi';
import { getBaseServerLink } from '../../General/AutoGeneratedAPI/Extension';
import '../../General/Catalogue/Catalogue.css';
import { useState, useEffect, ReactElement } from 'react';
import { PROFILE_IMAGE } from '../../Common/Consts';
import ImageComponent from '../../Common/CommonComponents/ImageComponent';

interface VehiclesCardContainerProps {
    vehicle: CarDto | TruckDto | TrailerDto;
    isCalendar?: boolean;
}

export default function VehiclesCardContainer({ vehicle, isCalendar }: VehiclesCardContainerProps): ReactElement {
    const [profileImageLink, setProfileImageLink] = useState<string>('');

    useEffect(() => {
        const docs = vehicle.documents;
        if (vehicle && docs && docs.length > 0) {
            const doc = docs.find((d) => {
                return d?.category === PROFILE_IMAGE;
            });
            if (doc?.requestPaths) {
                setProfileImageLink(doc.requestPaths[0]);
            }
        }
    }, [vehicle]);

    return (
        <Card className={isCalendar ? 'calendar-card' : 'card'}>
            <CardActionArea>
                <ImageComponent
                    src={
                        profileImageLink !== ''
                            ? getBaseServerLink(profileImageLink)
                            : (vehicle as TruckDto).truckType
                            ? truck_logo
                            : (vehicle as TrailerDto).trailerType
                            ? trailer_logo
                            : car_logo
                    }
                    placeholder={''}
                    isCalendar={isCalendar}
                />
                <CardContent>
                    <Typography
                        gutterBottom
                        variant={isCalendar ? 'body2' : 'subtitle1'}
                        component="p"
                        className="catalogue-card-text"
                    >
                        {vehicle.manufacturer} {vehicle.model}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{ display: isCalendar ? 'none' : 'default' }}
                        className="catalogue-card-text"
                    >
                        {vehicle.licensePlateNum ?? 'Nema br. tablica'}
                    </Typography>
                    <Typography
                        gutterBottom
                        variant={isCalendar ? 'body2' : 'body2'}
                        component={'p'}
                        className="catalogue-card-text"
                    >
                        {vehicle.productionDate
                            ? new Date(vehicle.productionDate as Date).getFullYear()
                            : 'Nema godine proizvodnje'}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
