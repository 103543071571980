import * as React from 'react';
import { ReactElement, useState, ChangeEvent } from 'react';
import Feed from './Feed/Feed';
import './Profile.css';
import AddRecordBtn from '../AddRecordBtn';
import { changeRecordType } from '../../Redux/Reducers/General/RecordTypeReducer';
import { useDispatch, useSelector } from 'react-redux';
import DetailsSidebar from '../Details/DetailsSidebar';
import { isAppTypeClient, isAppTypeVehicle, isProfilePage } from '../../Extensions/Selectors/Selectors';
import { RecordType } from '../../Extensions/Extensions';
import ProfileTabBar from './ProfileTabBar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { RootState } from '../../Redux/store';
import { LoaderProgress } from '../../../Common/CommonComponents/Loader/LoaderProgress';
import { changeRecordTypeBasedOnPath, getCurrEntity, getTabIndexForPath } from './ProfileHelper';
import { useAppSelector } from '../../hooks/hooks';
import FeedSideDetails from '../../SideDetails/FeedSideDetails';
import RightSidebar from '../../../Common/CommonComponents/RightSidebar';
import { Modal } from '@mui/material';
import ExpenseForm from '../Form/ExpenseForm';
import RecordForm from '../Form/RecordForm';
import { VehicleDto } from '../../AutoGeneratedAPI/clientApi';
import { fetchInitialEntities } from '../../../Common/FetchingEntities';

export default function Profile(): ReactElement {
    const recordType = useAppSelector((state: RootState) => state.recordTypes.recordType);

    const [value, setValue] = useState(
        recordType === RecordType.Record
            ? 0
            : recordType === RecordType.Expense
            ? 1
            : recordType === RecordType.TravelExpense
            ? 2
            : recordType === RecordType.ImportantDocument
            ? 4
            : recordType === RecordType.Invoices
            ? 1
            : recordType === RecordType.Projects
            ? 2
            : 0,
    );
    const [openFeedSideDetails, setOpenFeedSideDetails] = useState<boolean>(false);
    const [openFeedForm, setOpenFeedForm] = useState<boolean>(false);

    const currEntity = useSelector((state: RootState) => state.currentEntity.entity);

    const location = useLocation();
    const path = location.pathname;
    const navigate = useNavigate();

    const { entityId, recId, expId, impDocId } = useParams();

    const isClient = isAppTypeClient(path);
    const isVehicle = isAppTypeVehicle(path);

    const isProfile = isProfilePage(path);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchInitialEntities(path, dispatch);
    }, [path]);

    useEffect(() => {
        if (entityId && isProfile) {
            const id = +entityId;
            dispatch(getCurrEntity(id, path));
            setValue(getTabIndexForPath(path));
            dispatch(changeRecordTypeBasedOnPath(path));
        }
    }, [entityId]);

    useEffect(() => {
        if ((recId && !isNaN(+recId)) || (expId && !isNaN(+expId)) || (impDocId && !isNaN(+impDocId))) {
            setOpenFeedSideDetails(true);
        } else {
            setOpenFeedSideDetails(false);
        }
    }, [recId, expId, impDocId]);

    const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
        setValue(newValue);
        if (isClient) {
            if (newValue === 0) {
                if (isNaN(+path.substring(path.lastIndexOf('/') + 1))) {
                    navigate(path.substring(0, path.lastIndexOf('/') + 1) + 'records');
                } else {
                    navigate(
                        path.substring(0, path.substring(0, path.lastIndexOf('/')).lastIndexOf('/') + 1) + 'records',
                    );
                }
                dispatch(changeRecordType(RecordType.Record));
            } else if (newValue === 1) {
                if (isNaN(+path.substring(path.lastIndexOf('/') + 1))) {
                    navigate(path.substring(0, path.lastIndexOf('/') + 1) + 'invoices');
                } else {
                    navigate(
                        path.substring(0, path.substring(0, path.lastIndexOf('/')).lastIndexOf('/') + 1) + 'invoices',
                    );
                }
                dispatch(changeRecordType(RecordType.Invoices));
            } else if (newValue === 2) {
                if (isNaN(+path.substring(path.lastIndexOf('/') + 1))) {
                    navigate(path.substring(0, path.lastIndexOf('/') + 1) + 'projects');
                } else {
                    navigate(
                        path.substring(0, path.substring(0, path.lastIndexOf('/')).lastIndexOf('/') + 1) + 'projects',
                    );
                }
                dispatch(changeRecordType(RecordType.Projects));
            }
        } else {
            if (newValue === 0) {
                if (isNaN(+path.substring(path.lastIndexOf('/') + 1))) {
                    navigate(path.substring(0, path.lastIndexOf('/') + 1) + 'records');
                } else {
                    navigate(
                        path.substring(0, path.substring(0, path.lastIndexOf('/')).lastIndexOf('/') + 1) + 'records',
                    );
                }
                dispatch(changeRecordType(RecordType.Record));
            } else if (newValue === 1) {
                if (isNaN(+path.substring(path.lastIndexOf('/') + 1))) {
                    navigate(path.substring(0, path.lastIndexOf('/') + 1) + 'expenses');
                } else {
                    navigate(
                        path.substring(0, path.substring(0, path.lastIndexOf('/')).lastIndexOf('/') + 1) + 'expenses',
                    );
                }
                dispatch(changeRecordType(RecordType.Expense));
            } else if (newValue === 2) {
                if (isNaN(+path.substring(path.lastIndexOf('/') + 1))) {
                    navigate(path.substring(0, path.lastIndexOf('/') + 1) + 'transportwarrants');
                } else {
                    navigate(
                        path.substring(0, path.substring(0, path.lastIndexOf('/')).lastIndexOf('/') + 1) +
                            'transportwarrants',
                    );
                }
                dispatch(changeRecordType(RecordType.TravelExpense));
            } else if (newValue === 3) {
                if (isNaN(+path.substring(path.lastIndexOf('/') + 1))) {
                    navigate(path.substring(0, path.lastIndexOf('/') + 1) + 'impdocuments');
                } else {
                    navigate(
                        path.substring(0, path.substring(0, path.lastIndexOf('/')).lastIndexOf('/') + 1) +
                            'impdocuments',
                    );
                }
                dispatch(changeRecordType(RecordType.ImportantDocument));
            }
        }
    };

    const handleCloseFeedSideDetails = (): void => {
        setOpenFeedSideDetails(false);
        navigate(path.substring(0, path.lastIndexOf('/')));
    };

    const handleOpenFeedForm = (): void => {
        setOpenFeedForm(true);
    };

    const handleCloseFeedForm = (): void => {
        setOpenFeedForm(false);
    };

    if (currEntity) {
        return (
            <div className="profile">
                <div className={'details-element'}>
                    <DetailsSidebar />
                </div>
                <div className="feed-element">
                    <ProfileTabBar value={value} handleChange={handleChange} />

                    <div className="feed">
                        <Feed />
                    </div>
                    {!(isVehicle && (currEntity as VehicleDto).isDeleted) && <AddRecordBtn />}
                </div>
                <div style={{ position: 'absolute', width: 400 }}>
                    <RightSidebar open={openFeedSideDetails}>
                        <FeedSideDetails
                            recordType={recordType}
                            closeFeedSideDetails={handleCloseFeedSideDetails}
                            openFeedForm={handleOpenFeedForm}
                        />
                    </RightSidebar>
                </div>
                <Modal
                    className="modalStyle"
                    open={openFeedForm}
                    aria-labelledby="add-vehicle-form"
                    aria-describedby="adding a vehicle to a database through this form"
                >
                    <div>
                        {recordType === RecordType.Record || recordType === RecordType.ImportantDocument ? (
                            <RecordForm closeForm={handleCloseFeedForm} recType={recordType} />
                        ) : recordType === RecordType.Expense ? (
                            <ExpenseForm closeForm={handleCloseFeedForm} />
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
            </div>
        );
    }
    return (
        <div style={{ width: 600, height: 600, margin: 'auto' }}>
            <LoaderProgress />
        </div>
    );
}
