import React, { ReactElement, useEffect } from 'react';
import '../App.css';

export default function WeatherForecastWidget(): ReactElement {
    async function insertWidget() {
        if (!document.getElementById('weatherwidget-io-js')) {
            const js = document.createElement('script');
            js.id = 'weatherwidget-io-js';
            js.onload = function () {
                console.log('weather widget loaded');
            };
            js.src = 'https://weatherwidget.io/js/widget.min.js';

            const scriptElement = document.getElementsByTagName('script')[0];

            await scriptElement.parentNode?.insertBefore(js, scriptElement);
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).__weatherwidget_init();
        }
    }

    useEffect(() => {
        console.log('weather widget useEffect');
        insertWidget();
    }, []);

    console.log('weather widget Render');

    return (
        <>
            <a
                className="weatherwidget-io"
                href="https://forecast7.com/sr/44d0120d91/kragujevac/"
                data-label_1="KRAGUJEVAC"
                data-theme="pure"
            >
                KRAGUJEVAC
            </a>
        </>
    );
}
