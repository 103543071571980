import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TransportWarrantDto } from '../../../AutoGeneratedAPI/clientApi';
import { TWSearchTokenModel } from '../../models/RecordSearchTokenModel';
import { isAppTypeDriver, isAppTypeVehicle } from '../../Selectors/Selectors';
import { fetchVehicleTransportWarrants, fetchDriverTransportWarrants, defaultNumberOfEntities } from './API';

type TWSearchType = {
    loading: boolean;
    error: boolean;
    hasMore: boolean;
    transportWarrants: TransportWarrantDto[];
};

export default function useTWSearch(entityId: number, onlyFinished: boolean, query: TWSearchTokenModel): TWSearchType {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [transportWarrants, setTransportWarrants] = useState<TransportWarrantDto[]>([]);

    const location = useLocation();
    const path = location.pathname;

    const IsVehicle = isAppTypeVehicle(path);
    const IsDriver = isAppTypeDriver(path);

    useEffect(() => {
        setLoading(true);
        setError(false);
        IsVehicle
            ? fetchVehicleTransportWarrants(entityId, onlyFinished, query).then(
                  (res) => {
                      if (query.lastEntityId && query.lastEntityId > 0) {
                          setTransportWarrants((prevTws) => {
                              return [...prevTws, ...res];
                          });
                      } else {
                          setTransportWarrants(res);
                      }
                      setHasMore(res.length >= defaultNumberOfEntities);
                      setLoading(false);
                  },
                  (error) => {
                      setError(true);
                      console.log(error.message);
                  },
              )
            : IsDriver
            ? fetchDriverTransportWarrants(entityId, onlyFinished, query).then(
                  (res) => {
                      if (query.lastEntityId && query.lastEntityId > 0) {
                          setTransportWarrants((prevTws) => {
                              return [...prevTws, ...res];
                          });
                      } else {
                          setTransportWarrants(res);
                      }
                      setHasMore(res.length >= defaultNumberOfEntities);
                      setLoading(false);
                  },
                  (error) => {
                      setError(true);
                      console.log(error.message);
                  },
              )
            : console.log('Ne postoje putni nalozi za ovaj tip entiteta');
    }, [query]);

    useEffect(() => {
        setTransportWarrants([]);
    }, [query.endingDate, query.clientId, query.numberOfEntitiesToReturn, query.startingDate]);

    return { loading, error, hasMore, transportWarrants };
}
