import { Grid, Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';
import {
    CompareTwoDates,
    CompareTwoDatesEquality,
    FormatTimeText,
    GetStartOfTheWeek,
    getTimeDifference,
} from '../../Common/DateHandler';
import { RequestOriginApp, TruckDto } from '../../General/AutoGeneratedAPI/clientApi';
import { TimelineViewType } from '../../General/Extensions/Extensions';
import { useAppSelector } from '../../General/hooks/hooks';
import { RootState } from '../../General/Redux/store';
import { getEvent, getEventDuration, InitialType } from '../event-utils/event-utils';
import { times } from './TimelineView';
import { addDays } from 'date-fns';

type TimelineGridItemProps = {
    startingHours: number;
    endingHours: number;
    truck: TruckDto;
    setUpdateFormTW(twId: number): void;
    dayIndex: number;
};

export default function TimelineGridItem({
    startingHours,
    endingHours,
    truck,
    setUpdateFormTW,
    dayIndex,
}: TimelineGridItemProps): ReactElement {
    const currentDate = useAppSelector((state: RootState) => state.calendarData.currentDate);
    const transportWarrants = useAppSelector((state: RootState) => state.calendarData.transportWarrants);
    const timelineViewType = useAppSelector((state: RootState) => state.calendarData.timelineViewType);

    const currentEvents = () => {
        const str = timelineViewType === TimelineViewType.Week ? GetStartOfTheWeek(currentDate) : currentDate;
        const currDate = addDays(str, dayIndex);
        if (currDate) {
            const strDateStart = currDate;
            const strDateEnd = addDays(currDate, 1);
            let items: InitialType[] = [];
            transportWarrants
                ?.filter((tw) => tw.composition.mainVehicleId === truck.id)
                ?.filter(
                    (tw) =>
                        ((CompareTwoDates(strDateStart, tw.actStartingTime) ||
                            CompareTwoDatesEquality(strDateStart, tw.actStartingTime)) &&
                            CompareTwoDates(tw.actStartingTime, strDateEnd)) ||
                        ((CompareTwoDates(strDateStart, tw.actEndingTime) ||
                            CompareTwoDatesEquality(strDateStart, tw.actEndingTime)) &&
                            CompareTwoDates(tw.actEndingTime, strDateEnd)),
                )
                ?.map((tw) => {
                    const totalLoadingTime = tw.transportWarrantItems?.reduce((acc, twi) => {
                        if (twi.shipment.requestOriginApp !== RequestOriginApp.Transport) {
                            const timeDiff = getTimeDifference(
                                twi.shipment.startingStop.endingTime,
                                twi.shipment.startingStop.startingTime,
                            );
                            return acc + timeDiff.hours * 60 + timeDiff.minutes;
                        }
                        return acc;
                    }, 0);

                    const event = getEvent(tw, truck, totalLoadingTime);
                    if (event) {
                        items = items.concat(event);
                    }
                });

            return items;
        }
        return [];
    };

    return (
        <div style={{ width: 'inherit', height: 'inherit', position: 'relative' }}>
            <Grid
                key={Math.random()}
                container
                columns={{ xs: (endingHours - startingHours) * 6 }}
                style={{
                    position: 'absolute',
                    width: 'inherit',
                    height: '70px',
                    border: '1px solid black',
                    borderRight: 'none',
                    borderTop: 'none',
                    borderBottom: 'none',
                }}
            >
                {times.map((time, index) => {
                    if (index >= startingHours && index < endingHours) {
                        return (
                            <Grid
                                item
                                xs={6}
                                style={{ height: 'inherit', border: '1px solid lightgray', borderRight: 'none' }}
                                key={index}
                            ></Grid>
                        );
                    }
                })}
            </Grid>
            {currentEvents().map((event, index) => {
                const str = timelineViewType === TimelineViewType.Week ? GetStartOfTheWeek(currentDate) : currentDate;
                const currDate = addDays(str, dayIndex);
                const {
                    startingTimeInMinutes,
                    durationInMinutes,
                    hasStartedBeforeToday,
                    willEndAfterToday,
                } = getEventDuration(event.start, event.end, currDate, startingHours, endingHours);

                return (
                    durationInMinutes > 0 && (
                        <Grid
                            key={index}
                            container
                            columns={{ xs: (endingHours - startingHours) * 6 }}
                            style={{
                                position: 'absolute',
                                width: 'inherit',
                                height: 50,
                                marginTop: 10,
                            }}
                        >
                            <Grid
                                item
                                xs={(startingTimeInMinutes - event.loadingTime) / 10}
                                style={{ backgroundColor: 'transparent', height: 50, pointerEvents: 'none', zIndex: 2 }}
                            ></Grid>
                            <Tooltip
                                title={
                                    <div>
                                        {event.shipments.map((shipment) => {
                                            return (
                                                <p
                                                    key={shipment}
                                                    style={{
                                                        color: 'white',
                                                        fontSize: 10,
                                                        margin: 2,
                                                    }}
                                                >
                                                    {shipment}
                                                </p>
                                            );
                                        })}
                                    </div>
                                }
                                arrow
                                disableHoverListener={!event.shipments || event.shipments.length === 0}
                            >
                                <Grid
                                    item
                                    xs={(durationInMinutes + event.loadingTime) / 10}
                                    key={index}
                                    style={{
                                        background: `repeating-linear-gradient(
                                            -45deg,
                                            white,
                                            white 3px,
                                            gray 3px,
                                            gray 6px
                                        )`,
                                        height: 50,
                                        border: '1px solid #051628',
                                        borderRadius:
                                            hasStartedBeforeToday && willEndAfterToday
                                                ? 0
                                                : hasStartedBeforeToday
                                                ? '0px 5px 5px 0px'
                                                : willEndAfterToday
                                                ? '5px 0px 0px 5px'
                                                : 5,
                                        cursor: 'pointer',
                                        zIndex: 5,
                                        overflow: 'hidden',
                                        position: 'relative',
                                    }}
                                    onClick={() => setUpdateFormTW(+event.id)}
                                >
                                    <div
                                        style={{
                                            height: '100%',
                                            width: `${Math.round(
                                                (durationInMinutes / (durationInMinutes + event.loadingTime)) * 100,
                                            )}%`,
                                            position: 'absolute',
                                            right: 0,
                                            top: 0,
                                            backgroundColor: event.color,
                                            border: '1px solid #051628',
                                            borderRight: 'none',
                                            borderRadius:
                                                hasStartedBeforeToday && willEndAfterToday
                                                    ? 0
                                                    : hasStartedBeforeToday
                                                    ? '0px 5px 5px 0px'
                                                    : willEndAfterToday
                                                    ? '5px 0px 0px 5px'
                                                    : 5,
                                        }}
                                    >
                                        {timelineViewType !== TimelineViewType.Week && (
                                            <p
                                                style={{
                                                    color: 'white',
                                                    fontSize: 10,
                                                    margin: 2,
                                                }}
                                            >
                                                {FormatTimeText(new Date(event.start))}-
                                                {FormatTimeText(new Date(event.end))}
                                            </p>
                                        )}

                                        {event.shipments.map((shipment) => {
                                            return (
                                                <p
                                                    key={shipment}
                                                    style={{
                                                        color: 'white',
                                                        fontSize: 10,
                                                        margin: 2,
                                                    }}
                                                >
                                                    {timelineViewType !== TimelineViewType.Week
                                                        ? shipment
                                                        : shipment.slice(0, shipment.lastIndexOf('(') - 1)}
                                                </p>
                                            );
                                        })}
                                    </div>
                                </Grid>
                            </Tooltip>
                        </Grid>
                    )
                );
            })}
        </div>
    );
}
