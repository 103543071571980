import React, { ReactElement, useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Typography } from '@mui/material';
import { ServerResponseStatus } from '../General/Extensions/Extensions';
import { LoaderProgress } from '../Common/CommonComponents/Loader/LoaderProgress';
import { Data, pieOptions } from './ChartsHelper';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../General/hooks/hooks';
import { RootState } from '../General/Redux/store';
import { fetchVehicleExpenses } from '../General/Extensions/Hooks/RecordsHooks/API';
import { ExpenseSearchTokenModel } from '../General/Extensions/models/RecordSearchTokenModel';
import { setInitialExpenses } from '../General/Redux/Reducers/General/ExpensesReducer';
import { CompareTwoDates } from '../Common/DateHandler';
import { getPieChartData, getDefaultPieChartData } from './ChartsHelper';

Chart.register(...registerables);

type AllExpensesPieChartProps = {
    pieEndDate: string;
    pieStartDate: string;
};

export default function AllExpensesPieChart({ pieEndDate, pieStartDate }: AllExpensesPieChartProps): ReactElement {
    const trucks = useAppSelector((state: RootState) => state.vehicles.trucks);
    const expenses = useAppSelector((state: RootState) => state.expenses.expenses);

    const [pieData, setPieData] = useState<Data>({ labels: [], datasets: [] });
    const [calcExpensesLoadingStatus, setCalcExpensesLoadingStatus] = useState<ServerResponseStatus>(
        ServerResponseStatus.Success,
    );

    const [searchParams] = useSearchParams();

    const dispatch = useAppDispatch();

    useEffect(() => {
        const vehId = searchParams.get('vehicleId');
        const eDate = new Date(pieEndDate);
        const sDate = new Date(pieStartDate);
        if (vehId && !isNaN(+vehId) && +vehId > 0 && CompareTwoDates(sDate, eDate)) {
            const vehicleId = +vehId;
            const query = new ExpenseSearchTokenModel();
            query.startingDate = sDate;
            query.endingDate = eDate;
            query.numberOfEntitiesToReturn = 10000;
            fetchVehicleExpenses(vehicleId, query).then((res) => {
                dispatch(setInitialExpenses(res));
            });
        } else {
            if (!expenses || expenses.length > 0) {
                dispatch(setInitialExpenses([]));
            }
        }
    }, [searchParams, pieStartDate, pieEndDate]);

    useEffect(() => {
        if (expenses && expenses.length > 0) {
            setCalcExpensesLoadingStatus(ServerResponseStatus.Pending);

            const { lbls, dataset } = getPieChartData(expenses, searchParams.get('vehicleId') ?? '0', trucks ?? []);

            setPieData(() => ({ labels: lbls, datasets: dataset }));
            setCalcExpensesLoadingStatus(ServerResponseStatus.Success);
        } else {
            const { lbls, dataset } = getDefaultPieChartData();

            setPieData(() => ({ labels: lbls, datasets: dataset }));
        }
    }, [expenses]);

    return (
        <div
            style={{
                width: 600,
                height: 500,
                border: '1px solid lightgray',
                borderRadius: 5,
                padding: 20,
                position: 'relative',
            }}
        >
            {calcExpensesLoadingStatus === ServerResponseStatus.Success ? (
                <div style={{ height: 480, overflow: 'clip' }}>
                    <div style={{ height: 600, position: 'relative' }}>
                        <Typography component="h6" variant="h6" style={{ position: 'absolute', letterSpacing: -1 }}>
                            Ukupno potrošeno u periodu:
                        </Typography>
                        <div
                            style={{
                                position: 'absolute',
                                right: 20,
                                top: -2,
                                zIndex: 10,
                                fontSize: 22,
                                letterSpacing: -2,
                            }}
                        >
                            <div style={{ display: 'inline-block' }}>
                                {new Date(pieStartDate).toLocaleDateString('sr-SR')}
                            </div>{' '}
                            -{' '}
                            <div style={{ display: 'inline-block' }}>
                                {new Date(pieEndDate).toLocaleDateString('sr-SR')}
                            </div>
                        </div>
                        <Typography
                            component="h5"
                            variant="h5"
                            style={{ position: 'absolute', top: 35, letterSpacing: -2 }}
                        >
                            {expenses
                                ?.reduce((acc, exp) => {
                                    return acc + exp.price;
                                }, 0)
                                .toLocaleString('sr-SR') ?? 0}{' '}
                            din.
                        </Typography>
                        <Pie
                            data={pieData}
                            style={{ position: 'absolute', zIndex: 9, top: -20 }}
                            options={pieOptions}
                        />
                    </div>
                </div>
            ) : calcExpensesLoadingStatus === ServerResponseStatus.Pending ? (
                <div style={{ height: 250, width: 250, margin: 'auto' }}>
                    <LoaderProgress />
                </div>
            ) : (
                'Greška'
            )}
        </div>
    );
}
