import React, { ReactElement, useMemo } from 'react';
import { Stack, Typography, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { CompareTwoDates } from '../Common/DateHandler';

type AverageConsumptionProps = {
    startDate: string;
    endDate: string;
    averageExpense: number;
    averageFuelConsumption?: number;
    isFuel: boolean;
};

export default function AverageConsumption({
    startDate,
    endDate,
    averageExpense,
    averageFuelConsumption,
    isFuel,
}: AverageConsumptionProps): ReactElement {
    const startDateElement = useMemo(() => {
        const currDate = new Date(startDate);
        const lastYearEndDate = new Date(new Date(endDate).getFullYear() - 1, new Date(endDate).getMonth(), 1);
        const sDate = isFuel
            ? currDate
            : CompareTwoDates(new Date(currDate.getFullYear(), currDate.getMonth(), 1), lastYearEndDate)
            ? lastYearEndDate
            : new Date(currDate.getFullYear(), currDate.getMonth(), 1);

        return <div style={{ display: 'inline-block' }}>{new Date(sDate).toLocaleDateString('sr-SR')}</div>;
    }, [isFuel, startDate]);

    const endDateElement = useMemo(() => {
        const currDate = new Date(endDate);
        const eDate = isFuel ? currDate : new Date(currDate.getFullYear(), currDate.getMonth() + 1, 1);

        return <div style={{ display: 'inline-block' }}>{new Date(eDate).toLocaleDateString('sr-SR')}</div>;
    }, [isFuel, endDate]);

    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <Tooltip
                title={
                    <>
                        Prosečna potrošnja za period: {startDateElement} - {endDateElement}
                    </>
                }
            >
                <InfoIcon style={{ padding: 0, margin: 0 }} />
            </Tooltip>
            <Typography component="h6" variant="h6" style={{ letterSpacing: -1, fontWeight: 'normal', fontSize: 20 }}>
                {Math.round(averageExpense * 10) / 10} RSD/km{' '}
                {averageFuelConsumption ? (
                    <span style={{ fontSize: 18 }}>( {Math.round(averageFuelConsumption * 10) / 10} l/100km)</span>
                ) : (
                    ''
                )}
            </Typography>
        </Stack>
    );
}
