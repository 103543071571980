import { CompareTwoDates, CompareTwoDatesEquality } from '../../../Common/DateHandler';
import {
    ExpenseCategory,
    IKmEventDto,
    ITimeEventDto,
    IWorkingToolExpenseDto,
    IVehicleExpenseDto,
    VehicleExpenseDto,
} from '../../AutoGeneratedAPI/clientApi';

export const isTimeEventExpense = (currentExpense?: IWorkingToolExpenseDto | IVehicleExpenseDto): boolean => {
    return !currentExpense ||
        !currentExpense.expenseType ||
        !currentExpense.expenseType.durationInMonths ||
        currentExpense.expenseType.durationInMonths <= 0
        ? false
        : true;
};

export const isKmEventExpense = (currentExpense?: IWorkingToolExpenseDto | IVehicleExpenseDto): boolean => {
    return !currentExpense ||
        !currentExpense.expenseType ||
        !currentExpense.expenseType.durationInKm ||
        currentExpense.expenseType.durationInKm <= 0
        ? false
        : true;
};

export const isKmEventValid = (currentKmEvent: IKmEventDto, isInKmRingActive: boolean): boolean => {
    return (isInKmRingActive && (!currentKmEvent.inKmToRing || currentKmEvent.inKmToRing <= 0)) ||
        (!isInKmRingActive && (!currentKmEvent.kmWhenToRing || currentKmEvent.kmWhenToRing <= 0)) ||
        !currentKmEvent.title ||
        currentKmEvent.title.trim() === ''
        ? false
        : true;
};

export const isKmEventChanged = (
    newKmEvent: IKmEventDto,
    isInKmRingActive: boolean,
    currentKmEvent?: IKmEventDto,
): boolean => {
    return !currentKmEvent
        ? true
        : !currentKmEvent.title ||
          currentKmEvent.title.trim() === '' ||
          !newKmEvent.title ||
          newKmEvent.title.trim() === ''
        ? false
        : (isInKmRingActive &&
              (!currentKmEvent.inKmToRing ||
                  currentKmEvent.inKmToRing <= 0 ||
                  !newKmEvent.inKmToRing ||
                  newKmEvent.inKmToRing <= 0 ||
                  (currentKmEvent.inKmToRing === newKmEvent.inKmToRing &&
                      currentKmEvent.title === newKmEvent.title &&
                      currentKmEvent.description === newKmEvent.description &&
                      currentKmEvent.kmMargin === newKmEvent.kmMargin))) ||
          (!isInKmRingActive &&
              (!currentKmEvent.kmWhenToRing ||
                  currentKmEvent.kmWhenToRing <= 0 ||
                  !newKmEvent.kmWhenToRing ||
                  newKmEvent.kmWhenToRing <= 0 ||
                  (currentKmEvent.kmWhenToRing === newKmEvent.kmWhenToRing &&
                      currentKmEvent.title === newKmEvent.title &&
                      currentKmEvent.description === newKmEvent.description &&
                      currentKmEvent.kmMargin === newKmEvent.kmMargin)))
        ? false
        : true;
};

export const isTimeEventInTimeRingValid = (currentTimeEvent?: ITimeEventDto): boolean => {
    const startingDate = currentTimeEvent?.startingDate;
    if (!!startingDate) {
        const calculatedRingDate = new Date(
            startingDate?.getUTCFullYear(),
            startingDate?.getUTCMonth() + (currentTimeEvent?.inTimeRing?.months ?? 0),
            startingDate?.getUTCDate() + (currentTimeEvent?.inTimeRing?.days ?? 0),
        );
        if (CompareTwoDates(calculatedRingDate, new Date())) {
            return false;
        }
    } else {
        return false;
    }

    return !currentTimeEvent ||
        !currentTimeEvent.inTimeRing ||
        (!currentTimeEvent.inTimeRing.months && !currentTimeEvent.inTimeRing.days) ||
        (currentTimeEvent.inTimeRing.months <= 0 && currentTimeEvent.inTimeRing.days <= 0)
        ? false
        : true;
};

export const isTimeEventValid = (currentTimeEvent: ITimeEventDto, isInTimeRingActive: boolean): boolean => {
    return (isInTimeRingActive && !isTimeEventInTimeRingValid(currentTimeEvent)) ||
        (!isInTimeRingActive && !currentTimeEvent.ringDate) ||
        !currentTimeEvent.title ||
        currentTimeEvent.title.trim() === ''
        ? false
        : true;
};

export const isTimeEventChanged = (
    newTimeEvent: ITimeEventDto,
    isInTimeRingActive: boolean,
    currentTimeEvent?: ITimeEventDto,
): boolean => {
    return !currentTimeEvent
        ? true
        : !currentTimeEvent.title ||
          currentTimeEvent.title.trim() === '' ||
          !newTimeEvent.title ||
          newTimeEvent.title.trim() === ''
        ? false
        : (isInTimeRingActive &&
              (!isTimeEventInTimeRingValid(currentTimeEvent) ||
                  !isTimeEventInTimeRingValid(newTimeEvent) ||
                  (currentTimeEvent.inTimeRing.days === newTimeEvent.inTimeRing.days &&
                      currentTimeEvent.inTimeRing.months === newTimeEvent.inTimeRing.months &&
                      currentTimeEvent.title === newTimeEvent.title &&
                      currentTimeEvent.description === newTimeEvent.description &&
                      currentTimeEvent.marginInDays === newTimeEvent.marginInDays))) ||
          (!isInTimeRingActive &&
              (!currentTimeEvent.ringDate ||
                  !newTimeEvent.ringDate ||
                  (CompareTwoDatesEquality(currentTimeEvent.ringDate, newTimeEvent.ringDate) &&
                      currentTimeEvent.title === newTimeEvent.title &&
                      currentTimeEvent.description === newTimeEvent.description &&
                      currentTimeEvent.marginInDays === newTimeEvent.marginInDays)))
        ? false
        : true;
};

export const durationError = (currentExpense: IWorkingToolExpenseDto | IVehicleExpenseDto): boolean => {
    return currentExpense?.expenseType &&
        ((currentExpense?.expenseType.durationInKm as number) > 0 ||
            (currentExpense?.expenseType.durationInMonths as number) > 0)
        ? false
        : true;
};

export const isExpenseCategoryFuel = (
    currentExpense: IWorkingToolExpenseDto | IVehicleExpenseDto | undefined,
): boolean => {
    if (!currentExpense) {
        return false;
    }
    return (
        currentExpense?.expenseType &&
        currentExpense.expenseType.expenseCategory &&
        currentExpense?.expenseType.expenseCategory === ExpenseCategory.Fuel
    );
};

export const isExpenseCategoryCalculatorExpense = (
    currentExpense: IWorkingToolExpenseDto | IVehicleExpenseDto | undefined,
): boolean => {
    if (!currentExpense) {
        return false;
    }
    return (
        currentExpense?.expenseType &&
        currentExpense.expenseType.expenseCategory &&
        currentExpense?.expenseType.expenseCategory === ExpenseCategory.CalculatorExpense
    );
};

export const checkIfDataIsChanged = (
    currentExpense?: IWorkingToolExpenseDto | IVehicleExpenseDto,
    oldExpense?: IWorkingToolExpenseDto | IVehicleExpenseDto,
    selectedFiles?: File[],
): boolean => {
    if (oldExpense !== undefined) {
        return currentExpense?.title === oldExpense.title &&
            currentExpense?.description === oldExpense.description &&
            currentExpense?.price === (oldExpense as VehicleExpenseDto).price &&
            currentExpense?.expenseType?.expenseCategory ===
                (oldExpense as VehicleExpenseDto).expenseType?.expenseCategory &&
            (selectedFiles === undefined || selectedFiles.length < 1) &&
            currentExpense?.validFrom === oldExpense?.validFrom &&
            currentExpense?.expenseKm === (oldExpense as VehicleExpenseDto).expenseKm &&
            (currentExpense?.expenseType?.expenseCategory === ExpenseCategory.Fuel
                ? currentExpense?.liters === (oldExpense as VehicleExpenseDto).liters &&
                  currentExpense?.pricePerLiter === (oldExpense as VehicleExpenseDto).pricePerLiter
                : currentExpense.expenseType.durationInKm ===
                      (oldExpense as VehicleExpenseDto).expenseType.durationInKm &&
                  currentExpense.expenseType.durationInMonths ===
                      (oldExpense as VehicleExpenseDto).expenseType.durationInMonths &&
                  currentExpense.expenseType.expenseSubCategory ===
                      (oldExpense as VehicleExpenseDto).expenseType.expenseSubCategory)
            ? false
            : true;
    }
    return currentExpense?.title === '' &&
        currentExpense?.description === '' &&
        (currentExpense?.price as number) <= 0 &&
        (selectedFiles === undefined || selectedFiles.length < 1) &&
        (currentExpense?.expenseType?.expenseCategory === ExpenseCategory.Fuel
            ? (!currentExpense?.liters || currentExpense?.liters <= 0) &&
              (!currentExpense?.expenseKm || currentExpense?.expenseKm <= 0) &&
              (!currentExpense?.pricePerLiter || currentExpense?.pricePerLiter <= 0)
            : (!currentExpense.expenseType.durationInKm || currentExpense.expenseType.durationInKm <= 0) &&
              (!currentExpense.expenseType.durationInMonths || currentExpense.expenseType.durationInMonths <= 0))
        ? false
        : true;
};

export const checkIfRequiredIsNotEmpty = (currentExpense?: IWorkingToolExpenseDto | IVehicleExpenseDto): boolean => {
    return (
        currentExpense?.title !== undefined &&
        currentExpense?.title !== '' &&
        currentExpense?.expenseType !== undefined &&
        currentExpense?.price !== undefined &&
        (currentExpense?.price as number) > 0 &&
        (currentExpense?.expenseType?.expenseCategory === ExpenseCategory.Fuel
            ? currentExpense?.liters !== undefined &&
              currentExpense?.liters > 0 &&
              currentExpense?.pricePerLiter !== undefined &&
              currentExpense?.pricePerLiter > 0 &&
              currentExpense?.expenseKm !== undefined &&
              currentExpense?.expenseKm > 0
            : (currentExpense?.expenseType?.durationInKm !== undefined &&
                  currentExpense?.expenseType?.durationInKm > 0) ||
              (currentExpense?.expenseType?.durationInMonths !== undefined &&
                  currentExpense?.expenseType?.durationInMonths > 0))
    );
};
