import * as React from 'react';
import { ReactElement } from 'react';
import './Catalogue.css';
import { EntitiesType } from '../Extensions/Entities';
import { Collapse, Grid } from '@mui/material';
import VehiclesCardContainer from '../../Vehicles/VehiclesCatalogue/CatalogueCard';
import WorkingToolsCardContainer from '../../WorkingTools/WorkingToolsCatalogue/CatalogueCard';
import { useDispatch, useSelector } from 'react-redux';
import { changeCurrentEntity } from '../Redux/Reducers/General/CurrentEntityReducer';
import { RootState } from '../Redux/store';
import NoDataScreen from './NoDataScreen';
import { LoaderProgress } from '../../Common/CommonComponents/Loader/LoaderProgress';
import CustomersCardContainer from '../../Customers/CustomersCatalogue/CatalogueCard';
import {
    isAppTypeCar,
    isAppTypeClient,
    isAppTypeDriver,
    isAppTypeTrailer,
    isAppTypeVehicle,
    isAppTypeWorkingTool,
} from '../Extensions/Selectors/Selectors';
import { useLocation } from 'react-router-dom';
import { routes } from '../../Common/Routes';
import { Link } from 'react-router-dom';
import { Person } from '@microsoft/mgt-react';
import {
    CarDto,
    ClientDto,
    DriverDto,
    TrailerDto,
    TruckDto,
    WorkingMachineDto,
    WorkingToolDto,
} from '../AutoGeneratedAPI/clientApi';
import GraphDriversCardContainer from '../../Drivers/DriversCatalogue/GraphCatalogueCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function CatalogueElement(): ReactElement {
    const [expandedArchived, setExpandedArchived] = React.useState<boolean>(false);

    const cars = useSelector((state: RootState) => state.vehicles.cars);
    const trucks = useSelector((state: RootState) => state.vehicles.trucks);
    const trailers = useSelector((state: RootState) => state.vehicles.trailers);
    const workingTools = useSelector((state: RootState) => state.workingTools.workingTools);
    const workingMachines = useSelector((state: RootState) => state.workingMachines.workingMachines);
    const handMachines = useSelector((state: RootState) => state.handMachines.handMachines);
    const officeMachines = useSelector((state: RootState) => state.officeMachines.officeMachines);
    const drivers = useSelector((state: RootState) => state.drivers.drivers);
    const customers = useSelector((state: RootState) => state.customers.customers);
    const services = useSelector((state: RootState) => state.customers.services);
    const suppliers = useSelector((state: RootState) => state.customers.suppliers);

    const location = useLocation();
    const path = location.pathname;
    const newBase = path.replace('catalogue', 'profile');

    const isClient = isAppTypeClient(path);
    const isVehicle = isAppTypeVehicle(path);
    const isDriver = isAppTypeDriver(path);
    const isCar = isAppTypeCar(path);
    const isTrailer = isAppTypeTrailer(path);
    const isWorkingTool = isAppTypeWorkingTool(path);

    const dispatch = useDispatch();

    let entities: EntitiesType[] | null = null;
    let archivedEntities: EntitiesType[] | null = null;

    const handleExpandedToggle = () => {
        setExpandedArchived(!expandedArchived);
    };

    switch (path) {
        case routes.cars:
            entities = cars?.filter((c) => !c.isDeleted) ?? null;
            archivedEntities = cars?.filter((c) => c.isDeleted) ?? null;
            break;
        case routes.trucks:
            entities = trucks?.filter((t) => !t.isDeleted) ?? null;
            archivedEntities = trucks?.filter((t) => t.isDeleted) ?? null;
            break;
        case routes.trailers:
            entities = trailers?.filter((t) => !t.isDeleted) ?? null;
            archivedEntities = trailers?.filter((t) => t.isDeleted) ?? null;
            break;
        case routes.drivers:
            entities = drivers;
            break;
        case routes.workingtools:
            entities = workingTools;
            break;
        case routes.workingmachines:
            entities = workingMachines;
            break;
        case routes.officemachines:
            entities = officeMachines;
            break;
        case routes.handmachines:
            entities = handMachines;
            break;
        case routes.customers:
            entities = customers;
            break;
        case routes.services:
            entities = services;
            break;
        case routes.suppliers:
            entities = suppliers;
            break;
    }

    return (
        <>
            <Grid container spacing={1} className="grid-container">
                {entities && entities.length <= 0 && <NoDataScreen />}
                {!entities && (
                    <div style={{ height: 600, width: 600, margin: 'auto' }}>
                        <LoaderProgress />
                    </div>
                )}
                {entities?.map((entity) => {
                    return (
                        entity.id && (
                            <Grid key={entity.id} item xs={12} sm={6} md={4} lg={3}>
                                <Link
                                    to={newBase + '/' + entity.id?.toString() + '/records'}
                                    onClick={() => {
                                        dispatch(changeCurrentEntity(entity));
                                    }}
                                    className="link"
                                >
                                    {isVehicle ? (
                                        <VehiclesCardContainer
                                            vehicle={
                                                isCar
                                                    ? (entity as CarDto)
                                                    : isTrailer
                                                    ? (entity as TrailerDto)
                                                    : (entity as TruckDto)
                                            }
                                        />
                                    ) : isDriver ? (
                                        <Person userId={(entity as DriverDto).azObjId}>
                                            <GraphDriversCardContainer template="default" />
                                        </Person>
                                    ) : isClient ? (
                                        <CustomersCardContainer client={entity as ClientDto} />
                                    ) : (
                                        <WorkingToolsCardContainer
                                            workingTool={
                                                isWorkingTool
                                                    ? (entity as WorkingToolDto)
                                                    : (entity as WorkingMachineDto)
                                            }
                                        />
                                    )}
                                </Link>
                            </Grid>
                        )
                    );
                })}
            </Grid>
            {archivedEntities !== null && isVehicle && (
                <div style={{ width: '70vw', margin: 'auto', marginTop: 20, marginBottom: 50 }}>
                    <hr />
                    <div onClick={handleExpandedToggle} style={{ cursor: 'pointer', width: 20 }}>
                        {expandedArchived ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                    <Collapse in={expandedArchived} timeout="auto" unmountOnExit>
                        {archivedEntities.length > 0 ? (
                            <Grid container spacing={1} className="grid-container">
                                {archivedEntities?.map((entity) => {
                                    return (
                                        entity.id && (
                                            <Grid key={entity.id} item xs={12} sm={6} md={4} lg={3}>
                                                <Link
                                                    to={newBase + '/' + entity.id?.toString() + '/records'}
                                                    onClick={() => {
                                                        dispatch(changeCurrentEntity(entity));
                                                    }}
                                                    className="link"
                                                >
                                                    <VehiclesCardContainer
                                                        vehicle={
                                                            isCar
                                                                ? (entity as CarDto)
                                                                : isTrailer
                                                                ? (entity as TrailerDto)
                                                                : (entity as TruckDto)
                                                        }
                                                    />
                                                </Link>
                                            </Grid>
                                        )
                                    );
                                })}
                            </Grid>
                        ) : (
                            <div style={{ width: '100%', height: 500 }}>
                                <div
                                    style={{
                                        width: 500,
                                        textAlign: 'center',
                                        margin: 'auto',
                                        fontSize: 50,
                                        color: 'rgba(0, 0, 0, 0.4)',
                                    }}
                                >
                                    NEMA ARHIVIRANIH VOZILA
                                </div>
                            </div>
                        )}
                    </Collapse>
                </div>
            )}
        </>
    );
}
