import { RecordType } from '../../../Extensions/Extensions';
import { createSlice } from '@reduxjs/toolkit';

interface IRecordTypeReducerState {
    recordType: RecordType | null;
}

const initialState: IRecordTypeReducerState = {
    recordType: RecordType.Record,
};

const recordTypeSlice = createSlice({
    name: 'recordType',
    initialState,
    reducers: {
        changeRecordType(state, action) {
            state.recordType = action.payload;
        },
    },
});

export const { changeRecordType } = recordTypeSlice.actions;

export default recordTypeSlice.reducer;
