import React, { ReactElement } from 'react';
import {
    Fab,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Tooltip,
} from '@mui/material';
import {
    ShipmentLoadingFilterBarContainer,
    ShipmentLoadingSortParamsContainer,
    ShipmentLoadingTimeParamsContainer,
    ShipmentLoadingVerticalLine,
} from './ShipmentLoadingStyled';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/sr';
import AddIcon from '@mui/icons-material/Add';
import { formatNumberOnTwoDigits } from '../../../Common/DateHandler';
import {
    SortingDirection,
    SortingDirectionSR,
    TWSortingParameter,
    TWSortingParameterSR,
} from './ShipmentLoadingCommon';

const hrs = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

type ShipmentLoadingFilterBarProps = {
    currDate?: Date;
    startingHours: number;
    endingHours: number;
    handleCurrentDateChange(date?: Date): void;
    handleSetStartingHours(startHour: number): void;
    handleSetEndingHours(endHour: number): void;
    handleOpenTransportWarrantForm(): void;
    changeTWSortParameter(twSortParam: TWSortingParameter): void;
    changeTWSortDirection(twSortDir: SortingDirection): void;
    sortParam: TWSortingParameter;
    sortDir: SortingDirection;
    twsExist: boolean;
};

export default function ShipmentLoadingFilterBar({
    currDate,
    startingHours,
    endingHours,
    handleCurrentDateChange,
    handleSetStartingHours,
    handleSetEndingHours,
    handleOpenTransportWarrantForm,
    changeTWSortParameter,
    changeTWSortDirection,
    sortParam,
    sortDir,
    twsExist,
}: ShipmentLoadingFilterBarProps): ReactElement {
    const handleChangeStartingHours = (event: SelectChangeEvent<number>): void => {
        const target = event.target;
        const value = +target.value;

        handleSetStartingHours(value);
    };

    const handleChangeEndingHours = (event: SelectChangeEvent<number>): void => {
        const target = event.target;
        const value = +target.value;

        handleSetEndingHours(value);
    };

    const handleChangeSortingParam = (event: SelectChangeEvent<TWSortingParameter | string | undefined>): void => {
        const target = event.target;
        const value = target.value;

        if (!value) {
            changeTWSortParameter(TWSortingParameter.StartingTime);
        } else if (value as TWSortingParameter) {
            changeTWSortParameter(value as TWSortingParameter);
        }
    };

    const handleChangeSortingDir = (event: SelectChangeEvent<SortingDirection | string | undefined>): void => {
        const target = event.target;
        const value = target.value;

        if (!value) {
            changeTWSortDirection(SortingDirection.Ascending);
        } else if (value as SortingDirection) {
            changeTWSortDirection(value as SortingDirection);
        }
    };

    return (
        <ShipmentLoadingFilterBarContainer>
            {twsExist && (
                <>
                    <ShipmentLoadingSortParamsContainer>
                        <Grid container spacing={1}>
                            <Grid item xs={7.5}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="sortingParamLabel">Parametar sort.</InputLabel>
                                    <Select
                                        labelId="sortingParamLabel"
                                        label="Parametar sort."
                                        id="sortingParamSelect"
                                        onChange={handleChangeSortingParam}
                                        value={sortParam}
                                        variant="outlined"
                                        IconComponent={() => null}
                                    >
                                        {Object.values(TWSortingParameter).map((twSP) => {
                                            return (
                                                <MenuItem key={twSP} value={twSP as TWSortingParameter}>
                                                    {TWSortingParameterSR[twSP as TWSortingParameter]}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4.5}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="sortingDirLabel" style={{ fontSize: 14 }}>
                                        Smer sort.
                                    </InputLabel>
                                    <Select
                                        labelId="sortingDirLabel"
                                        label="Smer sort."
                                        id="sortingDirectionSelect"
                                        onChange={handleChangeSortingDir}
                                        value={sortDir}
                                        variant="outlined"
                                        IconComponent={() => null}
                                    >
                                        {Object.values(SortingDirection).map((sD) => {
                                            return (
                                                <MenuItem key={sD} value={sD as SortingDirection}>
                                                    {SortingDirectionSR[sD as SortingDirection]}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ShipmentLoadingSortParamsContainer>
                    <div>
                        <ShipmentLoadingVerticalLine></ShipmentLoadingVerticalLine>
                    </div>
                </>
            )}
            <ShipmentLoadingTimeParamsContainer>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                            <Stack spacing={3}>
                                <DesktopDatePicker
                                    label="Datum"
                                    disableMaskedInput
                                    value={currDate ? new Date(currDate) : new Date()}
                                    inputFormat="dddd DD.MM.YYYY"
                                    onChange={(newValue) => {
                                        handleCurrentDateChange(newValue ? new Date(newValue) : undefined);
                                    }}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="startingHoursLabel">Poč. h</InputLabel>
                            <Select
                                labelId="startingHoursLabel"
                                label="Poč. h"
                                id="startingHoursSelect"
                                onChange={handleChangeStartingHours}
                                value={startingHours}
                                MenuProps={{
                                    PaperProps: {
                                        sx: { maxHeight: 300, display: 'flex', flexDirection: 'column-reverse' },
                                    },
                                }}
                                variant="outlined"
                                IconComponent={() => null}
                            >
                                {hrs.map((sh) => {
                                    return (
                                        sh <= endingHours && (
                                            <MenuItem key={sh} value={sh}>
                                                {formatNumberOnTwoDigits(sh)}
                                            </MenuItem>
                                        )
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="endingHoursLabel">Zav. h</InputLabel>
                            <Select
                                labelId="endingHoursLabel"
                                label="Zav. h"
                                id="endingHoursSelect"
                                value={endingHours}
                                onChange={handleChangeEndingHours}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                variant="outlined"
                                IconComponent={() => null}
                            >
                                {hrs.map((eh) => {
                                    return (
                                        eh >= startingHours && (
                                            <MenuItem key={eh} value={eh}>
                                                {formatNumberOnTwoDigits(eh)}
                                            </MenuItem>
                                        )
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Rezerviši novi transport">
                            <Fab
                                color="primary"
                                aria-label="add"
                                onClick={() => handleOpenTransportWarrantForm()}
                                size="small"
                                style={{ marginTop: -5 }}
                            >
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </Grid>
                </Grid>
            </ShipmentLoadingTimeParamsContainer>
        </ShipmentLoadingFilterBarContainer>
    );
}
