import { createSlice } from '@reduxjs/toolkit';
import { InvoiceDto } from '../../../AutoGeneratedAPI/clientApi';

interface IInitialStateType {
    invoices: InvoiceDto[];
}

const initialState: IInitialStateType = {
    invoices: [],
};

const invoiceReducer = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        setInitialInvoices(state, action) {
            state.invoices = action.payload ?? state.invoices;
        },
        concatInvoices(state, action) {
            if (action.payload && action.payload.length > 0) {
                state.invoices = [...state.invoices, ...action.payload];
            }
        },
        addInvoiceToBeginning(state, action) {
            if (action.payload) {
                state.invoices = [action.payload, ...state.invoices];
            }
        },
        updateInvoice(state, action) {
            const invoice = action.payload;
            const invs = state.invoices ?? [];
            const invIndex = invs.findIndex((i) => {
                return i.id === invoice.id;
            });
            state.invoices = [...invs.slice(0, invIndex), invoice, ...invs.slice(invIndex + 1)];
        },
        deleteOldInvoice(state, action) {
            const invoiceId = action.payload;
            const invs = state.invoices ?? [];
            const invIndex = invs.findIndex((i) => {
                return i.id === invoiceId;
            });
            state.invoices = [...invs.slice(0, invIndex), ...invs.slice(invIndex + 1)];
        },
    },
});

export const {
    addInvoiceToBeginning,
    updateInvoice,
    setInitialInvoices,
    concatInvoices,
    deleteOldInvoice,
} = invoiceReducer.actions;

export default invoiceReducer.reducer;
