import React, { ReactElement, useEffect } from 'react';
import App from './App';
import { isEmployeeAuthenticatedOB, loggedInEmployee } from '../General/Redux/Selectors/EmployeeSelectors';
import { IEmployeeInfoDto } from '../General/AutoGeneratedAPI/clientApi';
import { Authenticate } from '../auth/Authenticate';
import { GranitClient } from '../General/AutoGeneratedAPI/Extension';
import { RootState } from '../General/Redux/store';
import { useLoginData } from '../General/Extensions/useToken';
import { setGoogleApiKey } from '../General/Redux/Reducers/Login/EmployeeReducer';
import { useAppDispatch, useAppSelector } from '../General/hooks/hooks';
import { createUserGroup, getUserGroup } from '../General/Redux/Actions/Login/EmployeeActions';
import {
    fetchInitialKmEvents,
    fetchInitialNotifications,
    fetchInitialTimeEvents,
} from '../General/Redux/Reducers/Notifications/NotificationsReducer';

export default function AppWrapper(): ReactElement {
    const loggedInUser: IEmployeeInfoDto | null = useAppSelector(loggedInEmployee);
    const authenticatedEmpOB = useAppSelector(isEmployeeAuthenticatedOB);
    const { setLoginData } = useLoginData();

    const userInfo = useAppSelector((state: RootState) => state.loggedEmployee.employee?.employee);
    const token = useAppSelector((state: RootState) => state.loggedEmployee.token);
    const expirationDate = useAppSelector((state: RootState) => state.loggedEmployee.expirationDate);
    const userGroup = useAppSelector((state: RootState) => state.loggedEmployee.userGroup);

    const notifications = useAppSelector((state: RootState) => state.notifications.notifications);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (token && expirationDate) {
            if (localStorage.expirationDate) {
                const expTime = new Date(JSON.parse(localStorage.expirationDate)).getTime();
                if (
                    !localStorage.token ||
                    localStorage.token === '' ||
                    localStorage.token !== token ||
                    !localStorage.expirationDate ||
                    expTime < new Date().getTime() ||
                    expTime != expirationDate.getTime()
                ) {
                    setLoginData({ token: token, expirationDate: expirationDate });
                }
            } else {
                setLoginData({ token: token, expirationDate: expirationDate });
            }
        }
    }, [token, expirationDate, userInfo]);

    useEffect(() => {
        if (userInfo && token && expirationDate && localStorage.token && localStorage.expirationDate) {
            GranitClient.getConfig().then((res) => {
                dispatch(setGoogleApiKey(res.googleConfig?.apiKey));
            });
        }
    }, [token, expirationDate, userInfo, localStorage.token, localStorage.expirationDate]);

    /** if (res.googleConfig?.apiKey && res.googleConfig?.apiKey != '') {
        // delete old script if exists
        const oldScript = document.getElementById('google-maps');
        if (oldScript) {
            document.body.removeChild(oldScript);
        }
        // add new script
        const script = document.createElement('script');

        script.src =
            'https://maps.googleapis.com/maps/api/js?key=' +
            res.googleConfig?.apiKey +
            '&libraries=places&language=sr&region=sr';
        script.id = 'google-maps';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    } */

    useEffect(() => {
        if (
            userInfo &&
            userInfo.id &&
            userInfo.id > 0 &&
            !notifications &&
            token &&
            expirationDate &&
            (expirationDate as Date) > new Date() &&
            userGroup &&
            userGroup.id &&
            userGroup.id > 0
        ) {
            GranitClient.checkForValidEvents().then(() => {
                dispatch(fetchInitialNotifications());
                dispatch(fetchInitialTimeEvents());
                dispatch(fetchInitialKmEvents());
            });
        }
    }, [token, expirationDate, userGroup]);

    useEffect(() => {
        if (userInfo && userInfo.id && userGroup === null) {
            dispatch(getUserGroup());
        } else if (userInfo && userInfo.id && userGroup === undefined) {
            dispatch(createUserGroup());
        }
    }, [userInfo, userGroup]);

    return loggedInUser && authenticatedEmpOB ? <App /> : <Authenticate key={'AuthComponent'} />;
}
