import React, { ReactElement } from 'react';
import { TrailerDto, TruckDto } from '../../AutoGeneratedAPI/clientApi';
import TWOccupancyDetails from '../../../Common/CommonComponents/TWOccupancyDetails';
import { useAppSelector } from '../../hooks/hooks';
import { RootState } from '../../Redux/store';

type TransportWarrantSideDetailsElementTwOccupancyProps = {
    truck: TruckDto;
    trailers: TrailerDto[];
};

export default function TransportWarrantSideDetailsElementTwOccupancy({
    truck,
    trailers,
}: TransportWarrantSideDetailsElementTwOccupancyProps): ReactElement {
    const preselectedShipment = useAppSelector((state: RootState) => state.twFormData.preselectedShipmentForTW);
    const currShipments = useAppSelector((state: RootState) => state.twFormData.currentTwInitialShipments);

    return (
        <div>
            <TWOccupancyDetails
                shipments={currShipments.concat(preselectedShipment ? [preselectedShipment] : [])}
                loadPalletSpacesCapacity={
                    truck.loadPalletSpaces +
                        trailers.reduce((acc, tr) => {
                            return acc + tr.loadPalletSpaces;
                        }, 0) ?? 0
                }
                loadWeightCapacity={
                    truck.loadWeight +
                        trailers.reduce((acc, tr) => {
                            return acc + tr.loadWeight;
                        }, 0) ?? 0
                }
                loadVolumeCapacity={
                    truck.loadVolume +
                        trailers.reduce((acc, tr) => {
                            return acc + tr.loadVolume;
                        }, 0) ?? 0
                }
            />
        </div>
    );
}
