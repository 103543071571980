// Exceptions with fetching
export enum ExceptionsWithFetching {
    EXCEPTION_FETCHING_NOTIFICATIONS = 'Greška pri preuzimanju podsetnika',
    EXCEPTION_FETCHING_COMPOSITIONS = 'Greška pri preuzimanju kompozicija',
    EXCEPTION_FETCHING_CARS = 'Greška pri preuzimanju automobila',
    EXCEPTION_FETCHING_TRUCKS = 'Greška pri preuzimanju kamiona',
    EXCEPTION_FETCHING_TRAILERS = 'Greška pri preuzimanju prikolica',
    EXCEPTION_FETCHING_DRIVERS = 'Greška pri preuzimanju vozača',
    EXCEPTION_FETCHING_WORKINGTOOLS = 'Greška pri preuzimanju alata',
    EXCEPTION_FETCHING_WORKINGMACHINES = 'Greška pri preuzimanju radnih mašina',
    EXCEPTION_FETCHING_OFFICEMACHINES = 'Greška pri preuzimanju kanc. mašina',
    EXCEPTION_FETCHING_HANDMACHINES = 'Greška pri preuzimanju ručnih mašina',
    EXCEPTION_FETCHING_RECORDS = 'Greška pri preuzimanju evidencija',
    EXCEPTION_FETCHING_EXPENSES = 'Greška pri preuzimanju troškova',
    EXCEPTION_FETCHING_TRANSPORTWARRANTS = 'Greška pri preuzimanju putnih naloga',
    EXCEPTION_FETCHING_TRAVELWARRANTS = 'Greška pri preuzimanju putnih naloga',
    EXCEPTION_FETCHING_ENGINETYPES = 'Greška pri preuzimanju tipova motora',
    EXCEPTION_FETCHING_TRUCKTYPES = 'Greška pri preuzimanju tipova kamiona',
    EXCEPTION_FETCHING_TRAILERTYPES = 'Greška pri preuzimanju tipova prikolica',
    EXCEPTION_FETCHING_MACHINETYPES = 'Greška pri preuzimanju tipova mašina',
    EXCEPTION_FETCHING_EXPENSETYPES = 'Greška pri preuzimanju tipova troškova',
    EXCEPTION_FETCHING_ALARMTYPES = 'Greška pri preuzimanju tipova alarma',
    EXCEPTION_FETCHING_JOBPOSITIONTYPES = 'Greška pri preuzimanju tipova pozicija',
    EXCEPTION_FETCHING_DOCUMENTS = 'Greška pri preuzimanju dokumenata',
    EXCEPTION_FETCHING_ALARMS = 'Greška pri preuzimanju alarma',
    EXCEPTION_FETCHING_CUSTOMERS = 'Greška pri preuzimanju klijenata',
    EXCEPTION_FETCHING_CUSTOMERTYPES = 'Greška pri preuzimanju tipova klijenata',
    EXCEPTION_FETCHING_ENTITYTYPES = 'Greška pri preuzimanju vrste klijenata',
    EXCEPTION_FETCHING_PROJECTS = 'Greška pri preuzimanju projekata',
    EXCEPTION_FETCHING_CONSTRUCTIONSITES = 'Greška pri preuzimanju gradilišta',
    EXCEPTION_FETCHING_INVOICES = 'Greška pri preuzimanju računa',
    EXCEPTION_FETCHING_SHIPMENTS = 'Greška pri preuzimanju isporuka',
}

export interface TWExceptionModel {
    code: string;
    value: string;
}

// TransportWarrantExceptions
export const ExceptionsWithTransportWarrant: TWExceptionModel[] = [
    {
        code: 'BAD_DATE_RANGE',
        value: 'Vozilo ili vozač je zauzet/o za odabrano vreme, ili vreme nije validno',
    },
    { code: 'BAD_GENERAL', value: 'Doslo je do greske pri odabiru parametara putnog naloga' },
    { code: 'BAD_KM_RANGE', value: 'Kilometraža se ne poklapa sa dosadasnjim unosom' },
    { code: 'INVALID_COMPOSITION', value: 'Vozilo nije dostupno ili nije uneto' },
    { code: 'INVALID_DRIVER', value: 'Vozač nije dostupan ili nije unet' },
];

// Exceptions with adding
export enum ExceptionsWithAdding {
    EXCEPTION_ADDING_COMPOSITIONS = 'Greška pri dodavanju kompozicija',
    EXCEPTION_ADDING_CARS = 'Greška pri dodavanju automobila',
    EXCEPTION_ADDING_TRUCKS = 'Greška pri dodavanju kamiona',
    EXCEPTION_ADDING_TRAILERS = 'Greška pri dodavanju prikolica',
    EXCEPTION_ADDING_DRIVERS = 'Greška pri dodavanju vozača',
    EXCEPTION_ADDING_WORKINGTOOLS = 'Greška pri dodavanju alata',
    EXCEPTION_ADDING_WORKINGMACHINES = 'Greška pri dodavanju radnih mašina',
    EXCEPTION_ADDING_OFFICEMACHINES = 'Greška pri dodavanju kanc. mašina',
    EXCEPTION_ADDING_HANDMACHINES = 'Greška pri dodavanju ručnih mašina',
    EXCEPTION_ADDING_RECORDS = 'Greška pri dodavanju evidencija',
    EXCEPTION_ADDING_EXPENSES = 'Greška pri dodavanju troškova',
    EXCEPTION_ADDING_TRANSPORTWARRANTS = 'Greška pri dodavanju putnih naloga',
    EXCEPTION_ADDING_TRAVELWARRANTS = 'Greška pri dodavanju putnih naloga',
    EXCEPTION_ADDING_DOCUMENT = 'Greška pri dodavanju dokumenta',
    EXCEPTION_ADDING_ALARMS = 'Greška pri dodavanju alarma',
    EXCEPTION_ADDING_CUSTOMERS = 'Greška pri dodavanju klijenta',
    EXCEPTION_ADDING_PROJECT = 'Greška pri dodavanju projekta',
    EXCEPTION_ADDING_CONSTRUCTIONSITE = 'Greška pri dodavanju gradilišta',
    EXCEPTION_ADDING_INVOICE = 'Greška pri dodavanju računa',
    EXCEPTION_ADDING_SHIPMENT = 'Greška pri dodavanju isporuke',
}

// Exceptions with updating
export enum ExceptionsWithUpdating {
    EXCEPTION_UPDATING_COMPOSITIONS = 'Greška pri menjanju kompozicija',
    EXCEPTION_UPDATING_CARS = 'Greška pri menjanju automobila',
    EXCEPTION_UPDATING_TRUCKS = 'Greška pri menjanju kamiona',
    EXCEPTION_UPDATING_TRAILERS = 'Greška pri menjanju prikolica',
    EXCEPTION_UPDATING_DRIVERS = 'Greška pri menjanju vozača',
    EXCEPTION_UPDATING_WORKINGTOOLS = 'Greška pri menjanju alata',
    EXCEPTION_UPDATING_WORKINGMACHINES = 'Greška pri menjanju radnih mašina',
    EXCEPTION_UPDATING_OFFICEMACHINES = 'Greška pri menjanju kanc. mašina',
    EXCEPTION_UPDATING_HANDMACHINES = 'Greška pri menjanju ručnih mašina',
    EXCEPTION_UPDATING_RECORDS = 'Greška pri menjanju evidencija',
    EXCEPTION_UPDATING_EXPENSES = 'Greška pri menjanju troškova',
    EXCEPTION_UPDATING_TRANSPORTWARRANTS = 'Greška pri menjanju putnih naloga',
    EXCEPTION_UPDATING_TRAVELWARRANTS = 'Greška pri menjanju putnih naloga',
    EXCEPTION_UPDATING_ALARMS = 'Greška pri menjanju alarma',
    EXCEPTION_UPDATING_NOTIFICATIONS = 'Greška pri menjanju podsetnika',
    EXCEPTION_UPDATING_CUSTOMERS = 'Greška pri menjanju klijenta',
    EXCEPTION_UPDATING_PROJECTS = 'Greška pri menjanju projekta',
    EXCEPTION_UPDATING_CONSTRUCTIONSITES = 'Greška pri menjanju gradilišta',
    EXCEPTION_UPDATING_INVOICE = 'Greška pri menjanju računa',
    EXCEPTION_UPDATING_SHIPMENT = 'Greška pri menjanju isporuke',
}

export enum ExceptionsWithDeleting {
    EXCEPTION_DELETING_RECORDS = 'Greška pri brisanju dokumenta',
    EXCEPTION_DELETING_EXPENSES = 'Greška pri brisanju troška',
    EXCEPTION_DELETING_CUSTOMERS = 'Greška pri brisanju klijenta',
    EXCEPTION_DELETING_CONSTRUCTIONSITE = 'Greška pri brisanju gradilišta',
    EXCEPTION_DELETING_PROJECT = 'Greška pri brisanju projekta',
    EXCEPTION_DELETING_INVOICE = 'Greška pri brisanju računa',
    EXCEPTION_DELETING_SHIPMENT = 'Greška pri brisanju isporuke',
}
