import { WorkingToolDto } from '../../../AutoGeneratedAPI/clientApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithFetching } from '../../../Extensions/Exceptions';

interface IInitialStateType {
    workingTools: WorkingToolDto[] | null;
}

const initialState: IInitialStateType = {
    workingTools: null,
};

export const fetchInitialWorkingTools = createAsyncThunk(
    'workingTools/fetchInitialWorkingTools',
    async () => {
        const response = await GranitClient.getAllWorkingTools();
        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingWTools: WorkingToolDto[] | null = thunkAPI.getState().workingTools.workingTools;

            return existingWTools === null;
        },
    },
);

const workingToolsSlice = createSlice({
    name: 'workingTools',
    initialState,
    reducers: {
        addWorkingTool(state, action) {
            state.workingTools = [action.payload, ...(state.workingTools ?? [])];
        },
        updateWorkingTool: {
            reducer(state, action) {
                const { workingTool, oldWorkingTool } = action.payload;
                const workingTools = state.workingTools ?? [];
                const workingToolIndex = workingTools.findIndex((wt) => {
                    return wt.id === oldWorkingTool.id;
                });
                state.workingTools = [
                    ...workingTools.slice(0, workingToolIndex),
                    workingTool,
                    ...workingTools.slice(workingToolIndex + 1),
                ];
            },
            prepare(workingTool, oldWorkingTool) {
                return {
                    payload: { workingTool, oldWorkingTool },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
        changeWorkingToolProfileImage: {
            reducer(state, action) {
                const wtIndex = state.workingTools?.findIndex((wt) => {
                    return wt.id === action.payload.workingToolId;
                });
                if (state.workingTools && wtIndex !== undefined && wtIndex >= 0) {
                    const wt = state.workingTools[wtIndex];
                    wt.documents = [action.payload.doc, ...(wt.documents?.slice(1) ?? [])];
                    state.workingTools = [
                        ...state.workingTools.slice(0, wtIndex),
                        wt,
                        ...state.workingTools.slice(wtIndex + 1),
                    ];
                }
            },
            prepare(workingToolId, doc) {
                return {
                    payload: { workingToolId, doc },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInitialWorkingTools.fulfilled, (state, action) => {
            state.workingTools = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialWorkingTools.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_WORKINGTOOLS);
        });
    },
});

export const { addWorkingTool, updateWorkingTool, changeWorkingToolProfileImage } = workingToolsSlice.actions;

export default workingToolsSlice.reducer;
