import React, { ChangeEvent, ReactElement, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NameDataPair } from '../../Extensions/Extensions';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import { updateOldProject } from '../../Redux/Actions/ConstructionProjects/ConstructionProjectAction';
import {
    addConstrucionSite,
    addProjectToBeginning,
} from '../../Redux/Reducers/ConstructionProject/ConstructionProjectReducer';
import { RootState } from '../../Redux/store';
import FormDataReview from '../Common/FormDataReview';
import FormTemplate from '../Common/FormTemplate';
import ConstructionSiteForm from './Pages/ConstructionSiteForm';
import ProjectForm from './Pages/ProjectForm';
import { useLocation } from 'react-router-dom';
import { isUpdateForm } from '../../Extensions/Selectors/Selectors';
import { ProjectDto, ConstructionSiteDto, IConstructionSiteDto, IProjectDto } from '../../AutoGeneratedAPI/clientApi';
import { LatLngLiteral } from '../../../Common/Common';

const steps = ['Naziv projekta', 'Gradilišta', 'Pregled unetih informacija'];

type ConstructionProjectFormProps = {
    closeForm(): void;
    isConstrSite?: boolean;
    clientId?: number;
    project?: ProjectDto;
    projectId?: number;
};

export default function ConstructionProjectForm({
    closeForm,
    isConstrSite,
    project,
    clientId,
    projectId,
}: ConstructionProjectFormProps): ReactElement {
    const [activeStep, setActiveStep] = useState(isConstrSite ? 1 : 0);
    const [currProject, setCurrProject] = useState<IProjectDto>(new ProjectDto());
    const [constrSites, setConstrSites] = useState<ConstructionSiteDto[]>([]);
    const [constrSite, setConstrSite] = useState<IConstructionSiteDto>(new ConstructionSiteDto());

    const location = useLocation();
    const path = location.pathname;

    const isUpdateFrm = isUpdateForm(path);
    const currEntity = useSelector((state: RootState) => state.currentEntity.entity);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isUpdateFrm && projectId) {
            GranitClient.getProject(projectId, true)
                .then((res) => setCurrProject(res))
                .then(() =>
                    GranitClient.getAllConstructionSitesForProject(projectId ?? 0).then((res) => setConstrSites(res)),
                );
        }
    }, []);

    const handleProjectNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setCurrProject({ ...currProject, name: event.target.value });
    };

    const handleConstructionSiteNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setConstrSite({ ...constrSite, name: event.target.value });
    };

    const handleConstructionSiteLocationChange = (address: string, coordinates: LatLngLiteral): void => {
        setConstrSite({ ...constrSite, locationInfo: { address: address, coordinates: coordinates } });
    };

    const addConstructionSiteToArray = (): void => {
        const conSites = constrSites.concat(new ConstructionSiteDto(constrSite));
        setConstrSites(conSites);
    };

    const removeConstructionSiteFromArray = (index: number): void => {
        const conSites = constrSites;
        setConstrSites([...conSites.slice(0, index), ...conSites.slice(index + 1)]);
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const onSubmit = (): void => {
        let proj;
        if (project) {
            proj = project;
        } else {
            proj = currProject;
        }
        if (clientId) {
            proj.clientId = clientId;
        } else {
            proj.clientId = currEntity?.id ?? 0;
        }

        if (isUpdateFrm && projectId) {
            constrSites.forEach((cs) => {
                const conSite = cs;
                conSite.projectId = projectId ?? -1;
                GranitClient.addNewConstructionSite(conSite).then((result) => {
                    dispatch(addConstrucionSite(result));
                });
            });
            dispatch(updateOldProject(new ProjectDto(proj)));
        } else {
            GranitClient.addNewProject(new ProjectDto(proj)).then((res) => {
                dispatch(addProjectToBeginning(res));
                constrSites.forEach((cs) => {
                    const conSite = cs;
                    conSite.projectId = res.id;
                    GranitClient.addNewConstructionSite(conSite).then((result) => {
                        dispatch(addConstrucionSite(result));
                    });
                });
            });
        }

        closeForm();
    };

    const getStepContent = () => {
        const displayText: NameDataPair[] = [];
        displayText.push({ name: 'Naziv projekta:', data: currProject.name });
        constrSites.forEach((cs) => displayText.push({ name: 'Naziv gradilišta:', data: cs.name }));
        switch (activeStep) {
            case 0:
                return (
                    <ProjectForm handleInputElementChange={handleProjectNameChange} defaultValue={currProject.name} />
                );
            case 1:
                return (
                    <ConstructionSiteForm
                        constrSite={new ConstructionSiteDto(constrSite)}
                        constructionSites={constrSites}
                        handleNameChange={handleConstructionSiteNameChange}
                        handleLocationChange={handleConstructionSiteLocationChange}
                        handleAddingSiteToArray={addConstructionSiteToArray}
                        handleRemovingSiteFromArray={removeConstructionSiteFromArray}
                    />
                );
            case 2:
                return <FormDataReview displayText={displayText} />;
            default:
                throw new Error('Unknown step');
        }
    };

    const formElem = getStepContent();

    const shouldDisable =
        (activeStep === 1 && constrSites.length <= 0) ||
        (activeStep === 0 && (!currProject.name || currProject.name === ''));

    return (
        <>
            <FormTemplate
                activeStep={activeStep}
                close={closeForm}
                formContent={formElem}
                title={'Projekti / Gradilišta'}
                shouldDisable={shouldDisable}
                submit={onSubmit}
                steps={steps}
                handleNext={handleNext}
                handleBack={handleBack}
            />
        </>
    );
}
