import * as React from 'react';
import { Typography } from '@mui/material';
import car_logo from '../../General/assets/img/car_logo.png';
import truck_logo from '../../General/assets/img/truck_logo.jpg';
import trailer_logo from '../../General/assets/img/trailer_logo.jpg';
import { CarDto, TrailerDto, TruckDto } from '../../General/AutoGeneratedAPI/clientApi';
import { getBaseServerLink } from '../../General/AutoGeneratedAPI/Extension';
import { useState, useEffect, ReactElement } from 'react';
import { PROFILE_IMAGE } from '../../Common/Consts';
import ImageComponent from '../../Common/CommonComponents/ImageComponent';
import '../../General/Forms/TransportWarrantForm/TransportWarrantForm.css';

interface VehicleCalendarElementProps {
    vehicle: CarDto | TruckDto | TrailerDto;
}

export default function VehicleCalendarElement({ vehicle }: VehicleCalendarElementProps): ReactElement {
    const [profileImageLink, setProfileImageLink] = useState<string>('');

    useEffect(() => {
        const docs = vehicle.documents;
        if (vehicle && docs && docs.length > 0) {
            const doc = docs.find((d) => {
                return d?.category === PROFILE_IMAGE;
            });
            if (doc?.requestPaths) {
                setProfileImageLink(doc.requestPaths[0]);
            }
        }
    }, [vehicle]);

    return (
        <div style={{ height: 60, display: 'flex', width: 'inherit' }}>
            <ImageComponent
                src={
                    profileImageLink !== ''
                        ? getBaseServerLink(profileImageLink)
                        : (vehicle as TruckDto).truckType
                        ? truck_logo
                        : (vehicle as TrailerDto).trailerType
                        ? trailer_logo
                        : car_logo
                }
                placeholder={''}
                isCalendar={true}
            />
            <div style={{ height: 55, width: 'calc(100% - 50px)' }}>
                <Typography gutterBottom variant={'body2'} component="p" className="calendar-vehicle-card-text">
                    {vehicle.manufacturer} {vehicle.model}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" className="calendar-vehicle-card-text">
                    {vehicle.licensePlateNum ?? 'Nema br. tablica'}
                </Typography>
            </div>
        </div>
    );
}
