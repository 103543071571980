import { isAppTypeCar, isAppTypeTruck } from '../Extensions/Selectors/Selectors';

export default {
    getTitle: (isDetails: boolean, path: string): string => {
        return isDetails
            ? isAppTypeCar(path)
                ? 'Promenite detalje automobila'
                : isAppTypeTruck(path)
                ? 'Promenite detalje kamiona'
                : 'Promenite detalje prikolice'
            : isAppTypeCar(path)
            ? 'Ubacite novi automobil'
            : isAppTypeTruck(path)
            ? 'Ubacite novi kamion'
            : 'Ubacite novu prikolicu';
    },
};
