// export const GranitClientConfigAPI = (): ConfigApi => {
//     const configuration: Configuration = {
//         apiKey: localStorage.token ?? '',
//         username: '',
//         password: '',
//         accessToken: '',
//     };
//     return new ConfigApi(baseUrl, configuration);
// };

export interface KeyValue {
    key: string;
    value: string;
}

export enum VehicleType {
    Car,
    Truck,
    Trailer,
}

export enum ServerResponseStatus {
    Pending,
    Success,
    Error,
}

export enum RecordType {
    Record,
    Expense,
    ImportantDocument,
    TravelExpense,
    Invoices,
    Projects,
}

export enum AppType {
    Driver,
    Car,
    Trailer,
    Truck,
    WorkingTool,
    WorkingMachine,
    OfficeMachine,
    HandMachine,
    Calendar,
    Charts,
    Customer,
    Service,
    Supplier,
}

export type NameDataPair = {
    name: string;
    data?: string;
};

export interface KeyValueNumber {
    key: number;
    value: number;
}

export enum TimelineViewType {
    Day = 'Day',
    Week = 'Week',
}

export enum CalendarViewType {
    TimelineView = 'timelineView',
    TableView = 'tableView',
}

export type Dimensions = { height: number; width: number };
