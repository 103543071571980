import * as React from 'react';
import { ReactElement, useState } from 'react';
import AddRecordExpenseBtns from './AddRecordExpenseBtns';
import { Modal } from '@mui/material';
import '../AdditionsBtn.css';
import RecordForm from './Form/RecordForm';
import ExpenseForm from './Form/ExpenseForm';
import { RecordType } from '../Extensions/Extensions';
import InvoiceForm from '../Forms/Invoice/InvoiceForm';
import ConstructionProjectForm from '../Forms/ConstructionProject/ConstructionProjectForm';

function AddRecordBtn(): ReactElement {
    const [open, setOpen] = useState(false);
    const [recordType, setRecordType] = useState<RecordType | undefined>(undefined);

    const handleOpen = (recType: RecordType): void => {
        setRecordType(recType);
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            <AddRecordExpenseBtns handleOpen={handleOpen} />
            <Modal
                className="modalStyle"
                open={open}
                aria-labelledby="add-vehicle-form"
                aria-describedby="adding a vehicle to a database through this form"
            >
                <div>
                    {recordType === RecordType.Record || recordType === RecordType.ImportantDocument ? (
                        <RecordForm closeForm={handleClose} recType={recordType} />
                    ) : recordType === RecordType.Expense ? (
                        <ExpenseForm closeForm={handleClose} />
                    ) : recordType === RecordType.Invoices ? (
                        <InvoiceForm closeForm={handleClose} />
                    ) : recordType === RecordType.Projects ? (
                        <ConstructionProjectForm closeForm={handleClose} />
                    ) : (
                        <></>
                    )}
                </div>
            </Modal>
        </>
    );
}

export default AddRecordBtn;
