import { Typography } from '@mui/material';
import React, { ReactElement, ReactNode, useState } from 'react';
import { ICompositionDto, TrailerDto } from '../../../AutoGeneratedAPI/clientApi';
import CollapsableFormSection from '../../../../Common/CommonComponents/CollapsableFormSection';
import TrailersElement from '../Elements/TrailersElement';
import { useAppSelector } from '../../../hooks/hooks';
import { RootState } from '../../../Redux/store';

type TrailersSectionProps = {
    composition: ICompositionDto;
    handleTrailersAdding(trailer: TrailerDto): void;
};

export default function TrailersSection({ composition, handleTrailersAdding }: TrailersSectionProps): ReactElement {
    const [expand, setExpand] = useState<boolean>(false);
    const trailers = useAppSelector((state: RootState) => state.vehicles.trailers);

    const handleInitialExpandChange = (newExpand: boolean) => {
        setExpand(newExpand);
    };

    const handleTrailerAdding = (trailer: TrailerDto): void => {
        if (composition.trailersIds?.includes(trailer.id)) {
            handleTrailersAdding(trailer);
            handleInitialExpandChange(true);
        } else {
            handleTrailersAdding(trailer);
            handleInitialExpandChange(false);
        }
    };

    const titleComponent = (): ReactNode => {
        return (
            <>
                <Typography component="p" variant="body1" align="left" style={{ display: 'inline' }}>
                    {composition.trailersIds
                        ? composition.trailersIds?.length <= 0
                            ? 'Prikolice'
                            : composition.trailersIds?.length > 1
                            ? 'Prikolice: '
                            : 'Prikolica: '
                        : 'Prikolice'}
                </Typography>
                {composition.trailersIds &&
                    composition.trailersIds?.length > 0 &&
                    composition.trailersIds?.map((st, index) => {
                        const trailerIndex = trailers?.findIndex((tr) => tr.id === st);
                        if (trailerIndex !== undefined && trailerIndex >= 0 && trailers !== null) {
                            const trailer = trailers[trailerIndex];
                            return (
                                <Typography
                                    key={index}
                                    component="p"
                                    variant="body1"
                                    align="left"
                                    style={{ display: 'inline', fontWeight: 'bold' }}
                                >
                                    {index > 0
                                        ? ', ' + (trailer.licensePlateNum ?? 'Nema tablica')
                                        : trailer.licensePlateNum ?? 'Nema tablica'}
                                </Typography>
                            );
                        } else {
                            return <></>;
                        }
                    })}
            </>
        );
    };

    return (
        <CollapsableFormSection
            initialExpanded={expand}
            titleComponents={titleComponent()}
            handleInitialExpandChange={handleInitialExpandChange}
        >
            <TrailersElement
                handleTrailerAdding={handleTrailerAdding}
                currentTrailers={composition.trailersIds ?? []}
            />
        </CollapsableFormSection>
    );
}
