import React, { ReactElement } from 'react';
import { Grid, Menu, MenuItem, Typography } from '@mui/material';
import { RootState } from '../Redux/store';
import './EventsList.css';
import { EntityType, IKmEventDto, ITimeEventDto } from '../AutoGeneratedAPI/clientApi';
import { useAppSelector } from '../hooks/hooks';
import { FormatDateText, getDayDifference } from '../../Common/DateHandler';

type EventsListProps = {
    anchorEl: null | HTMLElement;
    closeWindow(): void;
    entityType: EntityType;
    entityId: number;
    handleSelectEvent(eventId: number, isKmEvent: boolean): void;
};

export default function EventsList({
    anchorEl,
    closeWindow,
    entityType,
    entityId,
    handleSelectEvent,
}: EventsListProps): ReactElement {
    const timeEvents = useAppSelector((state: RootState) =>
        state.notifications.timeEvents?.filter((te) => te.entityType === entityType && te.entityId === entityId),
    );
    const kmEvents = useAppSelector((state: RootState) =>
        state.notifications.kmEvents?.filter((kme) => kme.entityType === entityType && kme.entityId === entityId),
    );

    const eventInfoElement = (isTimeEvent: boolean, tEvent?: ITimeEventDto, kmEvent?: IKmEventDto) => {
        const timeEventDifference = isTimeEvent
            ? tEvent?.ringDate
                ? getDayDifference(tEvent?.ringDate, new Date()) - (tEvent?.marginInDays ?? 0)
                : tEvent?.inTimeRing && tEvent?.startingDate
                ? (tEvent?.inTimeRing.months && tEvent?.inTimeRing.months > 0) ||
                  (tEvent?.inTimeRing.days && tEvent?.inTimeRing.days > 0)
                    ? getDayDifference(
                          new Date(
                              tEvent?.startingDate.getFullYear(),
                              tEvent?.startingDate.getMonth() + (tEvent?.inTimeRing.months ?? 0),
                              tEvent?.startingDate.getDate() + (tEvent?.inTimeRing.days ?? 0),
                          ),
                          new Date(),
                      ) - (tEvent?.marginInDays ?? 0)
                    : 0
                : 0
            : 0;

        const kmEventDifferenceInKm = !isTimeEvent
            ? kmEvent?.inKmToRing
                ? kmEvent?.inKmToRing - (kmEvent?.kmMargin ?? 0)
                : 0
            : 0;

        const kmEventDifferenceKmWhen = !isTimeEvent
            ? kmEvent?.kmWhenToRing
                ? kmEvent?.kmWhenToRing - (kmEvent?.kmMargin ?? 0)
                : 0
            : 0;

        return (
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="textSecondary" component="h5">
                        {isTimeEvent ? tEvent?.title ?? 'Nema naslova' : kmEvent?.title ?? 'Nema naslova'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" color="textSecondary" component="p" display="block">
                        {isTimeEvent
                            ? `Zvoni za ${timeEventDifference} dana`
                            : kmEventDifferenceInKm > 0
                            ? `Zvoni za ${kmEventDifferenceInKm} km od ${FormatDateText(
                                  kmEvent?.startingDate ? new Date(kmEvent.startingDate) : new Date(),
                              )}`
                            : kmEventDifferenceKmWhen > 0
                            ? `Zvoni na ${kmEventDifferenceKmWhen} km`
                            : 'Nema informacija'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        component="p"
                        display="block"
                        className="event-description"
                    >
                        {isTimeEvent ? tEvent?.description ?? 'Nema opisa' : kmEvent?.description ?? 'Nema opisa'}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    return (
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeWindow}>
            {timeEvents !== undefined &&
                timeEvents.length > 0 &&
                timeEvents.map((te) => (
                    <MenuItem
                        key={te.id}
                        style={{ whiteSpace: 'normal' }}
                        onClick={() => handleSelectEvent(te.id, false)}
                    >
                        <div className="event-menu-item-container">
                            {eventInfoElement(true, te, undefined)}
                            <hr />
                        </div>
                    </MenuItem>
                ))}
            {kmEvents !== undefined &&
                kmEvents.length > 0 &&
                kmEvents.map((kme) => (
                    <MenuItem
                        key={kme.id}
                        style={{ whiteSpace: 'normal' }}
                        onClick={() => handleSelectEvent(kme.id, true)}
                    >
                        <div className="event-menu-item-container">
                            {eventInfoElement(false, undefined, kme)}
                            <hr />
                        </div>
                    </MenuItem>
                ))}
            {(timeEvents === undefined || timeEvents.length <= 0) && (kmEvents === undefined || kmEvents.length <= 0) && (
                <Typography
                    variant="h6"
                    color="textSecondary"
                    component="h6"
                    style={{ width: 300, marginLeft: 10, height: 100 }}
                >
                    Trenutno nema podsetnika
                </Typography>
            )}
        </Menu>
    );
}
