import { Button, Grid, Paper, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { styled } from 'styled-components';

const DeleteTransportWarrantModalContainer = styled.div`
    position: relative;
    width: 470px;
    margin-left: auto;
    margin-right: auto;
    top: 20vh;
    height: 250px;
`;

type DeleteTransportWarrantModalProps = {
    close(): void;
    deleteTw(): void;
};

export default function DeleteTransportWarrantModal({
    close,
    deleteTw,
}: DeleteTransportWarrantModalProps): ReactElement {
    return (
        <DeleteTransportWarrantModalContainer>
            <Paper
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: 15,
                    padding: 40,
                }}
            >
                <Typography
                    component="h5"
                    variant="h5"
                    align="left"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        textAlign: 'center',
                        marginBottom: 20,
                        fontSize: 30,
                    }}
                >
                    Da li ste sigurni da želite da obrišete putni nalog?
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button fullWidth size="small" variant="outlined" color="primary" onClick={close}>
                            IZAĐI
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button fullWidth size="small" variant="outlined" color="error" onClick={deleteTw}>
                            OBRIŠI
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </DeleteTransportWarrantModalContainer>
    );
}
