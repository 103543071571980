import React, { ChangeEvent, useState } from 'react';
import { useEffect } from 'react';
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NameDataPair } from '../../Extensions/Extensions';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import { isUpdateForm } from '../../Extensions/Selectors/Selectors';
import { addNewInvoice, updateOldInvoice } from '../../Redux/Actions/Invoice/InvoiceAction';
import { RootState } from '../../Redux/store';
import FormDataReview from '../Common/FormDataReview';
import FormTemplate from '../Common/FormTemplate';
import InvoiceInfoForm from './Pages/InvoiceInfoForm';
import { InvoiceDto, IInvoiceDto } from '../../AutoGeneratedAPI/clientApi';

const steps = ['Podaci o računu', 'Pregled unetih informacija'];

type InvoiceFormProps = {
    closeForm(): void;
    invoiceIndex?: number;
    clientId?: number;
};

export default function InvoiceForm({ closeForm, invoiceIndex, clientId }: InvoiceFormProps): ReactElement {
    const [activeStep, setActiveStep] = useState(0);
    const [invoice, setInvoice] = useState<IInvoiceDto>(new InvoiceDto());

    const location = useLocation();
    const path = location.pathname;

    const isUpdateFrm = isUpdateForm(path);
    const currEntity = useSelector((state: RootState) => state.currentEntity.entity);

    const dispatch = useDispatch();

    const handleInvoiceCodeChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setInvoice({ ...invoice, code: event.target.value });
    };

    const handleInvoiceDescriptionChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setInvoice({ ...invoice, description: event.target.value });
    };

    const handleCreationDateChange = (date?: Date): void => {
        if (date) {
            setInvoice({
                ...invoice,
                creationDate: date as Date,
            });
        }
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    useEffect(() => {
        if (isUpdateFrm && invoiceIndex) {
            GranitClient.getInvoice(invoiceIndex).then((res) => setInvoice(res));
        } else {
            setInvoice({ ...invoice, creationDate: new Date() });
        }
    }, []);

    const onSubmit = (): void => {
        const inv = invoice;
        inv.clientId = clientId ?? currEntity?.id ?? 0;
        if (invoiceIndex && isUpdateFrm) {
            dispatch(updateOldInvoice(new InvoiceDto(inv)));
        } else {
            dispatch(addNewInvoice(clientId ?? currEntity?.id ?? 0, new InvoiceDto(inv)));
        }
        closeForm();
    };

    const getStepContent = () => {
        const displayText: NameDataPair[] = [];
        displayText.push({ name: 'Kod računa:', data: invoice.code ?? '' });
        displayText.push({
            name: 'Datum izdavanja:',
            data: new Date(invoice.creationDate ?? new Date()).toLocaleDateString('en-UK'),
        });
        displayText.push({ name: 'Opis:', data: invoice.description ?? '' });
        switch (activeStep) {
            case 0:
                return (
                    <InvoiceInfoForm
                        handleCodeChange={handleInvoiceCodeChange}
                        handleCreationDateChange={handleCreationDateChange}
                        handleDescriptionChange={handleInvoiceDescriptionChange}
                        invoice={new InvoiceDto(invoice)}
                    />
                );
            case 1:
                return <FormDataReview displayText={displayText} />;
            default:
                throw new Error('Unknown step');
        }
    };
    const formElem = getStepContent();

    const shouldDisable = !invoice.code || !invoice.creationDate;

    return (
        <>
            <FormTemplate
                activeStep={activeStep}
                close={closeForm}
                formContent={formElem}
                title={'Računi'}
                shouldDisable={shouldDisable}
                submit={onSubmit}
                steps={steps}
                handleNext={handleNext}
                handleBack={handleBack}
            />
        </>
    );
}
