import * as React from 'react';
import { ReactElement } from 'react';
import { Popover } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './LogForm.css';
import { useAppSelector } from '../hooks/hooks';
import { RootState } from '../Redux/store';

type UserInfoWindowProps = {
    userInfoAnchorEl: null | HTMLElement;
    close(): void;
};

export function UserInfoWindow({ userInfoAnchorEl, close }: UserInfoWindowProps): ReactElement {
    const user = useAppSelector((state: RootState) => state.loggedEmployee.employee?.employee);

    return (
        <Popover
            id={Boolean(userInfoAnchorEl) ? 'simple-popover' : undefined}
            open={Boolean(userInfoAnchorEl)}
            anchorEl={userInfoAnchorEl}
            onClose={close}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className="logout-modal">
                <div className="user-icon">
                    <AccountCircleIcon style={{ width: 70, height: 70 }} />
                </div>
                <div className="info">
                    <hr style={{ marginBottom: 15 }} />
                    <div className="user-name">
                        <p>{user?.name}</p>
                    </div>
                    <div className="phonenumber">
                        <p>{user?.phoneNumber}</p>
                    </div>
                    <hr style={{ marginTop: 15 }} />
                </div>
            </div>
        </Popover>
    );
}
