import { Grid } from '@mui/material';
import React, { ChangeEvent, ReactElement } from 'react';
import { ClientDto, ClientEntityType } from '../../../AutoGeneratedAPI/clientApi';
import FormTextField from '../../Common/FormTextField';

type CommonCustomerInfoProps = {
    customer: ClientDto;
    handleInputElementChange(event: ChangeEvent<HTMLInputElement>): void;
};

export default function CommonCustomerInfo({
    customer,
    handleInputElementChange,
}: CommonCustomerInfoProps): ReactElement {
    const isFirm = customer?.clientEntityType === ClientEntityType.Legal;

    return (
        <>
            <Grid container spacing={5}>
                <FormTextField
                    defaultValue={customer?.name}
                    required={true}
                    label="Ime"
                    entityName="name"
                    errorCondition={customer?.name ? false : true}
                    onChange={handleInputElementChange}
                />
                <FormTextField defaultValue={customer?.email} entityName="email" onChange={handleInputElementChange} />
                <FormTextField
                    defaultValue={customer?.phone}
                    required={true}
                    label="Telefon"
                    entityName="phone"
                    errorCondition={customer?.phone ? false : true}
                    onChange={handleInputElementChange}
                />
                <FormTextField
                    defaultValue={customer?.address}
                    required={true}
                    label="Adresa"
                    entityName="address"
                    errorCondition={customer?.address ? false : true}
                    onChange={handleInputElementChange}
                />
                <FormTextField
                    defaultValue={customer?.city}
                    required={true}
                    label="Grad"
                    entityName="city"
                    errorCondition={customer?.city ? false : true}
                    onChange={handleInputElementChange}
                />
                <FormTextField
                    defaultValue={customer?.ucin}
                    required={true}
                    label={isFirm ? 'MB' : 'JMBG'}
                    entityName="ucin"
                    errorCondition={customer?.ucin ? false : true}
                    onChange={handleInputElementChange}
                />
            </Grid>
        </>
    );
}
