import * as React from 'react';
import { ReactElement } from 'react';
import './ProfileCard.css';
import { ImageList, ImageListItem, Skeleton } from '@mui/material';
import { getBaseServerLink } from '../../AutoGeneratedAPI/Extension';
import { LoaderProgress } from '../../../Common/CommonComponents/Loader/LoaderProgress';
import { FileIcon } from 'react-file-icon';

type ImageContainerProps = {
    link: string[];
    handleCarouselOpen(index: number): void;
};

export function ImageContainer({ link, handleCarouselOpen }: ImageContainerProps): ReactElement {
    return (
        <ImageList rowHeight={100} cols={6} style={{ overflow: 'hidden', height: 200 }}>
            {!link ? (
                <div style={{ width: 300 }}>
                    <div style={{ height: 100, width: 100, margin: 'auto' }}>
                        <LoaderProgress />
                    </div>
                </div>
            ) : link.length <= 0 ? (
                [0, 1, 2, 3, 4].map((i) => {
                    return (
                        <ImageListItem key={i} cols={i < 2 ? 3 : 2} style={{ cursor: 'default' }}>
                            <Skeleton variant="rectangular" animation={false} style={{ height: 100 }} />
                        </ImageListItem>
                    );
                })
            ) : (
                link
                    .filter((li) => link.indexOf(li) < 5)
                    .map((doc) => {
                        return (
                            <ImageListItem
                                key={link.indexOf(doc)}
                                cols={
                                    link.length === 2
                                        ? 3
                                        : link.length === 3 && link.indexOf(doc) === 0
                                        ? 6
                                        : link.length === 3 && link.indexOf(doc) !== 0
                                        ? 3
                                        : link.length === 4
                                        ? 3
                                        : link.length >= 5 && (link.indexOf(doc) === 0 || link.indexOf(doc) === 1)
                                        ? 3
                                        : link.length >= 5 && link.indexOf(doc) !== 0 && link.indexOf(doc) !== 1
                                        ? 2
                                        : 6
                                }
                                rows={link.length === 2 || link.length === 1 ? 2 : 1}
                            >
                                <div
                                    className="image-album-container"
                                    onClick={() => handleCarouselOpen(link.indexOf(doc))}
                                >
                                    <div
                                        className={
                                            link.length > 5 && link.indexOf(doc) === 4
                                                ? 'last-image'
                                                : 'last-image-disabled'
                                        }
                                    >
                                        <p style={{ marginTop: 0, marginBottom: 0 }}>
                                            +{link.length > 5 && link.indexOf(doc) === 4 ? link.length - 5 : ''}
                                        </p>
                                    </div>
                                    {doc.endsWith('.jpg') || doc.endsWith('.png') ? (
                                        <img className="album-image" src={getBaseServerLink(doc)} alt="SLIKA" />
                                    ) : (
                                        <div
                                            style={{
                                                position: 'relative',
                                                height: '80px',
                                                width: '80px',
                                                margin: 'auto',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <div className="file-name">
                                                <p className="file-name-p">{doc.slice(doc.lastIndexOf('/') + 1)}</p>
                                            </div>
                                            <div className="file-icon-container">
                                                <div className="file-icon">
                                                    <FileIcon
                                                        extension={doc.slice(doc.lastIndexOf('.'))}
                                                        labelColor={
                                                            doc.slice(doc.lastIndexOf('.')) === '.pdf'
                                                                ? 'rgb(221, 68, 41)'
                                                                : doc.endsWith('.docx')
                                                                ? 'rgb(0, 103, 199)'
                                                                : doc.endsWith('.xlsx') ||
                                                                  doc.endsWith('.xlsm') ||
                                                                  doc.endsWith('.xls')
                                                                ? 'rgb(9, 133, 75)'
                                                                : 'default'
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </ImageListItem>
                        );
                    })
            )}
        </ImageList>
    );
}
