/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClientDto } from '../../../AutoGeneratedAPI/clientApi';
import { AppType } from '../../../Extensions/Extensions';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithAdding, ExceptionsWithUpdating } from '../../../Extensions/Exceptions';
import {
    addCustomer,
    updateCustomer,
    updateService,
    updateSupplier,
    addService,
    addSupplier,
} from '../../Reducers/Customers/CustomersReducer';
import { changeCurrentEntity } from '../../Reducers/General/CurrentEntityReducer';

export const updateOldCustomer = (customer: ClientDto | undefined, oldCustomer: ClientDto, appType: AppType) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    GranitClient.updateClient(customer !== undefined ? (customer.id as number) : 0, customer as ClientDto).then(
        (result) => {
            dispatch(changeCurrentEntity(result));
            appType === AppType.Customer
                ? dispatch(updateCustomer(result as ClientDto, oldCustomer))
                : appType === AppType.Service
                ? dispatch(updateService(result as ClientDto, oldCustomer))
                : dispatch(updateSupplier(result as ClientDto, oldCustomer));
        },
        (error) => {
            alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_CUSTOMERS);
            console.log(error.message);
        },
    );
};

export const addNewCustomer = (customer: ClientDto | undefined, appType: AppType) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    GranitClient.addNewClient(customer as ClientDto).then(
        (result) => {
            appType === AppType.Customer
                ? dispatch(addCustomer(result))
                : appType === AppType.Service
                ? dispatch(addService(result))
                : dispatch(addSupplier(result));
        },
        (error) => {
            alert(ExceptionsWithAdding.EXCEPTION_ADDING_CUSTOMERS);
            console.log(error.message);
        },
    );
};
