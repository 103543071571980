import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

import { ReactElement } from 'react';
import NavigationSideBar from '../../General/Navigation/NavigationSideBar';
import { isAppTypeCalendar, isAppTypeChart, isAppTypeTWReport } from '../../General/Extensions/Selectors/Selectors';
import Navbar from '../../General/Navigation/Navbar';

export default function DefaultLayout(): ReactElement {
    const location = useLocation();
    const path = location.pathname;

    const isCalendar = isAppTypeCalendar(path);
    const isChart = isAppTypeChart(path);
    const isTWReport = isAppTypeTWReport(path);

    if (isTWReport) {
        return (
            <>
                <Outlet />
            </>
        );
    }

    return (
        <div style={{ marginTop: !(isCalendar || isChart) ? 64 : 0 }}>
            <Box sx={{ display: 'flex' }}>
                {!(isCalendar || isChart) && (
                    <>
                        <NavigationSideBar />
                    </>
                )}
                <Navbar />
                <Box component="main" sx={{ flexGrow: 1 }}>
                    <Outlet />
                </Box>
            </Box>
        </div>
    );
}
