import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import vehiclesReducer from './Reducers/Vehicles/VehiclesReducer';
import workingToolsReducer from './Reducers/WorkingTools/WorkingToolsReducer';
import workingMachinesReducer from './Reducers/WorkingTools/WorkingMachinesReducer';
import handMachinesReducer from './Reducers/WorkingTools/HandMachinesReducer';
import officeMachinesReducer from './Reducers/WorkingTools/OfficeMachinesReducer';
import currentEntityReducer from './Reducers/General/CurrentEntityReducer';
import recordTypeReducer from './Reducers/General/RecordTypeReducer';
import driversReducer from './Reducers/Drivers/DriversReducer';
import recordsReducer from './Reducers/General/RecordsReducer';
import expensesReducer from './Reducers/General/ExpensesReducer';
import calendarReducer from './Reducers/Warrants/CalendarReducer';
import travelWarrantReducer from './Reducers/Warrants/TravelWarrantReducers';
import compositionReducer from './Reducers/Compositions/CompositionsReducer';
import alarmReducer from './Reducers/Alarms/AlarmsReducer';
import CustomersReducer from './Reducers/Customers/CustomersReducer';
import InvoiceReducer from './Reducers/Invoice/InvoiceReducer';
import ConstructionProjectReducer from './Reducers/ConstructionProject/ConstructionProjectReducer';
import ImportantDocsReducer from './Reducers/General/ImportantDocsReducer';
import { loggedEmployeeReducer } from './Reducers/Login/EmployeeReducer';
import ShipmentReducer from './Reducers/Shipments/ShipmentReducer';
import TransportWarrantFormReducer from './Reducers/Warrants/TransportWarrantFormReducer';
import ShipmentFormReducer from './Reducers/Shipments/ShipmentFormReducer';
import NotificationsReducer from './Reducers/Notifications/NotificationsReducer';

const store = configureStore({
    reducer: {
        vehicles: vehiclesReducer,
        currentEntity: currentEntityReducer,
        recordTypes: recordTypeReducer,
        records: recordsReducer,
        expenses: expensesReducer,
        drivers: driversReducer,
        workingTools: workingToolsReducer,
        workingMachines: workingMachinesReducer,
        handMachines: handMachinesReducer,
        officeMachines: officeMachinesReducer,
        travelWarrants: travelWarrantReducer,
        calendarData: calendarReducer,
        compositions: compositionReducer,
        alarms: alarmReducer,
        customers: CustomersReducer,
        invoices: InvoiceReducer,
        constructionProjects: ConstructionProjectReducer,
        importantDocs: ImportantDocsReducer,
        loggedEmployee: loggedEmployeeReducer,
        shipments: ShipmentReducer,
        twFormData: TransportWarrantFormReducer,
        shipFormData: ShipmentFormReducer,
        notifications: NotificationsReducer,
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
