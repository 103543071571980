import React, { useEffect, useState, ReactElement, ChangeEvent } from 'react';
import { AppType } from '../Extensions/Extensions';
import { GranitClient } from '../AutoGeneratedAPI/Extension';
import './Forms.css';
import {
    Button,
    Grid,
    Stack,
    TextField,
    Typography,
    Paper,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    InputAdornment,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import {
    CarDto,
    EngineType,
    ICarDto,
    ITrailerDto,
    ITruckDto,
    TrailerDto,
    TrailerType,
    TruckDto,
    TruckType,
} from '../AutoGeneratedAPI/clientApi';
import { useDispatch, useSelector } from 'react-redux';
import { addNewVehicle, updateOldVehicle } from '../Redux/Actions/Vehicles/VehiclesAction';
import { RootState } from '../Redux/store';
import VehicleFormHelper from './VehicleFormHelper';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import { SelectChangeEvent } from '@mui/material';
import { isAppTypeCar, isAppTypeTrailer, isAppTypeTruck, isUpdateForm } from '../Extensions/Selectors/Selectors';
import { useLocation } from 'react-router-dom';
import { EngineTypeSR } from '../Extensions/Localizations/Types/Vehicles/SR/EngineTypeSR';
import { TrailerTypeSR } from '../Extensions/Localizations/Types/Vehicles/SR/TrailerTypeSR';
import { TruckTypeSR } from '../Extensions/Localizations/Types/Vehicles/SR/TruckTypeSR';
import { useAppSelector } from '../hooks/hooks';
import { CompareTwoDatesEquality } from '../../Common/DateHandler';
import { formatNumberTypeInput } from '../../Common/Common';

type VehicleFormProps = {
    closeForm(): void;
};

export default function VehicleForm({ closeForm }: VehicleFormProps): ReactElement {
    const cars = useAppSelector((state: RootState) => state.vehicles.cars);
    const trucks = useAppSelector((state: RootState) => state.vehicles.trucks);
    const trailers = useAppSelector((state: RootState) => state.vehicles.trailers);

    const location = useLocation();
    const path = location.pathname;

    const isCar = isAppTypeCar(path);
    const isTruck = isAppTypeTruck(path);
    const isTrailer = isAppTypeTrailer(path);

    const [vehicle, setVehicle] = useState<ICarDto | ITruckDto | ITrailerDto>(new TruckDto());
    const [manufacturers, setManufacturers] = useState<string[]>([]);
    const [models, setModels] = useState<string[]>([]);

    const entity = useSelector((state: RootState) => state.currentEntity.entity);

    const isUpdateFrm = isUpdateForm(path);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isUpdateFrm && entity) {
            const e = isCar
                ? cars?.find((c) => c.id === entity.id)
                : isTruck
                ? trucks?.find((t) => t.id === entity.id)
                : trailers?.find((t) => t.id === entity.id);

            setVehicle(e ?? new TruckDto());
        } else {
            const newVeh = isCar ? new CarDto() : isTruck ? new TruckDto() : new TrailerDto();
            setVehicle({ ...newVeh, productionDate: new Date(), loadPalletSpaces: 0, loadVolume: 0, loadWeight: 0 });
        }
    }, [entity, isUpdateFrm]);

    const handleMaufacturerSelect = (event: ChangeEvent<unknown>, value: string | null): void => {
        setVehicle({ ...vehicle, manufacturer: value ? (value as string) : '' });
        GranitClient.getModels(value ? (value as string) : '').then(
            (result) => {
                setModels(result);
            },
            (error) => console.log(error.message),
        );
    };

    const handleMaufacturerChange = (event: ChangeEvent<{ value: unknown }>): void => {
        setVehicle({ ...vehicle, manufacturer: event.target.value ? (event.target.value as string) : '' });
        GranitClient.getManufacturers().then(
            (result) => {
                setManufacturers(result);
            },
            (error) => console.log(error.message),
        );
        GranitClient.getModels(event.target.value ? (event.target.value as string) : '').then(
            (result) => {
                setModels(result);
            },
            (error) => console.log(error.message),
        );
    };

    const handleModelSelect = (event: ChangeEvent<unknown>, value: string | null): void => {
        setVehicle({ ...vehicle, model: value as string });
    };

    const handleInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const type = target.type;

        if (type === 'number') {
            const newVal = +value;
            if (newVal && !isNaN(newVal) && newVal > 0) {
                setVehicle({ ...vehicle, [name]: newVal });
            } else {
                setVehicle({ ...vehicle, [name]: 0 });
            }
        } else {
            setVehicle({ ...vehicle, [name]: value });
        }
    };

    const handleSelectElement = (event: SelectChangeEvent<EngineType | TruckType | TrailerType>): void => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setVehicle({ ...vehicle, [name]: value });
    };

    const handleProductionDateChange = (date?: Date): void => {
        if (date) {
            setVehicle({ ...vehicle, productionDate: date as Date });
        }
    };

    const onSubmit = (): void => {
        const appType = isCar ? AppType.Car : isTruck ? AppType.Truck : AppType.Trailer;
        const ent = isCar ? (entity as CarDto) : isTruck ? (entity as TruckDto) : (entity as TrailerDto);
        const veh = isCar
            ? new CarDto(vehicle as ICarDto)
            : isTruck
            ? new TruckDto(vehicle as ITruckDto)
            : new TrailerDto(vehicle as ITrailerDto);
        if (isUpdateFrm) {
            dispatch(updateOldVehicle(veh, appType, ent));
        } else {
            dispatch(addNewVehicle(veh, appType));
        }
        closeForm();
    };

    const formTitle = VehicleFormHelper.getTitle(isUpdateFrm, path);

    const checkIfDataIsChanged = (): boolean => {
        const currVehicle = vehicle;
        const currEntity = isTruck
            ? trucks?.find((t) => t.id === entity?.id)
            : isTrailer
            ? trailers?.find((t) => t.id === entity?.id)
            : cars?.find((t) => t.id === entity?.id);
        const vehProdDate = new Date(currVehicle?.productionDate as Date);
        const entityProdDate = currEntity ? new Date((currEntity as CarDto).productionDate as Date) : new Date();

        if (isUpdateFrm && currEntity) {
            return (
                currVehicle?.manufacturer === (currEntity as CarDto).manufacturer &&
                currVehicle?.licensePlateNum === (currEntity as CarDto).licensePlateNum &&
                currVehicle?.chassisNumber === (currEntity as CarDto).chassisNumber &&
                currVehicle?.model === (currEntity as CarDto).model &&
                currVehicle?.depreciationPeriod === (currEntity as CarDto).depreciationPeriod &&
                currVehicle?.amortisation === (currEntity as CarDto).amortisation &&
                CompareTwoDatesEquality(vehProdDate, entityProdDate) &&
                ((isCar && (currVehicle as CarDto).engineType === (currEntity as CarDto).engineType) ||
                    (isTruck &&
                        (currVehicle as TruckDto).engineType === (currEntity as TruckDto).engineType &&
                        (currVehicle as TruckDto).truckType === (currEntity as TruckDto).truckType &&
                        (currVehicle as TruckDto).loadWeight === (currEntity as TruckDto).loadWeight &&
                        (currVehicle as TruckDto).loadVolume === (currEntity as TruckDto).loadVolume &&
                        (currVehicle as TruckDto).loadPalletSpaces === (currEntity as TruckDto).loadPalletSpaces) ||
                    (isTrailer &&
                        (currVehicle as TrailerDto).trailerType === (currEntity as TrailerDto).trailerType &&
                        (currVehicle as TrailerDto).loadWeight === (currEntity as TrailerDto).loadWeight &&
                        (currVehicle as TrailerDto).loadVolume === (currEntity as TrailerDto).loadVolume &&
                        (currVehicle as TrailerDto).loadPalletSpaces === (currEntity as TrailerDto).loadPalletSpaces))
            );
        }
        return false;
    };

    const checkIfRequiredIsEmpty = (): boolean => {
        return !vehicle?.manufacturer ||
            vehicle.manufacturer === '' ||
            !vehicle?.model ||
            vehicle?.model === '' ||
            !vehicle?.chassisNumber ||
            vehicle?.chassisNumber === '' ||
            (isCar && !(vehicle as CarDto).engineType) ||
            (isTruck && (!(vehicle as TruckDto).engineType || !(vehicle as TruckDto).truckType)) ||
            (isTrailer && !(vehicle as TrailerDto).trailerType) ||
            ((isTrailer || isTruck) &&
                ((vehicle as TruckDto).loadWeight === undefined ||
                    (vehicle as TruckDto).loadVolume === undefined ||
                    (vehicle as TruckDto).loadPalletSpaces === undefined))
            ? true
            : false;
    };

    const isDataChanged = !checkIfDataIsChanged();
    const isRequiredEmpty = checkIfRequiredIsEmpty();

    const VehicleFormElement = (): ReactElement => {
        return (
            <>
                <Grid container spacing={5}>
                    <Grid item xs={12} lg={6}>
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={manufacturers.map((option) => option)}
                            value={vehicle?.manufacturer || ''}
                            onChange={handleMaufacturerSelect}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={vehicle?.manufacturer ? false : true}
                                    required={true}
                                    fullWidth
                                    id="filled-basic"
                                    label="Marka"
                                    variant="outlined"
                                    onChange={handleMaufacturerChange}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={models.map((option) => option)}
                            value={vehicle?.model || ''}
                            onChange={handleModelSelect}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={vehicle?.model ? false : true}
                                    required={true}
                                    name="model"
                                    fullWidth
                                    id="filled-basic"
                                    label="Model"
                                    variant="outlined"
                                    value={vehicle?.model ?? ''}
                                    onChange={handleInputElementChange}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            fullWidth
                            id="filled-basic"
                            label="Cena vozila"
                            name="amortisation"
                            value={formatNumberTypeInput(vehicle?.amortisation)}
                            variant="outlined"
                            type="number"
                            InputProps={{
                                inputProps: { min: 0 },
                            }}
                            onChange={handleInputElementChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            fullWidth
                            id="filled-basic"
                            label="Period otplate (god)"
                            name="depreciationPeriod"
                            value={formatNumberTypeInput(vehicle?.depreciationPeriod)}
                            variant="outlined"
                            type="number"
                            InputProps={{
                                inputProps: { min: 0 },
                            }}
                            onChange={handleInputElementChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                            <Stack spacing={3}>
                                <DesktopDatePicker
                                    label="Datum proizvodnje"
                                    disableMaskedInput
                                    value={vehicle?.productionDate || new Date()}
                                    inputFormat="DD/MM/YYYY"
                                    minDate={dayjs('1940-01-01')}
                                    onChange={(newValue) => {
                                        handleProductionDateChange(newValue?.toDate());
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            fullWidth
                            id="filled-basic"
                            label="Registracija"
                            name="licensePlateNum"
                            value={vehicle?.licensePlateNum || ''}
                            variant="outlined"
                            onChange={handleInputElementChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            error={vehicle?.chassisNumber ? false : true}
                            required={true}
                            fullWidth
                            id="filled-basic"
                            label="Broj šasije"
                            name="chassisNumber"
                            value={vehicle?.chassisNumber || ''}
                            variant="outlined"
                            onChange={handleInputElementChange}
                        />
                    </Grid>
                    {(isCar || isTruck) && (
                        <Grid item xs={12} lg={6}>
                            <FormControl
                                fullWidth
                                required={true}
                                error={(vehicle as TruckDto).engineType ? false : true}
                            >
                                <InputLabel id="engine-type">Tip goriva</InputLabel>
                                <Select
                                    labelId="engine-type"
                                    label="Tip goriva"
                                    id="demo-simple-select"
                                    value={(vehicle as TruckDto).engineType ?? ''}
                                    name="engineType"
                                    onChange={handleSelectElement}
                                >
                                    {Object.values(EngineType).map((et) => {
                                        return (
                                            <MenuItem key={et} value={et as EngineType}>
                                                {EngineTypeSR[et as EngineType]}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {isTruck && (
                        <Grid item xs={12} lg={6}>
                            <FormControl
                                fullWidth
                                required={true}
                                error={(vehicle as TruckDto).truckType ? false : true}
                            >
                                <InputLabel id="truck-type">Tip kamiona</InputLabel>
                                <Select
                                    labelId="truck-type"
                                    label="Tip kamiona"
                                    id="demo-simple-select"
                                    value={(vehicle as TruckDto).truckType ?? ''}
                                    name="truckType"
                                    onChange={handleSelectElement}
                                >
                                    {Object.values(TruckType).map((tt) => {
                                        return (
                                            <MenuItem key={tt} value={tt as TruckType}>
                                                {TruckTypeSR[tt as TruckType]}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {isTrailer && (
                        <Grid item xs={12} lg={6}>
                            <FormControl
                                fullWidth
                                required={true}
                                error={(vehicle as TrailerDto).trailerType ? false : true}
                            >
                                <InputLabel id="trailer-type">Tip prikolice</InputLabel>
                                <Select
                                    labelId="trailer-type"
                                    label="Tip prikolice"
                                    id="demo-simple-select"
                                    value={(vehicle as TrailerDto).trailerType ?? ''}
                                    name="trailerType"
                                    onChange={handleSelectElement}
                                >
                                    {Object.values(TrailerType).map((tt) => {
                                        return (
                                            <MenuItem key={tt} value={tt as TrailerType}>
                                                {TrailerTypeSR[tt as TrailerType]}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {(isTruck || isTrailer) && (
                        <>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    fullWidth
                                    id="filled-basic"
                                    label="Nosivost"
                                    name="loadWeight"
                                    error={(vehicle as TruckDto).loadWeight === undefined}
                                    required
                                    value={formatNumberTypeInput((vehicle as TruckDto).loadWeight)}
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                        inputProps: { min: 0 },
                                    }}
                                    onChange={handleInputElementChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    fullWidth
                                    id="filled-basic"
                                    label="Nosivost"
                                    name="loadVolume"
                                    error={(vehicle as TruckDto).loadVolume === undefined}
                                    required
                                    value={formatNumberTypeInput((vehicle as TruckDto).loadVolume)}
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">m&sup3;</InputAdornment>,
                                        inputProps: { min: 0 },
                                    }}
                                    onChange={handleInputElementChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    fullWidth
                                    id="filled-basic"
                                    label="Nosivost"
                                    name="loadPalletSpaces"
                                    error={(vehicle as TruckDto).loadPalletSpaces === undefined}
                                    required
                                    value={formatNumberTypeInput((vehicle as TruckDto).loadPalletSpaces)}
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">paletna mesta</InputAdornment>,
                                        inputProps: { min: 0 },
                                    }}
                                    onChange={handleInputElementChange}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        );
    };

    return (
        <>
            <main className="custom-form-main">
                <div className="custom-form-close" onClick={closeForm}>
                    x
                </div>
                <Paper className="custom-form-paper">
                    <Typography component="h4" variant="h4" align="center" style={{ marginBottom: 50 }}>
                        {formTitle}
                    </Typography>
                    <div style={{ height: 'calc(80vh - 200px)', overflowX: 'hidden', overflowY: 'auto', padding: 20 }}>
                        {VehicleFormElement()}
                    </div>
                    <div style={{ position: 'absolute', bottom: 20, right: 50, width: 200 }}>
                        <Button
                            disabled={isDataChanged && !isRequiredEmpty ? false : true}
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onSubmit();
                            }}
                        >
                            SAČUVAJ
                        </Button>
                    </div>
                </Paper>
            </main>
        </>
    );
}
