import React, { ChangeEvent } from 'react';
import { Grid, Typography } from '@mui/material';
import { ReactElement } from 'react';
import CommonCustomerInfo from './CommonCustomerInfo';
import FormTextField from '../../Common/FormTextField';
import { ClientDto } from '../../../AutoGeneratedAPI/clientApi';

type FirmInfoProps = {
    customer: ClientDto;
    title: string;
    handleInputElementChange(event: ChangeEvent<HTMLInputElement>): void;
};

export default function FirmInfo({ customer, title, handleInputElementChange }: FirmInfoProps): ReactElement {
    return (
        <>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="h5" align="center">
                        Unesite podatke {title}
                    </Typography>
                </Grid>
            </Grid>
            <CommonCustomerInfo customer={customer} handleInputElementChange={handleInputElementChange} />
            <Grid container spacing={5}>
                <FormTextField
                    defaultValue={customer?.taxId}
                    required={true}
                    label="PIB"
                    entityName="taxId"
                    errorCondition={customer?.taxId ? false : true}
                    onChange={handleInputElementChange}
                />

                <FormTextField
                    defaultValue={customer?.contactInfo}
                    required={true}
                    label="Kontakt osoba"
                    entityName="contactInfo"
                    errorCondition={customer?.contactInfo ? false : true}
                    onChange={handleInputElementChange}
                />
            </Grid>
        </>
    );
}
