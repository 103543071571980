import React, { ReactElement, Ref, forwardRef, memo, useImperativeHandle, useRef, useState } from 'react';
import RightSidebar from '../Common/CommonComponents/RightSidebar';
import { ServerResponseStatus } from '../General/Extensions/Extensions';
import { setUpdateStatus } from '../General/Redux/Reducers/Warrants/CalendarReducer';
import {
    emptyUnselectedShipmentsForTw,
    unselectSelectedShipmentForTw,
} from '../General/Redux/Reducers/Warrants/TransportWarrantFormReducer';
import CalendarTransportWarrantSideDetails, {
    CalendarTWSideDetailsRefType,
} from '../General/SideDetails/CalendarTWSideDetails/CalendarTransportWarrantSideDetails';
import { useAppDispatch } from '../General/hooks/hooks';
import { ShipmentDto } from '../General/AutoGeneratedAPI/clientApi';

export interface CalendarTWSideDetailsRightSidebarRefType {
    handleToggleTwInfo: (toggle: boolean) => void;
    handleCloseTwInfo: () => void;
    openSaveUnsavedShipmentsChangesAlertForShipmentForm: (openTwForm: boolean, openShipForm: boolean) => void;
}

type CalendarTWSideDetailsRightSidebarProps = {
    emptyTwFromRedux(): void;
    changeSearchTwId(twId: number): void;
    handleShowShipmentInfoFromTWInfo(shipment: ShipmentDto, isTwFinished: boolean): void;
    handleOpenTransportWarrantForm(): void;
    handleOpenShipmentForm(): void;
};

const frComponent = forwardRef(function CalendarTWSideDetailsRightSidebar(
    {
        emptyTwFromRedux,
        changeSearchTwId,
        handleShowShipmentInfoFromTWInfo,
        handleOpenTransportWarrantForm,
        handleOpenShipmentForm,
    }: CalendarTWSideDetailsRightSidebarProps,
    ref: Ref<CalendarTWSideDetailsRightSidebarRefType>,
): ReactElement {
    const [showTWInfo, setShowTwInfo] = useState<boolean>(false);

    const Ref_CalendarTWSideDetails = useRef<CalendarTWSideDetailsRefType>(null);

    const dispatch = useAppDispatch();

    useImperativeHandle(
        ref,
        () => {
            return {
                handleToggleTwInfo(toggle: boolean) {
                    setShowTwInfo(toggle);
                },
                handleCloseTwInfo() {
                    handleUnshowTwInfo();
                },
                openSaveUnsavedShipmentsChangesAlertForShipmentForm(openTwForm: boolean, openShipForm: boolean) {
                    Ref_CalendarTWSideDetails.current?.openSaveUnsavedShipmentsChangesAlertForShipmentForm(
                        openTwForm,
                        openShipForm,
                    );
                },
            };
        },
        [],
    );

    const handleUnshowTwInfo = (): void => {
        setShowTwInfo(false);
        dispatch(emptyUnselectedShipmentsForTw());
        emptyTwFromRedux();
        changeSearchTwId(0);
        dispatch(setUpdateStatus(ServerResponseStatus.Pending));
        dispatch(unselectSelectedShipmentForTw());
    };

    return (
        <RightSidebar open={showTWInfo} width={'100vw'} defaultheight={'calc(100% - 146px)'}>
            <CalendarTransportWarrantSideDetails
                ref={Ref_CalendarTWSideDetails}
                closeTWSideDetails={handleUnshowTwInfo}
                openShipmentSideDetails={handleShowShipmentInfoFromTWInfo}
                openTwForm={handleOpenTransportWarrantForm}
                openShipmentForm={handleOpenShipmentForm}
            />
        </RightSidebar>
    );
});

export default memo(frComponent);
