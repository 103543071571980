import {
    LoadFragilityCategory,
    LoadType,
    RequestOriginApp,
    ShipmentDto,
    ShipmentStatus,
    StopDto,
    StopType,
    Urgency,
} from '../../../AutoGeneratedAPI/clientApi';
import { createSlice } from '@reduxjs/toolkit';
import { warehouseAddress } from '../../../../Common/Common';

interface IInitialStateType {
    shipment: ShipmentDto;
}

const initialState: IInitialStateType = {
    shipment: new ShipmentDto({
        ...new ShipmentDto(),
        requestOriginApp: RequestOriginApp.Warehouse,
        urgency: Urgency.Medium,
        loadFragilityCategory: LoadFragilityCategory.Sturdy,
        loadType: LoadType.BuildingMaterials,
        shipmentStatus: ShipmentStatus.Pending,
        startingStop: new StopDto({
            ...new StopDto(),
            stopType: StopType.Loading,
            startingTime: new Date(),
            endingTime: new Date(),
            name: 'Granit stovariste',
            locationInfo: {
                address: warehouseAddress.address,
                coordinates: warehouseAddress.coordinates,
            },
        }),
        endingStop: new StopDto({
            ...new StopDto(),
            stopType: StopType.Unloading,
            startingTime: new Date(),
            endingTime: new Date(),
        }),
        loadPalletSpaces: 0,
        loadVolume: 0,
        loadWeight: 0,
        loadValue: 0,
        transportPrice: 0,
    }),
};

const shipFormSlice = createSlice({
    name: 'shipmentForm',
    initialState,
    reducers: {
        setShipment(state, action) {
            state.shipment = action.payload;
        },
        resetShipment(state) {
            state.shipment = new ShipmentDto({
                ...new ShipmentDto(),
                requestOriginApp: RequestOriginApp.Warehouse,
                urgency: Urgency.Medium,
                loadFragilityCategory: LoadFragilityCategory.Sturdy,
                loadType: LoadType.BuildingMaterials,
                shipmentStatus: ShipmentStatus.Pending,
                startingStop: new StopDto({
                    ...new StopDto(),
                    stopType: StopType.Loading,
                    startingTime: new Date(),
                    endingTime: new Date(),
                    name: 'Granit stovariste',
                    locationInfo: {
                        address: warehouseAddress.address,
                        coordinates: warehouseAddress.coordinates,
                    },
                }),
                endingStop: new StopDto({
                    ...new StopDto(),
                    stopType: StopType.Unloading,
                    startingTime: new Date(),
                    endingTime: new Date(),
                }),
                loadPalletSpaces: 0,
                loadVolume: 0,
                loadWeight: 0,
                transportPrice: 0,
                loadValue: 0,
            });
        },
        setStartingStop(state, action) {
            const newSStop = action.payload;
            const ship = new ShipmentDto({ ...state.shipment, startingStop: newSStop });
            state.shipment = ship;
        },
        resetStartingStop(state) {
            const sStop = new StopDto({
                ...new StopDto(),
                stopType: StopType.Loading,
                startingTime: new Date(),
                endingTime: new Date(),
                name: 'Granit stovariste',
                locationInfo: {
                    address: warehouseAddress.address,
                    coordinates: warehouseAddress.coordinates,
                },
            });
            const ship = new ShipmentDto({ ...state.shipment, startingStop: sStop });
            state.shipment = ship;
        },
        setEndingStop(state, action) {
            const newEStop = action.payload;
            const ship = new ShipmentDto({ ...state.shipment, endingStop: newEStop });
            state.shipment = ship;
        },
        resetEndingStop(state) {
            const eStop = new StopDto({
                ...new StopDto(),
                stopType: StopType.Unloading,
                startingTime: new Date(),
                endingTime: new Date(),
            });
            const ship = new ShipmentDto({ ...state.shipment, endingStop: eStop });
            state.shipment = ship;
        },
    },
});

export const {
    setShipment,
    resetShipment,
    setStartingStop,
    resetStartingStop,
    setEndingStop,
    resetEndingStop,
} = shipFormSlice.actions;

export default shipFormSlice.reducer;
