import React, { ReactElement, useEffect, useState } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import './GoogleMaps.css';
import { TextField } from '@mui/material';
import { GOOGLE_MAPS_API_LIBRARIES, LatLngLiteral } from '../../Common';

const containerStyle = {
    width: '100%',
    height: '400px',
};

type GoogleMapsProps = {
    handleAddingAddress(address: string, coordinates: LatLngLiteral): void;
    label: string;
    currLocation?: { address: string; coordinates: LatLngLiteral };
    apiKey: string;
};

export default function GoogleMaps({
    handleAddingAddress,
    label,
    currLocation,
    apiKey,
}: GoogleMapsProps): ReactElement {
    const [address, setAddress] = useState(currLocation?.address ?? '');
    const [coordinates, setCoordinates] = useState<LatLngLiteral>({
        lat: currLocation?.coordinates?.lat ?? 44.01667,
        lng: currLocation?.coordinates?.lng ?? 20.91667,
    });
    const [zoom, setZoom] = React.useState(11);

    useEffect(() => {
        if (currLocation && currLocation.address) {
            setAddress(currLocation.address);
            if (currLocation.coordinates) {
                if (currLocation.coordinates.lat !== 0 && currLocation.coordinates.lng !== 0) {
                    setCoordinates(currLocation.coordinates);
                }
            }
            setZoom(14);
        }
    }, [currLocation]);

    const handleSelect = async (currAddress: string) => {
        const results = await geocodeByAddress(currAddress);
        const latLng = await getLatLng(results[0]);

        setZoom(14);
        setAddress(currAddress);
        setCoordinates(latLng);
        handleAddingAddress(currAddress, latLng);
    };

    const handleSelectCoordinates = (e: google.maps.MapMouseEvent) => {
        if (e.latLng) {
            const coord: LatLngLiteral = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
            };

            const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: e.latLng }).then((response) => {
                if (response.results[0]) {
                    let newAddress = response.results[0].formatted_address;
                    if (newAddress.startsWith('Unnamed Road, ')) {
                        newAddress = newAddress.replace('Unnamed Road, ', '');
                    }
                    setAddress(newAddress);
                    handleAddingAddress(newAddress, coord);
                } else {
                    window.alert('No results found');
                }
            });
            setCoordinates(coord);
        }
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
        libraries: GOOGLE_MAPS_API_LIBRARIES,
    });

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={coordinates}
            zoom={zoom}
            onClick={handleSelectCoordinates}
        >
            <div className="google-maps-search">
                <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <TextField
                                variant="outlined"
                                style={{ backgroundColor: '#fff', width: '100%', borderRadius: 5 }}
                                {...getInputProps({ placeholder: label })}
                            />
                            <div>
                                {loading ? <div>...loading</div> : null}
                                {suggestions.map((suggestion) => {
                                    const style = {
                                        backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                                    };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, { style })}
                                            key={suggestion.description}
                                            className="google-maps-item"
                                        >
                                            {suggestion.description}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
            <MarkerF position={coordinates} />
        </GoogleMap>
    ) : (
        <></>
    );
}
