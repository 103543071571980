import React, { ReactElement } from 'react';
import TimelineViewFilterBar from '../TimelineViewFilterBar/TimelineViewFilterBar';
import TimelineViewTable from './TimelineViewTable';
import { useLocalStorage } from '../../General/hooks/useLocalStorageHook';
import { TWSearchTokenModel } from '../../General/Extensions/models/RecordSearchTokenModel';

export const times = [
    '00h',
    '01h',
    '02h',
    '03h',
    '04h',
    '05h',
    '06h',
    '07h',
    '08h',
    '09h',
    '10h',
    '11h',
    '12h',
    '13h',
    '14h',
    '15h',
    '16h',
    '17h',
    '18h',
    '19h',
    '20h',
    '21h',
    '22h',
    '23h',
];

type TimelineViewProps = {
    handleOpenShipmentForm(): void;
    handleOpenTWForm(): void;
    handleSelectTWForUpdating(twId: number): void;
    query: TWSearchTokenModel;
};

export default function TimelineView({
    handleOpenShipmentForm,
    handleOpenTWForm,
    handleSelectTWForUpdating,
    query,
}: TimelineViewProps): ReactElement {
    const [startingHours, setStartingHours] = useLocalStorage<number>('TimelineViewStartingHours', 7);
    const [endingHours, setEndingHours] = useLocalStorage<number>('TimelineViewEndingHours', 17);

    const handleSetStartingHours = (startHours: number): void => {
        setStartingHours(startHours);
    };

    const handleSetEndingHours = (endHours: number): void => {
        setEndingHours(endHours);
    };

    // show those events
    return (
        <>
            {/** Filter bar */}
            <TimelineViewFilterBar
                handleOpenShipmentForm={handleOpenShipmentForm}
                handleOpenTransportWarrantForm={handleOpenTWForm}
                startingHours={startingHours}
                endingHours={endingHours}
                setStartHours={handleSetStartingHours}
                setEndHours={handleSetEndingHours}
            />

            {/** Table */}
            <TimelineViewTable
                setUpdateFormTW={handleSelectTWForUpdating}
                startingHours={startingHours}
                endingHours={endingHours}
                startingDate={query.startingDate}
            />
        </>
    );
}
