import React, { ReactElement, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import '../App.css';

export default function ExchangeRateWidget(): ReactElement {
    const [cookies, setCookie] = useCookies(['exchangerate']);

    useEffect(() => {
        setCookie(
            'exchangerate',
            'https://www.kursna-lista.info/resources/kursna-lista.php?format=2&br_decimala=4&promene=1&procenat=1&datum=1',
        );
    }, []);
    return (
        <>
            <iframe src={cookies.exchangerate} width="200px" height="230px" scrolling="no"></iframe>
        </>
    );
}
