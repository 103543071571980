import * as React from 'react';
import { ReactElement } from 'react';
import { CssBaseline } from '@mui/material';
import AppRouter from '../AppRouter';

export default function AppLayout(): ReactElement {
    return (
        <React.Fragment>
            <CssBaseline />
            <main>
                <AppRouter />
            </main>
        </React.Fragment>
    );
}
