import { Paper, Tab, Tabs } from '@mui/material';
import React, { ChangeEvent, ReactElement } from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EuroIcon from '@mui/icons-material/Euro';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import DescriptionIcon from '@mui/icons-material/Description';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {
    isAppTypeClient,
    isAppTypeCustomer,
    isAppTypeDriver,
    isAppTypeVehicle,
} from '../../Extensions/Selectors/Selectors';
import { useLocation } from 'react-router-dom';

type ProfileTabBarProps = {
    value: number;
    handleChange(event: ChangeEvent<unknown>, newValue: number): void;
};

export default function ProfileTabBar({ value, handleChange }: ProfileTabBarProps): ReactElement {
    const location = useLocation();
    const path = location.pathname;

    const isVehicle = isAppTypeVehicle(path);
    const isDriver = isAppTypeDriver(path);
    const isClient = isAppTypeClient(path);
    const isCustomer = isAppTypeCustomer(path);

    return (
        <div className="vehicle-feed-selector">
            <Paper>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    centered
                >
                    <Tab icon={<DescriptionIcon />} label="Evidencije" className="feed-tab" />
                    {isClient && <Tab icon={<ReceiptIcon />} label="Računi" className="feed-tab" />}
                    {isClient && (
                        <Tab
                            disabled={!isCustomer}
                            icon={<AccountBalanceIcon />}
                            label="Proj/Gradilišta"
                            className="feed-tab"
                        />
                    )}

                    {!isClient && (
                        <Tab
                            icon={<EuroIcon />}
                            label={isDriver ? '' : 'Troškovi'}
                            disabled={isDriver}
                            className="feed-tab"
                        />
                    )}
                    {!isClient && <Tab icon={<CardTravelIcon />} label="Put. nalozi" className="feed-tab" />}
                    {!isClient && (
                        <Tab
                            icon={<AssignmentIcon />}
                            label="Opšta dok."
                            className="feed-tab"
                            disabled={!isDriver && !isVehicle}
                        />
                    )}
                </Tabs>
            </Paper>
        </div>
    );
}
