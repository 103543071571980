import * as React from 'react';
import { ReactElement, ChangeEvent, useState } from 'react';
import { AppType, NameDataPair } from '../../Extensions/Extensions';
import '../Forms.css';
import { useDispatch, useSelector } from 'react-redux';
import { addNewCustomer, updateOldCustomer } from '../../Redux/Actions/Customers/CustomersAction';
import { RootState } from '../../Redux/store';
import { ClientDto, ClientType, ClientEntityType, IClientDto } from '../../AutoGeneratedAPI/clientApi';
import { useEffect } from 'react';
import TypeOfCustomer from './Pages/TypeOfCustomer';
import CustomerInfo from './Pages/CustomerInfo';
import FirmInfo from './Pages/FirmInfo';
import FormTemplate from '../Common/FormTemplate';
import FormDataReview from '../Common/FormDataReview';
import { ClientTypeSR } from '../../Extensions/Localizations/Types/Client/SR/ClientTypeSR';
import { EntityTypeSR } from '../../Extensions/Localizations/Types/Client/SR/EntityTypeSR';
import { useLocation } from 'react-router-dom';
import { isAppTypeCustomer, isAppTypeService, isUpdateForm } from '../../Extensions/Selectors/Selectors';

const steps = ['Tip klijenta', 'Podaci o klijentu', 'Pregled unetih informacija'];

type CustomerFormProps = {
    closeForm(): void;
};

export default function CustomerForm({ closeForm }: CustomerFormProps): ReactElement {
    const customer = useSelector((state: RootState) => state.currentEntity.entity as ClientDto);

    const location = useLocation();
    const path = location.pathname;

    const isCustomer = isAppTypeCustomer(path);
    const isService = isAppTypeService(path);
    const isUpdateFrm = isUpdateForm(path);

    const [currCustomer, setCurrCustomer] = useState<IClientDto>(isUpdateFrm && customer ? customer : new ClientDto());
    const [activeStep, setActiveStep] = useState(0);

    const dispatch = useDispatch();

    const isFirm = currCustomer?.clientEntityType === ClientEntityType.Legal;

    const handleInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setCurrCustomer({ ...currCustomer, [name]: value });
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    useEffect(() => {
        if (isUpdateFrm) {
            setActiveStep(1);
        }
    }, []);

    useEffect(() => {
        if (!currCustomer?.clientType && !currCustomer?.clientEntityType) {
            const clientTp = isCustomer ? ClientType.Customer : isService ? ClientType.Service : ClientType.Supplier;
            setCurrCustomer({ ...currCustomer, clientEntityType: ClientEntityType.Physical, clientType: clientTp });
        } else {
            const et = currCustomer?.clientEntityType ?? ClientEntityType.Legal;
            const ct = currCustomer?.clientType ?? ClientType.Customer;

            setCurrCustomer({
                ...currCustomer,
                clientEntityType: et,
                clientType: ct,
            });
        }
    }, []);

    const onSubmit = (): void => {
        const appType = isCustomer ? AppType.Customer : isService ? AppType.Service : AppType.Supplier;
        if (isUpdateFrm) {
            dispatch(updateOldCustomer(new ClientDto(currCustomer), customer, appType as AppType));
        } else {
            dispatch(addNewCustomer(new ClientDto(currCustomer), appType as AppType));
        }
        closeForm();
    };

    const checkIfDataIsChanged = (): boolean => {
        const currentCustomer = currCustomer;
        const currEntity = customer;

        if (isUpdateFrm && currEntity && currentCustomer) {
            return (
                currentCustomer?.name === currEntity?.name &&
                currentCustomer?.ucin === currEntity?.ucin &&
                currentCustomer?.address === currEntity?.address &&
                currentCustomer?.city === currEntity?.city &&
                currentCustomer?.clientType === currEntity?.clientType &&
                currentCustomer?.email === currEntity?.email &&
                currentCustomer?.clientEntityType === currEntity?.clientEntityType &&
                currentCustomer?.phone === currEntity?.phone &&
                (isFirm
                    ? currentCustomer?.contactInfo === currEntity?.contactInfo &&
                      currentCustomer?.taxId === currEntity?.taxId
                    : true) &&
                currentCustomer?.phone === currEntity?.phone
            );
        }
        return false;
    };

    const checkIfRequiredIsEmpty = (): boolean => {
        return currCustomer?.name &&
            currCustomer?.ucin &&
            currCustomer?.address &&
            currCustomer?.phone &&
            currCustomer?.city &&
            currCustomer?.clientType &&
            currCustomer?.clientEntityType &&
            (isFirm ? currCustomer?.taxId && currCustomer?.contactInfo : true)
            ? false
            : true;
    };

    const isDataChanged = !checkIfDataIsChanged();

    const isRequiredEmpty = checkIfRequiredIsEmpty();

    const shouldDisable = (!isDataChanged || isRequiredEmpty) && activeStep === 1;

    const formTitle =
        currCustomer?.clientEntityType === ClientEntityType.Legal
            ? currCustomer?.clientType === ClientType.Service
                ? 'servisne firme'
                : currCustomer?.clientType === ClientType.Supplier
                ? 'firme dobavljaca'
                : 'firme kupca'
            : currCustomer?.clientEntityType === ClientEntityType.Physical
            ? currCustomer?.clientType === ClientType.Service
                ? 'servisera'
                : currCustomer?.clientType === ClientType.Supplier
                ? 'dobavljaca'
                : 'kupca'
            : '';

    const clientTypeText = ClientTypeSR[currCustomer?.clientType ?? 0];
    const entityTypeText = EntityTypeSR[currCustomer?.clientEntityType ?? 0];

    const physicalClientText: NameDataPair[] = [
        { name: isFirm ? 'Naziv:' : 'Ime:', data: currCustomer?.name ?? '' },
        { name: 'Tip klijenta:', data: clientTypeText ?? '' },
        { name: 'Tip entiteta:', data: entityTypeText ?? '' },
        { name: 'Adresa:', data: currCustomer?.address ?? '' },
        { name: 'Grad:', data: currCustomer?.city ?? '' },
        { name: 'Telefon:', data: currCustomer?.phone ?? '' },
        { name: isFirm ? 'MB:' : 'JMBG:', data: currCustomer?.ucin ?? '' },
    ];

    const legalClientText: NameDataPair[] = [];
    physicalClientText.forEach((pct) => legalClientText.push(pct));
    legalClientText.push({ name: 'PIB:', data: currCustomer?.taxId ?? '' });
    legalClientText.push({ name: 'Kontakt osoba:', data: currCustomer?.contactInfo ?? '' });

    const getStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <TypeOfCustomer
                        entityType={currCustomer?.clientEntityType ?? ClientEntityType.Physical}
                        selectCustomerEntityType={handleInputElementChange}
                    />
                );
            case 1:
                return currCustomer?.clientEntityType === ClientEntityType.Physical ? (
                    <CustomerInfo
                        customer={new ClientDto(currCustomer)}
                        handleInputElementChange={handleInputElementChange}
                        title={formTitle}
                    />
                ) : (
                    <FirmInfo
                        customer={new ClientDto(currCustomer)}
                        handleInputElementChange={handleInputElementChange}
                        title={formTitle}
                    />
                );
            case 2:
                return <FormDataReview displayText={isFirm ? legalClientText : physicalClientText} />;
            default:
                throw new Error('Unknown step');
        }
    };

    const formElem = getStepContent();

    return (
        <>
            <FormTemplate
                activeStep={activeStep}
                close={closeForm}
                formContent={formElem}
                title={'Klijent forma'}
                shouldDisable={shouldDisable}
                submit={onSubmit}
                steps={steps}
                handleNext={handleNext}
                handleBack={handleBack}
            />
        </>
    );
}
