import { Typography } from '@mui/material';
import React, { ReactElement } from 'react';

type EmptyPageProps = {
    title: string;
};

export default function EmptyPage({ title }: EmptyPageProps): ReactElement {
    return (
        <div
            style={{
                display: 'flex',
                height: '50vh',
                width: 500,
                margin: 'auto',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography
                variant="h5"
                style={{
                    opacity: 0.5,
                    fontSize: '5vw',
                    height: 200,
                }}
            >
                {title}
            </Typography>
        </div>
    );
}
