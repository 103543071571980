import React, { ChangeEvent, ReactElement, useState } from 'react';
import './Feed.css';
import { ImportantDocsSearchTokenModel } from '../../../Extensions/models/RecordSearchTokenModel';
import ImportantDocsFeedElement from './FeedElements/ImportantDocsFeedElement';
import 'dayjs/locale/sr';
import SearchWindow from './SearchWindow';
import { RecordType } from '../../../Extensions/Extensions';

export default function ImportantDocsFeed(): ReactElement {
    const [query, setQuery] = useState<ImportantDocsSearchTokenModel>({
        searchText: '',
        startingDate: undefined,
        endingDate: undefined,
        lastEntityId: 0,
    });

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery({ ...query, searchText: e.target.value, lastEntityId: 0 });
    };

    const handleStartDateChange = (date?: Date) => {
        setQuery({ ...query, startingDate: date as Date, lastEntityId: 0 });
    };

    const handleEndDateChange = (date?: Date) => {
        setQuery({ ...query, endingDate: date as Date, lastEntityId: 0 });
    };

    const setImportantDocId = (impDocId: number) => {
        setQuery({ ...query, lastEntityId: impDocId });
    };

    return (
        <div style={{ overflowY: 'hidden' }}>
            <SearchWindow
                handleEndDateChange={handleEndDateChange}
                handleSearch={handleSearch}
                handleStartDateChange={handleStartDateChange}
                query={query}
                recordType={RecordType.ImportantDocument}
            />
            <div className={'outter'}>
                <div className={'inner'}>
                    <ImportantDocsFeedElement setImpDocId={setImportantDocId} query={query} />
                </div>
            </div>
        </div>
    );
}
