import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ExpenseCategory } from '../../../AutoGeneratedAPI/clientApi';
import { concatExpenses, setInitialExpenses } from '../../../Redux/Reducers/General/ExpensesReducer';
import { ExpenseSearchTokenModel } from '../../models/RecordSearchTokenModel';
import { isAppTypeVehicle, isAppTypeWorkingToolCat } from '../../Selectors/Selectors';
import { fetchVehicleExpenses, fetchWorkingToolExpenses } from './API';

type RecordSearchType = {
    loadingExpenses: boolean;
    hasMoreExpenses: boolean;
    expenseError: boolean;
};

export default function useExpensesSearch(
    entityId: number,
    query: ExpenseSearchTokenModel,
    expenseCategory?: ExpenseCategory,
): RecordSearchType {
    const [loadingExpenses, setLoadingExpenses] = useState(true);
    const [expenseError, setExpenseError] = useState(false);
    const [hasMoreExpenses, setHasMoreExpenses] = useState(false);

    const location = useLocation();
    const path = location.pathname;

    const IsVehicle = isAppTypeVehicle(path);
    const IsWorkingMachine = isAppTypeWorkingToolCat(path);

    const dispatch = useDispatch();

    useEffect(() => {
        setLoadingExpenses(true);
        setExpenseError(false);
        query.expenseCategory = expenseCategory;
        IsVehicle
            ? fetchVehicleExpenses(entityId, query).then(
                  (response) => {
                      if (query.lastEntityId && query.lastEntityId > 0) {
                          dispatch(concatExpenses(response));
                      } else {
                          dispatch(setInitialExpenses(response));
                      }
                      setHasMoreExpenses(response.length > 0);
                      setLoadingExpenses(false);
                  },
                  (error) => {
                      setExpenseError(true);
                      console.log(error.message);
                  },
              )
            : IsWorkingMachine
            ? fetchWorkingToolExpenses(entityId, query).then(
                  (res) => {
                      if (query.lastEntityId && query.lastEntityId > 0) {
                          dispatch(concatExpenses(res));
                      } else {
                          dispatch(setInitialExpenses(res));
                      }
                      setHasMoreExpenses(res.length > 0);
                      setLoadingExpenses(false);
                  },
                  (error) => {
                      setExpenseError(true);
                      console.log(error.message);
                  },
              )
            : console.log('Ne postoje troškovi za ovaj tip entiteta');
    }, [query]);

    useEffect(() => {
        dispatch(setInitialExpenses([]));
    }, [query.expenseCategory, query.endingDate, query.searchText, query.startingDate, query.numberOfEntitiesToReturn]);

    return { loadingExpenses, hasMoreExpenses, expenseError };
}
