import {
    Box,
    Stack,
    TextField,
    Select,
    MenuItem,
    Fab,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    SelectChangeEvent,
    Popover,
    Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import React, { ChangeEvent, ReactElement, MouseEvent, useState } from 'react';
import { ExpenseCategory } from '../../../AutoGeneratedAPI/clientApi';
import { ALL_EXP_TYPES } from '../../../../Common/Consts';
import { ExpenseTypeSR } from '../../../Extensions/Localizations/Types/Expenses/SR/ExpenseTypeSR';
import {
    ExpenseSearchTokenModel,
    ImportantDocsSearchTokenModel,
    InvoiceSearchTokenModel,
    ProjectsSearchToken,
    RecordSearchTokenModel,
    TWSearchTokenModel,
} from '../../../Extensions/models/RecordSearchTokenModel';
import { RecordType } from '../../../Extensions/Extensions';

type SearchWindowProps = {
    query:
        | ExpenseSearchTokenModel
        | RecordSearchTokenModel
        | TWSearchTokenModel
        | ImportantDocsSearchTokenModel
        | InvoiceSearchTokenModel
        | ProjectsSearchToken;
    handleSearch?(e: ChangeEvent<HTMLInputElement>): void;
    handleExpenseTypeChange?(event: SelectChangeEvent<ExpenseCategory | string | undefined>): void;
    handleStartDateChange?(date?: Date): void;
    handleEndDateChange?(date?: Date): void;
    recordType: RecordType;
    defaultExpenseType?: ExpenseCategory;
};

export default function SearchWindow({
    query,
    handleSearch,
    handleExpenseTypeChange,
    handleStartDateChange,
    handleEndDateChange,
    recordType,
    defaultExpenseType,
}: SearchWindowProps): ReactElement {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClickAnchor = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAnchor = () => {
        setAnchorEl(null);
    };
    return (
        <Box>
            <Tooltip title="Pretraga" placement="right">
                <Fab
                    aria-label="search"
                    onClick={handleClickAnchor}
                    color="warning"
                    style={{
                        position: 'absolute',
                        right: 60,
                        bottom: 120,
                        zIndex: 6,
                        height: 40,
                        width: 40,
                    }}
                >
                    <SearchIcon style={{ fontSize: 25 }} />
                </Fab>
            </Tooltip>
            <Popover
                id={Boolean(anchorEl) ? 'simple-popover' : undefined}
                open={Boolean(anchorEl)}
                onClose={handleCloseAnchor}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: -10,
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <div
                    className={
                        recordType === RecordType.Expense
                            ? 'feed-search feed-expense-search'
                            : recordType === RecordType.TravelExpense
                            ? 'feed-search feed-tw-search'
                            : 'feed-search'
                    }
                >
                    <Typography variant="h6" component="h6" style={{ marginBottom: 10 }}>
                        Pretraga
                    </Typography>
                    <Grid container spacing={3}>
                        {recordType !== RecordType.TravelExpense && (
                            <Grid item xs={12}>
                                <TextField
                                    key="search token text key"
                                    fullWidth
                                    id="filled-basic"
                                    label={recordType === RecordType.Invoices ? 'Kod' : 'Naslov/opis'}
                                    value={(query as RecordSearchTokenModel).searchText ?? ''}
                                    variant="outlined"
                                    onChange={handleSearch}
                                />
                            </Grid>
                        )}
                        {recordType === RecordType.Expense && (
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="expense-type">Tip troška</InputLabel>
                                    <Select
                                        labelId="expense-type"
                                        label="Tip troška"
                                        id="demo-simple-select"
                                        name="expenseType"
                                        value={defaultExpenseType ?? ALL_EXP_TYPES}
                                        onChange={handleExpenseTypeChange}
                                    >
                                        {Object.values(ExpenseCategory).map((et) => {
                                            return (
                                                et != ExpenseCategory.Other &&
                                                et != ExpenseCategory.CalculatorExpense && (
                                                    <MenuItem key={et} value={et as ExpenseCategory}>
                                                        {ExpenseTypeSR[et as ExpenseCategory]}
                                                    </MenuItem>
                                                )
                                            );
                                        })}
                                        <MenuItem key={ExpenseCategory.Other} value={ExpenseCategory.Other}>
                                            {ExpenseTypeSR[ExpenseCategory.Other]}
                                        </MenuItem>
                                        <MenuItem key={ALL_EXP_TYPES} value={ALL_EXP_TYPES}>
                                            Svi
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        {recordType !== RecordType.Projects && (
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Početni datum"
                                            disableMaskedInput
                                            value={(query as RecordSearchTokenModel).startingDate ?? new Date()}
                                            inputFormat="DD/MM/YYYY"
                                            minDate={dayjs('1940-01-01')}
                                            onChange={(newValue) => {
                                                handleStartDateChange
                                                    ? handleStartDateChange(newValue?.toDate())
                                                    : console.log('no handleStartDateChange() func');
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                        )}
                        {recordType !== RecordType.Projects && (
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Završni datum"
                                            disableMaskedInput
                                            value={(query as RecordSearchTokenModel).endingDate ?? new Date()}
                                            inputFormat="DD/MM/YYYY"
                                            minDate={dayjs('1940-01-01')}
                                            onChange={(newValue) => {
                                                handleEndDateChange
                                                    ? handleEndDateChange(newValue?.toDate())
                                                    : console.log('no handleEndDateChange() func');
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </Popover>
        </Box>
    );
}
