import React, { ReactElement } from 'react';
import './Details.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';
import DriverDetails from './DriversDetails';
import WorkingToolsDetails from './WorkingToolsDetails';
import VehicleDetails from './VehiclesDetails';
import {
    isAppTypeClient,
    isAppTypeDriver,
    isAppTypeVehicle,
    isAppTypeWorkingToolCat,
} from '../../Extensions/Selectors/Selectors';
import CustomerDetails from './CustomerDetails';
import { useLocation } from 'react-router-dom';
import { DriverDto } from '../../AutoGeneratedAPI/clientApi';

export default function DetailsText(): ReactElement {
    const entity = useSelector((state: RootState) => state.currentEntity.entity);

    const location = useLocation();
    const path = location.pathname;

    const isVehicle = isAppTypeVehicle(path);
    const isClient = isAppTypeClient(path);
    const isWorkingTool = isAppTypeWorkingToolCat(path);
    const isDriver = isAppTypeDriver(path);

    const details = () => {
        if (isDriver) {
            return <DriverDetails driver={entity as DriverDto} />;
        } else if (isVehicle) {
            return <VehicleDetails entity={entity} />;
        } else if (isWorkingTool) {
            return <WorkingToolsDetails entity={entity} />;
        } else if (isClient) {
            return <CustomerDetails entity={entity} />;
        } else {
            return <></>;
        }
    };
    return <div className="details">{details()}</div>;
}
