/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ShipmentDto, StopDto } from '../../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithAdding, ExceptionsWithDeleting, ExceptionsWithUpdating } from '../../../Extensions/Exceptions';
import {
    addShipmentToBeginning,
    removeShipmentFromSelectableArray,
    updateShipment,
} from '../../Reducers/Shipments/ShipmentReducer';
import { replaceCalendarTransportWarrantItem } from '../../Reducers/Warrants/CalendarReducer';
import { addSelectedShipmentForTw } from '../../Reducers/Warrants/TransportWarrantFormReducer';

export const updateOldShipment = (shipment: ShipmentDto | undefined, startingStop: StopDto, endingStop: StopDto) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    if (shipment) {
        GranitClient.updateShipment(shipment.id as number, shipment as ShipmentDto).then(
            (result) => {
                const ship = result;
                ship.startingStop = startingStop;
                ship.endingStop = endingStop;
                dispatch(updateShipment(ship));

                dispatch(replaceCalendarTransportWarrantItem(ship));
            },
            (error) => {
                alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_SHIPMENT);
                console.log(error.message);
            },
        );
    }
};

export const addNewShipment = (shipment: ShipmentDto | undefined, startingStop: StopDto, endingStop: StopDto) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    if (shipment) {
        GranitClient.addNewShipment(shipment).then(
            (result) => {
                const ship = result;
                ship.startingStop = startingStop;
                ship.endingStop = endingStop;

                dispatch(addShipmentToBeginning(ship));

                dispatch(addSelectedShipmentForTw(ship));
            },
            (error) => {
                alert(ExceptionsWithAdding.EXCEPTION_ADDING_SHIPMENT);
                console.log(error.message);
            },
        );
    }
};

export const deleteShipment = (shipmentId: number, shipmentDto: ShipmentDto) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    GranitClient.deleteShipment(shipmentId, shipmentDto).then(
        () => {
            dispatch(removeShipmentFromSelectableArray(shipmentId));
        },
        (error) => {
            alert(ExceptionsWithDeleting.EXCEPTION_DELETING_SHIPMENT);
            console.log(error.message);
        },
    );
};
