import React, { ChangeEvent, ReactElement, useState } from 'react';
import './Feed.css';
import RecordFeedElement from './FeedElements/RecordFeedElement';
import { RecordSearchTokenModel } from '../../../Extensions/models/RecordSearchTokenModel';
import 'dayjs/locale/sr';
import SearchWindow from './SearchWindow';
import { RecordType } from '../../../Extensions/Extensions';

export default function RecordsFeed(): ReactElement {
    const [query, setQuery] = useState<RecordSearchTokenModel>({
        searchText: '',
        startingDate: undefined,
        endingDate: undefined,
        lastEntityId: 0,
    });

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery({ ...query, searchText: e.target.value, lastEntityId: 0 });
    };

    const handleStartDateChange = (date?: Date) => {
        setQuery({ ...query, startingDate: date as Date, lastEntityId: 0 });
    };

    const handleEndDateChange = (date?: Date) => {
        setQuery({ ...query, endingDate: date as Date, lastEntityId: 0 });
    };

    const setRecId = (recId: number) => {
        setQuery({ ...query, lastEntityId: recId });
    };

    return (
        <div style={{ overflowY: 'hidden' }}>
            <SearchWindow
                handleEndDateChange={handleEndDateChange}
                handleSearch={handleSearch}
                handleStartDateChange={handleStartDateChange}
                query={query}
                recordType={RecordType.Record}
            />
            <div className={'outter'}>
                <div className={'inner'}>
                    <RecordFeedElement query={query} setRecordId={setRecId} />
                </div>
            </div>
        </div>
    );
}
