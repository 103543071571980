import { Modal, Typography, Button, Grid } from '@mui/material';
import React, { ReactElement } from 'react';
import './ViberRequest.css';
import viber_logo from '../assets/img/viber_logo.png';

type ViberRequestModalProps = {
    open: boolean;
    sendViberRequest(): void;
    closeModal(): void;
    title: string;
};

export default function ViberRequestModal({
    open,
    sendViberRequest,
    closeModal,
    title,
}: ViberRequestModalProps): ReactElement {
    const onClickSend = () => {
        sendViberRequest();
        closeModal();
    };

    return (
        <div>
            <Modal
                className="viber-request-modal"
                open={open}
                aria-labelledby="viber-request"
                aria-describedby="viber request modal"
            >
                <div className="viber-modal-container">
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="h6">
                                Posaljite{' '}
                                <span className="icon">
                                    <img src={viber_logo} style={{ width: 30, height: 30, marginLeft: 10 }} />
                                </span>{' '}
                                zahtev za slanje dokumenata za - {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="secondary" onClick={() => closeModal()}>
                                Zatvori
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" onClick={() => onClickSend()}>
                                Posalji
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </div>
    );
}
