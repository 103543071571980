import React, { ReactElement } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import DirectionsMap from './DirectionsMap';
import { GOOGLE_MAPS_API_LIBRARIES } from '../../Common';
import { Dimensions } from '../../../General/Extensions/Extensions';

type DirectionsGoogleMapsProps = {
    apiKey: string;
    mapDimensions: Dimensions;
};

export default function DirectionsGoogleMaps({ apiKey, mapDimensions }: DirectionsGoogleMapsProps): ReactElement {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
        libraries: GOOGLE_MAPS_API_LIBRARIES,
    });

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return <DirectionsMap mapDimensions={mapDimensions} />;
}
