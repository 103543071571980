/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CompositionDto } from '../../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithAdding } from '../../../Extensions/Exceptions';
import { addComposition } from '../../Reducers/Compositions/CompositionsReducer';

export const addNewComposition = (composition: CompositionDto | undefined) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    GranitClient.createOrGetComposition(composition as CompositionDto).then(
        (result) => {
            dispatch(addComposition(result));
        },
        (error) => {
            alert(ExceptionsWithAdding.EXCEPTION_ADDING_COMPOSITIONS);
            console.log(error.message);
        },
    );
};

export const addCompositionToRedux = (composition: CompositionDto) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    dispatch(addComposition(composition));
};
