import React, { ReactElement, useEffect, useState } from 'react';
import './Feed.css';
import { TWSearchTokenModel } from '../../../Extensions/models/RecordSearchTokenModel';
import TransportWarrantFeedElement from './FeedElements/TransportWarrantFeedElement';
import 'dayjs/locale/sr';
import SearchWindow from './SearchWindow';
import { RecordType } from '../../../Extensions/Extensions';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { fetchInitialDrivers } from '../../../Redux/Reducers/Drivers/DriversReducer';
import { fetchInitialTrucks } from '../../../Redux/Reducers/Vehicles/VehiclesReducer';
import { RootState } from '../../../Redux/store';

export default function TWFeed(): ReactElement {
    const [query, setQuery] = useState<TWSearchTokenModel>({
        clientId: 0,
        startingDate: undefined,
        endingDate: undefined,
        lastEntityId: 0,
    });

    const trucks = useAppSelector((state: RootState) => state.vehicles.trucks);
    const drivers = useAppSelector((state: RootState) => state.drivers.drivers);

    const dispatch = useAppDispatch();

    const handleStartDateChange = (date?: Date) => {
        setQuery({ ...query, startingDate: date as Date, lastEntityId: 0 });
    };

    const handleEndDateChange = (date?: Date) => {
        setQuery({ ...query, endingDate: date as Date, lastEntityId: 0 });
    };

    const setlastTwId = (twId: number) => {
        setQuery({ ...query, lastEntityId: twId });
    };

    useEffect(() => {
        if (!drivers) {
            dispatch(fetchInitialDrivers());
        }

        if (!trucks) {
            dispatch(fetchInitialTrucks());
        }
    }, []);

    return (
        <div style={{ overflowY: 'hidden' }}>
            <SearchWindow
                handleEndDateChange={handleEndDateChange}
                handleStartDateChange={handleStartDateChange}
                query={query}
                recordType={RecordType.TravelExpense}
            />
            <div className={'outter'}>
                <div className={'inner'}>
                    <TransportWarrantFeedElement setLastTwId={setlastTwId} query={query} />
                </div>
            </div>
        </div>
    );
}
