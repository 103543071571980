import { createSlice } from '@reduxjs/toolkit';
import { ConstructionSiteDto, ProjectDto } from '../../../AutoGeneratedAPI/clientApi';

interface IInitialStateType {
    projects: ProjectDto[];
    constructionSites: ConstructionSiteDto[];
}

const initialState: IInitialStateType = {
    projects: [],
    constructionSites: [],
};

const constructionProjectSlice = createSlice({
    name: 'constructionProject',
    initialState,
    reducers: {
        setInitialConstructionSites(state, action) {
            state.constructionSites = action.payload ?? state.constructionSites;
        },
        concatConstrucionSites(state, action) {
            if (action.payload && action.payload.length > 0) {
                state.constructionSites = [...state.constructionSites, ...action.payload];
            }
        },
        addConstrucionSite(state, action) {
            if (action.payload) {
                state.constructionSites = [...state.constructionSites, action.payload];
            }
        },
        setInitialProjects(state, action) {
            state.projects = action.payload ?? state.projects;
        },
        concatProjects(state, action) {
            if (action.payload && action.payload.length > 0) {
                state.projects = [...state.projects, ...action.payload];
            }
        },
        addProjectToBeginning(state, action) {
            if (action.payload) {
                state.projects = [action.payload, ...state.projects];
            }
        },
        clearConstructionSites(state) {
            state.constructionSites = [];
        },
        updateConstructionSite(state, action) {
            const constSite = action.payload;
            const constSites = state.constructionSites ?? [];
            const constSiteIndex = constSites.findIndex((c) => {
                return c.id === constSite.id;
            });
            state.constructionSites = [
                ...constSites.slice(0, constSiteIndex),
                constSite,
                ...constSites.slice(constSiteIndex + 1),
            ];
        },
        updateProject(state, action) {
            const project = action.payload;
            const projs = state.projects ?? [];
            const projIndex = projs.findIndex((c) => {
                return c.id === project.id;
            });
            state.projects = [...projs.slice(0, projIndex), project, ...projs.slice(projIndex + 1)];
        },
        deleteProject(state, action) {
            const projectId = action.payload;
            const projs = state.projects ?? [];
            const projIndex = projs.findIndex((p) => {
                return p.id === projectId;
            });
            state.projects = [...projs.slice(0, projIndex), ...projs.slice(projIndex + 1)];
        },
        deleteConstructionSite(state, action) {
            const constrSiteId = action.payload;
            const constrSites = state.constructionSites ?? [];
            const constrSiteIndex = constrSites.findIndex((cs) => {
                return cs.id === constrSiteId;
            });
            state.constructionSites = [
                ...constrSites.slice(0, constrSiteIndex),
                ...constrSites.slice(constrSiteIndex + 1),
            ];
        },
    },
});

export const {
    setInitialConstructionSites,
    concatConstrucionSites,
    addConstrucionSite,
    setInitialProjects,
    concatProjects,
    addProjectToBeginning,
    updateConstructionSite,
    updateProject,
    clearConstructionSites,
    deleteProject,
    deleteConstructionSite,
} = constructionProjectSlice.actions;

export default constructionProjectSlice.reducer;
