import * as React from 'react';
import { useState, MouseEvent, ReactElement } from 'react';
import { Badge, IconButton, Modal, Tooltip } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { UserInfoWindow } from '../Loging/UserInfoWindow';
import { EntityType, KmEventDto, NotificationDto, TimeEventDto } from '../AutoGeneratedAPI/clientApi';
import { useAppSelector } from '../hooks/hooks';
import { RootState } from '../Redux/store';
import NotificationsList from '../Notifications/NotificationsList';
import NotificationInfo from '../Notifications/NotificationInfo';
import EventForm from '../Forms/EventForm/EventForm';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import AlarmIcon from '@mui/icons-material/Alarm';
import EventsList from '../Events/EventsList';

export function NavbarConstElements(): ReactElement {
    const kmEvents = useAppSelector((state: RootState) => state.notifications.kmEvents);
    const timeEvents = useAppSelector((state: RootState) => state.notifications.timeEvents);

    const [openNotificationInfo, setOpenNotitificationInfo] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [userInfoAnchorEl, setUserInfoAnchorEl] = useState<null | HTMLElement>(null);
    const [notification, setNotification] = useState<NotificationDto | undefined>(undefined);
    const [openEventModal, setOpenEventModal] = useState<boolean>(false);

    const [eventsListAnchorEl, setEventsListAnchorEl] = useState<null | HTMLElement>(null);

    const [defaultTimeEvent, setDefaultTimeEvent] = useState<TimeEventDto | undefined>(undefined);
    const [defaultKmEvent, setDefaultKmEvent] = useState<KmEventDto | undefined>(undefined);

    const notifications = useAppSelector((state: RootState) => state.notifications.notifications);
    const myInfo = useAppSelector((state: RootState) => state.loggedEmployee.employee?.employee);

    const handleSelectEvent = (eventId: number, isKmEvent: boolean) => {
        if (isKmEvent) {
            const defKmEvent = kmEvents?.find((e) => e.id === eventId);
            setDefaultKmEvent(defKmEvent);
        } else {
            const defTimeEvent = timeEvents?.find((e) => e.id === eventId);
            setDefaultTimeEvent(defTimeEvent);
        }
        setOpenEventModal(true);
    };

    const handleClickEventsListAnchor = (event: MouseEvent<HTMLButtonElement>) => {
        setEventsListAnchorEl(event.currentTarget);
    };

    const handleCloseEventsListAnchor = () => {
        setEventsListAnchorEl(null);
    };

    const handleOpenLogOutAnchor = (event: MouseEvent<HTMLButtonElement>) => {
        setUserInfoAnchorEl(event.currentTarget);
    };

    const handleCloseLogOutAnchor = () => {
        setUserInfoAnchorEl(null);
    };

    const handleOpenNotificationInfo = (): void => {
        setOpenNotitificationInfo(true);
    };

    const handleCloseNotificationInfo = (): void => {
        setOpenNotitificationInfo(false);
    };

    const handleOpenEventModal = (): void => {
        setOpenEventModal(true);
    };

    const handleCloseEventModal = (): void => {
        setOpenEventModal(false);
        if (defaultKmEvent) {
            setDefaultKmEvent(undefined);
        }
        if (defaultTimeEvent) {
            setDefaultTimeEvent(undefined);
        }
    };

    const handleClickAnchor = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAnchor = () => {
        setAnchorEl(null);
    };

    const handleSelectNotification = (index: number) => {
        const note = notifications?.find((note) => note.id === index);

        setNotification(note);

        if (note) {
            handleOpenNotificationInfo();
        }
    };

    return (
        <div>
            <Tooltip title="Pregledaj podsetnike" placement="bottom">
                <IconButton color="inherit" onClick={handleClickAnchor}>
                    {notifications !== null && notifications.filter((n) => !n.seen).length > 0 ? (
                        <Badge badgeContent={notifications.filter((n) => !n.seen).length} color="error">
                            <NotificationsActiveIcon />
                        </Badge>
                    ) : (
                        <NotificationsIcon />
                    )}
                </IconButton>
            </Tooltip>
            <Tooltip title="Pregledaj buduće podsetnike" placement="bottom">
                <IconButton color="inherit" onClick={handleClickEventsListAnchor}>
                    <AlarmIcon />
                </IconButton>
            </Tooltip>
            <NotificationsList
                anchorEl={anchorEl}
                closeWindow={handleCloseAnchor}
                selectNotification={handleSelectNotification}
            />
            <Tooltip title="Dodaj podsetnik" placement="bottom">
                <IconButton color="inherit" onClick={handleOpenEventModal}>
                    <AddAlertIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Moje informacije" placement="bottom">
                <IconButton color="inherit" edge="end" onClick={handleOpenLogOutAnchor}>
                    <AccountCircleIcon style={{ width: 30, height: 30 }} />
                </IconButton>
            </Tooltip>
            <UserInfoWindow userInfoAnchorEl={userInfoAnchorEl} close={handleCloseLogOutAnchor} />
            <NotificationInfo
                open={openNotificationInfo}
                closeWindow={handleCloseNotificationInfo}
                notification={notification}
            />
            <EventsList
                anchorEl={eventsListAnchorEl}
                closeWindow={handleCloseEventsListAnchor}
                entityId={myInfo?.id ?? 0}
                entityType={EntityType.Employee}
                handleSelectEvent={handleSelectEvent}
            />
            <div>
                <Modal
                    className="modalStyle"
                    open={openEventModal}
                    aria-labelledby="add-vehicle-form"
                    aria-describedby="adding a vehicle to a database through this form"
                >
                    <>
                        <EventForm
                            closeForm={handleCloseEventModal}
                            entityType={EntityType.Employee}
                            entityId={myInfo?.id ?? 0}
                            defaultKmEvent={defaultKmEvent}
                            defaultTimeEvent={defaultTimeEvent}
                        />
                    </>
                </Modal>
            </div>
        </div>
    );
}
