import { Typography } from '@mui/material';
import React, { ReactElement, ReactNode, useState } from 'react';
import { DriverDto, ITransportWarrantDto } from '../../../AutoGeneratedAPI/clientApi';
import CollapsableFormSection from '../../../../Common/CommonComponents/CollapsableFormSection';
import { Person } from '@microsoft/mgt-react';
import DriversElement from '../Elements/DriversElement';
import { useSearchParams } from 'react-router-dom';

type DriversSectionProps = {
    driversAzureObjIds: string[];
    handleDriversAdding(driver: DriverDto): void;
    transportWarrant: ITransportWarrantDto;
};

export default function DriversSection({
    driversAzureObjIds,
    handleDriversAdding,
    transportWarrant,
}: DriversSectionProps): ReactElement {
    const [searchParams] = useSearchParams();

    const [expand, setExpand] = useState<boolean>(
        (!searchParams || !searchParams.get('twId') || searchParams.get('twId') === '0') &&
            (!driversAzureObjIds || driversAzureObjIds.length <= 0)
            ? true
            : false,
    );

    const handleInitialExpandChange = (newExpand: boolean) => {
        setExpand(newExpand);
    };

    const handleDriverAdding = (driver: DriverDto): void => {
        if (driversAzureObjIds.includes(driver.azObjId ?? '')) {
            handleDriversAdding(driver);
            handleInitialExpandChange(true);
        } else {
            handleDriversAdding(driver);
            handleInitialExpandChange(false);
        }
    };

    const titleComponent = (): ReactNode => {
        return (
            <>
                <Typography component="p" variant="body1" align="left" style={{ display: 'inline' }}>
                    {driversAzureObjIds.length > 1 ? 'Vozači:' : driversAzureObjIds.length <= 0 ? 'Vozači' : 'Vozač:'}
                </Typography>
                {driversAzureObjIds.length > 0 &&
                    driversAzureObjIds.map((daoi) => {
                        return (
                            <Typography
                                key={daoi}
                                component="p"
                                variant="body1"
                                align="left"
                                style={{ display: 'inline', marginLeft: 10 }}
                            >
                                <Person userId={daoi} />
                            </Typography>
                        );
                    })}
            </>
        );
    };

    return (
        <CollapsableFormSection
            initialExpanded={expand}
            titleComponents={titleComponent()}
            handleInitialExpandChange={handleInitialExpandChange}
        >
            <DriversElement
                handleDriverAdding={handleDriverAdding}
                currentDrivers={transportWarrant.driverJobPositionIds ?? []}
            />
        </CollapsableFormSection>
    );
}
