import React, { ReactElement } from 'react';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import './RightSidebar.css';

const openedMixin = (theme: Theme, minwidth?: string, defaultheight?: string): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
    }),
    width: minwidth,
    height: defaultheight,
    marginTop: 66,
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme, defaultheight?: string): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
    }),
    overflowX: 'hidden',
    width: 0,
    marginTop: 66,
    [theme.breakpoints.up('sm')]: {
        width: 0,
    },
    height: defaultheight,
});

type DrawerProps = {
    minwidth?: string;
    defaultheight?: string;
};

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })<DrawerProps>(
    ({ theme, open, minwidth, defaultheight }) => ({
        width: minwidth,
        minWidth: minwidth,
        zIndex: 2,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        height: defaultheight,
        ...(open && {
            ...openedMixin(theme, minwidth, defaultheight),
            '& .MuiDrawer-paper': openedMixin(theme, minwidth, defaultheight),
        }),
        ...(!open && {
            ...closedMixin(theme, defaultheight),
            '& .MuiDrawer-paper': closedMixin(theme, defaultheight),
        }),
    }),
);

type RightSidebarProps = {
    children: ReactElement;
    open: boolean;
    width?: string;
    defaultheight?: string;
};

export default function RightSidebar(props: RightSidebarProps): ReactElement {
    return (
        <div style={{ height: 0 }}>
            <Drawer
                variant="permanent"
                anchor={'right'}
                open={props.open}
                minwidth={props.width ?? '500px'}
                defaultheight={props.defaultheight ?? '100%'}
            >
                {props.open && <>{props.children}</>}
            </Drawer>
        </div>
    );
}
