import React, { ReactElement, MouseEvent, useEffect } from 'react';
import './Details.css';
import user_logo from '../../../General/assets/img/user_logo.png';
import car_logo from '../../../General/assets/img/car_logo.png';
import truck_logo from '../../../General/assets/img/truck_logo.jpg';
import trailer_logo from '../../../General/assets/img/trailer_logo.jpg';
import workingtool_logo from '../../../General/assets/img/workingtool_logo.png';
import workingmachine_logo from '../../../General/assets/img/workingmachine_logo.jpg';
import officemachine_logo from '../../../General/assets/img/officemachine_logo.jpg';
import handmachine_logo from '../../../General/assets/img/handmachine_logo.jpg';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import { Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';
import { ExceptionsWithAdding } from '../../Extensions/Exceptions';
import { DetailsMenu } from './DetailsMenu';
import { useState } from 'react';
import { changeVehicleProfileImage } from '../../Redux/Reducers/Vehicles/VehiclesReducer';
import { changeClientProfileImage } from '../../Redux/Reducers/Customers/CustomersReducer';
import { changeHandMachineProfileImage } from '../../Redux/Reducers/WorkingTools/HandMachinesReducer';
import { changeOfficeMachineProfileImage } from '../../Redux/Reducers/WorkingTools/OfficeMachinesReducer';
import { changeWorkingMachineProfileImage } from '../../Redux/Reducers/WorkingTools/WorkingMachinesReducer';
import { changeWorkingToolProfileImage } from '../../Redux/Reducers/WorkingTools/WorkingToolsReducer';
import {
    isAppTypeCar,
    isAppTypeClient,
    isAppTypeDriver,
    isAppTypeHandMachine,
    isAppTypeOfficeMachine,
    isAppTypeTrailer,
    isAppTypeTruck,
    isAppTypeVehicle,
    isAppTypeWorkingMachine,
    isAppTypeWorkingTool,
    isAppTypeWorkingToolCat,
} from '../../Extensions/Selectors/Selectors';
import { PROFILE_IMAGE } from '../../../Common/Consts';
import ImageComponent from '../../../Common/CommonComponents/ImageComponent';
import { getBaseServerLink } from '../../AutoGeneratedAPI/Extension';
import { useLocation } from 'react-router-dom';
import { DriverDto } from '../../AutoGeneratedAPI/clientApi';
import { getGraphDriverPhoto } from '../../Extensions/Selectors/Drivers/DriverSelector';
import { getPhotoUrl } from '../../../Common/Common';

export default function DetailsImage(): ReactElement {
    const [prflImg, setPrflImg] = useState<string>('');
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const entity = useSelector((state: RootState) => state.currentEntity.entity);

    const location = useLocation();
    const path = location.pathname;

    const isVehicle = isAppTypeVehicle(path);
    const isClient = isAppTypeClient(path);
    const isWorkingToolCat = isAppTypeWorkingToolCat(path);
    const isDriver = isAppTypeDriver(path);
    const isCar = isAppTypeCar(path);
    const isTruck = isAppTypeTruck(path);
    const isTrailer = isAppTypeTrailer(path);
    const isWorkingTool = isAppTypeWorkingTool(path);
    const isWorkingMachine = isAppTypeWorkingMachine(path);
    const isOfficeMachine = isAppTypeOfficeMachine(path);
    const isHandMachine = isAppTypeHandMachine(path);

    const dispatch = useDispatch();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (isDriver) {
            getGraphDriverPhoto(entity as DriverDto).then((res) => {
                setPrflImg(getPhotoUrl(res));
            });
        } else {
            const docs = entity?.documents;
            if (docs) {
                const entityDoc = docs.find((c) => c?.category === PROFILE_IMAGE);
                let profileImageLink = '';
                if (entityDoc) {
                    if (entityDoc.requestPaths !== undefined) {
                        profileImageLink = entityDoc.requestPaths[0] ?? '';
                    }
                }
                setPrflImg(profileImageLink ?? '');
            }
        }
    }, [entity]);

    const handleFileInputChange = async (selectorFiles: FileList | undefined) => {
        const filesArray = selectorFiles !== undefined ? Array.from(selectorFiles).map((file) => file) : undefined;

        entity !== null
            ? isVehicle
                ? await GranitClient.saveVehicleDocument(
                      entity.id as number,
                      PROFILE_IMAGE,
                      filesArray,
                      '{ "test": "ovo je test"}',
                  ).then((res) => {
                      dispatch(changeVehicleProfileImage(entity.id as number, res));
                      if (res.requestPaths !== undefined) {
                          setPrflImg(res.requestPaths[0]);
                      }
                  })
                : isWorkingToolCat
                ? await GranitClient.saveWorkingToolDocument(
                      entity.id as number,
                      PROFILE_IMAGE,
                      filesArray,
                      '{ "test": "ovo je test"}',
                  ).then((res) => {
                      switch (true) {
                          case isAppTypeWorkingTool(path):
                              dispatch(changeWorkingToolProfileImage(entity.id as number, res));
                              break;
                          case isAppTypeWorkingMachine(path):
                              dispatch(changeWorkingMachineProfileImage(entity.id as number, res));
                              break;
                          case isAppTypeOfficeMachine(path):
                              dispatch(changeOfficeMachineProfileImage(entity.id as number, res));
                              break;
                          case isAppTypeHandMachine(path):
                              dispatch(changeHandMachineProfileImage(entity.id as number, res));
                              break;
                          default:
                              break;
                      }
                      if (res.requestPaths !== undefined) {
                          setPrflImg(res.requestPaths[0]);
                      }
                  })
                : isClient
                ? await GranitClient.saveClientDocument(
                      entity.id as number,
                      PROFILE_IMAGE,
                      filesArray,
                      '{ "test": "ovo je test"}',
                  ).then((res) => {
                      dispatch(changeClientProfileImage(entity.id as number, res));
                      if (res.requestPaths !== undefined) {
                          setPrflImg(res.requestPaths[0]);
                      }
                  })
                : alert(ExceptionsWithAdding.EXCEPTION_ADDING_DOCUMENT)
            : (alert(ExceptionsWithAdding.EXCEPTION_ADDING_DOCUMENT),
              console.log('Could not save the document, ERROR: No vehicle'));
    };

    const imageSection = (): ReactElement => {
        const imgLink =
            prflImg !== ''
                ? getBaseServerLink(prflImg)
                : isDriver || isClient
                ? user_logo
                : isCar
                ? car_logo
                : isTruck
                ? truck_logo
                : isTrailer
                ? trailer_logo
                : isWorkingTool
                ? workingtool_logo
                : isWorkingMachine
                ? workingmachine_logo
                : isOfficeMachine
                ? officemachine_logo
                : isHandMachine
                ? handmachine_logo
                : '';
        return (
            <div className="img-div">
                <ImageComponent placeholderImg="" src={imgLink} isDetails={true} />
                <Button style={{ height: 50, position: 'absolute', left: -20, top: 0 }} onClick={handleClick}>
                    <MoreVertIcon />
                </Button>
                <DetailsMenu
                    anchorEl={anchorEl}
                    handleCloseAnchor={handleClose}
                    entity={entity}
                    handleFileInputChange={handleFileInputChange}
                />
            </div>
        );
    };
    return imageSection();
}
