import React, { ReactElement } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import EuroIcon from '@mui/icons-material/Euro';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { RecordType } from '../Extensions/Extensions';
import { isAppTypeClient, isAppTypeDriver, isAppTypeVehicle } from '../Extensions/Selectors/Selectors';
import { useLocation } from 'react-router-dom';

type AddRecordExpenseBtnsProps = {
    handleOpen(recType: RecordType): void;
};

export default function AddRecordExpenseBtns({ handleOpen }: AddRecordExpenseBtnsProps): ReactElement {
    const [speedDialToggle, setSpeedDialToggle] = React.useState(false);

    const location = useLocation();
    const path = location.pathname;

    const isDriver = isAppTypeDriver(path);
    const isVehicle = isAppTypeVehicle(path);
    const isClient = isAppTypeClient(path);

    const actions = isVehicle
        ? [
              { icon: <DescriptionIcon color="primary" />, name: 'Evidencija' },
              { icon: <EuroIcon color="primary" />, name: 'Trošak' },
              { icon: <InsertDriveFileIcon color="primary" />, name: 'Opšta dokumenta' },
          ]
        : isDriver
        ? [
              { icon: <DescriptionIcon color="primary" />, name: 'Evidencija' },
              { icon: <InsertDriveFileIcon color="primary" />, name: 'Opšta dokumenta' },
          ]
        : isClient
        ? [
              { icon: <DescriptionIcon color="primary" />, name: 'Evidencija' },
              { icon: <AccountBalanceIcon color="primary" />, name: 'Projekti / Gradilišta' },
              { icon: <ReceiptIcon color="primary" />, name: 'Računi' },
          ]
        : [
              { icon: <DescriptionIcon color="primary" />, name: 'Evidencija' },
              { icon: <EuroIcon color="primary" />, name: 'Trošak' },
          ];

    const handleSpeedDialToggle = () => {
        setSpeedDialToggle(!speedDialToggle);
    };

    return (
        <div>
            <div>
                <SpeedDial
                    ariaLabel="add record btns"
                    className="add-record-btns"
                    icon={<SpeedDialIcon />}
                    onClick={handleSpeedDialToggle}
                    open={speedDialToggle}
                    direction="left"
                    color="secondary"
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            tooltipPlacement="top"
                            onClick={() => {
                                handleOpen(
                                    action.name === 'Trošak'
                                        ? RecordType.Expense
                                        : action.name === 'Evidencija'
                                        ? RecordType.Record
                                        : action.name === 'Računi'
                                        ? RecordType.Invoices
                                        : action.name === 'Projekti / Gradilišta'
                                        ? RecordType.Projects
                                        : RecordType.ImportantDocument,
                                );
                            }}
                        />
                    ))}
                </SpeedDial>
            </div>
        </div>
    );
}
