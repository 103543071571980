export enum routes {
    // Roots
    firstPage = '/',
    twPrintBase = 'tw_print',
    twPrint = 'tw_print/:twId',
    charts = 'charts',
    calendar = 'calendar',
    mainMenu = '/',
    catalogue = 'catalogue',
    // Nested pages
    // CLIENTS
    clients = '/catalogue/clients',
    // services
    servicesBare = 'services',
    services = '/catalogue/services',
    serviceDetails = 'services/:entityId',
    serviceDetailsRecords = 'services/:entityId/records',
    serviceDetailsRecordsRecord = 'services/:entityId/records/:recId',
    serviceDetailsInvoices = 'services/:entityId/invoices',
    serviceDetailsProjects = 'services/:entityId/projects',
    // suppliers
    suppliersBare = 'suppliers',
    suppliers = '/catalogue/suppliers',
    supplierDetails = 'suppliers/:entityId',
    supplierDetailsRecords = 'suppliers/:entityId/records',
    supplierDetailsRecordsRecord = 'suppliers/:entityId/records/:recId',
    supplierDetailsInvoices = 'suppliers/:entityId/invoices',
    supplierDetailsProjects = 'suppliers/:entityId/projects',
    // cutomers
    customersBare = 'customers',
    customers = '/catalogue/customers',
    customerDetails = 'customers/:entityId',
    customerDetailsRecords = 'customers/:entityId/records',
    customerDetailsRecordsRecord = 'customers/:entityId/records/:recId',
    customerDetailsInvoices = 'customers/:entityId/invoices',
    customerDetailsProjects = 'customers/:entityId/projects',
    // EMPLOYEES
    employees = '/catalogue/employees',
    // drivers
    driversBare = 'drivers',
    drivers = '/catalogue/drivers',
    driversDetails = 'drivers/:entityId',
    driversDetailsRecords = 'drivers/:entityId/records',
    driversDetailsRecordsRecord = 'drivers/:entityId/records/:recId',
    driversDetailsTransportWarrants = 'drivers/:entityId/transportwarrants',
    driversDetailsImportantDocuments = 'drivers/:entityId/impdocuments',
    driversDetailsImportantDocumentsImpDoc = 'drivers/:entityId/impdocuments/:impDocId',
    // VEHICLES
    vehicles = '/catalogue/vehicles',
    // trailers
    trailersBare = 'trailers',
    trailers = '/catalogue/trailers',
    trailerDetails = 'trailers/:entityId',
    trailerDetailsRecords = 'trailers/:entityId/records',
    trailerDetailsRecordsRecord = 'trailers/:entityId/records/:recId',
    trailerDetailsExpenses = 'trailers/:entityId/expenses',
    trailerDetailsExpensesExpense = 'trailers/:entityId/expenses/:expId',
    trailerDetailsTransportWarrants = 'trailers/:entityId/transportwarrants',
    trailerDetailsImportantDocuments = 'trailers/:entityId/impdocuments',
    trailerDetailsImportantDocumentsImpDoc = 'trailers/:entityId/impdocuments/:impDocId',
    // trucks
    trucksBare = 'trucks',
    trucks = '/catalogue/trucks',
    truckDetails = 'trucks/:entityId',
    truckDetailsRecords = 'trucks/:entityId/records',
    truckDetailsRecordsRecord = 'trucks/:entityId/records/:recId',
    truckDetailsExpenses = 'trucks/:entityId/expenses',
    truckDetailsExpensesExpense = 'trucks/:entityId/expenses/:expId',
    truckDetailsTransportWarrants = 'trucks/:entityId/transportwarrants',
    truckDetailsImportantDocuments = 'trucks/:entityId/impdocuments',
    truckDetailsImportantDocumentsImpDoc = 'trucks/:entityId/impdocuments/:impDocId',
    // cars
    carsBare = 'cars',
    cars = '/catalogue/cars',
    carDetails = 'cars/:entityId',
    carDetailsRecords = 'cars/:entityId/records',
    carDetailsRecordsRecord = 'cars/:entityId/records/:recId',
    carDetailsExpenses = 'cars/:entityId/expenses',
    carDetailsExpensesExpense = 'cars/:entityId/expenses/:expId',
    carDetailsTransportWarrants = 'cars/:entityId/transportwarrants',
    carDetailsImportantDocuments = 'cars/:entityId/impdocuments',
    carDetailsImportantDocumentsImpDoc = 'cars/:entityId/impdocuments/:impDocId',
    // WORKINGMACHINES
    workingmachinesBase = '/catalogue/workingmachines',
    // workingTools
    workingtoolsBare = 'workingtools',
    workingtools = '/catalogue/workingtools',
    workingtoolDetails = 'workingtools/:entityId',
    workingtoolDetailsRecords = 'workingtools/:entityId/records',
    workingtoolDetailsRecordsRecord = 'workingtools/:entityId/records/:recId',
    workingtoolDetailsExpenses = 'workingtools/:entityId/expenses',
    workingtoolDetailsExpensesExpense = 'workingtools/:entityId/expenses/:expId',
    workingtoolDetailsTransportWarrants = 'workingtools/:entityId/transportwarrants',
    workingtoolDetailsImportantDocuments = 'workingtools/:entityId/impdocuments',
    workingtoolDetailsImportantDocumentsImpDoc = 'workingtools/:entityId/impdocuments/:impDocId',
    // workingMachines
    workingmachinesBare = 'workingmachines',
    workingmachines = '/catalogue/workingmachines',
    workingmachineDetails = 'workingmachines/:entityId',
    workingmachineDetailsRecords = 'workingmachines/:entityId/records',
    workingmachineDetailsRecordsRecord = 'workingmachines/:entityId/records/:recId',
    workingmachineDetailsExpenses = 'workingmachines/:entityId/expenses',
    workingmachineDetailsExpensesExpense = 'workingmachines/:entityId/expenses/:expId',
    workingmachineDetailsTransportWarrants = 'workingmachines/:entityId/transportwarrants',
    workingmachineDetailsImportantDocuments = 'workingmachines/:entityId/impdocuments',
    workingmachineDetailsImportantDocumentsImpDoc = 'workingmachines/:entityId/impdocuments/:impDocId',
    // handMachines
    handmachinesBare = 'handmachines',
    handmachines = '/catalogue/handmachines',
    handmachineDetails = 'handmachines/:entityId',
    handmachineDetailsRecords = 'handmachines/:entityId/records',
    handmachineDetailsRecordsRecord = 'handmachines/:entityId/records/:recId',
    handmachineDetailsExpenses = 'handmachines/:entityId/expenses',
    handmachineDetailsExpensesExpense = 'handmachines/:entityId/expenses/:expId',
    handmachineDetailsTransportWarrants = 'handmachines/:entityId/transportwarrants',
    handmachineDetailsImportantDocuments = 'handmachines/:entityId/impdocuments',
    handmachineDetailsImportantDocumentsImpDoc = 'handmachines/:entityId/impdocuments/:impDocId',
    // oficeMachines
    officemachinesBare = 'officemachines',
    officemachines = '/catalogue/officemachines',
    officemachineDetails = 'officemachines/:entityId',
    officemachineDetailsRecords = 'officemachines/:entityId/records',
    officemachineDetailsRecordsRecord = 'officemachines/:entityId/records/:recId',
    officemachineDetailsExpenses = 'officemachines/:entityId/expenses',
    officemachineDetailsExpensesExpense = 'officemachines/:entityId/expenses/:expId',
    officemachineDetailsTransportWarrants = 'officemachines/:entityId/transportwarrants',
    officemachineDetailsImportantDocuments = 'officemachines/:entityId/impdocuments',
    officemachineDetailsImportantDocumentsImpDoc = 'officemachines/:entityId/impdocuments/:impDocId',
    // profile
    profile = '/profile',
    events = 'events',
    tasks = 'tasks',

    // helpers
    records = '/records',
    expenses = '/expenses',
    transportwarrants = '/transportwarrants',
    impdocuments = '/impdocuments',
    projects = '/projects',
    invoices = '/invoices',
    updateForm = '/updateform',
}
