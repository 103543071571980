/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../Redux/store';
import { deleteInvoice } from '../../../../Redux/Actions/Invoice/InvoiceAction';
import EmptyPage from '../../../../EmptyPage';
import useInvoicesSearch from '../../../../Extensions/Hooks/RecordsHooks/InvoiceSearchingHook';
import { InvoiceSearchTokenModel } from '../../../../Extensions/models/RecordSearchTokenModel';
import MuiTableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import {
    Box,
    Collapse,
    Modal,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InvoiceForm from '../../../../Forms/Invoice/InvoiceForm';
import ConfirmDialog from '../../../../Forms/Common/ConfirmationDialog';
import { LoaderProgress } from '../../../../../Common/CommonComponents/Loader/LoaderProgress';

const TableCell = styled(
    MuiTableCell,
    {},
)(({}) => ({
    borderBottom: 'unset',
}));

function createData(id: number, code: string, creationDate: string, description?: string) {
    return {
        id,
        code,
        creationDate,
        description,
    };
}

type InvoicesFeedElementProps = {
    query: InvoiceSearchTokenModel;
    setInvoiceId(invoiceId: number): void;
};

export default function InvoicesFeedElement({ query, setInvoiceId }: InvoicesFeedElementProps): ReactElement {
    const [currentInvoiceIndex, setCurrentInvoiceIndex] = useState<number | undefined>(undefined);
    const [openInvForm, setOpenInvoiceForm] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [rowIndex, setRowIndex] = useState(0);

    const dispatch = useDispatch();

    const invoices = useSelector((state: RootState) => state.invoices.invoices);
    const entity = useSelector((state: RootState) => state.currentEntity.entity);

    const { loadingInvoices, hasMoreInvoices, invoiceError } = useInvoicesSearch(entity?.id ?? 0, query);

    const observer = useRef<IntersectionObserver>();

    const lastExpenseRef = useCallback(
        (node: any) => {
            if (loadingInvoices) {
                return;
            }

            if (observer.current) {
                observer.current.disconnect();
            }
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMoreInvoices) {
                    setInvoiceId(invoices[invoices.length - 1].id ?? 0);
                }
            });

            if (node) {
                observer.current.observe(node);
            }
        },
        [loadingInvoices, hasMoreInvoices],
    );

    const handleOpenInvoiceForm = (index: number): void => {
        setCurrentInvoiceIndex(index);
        setOpenInvoiceForm(true);
    };

    const handleCloseInvoiceForm = (): void => {
        setOpenInvoiceForm(false);
    };

    const deleteOldInvoice = (index: number): void => {
        dispatch(deleteInvoice(index));
    };

    const rows = invoices?.map((i) => {
        return createData(
            i.id ?? -1,
            i.code ? (i.code !== '' ? i.code : 'Nema naziva') : 'Nema naziva',
            new Date(i.creationDate as Date).toLocaleDateString('en-UK'),
            i.description,
        );
    });

    const handleOpenConfirmDialog = (currRowIndex: number): void => {
        setRowIndex(currRowIndex);
        setShowConfirmDialog(true);
    };

    const handleCofirmConfirmDialog = (): void => {
        setShowConfirmDialog(false);
        deleteOldInvoice(rowIndex);
    };

    const handleCancelConfirmDialog = (): void => {
        setShowConfirmDialog(false);
    };

    const ViewElement = () => {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell>Kod</TableCell>
                                <TableCell>Datum izdavanja</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row, index) => {
                                const [openDrawer, setOpenDrawer] = useState(false);
                                return (
                                    <>
                                        <TableRow ref={rows.length === index + 1 ? lastExpenseRef : null}>
                                            <TableCell>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => setOpenDrawer(!openDrawer)}
                                                >
                                                    {openDrawer ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                onClick={() => handleOpenConfirmDialog(row.id)}
                                            >
                                                <p style={{ cursor: 'pointer' }}>
                                                    <DeleteIcon style={{ fontSize: 20, color: '#B33E41' }} />
                                                </p>
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                onClick={() => handleOpenInvoiceForm(row.id)}
                                            >
                                                <p style={{ cursor: 'pointer' }}>
                                                    <EditIcon style={{ fontSize: 20, color: '#366E9B' }} />
                                                </p>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.code}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.creationDate}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                <Collapse in={openDrawer} timeout="auto" unmountOnExit>
                                                    <Box margin={1}>
                                                        <Typography variant="h6" gutterBottom component="div">
                                                            Opis
                                                        </Typography>
                                                        <Table size="small" aria-label="purchases">
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">
                                                                        {row.description}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div>{loadingInvoices && 'Učitava se...'}</div>
                <div>{invoiceError && 'Greška'}</div>

                <div>
                    <Modal
                        className="modalStyle"
                        open={openInvForm}
                        aria-labelledby="add-vehicle-form"
                        aria-describedby="adding a vehicle to a database through this form"
                    >
                        <>
                            <InvoiceForm closeForm={handleCloseInvoiceForm} invoiceIndex={currentInvoiceIndex} />
                        </>
                    </Modal>
                </div>
                <ConfirmDialog
                    visible={showConfirmDialog}
                    title="Potvrda"
                    message="Da li ste sigurni da želite da obrišete račun?"
                    onConfirmSubmit={handleCofirmConfirmDialog}
                    onCancelSubmit={handleCancelConfirmDialog}
                />
            </>
        );
    };

    if (!loadingInvoices && !hasMoreInvoices && invoices.length <= 0) {
        return <EmptyPage title="Nema računa!" />;
    }
    if (loadingInvoices && (!invoices || invoices.length <= 0)) {
        return (
            <div style={{ height: '60vh', width: '60vh', margin: 'auto' }}>
                <LoaderProgress />
            </div>
        );
    }
    return <ViewElement />;
}
