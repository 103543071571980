import { Button } from '@mui/material';
import React, { ReactElement, useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import {
    TrailerDto,
    TransportWarrantItemDto,
    VehicleDto,
    WorkingMachineDto,
    TransportWarrantDto,
    ShipmentDto,
} from '../General/AutoGeneratedAPI/clientApi';
import { GraphUserDto } from '../General/AutoGeneratedAPI/MSGraph/msGraphApi';
import { GraphClient } from '../General/AutoGeneratedAPI/MSGraph/MSGraphExtensions';
import { GranitClient } from '../General/AutoGeneratedAPI/Extension';
import './Report.css';
import { TWReportContent } from './TWReportContent';

export default function TransportWarrantReport(): ReactElement {
    const componentRef = useRef(null);
    const [transportWarrant, setTransportWarrant] = useState<TransportWarrantDto | undefined>(undefined);
    const [vehicle, setVehicle] = useState<VehicleDto | undefined>(undefined);
    const [trailers, setTrailers] = useState<TrailerDto[]>([]);
    const [workingMachines, setWorkingMachines] = useState<WorkingMachineDto[]>([]);
    const [graphDrivers, setGraphDrivers] = useState<GraphUserDto[]>([]);
    const [twItems, setTwItems] = useState<TransportWarrantItemDto[]>([]);
    const [shipments, setShipments] = useState<ShipmentDto[]>([]);

    const { twId } = useParams();

    useEffect(() => {
        GranitClient.getTransportWarrant(twId ? +twId : 0, true).then((twRes) => {
            const tw = twRes;
            setTransportWarrant(tw);
            GranitClient.getComposition(tw.compositionId ?? -1).then((res) => {
                GranitClient.getVehicle(res.mainVehicleId ?? -1).then((veh) => setVehicle(veh));
                res.trailersIds?.map((trId) =>
                    GranitClient.getVehicle(trId ?? -1).then((tr) =>
                        setTrailers((prev) => [...prev, tr as TrailerDto]),
                    ),
                );
                res.workingMachinesIds?.map((wmId) =>
                    GranitClient.getWorkingMachine(wmId ?? -1).then((wm) =>
                        setWorkingMachines((prev) => [...prev, wm]),
                    ),
                );
            });
            tw.driverJobPositionIds?.map((djpId) =>
                GranitClient.getDriverByJobPositionId(djpId ?? -1).then((dr) => {
                    GraphClient.getUserById(dr.azObjId ?? 'error').then((res) => {
                        if (graphDrivers.find((gd) => gd.id === res.id) === undefined) {
                            setGraphDrivers((prev) => [...prev, res]);
                        }
                    });
                }),
            );
            GranitClient.getTransportWarrantItems(tw.id ?? -1).then((res) => {
                setTwItems(res);
                res.map((twItem) => {
                    GranitClient.getShipment(twItem.shipmentId ?? 0, true).then((res) => {
                        if (res) {
                            setShipments((prev) => [...prev, res]);
                        }
                    });
                });
            });
        });
    }, [twId]);

    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = useCallback(() => {
        return (
            <Button variant="contained" color="primary" style={{ marginTop: 10 }}>
                Odštampaj
            </Button>
        );
    }, []);

    if (transportWarrant === undefined) {
        return <>Currently waiting for miracle...</>;
    }

    return (
        <div className="tw-report-container">
            <ReactToPrint content={reactToPrintContent} trigger={reactToPrintTrigger} />
            <div className="tw-report-scroll">
                <div>
                    <TWReportContent
                        ref={componentRef}
                        tw={transportWarrant ?? {}}
                        vehicle={
                            vehicle?.licensePlateNum
                                ? vehicle?.manufacturer + ' ' + vehicle?.model + ', ' + vehicle?.licensePlateNum
                                : vehicle?.manufacturer + ' ' + vehicle?.model
                        }
                        trailers={trailers}
                        workingMachines={workingMachines}
                        graphDrivers={graphDrivers}
                        twItems={twItems}
                        shipments={shipments}
                    />
                </div>
            </div>
        </div>
    );
}
