import { WorkingMachineDto } from '../../../AutoGeneratedAPI/clientApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithFetching } from '../../../Extensions/Exceptions';

interface IInitialStateType {
    officeMachines: WorkingMachineDto[] | null;
}

const initialState: IInitialStateType = {
    officeMachines: null,
};
export const fetchInitialOfficeMachines = createAsyncThunk(
    'officeMachines/fetchInitialOfficeMachines',
    async () => {
        const response = await GranitClient.getAllOfficeMachines();
        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingOMachines: WorkingMachineDto[] | null = thunkAPI.getState().officeMachines.officeMachines;

            return existingOMachines === null;
        },
    },
);

const officeMachinesSlice = createSlice({
    name: 'officeMachines',
    initialState,
    reducers: {
        addOfficeMachine(state, action) {
            state.officeMachines = [action.payload, ...(state.officeMachines ?? [])];
        },
        updateOfficeMachine: {
            reducer(state, action) {
                const { officeMachine, oldOfficeMachine } = action.payload;
                const officeMachines = state.officeMachines ?? [];
                const officeMachineIndex = officeMachines.findIndex((om) => {
                    return om.id === oldOfficeMachine.id;
                });
                state.officeMachines = [
                    ...officeMachines.slice(0, officeMachineIndex),
                    officeMachine,
                    ...officeMachines.slice(officeMachineIndex + 1),
                ];
            },
            prepare(officeMachine, oldOfficeMachine) {
                return {
                    payload: { officeMachine, oldOfficeMachine },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
        changeOfficeMachineProfileImage: {
            reducer(state, action) {
                const omIndex = state.officeMachines?.findIndex((om) => {
                    return om.id === action.payload.workingToolId;
                });
                if (state.officeMachines && omIndex !== undefined && omIndex >= 0) {
                    const om = state.officeMachines[omIndex];
                    om.documents = [action.payload.doc, ...(om.documents?.slice(1) ?? [])];
                    state.officeMachines = [
                        ...state.officeMachines.slice(0, omIndex),
                        om,
                        ...state.officeMachines.slice(omIndex + 1),
                    ];
                }
            },
            prepare(workingToolId, doc) {
                return {
                    payload: { workingToolId, doc },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInitialOfficeMachines.fulfilled, (state, action) => {
            state.officeMachines = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialOfficeMachines.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_OFFICEMACHINES);
        });
    },
});

export const { addOfficeMachine, updateOfficeMachine, changeOfficeMachineProfileImage } = officeMachinesSlice.actions;

export default officeMachinesSlice.reducer;
