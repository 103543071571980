import { EntitiesType } from '../../../Extensions/Entities';
import { createSlice } from '@reduxjs/toolkit';

interface ICurrentEntityReducerState {
    entity: EntitiesType | null;
}

const initialState: ICurrentEntityReducerState = {
    entity: null,
};

const currentEntitySlice = createSlice({
    name: 'currentEntity',
    initialState,
    reducers: {
        changeCurrentEntity(state, action) {
            state.entity = action.payload;
        },
    },
});

export const { changeCurrentEntity } = currentEntitySlice.actions;

export default currentEntitySlice.reducer;
