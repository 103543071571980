import { Fab, Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import TableChartIcon from '@mui/icons-material/TableChart';

type ToggleViewWindowProps = {
    toggleView: boolean;
    handleToggleView(): void;
};

export default function ToggleViewWindow({ toggleView, handleToggleView }: ToggleViewWindowProps): ReactElement {
    return (
        <>
            <Tooltip title={toggleView ? 'Kartice' : 'Tabela'} placement="right">
                <Fab
                    aria-label="search"
                    onClick={handleToggleView}
                    color="success"
                    style={{ position: 'absolute', right: 60, bottom: 170, zIndex: 6, height: 40, width: 40 }}
                >
                    {toggleView ? (
                        <DynamicFeedIcon style={{ fontSize: 20 }} />
                    ) : (
                        <TableChartIcon style={{ fontSize: 20 }} />
                    )}
                </Fab>
            </Tooltip>
        </>
    );
}
