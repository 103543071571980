import { Grid, Modal, Paper, Typography } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { EntityType, NotificationDto, VehicleDto } from '../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../AutoGeneratedAPI/Extension';
import { useAppDispatch } from '../hooks/hooks';
import { updateOldNotification } from '../Redux/Reducers/Notifications/NotificationsReducer';

type NotificationInfoProps = {
    open: boolean;
    closeWindow(): void;
    notification?: NotificationDto;
};

export default function NotificationInfo({ open, closeWindow, notification }: NotificationInfoProps): ReactElement {
    const [entityInfo, setEntityInfo] = useState<string>('');
    const [entity, setEntity] = useState<VehicleDto | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (notification && notification.entityType) {
            switch (notification.entityType) {
                case EntityType.Expense:
                    GranitClient.getVehicleExpenseByExpId(notification.entityId).then((resExp) => {
                        if (resExp && resExp.vehicleId) {
                            GranitClient.getVehicle(resExp.vehicleId).then((resVeh) => {
                                setEntityInfo(resVeh.manufacturer + ' ' + resVeh.model + ', ' + resVeh.licensePlateNum);
                                setEntity(resVeh);
                            });
                        }
                    });
                    break;
                case EntityType.Record:
                    GranitClient.getVehicleRecordByRecId(notification.entityId).then((resRec) => {
                        if (resRec && resRec.vehicleId) {
                            GranitClient.getVehicle(resRec.vehicleId).then((resVeh) => {
                                setEntityInfo(resVeh.manufacturer + ' ' + resVeh.model + ', ' + resVeh.licensePlateNum);
                                setEntity(resVeh);
                            });
                        }
                    });
                    break;
                case EntityType.Vehicle:
                    GranitClient.getVehicle(notification.entityId).then((resVeh) => {
                        setEntityInfo(resVeh.manufacturer + ' ' + resVeh.model + ', ' + resVeh.licensePlateNum);
                        setEntity(resVeh);
                    });
                    break;
                case EntityType.Employee:
                    setEntityInfo('Lični podsetnik');
                    setEntity(null);
                    break;
                default:
                    setEntityInfo('Nema podataka');
                    setEntity(null);
                    break;
            }
        }
    }, [notification]);

    useEffect(() => {
        if (open && notification && !notification.closed) {
            const newNote = new NotificationDto();
            Object.assign(newNote, notification);
            newNote.closed = true;

            GranitClient.updateNotification(newNote.id, newNote).then((res) => {
                dispatch(updateOldNotification(res));
            });
        }
    }, [open, notification]);

    const notificationInfoElement = () => {
        return (
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                        {notification?.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {entity !== null && notification !== undefined ? (
                        notification.entityType === EntityType.Expense ? (
                            <Link to={`profile/trucks/${entity.id}/expenses/${notification?.entityId}`} target="_blank">
                                <Typography variant="body2" component="p">
                                    {entityInfo}
                                </Typography>
                            </Link>
                        ) : notification.entityType === EntityType.Record ? (
                            <Link to={`profile/trucks/${entity.id}/records/${notification?.entityId}`} target="_blank">
                                <Typography variant="body2" component="p">
                                    {entityInfo}
                                </Typography>
                            </Link>
                        ) : notification.entityType === EntityType.Vehicle ? (
                            <Link to={`profile/trucks/${entity.id}/records`} target="_blank">
                                <Typography variant="body2" component="p">
                                    {entityInfo}
                                </Typography>
                            </Link>
                        ) : notification.entityType === EntityType.WorkingMachine ? (
                            <Link to={`profile/workingmachines/${entity.id}/records`} target="_blank">
                                <Typography variant="body2" component="p">
                                    {entityInfo}
                                </Typography>
                            </Link>
                        ) : (
                            <Link
                                to={`profile/trucks/${entity.id}/impdocuments/${notification?.entityId}`}
                                target="_blank"
                            >
                                <Typography variant="body2" component="p">
                                    {entityInfo}
                                </Typography>
                            </Link>
                        )
                    ) : (
                        <Typography variant="body2" component="p">
                            {entityInfo}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" component="p">
                        {notification?.description}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    return (
        <Modal
            className="modalStyle"
            open={open}
            aria-labelledby="add-vehicle-form"
            aria-describedby="adding a vehicle to a database through this form"
        >
            <main className="custom-form-main">
                <div className="custom-form-close" onClick={closeWindow}>
                    x
                </div>
                <Paper className="custom-form-paper">
                    <Typography component="h4" variant="h4" align="center" style={{ marginBottom: 50 }}>
                        Notifikacija
                    </Typography>
                    <div style={{ height: 'calc(80vh - 200px)', overflowX: 'hidden', overflowY: 'auto', padding: 20 }}>
                        {notificationInfoElement()}
                    </div>
                </Paper>
            </main>
        </Modal>
    );
}
