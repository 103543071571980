import { Grid, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { FormatDateWithDayNameText, GetStartOfTheWeek } from '../../Common/DateHandler';
import { TimelineViewType } from '../../General/Extensions/Extensions';
import { useAppSelector } from '../../General/hooks/hooks';
import { RootState } from '../../General/Redux/store';
import TimelineGridItem from './TimelineGridItem';
import { times } from './TimelineView';
import { addDays } from 'date-fns';

const shouldShowHours = (index: number): boolean => {
    return index % 2 == 0;
};

type TimelineViewTableProps = {
    setUpdateFormTW(twId: number): void;
    startingHours: number;
    endingHours: number;
    startingDate?: Date;
};

export default function TimelineViewTable({
    setUpdateFormTW,
    startingHours,
    endingHours,
    startingDate,
}: TimelineViewTableProps): ReactElement {
    const trucks = useAppSelector((state: RootState) => state.vehicles.trucks);
    const timelineViewType = useAppSelector((state: RootState) => state.calendarData.timelineViewType);

    return (
        <div
            style={{
                width: '100vw',
                height: 'calc(100vh - 60px - 67px - 80px)',
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
        >
            <div>
                <div style={{ width: 250, height: 30, float: 'left' }}></div>
                <div style={{ width: 'calc(100% - 250px)', height: 30, float: 'right' }}>
                    {timelineViewType === TimelineViewType.Week ? (
                        <Grid
                            key={-1}
                            container
                            columns={{ xs: 7 }}
                            style={{ width: 'calc(100vw - 250px)', height: 30 }}
                        >
                            {[0, 1, 2, 3, 4, 5, 6].map((i) => {
                                return (
                                    <Grid key={i} item xs={1} style={{ height: 30 }}>
                                        <p
                                            style={{
                                                marginTop: 6,
                                                fontSize: 16,
                                                textAlign: 'center',
                                                borderLeft: '1px solid black',
                                            }}
                                        >
                                            {FormatDateWithDayNameText(
                                                addDays(GetStartOfTheWeek(startingDate ?? new Date()), i),
                                            )}
                                        </p>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Grid
                            key={-1}
                            container
                            columns={{ xs: (endingHours - startingHours) * 6 }}
                            style={{ width: '100%', height: 30 }}
                        >
                            {times.map((time, index) => {
                                if (index >= startingHours && index < endingHours) {
                                    return (
                                        <Grid item xs={6} style={{ height: 30 }} key={index}>
                                            {shouldShowHours(index) && (
                                                <p style={{ marginTop: 15, fontSize: 13 }}>{time}</p>
                                            )}
                                        </Grid>
                                    );
                                }
                            })}
                        </Grid>
                    )}
                </div>
                {trucks
                    ?.filter((tr) => !tr.isDeleted)
                    .map((tr, index) => {
                        return (
                            <div key={index} style={{ width: '100vw' }}>
                                <div
                                    style={{
                                        height: 70,
                                        border: '1px solid lightgray',
                                        borderRight: 'none',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        paddingLeft: 15,
                                        backgroundColor: index % 2 ? '#0f467d' : '#051628',
                                        color: 'white',
                                        width: 250,
                                        float: 'left',
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        component="h6"
                                        style={{
                                            width: '100%',
                                            height: 25,
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {tr.manufacturer} {tr.model}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        {tr.licensePlateNum}
                                    </Typography>
                                </div>
                                {timelineViewType === TimelineViewType.Week ? (
                                    <div
                                        style={{
                                            width: 'calc(100vw - 250px)',
                                            height: 70,
                                            float: 'right',
                                        }}
                                    >
                                        <Grid container columns={7} style={{ width: '100%' }}>
                                            {[0, 1, 2, 3, 4, 5, 6].map((i) => {
                                                return (
                                                    <Grid key={i} item xs={1}>
                                                        <div style={{ width: '100%' }}>
                                                            <TimelineGridItem
                                                                startingHours={startingHours}
                                                                endingHours={endingHours}
                                                                truck={tr}
                                                                setUpdateFormTW={setUpdateFormTW}
                                                                dayIndex={i}
                                                            />
                                                        </div>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </div>
                                ) : (
                                    <div style={{ width: 'calc(100vw - 250px)', height: 70, float: 'right' }}>
                                        <TimelineGridItem
                                            startingHours={startingHours}
                                            endingHours={endingHours}
                                            truck={tr}
                                            setUpdateFormTW={setUpdateFormTW}
                                            dayIndex={0}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
