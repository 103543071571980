/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAction, createReducer, createSlice } from '@reduxjs/toolkit';
import { AuthenticatedDto, EmployeeInfoDto, UserGroupDto } from '../../../AutoGeneratedAPI/clientApi';
import { setToken } from '../../../AutoGeneratedAPI/Extension';
import { GraphUserDto } from '../../../AutoGeneratedAPI/MSGraph/msGraphApi';
import {
    createUserGroup,
    getEmployeeData,
    getEmployeeDataExtended,
    getUserGroup,
    loginEmployee,
    logoutEmployee,
} from '../../Actions/Login/EmployeeActions';

export enum LoginStatus {
    InProgress = 'InProgress',
    Succeeded = 'Succeeded',
    Failed = 'Failed',
}

export interface LoginData {
    token?: string | null;
    expirationDate?: Date | null;
}

export interface LoggedEmployeeState {
    basicEmployee: GraphUserDto | null;
    employee: EmployeeInfoDto | null;
    token: string | null;
    expirationDate: Date | null;

    userGroup?: UserGroupDto | null;

    logginStatus: LoginStatus | null;

    error: string | null;
    googleApiKey: string | null;
}

const initialState: LoggedEmployeeState = {
    basicEmployee: null,
    employee: null,
    token: null,
    expirationDate: null,
    userGroup: null,
    logginStatus: null,
    error: null,
    googleApiKey: null,
};

export const setGoogleApiKey = createAction<string | undefined>('logginData/setGoogleApiKey');
export const addLoginData = createAction<LoginData | null>('logginData/addLoginData');
export const setRoles = createAction<string[] | undefined>('logginData/setRoles');

export const loggedEmployeeReducer = createReducer<LoggedEmployeeState>(
    {
        basicEmployee: null,
        employee: null,
        error: null,
        token: null,
        expirationDate: null,
        userGroup: null,
        logginStatus: null,
        googleApiKey: null,
    },
    (builder) => {
        builder
            .addCase(getEmployeeDataExtended.fulfilled, (state: LoggedEmployeeState, action: any) => {
                const info: EmployeeInfoDto = action.payload;

                state.employee = info;
            })
            .addCase(getEmployeeData.fulfilled, (state: LoggedEmployeeState, action: any) => {
                const info: GraphUserDto = action.payload;

                state.basicEmployee = info;
            })
            .addCase(loginEmployee.fulfilled, (state: LoggedEmployeeState, action: any) => {
                const logged: AuthenticatedDto = action.payload;

                state.employee = logged.employeeInfo;
                state.expirationDate = logged.expirationDate;
                state.token = logged.token ?? null;
                state.logginStatus = LoginStatus.Succeeded;

                setToken(state.token ?? '');
            })
            .addCase(loginEmployee.rejected, (state: LoggedEmployeeState, action: any) => {
                state.logginStatus = LoginStatus.Failed;
            })
            .addCase(loginEmployee.pending, (state: LoggedEmployeeState, action: any) => {
                state.logginStatus = LoginStatus.InProgress;
            })
            .addCase(logoutEmployee.fulfilled, (state: LoggedEmployeeState, action: any) => {
                state.employee = null;
                state.expirationDate = null;
                state.token = null;
                state.logginStatus = null;

                setToken(state.token ?? '');
            })
            .addCase(getUserGroup.fulfilled, (state: LoggedEmployeeState, action: any) => {
                state.userGroup = action.payload ?? undefined;
            })
            .addCase(getUserGroup.rejected, (state: LoggedEmployeeState, action: any) => {
                state.userGroup = undefined;
            })
            .addCase(createUserGroup.fulfilled, (state: LoggedEmployeeState, action: any) => {
                state.userGroup = action.payload ?? undefined;
            })
            .addCase(createUserGroup.rejected, (state: LoggedEmployeeState, action: any) => {
                state.userGroup = undefined;
            })
            .addCase(setGoogleApiKey, (state, action) => {
                state.googleApiKey = action.payload ?? null;
            })
            .addCase(addLoginData, (state, action) => {
                if (action.payload) {
                    const { token, expirationDate } = action.payload;
                    state.token = token ?? null;
                    state.expirationDate = expirationDate ?? null;
                }
            })
            .addCase(setRoles, (state, action) => {
                if (state.employee) {
                    state.employee = { ...state.employee, roles: action.payload };
                }
            });
    },
);
