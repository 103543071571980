import { TravelWarrantDto } from '../../../AutoGeneratedAPI/clientApi';
import { createSlice } from '@reduxjs/toolkit';

interface IInitialStateType {
    travelWarrants: TravelWarrantDto[] | null;
}

const initialState: IInitialStateType = {
    travelWarrants: null,
};

const travelWarrantsSlice = createSlice({
    name: 'travelWarrants',
    initialState,
    reducers: {
        addTravelWarrants(state, action) {
            state.travelWarrants = action.payload ?? [];
        },
        addTravelWarrant(state, action) {
            state.travelWarrants = (state.travelWarrants ?? []).concat(action.payload);
        },
        updateTravelWarrant: {
            reducer(state, action) {
                const { tw, oldTw } = action.payload;
                const tws = state.travelWarrants ?? [];
                const twIndex = tws.findIndex((tw) => {
                    return tw.id === oldTw.id;
                });
                state.travelWarrants = [...tws.slice(0, twIndex), tw, ...tws.slice(twIndex + 1)];
            },
            prepare(tw, oldTw) {
                return {
                    payload: { tw, oldTw },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
    },
});

export const { addTravelWarrants, addTravelWarrant, updateTravelWarrant } = travelWarrantsSlice.actions;

export default travelWarrantsSlice.reducer;
