import { Person, ViewType } from '@microsoft/mgt-react';
import { Button, Fab, Modal, Tooltip, Typography } from '@mui/material';
import React, { ReactElement, memo, useEffect, useMemo, useRef, useState } from 'react';
import { formatNumberOnTwoDigits, FormatTimeText, getTimeDifference } from '../../../Common/DateHandler';
import {
    TruckDto,
    TrailerDto,
    TransportWarrantDto,
    ShipmentStatus,
    RequestOriginApp,
} from '../../AutoGeneratedAPI/clientApi';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { RootState } from '../../Redux/store';
import TransportWarrantSideDetailsElementFinishComponent from './TransportWarrantSideDetailsElementFinishComponent';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link } from 'react-router-dom';
import { isRoleCEO, isRoleTransOp } from '../../Extensions/Selectors/Selectors';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import { deleteTransportWarrantFromRedux, updateTransportWarrant } from '../../Redux/Reducers/Warrants/CalendarReducer';
import PrintIcon from '@mui/icons-material/Print';
import { addShipmentToBeginning } from '../../Redux/Reducers/Shipments/ShipmentReducer';
import { CalendarTWSideDetailsBtnsContainer } from './CalendarTransportWarrantSideDetailsStyled';
import { setTransportWarrant } from '../../Redux/Reducers/Warrants/TransportWarrantFormReducer';
import { addMinutes } from 'date-fns';
import DeleteTransportWarrantModal from './DeleteTransportWarrantModal';
import TransportWarrantSideDetailsElementTwOccupancy from './TransportWarrantSideDetailsElementTwOccupancy';

type TransportWarrantSideDetailsElementProps = {
    closeTWSideDetails(): void;
    openTwForm(): void;
    enableFinishTW: boolean;
};

function TransportWarrantSideDetailsElement({
    closeTWSideDetails,
    openTwForm,
    enableFinishTW,
}: TransportWarrantSideDetailsElementProps): ReactElement {
    const trucks = useAppSelector((state: RootState) => state.vehicles.trucks);
    const trailers = useAppSelector((state: RootState) => state.vehicles.trailers);
    const composition = useAppSelector((state: RootState) => state.twFormData.composition);

    const transportWarrant = useAppSelector((state: RootState) => state.twFormData.transportWarrant);

    const currShipments = useAppSelector((state: RootState) => state.twFormData.currentTwInitialShipments);
    const driversAzureObjIds = useAppSelector((state: RootState) => state.twFormData.driversAzureObjIds);

    const stopsDurations = useAppSelector((state: RootState) => state.twFormData.stopsDurations);
    const stopsDistances = useAppSelector((state: RootState) => state.twFormData.stopsDistances);

    const [truck, setTruck] = useState<TruckDto>(new TruckDto());
    const [currentTrailers, setCurrentTrailers] = useState<TrailerDto[]>([]);

    const [openDeleteTWModal, setOpenDeleteTWModal] = useState<boolean>(false);
    const [toggleFinishComponent, setToggleFinishComponent] = useState<boolean>(false);

    const isCEO = useAppSelector(isRoleCEO);
    const isTransportOperater = useAppSelector(isRoleTransOp);

    const dispatch = useAppDispatch();

    const bottomRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [toggleFinishComponent]);

    useEffect(() => {
        if (trucks && composition && composition.mainVehicleId) {
            const trId = trucks?.findIndex((tr) => tr.id === composition.mainVehicleId);
            if (trId >= 0) {
                setTruck(trucks[trId]);
            }
        }
    }, [trucks, composition]);

    useEffect(() => {
        if (trailers && composition && composition.trailersIds && composition.trailersIds.length > 0) {
            composition.trailersIds.map((trId) => {
                const trIndex = trailers.findIndex((tr) => tr.id === trId);
                if (trIndex >= 0) {
                    setCurrentTrailers((prevState) =>
                        prevState.includes(trailers[trIndex]) ? [...prevState] : [...prevState, trailers[trIndex]],
                    );
                }
            });
        } else {
            setCurrentTrailers([]);
        }
    }, [trailers, composition]);

    const handleCloseDeleteTWModal = () => {
        setOpenDeleteTWModal(false);
    };

    const handleOpenDeleteTWModal = () => {
        setOpenDeleteTWModal(true);
    };

    const openFinishComponent = () => {
        setToggleFinishComponent(true);
    };

    const closeFinishComponent = () => {
        setToggleFinishComponent(false);
    };

    const unfinishTw = (tw: TransportWarrantDto) => {
        const newTw: TransportWarrantDto = new TransportWarrantDto();
        Object.assign(newTw, tw);

        newTw.isTwFinished = false;

        GranitClient.updateTransportWarrant(tw.id, newTw).then(
            (res) => {
                dispatch(updateTransportWarrant(res));
                dispatch(setTransportWarrant(res));
            },
            () => {
                alert('Doslo je do greske pri otkazivanju putnog naloga');
            },
        );
    };

    const deleteTw = () => {
        const tw = new TransportWarrantDto();
        Object.assign(tw, transportWarrant);

        GranitClient.deleteTransportWarrant(tw.id, tw)
            .then(
                () => {
                    dispatch(deleteTransportWarrantFromRedux(tw.id));
                    if (tw.transportWarrantItems !== undefined && tw.transportWarrantItems.length > 0) {
                        tw.transportWarrantItems.map((twi) => {
                            const newShip = twi.shipment;
                            newShip.shipmentStatus = ShipmentStatus.Pending;

                            GranitClient.updateShipment(newShip.id ?? 0, newShip).then((ship) => {
                                dispatch(addShipmentToBeginning(ship));
                            });
                        });
                    }
                },
                () => {
                    alert('Doslo je do greske pri brisanju putnog naloga');
                },
            )
            .then(() => closeTWSideDetails());
    };

    const totalDurationInMinutes = Math.floor(
        stopsDurations.reduce((acc, d) => {
            return acc + d.value;
        }, 0) / 60,
    );

    const durationHours = Math.floor(totalDurationInMinutes / 60);

    const durationMinutes = Math.floor(totalDurationInMinutes % 60);

    const loadingDurationInMinutes = useMemo(() => {
        return currShipments.reduce((acc, sh) => {
            if (sh.requestOriginApp === RequestOriginApp.Transport) {
                return acc;
            }
            const diffTimeSpan = getTimeDifference(sh.startingStop.endingTime, sh.startingStop.startingTime);
            const loadingTimeInMinutes = diffTimeSpan.hours * 60 + diffTimeSpan.minutes;

            return acc + loadingTimeInMinutes;
        }, 0);
    }, [currShipments]);

    const totalUnloadingTimeInMinutes = useMemo(() => {
        return currShipments.reduce((acc, sh) => {
            const diffTimeSpan = getTimeDifference(sh.endingStop.endingTime, sh.endingStop.startingTime);
            let unloadingTimeInMinutes = diffTimeSpan.hours * 60 + diffTimeSpan.minutes;

            if (sh.requestOriginApp === RequestOriginApp.Transport) {
                const diffTimeSpanStart = getTimeDifference(sh.startingStop.endingTime, sh.startingStop.startingTime);
                unloadingTimeInMinutes += diffTimeSpanStart.hours * 60 + diffTimeSpanStart.minutes;
            }
            return acc + unloadingTimeInMinutes;
        }, 0);
    }, [currShipments]);

    const twLoadingStartingPoint = useMemo(() => {
        return addMinutes(transportWarrant.actStartingTime, 0 - loadingDurationInMinutes);
    }, [loadingDurationInMinutes, transportWarrant.actStartingTime]);

    const twTransportEndingPoint = useMemo(() => {
        return addMinutes(transportWarrant.actStartingTime, totalDurationInMinutes + totalUnloadingTimeInMinutes);
    }, [totalDurationInMinutes, totalUnloadingTimeInMinutes, transportWarrant.actStartingTime]);

    return (
        <div
            style={{
                flexGrow: 1,
                minWidth: transportWarrant.actEndingTime < new Date() && !transportWarrant.isTwFinished ? 350 : 310,
                height: 'calc(100vh - 290px)',
                maxWidth: 350,
                overflowY: 'auto',
                paddingRight: 10,
                position: 'relative',
            }}
        >
            <div style={{ height: 24 }}>
                <Typography gutterBottom variant="h6" component="p" style={{ display: 'inline-block', fontSize: 18 }}>
                    {truck.manufacturer
                        ? truck.model
                            ? truck.manufacturer + ' ' + truck.model
                            : truck.manufacturer
                        : 'Nema informacija'}{' '}
                    {truck.manufacturer
                        ? truck.licensePlateNum
                            ? ', ' + truck.licensePlateNum
                            : ''
                        : truck.licensePlateNum ?? ''}
                </Typography>
            </div>
            <div
                style={{
                    whiteSpace: 'pre-wrap',
                }}
            >
                {currentTrailers.map((tr, index) => {
                    return (
                        <Typography
                            key={tr.id}
                            gutterBottom
                            variant="body1"
                            component="p"
                            color="gray"
                            style={{
                                display: 'inline-block',
                                fontSize: 14,
                                height: 16,
                            }}
                        >
                            {index === 0 && '('}
                            {tr.manufacturer
                                ? tr.model
                                    ? tr.manufacturer + ' ' + tr.model
                                    : tr.manufacturer
                                : 'Nema informacija'}{' '}
                            {tr.manufacturer
                                ? tr.licensePlateNum
                                    ? ', ' + tr.licensePlateNum
                                    : ''
                                : tr.licensePlateNum ?? ''}
                            {index === currentTrailers.length - 1 ? ')' : '; '}
                        </Typography>
                    );
                })}
            </div>
            <hr style={{ width: '100%', borderColor: '#3277d5' }} />
            {driversAzureObjIds.map((daoi) => {
                return (
                    <div key={daoi} style={{ padding: 10, display: 'inline-block' }}>
                        <Person userId={daoi} view={ViewType.threelines} />
                    </div>
                );
            })}
            <hr style={{ width: '100%', borderColor: '#3277d5' }} />
            <TransportWarrantSideDetailsElementTwOccupancy truck={truck} trailers={currentTrailers} />
            <div style={{ marginTop: 25 }}>
                <Typography gutterBottom variant="body1" component="p">
                    Predviđeno trajanje putnog naloga
                </Typography>
            </div>
            <hr style={{ width: '100%', borderColor: '#3277d5' }} />
            <div>
                <Tooltip title="Vreme predviđeno za utovar">
                    <Typography
                        variant="h6"
                        component="p"
                        style={{
                            display: 'inline-block',
                            float: 'left',
                            margin: 0,
                            padding: 0,
                            height: 30,
                            width: 30,
                            marginRight: 10,
                        }}
                    >
                        <InfoOutlinedIcon style={{ fontSize: 30 }} />
                    </Typography>
                </Tooltip>
                <Typography gutterBottom variant="body1" component="p" style={{ display: 'inline-block' }}>
                    utovar:
                </Typography>{' '}
                <Typography gutterBottom variant="h6" component="p" style={{ display: 'inline-block', fontSize: 18 }}>
                    {FormatTimeText(new Date(twLoadingStartingPoint))} -{' '}
                    {FormatTimeText(new Date(transportWarrant.actStartingTime))}
                </Typography>
            </div>
            {transportWarrant.isTwFinished && (
                <div>
                    <Tooltip title="Pravo trajanje transporta">
                        <Typography
                            variant="h6"
                            component="p"
                            style={{
                                display: 'inline-block',
                                float: 'left',
                                margin: 0,
                                padding: 0,
                                height: 30,
                                width: 30,
                                marginRight: 10,
                            }}
                        >
                            <InfoOutlinedIcon style={{ fontSize: 30 }} />
                        </Typography>
                    </Tooltip>
                    <Typography gutterBottom variant="body1" component="p" style={{ display: 'inline-block' }}>
                        transport:
                    </Typography>{' '}
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="p"
                        style={{ display: 'inline-block', fontSize: 18 }}
                    >
                        {FormatTimeText(new Date(transportWarrant.actStartingTime))} -{' '}
                        {FormatTimeText(new Date(transportWarrant.actEndingTime))}
                    </Typography>
                </div>
            )}
            <div>
                <Tooltip title="Vreme predviđeno za transport">
                    <Typography
                        variant="h6"
                        component="p"
                        style={{
                            display: 'inline-block',
                            float: 'left',
                            margin: 0,
                            padding: 0,
                            height: 30,
                            width: 30,
                            marginRight: 10,
                        }}
                    >
                        <InfoOutlinedIcon style={{ fontSize: 30 }} />
                    </Typography>
                </Tooltip>
                <Typography gutterBottom variant="body1" component="p" style={{ display: 'inline-block' }}>
                    predv. transport:
                </Typography>{' '}
                <Typography gutterBottom variant="h6" component="p" style={{ display: 'inline-block', fontSize: 18 }}>
                    {FormatTimeText(new Date(transportWarrant.actStartingTime))} -{' '}
                    {FormatTimeText(
                        transportWarrant.isTwFinished
                            ? new Date(transportWarrant.actStartingTime)
                            : twTransportEndingPoint,
                    )}
                </Typography>
            </div>
            <div>
                <Tooltip title="Procenjeno vreme (i kilometraža) provedeno u voznji na osnovu mape i rasporeda isporuka na putnom nalogu">
                    <Typography
                        variant="h6"
                        component="p"
                        style={{
                            display: 'inline-block',
                            float: 'left',
                            margin: 0,
                            padding: 0,
                            height: 30,
                            width: 30,
                            marginRight: 10,
                        }}
                    >
                        <InfoOutlinedIcon style={{ fontSize: 30 }} />
                    </Typography>
                </Tooltip>
                <Typography gutterBottom variant="body1" component="p" style={{ display: 'inline-block' }}>
                    na osnovu mape:
                </Typography>{' '}
                <Typography gutterBottom variant="h6" component="p" style={{ display: 'inline-block', fontSize: 18 }}>
                    {durationHours > 0
                        ? formatNumberOnTwoDigits(durationHours) + ':' + formatNumberOnTwoDigits(durationMinutes)
                        : durationMinutes + 'min'}
                </Typography>{' '}
                <Typography
                    gutterBottom
                    variant="h6"
                    component="p"
                    style={{ display: 'inline-block', color: 'gray', fontSize: 18 }}
                >
                    (
                    {Math.floor(
                        stopsDistances.reduce((acc, d) => {
                            return acc + d.value;
                        }, 0) / 100,
                    ) / 10}
                    km)
                </Typography>
            </div>
            <div>
                <Tooltip title="Ukupno vreme predviđeno za istovar">
                    <Typography
                        variant="h6"
                        component="p"
                        style={{
                            display: 'inline-block',
                            float: 'left',
                            margin: 0,
                            padding: 0,
                            height: 30,
                            width: 30,
                            marginRight: 10,
                        }}
                    >
                        <InfoOutlinedIcon style={{ fontSize: 30 }} />
                    </Typography>
                </Tooltip>
                <Typography gutterBottom variant="body1" component="p" style={{ display: 'inline-block' }}>
                    istovar:
                </Typography>{' '}
                <Typography gutterBottom variant="h6" component="p" style={{ display: 'inline-block', fontSize: 18 }}>
                    {Math.floor(totalUnloadingTimeInMinutes / 60) > 0
                        ? formatNumberOnTwoDigits(Math.floor(totalUnloadingTimeInMinutes / 60)) +
                          ':' +
                          formatNumberOnTwoDigits(totalUnloadingTimeInMinutes % 60)
                        : (totalUnloadingTimeInMinutes % 60) + 'min'}
                </Typography>
            </div>
            {transportWarrant.isTwFinished && (
                <>
                    <div style={{ marginTop: 25 }}>
                        <Typography gutterBottom variant="body1" component="p">
                            Kilometraža transporta
                        </Typography>
                    </div>
                    <hr style={{ width: '100%', borderColor: '#3277d5' }} />
                    <div>
                        <Typography gutterBottom variant="h6" component="p">
                            {transportWarrant.startingKm ? transportWarrant.startingKm + 'km' : 'nema informacija'} -{' '}
                            {transportWarrant.endingKm ? transportWarrant.endingKm + 'km' : 'nema informacija'}
                        </Typography>
                    </div>
                </>
            )}

            {toggleFinishComponent && (
                <div ref={bottomRef}>
                    <TransportWarrantSideDetailsElementFinishComponent
                        closeFinishComponent={closeFinishComponent}
                        enableFinishTw={enableFinishTW}
                    />
                </div>
            )}
            {!transportWarrant.isTwFinished && (
                <CalendarTWSideDetailsBtnsContainer $width={transportWarrant.actEndingTime < new Date() ? 350 : 310}>
                    <>
                        <Link
                            to={'/tw_print/' + (transportWarrant.id ? transportWarrant.id.toString() : (0).toString())}
                            target="_blank"
                        >
                            <Tooltip title="Odštampaj">
                                <Fab color="primary" size="small">
                                    <PrintIcon />
                                </Fab>
                            </Tooltip>
                        </Link>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleOpenDeleteTWModal}
                            style={{ minWidth: 80 }}
                        >
                            Obriši
                        </Button>
                        <Button variant="outlined" color="primary" onClick={openTwForm} style={{ minWidth: 80 }}>
                            Izmeni
                        </Button>
                        {transportWarrant.actEndingTime < new Date() && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={openFinishComponent}
                                style={{ minWidth: 80 }}
                            >
                                Završi
                            </Button>
                        )}
                    </>
                </CalendarTWSideDetailsBtnsContainer>
            )}
            {transportWarrant.isTwFinished && (isCEO || isTransportOperater) && (
                <CalendarTWSideDetailsBtnsContainer $width={310}>
                    <Link
                        to={'/tw_print/' + (transportWarrant.id ? transportWarrant.id.toString() : (0).toString())}
                        target="_blank"
                    >
                        <Tooltip title="Odštampaj">
                            <Fab color="primary" size="small">
                                <PrintIcon />
                            </Fab>
                        </Tooltip>
                    </Link>
                    <Button variant="outlined" color="error" onClick={() => unfinishTw(transportWarrant)}>
                        Otkazi putni nalog
                    </Button>
                </CalendarTWSideDetailsBtnsContainer>
            )}
            <Modal
                className="modalStyle"
                open={openDeleteTWModal}
                aria-labelledby="delete-transport-warrant-module"
                aria-describedby="window to make sure that you want to delete transport warrant"
            >
                <div>
                    <DeleteTransportWarrantModal close={handleCloseDeleteTWModal} deleteTw={deleteTw} />
                </div>
            </Modal>
        </div>
    );
}

export default memo(TransportWarrantSideDetailsElement);
