import {
    IClientRecordDto,
    IEmployeeRecordDto,
    IVehicleRecordDto,
    IWorkingToolRecordDto,
} from '../../AutoGeneratedAPI/clientApi';

export const checkIfDataIsChanged = (
    currentRecord?: IWorkingToolRecordDto | IVehicleRecordDto | IEmployeeRecordDto | IClientRecordDto,
    oldRecord?: IWorkingToolRecordDto | IVehicleRecordDto | IEmployeeRecordDto | IClientRecordDto,
    selectedFiles?: File[],
): boolean => {
    if (oldRecord !== undefined) {
        return ((currentRecord?.title === oldRecord.title && currentRecord?.description === oldRecord.description) ||
            (currentRecord?.title === '' && currentRecord?.description === '')) &&
            (selectedFiles === undefined || selectedFiles.length < 1) &&
            currentRecord?.validFrom === oldRecord?.validFrom
            ? false
            : true;
    }
    return currentRecord?.title === '' &&
        currentRecord?.description === '' &&
        (selectedFiles === undefined || selectedFiles.length < 1)
        ? false
        : true;
};
