import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';
import user_logo from '../../General/assets/img/user_logo.png';
import '../../General/Catalogue/Catalogue.css';
import ImageComponent from '../../Common/CommonComponents/ImageComponent';
import { MgtTemplateProps } from '@microsoft/mgt-react';

export default function GraphDriversCardContainer(props: MgtTemplateProps): ReactElement {
    const { person, personImage } = props.dataContext;

    return (
        <Card className={'card'} style={{ width: 180, height: 180 }}>
            <CardActionArea>
                <ImageComponent src={personImage ?? user_logo} placeholder={''} isCalendar={false} />
                <CardContent>
                    <Typography gutterBottom variant={'subtitle1'} component={'p'} className="catalogue-card-text">
                        {person.displayName}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
