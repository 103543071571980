import React, { ReactElement, useEffect } from 'react';
import './App.css';
import '../Common/CommonComponents/Common.css';
import { LoaderProgress } from '../Common/CommonComponents/Loader/LoaderProgress';
import { RootState } from '../General/Redux/store';
import { LoginStatus } from '../General/Redux/Reducers/Login/EmployeeReducer';
import AppLayout from './Layouts/AppLayout';
import { BrowserRouter as Router } from 'react-router-dom';
import { GranitClient } from '../General/AutoGeneratedAPI/Extension';
import { useLocalStorage } from '../General/hooks/useLocalStorageHook';
import { useAppSelector } from '../General/hooks/hooks';

export default function App(): ReactElement {
    const userInfo = useAppSelector((state: RootState) => state.loggedEmployee.employee?.employee);
    const token = useAppSelector((state: RootState) => state.loggedEmployee.token);
    const expDate = useAppSelector((state: RootState) => state.loggedEmployee.expirationDate);
    const loginStatus = useAppSelector((state: RootState) => state.loggedEmployee.logginStatus);

    const [customers, setCustomers] = useLocalStorage<string[]>('shipmentCustomers', []);

    useEffect(() => {
        if (customers.length <= 0) {
            GranitClient.getAllShipmentCustomers().then((res) => setCustomers(res));
        }
    }, []);

    if (
        !(
            userInfo &&
            localStorage.token &&
            localStorage.expirationDate &&
            token &&
            expDate &&
            new Date(expDate) > new Date() &&
            loginStatus === LoginStatus.Succeeded
        )
    ) {
        return (
            <div style={{ height: 600, width: 600, margin: 'auto' }}>
                <LoaderProgress />
            </div>
        );
    }

    return (
        <Router>
            <AppLayout />
        </Router>
    );
}
