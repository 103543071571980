import { Menu, MenuItem, Typography } from '@mui/material';
import React, { ReactElement, useState, MouseEvent } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import styled from 'styled-components';
import { ShipmentDto } from '../../../AutoGeneratedAPI/clientApi';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { RootState } from '../../../Redux/store';
import {
    addUnselectedShipmentForTw,
    removeInitialShipmentForTw,
    removeInitialStopForTw,
    removeInitialStopFromArrangement,
} from '../../../Redux/Reducers/Warrants/TransportWarrantFormReducer';

const DNDTaskContainer = styled.div``;

type DNDTaskProps = {
    shipment: ShipmentDto;
    isStartingStop: boolean;
    index: number;
    openShipmentSideDetails(shipment?: ShipmentDto): void;
    isTwFinished: boolean;
};

export default function DNDTask({
    shipment,
    isStartingStop,
    openShipmentSideDetails,
    index,
    isTwFinished,
}: DNDTaskProps): ReactElement {
    const currShipments = useAppSelector((state: RootState) => state.twFormData.currentTwInitialShipments);
    const underbarShipments = useAppSelector((state: RootState) => state.shipments.shipments);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const dispatch = useAppDispatch();

    const removeShipment = (shipDto?: ShipmentDto): void => {
        if (shipDto) {
            const shipId = currShipments.findIndex((ship) => {
                return ship.id === shipDto.id;
            });

            if (shipId > -1) {
                const currShip = currShipments[shipId];

                dispatch(removeInitialShipmentForTw(currShip.id));
                dispatch(removeInitialStopForTw(currShip.startingStopId));
                dispatch(removeInitialStopForTw(currShip.endingStopId));

                if (underbarShipments?.findIndex((us) => us.id === currShip.id) === -1) {
                    dispatch(addUnselectedShipmentForTw(currShip));
                }

                dispatch(removeInitialStopFromArrangement(currShip.startingStopId));
                dispatch(removeInitialStopFromArrangement(currShip.endingStopId));
            }
        }
    };

    const handleClickAnchor = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAnchor = () => {
        setAnchorEl(null);
    };

    const DNDTaskMenu = () => {
        return (
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseAnchor}
                style={{ width: 300, height: 300 }}
            >
                <MenuItem
                    key={'Open shipment side details' + shipment?.id}
                    style={{ whiteSpace: 'normal' }}
                    onClick={() => {
                        openShipmentSideDetails(shipment);
                        handleCloseAnchor();
                    }}
                >
                    <div className="event-menu-item-container">Detalji isporuke</div>
                </MenuItem>

                {!isTwFinished && (
                    <MenuItem
                        key={'Remove shipment' + shipment?.id}
                        style={{ whiteSpace: 'normal' }}
                        onClick={() => {
                            removeShipment(shipment);
                            handleCloseAnchor();
                        }}
                    >
                        <div className="event-menu-item-container">Istovari isporuku</div>
                    </MenuItem>
                )}
            </Menu>
        );
    };

    return (
        <>
            <Draggable
                draggableId={isStartingStop ? shipment.startingStopId.toString() : shipment.endingStopId.toString()}
                index={index}
                isDragDisabled={isTwFinished}
            >
                {(provided) => (
                    <DNDTaskContainer
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                    >
                        <div
                            style={{
                                backgroundColor: 'white',
                                padding: 5,
                                cursor: isTwFinished ? 'pointer' : '',
                            }}
                            onClick={handleClickAnchor}
                        >
                            <Typography
                                gutterBottom
                                variant="body2"
                                component="p"
                                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                            >
                                {shipment?.title} ({shipment?.data?.clientName})
                            </Typography>
                            <Typography
                                variant="body2"
                                component="p"
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    color: 'gray',
                                    fontSize: 10,
                                }}
                            >
                                {shipment?.data?.invoiceCode} ({isStartingStop ? 'Utovar' : 'Istovar'})
                            </Typography>
                        </div>
                    </DNDTaskContainer>
                )}
            </Draggable>
            {DNDTaskMenu()}
        </>
    );
}
