import * as React from 'react';
import { ReactElement } from 'react';
import Carousel from 'react-material-ui-carousel';
import './Carousel.css';
import { FileIcon } from 'react-file-icon';
import { getBaseServerLink } from '../../AutoGeneratedAPI/Extension';

type ImageCarouselProps = {
    imageIndex: number;
    imageLinks: string[];
};

type ImageCarouselItemProps = {
    image: string;
};

export function ImageCarousel({ imageIndex, imageLinks }: ImageCarouselProps): ReactElement {
    const Item = ({ image }: ImageCarouselItemProps) => {
        return (
            <div className="carousel-container">
                <div className="carousel-img">
                    {image.endsWith('.jpg') || image.endsWith('.png') ? (
                        <img src={getBaseServerLink(image)} alt="SLIKA" />
                    ) : (
                        <div className="file-name-link">
                            <a href={image} target="_blank" rel="noreferrer">
                                <span className="file-name-carousel">
                                    <p style={{ height: 'auto' }}>{image.slice(image.lastIndexOf('/') + 1)}</p>
                                </span>
                                <FileIcon
                                    extension={image.slice(image.lastIndexOf('.'))}
                                    labelColor={
                                        image.slice(image.lastIndexOf('.')) === '.pdf'
                                            ? 'rgb(221, 68, 41)'
                                            : image.endsWith('.docx')
                                            ? 'rgb(0, 103, 199)'
                                            : image.endsWith('.xlsx') ||
                                              image.endsWith('.xlsm') ||
                                              image.endsWith('.xls')
                                            ? 'rgb(9, 133, 75)'
                                            : 'default'
                                    }
                                />
                            </a>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="carousel">
            <Carousel autoPlay={false} navButtonsAlwaysVisible={true} fullHeightHover={true} index={imageIndex}>
                {imageLinks.map((img, i) => (
                    <Item key={i} image={img} />
                ))}
            </Carousel>
        </div>
    );
}
