/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Redux/store';
import TransportWarrant from '../../../../TransportWarrant/TransportWarrant';
import EmptyPage from '../../../../EmptyPage';
import { TWSearchTokenModel } from '../../../../Extensions/models/RecordSearchTokenModel';
import useTWSearch from '../../../../Extensions/Hooks/RecordsHooks/TransportWarrantsSearchingHook';
import { createTheme, Grid, ThemeProvider } from '@mui/material';
import { LoaderProgress } from '../../../../../Common/CommonComponents/Loader/LoaderProgress';

type TransportWarrantFeedElementProps = {
    query: TWSearchTokenModel;
    setLastTwId(twId: number): void;
};

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1300,
            xl: 1800,
        },
    },
});

export default function TransportWarrantFeedElement({
    query,
    setLastTwId,
}: TransportWarrantFeedElementProps): ReactElement {
    const entity = useSelector((state: RootState) => state.currentEntity.entity);

    const { hasMore, loading, error, transportWarrants } = useTWSearch(entity?.id ?? 0, true, query);

    const observer = useRef<IntersectionObserver>();

    const lastRecordRef = useCallback(
        (node: any) => {
            if (loading) {
                return;
            }

            if (observer.current) {
                observer.current.disconnect();
            }
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    const currLastTwId = transportWarrants[transportWarrants.length - 1].id ?? 0;
                    setLastTwId(currLastTwId);
                }
            });

            if (node) {
                observer.current.observe(node);
            }
        },
        [loading, hasMore],
    );

    const ViewElement = () => {
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Grid container spacing={3}>
                        {transportWarrants.map((tw, index) => {
                            if (transportWarrants.length === index + 1) {
                                return (
                                    <Grid item xs={12} md={6} lg={4} xl={3} key={tw.id} ref={lastRecordRef}>
                                        <TransportWarrant transportWarrant={tw} />
                                    </Grid>
                                );
                            } else {
                                return (
                                    <Grid item xs={12} md={6} lg={4} xl={3} key={tw.id}>
                                        <TransportWarrant transportWarrant={tw} />
                                    </Grid>
                                );
                            }
                        })}
                    </Grid>
                    <div>{loading && 'Učitava se...'}</div>
                    <div>{error && 'Greška'}</div>
                </ThemeProvider>
            </>
        );
    };
    if (!loading && !hasMore && transportWarrants.length <= 0) {
        return <EmptyPage title="Nema putnih naloga!" />;
    }
    if (loading && (!transportWarrants || transportWarrants.length <= 0)) {
        return (
            <div style={{ height: '60vh', width: '60vh', margin: 'auto' }}>
                <LoaderProgress />
            </div>
        );
    }

    return <ViewElement />;
}
