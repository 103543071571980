import React, { ChangeEvent, ReactElement, useState } from 'react';
import './Feed.css';
import { ProjectsSearchToken } from '../../../Extensions/models/RecordSearchTokenModel';
import ProjectsFeedElement from './FeedElements/ProjectsFeedElement';
import SearchWindow from './SearchWindow';
import { RecordType } from '../../../Extensions/Extensions';

export default function ProjectsFeed(): ReactElement {
    const [query, setQuery] = useState<ProjectsSearchToken>({
        searchText: '',
    });

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery({ ...query, searchText: e.target.value, lastEntityId: 0 });
    };
    const setLastProjId = (projId: number) => {
        setQuery({ ...query, lastEntityId: projId });
    };

    return (
        <div style={{ overflowY: 'hidden' }}>
            <SearchWindow handleSearch={handleSearch} query={query} recordType={RecordType.Projects} />
            <div className={'outter'}>
                <div className={'inner'}>
                    <ProjectsFeedElement setLastProjectId={setLastProjId} query={query} />
                </div>
            </div>
        </div>
    );
}
