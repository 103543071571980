import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ClientDto } from '../../../AutoGeneratedAPI/clientApi';
import { ExceptionsWithFetching } from '../../../Extensions/Exceptions';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';

interface IInitialStateType {
    customers: ClientDto[] | null;
    services: ClientDto[] | null;
    suppliers: ClientDto[] | null;
}

const initialState: IInitialStateType = {
    customers: null,
    services: null,
    suppliers: null,
};

export const fetchInitialCustomers = createAsyncThunk(
    'customers/fetchInitialCustomers',
    async () => {
        const response = await GranitClient.getAllCustomers();
        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingCustomers: ClientDto[] | null = thunkAPI.getState().customers.customers;

            return existingCustomers === null;
        },
    },
);
export const fetchInitialSuppliers = createAsyncThunk(
    'customers/fetchInitialSuppliers',
    async () => {
        const response = await GranitClient.getAllSupliers();
        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingSuppliers: ClientDto[] | null = thunkAPI.getState().customers.suppliers;

            return existingSuppliers === null;
        },
    },
);
export const fetchInitialServices = createAsyncThunk(
    'customers/fetchInitialServices',
    async () => {
        const response = await GranitClient.getAllServices();
        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingServices: ClientDto[] | null = thunkAPI.getState().customers.services;

            return existingServices === null;
        },
    },
);

const customersSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        addCustomer(state, action) {
            state.customers = [action.payload, ...(state.customers ?? [])];
        },
        updateCustomer: {
            reducer(state, action) {
                const { customer, oldCustomer } = action.payload;
                const customers = state.customers ?? [];
                const customerIndex = customers.findIndex((c) => {
                    return c.id === oldCustomer.id;
                });
                state.customers = [
                    ...customers.slice(0, customerIndex),
                    customer,
                    ...customers.slice(customerIndex + 1),
                ];
            },
            prepare(customer, oldCustomer) {
                return {
                    payload: { customer, oldCustomer },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
        addService(state, action) {
            state.services = [action.payload, ...(state.services ?? [])];
        },
        updateService: {
            reducer(state, action) {
                const { service, oldService } = action.payload;
                const services = state.services ?? [];
                const serviceIndex = services.findIndex((c) => {
                    return c.id === oldService.id;
                });
                state.services = [...services.slice(0, serviceIndex), service, ...services.slice(serviceIndex + 1)];
            },
            prepare(service, oldService) {
                return {
                    payload: { service, oldService },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
        addSupplier(state, action) {
            state.suppliers = [action.payload, ...(state.suppliers ?? [])];
        },
        updateSupplier: {
            reducer(state, action) {
                const { supplier, oldSupplier } = action.payload;
                const suppliers = state.suppliers ?? [];
                const supplierIndex = suppliers.findIndex((c) => {
                    return c.id === oldSupplier.id;
                });
                state.suppliers = [
                    ...suppliers.slice(0, supplierIndex),
                    supplier,
                    ...suppliers.slice(supplierIndex + 1),
                ];
            },
            prepare(supplier, oldSupplier) {
                return {
                    payload: { supplier, oldSupplier },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
        changeClientProfileImage: {
            reducer(state, action) {
                const customerIndex = state.customers?.findIndex((c) => {
                    return c.id === action.payload.clientId;
                });
                const serviceIndex = state.services?.findIndex((c) => {
                    return c.id === action.payload.clientId;
                });
                const supplierIndex = state.suppliers?.findIndex((c) => {
                    return c.id === action.payload.clientId;
                });
                if (state.customers && customerIndex !== undefined && customerIndex >= 0) {
                    const customer = state.customers[customerIndex];
                    customer.documents = [action.payload.doc, ...(customer.documents?.slice(1) ?? [])];
                    state.customers = [
                        ...state.customers.slice(0, customerIndex),
                        customer,
                        ...state.customers.slice(customerIndex + 1),
                    ];
                }
                if (state.services && serviceIndex !== undefined && serviceIndex >= 0) {
                    const service = state.services[serviceIndex];
                    service.documents = [action.payload.doc, ...(service.documents?.slice(1) ?? [])];
                    state.services = [
                        ...state.services.slice(0, serviceIndex),
                        service,
                        ...state.services.slice(serviceIndex + 1),
                    ];
                }
                if (state.suppliers && supplierIndex !== undefined && supplierIndex >= 0) {
                    const supplier = state.suppliers[supplierIndex];
                    supplier.documents = [action.payload.doc, ...(supplier.documents?.slice(1) ?? [])];
                    state.suppliers = [
                        ...state.suppliers.slice(0, supplierIndex),
                        supplier,
                        ...state.suppliers.slice(supplierIndex + 1),
                    ];
                }
            },
            prepare(clientId, doc) {
                return {
                    payload: { clientId, doc },
                    meta: 'ok',
                    error: 'not ok',
                };
            },
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInitialCustomers.fulfilled, (state, action) => {
            state.customers = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialCustomers.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_CUSTOMERS);
        });
        builder.addCase(fetchInitialSuppliers.fulfilled, (state, action) => {
            state.suppliers = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialSuppliers.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_CUSTOMERS);
        });
        builder.addCase(fetchInitialServices.fulfilled, (state, action) => {
            state.services = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialServices.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_CUSTOMERS);
        });
    },
});

export const {
    addCustomer,
    updateCustomer,
    addService,
    updateService,
    addSupplier,
    updateSupplier,
    changeClientProfileImage,
} = customersSlice.actions;

export default customersSlice.reducer;
