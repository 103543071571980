import { CompareTwoDates, CompareTwoDatesEquality, CompareTwoTimes } from '../../../Common/DateHandler';
import { airToLandDistanceCoefficient, findOutTheDistanceBetweenTwoPoints } from '../../../Common/DistanceCalculator';
import { StopDto, TransportWarrantDto } from '../../AutoGeneratedAPI/clientApi';
import { KeyValueNumber } from '../../Extensions/Extensions';

/**
 * Returns the first transport warrant that happened before reference tw for a given main vehicle (truck)
 * @param transportWarrants transport warrants to go through
 * @param transportWarrant reference tw (date and mainVehicleId)
 * @returns First tw before reference tw
 */
export const findFirstPreviousTransportWarrant = (
    transportWarrants: TransportWarrantDto[] | null,
    transportWarrant: TransportWarrantDto,
): TransportWarrantDto | null => {
    const pastTws = transportWarrants
        ?.filter(
            (tw) =>
                tw.composition.mainVehicleId === transportWarrant.composition.mainVehicleId &&
                (CompareTwoDates(tw.actEndingTime, transportWarrant.actStartingTime) ||
                    (CompareTwoDatesEquality(tw.actEndingTime, transportWarrant.actStartingTime) &&
                        CompareTwoTimes(tw.actEndingTime, transportWarrant.actStartingTime))),
        )
        .sort((a, b) => {
            return (a.endingKm ?? 0) < (b.endingKm ?? 0) ? 1 : -1;
        });
    const firstPastTw = pastTws ? pastTws[0] : null;

    return firstPastTw;
};

/**
 * Returns the first transport warrant that happened after reference tw for a given main vehicle (truck)
 * @param transportWarrants transport warrants to go through
 * @param transportWarrant reference tw (date and mainVehicleId)
 * @returns First tw after reference tw
 */
export const findFirstFutureTransportWarrant = (
    transportWarrants: TransportWarrantDto[] | null,
    transportWarrant: TransportWarrantDto,
): TransportWarrantDto | null => {
    const futureTws = transportWarrants
        ?.filter(
            (tw) =>
                tw.composition.mainVehicleId === transportWarrant.composition.mainVehicleId &&
                (CompareTwoDates(transportWarrant.actEndingTime, tw.actStartingTime) ||
                    (CompareTwoDatesEquality(transportWarrant.actEndingTime, tw.actStartingTime) &&
                        CompareTwoTimes(transportWarrant.actEndingTime, tw.actStartingTime))),
        )
        .sort((a, b) => {
            return (a.endingKm ?? 0) < (b.endingKm ?? 0) ? 1 : -1;
        });
    const firstFutureTw = futureTws ? futureTws[0] : null;

    return firstFutureTw;
};

/**
 * Calculates routes between every two points with and without newStop and returns the difference as an array of numbers
 * @param stops StopDto[]
 * @param newStop StopDto
 * @returns differences between initial routes, and routes with additinal point of newStop
 */
export const calculateRoutesDistancesDiff = (
    stops?: StopDto[],
    newStop?: StopDto,
    homeStop?: StopDto,
): KeyValueNumber[] => {
    if (!newStop) {
        return [{ key: 0, value: 0 }];
    }

    if (!stops || stops.length <= 0) {
        const distances = [];

        const firstStart = homeStop?.locationInfo?.coordinates;
        const firstEnd = newStop.locationInfo?.coordinates;

        if (!firstStart || !firstEnd) {
            return [{ key: 0, value: 0 }];
        }

        const firstDiffOneAir = findOutTheDistanceBetweenTwoPoints(
            firstStart.lat,
            firstEnd.lat,
            firstStart.lng,
            firstEnd.lng,
        );

        const firstDiffOneLand = firstDiffOneAir * airToLandDistanceCoefficient(firstDiffOneAir);

        distances.push({ key: 0, value: firstDiffOneLand });

        return distances;
    }

    stops.sort((a, b) => {
        return CompareTwoTimes(a.startingTime, b.startingTime) ? -1 : 1;
    });

    const distances = [];

    const firstStart = homeStop?.locationInfo?.coordinates;
    const additionalStop = newStop.locationInfo?.coordinates;
    const firstEnd = stops[0].locationInfo?.coordinates;

    // The distance between home and first point
    const firstDiffOneAir =
        firstStart && additionalStop
            ? findOutTheDistanceBetweenTwoPoints(firstStart.lat, additionalStop.lat, firstStart.lng, additionalStop.lng)
            : 0;
    const firstDiffOneLand = firstDiffOneAir * airToLandDistanceCoefficient(firstDiffOneAir);

    const firstDiffTwoAir =
        firstEnd && additionalStop
            ? findOutTheDistanceBetweenTwoPoints(additionalStop.lat, firstEnd.lat, additionalStop.lng, firstEnd.lng)
            : 0;
    const firstDiffTwoLand = firstDiffTwoAir * airToLandDistanceCoefficient(firstDiffTwoAir);

    const firstDiffAir =
        firstEnd && firstStart
            ? findOutTheDistanceBetweenTwoPoints(firstStart.lat, firstEnd.lat, firstStart.lng, firstEnd.lng)
            : 0;
    const firstDiffLand = firstDiffAir * airToLandDistanceCoefficient(firstDiffAir);

    distances.push({ key: 0, value: firstDiffOneLand + firstDiffTwoLand - firstDiffLand });

    stops.map((ship, index) => {
        if (index !== 0) {
            const start = stops[index - 1].locationInfo?.coordinates;
            const end = ship.locationInfo?.coordinates;

            const diffOneAir =
                start && additionalStop
                    ? findOutTheDistanceBetweenTwoPoints(start.lat, additionalStop.lat, start.lng, additionalStop.lng)
                    : 0;
            const diffOneLand = diffOneAir * airToLandDistanceCoefficient(diffOneAir);

            const diffTwoAir =
                end && additionalStop
                    ? findOutTheDistanceBetweenTwoPoints(additionalStop.lat, end.lat, additionalStop.lng, end.lng)
                    : 0;
            const diffTwoLand = diffTwoAir * airToLandDistanceCoefficient(diffTwoAir);

            const diffAir =
                start && end ? findOutTheDistanceBetweenTwoPoints(start.lat, end.lat, start.lng, end.lng) : 0;
            const diffLand = diffAir * airToLandDistanceCoefficient(diffAir);

            distances.push({ key: stops[index - 1].id, value: diffOneLand + diffTwoLand - diffLand });
        }

        // When we reach the end, add the distance between last point and home
        if (index === stops.length - 1) {
            const start = ship.locationInfo.coordinates;
            const end = homeStop?.locationInfo.coordinates;

            const diffOneAir =
                start && additionalStop
                    ? findOutTheDistanceBetweenTwoPoints(start.lat, additionalStop.lat, start.lng, additionalStop.lng)
                    : 0;
            const diffOneLand = diffOneAir * airToLandDistanceCoefficient(diffOneAir);

            const diffTwoAir =
                end && additionalStop
                    ? findOutTheDistanceBetweenTwoPoints(additionalStop.lat, end.lat, additionalStop.lng, end.lng)
                    : 0;
            const diffTwoLand = diffTwoAir * airToLandDistanceCoefficient(diffTwoAir);

            const diffAir =
                start && end ? findOutTheDistanceBetweenTwoPoints(start.lat, end.lat, start.lng, end.lng) : 0;
            const diffLand = diffAir * airToLandDistanceCoefficient(diffAir);

            distances.push({ key: ship.id, value: diffOneLand + diffTwoLand - diffLand });
        }
    });

    return distances;
};
