import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import {
    fetchInitialCars,
    fetchInitialTrailers,
    fetchInitialTrucks,
} from '../General/Redux/Reducers/Vehicles/VehiclesReducer';
import ChartVehicleSelect from './ChartVehicleSelect';
import { useAppDispatch } from '../General/hooks/hooks';
import AllExpensesPieChart from './AllExpensesPieChart';
import FuelExpensesChart from './FuelExpensesChart';
import AllExpensesChart from './AllExpensesChart';
import AdditionalExpensesCalcContainer from './AdditionalExpensesCalcContainer';
import { CompareTwoDates } from '../Common/DateHandler';

Chart.register(...registerables);

export default function Charts(): ReactElement {
    const [margin, setMargin] = useState<number>(70);
    const [endDate, setEndDate] = useState<string>(new Date().toISOString().replace(/T.*$/, ''));
    const [startDate, setStartDate] = useState<string>(
        new Date(new Date().getUTCFullYear() - 1, new Date().getUTCMonth(), new Date().getUTCDate())
            .toISOString()
            .replace(/T.*$/, ''),
    );

    const dispatch = useAppDispatch();

    const resizeWindow = useCallback(() => {
        if (window.innerWidth > 900) {
            setMargin(70);
        } else {
            setMargin(220);
        }
    }, []);

    useEffect(() => {
        dispatch(fetchInitialCars());
        dispatch(fetchInitialTrucks());
        dispatch(fetchInitialTrailers());
    }, []);

    useEffect(() => {
        window.addEventListener('resize', resizeWindow);
        return () => {
            window.removeEventListener('resize', resizeWindow);
        };
    }, [resizeWindow]);

    const handleStartDateChange = (date?: Date): void => {
        if (date && new Date(date).toString() !== 'Invalid Date') {
            const nDate = new Date(date);
            if (startDate && CompareTwoDates(nDate, new Date(startDate))) {
                const newDate = new Date(nDate.getUTCFullYear(), nDate.getUTCMonth() - 1, nDate.getUTCDate());
                setStartDate(newDate.toISOString().replace(/T.*$/, ''));
            }
            setStartDate(nDate.toISOString().replace(/T.*$/, ''));
        }
    };

    const handleEndDateChange = (date?: Date): void => {
        if (date && new Date(date).toString() !== 'Invalid Date') {
            const nDate = new Date(date);
            if (endDate && CompareTwoDates(new Date(endDate), nDate)) {
                const newDate = new Date(nDate.getUTCFullYear(), nDate.getUTCMonth() + 1, nDate.getUTCDate());
                if (CompareTwoDates(newDate, new Date())) {
                    setEndDate(newDate.toISOString());
                } else {
                    setEndDate(new Date().toISOString().replace(/T.*$/, ''));
                }
            }
            setEndDate(nDate.toISOString().replace(/T.*$/, ''));
        }
    };

    return (
        <div
            style={{
                width: '100%',
                marginTop: 66,
                height: 'calc(100vh - 85px)',
                padding: 10,
            }}
        >
            <div style={{ zIndex: 10, display: 'flex', justifyContent: 'space-around' }}>
                <ChartVehicleSelect
                    startDate={startDate}
                    setStartDate={handleStartDateChange}
                    setEndDate={handleEndDateChange}
                    endDate={endDate}
                />
            </div>
            <div
                style={{
                    width: '100%',
                    margin: 'auto',
                    height: `calc(100vh - 102px - ${margin}px)`,
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    overflowY: 'auto',
                    gap: 20,
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                <AllExpensesChart endDate={endDate} startDate={startDate} />
                <FuelExpensesChart fuelExpenseEndDate={endDate} fuelExpenseStartDate={startDate} />
                <AdditionalExpensesCalcContainer />
                <AllExpensesPieChart pieEndDate={endDate} pieStartDate={startDate} />
            </div>
        </div>
    );
}
