import { format } from 'date-fns';
import sr from 'date-fns/locale/sr';
import sr_Latn_RS from 'date-fns/locale/sr-Latn';

export type TimeSpan = {
    hours: number;
    minutes: number;
};

// returns true if date1 is smaller than date2
export const CompareTwoDates = (date1: Date, date2: Date): boolean => {
    return (
        date1.getFullYear() < date2.getFullYear() ||
        (date1.getFullYear() === date2.getFullYear() && date1.getMonth() < date2.getMonth()) ||
        (date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() < date2.getDate())
    );
};

// returns true if two dates are equal
export const CompareTwoDatesEquality = (date1: Date, date2: Date): boolean => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
};

export const CompareTwoDatesWithoutDayEquality = (date1: Date, date2: Date): boolean => {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
};

export const CompareTwoTimes = (date1: Date, date2: Date): boolean => {
    return (
        date1.getHours() < date2.getHours() ||
        (date1.getHours() === date2.getHours() && date1.getMinutes() < date2.getMinutes())
    );
};

export const CompareTwoTimesEquality = (date1: Date, date2: Date): boolean => {
    return date1.getHours() === date2.getHours() && date1.getMinutes() === date2.getMinutes();
};

/**
 *  returns true if date1 is smaller than (or equal) to date2
 * @param date1
 * @param date2
 * @param includeEquality
 * @returns
 */
export const CompareTwoDateTimes = (date1: Date, date2: Date, includeEquality: boolean): boolean => {
    const lessThan =
        CompareTwoDates(date1, date2) || (CompareTwoDatesEquality(date1, date2) && CompareTwoTimes(date1, date2));
    if (includeEquality) {
        const equalTo = CompareTwoDatesEquality(date1, date2) && CompareTwoTimesEquality(date1, date2);
        return lessThan || equalTo;
    }
    return lessThan;
};

/**
 * Returns true if date1 is equal to date2 (includes dates and times)
 * @param date1
 * @param date2
 * @param includeEquality
 * @returns
 */
export const CompareTwoDateTimesEquality = (date1: Date, date2: Date): boolean => {
    return CompareTwoDatesEquality(date1, date2) && CompareTwoTimesEquality(date1, date2);
};

export const FormatDateText = (date: Date): string => {
    if (!date) {
        return '';
    }
    const dateView =
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
        '.' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '.' +
        date.getFullYear() +
        '.';
    return dateView;
};

export const FormatDateWithDayNameText = (date: Date): string => {
    if (!date) {
        return '';
    }

    return format(date, 'EEE dd.MM.yy.', { locale: sr });
};

export const FormatDateWithoutDay = (date: Date): string => {
    if (!date) {
        return '';
    }

    return format(date, 'MMMM yyyy.', { locale: sr_Latn_RS });
};

export const FormatDateWithDayNameTextAtTheEnd = (date: Date): string => {
    if (!date) {
        return '';
    }

    return format(date, 'dd.MM.yy. EEE', { locale: sr });
};

export const FormatDateSpanText = (dt1: Date, dt2: Date): string => {
    const date1 = FormatDateText(dt1);
    const date2 = FormatDateText(dt2);
    if (!date1 && !date2) {
        return '';
    }
    if (!date1) {
        return date2;
    }
    if (!date2) {
        return date1;
    }
    if (date1 === date2) {
        return date1;
    }

    const date1Day = date1.slice(0, 2);
    const date2Day = date2.slice(0, 2);
    const date1Month = date1.slice(3, 5);
    const date2Month = date1.slice(3, 5);
    const date1Year = date1.slice(6, 10);
    const date2Year = date1.slice(6, 10);

    const dateView =
        date1Year !== date2Year
            ? date1.slice(0, 10) + '/' + date2
            : date1Month !== date2Month
            ? date1.slice(0, 5) + '/' + date2.slice(0, 5) + date1Year + '.'
            : date1Day + '/' + date2Day + '.' + date1.slice(3);

    return dateView;
};

export const FormatTimeText = (date: Date): string => {
    if (!date) {
        return '';
    }
    const timeView =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
        ':' +
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    return timeView;
};

export const GetIsoDateFromDate = (date: Date): Date => {
    const nDate = new Date(date);
    nDate.setUTCDate(nDate.getDate());
    nDate.setUTCMonth(nDate.getMonth());
    nDate.setUTCFullYear(nDate.getFullYear());
    return nDate;
};

export const GetIsoDateString = (date: Date): string => {
    if (date) {
        return date.toISOString().replace(/T.*$/, '');
    }

    return new Date().toISOString().replace(/T.*$/, '');
};

export const GetStartOfTheWeek = (date: Date): Date => {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day == 0 ? -6 : 1);
    return new Date(d.setDate(diff));
};

export const GetStartOfTheMonth = (date: Date): Date => {
    const d = new Date(date);
    return new Date(d.getFullYear(), d.getMonth(), 1);
};

/**
 * Returns the difference between times in hours and minutes; ignores the difference in days, months or years
 * @param date1 first date
 * @param date2 second date
 * @returns TimeSpan { hours: number, minutes: number }
 */
export const getTimeDifference = (date1?: Date, date2?: Date): TimeSpan => {
    if (date1 && date2) {
        if (CompareTwoTimesEquality(date1, date2)) {
            return { hours: 0, minutes: 0 };
        }
        const newD1 = new Date(date1);
        const newD2 = new Date(date2);
        const seconds = Math.abs(newD1.getTime() - newD2.getTime()) / 1000;
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        return { hours: hours, minutes: minutes };
    }
    return { hours: 0, minutes: 0 };
};

export const getDayDifference = (date1?: Date, date2?: Date): number => {
    if (date1 && date2) {
        const newD1 = new Date(date1);
        const newD2 = new Date(date2);
        const seconds = Math.abs(newD1.getTime() - newD2.getTime()) / 1000;
        const days = Math.floor(seconds / 3600 / 24);

        return days;
    }

    return 0;
};

export const getWeekDifference = (date1?: Date, date2?: Date): number => {
    if (date1 && date2) {
        const newD1 = new Date(date1);
        const newD2 = new Date(date2);

        const days = getDayDifference(newD1, newD2);

        return days / 7;
    }

    return 0;
};

export const getMonthDifference = (date1?: Date, date2?: Date): number => {
    if (date1 && date2) {
        const newD1 = new Date(date1);
        const newD2 = new Date(date2);

        const months = Math.abs(newD1.getMonth() - newD2.getMonth());

        return months;
    }

    return 0;
};

export const getYearsDifference = (date1?: Date, date2?: Date): number => {
    if (date1 && date2) {
        const newD1 = new Date(date1);
        const newD2 = new Date(date2);

        const months = getMonthDifference(newD1, newD2);

        return Math.floor(months / 12);
    }

    return 0;
};

export const formatNumberOnTwoDigits = (num: number): string => {
    return num < 10 ? '0' + num : num.toString();
};

export const getTimeDifferenceForTwoDates = (greaterDate: Date, smallerDate: Date): string => {
    const days = getDayDifference(greaterDate, smallerDate);
    if (days < 1) {
        const timeSpan = getTimeDifference(greaterDate, smallerDate);

        if (timeSpan.hours === 0) {
            return `${timeSpan.minutes} min.`;
        } else {
            return `${timeSpan.hours} h ${timeSpan.minutes} min.`;
        }
    } else {
        return `${days} dana`;
    }
};

export const getZeroMomentOfTheDate = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
};

export const getLastMomentOfTheDate = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
};
