/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CarDto, TrailerDto, TruckDto } from '../../../AutoGeneratedAPI/clientApi';
import { AppType } from '../../../Extensions/Extensions';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithAdding, ExceptionsWithUpdating } from '../../../Extensions/Exceptions';
import {
    addCar,
    addTrailer,
    addTruck,
    updateCar,
    updateTrailer,
    updateTruck,
} from '../../Reducers/Vehicles/VehiclesReducer';
import { changeCurrentEntity } from '../../Reducers/General/CurrentEntityReducer';

export const addNewVehicle = (vehicle: CarDto | TruckDto | TrailerDto | undefined, appType: AppType | undefined) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    if (appType === AppType.Car) {
        GranitClient.addNewCar(vehicle as CarDto).then(
            (result) => {
                dispatch(addCar(result));
            },
            (error) => {
                alert(ExceptionsWithAdding.EXCEPTION_ADDING_CARS);
                console.log(error.message);
            },
        );
    } else if (appType === AppType.Truck) {
        GranitClient.addNewTruck(vehicle as TruckDto).then(
            (result) => {
                dispatch(addTruck(result));
            },
            (error) => {
                alert(ExceptionsWithAdding.EXCEPTION_ADDING_TRUCKS);
                console.log(error.message);
            },
        );
    } else if (appType === AppType.Trailer) {
        GranitClient.addNewTrailer(vehicle as TrailerDto).then(
            (result) => {
                dispatch(addTrailer(result));
            },
            (error) => {
                alert(ExceptionsWithAdding.EXCEPTION_ADDING_TRAILERS);
                console.log(error.message);
            },
        );
    }
};

export const updateOldVehicle = (
    vehicle: CarDto | TruckDto | TrailerDto | undefined,
    appType: AppType | undefined,
    oldVehicle: CarDto | TruckDto | TrailerDto | null,
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
    if (appType === AppType.Car) {
        GranitClient.updateCar(vehicle !== undefined ? (vehicle.id as number) : 0, vehicle as CarDto).then(
            (result) => {
                dispatch(changeCurrentEntity(result));
                dispatch(updateCar(result as CarDto, oldVehicle));
            },
            (error) => {
                alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_CARS);
                console.log(error.message);
            },
        );
    } else if (appType === AppType.Truck) {
        GranitClient.updateTruck(vehicle !== undefined ? (vehicle.id as number) : 0, vehicle as TruckDto).then(
            (result) => {
                dispatch(changeCurrentEntity(result));
                dispatch(updateTruck(result as TruckDto, oldVehicle));
            },
            (error) => {
                alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_TRUCKS);
                console.log(error.message);
            },
        );
    } else if (appType === AppType.Trailer) {
        GranitClient.updateTrailer(vehicle !== undefined ? (vehicle.id as number) : 0, vehicle as TrailerDto).then(
            (result) => {
                dispatch(changeCurrentEntity(result));
                dispatch(updateTrailer(result as TrailerDto, oldVehicle));
            },
            (error) => {
                alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_TRAILERS);
                console.log(error.message);
            },
        );
    }
};
