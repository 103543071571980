import { ExpenseCategory, TruckDto } from '../General/AutoGeneratedAPI/clientApi';
import { ExpenseEntityType } from '../General/Extensions/Entities';
import { ExpenseTypeSR } from '../General/Extensions/Localizations/Types/Expenses/SR/ExpenseTypeSR';

export type DataSet = {
    label: string;
    afterLabel?: number[];
    data: number[];
    fill: boolean;
    backgroundColor: string[];
    hoverBorderWidth: number;
    pointRadius: number;
    borderColor: string[];
    dates: Date[];
    tension: number;
};

export type Data = {
    labels: string[];
    datasets: DataSet[];
};

export const options = {
    plugins: {
        legend: {
            labels: {
                font: {
                    size: 20,
                },
            },
        },
        tooltip: {
            callbacks: {
                // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
                afterLabel: function (context: any) {
                    const afterLabels = context.dataset.afterLabel || '';
                    const dates = context.dataset.dates || '';

                    if (afterLabels && afterLabels !== '') {
                        let afterLbl = Math.round(+afterLabels[context.dataIndex]).toLocaleString('sr-SR') + ' RSD/km';
                        const date = new Date(dates[context.dataIndex] ?? new Date());
                        if (dates[context.dataIndex]) {
                            const viewDate =
                                date.toLocaleString('sr-SR', { month: 'short', day: '2-digit' }) +
                                ' ' +
                                date.getUTCFullYear();

                            afterLbl += ' (' + viewDate + ')';
                        }
                        return afterLbl;
                    }
                    return '';
                },
                // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
                label: function (context: any) {
                    const afterLabels = context.dataset.afterLabel || '';
                    let label = context.dataset.label || '';

                    if (label) {
                        if (afterLabels) {
                            label +=
                                ': ' +
                                Math.round(+context.dataset.data[context.dataIndex]).toLocaleString('sr-SR') +
                                ' l/100km';
                        } else {
                            label +=
                                ': ' +
                                Math.round(+context.dataset.data[context.dataIndex]).toLocaleString('sr-SR') +
                                ' RSD/km';
                        }
                    }
                    return label;
                },
            },
        },
    },
    scales: {
        y: {
            beginAtZero: true,
        },
    },
};

const positionLeft: 'right' | 'top' | 'bottom' | 'left' = 'left';

export const pieOptions = {
    plugins: {
        legend: {
            labels: {
                font: {
                    size: 15,
                },
            },
            position: positionLeft,
        },
        tooltip: {
            callbacks: {
                // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
                label: function (context: any) {
                    let label = context.dataset.label || '';
                    if (label) {
                        label +=
                            ': ' + Math.round(context.dataset.data[context.dataIndex]).toLocaleString('sr-SR') + ' RSD';
                    }
                    return label;
                },
            },
        },
    },
};

interface Column {
    id: 'title' | 'type' | 'duration (mon)' | 'duration (km)' | 'price';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

export const columns: readonly Column[] = [
    { id: 'title', label: 'Naslov', minWidth: 50 },
    {
        id: 'duration (mon)',
        label: 'Trajanje (mes)',
        minWidth: 50,
        align: 'right',
        format: (value: number) => value.toLocaleString('sr-SR'),
    },
    {
        id: 'duration (km)',
        label: 'Trajanje (km)',
        minWidth: 50,
        align: 'right',
        format: (value: number) => value.toLocaleString('sr-SR'),
    },
    {
        id: 'price',
        label: 'Cena',
        minWidth: 70,
        align: 'right',
        format: (value: number) => value.toLocaleString('sr-SR'),
    },
];

export const reduceExpense = (exps: ExpenseEntityType[], category: ExpenseCategory): number => {
    return exps
        .filter((exp) => exp.expenseType.expenseCategory === category)
        .reduce((acc, exp) => {
            return acc + exp.price;
        }, 0);
};

export const getPieChartData = (
    expenses: ExpenseEntityType[],
    vehId: string,
    trucks: TruckDto[],
): { lbls: string[]; dataset: DataSet[] } => {
    const fuelExp = reduceExpense(expenses, ExpenseCategory.Fuel);
    const calculatorExp = reduceExpense(expenses, ExpenseCategory.CalculatorExpense);
    const repairAndMaintenanceExp = reduceExpense(expenses, ExpenseCategory.RepairAndMaintenance);
    const recurringExp = reduceExpense(expenses, ExpenseCategory.RecurringExpense);
    const otherExp = reduceExpense(expenses, ExpenseCategory.Other);
    let amortisationExp = 0;

    if (vehId && !isNaN(+vehId)) {
        const vehicleId = +vehId;
        const truck = trucks?.find((t) => t.id === vehicleId);
        if (truck && truck.amortisation && truck.depreciationPeriod && truck.depreciationPeriod > 0) {
            amortisationExp = truck.amortisation / truck.depreciationPeriod;
        }
    }

    let arr = [
        { name: ExpenseTypeSR[ExpenseCategory.Fuel].toString(), value: fuelExp },
        { name: ExpenseTypeSR[ExpenseCategory.CalculatorExpense].toString(), value: calculatorExp },
        {
            name: ExpenseTypeSR[ExpenseCategory.RepairAndMaintenance].toString(),
            value: repairAndMaintenanceExp,
        },
        { name: ExpenseTypeSR[ExpenseCategory.RecurringExpense].toString(), value: recurringExp },
        { name: ExpenseTypeSR[ExpenseCategory.Other].toString(), value: otherExp },
        { name: 'Amortizacija', value: amortisationExp },
    ];

    arr = arr.sort((a, b) => b.value - a.value);

    const lbls = arr.map((a) => a.name);

    const vals: number[] = arr.map((a) => a.value);

    const dataset: DataSet[] = [
        {
            label: 'trošak',
            data: vals,
            fill: false,
            backgroundColor: ['#0a2f54', '#125393', '#8cbbe9', '#7e460f', '#d27519', '#e4ac75'],
            borderColor: ['#0a2f54', '#125393', '#8cbbe9', '#7e460f', '#d27519', '#e4ac75'],
            hoverBorderWidth: 8,
            pointRadius: 4,
            tension: 0.1,
            dates: [new Date()],
        },
    ];

    return { lbls, dataset };
};

export const getDefaultPieChartData = (): { lbls: string[]; dataset: DataSet[] } => {
    const arr = [
        { name: ExpenseTypeSR[ExpenseCategory.Fuel].toString(), value: 1 },
        { name: ExpenseTypeSR[ExpenseCategory.CalculatorExpense].toString(), value: 1 },
        {
            name: ExpenseTypeSR[ExpenseCategory.RepairAndMaintenance].toString(),
            value: 1,
        },
        { name: ExpenseTypeSR[ExpenseCategory.RecurringExpense].toString(), value: 1 },
        { name: ExpenseTypeSR[ExpenseCategory.Other].toString(), value: 1 },
        { name: 'Amortizacija', value: 1 },
    ];

    const lbls = arr.map((a) => a.name);

    const vals: number[] = arr.map((a) => a.value);

    const dataset: DataSet[] = [
        {
            label: 'trošak',
            data: vals,
            fill: false,
            backgroundColor: ['#0a2f54', '#125393', '#8cbbe9', '#7e460f', '#d27519', '#e4ac75'],
            borderColor: ['#0a2f54', '#125393', '#8cbbe9', '#7e460f', '#d27519', '#e4ac75'],
            hoverBorderWidth: 8,
            pointRadius: 4,
            tension: 0.1,
            dates: [new Date()],
        },
    ];

    return { lbls, dataset };
};
