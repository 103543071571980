import styled from 'styled-components';

export const ShipmentLoadingContainer = styled.div`
    height: calc(100vh - 216px);
    width: 100vw;
    overflow: hidden;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
`;

export const ShipmentLoadingCloseBtn = styled.div`
    position: absolute;
    left: 15px;
    top: 10px;
    font-size: 30px;
    font-family: Courier New;
    cursor: pointer;
`;

export const ShipmentLoadingFilterBarContainer = styled.div`
    width: calc(100vw - 50px);
    margin-left: 50px;
    padding: 15px;
    display: flex;
    justify-content: end;
    gap: 20px;
`;

export const ShipmentLoadingSortParamsContainer = styled.div`
    width: 300px;
`;

export const ShipmentLoadingTimeParamsContainer = styled.div`
    width: 500px;
`;

export const ShipmentLoadingVerticalLine = styled.div`
    width: 1px;
    height: 100%;
    background-color: lightgray;
`;

export const ShipmentLoadingShipmentInfoContainer = styled.div`
    width: 350px;
    height: calc(100vh - 248px);
    overflow-y: auto;
    padding-right: 15px;
    margin-right: 0px;
`;

export const ShipmentLoadingTWListContainer = styled.div`
    width: 100%;
    overflow-y: auto;
    padding-left: 15px;
`;

export const ShipmentLoadingTWListElementContainer = styled.div`
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
`;

export const ShipmentLoadingTWListShipmentsContainer = styled.div`
    position: absolute;
    top: 100px;
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 20px;
`;

export const ShipmentLoadingTWListShipmentElement = styled.div`
    width: 100%;
    max-width: 200px;
    height: 60px;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 5px;
    overflow: hidden;
`;
