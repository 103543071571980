import { DriverDto } from '../../../AutoGeneratedAPI/clientApi';
import { CompleteUserInfoDto } from '../../../AutoGeneratedAPI/MSGraph/msGraphApi';
import { GraphClient } from '../../../AutoGeneratedAPI/MSGraph/MSGraphExtensions';

export const getGraphDriverFromDriver = async (driver: DriverDto | null): Promise<CompleteUserInfoDto> => {
    const response = await GraphClient.getUserCompleteInfo(driver?.azObjId ?? 'error');
    return response;
};

export const getGraphDriverPhoto = async (driver: DriverDto | null): Promise<Blob | MediaSource | undefined> => {
    const response = await GraphClient.getUserPhoto(driver?.azObjId ?? 'error');
    return response;
};
