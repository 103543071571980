import {
    Grid,
    Typography,
    InputAdornment,
    TextField,
    Stack,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Paper,
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import {
    EntityType,
    EventTimeSpan,
    IKmEventDto,
    ITimeEventDto,
    NotificationMedia,
    TimeEventDto,
    KmEventDto,
    UserScope,
} from '../../AutoGeneratedAPI/clientApi';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import {
    isKmEventChanged,
    isKmEventValid,
    isTimeEventChanged,
    isTimeEventInTimeRingValid,
    isTimeEventValid,
} from '../../Profile/Form/ExpenseFormHelper';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { RootState } from '../../Redux/store';
import {
    addKmEventToRedux,
    addTimeEventToRedux,
    updateKmEventInRedux,
    updateTimeEventInRedux,
} from '../../Redux/Reducers/Notifications/NotificationsReducer';
import { formatNumberTypeInput } from '../../../Common/Common';

type EventFormProps = {
    entityType: EntityType;
    entityId: number;
    closeForm(): void;
    defaultInKmToRing?: number;
    defaultKmWhenToRing?: number;
    defaultInTimeToRing?: EventTimeSpan;
    defaultRingDate?: Date;
    defaultStartingDate?: Date;
    defaultKmEvent?: KmEventDto;
    defaultTimeEvent?: TimeEventDto;
};

export default function EventForm({
    entityType,
    entityId,
    closeForm,
    defaultInKmToRing,
    defaultKmWhenToRing,
    defaultInTimeToRing,
    defaultRingDate,
    defaultStartingDate,
    defaultKmEvent,
    defaultTimeEvent,
}: EventFormProps): ReactElement {
    const userGroup = useAppSelector((state: RootState) => state.loggedEmployee.userGroup);

    const [localTimeEvent, setLocalTimeEvent] = useState<ITimeEventDto>(
        new TimeEventDto(
            defaultTimeEvent ?? {
                ...new TimeEventDto(),
                notificationMedia: NotificationMedia.Transport,
                userScope: UserScope.Personal,
                entityType: entityType,
                isDone: false,
                startingDate: defaultStartingDate ?? new Date(),
                ringDate: defaultRingDate ?? undefined,
                inTimeRing: defaultInTimeToRing ?? new EventTimeSpan(),
            },
        ),
    );
    const [localKmEvent, setLocalKmEvent] = useState<IKmEventDto>(
        new KmEventDto(
            defaultKmEvent ?? {
                ...new KmEventDto(),
                notificationMedia: NotificationMedia.Transport,
                userScope: UserScope.Personal,
                entityType: entityType,
                isDone: false,
                startingDate: defaultStartingDate ?? new Date(),
                kmWhenToRing: defaultKmWhenToRing,
                inKmToRing: defaultInKmToRing,
            },
        ),
    );

    const [isTimeEventActive, setIsTimeEventActive] = useState<boolean>(
        defaultTimeEvent ||
            (entityType !== EntityType.Expense && entityType !== EntityType.Record && entityType !== EntityType.Vehicle)
            ? true
            : false,
    );
    const [isKmEventActive, setIsKmEventActive] = useState<boolean>(defaultKmEvent ? true : false);

    const [isTimeEventInTimeRingActive, setIsTimeEventInTimeRingActive] = useState<boolean>(
        defaultTimeEvent && defaultTimeEvent.ringDate ? false : true,
    );
    const [isKmEventInKmToRingActive, setIsKmEventInKmToRingActive] = useState<boolean>(
        defaultKmEvent && defaultKmEvent.kmWhenToRing && defaultKmEvent.kmWhenToRing > 0 ? false : true,
    );

    const dispatch = useAppDispatch();

    const handleToggleTimeEventActive = () => {
        setIsTimeEventActive(!isTimeEventActive);
    };

    const handleToggleKmEventActive = () => {
        setIsKmEventActive(!isKmEventActive);
    };

    const handleToggleTimeEventMode = () => {
        setIsTimeEventInTimeRingActive(!isTimeEventInTimeRingActive);
    };

    const handleToggleKmEventMode = () => {
        setIsKmEventInKmToRingActive(!isKmEventInKmToRingActive);
    };

    const handleEventInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (value !== undefined) {
            setLocalTimeEvent({ ...localTimeEvent, [name]: value });
            setLocalKmEvent({ ...localKmEvent, [name]: value });
        }
    };

    const handleTimeEventTimeSpanInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = +target.value;
        const name = target.name;

        if (!isNaN(value) && value >= 0) {
            setLocalTimeEvent({
                ...localTimeEvent,
                inTimeRing: { ...localTimeEvent.inTimeRing, [name]: value } as EventTimeSpan,
            });
        }
    };

    const handleTimeEventStartDateChange = (date?: Date): void => {
        if (date) {
            setLocalTimeEvent({ ...localTimeEvent, startingDate: date as Date });
        }
    };

    const handleTimeEventRingDateChange = (date?: Date): void => {
        if (date) {
            setLocalTimeEvent({ ...localTimeEvent, ringDate: date as Date });
        }
    };

    const handleTimeEventMarginInDaysChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = +target.value;

        if (!isNaN(value) && value >= 0) {
            setLocalTimeEvent({ ...localTimeEvent, marginInDays: value });
        }
    };

    const handleKmEventInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = +target.value;
        const name = target.name;

        if (!isNaN(value) && value >= 0) {
            setLocalKmEvent({ ...localKmEvent, [name]: value });
        }
    };

    const addKmEvent = () => {
        if (userGroup && userGroup.id) {
            const kmEvent = localKmEvent;
            kmEvent.entityId = entityId;
            kmEvent.userGroupId = userGroup.id;

            if (isKmEventInKmToRingActive) {
                kmEvent.kmWhenToRing = undefined;
            } else {
                kmEvent.inKmToRing = undefined;
            }

            GranitClient.addKmEvent(kmEvent as KmEventDto).then((res) => {
                dispatch(addKmEventToRedux(res));
                closeForm();
            });
        }
    };

    const addTimeEvent = () => {
        if (userGroup && userGroup.id) {
            const timeEvent = localTimeEvent;
            timeEvent.entityId = entityId;
            timeEvent.userGroupId = userGroup.id;

            if (isTimeEventInTimeRingActive) {
                timeEvent.ringDate = undefined;
            } else {
                timeEvent.inTimeRing = new EventTimeSpan({ months: 0, days: 0 });
            }

            GranitClient.addTimeEvent(timeEvent as TimeEventDto).then((res) => {
                dispatch(addTimeEventToRedux(res));
                closeForm();
            });
        }
    };

    const updateKmEvent = () => {
        if (userGroup && userGroup.id) {
            const kmEvent = localKmEvent;

            if (isKmEventInKmToRingActive) {
                kmEvent.kmWhenToRing = undefined;
            } else {
                kmEvent.inKmToRing = undefined;
            }

            GranitClient.updateKmEvent(kmEvent.id, kmEvent as KmEventDto).then((res) => {
                dispatch(updateKmEventInRedux(res));
                closeForm();
            });
        }
    };

    const updateTimeEvent = () => {
        if (userGroup && userGroup.id) {
            const timeEvent = localTimeEvent;

            if (isTimeEventInTimeRingActive) {
                timeEvent.ringDate = undefined;
            } else {
                timeEvent.inTimeRing = new EventTimeSpan({ months: 0, days: 0 });
            }

            GranitClient.updateTimeEvent(timeEvent.id, timeEvent as TimeEventDto).then((res) => {
                dispatch(updateTimeEventInRedux(res));
                closeForm();
            });
        }
    };

    const isTimeEventOk = isTimeEventValid(localTimeEvent, isTimeEventInTimeRingActive);
    const isKmEventOk = isKmEventValid(localKmEvent, isKmEventInKmToRingActive);

    const isInTimeRingValid = isTimeEventInTimeRingValid(localTimeEvent);

    const isTEChanged = isTimeEventChanged(localTimeEvent, isTimeEventInTimeRingActive, defaultTimeEvent);
    const isKMEChanged = isKmEventChanged(localKmEvent, isKmEventInKmToRingActive, defaultKmEvent);

    const onSubmit = () => {
        if (isTimeEventActive && isTimeEventOk) {
            if (!defaultTimeEvent) {
                addTimeEvent();
            } else {
                updateTimeEvent();
            }
        }

        if (isKmEventActive && isKmEventOk) {
            if (!defaultKmEvent) {
                addKmEvent();
            } else {
                updateKmEvent();
            }
        }
    };

    const isTEventInTimeRingValid = isTimeEventInTimeRingValid(localTimeEvent);

    const EventFormElement = (): ReactElement => {
        return (
            <div
                style={{
                    width: '100%',
                }}
            >
                <Grid container spacing={2}>
                    {!defaultTimeEvent &&
                        !defaultKmEvent &&
                        (entityType === EntityType.Expense ||
                            entityType === EntityType.Record ||
                            entityType === EntityType.Vehicle) && (
                            <Grid item xs={12}>
                                <Typography component="h6" variant="h6">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isTimeEventActive}
                                                    onChange={handleToggleTimeEventActive}
                                                />
                                            }
                                            label="Vremenski podsetnik"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isKmEventActive}
                                                    onChange={handleToggleKmEventActive}
                                                />
                                            }
                                            label="Kilometarski podsetnik"
                                        />
                                    </FormGroup>
                                    <hr />
                                </Typography>
                            </Grid>
                        )}
                    <Grid key={236} item xs={12} md={6}>
                        <TextField
                            fullWidth
                            id="filled-basic"
                            label="Naslov"
                            error={!localTimeEvent?.title && !localKmEvent.title}
                            name="title"
                            variant="outlined"
                            value={localTimeEvent?.title ?? localKmEvent.title ?? ''}
                            onChange={handleEventInputElementChange}
                        />
                    </Grid>
                    <Grid item xs={0} md={6} />
                    {isTimeEventActive && (
                        <>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">
                                        <Typography component="h6" variant="h6">
                                            Vremenski
                                        </Typography>
                                    </FormLabel>
                                    {localTimeEvent?.entityType !== EntityType.Expense && (
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={isTimeEventInTimeRingActive}
                                            name="radio-buttons-group"
                                            onChange={handleToggleTimeEventMode}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label="Zvoni za (interval)"
                                            />
                                            <FormControlLabel value={false} control={<Radio />} label="Zvoni datuma" />
                                        </RadioGroup>
                                    )}
                                </FormControl>
                                <hr />
                            </Grid>
                            {isTimeEventInTimeRingActive ? (
                                <>
                                    <Grid key={157} item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            id="filled-basic"
                                            label="Zvoni za"
                                            error={!isTEventInTimeRingValid}
                                            name="months"
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">mes.</InputAdornment>,
                                                inputProps: { min: 0 },
                                            }}
                                            variant="outlined"
                                            value={formatNumberTypeInput(localTimeEvent?.inTimeRing?.months)}
                                            onChange={handleTimeEventTimeSpanInputElementChange}
                                        />
                                    </Grid>
                                    <Grid key={158} item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            id="filled-basic"
                                            label="Zvoni za"
                                            error={!isTEventInTimeRingValid}
                                            name="days"
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">dana</InputAdornment>,
                                                inputProps: { min: 0 },
                                            }}
                                            variant="outlined"
                                            value={formatNumberTypeInput(localTimeEvent?.inTimeRing?.days)}
                                            onChange={handleTimeEventTimeSpanInputElementChange}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={12} md={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                                        <Stack spacing={3}>
                                            <DesktopDatePicker
                                                label="Datum zvonjenja"
                                                disableMaskedInput
                                                value={localTimeEvent?.ringDate ?? new Date()}
                                                inputFormat="DD/MM/YYYY"
                                                minDate={dayjs(new Date())}
                                                onChange={(newValue) => {
                                                    handleTimeEventRingDateChange(newValue?.toDate());
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </Grid>
                            )}
                            <Grid key={192} item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="filled-basic"
                                    label="Margina"
                                    name="marginInDays"
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">dana</InputAdornment>,
                                        inputProps: { min: 0 },
                                    }}
                                    variant="outlined"
                                    value={formatNumberTypeInput(localTimeEvent?.marginInDays)}
                                    onChange={handleTimeEventMarginInDaysChange}
                                />
                            </Grid>
                            {isTimeEventInTimeRingActive && (
                                <Grid key={1580} item xs={12} md={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                                        <Stack spacing={3}>
                                            <DesktopDatePicker
                                                label="Početni datum"
                                                disableMaskedInput
                                                value={localTimeEvent?.startingDate ?? new Date()}
                                                inputFormat="DD/MM/YYYY"
                                                minDate={dayjs(new Date(1990, 1, 1))}
                                                onChange={(newValue) => {
                                                    handleTimeEventStartDateChange(newValue?.toDate());
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </Grid>
                            )}
                        </>
                    )}
                    {isKmEventActive && (
                        <>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">
                                        <Typography component="h6" variant="h6">
                                            Kilometarski
                                        </Typography>
                                    </FormLabel>
                                    {localKmEvent?.entityType !== EntityType.Expense && (
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={isKmEventInKmToRingActive}
                                            name="radio-buttons-group"
                                            onChange={handleToggleKmEventMode}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Zvoni ZA (km)" />
                                            <FormControlLabel value={false} control={<Radio />} label="Zvoni NA (km)" />
                                        </RadioGroup>
                                    )}
                                </FormControl>
                                <hr />
                            </Grid>
                            {isKmEventInKmToRingActive ? (
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="filled-basic-km-1"
                                        label="Zvoni za"
                                        error={!localKmEvent?.inKmToRing}
                                        name="inKmToRing"
                                        type="number"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                            inputProps: { min: 0 },
                                        }}
                                        variant="outlined"
                                        value={formatNumberTypeInput(localKmEvent?.inKmToRing)}
                                        onChange={handleKmEventInputElementChange}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="filled-basic-km-2"
                                        label="Zvoni na"
                                        error={!localKmEvent?.kmWhenToRing}
                                        name="kmWhenToRing"
                                        type="number"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                            inputProps: { min: 0 },
                                        }}
                                        variant="outlined"
                                        value={formatNumberTypeInput(localKmEvent?.kmWhenToRing)}
                                        onChange={handleKmEventInputElementChange}
                                    />
                                </Grid>
                            )}
                            <Grid key={256} item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="filled-basic"
                                    label="Margina"
                                    name="kmMargin"
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                        inputProps: { min: 0 },
                                    }}
                                    variant="outlined"
                                    value={formatNumberTypeInput(localKmEvent?.kmMargin)}
                                    onChange={handleKmEventInputElementChange}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="filled-basic"
                            label="Opis podsetnika"
                            variant="outlined"
                            multiline
                            rows={6}
                            value={localTimeEvent?.description ?? localKmEvent.description ?? ''}
                            name="description"
                            onChange={handleEventInputElementChange}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    };

    const isDataValid =
        (isTimeEventActive && isTimeEventOk && (!isKmEventActive || (isKmEventOk && isKMEChanged)) && isTEChanged) ||
        (isKmEventActive && !isTimeEventActive && isKmEventOk && isKMEChanged);

    return (
        <>
            <main className="custom-form-main">
                <div className="custom-form-close" onClick={closeForm}>
                    x
                </div>
                <Paper className="custom-form-paper">
                    <Typography component="h4" variant="h4" align="center" style={{ marginBottom: 50 }}>
                        Podsetnik
                    </Typography>

                    <div style={{ height: 'calc(80vh - 200px)', overflowX: 'hidden', overflowY: 'auto', padding: 20 }}>
                        {EventFormElement()}
                    </div>
                    <div style={{ position: 'absolute', bottom: 20, right: 50, width: 200 }}>
                        <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                            disabled={!isDataValid}
                            onClick={() => {
                                onSubmit();
                            }}
                        >
                            SAČUVAJ
                        </Button>
                    </div>
                    {isTimeEventActive ? (
                        !isInTimeRingValid ? (
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 60,
                                    right: 40,
                                    width: 250,
                                    color: 'red',
                                    fontSize: 12,
                                    textAlign: 'end',
                                }}
                            >
                                Datum zvonjenja ne može biti u prošlosti
                            </div>
                        ) : (
                            <></>
                        )
                    ) : (
                        <></>
                    )}
                </Paper>
            </main>
        </>
    );
}
