/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DriverDto } from '../../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithUpdating } from '../../../Extensions/Exceptions';
import { updateDriver } from '../../Reducers/Drivers/DriversReducer';
import { changeCurrentEntity } from '../../Reducers/General/CurrentEntityReducer';

export const updateOldDriver = (driver: DriverDto | undefined, oldDriver: DriverDto) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    GranitClient.updateDriver(driver !== undefined ? (driver.id as number) : 0, driver as DriverDto).then(
        (result) => {
            dispatch(changeCurrentEntity(result));
            dispatch(updateDriver(driver as DriverDto, oldDriver));
        },
        (error) => {
            alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_DRIVERS);
            console.log(error.message);
        },
    );
};
