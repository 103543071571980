import { TruckDto } from '../General/AutoGeneratedAPI/clientApi';

export const isTruckSearched = (truck: TruckDto, searchTokens: string[]): boolean => {
    let numOfFinds = 0;
    let manufacturerTokens = truck.manufacturer?.toLowerCase().split(' ');
    let modelTokens = truck.model?.toLowerCase().split(' ');
    let licensePlateTokens = truck.licensePlateNum?.toLowerCase().split(' ');
    searchTokens.forEach((st) => {
        if (st !== '') {
            const mIndex = manufacturerTokens?.findIndex((mt) => mt.includes(st));
            const modelIndex = modelTokens?.findIndex((mt) => mt.includes(st));
            const lpIndex = licensePlateTokens?.findIndex((lpt) => lpt.includes(st));
            if (mIndex !== undefined && mIndex >= 0 && manufacturerTokens) {
                manufacturerTokens = [
                    ...manufacturerTokens?.slice(0, mIndex),
                    ...manufacturerTokens.slice(mIndex + 1, manufacturerTokens?.length),
                ];
                numOfFinds++;
            } else if (modelIndex !== undefined && modelIndex >= 0 && modelTokens) {
                modelTokens = [
                    ...modelTokens?.slice(0, modelIndex),
                    ...modelTokens.slice(modelIndex + 1, modelTokens?.length),
                ];
                numOfFinds++;
            } else if (lpIndex !== undefined && lpIndex >= 0 && licensePlateTokens) {
                licensePlateTokens = [
                    ...licensePlateTokens?.slice(0, lpIndex),
                    ...licensePlateTokens.slice(lpIndex + 1, licensePlateTokens?.length),
                ];
                numOfFinds++;
            }
        } else {
            numOfFinds++;
        }
    });

    return numOfFinds === searchTokens.length;
};
