import { KmEventDto, NotificationDto, NotificationMedia, TimeEventDto } from '../../../AutoGeneratedAPI/clientApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithFetching } from '../../../Extensions/Exceptions';

interface IInitialStateType {
    notifications: NotificationDto[] | null;
    timeEvents: TimeEventDto[] | null;
    kmEvents: KmEventDto[] | null;
}

// notifications: [
//     new NotificationDto({
//         ...new NotificationDto(),
//         id: 1,
//         closed: true,
//         seen: true,
//         entityType: EntityType.Expense,
//         entityId: 367,
//         notificationMedia: NotificationMedia.Transport,
//         title: 'Naslov podsetnika',
//         description: 'Ovo je opis ovog podsetnika. On kaže da treba neče',
//         userGroupId: 1002,
//     }),
//     new NotificationDto({
//         ...new NotificationDto(),
//         id: 2,
//         closed: false,
//         seen: false,
//         entityType: EntityType.Expense,
//         entityId: 368,
//         notificationMedia: NotificationMedia.Transport,
//         title: 'Naslov podsetnika',
//         description:
//             'Ovo je opis ovog podsetnika. On kaže da treba nečega da se setis i nesto da odradis. Nakon toga napravi novi podsetnik :D <3',
//         userGroupId: 1002,
//     }),
//     new NotificationDto({
//         ...new NotificationDto(),
//         id: 3,
//         closed: false,
//         seen: false,
//         entityType: EntityType.Expense,
//         entityId: 366,
//         notificationMedia: NotificationMedia.Transport,
//         title: 'Naslov podsetnika',
//         description: `Ovo je opis ovog podsetnika. On kaže da treba nečega da se setis i nesto da odradis. Nakon toga napravi novi podsetnik :D <3 Ovo je opis ovog podsetnika. On kaže da treba nečega da se setis i nesto da odradis. Nakon toga napravi novi podsetnik :D <3,
//             Ovo je opis ovog podsetnika. On kaže da treba nečega da se setis i nesto da odradis. Nakon toga napravi novi podsetnik :D <3 Ovo je opis ovog podsetnika. On kaže da treba nečega da se setis i nesto da odradis. Nakon toga napravi novi podsetnik :D <3`,
//         userGroupId: 1002,
//     }),
//     new NotificationDto({
//         ...new NotificationDto(),
//         id: 4,
//         closed: false,
//         seen: false,
//         entityType: EntityType.Expense,
//         entityId: 365,
//         notificationMedia: NotificationMedia.Transport,
//         title: 'Naslov podsetnika',
//         description:
//             'Ovo je opis ovog podsetnika. On kaže da treba nečega da se setis i nesto da odradis. Nakon toga napravi novi podsetnik :D <3',
//         userGroupId: 1002,
//     }),
// ],

const initialState: IInitialStateType = {
    notifications: null,
    timeEvents: null,
    kmEvents: null,
};

export const fetchInitialNotifications = createAsyncThunk(
    'notifications/fetchInitialNotifications',
    async (arg, thunkAPI: { getState: any }) => {
        const state = thunkAPI.getState();
        const myId = state.loggedEmployee.employee?.employee.id;

        const response = await GranitClient.getAllEmployeeNotifications(
            myId ?? 0,
            NotificationMedia.Transport,
            undefined,
            100,
        );

        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingNotifications: NotificationDto[] | null = thunkAPI.getState().notifications.notifications;

            return existingNotifications === null;
        },
    },
);

export const fetchInitialTimeEvents = createAsyncThunk(
    'notifications/fetchInitialTimeEvents',
    async (arg, thunkAPI: { getState: any }) => {
        const state = thunkAPI.getState();
        const myId = state.loggedEmployee.employee?.employee.id;

        const response = await GranitClient.getAllEmployeeTimeEvents(myId ?? 0, NotificationMedia.Transport);

        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingTimeEvents: TimeEventDto[] | null = thunkAPI.getState().notifications.timeEvents;

            return existingTimeEvents === null;
        },
    },
);

export const fetchInitialKmEvents = createAsyncThunk(
    'notifications/fetchInitialKmEvents',
    async (arg, thunkAPI: { getState: any }) => {
        const state = thunkAPI.getState();
        const myId = state.loggedEmployee.employee?.employee.id;

        const response = await GranitClient.getAllEmployeeKmEvents(myId ?? 0, NotificationMedia.Transport);

        return response;
    },
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        condition: (arg, thunkAPI: { getState: any }) => {
            const existingKmEvents: KmEventDto[] | null = thunkAPI.getState().notifications.kmEvents;

            return existingKmEvents === null;
        },
    },
);

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotifications(state, action) {
            state.notifications = action.payload ?? [];
        },
        addNotification(state, action) {
            state.notifications = (state.notifications ?? []).concat(action.payload);
        },
        addTimeEventToRedux(state, action) {
            state.timeEvents = (state.timeEvents ?? []).concat(action.payload);
        },
        updateTimeEventInRedux(state, action) {
            const currTEvents = state.timeEvents ?? [];
            const teIndex = currTEvents.findIndex((te) => {
                return te.id === action.payload.id;
            });
            state.timeEvents = [...currTEvents.slice(0, teIndex), action.payload, ...currTEvents.slice(teIndex + 1)];
        },
        addKmEventToRedux(state, action) {
            state.kmEvents = (state.kmEvents ?? []).concat(action.payload);
        },
        updateKmEventInRedux(state, action) {
            const currKmEvents = state.kmEvents ?? [];
            const kmeIndex = currKmEvents.findIndex((kme) => {
                return kme.id === action.payload.id;
            });
            state.kmEvents = [...currKmEvents.slice(0, kmeIndex), action.payload, ...currKmEvents.slice(kmeIndex + 1)];
        },
        updateOldNotification(state, action) {
            const currNotifications = state.notifications ?? [];
            const nIndex = currNotifications.findIndex((n) => {
                return n.id === action.payload.id;
            });
            state.notifications = [
                ...currNotifications.slice(0, nIndex),
                action.payload,
                ...currNotifications.slice(nIndex + 1),
            ];
        },
        removeNotification(state, action) {
            const currNotifications = state.notifications ?? [];
            const nIndex = currNotifications.findIndex((n) => {
                return n.id === action.payload.id;
            });
            state.notifications = [...currNotifications.slice(0, nIndex), ...currNotifications.slice(nIndex + 1)];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInitialNotifications.fulfilled, (state, action) => {
            state.notifications = action.payload ?? [];
        });
        builder.addCase(fetchInitialNotifications.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_NOTIFICATIONS);
        });
        builder.addCase(fetchInitialTimeEvents.fulfilled, (state, action) => {
            state.timeEvents = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialTimeEvents.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_NOTIFICATIONS);
        });
        builder.addCase(fetchInitialKmEvents.fulfilled, (state, action) => {
            state.kmEvents = action.payload.reverse() ?? [];
        });
        builder.addCase(fetchInitialKmEvents.rejected, () => {
            alert(ExceptionsWithFetching.EXCEPTION_FETCHING_NOTIFICATIONS);
        });
    },
});

export const {
    addNotifications,
    addNotification,
    removeNotification,
    updateOldNotification,
    addTimeEventToRedux,
    updateTimeEventInRedux,
    addKmEventToRedux,
    updateKmEventInRedux,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
