import { Modal, Typography, Button, Grid } from '@mui/material';
import React, { ReactElement } from 'react';
import './DeleteRec.css';

type DeleteRecModalProps = {
    open: boolean;
    deleteDoc(): void;
    title: string;
    closeModal(): void;
};

export default function DeleteRecModal({ open, deleteDoc, title, closeModal }: DeleteRecModalProps): ReactElement {
    const onClickDelete = () => {
        deleteDoc();
        closeModal();
    };

    return (
        <div>
            <Modal
                className="del-imp-doc-modal"
                open={open}
                aria-labelledby="delete-imp-docs"
                aria-describedby="delete important documents"
            >
                <div className="delete-modal-container">
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="h6">
                                Da li ste sigurni da želite da izbrišete {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" onClick={() => closeModal()}>
                                Ne
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="secondary" onClick={() => onClickDelete()}>
                                Da
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </div>
    );
}
