import React, { useEffect, useState } from 'react';
import { ReactElement, ChangeEvent } from 'react';
import { RecordType } from '../../Extensions/Extensions';
import { GranitClient } from '../../AutoGeneratedAPI/Extension';
import '../../Forms/Forms.css';
import { Button, Grid, Typography, Paper, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useSelector } from 'react-redux';
import { addNewRecord, updateOldRecord } from '../../Redux/Actions/General/RecordsAction';
import { deleteRecordDoc } from '../../Redux/Reducers/General/RecordsReducer';
import { RootState } from '../../Redux/store';
import {
    ClientRecordDto,
    RecordDocumentDto,
    RecordCategory,
    VehicleRecordDto,
    WorkingToolRecordDto,
    EmployeeRecordDto,
    IClientRecordDto,
    IEmployeeRecordDto,
    IVehicleRecordDto,
    IWorkingToolRecordDto,
    EntityType,
    IKmEventDto,
    ITimeEventDto,
    KmEventDto,
    NotificationMedia,
    TimeEventDto,
    UserScope,
    EventTimeSpan,
} from '../../AutoGeneratedAPI/clientApi';
import { useLocation, useParams } from 'react-router-dom';
import { isAppTypeClient, isAppTypeEmployee, isAppTypeVehicle } from '../../Extensions/Selectors/Selectors';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { addKmEventToRedux, addTimeEventToRedux } from '../../Redux/Reducers/Notifications/NotificationsReducer';
import { isKmEventValid, isTimeEventValid } from './ExpenseFormHelper';
import RecordFormElement from './RecordFormElement';
import ExpenseEventFormElement from './ExpenseEventFormElement';
import { EntitiesType } from '../../Extensions/Entities';
import { checkIfDataIsChanged } from './RecordFormHelper';

type RecordFormProps = {
    closeForm(): void;
    recType?: RecordType;
};

export default function RecordForm({ closeForm, recType }: RecordFormProps): ReactElement {
    const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
    const [currentRecord, setCurrentRecord] = useState<
        IVehicleRecordDto | IWorkingToolRecordDto | IEmployeeRecordDto | IClientRecordDto
    >(new VehicleRecordDto());
    const [oldRecord, setOldRecord] = useState<
        IVehicleRecordDto | IWorkingToolRecordDto | IEmployeeRecordDto | IClientRecordDto | undefined
    >(undefined);
    const [isUpdateForm, setIsUpdateForm] = useState<boolean>(false);
    const [currentTimeEvent, setCurrentTimeEvent] = useState<ITimeEventDto>(
        new TimeEventDto({
            ...new TimeEventDto(),
            notificationMedia: NotificationMedia.Transport,
            userScope: UserScope.Personal,
            entityType: EntityType.Record,
            isDone: false,
            startingDate: new Date(),
        }),
    );
    const [currentKmEvent, setCurrentKmEvent] = useState<IKmEventDto>(
        new KmEventDto({
            ...new KmEventDto(),
            notificationMedia: NotificationMedia.Transport,
            userScope: UserScope.Personal,
            entityType: EntityType.Record,
            isDone: false,
            startingDate: new Date(),
        }),
    );
    const [openEventForm, setOpenEventForm] = useState<boolean>(false);

    const [isTimeEventActive, setIsTimeEventActive] = useState<boolean>(false);
    const [isKmEventActive, setIsKmEventActive] = useState<boolean>(false);

    const [isTimeEventInTimeRingActive, setIsTimeEventInTimeRingActive] = useState<boolean>(false);
    const [isKmEventInKmToRingActive, setIsKmEventInKmToRingActive] = useState<boolean>(false);

    const userGroup = useAppSelector((state: RootState) => state.loggedEmployee.userGroup);

    const records = useAppSelector((state: RootState) => state.records.records);
    const importantDocuments = useAppSelector((state: RootState) => state.importantDocs.importantDocs);

    const location = useLocation();
    const path = location.pathname;

    const isEmployee = isAppTypeEmployee(path);
    const isVehicle = isAppTypeVehicle(path);
    const isClient = isAppTypeClient(path);

    const entity = useSelector((state: RootState) => state.currentEntity.entity);

    const dispatch = useAppDispatch();

    const { recId, impDocId } = useParams();

    useEffect(() => {
        if (records && recId && !isNaN(+recId)) {
            const rec = records?.find((rec) => rec.id === +recId);
            setCurrentRecord(rec ?? new VehicleRecordDto());
            setOldRecord(rec);
            setIsUpdateForm(true);
        } else if (importantDocuments && impDocId && !isNaN(+impDocId)) {
            const impDoc = importantDocuments?.find((impDoc) => impDoc.id === +impDocId);
            setCurrentRecord(impDoc ?? new VehicleRecordDto());
            setOldRecord(impDoc);
            setIsUpdateForm(true);
        }
    }, [recId, impDocId, records, importantDocuments]);

    const handleToogleEventForm = () => {
        setOpenEventForm(!openEventForm);
    };

    const handleToggleTimeEventActive = () => {
        setIsTimeEventActive(!isTimeEventActive);
    };

    const handleToggleKmEventActive = () => {
        setIsKmEventActive(!isKmEventActive);
    };

    const handleToggleTimeEventMode = () => {
        setIsTimeEventInTimeRingActive(!isTimeEventInTimeRingActive);
    };

    const handleToggleKmEventMode = () => {
        setIsKmEventInKmToRingActive(!isKmEventInKmToRingActive);
    };

    const handleFileInputChange = (selectorFiles: FileList | undefined): void => {
        const filesArray = selectorFiles !== undefined ? Array.from(selectorFiles).map((file) => file) : undefined;
        if (filesArray !== undefined) {
            if (selectedFiles.length <= 0) {
                setSelectedFiles(filesArray);
            } else {
                for (let i = 0; i < filesArray?.length; i++) {
                    setSelectedFiles((selectedFiles) => [...selectedFiles, filesArray[i]]);
                }
            }
        }
    };

    const handleFileDelete = (fileToDelete: File): void => {
        let newFiles: Array<File> = [];
        if (selectedFiles !== undefined) {
            newFiles = selectedFiles.filter((file) => file !== fileToDelete).map((f) => f);
            setSelectedFiles(newFiles);
        }
    };

    const handleInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setCurrentRecord({ ...currentRecord, [name]: value });
    };

    const handleValidFromChange = (date?: Date): void => {
        if (date) {
            setCurrentRecord({ ...currentRecord, validFrom: date as Date });
        }
    };

    const deleteFile = async (file: RecordDocumentDto) => {
        GranitClient.deleteRecordDocument(oldRecord?.id as number, file.id as number);
        await dispatch(deleteRecordDoc(oldRecord, file));
        setCurrentRecord({ ...currentRecord, documents: oldRecord?.documents });
    };

    const handleEventInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (value !== undefined) {
            setCurrentTimeEvent({ ...currentTimeEvent, [name]: value });
            setCurrentKmEvent({ ...currentKmEvent, [name]: value });
        }
    };

    const handleTimeEventTimeSpanInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = +target.value;
        const name = target.name;

        if (!isNaN(value) && value >= 0) {
            setCurrentTimeEvent({
                ...currentTimeEvent,
                inTimeRing: { ...currentTimeEvent.inTimeRing, [name]: value } as EventTimeSpan,
            });
        }
    };

    const handleTimeEventRingDateChange = (date?: Date): void => {
        if (date) {
            setCurrentTimeEvent({ ...currentTimeEvent, ringDate: date as Date });
        }
    };

    const handleTimeEventMarginInDaysChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = +target.value;

        if (!isNaN(value) && value >= 0) {
            setCurrentTimeEvent({ ...currentTimeEvent, marginInDays: value });
        }
    };

    const handleKmEventInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = +target.value;
        const name = target.name;

        if (!isNaN(value) && value >= 0) {
            setCurrentKmEvent({ ...currentKmEvent, [name]: value });
        }
    };

    const addKmEvent = (recId: number) => {
        if (userGroup && userGroup.id) {
            const kmEvent = currentKmEvent;
            kmEvent.entityId = recId;
            kmEvent.userGroupId = userGroup.id;

            if (isKmEventInKmToRingActive) {
                kmEvent.kmWhenToRing = undefined;
            } else {
                kmEvent.inKmToRing = undefined;
            }

            GranitClient.addKmEvent(kmEvent as KmEventDto).then((res) => {
                dispatch(addKmEventToRedux(res));
            });
        }
    };

    const addTimeEvent = (recId: number) => {
        if (userGroup && userGroup.id) {
            const timeEvent = currentTimeEvent;
            timeEvent.entityId = recId;
            timeEvent.userGroupId = userGroup.id;

            if (isTimeEventInTimeRingActive) {
                timeEvent.ringDate = undefined;
            } else {
                timeEvent.inTimeRing = new EventTimeSpan({ months: 0, days: 0 });
            }

            GranitClient.addTimeEvent(timeEvent as TimeEventDto).then((res) => {
                dispatch(addTimeEventToRedux(res));
            });
        }
    };

    const isTimeEventOk = isTimeEventValid(currentTimeEvent, isTimeEventInTimeRingActive);
    const isKmEventOk = isKmEventValid(currentKmEvent, isKmEventInKmToRingActive);

    const checkIfRequiredIsNotEmpty = (): boolean => {
        return currentRecord.title !== undefined && currentRecord.title !== '';
    };

    const onSubmit = async () => {
        const record = currentRecord;

        if (!record.validFrom) {
            record.validFrom = new Date();
        }

        if (recType === RecordType.ImportantDocument) {
            record.category = RecordCategory.ImportantDocs;
        }

        if (record !== undefined) {
            const rec = isClient
                ? new ClientRecordDto(record as IClientRecordDto)
                : isVehicle
                ? new VehicleRecordDto(record as IVehicleRecordDto)
                : isEmployee
                ? new EmployeeRecordDto(record as IEmployeeRecordDto)
                : new WorkingToolRecordDto(record as IWorkingToolRecordDto);
            if (isUpdateForm) {
                const recordId = await dispatch(updateOldRecord(rec, path, selectedFiles as Array<File>));

                if (isTimeEventActive && isTimeEventOk && recordId && openEventForm) {
                    addTimeEvent(recordId);
                }

                if (isKmEventActive && isKmEventOk && recordId && openEventForm) {
                    addKmEvent(recordId);
                }

                if (recordId !== 0) {
                    closeForm();
                }
            } else {
                const recordId = await dispatch(
                    addNewRecord(rec, entity as EntitiesType, path, selectedFiles as Array<File>),
                );

                if (isTimeEventActive && isTimeEventOk && recordId && openEventForm) {
                    addTimeEvent(recordId);
                }

                if (isKmEventActive && isKmEventOk && recordId && openEventForm) {
                    addKmEvent(recordId);
                }

                if (recordId !== 0) {
                    closeForm();
                }
            }
        }
    };

    const isEventDataValid =
        (isTimeEventActive && isTimeEventOk && (!isKmEventActive || isKmEventOk)) ||
        (isKmEventActive && !isTimeEventActive && isKmEventOk);

    const isDataChanged =
        ((!openEventForm && checkIfDataIsChanged(currentRecord, oldRecord, selectedFiles)) ||
            (openEventForm && isEventDataValid)) &&
        checkIfRequiredIsNotEmpty();

    const title =
        recType === RecordType.ImportantDocument
            ? !isUpdateForm
                ? 'Ubacite novi dokument'
                : 'Promenite dokument'
            : !isUpdateForm
            ? 'Ubacite novu evidenciju'
            : 'Promenite evidenciju';

    return (
        <>
            <main className="custom-form-main">
                <div className="custom-form-close" onClick={closeForm}>
                    x
                </div>
                <Paper className="custom-form-paper">
                    <Typography component="h4" variant="h4" align="center" style={{ marginBottom: 50 }}>
                        {title}
                    </Typography>

                    <div style={{ height: 'calc(80vh - 200px)', overflowX: 'hidden', overflowY: 'auto', padding: 20 }}>
                        <RecordFormElement
                            deleteFile={deleteFile}
                            handleFileDelete={handleFileDelete}
                            handleFileInputChange={handleFileInputChange}
                            handleInputElementChange={handleInputElementChange}
                            handleValidFromChange={handleValidFromChange}
                            selectedFiles={selectedFiles}
                            currentRecord={currentRecord}
                        >
                            {!isUpdateForm && (
                                <>
                                    <Grid item xs={12}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={openEventForm}
                                                        onChange={handleToogleEventForm}
                                                    />
                                                }
                                                label="Ubacite podsetnik"
                                            />
                                        </FormGroup>
                                    </Grid>
                                    {openEventForm && (
                                        <ExpenseEventFormElement
                                            handleEventInputElementChange={handleEventInputElementChange}
                                            handleKmEventInputElementChange={handleKmEventInputElementChange}
                                            handleTimeEventMarginInDaysChange={handleTimeEventMarginInDaysChange}
                                            handleTimeEventTimeSpanInputElementChange={
                                                handleTimeEventTimeSpanInputElementChange
                                            }
                                            handleTimeEventRingDateChange={handleTimeEventRingDateChange}
                                            isKmEventActive={isKmEventActive}
                                            isTimeEventActive={isTimeEventActive}
                                            currentKmEvent={currentKmEvent}
                                            currentTimeEvent={currentTimeEvent}
                                            isKmEventInKmRingActive={isKmEventInKmToRingActive}
                                            isTimeEventInTimeRingActive={isTimeEventInTimeRingActive}
                                            toggleKmEventMode={handleToggleKmEventMode}
                                            toggleTimeEventMode={handleToggleTimeEventMode}
                                            toggleKmEventActive={handleToggleKmEventActive}
                                            toggleTimeEventActive={handleToggleTimeEventActive}
                                        />
                                    )}
                                </>
                            )}
                        </RecordFormElement>
                    </div>
                    <div style={{ position: 'absolute', bottom: 20, right: 50, width: 200 }}>
                        <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                            disabled={!isDataChanged}
                            onClick={() => {
                                onSubmit();
                            }}
                        >
                            SAČUVAJ
                        </Button>
                    </div>
                </Paper>
            </main>
        </>
    );
}
