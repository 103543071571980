import { addDays } from 'date-fns';
import { GetStartOfTheWeek } from '../../Common/DateHandler';
import { TimelineViewType } from '../../General/Extensions/Extensions';

export const handleDateChange = (
    timelineViewType: TimelineViewType,
    currDate: Date,
    date?: Date,
    minus?: boolean,
): { newCurrDate?: Date; startDate?: Date; endDate?: Date } => {
    let currentDate;
    let startingDate;
    let endingDate;
    if (date) {
        currentDate = date;
        if (timelineViewType === TimelineViewType.Day) {
            startingDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            endingDate = addDays(startingDate, 1);
        } else if (timelineViewType === TimelineViewType.Week) {
            const startOfTheWeek = GetStartOfTheWeek(date);
            startingDate = startOfTheWeek;
            endingDate = addDays(startOfTheWeek, 7);
        }
    } else {
        if (minus !== undefined) {
            if (timelineViewType === TimelineViewType.Day) {
                if (minus) {
                    const newDate = addDays(currDate, -1);

                    currentDate = newDate;

                    startingDate = newDate;
                    endingDate = addDays(newDate, 1);
                } else {
                    const newDate = addDays(currDate, 1);

                    currentDate = newDate;

                    startingDate = newDate;
                    endingDate = addDays(newDate, 1);
                }
            } else if (timelineViewType === TimelineViewType.Week) {
                if (minus) {
                    const newDate = addDays(currDate, -7);
                    const startOfTheWeek = GetStartOfTheWeek(newDate);

                    currentDate = newDate;

                    startingDate = startOfTheWeek;
                    endingDate = addDays(startOfTheWeek, 7);
                } else {
                    const newDate = addDays(currDate, 7);
                    const startOfTheWeek = GetStartOfTheWeek(newDate);

                    currentDate = newDate;

                    startingDate = startOfTheWeek;
                    endingDate = addDays(startOfTheWeek, 7);
                }
            }
        }
    }
    return {
        newCurrDate: currentDate,
        startDate: startingDate,
        endDate: endingDate,
    };
};
