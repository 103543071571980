import { Typography } from '@mui/material';
import React, { ReactElement, ReactNode, useState } from 'react';
import { ICompositionDto } from '../../../AutoGeneratedAPI/clientApi';
import { useAppSelector } from '../../../hooks/hooks';
import { RootState } from '../../../Redux/store';
import VehiclesElement from '../Elements/VehiclesElement';
import CollapsableFormSection from '../../../../Common/CommonComponents/CollapsableFormSection';
import { useSearchParams } from 'react-router-dom';

type MainVehicleSectionProps = {
    composition: ICompositionDto;
    handleAddVehicleToComposition(truck: number): void;
};

export default function MainVehicleSection({
    composition,
    handleAddVehicleToComposition,
}: MainVehicleSectionProps): ReactElement {
    const [searchParams] = useSearchParams();

    const [expand, setExpand] = useState<boolean>(
        (!searchParams || !searchParams.get('twId') || searchParams.get('twId') === '0') &&
            (!composition || !composition.mainVehicleId)
            ? true
            : false,
    );

    const trucks = useAppSelector((state: RootState) => state.vehicles.trucks);

    const handleInitialExpandChange = (newExpand: boolean) => {
        setExpand(newExpand);
    };

    const handleVehicleAdding = (truck: number): void => {
        if (composition.mainVehicleId !== undefined && composition.mainVehicleId === truck) {
            handleAddVehicleToComposition(truck);
            handleInitialExpandChange(true);
        } else {
            handleAddVehicleToComposition(truck);
            handleInitialExpandChange(false);
        }
    };

    const titleComponent = (): ReactNode => {
        return (
            <>
                <Typography component="p" variant="body1" align="left" style={{ display: 'inline' }}>
                    {composition.mainVehicleId > 0 ? 'Kamion' : 'Kamioni'}
                </Typography>
                {composition.mainVehicleId > 0 && (
                    <Typography
                        component="p"
                        variant="body1"
                        align="left"
                        style={{ display: 'inline', fontWeight: 'bold' }}
                    >
                        : {trucks?.find((tr) => tr.id === composition.mainVehicleId)?.licensePlateNum}
                    </Typography>
                )}
            </>
        );
    };

    return (
        <CollapsableFormSection
            initialExpanded={expand}
            titleComponents={titleComponent()}
            handleInitialExpandChange={handleInitialExpandChange}
        >
            <VehiclesElement
                handleVehicleAdding={handleVehicleAdding}
                currentVehicle={composition.mainVehicleId as number}
            />
        </CollapsableFormSection>
    );
}
