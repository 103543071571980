import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import * as React from 'react';
import { useState, useEffect, ReactElement } from 'react';
import workingtool_logo from '../../General/assets/img/workingtool_logo.png';
import workingmachine_logo from '../../General/assets/img/workingmachine_logo.jpg';
import officemachine_logo from '../../General/assets/img/officemachine_logo.jpg';
import handmachine_logo from '../../General/assets/img/handmachine_logo.jpg';
import { WorkingToolDto, WorkingMachineDto, MachineType } from '../../General/AutoGeneratedAPI/clientApi';
import '../../General/Catalogue/Catalogue.css';
import { PROFILE_IMAGE } from '../../Common/Consts';
import ImageComponent from '../../Common/CommonComponents/ImageComponent';
import { getBaseServerLink } from '../../General/AutoGeneratedAPI/Extension';

interface WorkingToolsCardContainerProps {
    workingTool: WorkingToolDto | WorkingMachineDto;
    isCalendar?: boolean;
}

export default function WorkingToolsCardContainer({
    workingTool,
    isCalendar,
}: WorkingToolsCardContainerProps): ReactElement {
    const [profileImageLink, setProfileImageLink] = useState<string>('');

    useEffect(() => {
        const docs = workingTool.documents;
        if (workingTool && docs && docs.length > 0) {
            const doc = docs.find((d) => {
                return d?.category === PROFILE_IMAGE;
            });
            if (doc?.requestPaths) {
                setProfileImageLink(doc.requestPaths[0]);
            }
        }
    });
    return (
        <Card className={isCalendar ? 'calendar-card' : 'card'}>
            <CardActionArea>
                <ImageComponent
                    src={
                        profileImageLink !== ''
                            ? getBaseServerLink(profileImageLink)
                            : (workingTool as WorkingMachineDto).machineType === MachineType.WorkingMachine
                            ? workingmachine_logo
                            : (workingTool as WorkingMachineDto).machineType === MachineType.OfficeMachine
                            ? officemachine_logo
                            : (workingTool as WorkingMachineDto).machineType === MachineType.HandMachine
                            ? handmachine_logo
                            : (workingTool as WorkingMachineDto).machineType === MachineType.WorkingTool
                            ? workingtool_logo
                            : ''
                    }
                    placeholder={''}
                    isCalendar={isCalendar}
                />
                <CardContent>
                    <Typography
                        gutterBottom
                        variant={isCalendar ? 'body2' : 'subtitle1'}
                        component="p"
                        className="catalogue-card-text"
                    >
                        {workingTool.manufacturer} {workingTool.model}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{ display: isCalendar ? 'none' : 'default' }}
                        className="catalogue-card-text"
                    >
                        {workingTool.nickname ?? 'Nema spec. naziva'}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
