import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { concatImpDocs, setInitialImpDocs } from '../../../Redux/Reducers/General/ImportantDocsReducer';
import { ImportantDocsSearchTokenModel } from '../../models/RecordSearchTokenModel';
import { isAppTypeDriver, isAppTypeVehicle } from '../../Selectors/Selectors';
import { fetchEmployeeImpDocs, fetchVehicleImpDocs } from './API';

type ImpDocSearchType = {
    loading: boolean;
    error: boolean;
    hasMore: boolean;
};

export default function useImpDocsSearch(entityId: number, query: ImportantDocsSearchTokenModel): ImpDocSearchType {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const location = useLocation();
    const path = location.pathname;

    const IsVehicle = isAppTypeVehicle(path);
    const IsDriver = isAppTypeDriver(path);

    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        setError(false);
        IsVehicle
            ? fetchVehicleImpDocs(entityId, query).then(
                  (res) => {
                      if (query.lastEntityId && query.lastEntityId > 0) {
                          dispatch(concatImpDocs(res));
                      } else {
                          dispatch(setInitialImpDocs(res));
                      }
                      setHasMore(res.length > 0);
                      setLoading(false);
                  },
                  (error) => {
                      setError(true);
                      console.log(error.message);
                  },
              )
            : IsDriver
            ? fetchEmployeeImpDocs(entityId, query).then(
                  (res) => {
                      if (query.lastEntityId && query.lastEntityId > 0) {
                          dispatch(concatImpDocs(res));
                      } else {
                          dispatch(setInitialImpDocs(res));
                      }
                      setHasMore(res.length > 0);
                      setLoading(false);
                  },
                  (error) => {
                      setError(true);
                      console.log(error.message);
                  },
              )
            : console.log('Ne postoje evidencije za ovaj tip entiteta');
    }, [query]);

    useEffect(() => {
        dispatch(setInitialImpDocs([]));
    }, [query.numberOfEntitiesToReturn, query.endingDate, query.searchText, query.startingDate]);

    return { loading, error, hasMore };
}
