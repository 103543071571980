/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { InvoiceDto } from '../../../AutoGeneratedAPI/clientApi';
import { GranitClient } from '../../../AutoGeneratedAPI/Extension';
import { ExceptionsWithAdding, ExceptionsWithDeleting, ExceptionsWithUpdating } from '../../../Extensions/Exceptions';
import { addInvoiceToBeginning, deleteOldInvoice, updateInvoice } from '../../Reducers/Invoice/InvoiceReducer';

export const updateOldInvoice = (invoice: InvoiceDto | undefined) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    GranitClient.updateInvoice(invoice !== undefined ? (invoice.id as number) : 0, invoice as InvoiceDto).then(
        (result) => {
            dispatch(updateInvoice(result as InvoiceDto));
        },
        (error) => {
            alert(ExceptionsWithUpdating.EXCEPTION_UPDATING_INVOICE);
            console.log(error.message);
        },
    );
};

export const addNewInvoice = (clientId: number, invoice: InvoiceDto | undefined) => (
    dispatch: (arg0: { payload: any; type: string }) => void,
) => {
    GranitClient.addNewInvoice(clientId, invoice as InvoiceDto).then(
        (result) => {
            dispatch(addInvoiceToBeginning(result));
        },
        (error) => {
            alert(ExceptionsWithAdding.EXCEPTION_ADDING_INVOICE);
            console.log(error.message);
        },
    );
};

export const deleteInvoice = (invoiceId: number) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
    GranitClient.deleteInvoice(invoiceId).then(
        () => {
            dispatch(deleteOldInvoice(invoiceId));
        },
        (error) => {
            alert(ExceptionsWithDeleting.EXCEPTION_DELETING_INVOICE);
            console.log(error.message);
        },
    );
};
