import {
    CompositionDto,
    ShipmentDto,
    StopDto,
    TransportWarrantDto,
    TransportWarrantItemDto,
} from '../../../AutoGeneratedAPI/clientApi';
import { createSlice } from '@reduxjs/toolkit';
import { DistanceDuration } from '../../../../Common/Common';

interface IInitialStateType {
    transportWarrant: TransportWarrantDto;
    composition: CompositionDto;
    driversAzureObjIds: string[];
    currentTwInitialTwItems: TransportWarrantItemDto[];
    currentTwInitialShipments: ShipmentDto[];
    currentTwInitialStops: StopDto[];
    currentTwStopsArrangement: number[];
    stopsDistances: DistanceDuration[];
    stopsDurations: DistanceDuration[];
    unselectedShipmentsFromTW: ShipmentDto[];
    selectedShipmentForTW: ShipmentDto | undefined;
    preselectedShipmentForTW: ShipmentDto | undefined;
    twDate: Date;
    unloadingTime: Date | undefined;
    areStopsChanged: boolean;
}

const initialState: IInitialStateType = {
    transportWarrant: new TransportWarrantDto({
        ...new TransportWarrantDto(),
        estEndingTime: new Date(),
        estStartingTime: new Date(),
        actEndingTime: new Date(),
        actStartingTime: new Date(),
    }),
    composition: new CompositionDto(),
    driversAzureObjIds: [],
    currentTwInitialTwItems: [],
    currentTwInitialShipments: [],
    currentTwInitialStops: [],
    currentTwStopsArrangement: [],
    stopsDistances: [],
    stopsDurations: [],
    unselectedShipmentsFromTW: [],
    selectedShipmentForTW: undefined,
    preselectedShipmentForTW: undefined,
    twDate: new Date(),
    unloadingTime: undefined,
    areStopsChanged: false,
};

const twFormSlice = createSlice({
    name: 'transportWarrantForm',
    initialState,
    reducers: {
        addUnselectedShipmentsForTw(state, action) {
            state.unselectedShipmentsFromTW = action.payload ?? [];
        },
        addUnselectedShipmentForTw(state, action) {
            if (!state.unselectedShipmentsFromTW.includes(action.payload)) {
                state.unselectedShipmentsFromTW = [...(state.unselectedShipmentsFromTW ?? []), action.payload];
                if (state.preselectedShipmentForTW === action.payload) {
                    state.preselectedShipmentForTW = undefined;
                }
            }
        },
        emptyUnselectedShipmentsForTw(state) {
            state.unselectedShipmentsFromTW = [];
        },
        removeUnselectedShipmentForTw(state, action) {
            const oldShipmentId = action.payload;
            const shipments = state.unselectedShipmentsFromTW ?? [];
            const shipIndex = shipments.findIndex((ship) => {
                return ship.id === oldShipmentId;
            });

            if (shipIndex >= 0) {
                state.unselectedShipmentsFromTW = [...shipments.slice(0, shipIndex), ...shipments.slice(shipIndex + 1)];
            }
        },
        addPreselectedShipmentForTw(state, action) {
            state.preselectedShipmentForTW = action.payload;
        },
        unselectPreselectedShipmentForTw(state) {
            state.preselectedShipmentForTW = undefined;
        },
        addSelectedShipmentForTw(state, action) {
            state.selectedShipmentForTW = action.payload;
        },
        unselectSelectedShipmentForTw(state) {
            state.selectedShipmentForTW = undefined;
        },
        addInitialShipmentsForTw(state, action) {
            state.currentTwInitialShipments = action.payload ?? [];
        },
        addInitialShipmentForTw(state, action) {
            if (!state.currentTwInitialShipments.includes(action.payload)) {
                state.currentTwInitialShipments = [...(state.currentTwInitialShipments ?? []), action.payload];
            }
        },
        emptyInitialShipmentsForTw(state) {
            state.currentTwInitialShipments = [];
        },
        removeInitialShipmentForTw(state, action) {
            const oldShipmentId = action.payload;
            const shipments = state.currentTwInitialShipments ?? [];
            const shipIndex = shipments.findIndex((ship) => {
                return ship.id === oldShipmentId;
            });

            if (shipIndex >= 0) {
                state.currentTwInitialShipments = [...shipments.slice(0, shipIndex), ...shipments.slice(shipIndex + 1)];
            }
        },
        replaceInitialShipmentForTw(state, action) {
            const oldShipment = action.payload;
            const oldShipmentId = oldShipment.id;
            const shipments = state.currentTwInitialShipments ?? [];
            const shipIndex = shipments.findIndex((ship) => {
                return ship.id === oldShipmentId;
            });

            if (shipIndex >= 0) {
                state.currentTwInitialShipments = [
                    ...shipments.slice(0, shipIndex),
                    oldShipment,
                    ...shipments.slice(shipIndex + 1),
                ];
            }
        },
        addInitialStopsForTw(state, action) {
            state.currentTwInitialStops = action.payload ?? [];
        },
        addInitialStopForTw(state, action) {
            if (!state.currentTwInitialStops.includes(action.payload)) {
                state.currentTwInitialStops = [...(state.currentTwInitialStops ?? []), action.payload];
            }
        },
        emptyInitialStopsForTw(state) {
            state.currentTwInitialStops = [];
        },
        removeInitialStopForTw(state, action) {
            const oldStopId = action.payload;
            const stops = state.currentTwInitialStops ?? [];
            const stopIndex = stops.findIndex((stop) => {
                return stop.id === oldStopId;
            });

            if (stopIndex >= 0) {
                state.currentTwInitialStops = [...stops.slice(0, stopIndex), ...stops.slice(stopIndex + 1)];
            }
        },
        replaceInitialStopForTw(state, action) {
            const oldStop = action.payload;
            const oldStopId = oldStop.id;
            const stops = state.currentTwInitialStops ?? [];
            const stopIndex = stops.findIndex((stop) => {
                return stop.id === oldStopId;
            });

            if (stopIndex >= 0) {
                state.currentTwInitialStops = [...stops.slice(0, stopIndex), oldStop, ...stops.slice(stopIndex + 1)];
            }
        },
        addStopsArrangementForTw(state, action) {
            state.currentTwStopsArrangement = action.payload ?? [];
        },
        addStopForTwArrangement(state, action) {
            state.currentTwStopsArrangement = [...(state.currentTwStopsArrangement ?? []), action.payload];
        },
        removeInitialStopFromArrangement(state, action) {
            const oldStopId = action.payload;
            const stopsIds = state.currentTwStopsArrangement;
            const oldStopIdIndex = (stopsIds ?? []).findIndex((a) => a === oldStopId);
            if (oldStopIdIndex >= 0) {
                state.currentTwStopsArrangement = [
                    ...stopsIds.slice(0, oldStopIdIndex),
                    ...stopsIds.slice(oldStopIdIndex + 1),
                ];
            }
        },
        emptyInitialStopsFromArrangement(state) {
            state.currentTwStopsArrangement = [];
        },
        addInitialTWItemsForTw(state, action) {
            state.currentTwInitialTwItems = action.payload ?? [];
        },
        addInitialTWItemForTw(state, action) {
            if (!state.currentTwInitialTwItems.includes(action.payload)) {
                state.currentTwInitialTwItems = [...(state.currentTwInitialTwItems ?? []), action.payload];
            }
        },
        emptyInitialTWItemsForTw(state) {
            state.currentTwInitialTwItems = [];
        },
        removeInitialTWItemForTw(state, action) {
            const oldTwItemId = action.payload;
            const twItems = state.currentTwInitialTwItems ?? [];
            const twItemIndex = twItems.findIndex((twItem) => {
                return twItem.id === oldTwItemId;
            });

            if (twItemIndex >= 0) {
                state.currentTwInitialTwItems = [...twItems.slice(0, twItemIndex), ...twItems.slice(twItemIndex + 1)];
            }
        },
        replaceShipmentInInitialTWItem(state, action) {
            const newShipment = action.payload;
            const newShipmentId = newShipment.id;
            const twItems = state.currentTwInitialTwItems ?? [];
            const twItemIndex = twItems.findIndex((twItem) => {
                return twItem.shipmentId === newShipmentId;
            });
            const twItem = twItems.find((twItem) => {
                return twItem.shipmentId === newShipmentId;
            });

            if (twItemIndex >= 0 && twItem) {
                twItem.shipment = newShipment;
                state.currentTwInitialTwItems = [
                    ...twItems.slice(0, twItemIndex),
                    twItem,
                    ...twItems.slice(twItemIndex + 1),
                ];
            }
        },
        addStopsDistances(state, action) {
            state.stopsDistances = action.payload ?? [];
        },
        addStopsDurations(state, action) {
            state.stopsDurations = action.payload ?? [];
        },
        addDriverAzureObjIdsForTw(state, action) {
            state.driversAzureObjIds = action.payload ?? [];
        },
        addDriverAzureObjIdForTw(state, action) {
            if (!state.driversAzureObjIds.includes(action.payload)) {
                state.driversAzureObjIds = [...(state.driversAzureObjIds ?? []), action.payload];
            }
        },
        emptyDriverAzureObjIdsForTw(state) {
            state.driversAzureObjIds = [];
        },
        removeDriverAzureObjIdForTw(state, action) {
            const oldDriverAzureObjId = action.payload;
            const driverAzureObjectIds = state.driversAzureObjIds ?? [];
            const driverAzureObjIdIndex = driverAzureObjectIds.findIndex((driverAzObjId) => {
                return driverAzObjId === oldDriverAzureObjId;
            });

            if (driverAzureObjIdIndex >= 0) {
                state.driversAzureObjIds = [
                    ...driverAzureObjectIds.slice(0, driverAzureObjIdIndex),
                    ...driverAzureObjectIds.slice(driverAzureObjIdIndex + 1),
                ];
            }
        },
        setTwDate(state, action) {
            state.twDate = action.payload;
        },
        setUnloadingTime(state, action) {
            state.unloadingTime = action.payload;
        },
        setTransportWarrant(state, action) {
            state.transportWarrant = action.payload;
        },
        resetTransportWarrant(state) {
            state.transportWarrant = new TransportWarrantDto({
                ...new TransportWarrantDto(),
                estEndingTime: new Date(),
                estStartingTime: new Date(),
                actEndingTime: new Date(),
                actStartingTime: new Date(),
            });
        },
        setComposition(state, action) {
            state.composition = action.payload;
        },
        setAreStopsChanged(state, action) {
            state.areStopsChanged = action.payload;
        },
    },
});

export const {
    addUnselectedShipmentsForTw,
    addUnselectedShipmentForTw,
    addPreselectedShipmentForTw,
    unselectPreselectedShipmentForTw,
    addSelectedShipmentForTw,
    unselectSelectedShipmentForTw,
    emptyUnselectedShipmentsForTw,
    removeUnselectedShipmentForTw,
    addInitialShipmentsForTw,
    addInitialShipmentForTw,
    emptyInitialShipmentsForTw,
    removeInitialShipmentForTw,
    replaceInitialShipmentForTw,
    addInitialStopsForTw,
    addInitialStopForTw,
    emptyInitialStopsForTw,
    removeInitialStopForTw,
    replaceInitialStopForTw,
    addStopsArrangementForTw,
    addStopForTwArrangement,
    removeInitialStopFromArrangement,
    emptyInitialStopsFromArrangement,
    addInitialTWItemsForTw,
    addInitialTWItemForTw,
    emptyInitialTWItemsForTw,
    removeInitialTWItemForTw,
    replaceShipmentInInitialTWItem,
    addStopsDistances,
    addStopsDurations,
    addDriverAzureObjIdsForTw,
    addDriverAzureObjIdForTw,
    emptyDriverAzureObjIdsForTw,
    removeDriverAzureObjIdForTw,
    setTwDate,
    setUnloadingTime,
    setTransportWarrant,
    setComposition,
    resetTransportWarrant,
    setAreStopsChanged,
} = twFormSlice.actions;

export default twFormSlice.reducer;
