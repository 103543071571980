import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';
import Calendar from '../Calendar/Calendar';
import Charts from '../Charts/Charts';
import Catalogue from '../General/Catalogue/Catalogue';
import { routes } from '../Common/Routes';
import Profile from '../General/Profile/ProfilePage/Profile';
import TransportWarrantReport from '../Reports/TransportWarrantReport';
import AdminLayout from './Layouts/AdminLayout';
import DefaultLayout from './Layouts/DefaultLayout';
import MainMenu from './MainMenu';
import { cataloguePages, profilePages } from './Pages/Pages';

export default function AppRouter(): ReactElement {
    return (
        <div>
            <Routes>
                <Route path="/" element={<DefaultLayout />}>
                    <Route index element={<MainMenu />} />
                    <Route path={routes.calendar} element={<AdminLayout />}>
                        <Route index element={<Calendar />} />
                    </Route>
                    <Route path={routes.charts} element={<Charts />} />
                    <Route path={routes.twPrint} element={<TransportWarrantReport />} />
                    <Route path="catalogue" element={<AdminLayout />}>
                        <Route index element={<Catalogue />} />
                        {cataloguePages.map((cp) => {
                            return <Route key={cp.path} path={cp.path} element={<Catalogue />} />;
                        })}
                    </Route>
                    <Route path="/profile/*" element={<AdminLayout />}>
                        <Route index element={<Profile />} />
                        {profilePages.map((pp) => {
                            return <Route key={pp.path} path={pp.path} element={<Profile />} />;
                        })}
                    </Route>
                </Route>
            </Routes>
        </div>
    );
}
