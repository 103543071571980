import React, { ReactElement, useMemo, useState } from 'react';
import { getPercentage } from '../../../../Common/Common';
import { CompareTwoTimes, formatNumberOnTwoDigits, getTimeDifference, TimeSpan } from '../../../../Common/DateHandler';
import { useAppSelector } from '../../../hooks/hooks';
import { RootState } from '../../../Redux/store';
import { getTimeStamps } from './TransportWarrantTimelineViewHelper';
import {
    TWTimelineViewContainer,
    TWTimelineViewActualTimeElement,
    TWTimelineViewTimestampElement,
    TWTimelineViewEstimatedTimeElement,
} from './TransportWarrantTimelineViewStyled';
import { Tooltip } from '@mui/material';
import { addSeconds } from 'date-fns';

export default function FinishedTwTimelineViewElement(): ReactElement {
    const [timeStamps, setTimeStamps] = useState<TimeSpan[]>([]);

    const transportWarrant = useAppSelector((state: RootState) => state.twFormData.transportWarrant);
    const currShipments = useAppSelector((state: RootState) => state.twFormData.currentTwInitialShipments);

    const loadingTimeInSeconds = useMemo(() => {
        return currShipments.reduce((acc, sh) => {
            const diffTimeSpan = getTimeDifference(sh.startingStop.endingTime, sh.startingStop.startingTime);
            const loadingTimeInMinutes = diffTimeSpan.hours * 60 + diffTimeSpan.minutes;

            return acc + loadingTimeInMinutes * 60;
        }, 0);
    }, [currShipments]);

    const estStartingTime = useMemo(() => {
        return addSeconds(transportWarrant.estStartingTime, 0 - loadingTimeInSeconds);
    }, [loadingTimeInSeconds]);

    const actStartingTime = useMemo(() => {
        return addSeconds(transportWarrant.actStartingTime, 0 - loadingTimeInSeconds);
    }, [loadingTimeInSeconds]);

    const startingTime = useMemo(() => {
        return CompareTwoTimes(estStartingTime, actStartingTime) ? estStartingTime : actStartingTime;
    }, [estStartingTime, actStartingTime]);

    const endingTime = useMemo(() => {
        const estEndingTime = transportWarrant.estEndingTime;
        const actEndingTime = transportWarrant.actEndingTime;

        return !CompareTwoTimes(estEndingTime, actEndingTime) ? estEndingTime : actEndingTime;
    }, []);

    const totalDuration = useMemo(() => {
        const maxDurationTimeSpan = getTimeDifference(startingTime, endingTime);

        const maxDurationInSeconds = (maxDurationTimeSpan.hours * 60 + maxDurationTimeSpan.minutes) * 60;

        const tStamps = getTimeStamps(startingTime, maxDurationInSeconds, 0);

        setTimeStamps(tStamps);

        return maxDurationInSeconds;
    }, [transportWarrant, startingTime]);

    const estimatedDuration = useMemo(() => {
        const estimatedDurationTimeSpan = getTimeDifference(
            transportWarrant.estEndingTime,
            addSeconds(transportWarrant.estStartingTime, 0 - loadingTimeInSeconds),
        );
        return (estimatedDurationTimeSpan.hours * 60 + estimatedDurationTimeSpan.minutes) * 60;
    }, [loadingTimeInSeconds]);

    const actualDuration = useMemo(() => {
        const actualDurationTimeSpan = getTimeDifference(
            transportWarrant.actEndingTime,
            addSeconds(transportWarrant.actStartingTime, 0 - loadingTimeInSeconds),
        );
        return (actualDurationTimeSpan.hours * 60 + actualDurationTimeSpan.minutes) * 60;
    }, [loadingTimeInSeconds]);

    return (
        <TWTimelineViewContainer>
            {timeStamps.map((ts) => {
                const diffInSeconds =
                    ((ts.hours - startingTime.getHours()) * 60 + ts.minutes - startingTime.getMinutes()) * 60;
                return (
                    <TWTimelineViewTimestampElement
                        key={ts.hours + ', ' + ts.minutes + ',' + diffInSeconds}
                        $left={getPercentage(totalDuration, diffInSeconds)}
                    >
                        {formatNumberOnTwoDigits(ts.hours)}:{formatNumberOnTwoDigits(ts.minutes)}
                    </TWTimelineViewTimestampElement>
                );
            })}
            <Tooltip title="Predviđeno trajanje transporta sa utovarom">
                <TWTimelineViewEstimatedTimeElement
                    key={Math.random()}
                    $left={
                        !CompareTwoTimes(transportWarrant.estStartingTime, transportWarrant.actStartingTime)
                            ? getPercentage(
                                  totalDuration,
                                  getTimeDifference(estStartingTime, startingTime).hours * 3600 +
                                      getTimeDifference(estStartingTime, startingTime).minutes * 60,
                              )
                            : 0
                    }
                    $width={getPercentage(totalDuration, estimatedDuration ?? 0)}
                />
            </Tooltip>
            <Tooltip title="Pravo trajanje transporta sa utovarom">
                <TWTimelineViewActualTimeElement
                    key={Math.random()}
                    $left={
                        CompareTwoTimes(transportWarrant.estStartingTime, transportWarrant.actStartingTime)
                            ? getPercentage(
                                  totalDuration,
                                  getTimeDifference(actStartingTime, startingTime).hours * 3600 +
                                      getTimeDifference(actStartingTime, startingTime).minutes * 60,
                              )
                            : 0
                    }
                    $width={getPercentage(totalDuration, actualDuration)}
                />
            </Tooltip>
        </TWTimelineViewContainer>
    );
}
