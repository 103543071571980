import {
    getGridStringOperators,
    GridColDef,
    GridComparatorFn,
    GridFilterItem,
    GridFilterOperator,
} from '@mui/x-data-grid';
import {
    CompareTwoDateTimes,
    FormatDateWithDayNameTextAtTheEnd,
    formatNumberOnTwoDigits,
    FormatTimeText,
    TimeSpan,
} from '../../Common/DateHandler';
import { InputNumberInterval } from './CustomFilters/InputNumberInterval';
import { InputDateInterval } from './CustomFilters/InpurDateInterval';

export interface RowDataType {
    id: number;
    isFinished: boolean;
    realId: number;
    date: string;
    realDate: Date;
    truck: string;
    truckRegNum: string;
    driver: string;
    customer: string;
    document: string;
    documentCode: string;
    loadValue: number;
    unloadingAddress: string;
    transportationPrice: number;
    loadingStartingTime: string;
    realLoadingStartingTime: Date;
    loadingEndingTime: string;
    realLoadingEndingTime: Date;
    loadingDuration: string;
    twStartingTime: string;
    realTwStartingTime: Date;
    twEndingTime: string;
    realTwEndingTime: Date;
    twDuration: string;
    startingKm: number;
    endingKm: number;
    kmDriven: number;
}

export function createRowData(
    id: number,
    isFinished: boolean,
    date: Date,
    truck: string,
    truckRegNum: string,
    driver: string,
    customer: string,
    document: string,
    documentCode: string,
    loadValue: number,
    unloadingAddress: string,
    transportationPrice: number,
    loadingStartingTime: Date,
    loadingEndingTime: Date,
    loadingDuration: TimeSpan,
    twStartingTime: Date,
    twEndingTime: Date,
    twDuration: TimeSpan,
    startingKm: number,
    endingKm: number,
    kmDriven: number,
): RowDataType {
    return {
        id: id + Math.random(),
        isFinished,
        realId: id,
        date: FormatDateWithDayNameTextAtTheEnd(date),
        realDate: date,
        truck,
        truckRegNum,
        driver,
        customer,
        document,
        documentCode,
        loadValue,
        unloadingAddress,
        transportationPrice,
        loadingStartingTime: FormatTimeText(new Date(loadingStartingTime)),
        realLoadingStartingTime: loadingStartingTime,
        loadingEndingTime: FormatTimeText(new Date(loadingEndingTime)),
        realLoadingEndingTime: loadingEndingTime,
        loadingDuration: `${formatNumberOnTwoDigits(loadingDuration.hours)}:${formatNumberOnTwoDigits(
            loadingDuration.minutes,
        )}`,
        twStartingTime: FormatTimeText(new Date(twStartingTime)),
        realTwStartingTime: twStartingTime,
        twEndingTime: FormatTimeText(new Date(twEndingTime)),
        realTwEndingTime: twEndingTime,
        twDuration: `${formatNumberOnTwoDigits(twDuration.hours)}:${formatNumberOnTwoDigits(twDuration.minutes)}`,
        startingKm,
        endingKm,
        kmDriven,
    };
}

const numberOnlyOperators: GridFilterOperator[] = [
    {
        label: 'Između',
        value: 'between',
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
                return null;
            }
            if (filterItem.value[0] == null || filterItem.value[1] == null) {
                return null;
            }

            return ({ value }) => {
                return value !== null && filterItem.value[0] <= value && value <= filterItem.value[1];
            };
        },
        InputComponent: InputNumberInterval,
    },
];

const dateOnlyOperators: GridFilterOperator[] = [
    {
        label: 'Između',
        value: 'between',
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
                return null;
            }
            if (filterItem.value[0] == null || filterItem.value[1] == null) {
                return null;
            }

            return ({ value }) => {
                return value !== null && filterItem.value[0] <= value && value <= filterItem.value[1];
            };
        },
        InputComponent: InputDateInterval,
    },
];

const customDateComparator: GridComparatorFn<Date> = (v1: Date, v2: Date) =>
    CompareTwoDateTimes(v2, v1, true) ? 1 : -1;

const customStringFilterOperators = () => {
    return getGridStringOperators().map((operator) => {
        return {
            ...operator,
            label: operator.value.includes('equals')
                ? 'jednako'
                : operator.value.includes('contains')
                ? 'sadrzi'
                : operator.value.includes('startsWith')
                ? 'počinje sa'
                : operator.value.includes('endsWith')
                ? 'završava se sa'
                : operator.value.includes('isEmpty')
                ? 'prazno'
                : operator.value.includes('isNotEmpty')
                ? 'neprazno'
                : 'bilo sta',
        };
    });
};

export const columns: readonly GridColDef[] = [
    {
        field: 'realDate',
        valueFormatter: (params) => FormatDateWithDayNameTextAtTheEnd(params.value),
        width: 150,
        headerName: 'Datum',
        hideable: false,
        headerClassName: 'table-view-header',
        sortComparator: customDateComparator,
        filterOperators: dateOnlyOperators,
    },
    {
        field: 'truck',
        minWidth: 200,
        headerName: 'Kamion',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        filterOperators: customStringFilterOperators(),
    },
    {
        field: 'truckRegNum',
        width: 150,
        headerName: 'Registracija',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterOperators: customStringFilterOperators(),
    },
    {
        field: 'driver',
        width: 150,
        headerName: 'Vozač',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterOperators: customStringFilterOperators(),
    },
    {
        field: 'customer',
        width: 150,
        headerName: 'Kupac',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterOperators: customStringFilterOperators(),
    },
    {
        field: 'document',
        width: 150,
        headerName: 'Dokument',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterOperators: customStringFilterOperators(),
    },
    {
        field: 'documentCode',
        width: 150,
        headerName: 'Br. dok.',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterOperators: customStringFilterOperators(),
    },
    {
        field: 'loadValue',
        headerName: 'Vrednost robe',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterOperators: numberOnlyOperators,
    },
    {
        field: 'unloadingAddress',
        width: 250,
        headerName: 'Adresa istovara',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        filterOperators: customStringFilterOperators(),
    },
    {
        field: 'transportationPrice',
        headerName: 'Cena transporta',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterOperators: numberOnlyOperators,
    },
    {
        field: 'loadingStartingTime',
        headerName: 'Početak utovara',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterable: false,
        disableColumnMenu: true,
        filterOperators: undefined,
    },
    {
        field: 'loadingEndingTime',
        headerName: 'Kraj utovara',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterable: false,
        disableColumnMenu: true,
        filterOperators: undefined,
    },
    {
        field: 'loadingDuration',
        headerName: 'Trajanje utovara',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterable: false,
        disableColumnMenu: true,
        filterOperators: undefined,
    },
    {
        field: 'twStartingTime',
        headerName: 'Vreme polaska',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterable: false,
        disableColumnMenu: true,
        filterOperators: undefined,
    },
    {
        field: 'twEndingTime',
        headerName: 'Vreme dolaska',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterable: false,
        disableColumnMenu: true,
        filterOperators: undefined,
    },
    {
        field: 'twDuration',
        headerName: 'Vreme u transportu',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterable: false,
        disableColumnMenu: true,
        filterOperators: undefined,
    },
    {
        field: 'startingKm',
        headerName: 'Početna km',
        hideable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterOperators: numberOnlyOperators,
    },
    {
        field: 'endingKm',
        headerName: 'Završna km',
        hideable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterOperators: numberOnlyOperators,
    },
    {
        field: 'kmDriven',
        headerName: 'Pređeno km',
        hideable: false,
        sortable: false,
        headerClassName: 'table-view-header',
        align: 'right',
        headerAlign: 'right',
        filterOperators: numberOnlyOperators,
    },
];
